import {
    TextCaseType,
    TextSubSuperType,
    TextDirectionType,
    TextAlignHorizontalType,
    TextAlignVerticalType
} from '@phase-software/types'
import unicode from 'unicode-properties'
import { fromUnicode as scriptFromUnicodeScript } from 'fontkit/src/layout/Script'
import { UtfString } from 'utfstring'
import { FontHelper } from '@phase-software/data-store/src/media/FontHelper'
import { Resource } from "../Resource"
import { VisualStorage } from '../visual_server/VisualStorage'

/** @typedef {import('./FontResource').FontResource} FontResource */

/**
 * @typedef {object} Options
 * @property {string} fontFamily
 * @property {string} fontStyle
 * @property {number} fontSize
 * @property {TextDirectionType} textDirection
 * @property {boolean} underline
 * @property {boolean} strikethrough
 * @property {TextCaseType} cases
 * @property {TextSubSuperType} subSuper
 * @property {number} lineSpacing
 * @property {number} characterSpacing
 * @property {number} paragraphIndent
 * @property {TextAlignHorizontalType} horizontalAlignment
 * @property {TextAlignVerticalType} verticalAlignment
 * @property {[number, number]} size
 */

// TODO:
// - expose options into individual updatable properties
// - implement the unicode line breaking algorithm
// - see https://app.clubhouse.io/phase/story/5458/improve-text-layout
// - cache triangulated glyphs - to speed up rendering fills
// - see if we need to further cache result of layouting

export class TextResource extends Resource {
    constructor() {
        super()

        this.content = ''

        /** @type {Options} */
        this.options = {
            fontFamily: 'Roboto',
            fontStyle: 'Regular',
            fontSize: 12,
            textDirection: TextDirectionType.LTR,
            underline: false,
            strikethrough: false,
            cases: TextDirectionType.NORMAL,
            subSuper: TextSubSuperType.NORMAL,
            lineSpacing: 120,
            characterSpacing: 0,
            paragraphIndent: 0,
            horizontalAlignment: TextAlignHorizontalType.LEFT,
            verticalAlignment: TextAlignVerticalType.TOP,
            size: [0, 0],
        }

        /** @type {{glyphLines: GlyphLine[], styledGlyphs: Glyph[][] }} */
        this._data = { glyphLines: null, styledGlyphs: null }
    }

    getData() {
        return this._data
    }

    /**
     * @param {string} content
     * @param {Options} [options]
     */
    setData(content, options) {
        this.content = content
        if (options) this.options = options

        this._dirty = true
    }

    /** @protected */
    update() {
        const fontName = FontHelper.translateFontName(this.options)
        const defaultFontRes = VisualStorage.instance().getOrCreateFontResource(fontName)
        this.addDependency(defaultFontRes)

        const defaultFont = defaultFontRes.getData()

        if (!defaultFont) {
            this._data = { glyphLines: null, styledGlyphs: null }
            return
        }

        // Map characters to character data
        /** @type {CharData[]} */
        const charData = []
        let nextIsVariationSelector = false

        // save characters with changed case to find the index
        // const length = utfstring.length(this.content)
        const length = this.content.length
        for (let i = 0; i < length; i++) {
            // const char = utfstring.charAt(this.content, i)
            // const codePoint = utfstring.charCodeAt(this.content, i)
            const char = this.content.charAt(i)
            const codePoint = this.content.charCodeAt(i)
            const isLast = i === length - 1

            // determine glyph and record the style or the overrided style
            const font = defaultFont
            let fontRes = defaultFontRes
            // const charStyleIndex = node.computedStyle.styles[0].characterStyleOverrides[i]
            const style = { ...this.options }
            // if (charStyleIndex) {
            //     const charStyle = node.computedStyle.styles[0].styleOverrideTable[charStyleIndex]
            //     Object.assign(style, charStyle)
            //     if (charStyle.fontFamily || charStyle.fontStyle) {
            //         font = fonts.get(charStyle)
            //     }
            // }

            // check if it's variation first
            const safeString = new UtfString(this.content)
            const isVariation = !isLast && font._cmapProcessor.getVariationSelector(codePoint, safeString.charCodeAt(i + 1)) !== 0
            const isInFont = isVariation || nextIsVariationSelector || font.hasGlyphForCodePoint(codePoint)
            nextIsVariationSelector = isVariation

            if (codePoint !== 10 && !isInFont) {
                const fontName = FontHelper.getFallbackFontName(codePoint)
                fontRes = VisualStorage.instance().getOrCreateFontResource(fontName)
                this.addDependency(fontRes)
            }

            let c = char
            if (style.cases === TextCaseType.UPPER) {
                c = char.toUpperCase()
            } else if (style.cases === TextCaseType.LOWER) {
                c = char.toLowerCase()
            } else if (style.cases === TextCaseType.TITLE && (i === 0 || safeString.charAt(i - 1).match(/\s/))) {
                c = char.toUpperCase()
            }
            charData.push({ char: c, style, font: fontRes })
        }

        // Split character data into lines of character data
        /** @type {CharData[]} */
        let lastCharLine = []
        /** @type {CharData[][]} */
        const charLines = [lastCharLine]
        for (const char of charData) {
            lastCharLine.push(char)
            if (char.char === '\n') {
                lastCharLine = []
                charLines.push(lastCharLine)
            }
        }
        if (
            lastCharLine.length === 0 ||
            (lastCharLine.length === 1 && lastCharLine[0].char === '\n')
        ) {
            charLines.pop()
        }

        /** @type {Glyph[][]} */
        const styledGlyphs = []
        /** @type {GlyphLine[]} */
        const glyphLines = []

        for (const line of charLines) {
            attachScriptChanges(line)
            const sections = generateGlyphSections(line)
            styledGlyphs.push(...sections)
            glyphLines.push({
                glyphs: sections.reduce((acc, section) => acc.concat(section), []),
                EOL: true
            })
        }

        let index = 0
        for (const line of glyphLines) {
            for (const glyph of line.glyphs) {
                if (!glyph.glyph) {
                    continue
                }
                glyph.index = index
                index += glyph.length
            }
        }

        const finalLines = positionHorizontally(glyphLines, this.options)
        positionVertically(finalLines, this.options)

        this._data = { glyphLines: finalLines, styledGlyphs }
    }
}

/**
 * @typedef Glyph
 * @property {number} [x]
 * @property {number} [y]
 * @property {number} [top]
 * @property {number} [bottom]
 * @property {number} [scale]
 * @property {number} [width]
 * @property {number} [height]
 * @property {number} [index] - corrseponds with the nth character in the text
 * @property {string} char
 * @property {number} length
 * @property {Options} style
 * @property {boolean} empty - the glyph doesn't contain any geometry (spaces etc.)
 * @property {BBOX} [bbox] - bounding box relative to text node position
 * @property {FontResource} fontRes
 * @property {import('fontkit').Glyph} glyph
 * @property {import('fontkit').GlyphPosition} position
 * @property {boolean} loading
 */

/**
 * @typedef {object} BBOX
 * @property {number} minX
 * @property {number} minY
 * @property {number} maxX
 * @property {number} maxY
 */

/**
 * @typedef {object} GlyphLine
 * @property {Glyph[]} glyphs
 * @property {boolean} EOL - whether there is an EOL character on this line
 */

/**
 * @typedef {object} CharData
 * @property {string} char
 * @property {Options} style
 * @property {FontResource} font
 * @property {string} [scriptChange]
 */

/**
 * @typedef {object} LineSection
 * @property {Options} style
 * @property {FontResource} font
 * @property {string} script
 * @property {CharData[]} chars
 */

/**
 * @typedef {object} PositioningOptions
 * @property {[number, number]} size
 * @property {TextDirectionType} textDirection
 * @property {number} paragraphIndent
 * @property {TextAlignHorizontalType} horizontalAlignment
 * @property {number} fontSize
 * @property {number} lineSpacing
 * @property {TextAlignVerticalType} verticalAlignment
 */

/**
 * Returns glyph sections with the same style
 * @param {CharData[]} line
 * @returns {Glyph[][]}
 */
function generateGlyphSections(line) {
    const sections = generateLineSections(line)

    // Generate glyph data
    /** @type {Glyph[][]} */
    const result = []
    for (const section of sections) {
        /** @type {Glyph[]} */
        const res = []

        const font = section.font.getData()
        if (font) {
            const text = section.chars.reduce((text, data) => text + data.char, '')
            // TODO: remove 'ltr' from here when implementing the unicode bidirectional algorithm
            const { glyphs, positions } = font.layout(text, {}, section.script, undefined, 'ltr')
            for (let i = 0; i < glyphs.length; i++) {
                const glyph = glyphs[i]
                const char = String.fromCodePoint(...glyph.codePoints)
                const safeString = new UtfString(char)
                res.push({
                    fontRes: section.font,
                    style: section.style,
                    glyph,
                    empty: glyph.path.commands.length === 0,
                    char,
                    length: safeString.length,
                    position: positions[i],
                    loading: false
                })
            }
        } else {
            for (const charData of section.chars) {
                const safeString = new UtfString(charData.char)
                res.push({
                    fontRes: section.font,
                    style: section.style,
                    glyph: null,
                    loading: true,
                    empty: true,
                    char: charData.char,
                    length: safeString.length,
                    position: null
                })
            }
        }

        if (res.length !== 0) result.push(res)
    }
    return result
}

/**
 * @param {CharData[]} line
 * @returns {LineSection[]}
 */
function generateLineSections(line) {
    /** @type {LineSection[]} */
    const sections = []
    for (let i = 0; i < line.length; i++) {
        const charData = line[i]
        // Split lines further into chunks of data with similar font, style and script
        if (i === 0 || !isSameStyle(charData, line[i - 1]) || charData.scriptChange) {
            sections.push({
                font: charData.font,
                style: charData.style,
                script: charData.scriptChange || sections[sections.length - 1].script,
                chars: [charData]
            })
        } else {
            sections[sections.length - 1].chars.push(charData)
        }
    }
    return sections
}

/**
 * compares two characters to see if they're the same type
 * @param {CharData} char1
 * @param {CharData} char2
 * @returns {boolean}
 */
function isSameStyle(char1, char2) {
    return (
        char1.font === char2.font &&
        char1.style.fontSize === char2.style.fontSize &&
        char1.style.fontStyle === char2.style.fontStyle &&
        char1.style.lineSpacing === char2.style.lineSpacing &&
        char1.style.subSuper === char2.style.subSuper &&
        char1.style.characterSpacing === char2.style.characterSpacing &&
        char1.style.underline === char2.style.underline &&
        char1.style.strikethrough === char2.style.strikethrough
        // drawStyleEqual(char1.style.fills, char2.style.fills) &&
        // drawStyleEqual(char1.style.strokes, char2.style.strokes)
    )
}

/**
 * Attach script changes to charData in line
 *
 * part of this is from: https://github.com/foliojs/fontkit/blob/e2ff84e69f83272a0f05179d537a86a462aea299/src/layout/Script.js#L156-L180
 * @param {CharData[]} line
 */
function attachScriptChanges(line) {
    let lastScriptChange

    const len = line.length
    let idx = 0
    while (idx < len) {
        let code = line[idx++].char.charCodeAt(0)

        // Check if this is a high surrogate
        if (code >= 0xd800 && code <= 0xdbff && idx < len) {
            const next = line[idx].char.charCodeAt(0)

            // Check if this is a low surrogate
            if (0xdc00 <= next && next <= 0xdfff) {
                idx++
                code = ((code & 0x3ff) << 10) + (next & 0x3ff) + 0x10000
            }
        }

        const script = unicode.getScript(code)
        if (script !== 'Common' && script !== 'Inherited' && script !== 'Unknown') {
            const s = scriptFromUnicodeScript(script)
            if (lastScriptChange !== s) {
                line[lastScriptChange ? idx - 1 : 0].scriptChange = s
                lastScriptChange = s
            }
        }
    }

    if (len > 0 && !lastScriptChange) {
        line[0].scriptChange = scriptFromUnicodeScript('Unknown')
    }
}

/**
 * @param {GlyphLine[]} lines
 * @param {PositioningOptions} options
 * @returns {GlyphLine[]}
 */
function positionHorizontally(lines, options) {
    /**
     * find next space in a glyphLine
     * @param {Glyph[]} line to search
     * @param {number} start index
     * @returns {number} index or -1 if not found
     */
    function nextSpace(line, start) {
        for (let i = start; i < line.length; i++) {
            if (line[i].char === ' ' || line[i].char === '\t') {
                return i
            }
        }
        return line.length
    }

    const finalLines = []
    for (const line of lines) {
        if (line.glyphs.length === 0) {
            finalLines.push(line)
        } else {
            let previousParagraph = true
            let width = measureLine(line, 0, previousParagraph, options)
            let counter1 = 0,
                done
            while (width > options.size[0] && !done && counter1++ < 1000) {
                let start = 0
                let last = 0
                let testWidth
                let counter2 = 0
                let lastIndex = -1

                // finds the furthest space to break the line
                do {
                    const endIndex = lastIndex === line.glyphs.length ? -1 : nextSpace(line.glyphs, start)
                    if (endIndex === -1) {
                        start = -1
                    } else {
                        testWidth = measureLine(line, endIndex, previousParagraph, options)
                        last = start
                        start = endIndex + 1
                    }
                    lastIndex = endIndex
                } while (testWidth < options.size[0] && start !== -1 && counter2++ < 1000)
                if (counter2 === 1001) {
                    console.error('getPathsWithLineBreaks has an infinite loop (inside loop)')
                }
                if (last === 0) {
                    done = true
                } else {
                    const newLine = {
                        EOL: false,
                        glyphs: line.glyphs.splice(0, last),
                        decorations: []
                    }
                    finalLines.push(newLine)
                    reverseLine(newLine, options)
                    measureLine(newLine, 0, previousParagraph, options, true)
                    previousParagraph = false
                    width = measureLine(line, 0, previousParagraph, options)
                }
            }
            if (counter1 === 1001) {
                console.error('getPathsWithLineBreaks has an infinite loop (outside loop).')
            }
            if (line.glyphs.length !== 0) {
                finalLines.push(line)
            }
            reverseLine(line, options)
            measureLine(line, 0, previousParagraph, options, true)
        }
    }

    return finalLines
}

/**
 * measures the width of a line (for word wrap) or populates the glyph.x (for when word wrap is complete)
 * @param {GlyphLine} line
 * @param {number} end                  index
 * @param {boolean} previousParagraph   whether the previous paragraph contained a \n (for paragraphIndent purposes)
 * @param {PositioningOptions} options
 * @param {boolean} [save=false]        stores x values for glyphs (used after word wrap is complete)
 * @returns {number|undefined}
 */
function measureLine(line, end, previousParagraph, options, save = false) {
    const paragraphIndent = previousParagraph ? options.paragraphIndent : 0
    let x = paragraphIndent
    const glyphs = line.glyphs
    const length = end === 0 ? glyphs.length : end
    for (let i = 0; i < length; i++) {
        const glyph = glyphs[i]
        const { style, fontRes, position } = glyph
        const font = fontRes.getData()

        if (glyph.char === '\n') {
            glyph.x = x
            glyph.width = 0
            glyph.height = style.fontSize
            glyph.scale = style.fontSize / font.unitsPerEm
            continue
        }
        if (glyph.loading) {
            const fontSize = style.fontSize
            if (save) {
                glyph.x = x
                glyph.scale = 1
                glyph.width = fontSize / 2
                glyph.height = fontSize
            }
            x += fontSize / 2 + style.characterSpacing
        } else {
            const scale = style.fontSize / font.unitsPerEm

            // const os2 = font['OS/2']
            // if (style.subSuper ===  TextSubSuperType.SUPERSCRIPT) {
            //     console.log(glyph, font.name, os2, font.unitsPerEm)
            //     if (os2 && os2.ySuperscriptYSize) {
            //         scale *= os2.ySuperscriptYSize / (font.ascent - font.descent)
            //         x += os2.ySuperscriptXOffset * scale
            //         glyph.yAdjust = -style.fontSize * (os2.ySuperscriptYOffset / font.unitsPerEm)
            //     }
            // } else if (style.subSuper === TextSubSuperType.SUBSCRIPT) {
            //     console.log(glyph, font.name, os2, font.unitsPerEm)
            //     if (os2 && os2.ySubscriptYSize) {
            //         scale *= Math.min(os2.ySubscriptXSize, os2.ySubscriptYSize) / font.unitsPerEm
            //         x += os2.ySubscriptXOffset * scale
            //         glyph.yAdjust = -style.fontSize * (os2.ySubscriptYOffset / font.unitsPerEm)
            //     }
            // }

            const xAdvance = position.xAdvance * scale

            if (save) {
                glyph.x = x + position.xOffset * scale
                glyph.scale = scale
                glyph.width = xAdvance
                glyph.height = (font.ascent - font.descent) * scale
            }
            x += xAdvance + style.characterSpacing
        }
    }

    if (!save) {
        return x
    }

    // apply horizontal alignment to line
    if (length && !(glyphs.length === 1 && !glyphs[0].glyph)) {
        const last = glyphs[glyphs.length - 1]
        const x = last.x + (last.loading ? 0 : last.width)

        switch (options.horizontalAlignment) {
            case TextAlignHorizontalType.CENTER: {
                for (const glyph of glyphs) {
                    glyph.x += options.size[0] / 2 - x / 2
                }
                break
            }
            case TextAlignHorizontalType.RIGHT: {
                for (const glyph of glyphs) {
                    glyph.x += options.size[0] - x
                }
                break
            }
            case TextAlignHorizontalType.JUSTIFIED: {
                const leftOver = (options.size[0] - x) / (glyphs.length - 1)
                let total = 0
                for (let i = 0; i < glyphs.length; i++) {
                    glyphs[i].x += total
                    total += leftOver
                }
                break
            }
        }
    }
}

/**
 * Reverses line after word wrap for RTL declaration
 * note: currently triggered by name contaiing "(RTL)" until we include our own RTL flag in text boxes
 * @param {GlyphLine} line
 * @param {PositioningOptions} options
 */
function reverseLine(line, options) {
    if (options.textDirection === TextDirectionType.RTL) {
        line.glyphs.reverse()
    }
}

/**
 * @param {GlyphLine[]} lines
 * @param {PositioningOptions} options
 */
function positionVertically(lines, options) {
    let y = 0
    // iterate through the glyphs and set the proper y coordinate
    for (const line of lines) {
        let totalLineHeight = 0
        if (line.glyphs.length === 0) {
            totalLineHeight = options.fontSize * options.lineSpacing / 100
        } else {
            const isFirstLine = line === lines[0]

            for (const { style, fontRes } of line.glyphs) {
                const font = fontRes.getData()
                // figma like line-height behavior
                // first line gets half line height above (works like CSS)
                // for subsequent lines, the line height is being distributed between baselines
                const lineSpacing = style.fontSize * style.lineSpacing / 100
                if (isFirstLine && font) {
                    // calculate ascenderOffset
                    const ascent = Math.abs(font.ascent / font.unitsPerEm)
                    const descent = Math.abs(font.descent / font.unitsPerEm)
                    const total = ascent + descent
                    // normalize ascent to be 0 - 1
                    const ascenderPercent = ascent / total
                    const ascenderOffset = style.fontSize * ascenderPercent

                    const halfLineHeight = (lineSpacing - style.fontSize) / 2
                    const lineHeight = halfLineHeight + ascenderOffset
                    totalLineHeight = Math.max(totalLineHeight, lineHeight)
                } else {
                    totalLineHeight = Math.max(totalLineHeight, lineSpacing)
                }
            }
        }

        y += totalLineHeight

        for (const glyph of line.glyphs) {
            const fontData = glyph.fontRes.getData()
            glyph.y = y + (fontData ? glyph.position.yOffset * glyph.scale : 0)

            const ascender = fontData ? fontData.ascent * glyph.scale : glyph.height * 0.25
            const descender = fontData ? fontData.descent * glyph.scale : glyph.height * 0.75
            glyph.top = glyph.y - ascender
            glyph.bottom = glyph.y - descender
        }
    }

    // adjust all glyphs based on verticalAlignment
    let yOffset = 0
    if (options.verticalAlignment === TextAlignVerticalType.BOTTOM) {
        yOffset = options.size[1] - y
    } else if (options.verticalAlignment === TextAlignVerticalType.CENTER) {
        yOffset = options.size[1] / 2 - y / 2
    }
    if (yOffset) {
        for (const line of lines) {
            for (const glyph of line.glyphs) {
                glyph.y += yOffset
                glyph.top += yOffset
                glyph.bottom += yOffset
            }
        }
    }
}
