import {
    CreateElementToolType,
    ElementType,
    EditMode,
    Mode,
} from '@phase-software/types'
import { NO_COMMIT } from '@phase-software/data-utils'
import { TRANSITION_STATUS } from '@phase-software/transition-manager'
import {
    Hover,
    ElementSelectionTypeMap,
    ElementSelection,
    AllowedEventsInViewingState,
    AllowedEventsInInspectingingState
} from './constants'

class EAMStates {
    constructor(dataStore) {
        this.dataStore = dataStore
        this.init()
        dataStore.on('LOAD-START', this.handleDataStoreLoadStart.bind(this))
        dataStore.on('LOAD', this.handleDataStoreLoad.bind(this))
        dataStore.on('mode', this._handleModeChange.bind(this))
        dataStore.on('editMode', this._handleEditModeChange.bind(this))
        dataStore.transition.on('STATUS', this._handleTransitionStatusChange.bind(this))
        dataStore.selection.on('hover', this._handleHoverElementChange.bind(this))
        dataStore.selection.on('SELECT', this._handleSelectionChange.bind(this))
        dataStore.selection.on('SELECT_CELL', this._handleCellSelectionChange.bind(this))
        dataStore.selection.on('hoverVertex', this._handleHoverVertex.bind(this))
    }

    init() {
        this.dataStoreLoaded = this.dataStore.isLoaded
        this.mode = this.dataStore.get('mode')
        this.hover = Hover.NONE
        // If hold space
        this.space = false
        // If hold left mouse
        this.mouse = false
        this._elementSelectionCache = null
        this.elementSelection = ElementSelection.NONE
        this._cellSelectionCache = null
        this.cellSelection = false
        this.keyframeSelection = false
        this.gradientStopSelection = false

        this.currentModal = null
        this.currentBasicModal = null
        this.inputFocus = false
        this.animation = false

        this._hoversFound = 0
        this._prevEditMode = EditMode.ELEMENT

        this._hoverSelectedElement = null
        this.hoverSelected = false
        this.prevHideOrigin = false
    }

    _handleModeChange(mode) {
        this.mode = mode
        if (mode === Mode.ACTION && this.dataStore.get('state') === 'EDITING') {
            this.dataStore.setActiveTool(this.lastGeneralTool)
        }
    }

    _handleEditModeChange(editMode) {
        // We will set editMode back to GRADIENT_HANDLES mode in this situation.
        // WHEN open color picker with gradient
        // AND close the color picker
        // AND undo
        //
        // If get editMode = GRADIENT_HANDLES mode from undo with linear color change,
        // then we should force change it back to ELEMENT mode.
        if (editMode === EditMode.GRADIENT_HANDLES && !this.currentModal) {
            this.dataStore.set('editMode', EditMode.ELEMENT, NO_COMMIT)
        }
    }

    _handleTransitionStatusChange(status) {
        this.animation = status === TRANSITION_STATUS.START
    }

    _handleHoverElementChange(/* element */) {
        // noop
        // chageHover(ELEMENT) is done in HOVER_ELEMENT
    }

    _handleHoverVertex(/* vertex */) {
        // chageHover(CELL) is done in HOVER_CELL
    }

    _handleSelectionChange(change) {
        const elements = change.get('elements')
        if (elements) {
            this._elementSelectionCache = elements.after
            this._updateElementSelectionState()
        }

        const kfs = change.get('kfs')
        if (kfs && kfs.after.length) {
            this.keyframeSelection = true
        } else {
            this.keyframeSelection = false
        }
    }

    _handleCellSelectionChange(change) {
        const cells = change.get('vertices')
        if (cells && cells.after.length) {
            this.cellSelection = true
        } else {
            this.cellSelection = false
        }
    }

    _updateElementSelectionState() {
        if (this._elementSelectionCache.length === 0) {
            this.elementSelection = ElementSelection.NONE
        } else if (this._elementSelectionCache.length === 1) {
            this.elementSelection = this._getElementType(this._elementSelectionCache[0])
        } else if (this._elementSelectionCache.length > 1) {
            this.elementSelection = ElementSelection.MULTIPLE
        }
    }

    _getElementType(elem) {
        const elementType = elem.get('elementType')
        let elemState = ElementSelection.NONE
        if (elementType === ElementType.PATH) {
            const selectionMap = ElementSelectionTypeMap[elementType]
            const geometryType = elem.get('geometry').get('geometryType')
            elemState = selectionMap[geometryType]
        } else {
            elemState = ElementSelectionTypeMap[elementType]
        }

        return elemState
    }

    get isSingleElementSelection() {
        return this.elementSelection !== ElementSelection.NONE && this.elementSelection !== ElementSelection.MULTIPLE
    }

    get isMultipleElementSelection() {
        return this.elementSelection === ElementSelection.MULTIPLE
    }

    get isEditMode() {
        return this.dataStore.get('editMode') !== EditMode.ELEMENT
    }

    get editMode() {
        return this.dataStore.get('editMode')
    }

    get isShapeMode() {
        return this.dataStore.get('editMode') === EditMode.SHAPE
    }

    get isTextMode() {
        return this.dataStore.get('editMode') === EditMode.TEXT
    }

    get isGradientHandlesMode() {
        return this.dataStore.get('editMode') === EditMode.GRADIENT_HANDLES
    }

    get activeTool() {
        return this.dataStore.get('activeTool')
    }

    get lastGeneralTool() {
        return this.dataStore.get('lastGeneralTool')
    }

    get isCreationTool() {
        return this.activeTool in CreateElementToolType
    }

    get isHoverOnHandle() {
        const hover = this.hover
        return hover === Hover.RESIZE_HANDLE ||
            hover === Hover.ROTATE_HANDLE ||
            hover === Hover.GRADIENT_STOP_HANDLE ||
            hover === Hover.GRADIENT_TRANSFORM_START_HANDLE ||
            hover === Hover.GRADIENT_TRANSFORM_END_HANDLE ||
            hover === Hover.GRADIENT_TRANSFORM_SHAPE_HANDLE
    }

    get isHoverOnGradientHandle() {
        const hover = this.hover
        return hover === Hover.GRADIENT_STOP_HANDLE ||
            hover === Hover.GRADIENT_TRANSFORM_START_HANDLE ||
            hover === Hover.GRADIENT_TRANSFORM_END_HANDLE ||
            hover === Hover.GRADIENT_TRANSFORM_SHAPE_HANDLE
    }

    get isHoverOnGradientReference() {
        return this.hover === Hover.GRADIENT_TRANSFORM_REFERENCE_LINE
    }

    get isPathElement() {
        return this.elementSelection === ElementSelection.RECTANGLE ||
            this.elementSelection === ElementSelection.ELLIPSE ||
            this.elementSelection === ElementSelection.POLYGON ||
            this.elementSelection === ElementSelection.REGULAR_POLYGON ||
            this.elementSelection === ElementSelection.STAR ||
            this.elementSelection === ElementSelection.LINE ||
            this.elementSelection === ElementSelection.ARROW
    }

    handleDataStoreLoadStart() {
        this.dataStoreLoaded = false
    }

    handleDataStoreLoad() {
        this.dataStoreLoaded = true
    }

    switchMode(mode) {
        this.dataStore.switchMode(mode)
    }

    changeSpace(space) {
        this.space = space
    }

    changeMouse(mouse) {
        this.mouse = mouse
    }

    switchEditMode(editMode, options = {}) {
        this._prevEditMode = this.dataStore.get('editMode')
        this.dataStore.switchEditMode(editMode, options)
        if (options.commit) {
            this.dataStore.commitUndo()
        }
    }

    activateElementEditMode(options) {
        this.switchEditMode(EditMode.ELEMENT, options)
    }

    activateShapeMode(options) {
        this.switchEditMode(EditMode.SHAPE, options)
    }

    activateTextMode() {
        this.switchEditMode(EditMode.TEXT)
    }

    activateGradientHandlesMode(options) {
        this.switchEditMode(EditMode.GRADIENT_HANDLES, options)
    }

    setActiveTool(activeTool, options) {
        this.dataStore.setActiveTool(activeTool, options)
    }

    startHover() {
        this._hoversFound = 0
    }

    changeHover(hoverType) {
        this._hoversFound++
        this.hover = hoverType
    }

    endHover() {
        if (!this._hoversFound) {
            this.hover = Hover.NONE
        }
    }

    changeHoverSelected(hoverSelectedElement) {
        this._hoverSelectedElement = hoverSelectedElement
        this.hoverSelected = !!hoverSelectedElement && (this.hover === Hover.NONE || this.hover === Hover.ELEMENT)
    }

    changeCurrentModal(modalKey) {
        this.currentModal = modalKey
        if (modalKey?.includes('Modal')) this.currentBasicModal = modalKey
    }

    changeInputFocus(focus) {
        this.inputFocus = focus
    }

    setActiveGradientStop(idx) {
        this.gradientStopSelection = idx !== -1
    }

    clear() {
        this.init()
    }

    toggleOrigin() {
        this.dataStore.data.hideOrigin = !this.dataStore.data.hideOrigin
    }

    toggleSnapToPixelGrid() {
        this.dataStore.data.snapToPixelGrid =
            !this.dataStore.data.snapToPixelGrid
    }

    toggleSnapToObject() {
        this.dataStore.data.snapToObject =
            !this.dataStore.data.snapToObject
    }

    canFireEvent(eventName) {
        if(this.dataStore.isInspectingState) {
            return AllowedEventsInInspectingingState.has(eventName)
        }

        if(this.dataStore.isEditingState) return true

        return AllowedEventsInViewingState.has(eventName)
    }
}

export default EAMStates
