import FontFaceObserver from "fontfaceobserver"
import { Num, Transform2D, Vector2, pack_color_u, PI_2, PI2 } from "../math"
import { WASM, allocTempFloat32Array, allocTempUint32Array, allocTempUint8Array } from "../wasm/platforms/wasm"

/** @typedef {import('../gfx/index').Gfx} Gfx */
/** @typedef {import('../gfx/index').Gfx_Pipeline_t} Gfx_Pipeline_t */
/** @typedef {import('../gfx/index').Gfx_Image_t} Gfx_Image_t */
/** @typedef {import('../visual_server/VisualStorage').VisualStorage} VisualStorage */
/** @typedef {import('../resources/VectorResource').PathData} PathData */
/** @typedef {import('../Viewport').Viewport} Viewport */


const MIN_STEPS_FOR_CIRCLE = 8
const MAX_STEPS_FOR_CIRCLE = 128

/** the extra space for covering boundary clipping area */
const VIEW_MARGIN = 150

/** store current viewport rect size for clipping */
const viewRectSize = new Vector2()
/** store Bezier Curve segment geometry vertices which consisted of 2 segment vertices and 2 control points */
const ccwPointsList = [new Vector2(), new Vector2(), new Vector2(), new Vector2()]
/** store clipping viewport boundary vertices */
const viewportVertices = [
    new Vector2(-VIEW_MARGIN, -VIEW_MARGIN),
    new Vector2(viewRectSize.x + VIEW_MARGIN, -VIEW_MARGIN),
    new Vector2(viewRectSize.x + VIEW_MARGIN, viewRectSize.y + VIEW_MARGIN),
    new Vector2(-VIEW_MARGIN, viewRectSize.y + VIEW_MARGIN),
    new Vector2(-VIEW_MARGIN, -VIEW_MARGIN)
]

export class Pane {
    /**
     * @param {Viewport} viewport
     */
    constructor(viewport) {
        this.index = 0

        this.viewport = viewport

        this.transform = new Transform2D()
        this.globalAlpha = 1.0

        this.fill = {
            color: 0xFFFFFF,
            alpha: 1.0,
        }
        this.stroke = {
            width: 1.0,
            color: 0xFFFFFF,
            alpha: 1.0,
        }

        this.resetTransform()
    }

    reset() {
        this.transform.identity()

        this.globalAlpha = 1.0

        this.fill.color = 0xFFFFFF
        this.fill.alpha = 1.0

        this.stroke.width = 1.0
        this.stroke.color = 0xFFFFFF
        this.stroke.alpha = 1.0

        this.clear()

        this.resetTransform()

        return true
    }

    clear() {
        updateViewportData(this.viewport)

        return this
    }

    /**
     * @param {number} alpha
     * @returns {this}
     */
    setAlpha(alpha) {
        this.globalAlpha = alpha
        return this
    }

    /**
     * @returns {this}
     */
    resetTransform() {
        this.transform.identity().scale(this.viewport.pixelRatio, this.viewport.pixelRatio)
        return this
    }

    /**
     * @param {Transform2D} transform
     * @returns {this}
     */
    appendTansform(transform) {
        this.transform.append(transform)
        return this
    }

    /**
     * @param {number} color
     * @param {number} [alpha]
     * @returns {this}
     */
    fillStyle(color, alpha = 1) {
        this.fill.color = color
        this.fill.alpha = alpha
        return this
    }

    /**
     * @param {number} width
     * @param {number} color
     * @param {number} [alpha]
     * @returns {this}
     */
    lineStyle(width, color, alpha = 1) {
        this.stroke.width = width * this.viewport.pixelRatio
        this.stroke.color = color
        this.stroke.alpha = alpha
        return this
    }

    /**
     * @param {number} add extra width to add to original width (used together with AA pipeline)
     * @returns {number} stroke width with the inverse of the transform applied
     */
    getLineWidth(add = 0) {
        const T = this.transform.untranslated().affine_inverse()
        const w = vec2.set(this.stroke.width + add, this.stroke.width + add)
        T.xform(w, w)
        w.abs()
        return Math.max(w.x, w.y)
    }

    /**
     * @param {number} x0
     * @param {number} y0
     * @param {number} x1
     * @param {number} y1
     * @param {"butt"|"round"|"square"} [cap="butt"]
     * @returns {this}
     */
    drawLine(x0, y0, x1, y1, cap = "butt") {
        const addCaps = this.stroke.width > 2 && (cap === "round" || cap === "square")
        const useAAPipeline = !addCaps
        const width2 = this.getLineWidth(useAAPipeline ? 1 : 0) * 0.5
        // const { vertices, indices } = getCommand(this, null, useAAPipeline ? PipelineEnum.AA_LINE : PipelineEnum.TEX_2D)
        const { vertices, indices } = getCommand(this)

        const color = packColor32F(this.stroke.color, this.stroke.alpha * this.globalAlpha)

        const angle = Math.atan2(y1 - y0, x1 - x0)

        miter11.set(0, -width2).rotate(angle).add(x0, y0)
        miter12.set(0, +width2).rotate(angle).add(x0, y0)
        miter21.set(0, -width2).rotate(angle).add(x1, y1)
        miter22.set(0, +width2).rotate(angle).add(x1, y1)

        // const width = this.stroke.width
        pushQuad(vertices, indices,
            miter11.x, miter11.y,
            miter12.x, miter12.y,
            miter21.x, miter21.y,
            miter22.x, miter22.y,
            color,
            this.transform,
            // width, 0,
            // 0, width,
            // width, 0,
            // 0, width
        )

        if (!addCaps) {
            pushBrush()
            return this
        }

        if (cap === "round") {
            // start cap
            pushArc(vertices, indices,
                width2,
                x0, y0,
                miter11.x, miter11.y,
                miter12.x, miter12.y,
                color,
                this.transform
            )

            // end cap
            pushArc(vertices, indices,
                width2,
                x1, y1,
                miter22.x, miter22.y,
                miter21.x, miter21.y,
                color,
                this.transform
            )
        } else if (cap === "square") {
            // start cap
            firstMiter1.set(-width2, -width2).rotate(angle).add(x0, y0)
            firstMiter2.set(-width2, +width2).rotate(angle).add(x0, y0)
            pushQuad(vertices, indices,
                miter11.x, miter11.y,
                miter12.x, miter12.y,
                firstMiter1.x, firstMiter1.y,
                firstMiter2.x, firstMiter2.y,
                color,
                this.transform
            )

            // end cap
            firstMiter1.set(+width2, -width2).rotate(angle).add(x1, y1)
            firstMiter2.set(+width2, +width2).rotate(angle).add(x1, y1)
            pushQuad(vertices, indices,
                miter21.x, miter21.y,
                miter22.x, miter22.y,
                firstMiter1.x, firstMiter1.y,
                firstMiter2.x, firstMiter2.y,
                color,
                this.transform
            )
        }

        pushBrush()
        return this
    }

    drawLineShadow(x0, y0, x1, y1, shadowImageSrc) {
        const width2 = this.getLineWidth(this.viewport.pixelRatio) * 0.5
        const shadowImage = loadImage(shadowImageSrc)
        const shadowImageInfo = shadowImage.imageInfo
        if (!shadowImage || !shadowImageInfo) return this

        const { vertices, indices } = getCommand(this, 1, shadowImage.image)

        const color = packColor32F(this.stroke.color, this.stroke.alpha * this.globalAlpha)

        const angle = Math.atan2(y1 - y0, x1 - x0)

        miter11.set(0, -width2).rotate(angle).add(x0, y0)
        miter12.set(0, +width2).rotate(angle).add(x0, y0)
        miter21.set(0, -width2).rotate(angle).add(x1, y1)
        miter22.set(0, +width2).rotate(angle).add(x1, y1)
        pushQuad(vertices, indices,
            miter11.x, miter11.y,
            miter12.x, miter12.y,
            miter21.x, miter21.y,
            miter22.x, miter22.y,
            color,
            this.transform,
            1, 0,
            0, 0,
            1, 1,
            0, 1
        )

        pushBrush()
        return this
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} height
     * @param {number} [cornerRadius]
     * @param {Vector2} elementScale
     * @returns {this}
     */
    drawRect(x, y, width, height, cornerRadius = 0, elementScale) {
        // if (cornerRadius >= width * 0.5 && cornerRadius >= height * 0.5) {
        //     this.drawEllipse(x, y, width, height, false)
        //     return
        // }
        const elementScaleInv = new Vector2(1, 1)
        if (elementScale) {
            elementScaleInv.copy(elementScale.inverse())
        }

        const align = 0.5

        const { vertices, indices } = getCommand(this)
        const v = (vertices.length * 0.2) | 0

        const color = packColor32F(this.stroke.color, this.stroke.alpha * this.globalAlpha)

        const rotation = this.transform.get_rotation()
        const innerWidth = this.stroke.width * (1 - align)
        const outerWidth = this.stroke.width * align

        if (cornerRadius === 0) {
            const iW = new Vector2(innerWidth, innerWidth).rotate(rotation)
            const oW = new Vector2(outerWidth, outerWidth).rotate(rotation)

            // inner vertices
            vec2.set(x, y)
            this.transform.xform(vec2, vec2)
            vec2.add(iW.x, iW.y)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            iW.perp_inv()

            vec2.set(x + width, y)
            this.transform.xform(vec2, vec2)
            vec2.add(iW.x, iW.y)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            iW.perp_inv()

            vec2.set(x + width, y + height)
            this.transform.xform(vec2, vec2)
            vec2.add(iW.x, iW.y)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            iW.perp_inv()

            vec2.set(x, y + height)
            this.transform.xform(vec2, vec2)
            vec2.add(iW.x, iW.y)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            // outer vertices
            vec2.set(x, y)
            this.transform.xform(vec2, vec2)
            vec2.add(-oW.x, -oW.y)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            oW.perp_inv()

            vec2.set(x + width, y)
            this.transform.xform(vec2, vec2)
            vec2.add(-oW.x, -oW.y)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            oW.perp_inv()

            vec2.set(x + width, y + height)
            this.transform.xform(vec2, vec2)
            vec2.add(-oW.x, -oW.y)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            oW.perp_inv()

            vec2.set(x, y + height)
            this.transform.xform(vec2, vec2)
            vec2.add(-oW.x, -oW.y)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            indices.push(
                // top
                v + 4,
                v + 5,
                v + 1,
                v + 4,
                v + 1,
                v + 0,

                // right
                v + 5,
                v + 6,
                v + 2,
                v + 5,
                v + 2,
                v + 1,

                // bottom
                v + 6,
                v + 7,
                v + 3,
                v + 6,
                v + 3,
                v + 2,

                // left
                v + 7,
                v + 4,
                v + 0,
                v + 7,
                v + 0,
                v + 3
            )
        } else {
            const r = Math.min(cornerRadius, width * 0.5, height * 0.5)

            const iw = innerWidth / this.viewport.pixelRatio / this.viewport.scale
            const ow = outerWidth / this.viewport.pixelRatio / this.viewport.scale

            // north bar
            vec2.set(x + r, y + iw * elementScaleInv.y)
            this.transform.xform(vec2, vec2)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            vec2.set(x + width - r, y + iw * elementScaleInv.y)
            this.transform.xform(vec2, vec2)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            vec2.set(x + width - r, y - ow * elementScaleInv.y)
            this.transform.xform(vec2, vec2)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            vec2.set(x + r, y - ow * elementScaleInv.y)
            this.transform.xform(vec2, vec2)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            indices.push(
                v + 0,
                v + 1,
                v + 2,
                v + 2,
                v + 3,
                v + 0
            )

            // east bar
            vec2.set(x + width - iw * elementScaleInv.x, y + r)
            this.transform.xform(vec2, vec2)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            vec2.set(x + width - iw * elementScaleInv.x, y + height - r)
            this.transform.xform(vec2, vec2)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            vec2.set(x + width + ow * elementScaleInv.x, y + height - r)
            this.transform.xform(vec2, vec2)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            vec2.set(x + width + ow * elementScaleInv.x, y + r)
            this.transform.xform(vec2, vec2)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            indices.push(
                v + 4,
                v + 5,
                v + 6,
                v + 6,
                v + 7,
                v + 4
            )

            // south bar
            vec2.set(x + width - r, y + height - iw * elementScaleInv.y)
            this.transform.xform(vec2, vec2)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            vec2.set(x + r, y + height - iw * elementScaleInv.y)
            this.transform.xform(vec2, vec2)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            vec2.set(x + r, y + height + ow * elementScaleInv.y)
            this.transform.xform(vec2, vec2)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            vec2.set(x + width - r, y + height + ow * elementScaleInv.y)
            this.transform.xform(vec2, vec2)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            indices.push(
                v + 8,
                v + 9,
                v + 10,
                v + 10,
                v + 11,
                v + 8
            )

            // west bar
            vec2.set(x + iw * elementScaleInv.x, y + height - r)
            this.transform.xform(vec2, vec2)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            vec2.set(x + iw * elementScaleInv.x, y + r)
            this.transform.xform(vec2, vec2)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            vec2.set(x - ow * elementScaleInv.x, y + r)
            this.transform.xform(vec2, vec2)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            vec2.set(x - ow * elementScaleInv.x, y + height - r)
            this.transform.xform(vec2, vec2)
            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )

            indices.push(
                v + 12,
                v + 13,
                v + 14,
                v + 14,
                v + 15,
                v + 12
            )

            const PI_1_2 = Math.PI * 1 / 2
            const PI_3_2 = Math.PI * 3 / 2

            const segments = Num.clamp(Math.ceil(r * this.viewport.scale * 0.2), 8, 64)
            const step = PI_1_2 / segments

            let vv = v + 16

            const radiusOffset = new Vector2()
            if(isArchInView(x + r, y + r, r, ow, Math.PI, this.viewport.scale, this.transform)) {
                // north west
                for (let s = 0; s <= segments; s++) {
                    radiusOffset.set(ow, 0).rotate(Math.PI + step * s).multiply(elementScaleInv)
                    vec2.set(r, 0).rotate(Math.PI + step * s).add(radiusOffset)
                        .add(x + r, y + r)
                    this.transform.xform(vec2, vec2)
                    vertices.push(
                        vec2.x, vec2.y,
                        1, 0,
                        color
                    )

                    radiusOffset.set(iw, 0).rotate(Math.PI + step * s).multiply(elementScaleInv)
                    vec2.set(r, 0).rotate(Math.PI + step * s).sub(radiusOffset)
                        .add(x + r, y + r)
                    this.transform.xform(vec2, vec2)
                    vertices.push(
                        vec2.x, vec2.y,
                        1, 0,
                        color
                    )

                    if (s === segments) {
                        radiusOffset.set(ow, 0).rotate(PI_3_2).multiply(elementScaleInv)
                        vec2.set(r, 0).rotate(PI_3_2).add(radiusOffset)
                            .add(x + r, y + r)
                        this.transform.xform(vec2, vec2)
                        vertices.push(
                            vec2.x, vec2.y,
                            0, 0,
                            color
                        )

                        radiusOffset.set(iw, 0).rotate(PI_3_2).multiply(elementScaleInv)
                        vec2.set(r, 0).rotate(PI_3_2).sub(radiusOffset)
                            .add(x + r, y + r)
                        this.transform.xform(vec2, vec2)
                        vertices.push(
                            vec2.x, vec2.y,
                            0, 0,
                            color
                        )
                    }

                    indices.push(
                        vv,
                        vv + 1,
                        vv + 2,

                        vv + 2,
                        vv + 1,
                        vv + 3
                    )
                    vv += 2
                }
                vv += 2
            }

            if(isArchInView(x + width - r, y + r, r, ow, PI_3_2, this.viewport.scale, this.transform)) {
                // north east
                for (let s = 0; s <= segments; s++) {
                    radiusOffset.set(ow, 0).rotate(PI_3_2 + step * s).multiply(elementScaleInv)
                    vec2.set(r, 0).rotate(PI_3_2 + step * s).add(radiusOffset)
                        .add(x + width - r, y + r)
                    this.transform.xform(vec2, vec2)
                    vertices.push(
                        vec2.x, vec2.y,
                        1, 0,
                        color
                    )

                    radiusOffset.set(iw, 0).rotate(PI_3_2 + step * s).multiply(elementScaleInv)
                    vec2.set(r, 0).rotate(PI_3_2 + step * s).sub(radiusOffset)
                        .add(x + width - r, y + r)
                    this.transform.xform(vec2, vec2)
                    vertices.push(
                        vec2.x, vec2.y,
                        1, 0,
                        color
                    )

                    if (s === segments) {
                        radiusOffset.set(ow, 0).multiply(elementScaleInv)
                        vec2.set(r, 0).add(radiusOffset)
                            .add(x + width - r, y + r)
                        this.transform.xform(vec2, vec2)
                        vertices.push(
                            vec2.x, vec2.y,
                            0, 0,
                            color
                        )

                        radiusOffset.set(iw, 0).multiply(elementScaleInv)
                        vec2.set(r, 0).sub(radiusOffset)
                            .add(x + width - r, y + r)
                        this.transform.xform(vec2, vec2)
                        vertices.push(
                            vec2.x, vec2.y,
                            0, 0,
                            color
                        )
                    }

                    indices.push(
                        vv,
                        vv + 1,
                        vv + 2,

                        vv + 2,
                        vv + 1,
                        vv + 3
                    )
                    vv += 2
                }
                vv += 2
            }

            if(isArchInView(x + width - r, y + height - r, r, ow, 0, this.viewport.scale, this.transform)) {
                // south east
                for (let s = 0; s <= segments; s++) {
                    radiusOffset.set(ow, 0).rotate(step * s).multiply(elementScaleInv)
                    vec2.set(r, 0).rotate(step * s).add(radiusOffset)
                        .add(x + width - r, y + height - r)
                    this.transform.xform(vec2, vec2)
                    vertices.push(
                        vec2.x, vec2.y,
                        1, 0,
                        color
                    )

                    radiusOffset.set(iw, 0).rotate(step * s).multiply(elementScaleInv)
                    vec2.set(r, 0).rotate(step * s).sub(radiusOffset)
                        .add(x + width - r, y + height - r)
                    this.transform.xform(vec2, vec2)
                    vertices.push(
                        vec2.x, vec2.y,
                        1, 0,
                        color
                    )

                    if (s === segments) {
                        radiusOffset.set(ow, 0).rotate(PI_1_2).multiply(elementScaleInv)
                        vec2.set(r, 0).rotate(PI_1_2).add(radiusOffset)
                            .add(x + width - r, y + height - r)
                        this.transform.xform(vec2, vec2)
                        vertices.push(
                            vec2.x, vec2.y,
                            0, 0,
                            color
                        )

                        radiusOffset.set(iw, 0).rotate(PI_1_2).multiply(elementScaleInv)
                        vec2.set(r, 0).rotate(PI_1_2).sub(radiusOffset)
                            .add(x + width - r, y + height - r)
                        this.transform.xform(vec2, vec2)
                        vertices.push(
                            vec2.x, vec2.y,
                            0, 0,
                            color
                        )
                    }

                    indices.push(
                        vv,
                        vv + 1,
                        vv + 2,

                        vv + 2,
                        vv + 1,
                        vv + 3
                    )
                    vv += 2
                }
                vv += 2
            }

            if(isArchInView(x + r, y + height - r, r, ow, PI_1_2, this.viewport.scale, this.transform)) {
                // south west
                for (let s = 0; s <= segments; s++) {
                    radiusOffset.set(ow, 0).rotate(PI_1_2 + step * s).multiply(elementScaleInv)
                    vec2.set(r, 0).rotate(PI_1_2 + step * s).add(radiusOffset)
                        .add(x + r, y + height - r)
                    this.transform.xform(vec2, vec2)
                    vertices.push(
                        vec2.x, vec2.y,
                        1, 0,
                        color
                    )

                    radiusOffset.set(iw, 0).rotate(PI_1_2 + step * s).multiply(elementScaleInv)
                    vec2.set(r, 0).rotate(PI_1_2 + step * s).sub(radiusOffset)
                        .add(x + r, y + height - r)
                    this.transform.xform(vec2, vec2)
                    vertices.push(
                        vec2.x, vec2.y,
                        1, 0,
                        color
                    )

                    if (s === segments) {
                        radiusOffset.set(ow, 0).rotate(Math.PI).multiply(elementScaleInv)
                        vec2.set(r, 0).rotate(Math.PI).add(radiusOffset)
                        vec2.set(r + ow, 0).rotate(Math.PI)
                            .add(x + r, y + height - r)
                        this.transform.xform(vec2, vec2)
                        vertices.push(
                            vec2.x, vec2.y,
                            0, 0,
                            color
                        )

                        radiusOffset.set(iw, 0).rotate(Math.PI).multiply(elementScaleInv)
                        vec2.set(r, 0).rotate(Math.PI).sub(radiusOffset)
                        vec2.set(r - iw, 0).rotate(Math.PI)
                            .add(x + r, y + height - r)
                        this.transform.xform(vec2, vec2)
                        vertices.push(
                            vec2.x, vec2.y,
                            0, 0,
                            color
                        )
                    }

                    indices.push(
                        vv,
                        vv + 1,
                        vv + 2,

                        vv + 2,
                        vv + 1,
                        vv + 3
                    )
                    vv += 2
                }
                vv += 2
            }
        }

        pushBrush()
        return this
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} height
     * @param {number} [cornerRadius]
     * @returns {this}
     */
    drawSolidRect(x, y, width, height, cornerRadius = 0) {
        if (cornerRadius >= width * 0.5 && cornerRadius >= height * 0.5) {
            this.drawSolidEllipse(x, y, width, height, false)
            return this
        }

        const { vertices, indices } = getCommand(this)

        const color = packColor32F(this.fill.color, this.fill.alpha * this.globalAlpha)

        if (cornerRadius === 0) {
            pushQuad(vertices, indices,
                x, y,
                x + width, y,
                x, y + height,
                x + width, y + height,
                color,
                this.transform
            )
        } else {
            const width2 = width * 0.5
            const height2 = height * 0.5

            if (cornerRadius >= width2) {
                /* standing capsule */

                // top round cap
                pushArc(vertices, indices,
                    width2,
                    x + width2, y + width2,
                    x + width, y + width2,
                    x, y + width2,
                    color,
                    this.transform
                )

                // rectangle
                pushQuad(vertices, indices,
                    x, y + width2,
                    x + width, y + width2,
                    x, y + height - width2,
                    x + width, y + height - width2,
                    color,
                    this.transform
                )

                // bottom round cap
                pushArc(vertices, indices,
                    width2,
                    x + width2, y + height - width2,
                    x, y + height - width2,
                    x + width, y + height - width2,
                    color,
                    this.transform
                )
            } else if (cornerRadius >= height * 0.5) {
                /* sleeping capsule */

                // left round cap
                pushArc(vertices, indices,
                    height2,
                    x + height2, y + height2,
                    x + height2, y,
                    x + height2, y + height,
                    color,
                    this.transform
                )

                // rectangle
                pushQuad(vertices, indices,
                    x + height2, y,
                    x + width - height2, y,
                    x + height2, y + height,
                    x + width - height2, y + height,
                    color,
                    this.transform
                )

                // right round cap
                pushArc(vertices, indices,
                    height2,
                    x + width - height2, y + height2,
                    x + width - height2, y + height,
                    x + width - height2, y,
                    color,
                    this.transform
                )
            } else {
                // center rectangle
                pushQuad(vertices, indices,
                    x + cornerRadius, y,
                    x + (width - cornerRadius), y,
                    x + cornerRadius, y + height,
                    x + (width - cornerRadius), y + height,
                    color,
                    this.transform
                )
                // left small rectangle
                pushQuad(vertices, indices,
                    x, y + cornerRadius,
                    x + cornerRadius, y + cornerRadius,
                    x, y + height - cornerRadius,
                    x + cornerRadius, y + height - cornerRadius,
                    color,
                    this.transform
                )
                // right small rectangle
                pushQuad(vertices, indices,
                    x + (width - cornerRadius), y + cornerRadius,
                    x + width, y + cornerRadius,
                    x + (width - cornerRadius), y + height - cornerRadius,
                    x + width, y + height - cornerRadius,
                    color,
                    this.transform
                )

                // top left corner
                pushArc(vertices, indices,
                    cornerRadius,
                    x + cornerRadius, y + cornerRadius,
                    x + cornerRadius, y,
                    x, y + cornerRadius,
                    color,
                    this.transform
                )
                // top right corner
                pushArc(vertices, indices,
                    cornerRadius,
                    x + width - cornerRadius, y + cornerRadius,
                    x + width, y + cornerRadius,
                    x + width - cornerRadius, y,
                    color,
                    this.transform
                )
                // bottom left corner
                pushArc(vertices, indices,
                    cornerRadius,
                    x + cornerRadius, y + height - cornerRadius,
                    x, y + height - cornerRadius,
                    x + cornerRadius, y + height,
                    color,
                    this.transform
                )
                // bottom right corner
                pushArc(vertices, indices,
                    cornerRadius,
                    x + width - cornerRadius, y + height - cornerRadius,
                    x + width - cornerRadius, y + height,
                    x + width, y + height - cornerRadius,
                    color,
                    this.transform
                )
            }
        }

        pushBrush()
        return this
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} [height=width]
     * @param {boolean} [center=true]
     * @param {boolean} [isSizeInScreenSpace=false]
     * @returns {this}
     */
    drawEllipse(x, y, width, height = width, center = true, isSizeInScreenSpace = false) {
        const align = 0.5

        let r0 = width * 0.5
        let r1 = height * 0.5

        if (isSizeInScreenSpace) {
            r0 *= this.viewport.pixelRatio
            r1 *= this.viewport.pixelRatio
        }

        let _x
        let _y
        if (center) {
            _x = x
            _y = y
        } else {
            _x = x + r0
            _y = y + r1
        }

        const outerWidth = this.stroke.width * align
        const innerWidth = this.stroke.width * (1 - align)

        const { vertices, indices } = getCommand(this)
        const v = (vertices.length * 0.2) | 0

        const color = packColor32F(this.stroke.color, this.stroke.alpha * this.globalAlpha)

        // world center position
        const c = this.transform.xform(vec2.set(_x, _y))
        const screenR0 = isSizeInScreenSpace ? r0 : this.transform.xform(vec2.set(_x + r0, _y), vec2).distance_to(c)
        const screenR1 = isSizeInScreenSpace ? r1 : this.transform.xform(vec2.set(_x, _y + r1), vec2).distance_to(c)

        const extent = Math.max(screenR0, screenR1) * 5
        const steps = Math.max(MIN_STEPS_FOR_CIRCLE, Math.ceil(extent / (200 + extent) * MAX_STEPS_FOR_CIRCLE))

        const angle = PI2 / steps
        const rotation = this.transform.get_rotation()

        // outer circle
        for (let s = 0; s < steps; s++) {
            if (isSizeInScreenSpace) {
                this.transform.xform(vec2.set(_x, _y), vec2)
                    .add(
                        Math.cos(angle * s) * (r0 + outerWidth),
                        Math.sin(angle * s) * (r1 + outerWidth)
                    )
            } else {
                this.transform.xform(vec2.set(
                    _x + Math.cos(angle * s) * r0,
                    _y + Math.sin(angle * s) * r1
                ), vec2)

                vec2.add(
                    Math.cos(angle * s + rotation) * outerWidth,
                    Math.sin(angle * s + rotation) * outerWidth
                )
            }

            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )
        }

        const genInnerCircle = screenR0 > innerWidth && screenR1 > innerWidth

        if (genInnerCircle) {
            // inner circle
            for (let s = 0; s < steps; s++) {
                if (isSizeInScreenSpace) {
                    this.transform.xform(vec2.set(_x, _y), vec2)
                        .add(
                            Math.cos(angle * s) * (r0 - innerWidth),
                            Math.sin(angle * s) * (r1 - innerWidth)
                        )
                } else {
                    this.transform.xform(vec2.set(
                        _x + Math.cos(angle * s) * r0,
                        _y + Math.sin(angle * s) * r1
                    ), vec2)

                    vec2.sub(
                        Math.cos(angle * s + rotation) * innerWidth,
                        Math.sin(angle * s + rotation) * innerWidth
                    )
                }

                vertices.push(
                    vec2.x, vec2.y,
                    1, 0,
                    color
                )
            }

            for (let s = 0, s1 = 0; s < steps; s++) {
                s1 = (s === steps - 1) ? 0 : (s + 1)

                indices.push(
                    v + s,
                    v + s1,
                    v + s1 + steps,

                    v + s,
                    v + s1 + steps,
                    v + s + steps
                )
            }
        } else {
            for (let s = 0; s < steps - 2; s++) {
                indices.push(
                    v + 0,
                    v + s + 1,
                    v + s + 2
                )
            }
        }

        pushBrush()
        return this
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} [height=width]
     * @param {boolean} [center=true]
     * @param {boolean} [isSizeInScreenSpace=false]
     * @param {string} shadowImageSrc
     * @returns {this}
     */
    drawEllipseShadow(x, y, width, height = width, center = true, isSizeInScreenSpace = false, shadowImageSrc = null) {
        const align = 0.5

        let r0 = width * 0.5
        let r1 = height * 0.5

        if (isSizeInScreenSpace) {
            r0 *= this.viewport.pixelRatio
            r1 *= this.viewport.pixelRatio
        }

        let _x
        let _y
        if (center) {
            _x = x
            _y = y
        } else {
            _x = x + r0
            _y = y + r1
        }

        const outerWidth = this.stroke.width * align
        const innerWidth = this.stroke.width * (1 - align)

        let shadowImage = null
        let shadowImageInfo = null
        if (shadowImageSrc) {
            const shadowAtlas = loadImage(shadowImageSrc)
            shadowImageInfo = shadowAtlas.imageInfo
            if (shadowAtlas && shadowImageInfo) {
                shadowImage = shadowAtlas.image
            }
        }

        const { vertices, indices } = getCommand(this, 1, shadowImage)
        const v = (vertices.length * 0.2) | 0

        const color = packColor32F(this.stroke.color, this.stroke.alpha * this.globalAlpha)

        // world center position
        const c = this.transform.xform(vec2.set(_x, _y))
        const screenR0 = isSizeInScreenSpace ? r0 : this.transform.xform(vec2.set(_x + r0, _y), vec2).distance_to(c)
        const screenR1 = isSizeInScreenSpace ? r1 : this.transform.xform(vec2.set(_x, _y + r1), vec2).distance_to(c)

        const extent = Math.max(screenR0, screenR1) * 5
        const steps = Math.max(MIN_STEPS_FOR_CIRCLE, Math.ceil(extent / (200 + extent) * MAX_STEPS_FOR_CIRCLE))

        const angle = PI2 / steps
        const rotation = this.transform.get_rotation()

        // outer circle
        for (let s = 0; s < steps + 1; s++) {

            if (isSizeInScreenSpace) {
                this.transform.xform(vec2.set(_x, _y), vec2)
                    .add(
                        Math.cos(angle * s) * (r0 + outerWidth),
                        Math.sin(angle * s) * (r1 + outerWidth)
                    )
            } else {
                this.transform.xform(vec2.set(
                    _x + Math.cos(angle * s) * r0,
                    _y + Math.sin(angle * s) * r1
                ), vec2)

                vec2.add(
                    Math.cos(angle * s + rotation) * outerWidth,
                    Math.sin(angle * s + rotation) * outerWidth
                )
            }

            const vv = s % 2 === 0 ? 1 : 0
            vertices.push(
                vec2.x, vec2.y,
                1 * vv, 0,
                color
            )
        }

        const genInnerCircle = screenR0 > innerWidth && screenR1 > innerWidth

        if (genInnerCircle) {
            // inner circle
            for (let s = 0; s < steps + 1; s++) {
                if (isSizeInScreenSpace) {
                    this.transform.xform(vec2.set(_x, _y), vec2)
                        .add(
                            Math.cos(angle * s) * (r0 - innerWidth),
                            Math.sin(angle * s) * (r1 - innerWidth)
                        )
                } else {
                    this.transform.xform(vec2.set(
                        _x + Math.cos(angle * s) * r0,
                        _y + Math.sin(angle * s) * r1
                    ), vec2)

                    vec2.sub(
                        Math.cos(angle * s + rotation) * innerWidth,
                        Math.sin(angle * s + rotation) * innerWidth
                    )
                }

                const uu = s % 2 === 1 ? 0 : 1
                vertices.push(
                    vec2.x, vec2.y,
                    1 * uu, 1,
                    color
                )
            }

            for (let s = 0, s1 = 0; s < steps; s++) {
                s1 = s + 1
                indices.push(
                    v + s,
                    v + s1 + steps + 1,
                    v + s + steps + 1,

                    v + s,
                    v + s1 + steps + 1,
                    v + s1,
                )
            }
        }

        pushBrush()
        return this
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} [height=width]
     * @param {boolean} [center=true]
     * @param {boolean} [isSizeInScreenSpace=false]
     * @returns {this}
     */
    drawSolidEllipse(x, y, width, height = width, center = true, isSizeInScreenSpace = false) {
        let r0 = width * 0.5
        let r1 = height * 0.5

        if (isSizeInScreenSpace) {
            r0 *= this.viewport.pixelRatio
            r1 *= this.viewport.pixelRatio
        }

        let _x
        let _y
        if (center) {
            _x = x
            _y = y
        } else {
            _x = x + r0
            _y = y + r1
        }

        const { vertices, indices } = getCommand(this)
        const v = (vertices.length * 0.2) | 0

        const color = packColor32F(this.fill.color, this.fill.alpha * this.globalAlpha)

        const c = this.transform.xform(vec2.set(_x, _y))
        const screenR0 = isSizeInScreenSpace ? r0 : this.transform.xform(vec2.set(_x + r0, _y), vec2).distance_to(c)
        const screenR1 = isSizeInScreenSpace ? r1 : this.transform.xform(vec2.set(_x, _y + r1), vec2).distance_to(c)

        const extent = Math.max(screenR0, screenR1) * 5
        const steps = Math.max(MIN_STEPS_FOR_CIRCLE, Math.ceil(extent / (200 + extent) * MAX_STEPS_FOR_CIRCLE))

        const angle = PI2 / steps
        for (let s = 0; s < steps; s++) {
            if (isSizeInScreenSpace) {
                this.transform.xform(vec2.set(_x, _y), vec2)
                    .add(
                        Math.cos(angle * s) * r0,
                        Math.sin(angle * s) * r1
                    )
            } else {
                this.transform.xform(vec2.set(
                    _x + Math.cos(angle * s) * r0,
                    _y + Math.sin(angle * s) * r1
                ), vec2)
            }

            vertices.push(
                vec2.x, vec2.y,
                1, 0,
                color
            )
        }

        for (let s = 0; s < steps - 2; s++) {
            indices.push(
                v + 0,
                v + s + 1,
                v + s + 2
            )
        }

        pushBrush()
        return this
    }

    /**
     * Subpath with same first and last point will be considered as "closed",
     * so it won't have line caps
     * @param {number} x
     * @param {number} y
     * @param {PathData} path
     * @param {"butt"|"round"|"square"} [lineCap]
     * @param {"bevel"|"miter"|"round"} [lineJoin]
     * @param {number} [miterLimit]
     * @returns {this}
     */
    drawPath(x, y, path, lineCap = "butt", lineJoin = "miter", miterLimit = 10) {
        const width2 = this.stroke.width * 0.5
        const addCaps = this.stroke.width > 2 && (lineCap === "round" || lineCap === "square")

        const addMiter = (lineJoin === "miter")
        const _miterLimit = miterLimit * width2

        const { vertices, indices } = getCommand(this)

        const color = packColor32F(this.stroke.color, this.stroke.alpha * this.globalAlpha)

        transform
            .reset()
            .translate(x, y)
            .prepend(this.transform)

        subdividePath(path, transform)

        current.set(0, 0)
        next.set(0, 0)
        firstMiter1.set(0, 0)
        firstMiter2.set(0, 0)
        miter11.set(0, 0)
        miter12.set(0, 0)
        miter21.set(0, 0)
        miter22.set(0, 0)
        currentEdge.set(0, 0)
        currentExt.set(0, 0)
        nextEdge.set(0, 0)
        nextExt.set(0, 0)
        vertex.set(0, 0)

        /** @type {Vector2} */
        let transCurrent = null
        /** @type {Vector2} */
        let transNext = null

        for (let sp = 0; sp < pathArrayInfo.currPathIndex; sp++) {
            const path = pathArrayInfo.paths[sp]
            const pathLength = pathArrayInfo.pathLengths[sp]
            const pathSize = pathLength * 0.5

            const subPathIsClosed = Num.Equal(Math.hypot(path[0] - path[pathLength - 2], path[1] - path[pathLength - 1]), 0)
            let ignoreFirstSegment = addMiter && subPathIsClosed
            let firstInSubPath = true
            let miterLimitExceeded = false, firstMiterLimitExceeded = false

            transCurrent = null
            transNext = null

            if (addMiter && subPathIsClosed) {
                transNext = new Vector2(path[pathLength - 4], path[pathLength - 3])
                next.copy(transNext)
            }

            for (let pathIdx = 0; pathIdx < pathSize; pathIdx++) {
                vertex.set(path[pathIdx * 2], path[pathIdx * 2 + 1])

                transCurrent = transNext
                transNext = vertex

                current.copy(next)
                next.copy(transNext)

                if (!transCurrent) continue

                currentEdge.copy(nextEdge)
                currentExt.copy(nextExt)
                nextEdge.copy(next).sub(current).normalize()
                nextExt.set(-nextEdge.y * width2, nextEdge.x * width2)

                if (firstInSubPath) {
                    miter21.copy(current).add(nextExt)
                    miter22.copy(current).sub(nextExt)
                    firstMiter1.copy(miter21)
                    firstMiter2.copy(miter22)
                    firstInSubPath = false

                    // start cap
                    if (addCaps && !subPathIsClosed) {
                        capExt.set(-nextExt.y, nextExt.x)
                        cap11.copy(miter21).add(capExt)
                        cap12.copy(miter22).add(capExt)

                        if (lineCap === "square") {
                            pushQuad(vertices, indices,
                                cap11.x, cap11.y,
                                cap12.x, cap12.y,
                                miter21.x, miter21.y,
                                miter22.x, miter22.y,
                                color
                            )
                        } else {
                            pushArc(vertices, indices,
                                width2,
                                current.x, current.y,
                                miter22.x, miter22.y,
                                miter21.x, miter21.y,
                                color
                            )
                        }
                    }

                    continue
                }

                miter11.copy(miter21)
                miter12.copy(miter22)

                let miterAdded = false
                if (addMiter) {
                    miterEdge.copy(currentEdge).add(nextEdge)
                    const miterExt = (1 / miterEdge.dot(miterEdge)) * width2 * 2

                    if (miterExt < _miterLimit) {
                        miterEdge.scale(miterExt)
                        miter21.set(current.x - miterEdge.y, current.y + miterEdge.x)
                        miter22.set(current.x + miterEdge.y, current.y - miterEdge.x)

                        miterAdded = true
                        miterLimitExceeded = false
                    } else {
                        miterLimitExceeded = true
                    }
                }

                if (!miterAdded) {
                    miter21.copy(current).add(currentExt)
                    miter22.copy(current).sub(currentExt)
                }

                if (ignoreFirstSegment) {
                    firstMiter1.copy(miter21)
                    firstMiter2.copy(miter22)
                    if (!miterAdded) {
                        miter21.copy(firstMiter2)
                        miter22.copy(firstMiter1)
                    }
                    firstMiterLimitExceeded = miterLimitExceeded
                    ignoreFirstSegment = false
                    continue
                }

                if (!addMiter || miterLimitExceeded) {
                    prev.copy(current).sub(currentEdge)
                    let d1 = 0, d2 = 0

                    d1 = distanceToLineSegmentSquared(miter21.x, miter21.y, current.x, current.y, next.x, next.y)
                    d2 = distanceToLineSegmentSquared(miter22.x, miter22.y, current.x, current.y, next.x, next.y)
                    p1.copy((d1 > d2) ? miter21 : miter22)

                    vec2.copy(current).add(nextExt)
                    d1 = distanceToLineSegmentSquared(vec2.x, vec2.y, current.x, current.y, prev.x, prev.y)
                    vec2.copy(current).sub(nextExt)
                    d2 = distanceToLineSegmentSquared(vec2.x, vec2.y, current.x, current.y, prev.x, prev.y)
                    if (d1 > d2) {
                        p2.copy(current).add(nextExt)
                    } else {
                        p2.copy(current).sub(nextExt)
                    }

                    if (lineJoin === "round") {
                        pushArc(vertices, indices,
                            width2,
                            current.x, current.y,
                            p1.x, p1.y,
                            p2.x, p2.y,
                            color
                        )
                    } else {
                        pushTri(vertices, indices,
                            p1.x, p1.y,
                            current.x, current.y,
                            p2.x, p2.y,
                            color
                        )
                    }
                }
                pushQuad(vertices, indices,
                    miter11.x, miter11.y,
                    miter12.x, miter12.y,
                    miter21.x, miter21.y,
                    miter22.x, miter22.y,
                    color
                )

                if (!miterAdded) {
                    miter21.copy(current).add(nextExt)
                    miter22.copy(current).sub(nextExt)
                }
            }

            // last segment
            if (!firstMiterLimitExceeded && addMiter && subPathIsClosed) {
                miter11.copy(firstMiter1)
                miter12.copy(firstMiter2)
            } else {
                untransformedBack.set(path[pathLength - 2], path[pathLength - 1])
                miter11.copy(untransformedBack).add(nextExt)
                miter12.copy(untransformedBack).sub(nextExt)
            }

            if ((!addMiter || firstMiterLimitExceeded) && subPathIsClosed) {
                let d1 = 0, d2 = 0
                firstNormal.copy(firstMiter1).sub(firstMiter2)
                second.copy(next).add(firstNormal.y, -firstNormal.x)

                d1 = distanceToLineSegmentSquared(miter12.x, miter12.y, next.x, next.y, second.x, second.y)
                d2 = distanceToLineSegmentSquared(miter11.x, miter11.y, next.x, next.y, second.x, second.y)
                p2.copy((d1 > d2) ? miter12 : miter11)

                d1 = distanceToLineSegmentSquared(firstMiter1.x, firstMiter1.y, current.x, current.y, next.x, next.y)
                d2 = distanceToLineSegmentSquared(firstMiter2.x, firstMiter2.y, current.x, current.y, next.x, next.y)
                p1.copy((d1 > d2) ? firstMiter1 : firstMiter2)

                if (lineJoin === "round") {
                    pushArc(vertices, indices,
                        width2,
                        next.x, next.y,
                        p1.x, p1.y,
                        p2.x, p2.y,
                        color
                    )
                } else {
                    pushTri(vertices, indices,
                        p1.x, p1.y,
                        next.x, next.y,
                        p2.x, p2.y,
                        color
                    )
                }
            }

            pushQuad(vertices, indices,
                miter11.x, miter11.y,
                miter12.x, miter12.y,
                miter21.x, miter21.y,
                miter22.x, miter22.y,
                color
            )

            // end cap
            if (addCaps && !subPathIsClosed) {
                if (lineCap === "square") {
                    capExt.set(nextExt.y, -nextExt.x)
                    cap11.copy(miter11).add(capExt)
                    cap12.copy(miter12).add(capExt)

                    pushQuad(vertices, indices,
                        cap11.x, cap11.y,
                        cap12.x, cap12.y,
                        miter11.x, miter11.y,
                        miter12.x, miter12.y,
                        color
                    )
                } else {
                    pushArc(vertices, indices,
                        width2,
                        next.x, next.y,
                        miter11.x, miter11.y,
                        miter12.x, miter12.y,
                        color
                    )
                }
            }
        }

        pushBrush()
        return this
    }

    /**
     * @param {string} text
     * @param {string} fontFamily
     * @param {number} fontSize
     * @param {number} fontWeight
     * @param {number} letterSpacing
     * @returns
     */
    measureText(text, fontFamily, fontSize, fontWeight = 400, letterSpacing = 0) {
        let textW = (text.length-1) * letterSpacing
        let textH = 0
        let imageInfo
        for (let i = 0; i < text.length; i++) {
            const char = text[i]
            // TODO: Apply fill color to text atlas
            // TODO: Check our renderer if it support alpha for atlas
            const atlas = loadChar(char, fontFamily, fontSize, fontWeight)
            imageInfo = atlas.imageInfo
            textW += imageInfo.w / imageInfo.pixelRatio
        }
        if (text.length === 0) {
            const char = ''
            // TODO: Apply fill color to text atlas
            // TODO: Check our renderer if it support alpha for atlas
            const atlas = loadChar(char, fontFamily, fontSize, fontWeight)
            imageInfo = atlas.imageInfo
            textW += imageInfo.w / imageInfo.pixelRatio
        }
        textH = imageInfo.h / imageInfo.pixelRatio
        return [textW, textH]
    }
    /**
     * @param {number} x
     * @param {number} y
     * @param {string} text
     * @param {string} fontFamily
     * @param {number} fontSize
     * @param {"left"|"center"|"right"} align
     * @param {"top"|"center"|"bottom"} valign
     * @param {object} option
     * @returns {this}
     */
    drawText(x, y, text, fontFamily, fontSize, align = "left", valign = "top", option = null) {
        const atlases = []
        const letterSpacing = (option && option.letterSpacing) ? option.letterSpacing : 0
        let textW = 0, textH = 0, imageInfo
        let dotImageAtlas = null
        let fontWeight = undefined
        if (option) {
            fontWeight = option.fontWeight ?? fontWeight
            if (option.limitLength) {
                dotImageAtlas = loadChar('.', fontFamily, fontSize, option.fontWeight)
            }
        }
        for (let i = 0; i < text.length; i++) {
            const char = text[i]
            // TODO: Apply fill color to text atlas
            // TODO: Check our renderer if it support alpha for atlas
            atlases.push(loadChar(char, fontFamily, fontSize, fontWeight))
            imageInfo = atlases[atlases.length - 1].imageInfo
            textW += imageInfo.w / imageInfo.pixelRatio
            if (option && option.limitLength && (textW > option.limitLength)) {
                while (textW > (option.limitLength - dotImageAtlas.imageInfo.w * 3 / dotImageAtlas.imageInfo.pixelRatio)) {
                    const lastAtlasImageInfo = atlases.pop().imageInfo
                    const lastCharLength = lastAtlasImageInfo.w / lastAtlasImageInfo.pixelRatio
                    textW -= lastCharLength
                }
                for (let j = 0; j < 3; j++) {
                    textW += dotImageAtlas.imageInfo.w / dotImageAtlas.imageInfo.pixelRatio
                    atlases.push(dotImageAtlas)
                }
                break
            }
            if (i <= text.length - 1) textW += letterSpacing
        }

        if (text.length === 0) {
            const char = ''
            // TODO: Apply fill color to text atlas
            // TODO: Check our renderer if it support alpha for atlas
            atlases.push(loadChar(char, fontFamily, fontSize, fontWeight))
            imageInfo = atlases[atlases.length - 1].imageInfo
        }
        textH = imageInfo.h / imageInfo.pixelRatio

        let offsetX = 0
        if (align === "center") {
            offsetX = -0.5 * textW
        } else if (align === "right") {
            offsetX = -1 * textW
        }

        let offsetY = 0
        if (valign === "center") {
            offsetY = -0.5 * textH
        } else if (valign === "bottom") {
            offsetY = -1 * textH
        }

        let dx = 0
        for (let i = 0; i < atlases.length; i++) {
            const imageAtlas = atlases[i]
            if (imageAtlas && imageAtlas.valid) {
                const imageInfo = imageAtlas.imageInfo
                this.drawImage(
                    imageAtlas.image,
                    x + dx + i * letterSpacing + offsetX, y + offsetY,
                    imageInfo.w / imageInfo.pixelRatio, imageInfo.h / imageInfo.pixelRatio
                )

                dx += imageInfo.w / imageInfo.pixelRatio
            }
        }

        return this
    }

    /**
     * @param {number} id
     * @param {number} dx
     * @param {number} dy
     * @param {number} dw
     * @param {number} dh
     * @param {boolean} [flipY=false]
     * @returns {this}
     */
    drawImage(id, dx, dy, dw, dh, flipY = false) {
        const { vertices, indices } = getCommand(this, 3, id)
        const v = (vertices.length * 0.2) | 0

        const color = packColor32F(this.fill.color, this.fill.alpha * this.globalAlpha)
        const u0 = 0
        const u1 = 1
        let v0 = 0
        let v1 = 1
        if (flipY) {
            const v = v0
            v0 = v1
            v1 = v
        }

        this.transform.xform(vec2.set(dx, dy), vec2)
        vertices.push(
            vec2.x, vec2.y,
            u0, v0,
            color
        )

        this.transform.xform(vec2.set(dx + dw, dy), vec2)
        vertices.push(
            vec2.x, vec2.y,
            u1, v0,
            color
        )

        this.transform.xform(vec2.set(dx + dw, dy + dh), vec2)
        vertices.push(
            vec2.x, vec2.y,
            u1, v1,
            color
        )

        this.transform.xform(vec2.set(dx, dy + dh), vec2)
        vertices.push(
            vec2.x, vec2.y,
            u0, v1,
            color
        )

        indices.push(
            v + 0,
            v + 1,
            v + 2,
            v + 0,
            v + 2,
            v + 3
        )

        pushBrush()
        return this
    }

    /**
     * @param {string} imageSrc
     * @param {number} dx display posX
     * @param {number} dy display posY
     * @param {number} ratioX image size ratio
     * @param {number} ratioY image size ratio
     * @param {bool} centered location of anchor point
     * @param centeredX
     * @param centeredY
     * @returns {this}
     */
    drawImageFromAtlas(imageSrc, dx, dy, ratioX, ratioY, centeredX = true, centeredY = true) {
        const imageAtlas = loadImage(imageSrc)
        if (!imageAtlas || !imageAtlas.valid) {
            console.log(`image [${imageSrc}] not loaded into atlas yet`)
            return this
        }
        const imageInfo = imageAtlas.imageInfo
        let x = dx - imageInfo.w * 0.5 * ratioX / imageInfo.pixelRatio
        let y = dy - imageInfo.h * 0.5 * ratioY / imageInfo.pixelRatio
        const w = imageInfo.w * ratioX / imageInfo.pixelRatio
        const h =  imageInfo.h * ratioY / imageInfo.pixelRatio
        if (!centeredX){
            x = dx
        }
        if (!centeredY){
            y = dy
        }
        this.drawImage(imageAtlas.image, x, y, w, h)
        return this
    }

    drawSolidRectTexture(x, y, width, height, cornerRadius = 0) {
        const src = `solid-rect-${width}-${height}-${cornerRadius}`
        if (!imageAtlases.has(src)) {
            /** @type {ImageAtlas} */
            const imageAtlas = {
                image: null,
                valid: false,
                imageInfo: {
                    x: 0, y: 0,
                    w: 0, h: 0,
                    pixelRatio: _viewport.pixelRatio * 2
                },
            }

            ctx.clearRect(0, 0, canvas.width, canvas.height)

            const pixelRatio = _viewport.pixelRatio * 2
            canvas.width = Math.max(width * pixelRatio, 1)
            canvas.height = Math.max(height * pixelRatio, 1)
            ctx.scale(pixelRatio, pixelRatio)
            // ctx.shadowColor = "rgba(0, 0, 0, .35)"//option.shadow.color || "rgba(1, 0, 0, .25)"
            // ctx.shadowBlur = 3//option.shadow.shadowBlur || 4
            // ctx.shadowOffsetX = 0//option.shadow.shadowOffsetX || 0
            // ctx.shadowOffsetY = 1// option.shadow.shadowOffsetY || 4

            ctx.beginPath()
            ctx.roundRect(0, 0, width, height, [cornerRadius])
            ctx.fillStyle = "white"
            ctx.fill()

            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
            const pixels = imageData.data

            const ptr = allocTempUint8Array(pixels)
            if (ptr) {
                const id = WASM().makeBrushImage(ptr, pixels.length, canvas.width, canvas.height)
                imageAtlas.image = id
                imageAtlas.valid = true
            }
            imageAtlas.imageInfo.x = 0
            imageAtlas.imageInfo.y = 0
            imageAtlas.imageInfo.w = canvas.width
            imageAtlas.imageInfo.h = canvas.height
            imageAtlas.imageInfo.pixelRatio = pixelRatio

            imageAtlases.set(src, imageAtlas)
        }
        const imageAtlas = imageAtlases.get(src)
        this.drawImage(
            imageAtlas.image,
            x, y , imageAtlas.imageInfo.w / imageAtlas.imageInfo.pixelRatio, imageAtlas.imageInfo.h / imageAtlas.imageInfo.pixelRatio
        )
        return this
    }
    drawRectTexture(x, y, width, height, lineWidth, cornerRadius = 0, style = 'center') {
        const src = `rect-${width}-${height}-${lineWidth}-${cornerRadius}-${style}`
        if (!imageAtlases.has(src)) {
            /** @type {ImageAtlas} */
            const imageAtlas = {
                image: null,
                valid: false,
                imageInfo: {
                    x: 0, y: 0,
                    w: 0, h: 0,
                    pixelRatio: _viewport.pixelRatio
                },
            }

            ctx.clearRect(0, 0, canvas.width, canvas.height)

            const pixelRatio = _viewport.pixelRatio
            canvas.width = Math.max(width * pixelRatio, 1)
            canvas.height = Math.max(height * pixelRatio, 1)
            ctx.scale(pixelRatio, pixelRatio)

            if (style === 'center') {
                ctx.lineWidth = lineWidth
                ctx.roundRect(0, 0, width, height, [cornerRadius])
                ctx.strokeStyle = "white"
                ctx.stroke()
            } else if(style === 'inner') {
                ctx.fillStyle = "white"
                ctx.roundRect(0, 0, width, height, [cornerRadius])
                const corner = cornerRadius - lineWidth < 0 ? 0 : cornerRadius - lineWidth
                ctx.roundRect(lineWidth, lineWidth, width - lineWidth * 2, height - lineWidth * 2, [corner])
                ctx.fill("evenodd")
            }

            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
            const pixels = imageData.data

            const ptr = allocTempUint8Array(pixels)
            if (ptr) {
                const id = WASM().makeBrushImage(ptr, pixels.length, canvas.width, canvas.height)
                imageAtlas.image = id
                imageAtlas.valid = true
            }
            imageAtlas.imageInfo.x = 0
            imageAtlas.imageInfo.y = 0
            imageAtlas.imageInfo.w = canvas.width
            imageAtlas.imageInfo.h = canvas.height
            imageAtlas.imageInfo.pixelRatio = pixelRatio

            imageAtlases.set(src, imageAtlas)
        }
        const imageAtlas = imageAtlases.get(src)
        this.drawImage(
            imageAtlas.image,
            x, y , imageAtlas.imageInfo.w / imageAtlas.imageInfo.pixelRatio, imageAtlas.imageInfo.h / imageAtlas.imageInfo.pixelRatio
        )
        return this
    }
}

export class Overlay {
    /**
     * @param {Gfx} gfx
     * @param {VisualStorage} storage
     * @param {Viewport} viewport
     */
    constructor(gfx, storage, viewport) {
        this.gfx = gfx
        this.storage = storage
        this.viewport = viewport
        _viewport = viewport

        /** @type {Pane[]} */
        this.panes = []

        this.viewport.on('resize', () => {
            // this.setSize(this.viewport.realWidth, this.viewport.realHeight)
        })
    }

    /**
     * @param {number} index
     * @returns {Pane}
     */
    createPane(index) {
        const pane = new Pane(this.viewport)
        pane.layer_id = WASM().createLayer()
        this.panes[index] = pane
        return pane
    }

    /**
     * @param {number} index
     */
    destroyPane(index) {
        const pane = this.panes[index]
        if (!pane) return

        this.panes[index] = null
    }

    /**
     * @param {number} w
     * @param {number} h
     */
    setSize(w, h) {
        // viewport transform
        xform.identity()
            .scale(2 / w, -2 / h)
            .translate(-1, 1)
    }

    /**
     * Clear all exising panes
     */
    clearPanes() {
        for (const pane of this.panes) {
            pane.clear()
        }
    }

    /**
     * Clear the whole overlay, all panes will be freed
     */
    clear() {
        this.panes.length = 0
    }
}

/* implementation */

/**
 * @param {number} rgb
 * @param {number} alpha
 * @returns {number}
 */
function packColor32F(rgb, alpha) {
    if (alpha === 0) return 0

    return pack_color_u(
        ((rgb >> 16) & 0xFF),
        ((rgb >> 8) & 0xFF),
        ((rgb & 0xFF)),
        (alpha * 255) | 0
    )
}

const vec2 = new Vector2()
const xform = new Transform2D()

const transform = new Transform2D()

// path rendering

const PATH_RECURSION_LIMIT = 13
const PATH_DISTANCE_EPSILON = 0.7
const PATH_COLLINEARITY_EPSILON = 1e-5

/**
 * @param {PathData} path
 * @param {Transform2D} transform
 */
function subdividePath(path, transform) {
    pathArrayInfo.currPathIndex = 0
    pathArrayInfo.pathLengths[pathArrayInfo.currPathIndex] = 0

    let i = 0
    for (let c = 0; c < path.commands.length; c++) {
        switch (path.commands[c]) {
            // M
            case 1: {
                const x = path.vertices[i++]
                const y = path.vertices[i++]

                endSubPath()
                transform.xform(currentPos.set(x, y), currentPos)
                addVertexToCurrentPath(currentPos.x, currentPos.y, false)
            } break
            // L
            case 2: {
                const x = path.vertices[i++]
                const y = path.vertices[i++]

                transform.xform(currentPos.set(x, y), currentPos)
                addVertexToCurrentPath(currentPos.x, currentPos.y, false)
            } break
            // Q
            case 3: {
                const cpx = path.vertices[i++]
                const cpy = path.vertices[i++]
                const x = path.vertices[i++]
                const y = path.vertices[i++]

                transform.get_scale(scale)
                distanceTolerance = PATH_DISTANCE_EPSILON / scale.x
                distanceTolerance *= distanceTolerance

                transform.xform(cp.set(cpx, cpy), cp)
                transform.xform(p.set(x, y), p)

                if (checkGeometryCoverViewport(currentPos.x, currentPos.y, cp1.x, cp1.y, p.x, p.y)) {
                    createQuadBezierPath(currentPos.x, currentPos.y, cp.x, cp.y, p.x, p.y, 0)
                }
                currentPos.copy(p)
                addVertexToCurrentPath(currentPos.x, currentPos.y, true)
            } break
            // C
            case 4: {
                const cpx1 = path.vertices[i++]
                const cpy1 = path.vertices[i++]
                const cpx2 = path.vertices[i++]
                const cpy2 = path.vertices[i++]
                const x = path.vertices[i++]
                const y = path.vertices[i++]

                transform.get_scale(scale)
                distanceTolerance = PATH_DISTANCE_EPSILON / scale.x
                distanceTolerance *= distanceTolerance

                transform.xform(cp1.set(cpx1, cpy1), cp1)
                transform.xform(cp2.set(cpx2, cpy2), cp2)
                transform.xform(p.set(x, y), p)

                if (checkGeometryCoverViewport(currentPos.x, currentPos.y, cp1.x, cp1.y, p.x, p.y, cp2.x, cp2.y)) {
                    createCubicBezierPath(currentPos.x, currentPos.y, cp1.x, cp1.y, cp2.x, cp2.y, p.x, p.y, 0)
                }
                currentPos.copy(p)
                addVertexToCurrentPath(currentPos.x, currentPos.y, true)
            } break
        }
    }

    endSubPath()
}

const current = new Vector2()
const next = new Vector2()
const firstMiter1 = new Vector2()
const firstMiter2 = new Vector2()
const miter11 = new Vector2()
const miter12 = new Vector2()
const miter21 = new Vector2()
const miter22 = new Vector2()
const capExt = new Vector2()
const cap11 = new Vector2()
const cap12 = new Vector2()
const arcP1 = new Vector2()
const arcP2 = new Vector2()
const currentEdge = new Vector2()
const currentExt = new Vector2()
const nextEdge = new Vector2()
const nextExt = new Vector2()

const vertex = new Vector2()
const miterEdge = new Vector2()
const untransformedBack = new Vector2()
const firstNormal = new Vector2()
const second = new Vector2()
const scale = new Vector2()

const p = new Vector2()
const p1 = new Vector2()
const p2 = new Vector2()
const prev = new Vector2()
const cp = new Vector2()
const cp1 = new Vector2()
const cp2 = new Vector2()
const v1 = new Vector2()
const v2 = new Vector2()

const currentPos = new Vector2()

/**
 * [x,y, ...]
 * @type {number[]}
 */
let distanceTolerance = 1

function endSubPath() {
    if (pathArrayInfo.pathLengths[pathArrayInfo.currPathIndex] > 2){
        pathArrayInfo.currPathIndex++
        if(!pathArrayInfo.paths[pathArrayInfo.currPathIndex]) {
            pathArrayInfo.paths[pathArrayInfo.currPathIndex] = []
        }
    }
    pathArrayInfo.pathLengths[pathArrayInfo.currPathIndex] = 0
}

/** store curve segment paths' data */
const pathArrayInfo = {
    currPathIndex: 0,  // represent current generating path's index
    pathLengths: [0],  // record every path's length
    paths: [[]]        // subdivided path array
}
const lastPushed = new Vector2()

/**
 * @param {number} x
 * @param {number} y
 * @param {boolean} isCheckClip
 */
function addVertexToCurrentPath(x, y, isCheckClip = false) {
    if (x === lastPushed.x && y === lastPushed.y && pathArrayInfo.pathLengths[pathArrayInfo.currPathIndex] > 0) {
        return
    }
    if (isCheckClip) {
        if (containViewRect(lastPushed.x, lastPushed.y) || containViewRect(x, y) ) {
            if (pathArrayInfo.pathLengths[pathArrayInfo.currPathIndex] === 0) {
                pathArrayInfo.paths[pathArrayInfo.currPathIndex][pathArrayInfo.pathLengths[pathArrayInfo.currPathIndex]++] = lastPushed.x
                pathArrayInfo.paths[pathArrayInfo.currPathIndex][pathArrayInfo.pathLengths[pathArrayInfo.currPathIndex]++] = lastPushed.y
            }
            pathArrayInfo.paths[pathArrayInfo.currPathIndex][pathArrayInfo.pathLengths[pathArrayInfo.currPathIndex]++] = x
            pathArrayInfo.paths[pathArrayInfo.currPathIndex][pathArrayInfo.pathLengths[pathArrayInfo.currPathIndex]++] = y
        } else {
            endSubPath()
        }
    } else {
        pathArrayInfo.paths[pathArrayInfo.currPathIndex][pathArrayInfo.pathLengths[pathArrayInfo.currPathIndex]++] = x
        pathArrayInfo.paths[pathArrayInfo.currPathIndex][pathArrayInfo.pathLengths[pathArrayInfo.currPathIndex]++] = y
    }
    lastPushed.set(x, y)
}

/**
 * for storing line segments data of Bezier subdivision
 * @type {number[]}
 */
const subPathData1 = []
/**
 * for storing line segments data of Bezier subdivision
 * @type {number[]}
 */
const subPathData2 = []

/**
 * @param {number} x1 - x of point1
 * @param {number} y1 - y of point1
 * @param {number} x2 - x of control point
 * @param {number} y2 - y of control point
 * @param {number} x3 - x of point2
 * @param {number} y3 - y of point2
 * @param {number} level - segment subdivision level
 */
function createQuadBezierPath(x1, y1, x2, y2, x3, y3, level) {
    /**
     * The mid-points of the line segment
     */
    let x12 = 0  // x of mid-point of point1 and control point
    let y12 = 0  // y of mid-point of point1 and control point
    let x23 = 0  // x of mid-point of control point and point2
    let y23 = 0  // y of mid-point of control point and point2
    let x123 = 0 // x of mid-point of two mid-points(12, 23)
    let y123 = 0 // y of mid-point of two mid-points(12, 23)

    /**
     * for handling collinear case
     */
    let dx = 0
    let dy = 0
    let d = 0

    /**
     * represent line segment data
     */
    let dataX1 = 0 // x of point1
    let dataY1 = 0 // y of point1
    let dataX2 = 0 // x of control point
    let dataY2 = 0 // y of control point
    let dataX3 = 0 // x of point2
    let dataY3 = 0 // y of point2
    let subLevel = 0 // current segment subdivision level

    /**
     * index of the current line segment data
     */
    /**
     * subPathData1 will start from head of array
     */
    let subPathIndex1 = 0
    /**
     * subPathData2 will start from end of array
     */
    let subPathIndex2 = 0

    /**
     * the count of a set of line segment data
     */
    const segmentDataCount = 7

    // Push first line segment data
    subPathData1[0] = x1
    subPathData1[1] = y1
    subPathData1[2] = x2
    subPathData1[3] = y2
    subPathData1[4] = x3
    subPathData1[5] = y3
    subPathData1[6] = level

    while (subPathIndex1 !== segmentDataCount || subPathIndex2 !== 0) {
        // Handle first part of line segments
        while (subPathIndex1 !== segmentDataCount) {
            if (subPathData1[6] > PATH_RECURSION_LIMIT) {
                // remove the subDivision data which level is beyond the limit
                subPathIndex1 = segmentDataCount
                break
            }

            // set up line segment data
            dataX1 = subPathData1[0]
            dataY1 = subPathData1[1]
            dataX2 = subPathData1[2]
            dataY2 = subPathData1[3]
            dataX3 = subPathData1[4]
            dataY3 = subPathData1[5]
            subLevel = subPathData1[6]
            subPathIndex1 = segmentDataCount

            // calculate all the mid-points of the line segment
            x12 = (dataX1 + dataX2) * 0.5
            y12 = (dataY1 + dataY2) * 0.5
            x23 = (dataX2 + dataX3) * 0.5
            y23 = (dataY2 + dataY3) * 0.5
            x123 = (x12 + x23) * 0.5
            y123 = (y12 + y23) * 0.5

            // calculate collinear variable
            dx = dataX3 - dataX1
            dy = dataY3 - dataY1
            d = Math.abs(((dataX2 - dataX3) * dy - (dataY2 - dataY3) * dx))

            // Regular care
            if (d > PATH_COLLINEARITY_EPSILON) {
                if (d * d <= distanceTolerance * (dx * dx + dy * dy)) {
                    addVertexToCurrentPath(x123, y123, true)
                    break
                }
            } else {
                // Collinear case
                dx = x123 - (dataX1 + dataX3) * 0.5
                dy = y123 - (dataY1 + dataY3) * 0.5
                if (dx * dx + dy * dy <= distanceTolerance) {
                    addVertexToCurrentPath(x123, y123, true)
                    break
                }
            }

            // store data for subdivision front part
            subPathData1[0] = dataX1
            subPathData1[1] = dataY1
            subPathData1[2] = x12
            subPathData1[3] = y12
            subPathData1[4] = x123
            subPathData1[5] = y123
            subPathData1[6] = subLevel + 1
            // reset subPathIndex1
            subPathIndex1 = 0
            // store data for subdivision back part
            subPathData2[subPathIndex2] = x123
            subPathData2[subPathIndex2 + 1] = y123
            subPathData2[subPathIndex2 + 2] = x23
            subPathData2[subPathIndex2 + 3] = y23
            subPathData2[subPathIndex2 + 4] = dataX3
            subPathData2[subPathIndex2 + 5] = dataY3
            subPathData2[subPathIndex2 + 6] = subLevel + 1
            subPathIndex2 += segmentDataCount
        }

        // Handle second part of line segments
        if (subPathIndex2 === 0) continue
        if (subPathData2[subPathIndex2 - 1] > PATH_RECURSION_LIMIT) {
            // remove the subDivision data which level is beyond the limit
            subPathIndex2 -= segmentDataCount
            continue
        }

        // set up line segment data
        dataX1 = subPathData2[subPathIndex2 - 7]
        dataY1 = subPathData2[subPathIndex2 - 6]
        dataX2 = subPathData2[subPathIndex2 - 5]
        dataY2 = subPathData2[subPathIndex2 - 4]
        dataX3 = subPathData2[subPathIndex2 - 3]
        dataY3 = subPathData2[subPathIndex2 - 2]
        subLevel = subPathData2[subPathIndex2 - 1]
        // update the subPathIndex2 to previous data set
        subPathIndex2 -= segmentDataCount

        // Calculate all the mid-points of the line segment
        x12 = (dataX1 + dataX2) * 0.5
        y12 = (dataY1 + dataY2) * 0.5
        x23 = (dataX2 + dataX3) * 0.5
        y23 = (dataY2 + dataY3) * 0.5
        x123 = (x12 + x23) * 0.5
        y123 = (y12 + y23) * 0.5

        // handle collinear case
        dx = dataX3 - dataX1
        dy = dataY3 - dataY1
        d = Math.abs(((dataX2 - dataX3) * dy - (dataY2 - dataY3) * dx))

        // Regular care
        if (d > PATH_COLLINEARITY_EPSILON) {
            if (d * d <= distanceTolerance * (dx * dx + dy * dy)) {
                addVertexToCurrentPath(x123, y123, true)
                continue
            }
        } else {
            // Collinear case
            dx = x123 - (dataX1 + dataX3) * 0.5
            dy = y123 - (dataY1 + dataY3) * 0.5
            if (dx * dx + dy * dy <= distanceTolerance) {
                addVertexToCurrentPath(x123, y123, true)
                continue
            }
        }

        // store data for subdivision front part
        subPathData1[0] = dataX1
        subPathData1[1] = dataY1
        subPathData1[2] = x12
        subPathData1[3] = y12
        subPathData1[4] = x123
        subPathData1[5] = y123
        subPathData1[6] = subLevel + 1
        subPathIndex1 = 0
        // store data for subdivision back part
        subPathData2[subPathIndex2] = x123
        subPathData2[subPathIndex2 + 1] = y123
        subPathData2[subPathIndex2 + 2] = x23
        subPathData2[subPathIndex2 + 3] = y23
        subPathData2[subPathIndex2 + 4] = dataX3
        subPathData2[subPathIndex2 + 5] = dataY3
        subPathData2[subPathIndex2 + 6] = subLevel + 1
        // subPathIndex2 = subPathData2.length
        // remove data from array
        subPathIndex2 += segmentDataCount
    }
}


/**
 * @param {number} x1 - x of point1
 * @param {number} y1 - y of point1
 * @param {number} x2 - x of control point1
 * @param {number} y2 - y of control point1
 * @param {number} x3 - x of control point2
 * @param {number} y3 - y of control point2
 * @param {number} x4 - x of point2
 * @param {number} y4 - y of point2
 * @param {number} level
 */
function createCubicBezierPath(x1, y1, x2, y2, x3, y3, x4, y4, level) {
    // Based on http://www.antigrain.com/research/adaptive_bezier/index.html

    // Calculate all the mid-points of the line segments
    let x12 = (x1 + x2) * 0.5
    let y12 = (y1 + y2) * 0.5
    let x23 = (x2 + x3) * 0.5
    let y23 = (y2 + y3) * 0.5
    let x34 = (x3 + x4) * 0.5
    let y34 = (y3 + y4) * 0.5
    let x123 = (x12 + x23) * 0.5
    let y123 = (y12 + y23) * 0.5
    let x234 = (x23 + x34) * 0.5
    let y234 = (y23 + y34) * 0.5
    let x1234 = (x123 + x234) * 0.5
    let y1234 = (y123 + y234) * 0.5

    /**
     * for handling collinear case
     * @type {number}
     * @type {number}
     * @type {number}
     * @type {number}
     */
    let dx, dy, d2, d3

    /**
     * represent line segment data
     * @type {number} - dataX1: x of point1
     * @type {number} - dataY1: y of point1
     * @param {number} - dataX2: x of control point1
     * @param {number} - dataY2: y of control point1
     * @param {number} - dataX3: x of control point2
     * @param {number} - dataY3: y of control point2
     * @param {number} - dataX4: x of point2
     * @param {number} - dataY4: y of point2
     * @type {number} - subLevel: current segment subdivision level
     */
    let dataX1, dataY1, dataX2, dataY2, dataX3, dataY3, dataX4, dataY4, subLevel

    /**
     * index of the current line segment data
     * index of the current line segment data
     * index of the current line segment data
     * @type {number} - subPathIndex1: subPathData1 will start from head of array
     * @type {number} - subPathIndex2: subPathData2 will start from end of array
     */
    let subPathIndex1 = 0
    let subPathIndex2 = 0

    /**
     * the count of a set of line segment data
     * @type {number}
     */
    const segmentDataCount = 9

    // Enforce subdivision first time
    // Try to approximate the full cubic curve by a single straight line
    subPathData1[0] = x1
    subPathData1[1] = y1
    subPathData1[2] = x12
    subPathData1[3] = y12
    subPathData1[4] = x123
    subPathData1[5] = y123
    subPathData1[6] = x1234
    subPathData1[7] = y1234
    subPathData1[8] = level

    subPathData2[0] = x1234
    subPathData2[1] = y1234
    subPathData2[2] = x234
    subPathData2[3] = y234
    subPathData2[4] = x34
    subPathData2[5] = y34
    subPathData2[6] = x4
    subPathData2[7] = y4
    subPathData2[8] = level
    subPathIndex2 = segmentDataCount

    while (subPathIndex1 !== segmentDataCount || subPathIndex2 !== 0) {
        while (subPathIndex1 !== segmentDataCount) {
            if (subPathData1[8] > PATH_RECURSION_LIMIT) {
                // remove the subDivision data which level is beyond the limit
                subPathIndex1 = segmentDataCount
                break
            }

            // set up line segment data
            dataX1 = subPathData1[0]
            dataY1 = subPathData1[1]
            dataX2 = subPathData1[2]
            dataY2 = subPathData1[3]
            dataX3 = subPathData1[4]
            dataY3 = subPathData1[5]
            dataX4 = subPathData1[6]
            dataY4 = subPathData1[7]
            subLevel = subPathData1[8]
            subPathIndex1 = segmentDataCount

            // Calculate all the mid-points of the line segments
            x12 = (dataX1 + dataX2) * 0.5
            y12 = (dataY1 + dataY2) * 0.5
            x23 = (dataX2 + dataX3) * 0.5
            y23 = (dataY2 + dataY3) * 0.5
            x34 = (dataX3 + dataX4) * 0.5
            y34 = (dataY3 + dataY4) * 0.5
            x123 = (x12 + x23) * 0.5
            y123 = (y12 + y23) * 0.5
            x234 = (x23 + x34) * 0.5
            y234 = (y23 + y34) * 0.5
            x1234 = (x123 + x234) * 0.5
            y1234 = (y123 + y234) * 0.5

            // calculate collinear variable
            dx = dataX4 - dataX1
            dy = dataY4 - dataY1
            d2 = Math.abs(((dataX2 - dataX4) * dy - (dataY2 - dataY4) * dx))
            d3 = Math.abs(((dataX3 - dataX4) * dy - (dataY3 - dataY4) * dx))

            if (d2 > PATH_COLLINEARITY_EPSILON && d3 > PATH_COLLINEARITY_EPSILON) {
                // Regular care
                if ((d2 + d3) * (d2 + d3) <= distanceTolerance * (dx * dx + dy * dy)) {
                    // If the curvature doesn't exceed the distance_tolerance value
                    // we tend to finish subdivisions.
                    addVertexToCurrentPath(x1234, y1234, true)
                    break
                }
            } else {
                if (d2 > PATH_COLLINEARITY_EPSILON) {
                    // p1,p3,p4 are collinear, p2 is considerable
                    if (d2 * d2 <= (dx * dx + dy * dy)) {
                        addVertexToCurrentPath(x1234, y1234, true)
                        break
                    }
                } else if (d3 > PATH_COLLINEARITY_EPSILON) {
                    // p1,p2,p4 are collinear, p3 is considerable
                    if (d3 * d3 <= distanceTolerance * (dx * dx + dy * dy)) {
                        addVertexToCurrentPath(x1234, y1234, true)
                        break
                    }
                } else {
                    // Collinear case
                    dx = x1234 - (dataX1 + dataX4) * 0.5
                    dy = y1234 - (dataY1 + dataY4) * 0.5
                    if (dx * dx + dy * dy <= distanceTolerance) {
                        addVertexToCurrentPath(x1234, y1234, true)
                        break
                    }
                }
            }

            // Continue subdivision
            subPathData1[0] = dataX1
            subPathData1[1] = dataY1
            subPathData1[2] = x12
            subPathData1[3] = y12
            subPathData1[4] = x123
            subPathData1[5] = y123
            subPathData1[6] = x1234
            subPathData1[7] = y1234
            subPathData1[8] = subLevel + 1
            // remove data from array
            subPathIndex1 = 0
            subPathData2[subPathIndex2] = x1234
            subPathData2[subPathIndex2 + 1] = y1234
            subPathData2[subPathIndex2 + 2] = x234
            subPathData2[subPathIndex2 + 3] = y234
            subPathData2[subPathIndex2 + 4] = x34
            subPathData2[subPathIndex2 + 5] = y34
            subPathData2[subPathIndex2 + 6] = dataX4
            subPathData2[subPathIndex2 + 7] = dataY4
            subPathData2[subPathIndex2 + 8] = subLevel + 1
            // update subPath2 end index
            subPathIndex2 += segmentDataCount
        }

        if (subPathIndex2 === 0) continue
        if (subPathData2[subPathIndex2 - 1] > PATH_RECURSION_LIMIT) {
            // remove the subDivision data which level is beyond the limit
            subPathIndex2 -= segmentDataCount
            continue
        }

        // set up line segment data
        dataX1 = subPathData2[subPathIndex2 - 9]
        dataY1 = subPathData2[subPathIndex2 - 8]
        dataX2 = subPathData2[subPathIndex2 - 7]
        dataY2 = subPathData2[subPathIndex2 - 6]
        dataX3 = subPathData2[subPathIndex2 - 5]
        dataY3 = subPathData2[subPathIndex2 - 4]
        dataX4 = subPathData2[subPathIndex2 - 3]
        dataY4 = subPathData2[subPathIndex2 - 2]
        subLevel = subPathData2[subPathIndex2 - 1]
        subPathIndex2 -= segmentDataCount

        // Calculate all the mid-points of the line segments
        x12 = (dataX1 + dataX2) * 0.5
        y12 = (dataY1 + dataY2) * 0.5
        x23 = (dataX2 + dataX3) * 0.5
        y23 = (dataY2 + dataY3) * 0.5
        x34 = (dataX3 + dataX4) * 0.5
        y34 = (dataY3 + dataY4) * 0.5
        x123 = (x12 + x23) * 0.5
        y123 = (y12 + y23) * 0.5
        x234 = (x23 + x34) * 0.5
        y234 = (y23 + y34) * 0.5
        x1234 = (x123 + x234) * 0.5
        y1234 = (y123 + y234) * 0.5

        // calculate collinear variable
        dx = dataX4 - dataX1
        dy = dataY4 - dataY1
        d2 = Math.abs(((dataX2 - dataX4) * dy - (dataY2 - dataY4) * dx))
        d3 = Math.abs(((dataX3 - dataX4) * dy - (dataY3 - dataY4) * dx))

        if (d2 > PATH_COLLINEARITY_EPSILON && d3 > PATH_COLLINEARITY_EPSILON) {
            // Regular care
            if ((d2 + d3) * (d2 + d3) <= distanceTolerance * (dx * dx + dy * dy)) {
                // If the curvature doesn't exceed the distance_tolerance value
                // we tend to finish subdivisions.
                addVertexToCurrentPath(x1234, y1234, true)
                continue
            }
        } else {
            if (d2 > PATH_COLLINEARITY_EPSILON) {
                // p1,p3,p4 are collinear, p2 is considerable
                if (d2 * d2 <= (dx * dx + dy * dy)) {
                    addVertexToCurrentPath(x1234, y1234, true)
                    continue
                }
            } else if (d3 > PATH_COLLINEARITY_EPSILON) {
                // p1,p2,p4 are collinear, p3 is considerable
                if (d3 * d3 <= distanceTolerance * (dx * dx + dy * dy)) {
                    addVertexToCurrentPath(x1234, y1234, true)
                    continue
                }
            } else {
                // Collinear case
                dx = x1234 - (dataX1 + dataX4) * 0.5
                dy = y1234 - (dataY1 + dataY4) * 0.5
                if (dx * dx + dy * dy <= distanceTolerance) {
                    addVertexToCurrentPath(x1234, y1234, true)
                    continue
                }
            }
        }

        // Continue subdivision
        subPathData1[0] = dataX1
        subPathData1[1] = dataY1
        subPathData1[2] = x12
        subPathData1[3] = y12
        subPathData1[4] = x123
        subPathData1[5] = y123
        subPathData1[6] = x1234
        subPathData1[7] = y1234
        subPathData1[8] = subLevel + 1
        // remove data from array
        subPathIndex1 = 0

        subPathData2[subPathIndex2] = x1234
        subPathData2[subPathIndex2 + 1] = y1234
        subPathData2[subPathIndex2 + 2] = x234
        subPathData2[subPathIndex2 + 3] = y234
        subPathData2[subPathIndex2 + 4] = x34
        subPathData2[subPathIndex2 + 5] = y34
        subPathData2[subPathIndex2 + 6] = dataX4
        subPathData2[subPathIndex2 + 7] = dataY4
        subPathData2[subPathIndex2 + 8] = subLevel + 1
        // update subPath2 end index
        subPathIndex2 += segmentDataCount
    }
}

/**
 * @param {number} px
 * @param {number} py
 * @param {number} vx
 * @param {number} vy
 * @param {number} wx
 * @param {number} wy
 * @returns {number}
 */
function distanceToLineSegmentSquared(px, py, vx, vy, wx, wy) {
    const l2 = (vx - wx) * (vx - wx) + (vy - wy) * (vy - wy)
    if (l2 === 0) return (px - vx) * (px - vx) + (py - vy) * (py - vy)
    const t = ((px - vx) * (wx - vx) + (py - vy) * (wy - vy)) / l2
    if (t < 0) return (px - vx) * (px - vx) + (py - vy) * (py - vy)
    if (t > 1) return (px - wx) * (px - wx) + (py - wy) * (py - wy)
    return (px - (vx + t * (wx - vx))) * (px - (vx + t * (wx - vx))) + (py - (vy + t * (wy - vy))) * (py - (vy + t * (wy - vy)))
}

/**
 * Draw a triangle
 * @param {number[]} vertices
 * @param {number[]} indices
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 * @param {number} x3
 * @param {number} y3
 * @param {number} color
 * @param {Transform2D} [transform]
 */
function pushTri(vertices, indices, x1, y1, x2, y2, x3, y3, color, transform = Transform2D.IDENTITY) {
    const v = (vertices.length * 0.2) | 0

    transform.xform(vec2.set(x1, y1), vec2)
    vertices.push(
        vec2.x, vec2.y, 1, 0, color
    )
    transform.xform(vec2.set(x2, y2), vec2)
    vertices.push(
        vec2.x, vec2.y, 1, 0, color
    )
    transform.xform(vec2.set(x3, y3), vec2)
    vertices.push(
        vec2.x, vec2.y, 1, 0, color
    )

    indices.push(
        v + 0,
        v + 1,
        v + 2
    )
}

/**
 * Draw a quad with 4 points, in "Z" order instead of clockwise or counter-clockwise
 * - pushQuad( vertices, indices, x1, y1, x2, y2, x3, y3, x4, y4, color, transform )
 * - pushQuad( vertices, indices, x1, y1, x2, y2, x3, y3, x4, y4, color, transform, u1, v1, u2, v2, u3, v3, u4, v4)
 * @param {number[]} vertices
 * @param {number[]} indices
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 * @param {number} x3
 * @param {number} y3
 * @param {number} x4
 * @param {number} y4
 * @param {number} color
 * @param {Transform2D} [transform]
 * @param {number} [u1]
 * @param {number} [v1]
 * @param {number} [u2]
 * @param {number} [v2]
 * @param {number} [u3]
 * @param {number} [v3]
 * @param {number} [u4]
 * @param {number} [v4]
 */
function pushQuad(vertices, indices, x1, y1, x2, y2, x3, y3, x4, y4, color, transform = Transform2D.IDENTITY, u1 = 1, v1 = 0, u2 = 1, v2 = 0, u3 = 1, v3 = 0, u4 = 1, v4 = 0) {
    const v = (vertices.length * 0.2) | 0

    transform.xform(vec2.set(x1, y1), vec2)
    vertices.push(
        vec2.x, vec2.y, u1, v1, color
    )
    transform.xform(vec2.set(x2, y2), vec2)
    vertices.push(
        vec2.x, vec2.y, u2, v2, color
    )
    transform.xform(vec2.set(x3, y3), vec2)
    vertices.push(
        vec2.x, vec2.y, u3, v3, color
    )
    transform.xform(vec2.set(x4, y4), vec2)
    vertices.push(
        vec2.x, vec2.y, u4, v4, color
    )

    indices.push(
        v + 0,
        v + 1,
        v + 2,
        v + 1,
        v + 2,
        v + 3
    )
}

/**
 * Draw arc from one point to another, around center, in counter-clockwise
 * @param {number[]} vertices
 * @param {number[]} indices
 * @param {number} radius
 * @param {number} px center x
 * @param {number} py center y
 * @param {number} p1x start x
 * @param {number} p1y start y
 * @param {number} p2x end x
 * @param {number} p2y end y
 * @param {number} color
 * @param {Transform2D} [transform]
 */
function pushArc(vertices, indices, radius, px, py, p1x, p1y, p2x, p2y, color, transform = Transform2D.IDENTITY) {
    v1.set(p1x, p1y).sub(px, py).normalize()
    v2.set(p2x, p2y).sub(px, py).normalize()

    const angle1 = Math.atan2(1, 0) - Math.atan2(v1.x, -v1.y)

    let angle2 = 0
    if (v1.x === -v2.x && v1.y === -v2.y) {
        angle2 = Math.PI
    } else {
        angle2 = Math.acos(v1.x * v2.x + v1.y * v2.y)
    }

    const pxScale = transform.get_scale(scale).x
    let numSteps = Math.ceil((angle2 * radius * pxScale) * 0.2)

    if (numSteps === 1) {
        pushTri(vertices, indices,
            p1x, p1y,
            px, py,
            p2x, p2y,
            color,
            transform
        )
        return
    } else if (numSteps === 3 && Math.abs(angle2) > PI_2) {
        numSteps = 4
    }

    const direction = (v2.x * v1.y - v2.y * v1.x < 0) ? -1 : 1
    const step = (angle2 / numSteps) * direction
    let angle = angle1

    arcP1.set(px + Math.cos(angle) * radius, py - Math.sin(angle) * radius)

    for (let i = 0; i < numSteps; i++) {
        angle += step
        arcP2.set(px + Math.cos(angle) * radius, py - Math.sin(angle) * radius)

        pushTri(vertices, indices,
            arcP1.x, arcP1.y,
            px, py,
            arcP2.x, arcP2.y,
            color,
            transform
        )

        arcP1.copy(arcP2)
    }
}

/**
 * update clipping viewport data
 * @param {Viewport} viewport
 */
function updateViewportData(viewport) {
    viewRectSize.set(
        viewport.width * viewport.pixelRatio,
        viewport.height * viewport.pixelRatio
    )
    const margin = VIEW_MARGIN * viewport.pixelRatio * Math.max(1, viewport.scale)

    viewportVertices[0].set(-margin, -margin)
    viewportVertices[1].set(viewRectSize.x + margin, -margin)
    viewportVertices[2].set(viewRectSize.x + margin, viewRectSize.y + margin)
    viewportVertices[3].set(-margin, viewRectSize.y + margin)
    viewportVertices[4].set(-margin, -margin)
}

/**
 * check whether the point is inside the clipping viewport boundary
 * @param {number} x
 * @param {number} y
 * @returns {boolean}
 */
function containViewRect(x, y) {
    if (x > viewportVertices[0].x && x < viewportVertices[2].x) {
        if (y > viewportVertices[0].y && y < viewportVertices[2].y) {
            return true
        }
    }
    return false
}

/**
 * sort points to counter-clockwise order
 * @param {number} p1x
 * @param {number} p1y
 * @param {number} p2x
 * @param {number} p2y
 * @param {number} p3x
 * @param {number} p3y
 * @param {number} p4x
 * @param {number} p4y
 */
function sortPointOrientation(p1x, p1y, p2x, p2y, p3x, p3y, p4x, p4y) {
    prev.set((p1x + p2x + p3x + p4x) * 0.25, (p1y + p2y + p3y + p4y) * 0.25)
    ccwPointsList[0].set(p1x, p1y)
    ccwPointsList[1].set(p2x, p2y)
    ccwPointsList[2].set(p3x, p3y)
    ccwPointsList[3].set(p4x, p4y)
    p1.set(ccwPointsList[0].x - prev.x, ccwPointsList[0].y - prev.y)
    ccwPointsList.sort((a, b)=> {
        v1.set(a.x - prev.x, a.y - prev.y)
        v2.set(b.x - prev.x, b.y - prev.y)
        const angle2 = p1.angle_to_2(v1)
        const angle3 = p1.angle_to_2(v2)
        if (angle2 < angle3){
            return 1
        } else {
            return -1
        }
    })
}

/** if the ccwPointsList is a concave polygon, replace the concave point by last point */
function removeConcavePoint() {
    for (let index = 0; index < ccwPointsList.length; index++) {
        if(index === ccwPointsList.length - 1) {
            v1.set(ccwPointsList[0].x - ccwPointsList[index].x, ccwPointsList[0].y - ccwPointsList[index].y)
            v2.set(ccwPointsList[index - 1].x - ccwPointsList[index].x, ccwPointsList[index - 1].y - ccwPointsList[index].y)
            if (v1.cross(v2) > 0) {
                ccwPointsList[index].set( ccwPointsList[index - 1].x,  ccwPointsList[index - 1].y)
                break
            }
        } else if(index === 0) {
            v1.set(ccwPointsList[index + 1].x - ccwPointsList[0].x, ccwPointsList[index + 1].y - ccwPointsList[0].y)
            v2.set(ccwPointsList[ccwPointsList.length - 1].x - ccwPointsList[0].x, ccwPointsList[ccwPointsList.length - 1].y - ccwPointsList[0].y)
            if (v1.cross(v2) > 0) {
                ccwPointsList[index].set( ccwPointsList[ccwPointsList.length - 1].x,  ccwPointsList[ccwPointsList.length - 1].y)
                break
            }
        } else {
            v1.set(ccwPointsList[index + 1].x - ccwPointsList[index].x, ccwPointsList[index + 1].y - ccwPointsList[index].y)
            v2.set(ccwPointsList[index - 1].x - ccwPointsList[index].x, ccwPointsList[index - 1].y - ccwPointsList[index].y)
            if (v1.cross(v2) > 0) {
                ccwPointsList[index].set( ccwPointsList[index - 1].x,  ccwPointsList[index - 1].y)
                break
            }
        }
    }
}

/**
 * check whether viewport intersect with geometry which is made of Bezier Curve points and control points
 * @param {number} p1x   Bezier Curve point1
 * @param {number} p1y
 * @param {number} cp1x  Bezier Curve control point1
 * @param {number} cp1y
 * @param {number} p2x   Bezier Curve point2
 * @param {number} p2y
 * @param {number} cp2x  Bezier Curve control point2
 * @param {number} cp2y
 * @returns {boolean}
 */
function checkGeometryCoverViewport(p1x, p1y, cp1x, cp1y, p2x, p2y, cp2x, cp2y) {
    let res = false

    if (cp2x) {
        // have two control points
        sortPointOrientation(p1x, p1y, cp1x, cp1y,  p2x, p2y, cp2x, cp2y)
    } else {
        // have one control point
        sortPointOrientation(p1x, p1y, cp1x, cp1y,  p2x, p2y, p2x, p2y)
    }
    removeConcavePoint()
    // check whether sides of viewport intersect with sides of geometry
    for (let i = 0; i < viewportVertices.length - 1; i++) {
        for (let j = 0; j < ccwPointsList.length; j++) {
            if (j === ccwPointsList.length - 1) {
                res = checkIntersect(ccwPointsList[j], ccwPointsList[0], viewportVertices[i], viewportVertices[i + 1])
            } else {
                res = checkIntersect(ccwPointsList[j], ccwPointsList[j + 1], viewportVertices[i], viewportVertices[i + 1])
            }
            if (res) return res
            // check whether geometry inside the viewport
            if (isInside(viewportVertices, viewportVertices.length - 1, ccwPointsList[j])) return true
        }
        // check whether viewport inside the geometry
        if (isInside(ccwPointsList, ccwPointsList.length, viewportVertices[i])) return true
    }

    return res
}

/**
 * check whether three points are collinear
 * @param {Vector2} p
 * @param {Vector2} q
 * @param {Vector2} r
 * @returns {boolean}
 */
function checkCollinear(p, q, r) {
    if (q.x <= Math.max(p.x, r.x) && q.x >= Math.min(p.x, r.x) &&
            q.y <= Math.max(p.y, r.y) && q.y >= Math.min(p.y, r.y)) {
        return true
    }

    return false
}

/**
 * To find orientation of ordered triplet (p, q, r)
 * 0 --> p, q and r are collinear; 1 --> Clockwise; 2 --> Counterclockwise
 * @param {Vector2} p
 * @param {Vector2} q
 * @param {Vector2} r
 * @returns {boolean}
 */
function checkOrientation(p, q, r) {
    const val = (q.y - p.y) * (r.x - q.x) -
            (q.x - p.x) * (r.y - q.y)

    // collinear
    if (val === 0) return 0

    // clock or counter-clockwise
    return (val > 0) ? 1: 2
}

/**
 * check whether the line p1q1 and line p2q2 intersect
 * @param {Vector2} p1
 * @param {Vector2} q1
 * @param {Vector2} p2
 * @param {Vector2} q2
 * @returns {boolean}
 */
function checkIntersect(p1, q1, p2, q2) {
    // Find the four orientations needed for general and special cases
    const o1 = checkOrientation(p1, q1, p2)
    const o2 = checkOrientation(p1, q1, q2)
    const o3 = checkOrientation(p2, q2, p1)
    const o4 = checkOrientation(p2, q2, q1)

    // General case
    if (o1 !== o2 && o3 !== o4) return true

    // Special Cases
    // p1, q1 and p2 are collinear and p2 lies on segment p1q1
    if (o1 === 0 && checkCollinear(p1, p2, q1)) return true

    // p1, q1 and q2 are collinear and q2 lies on segment p1q1
    if (o2 === 0 && checkCollinear(p1, q2, q1)) return true

    // p2, q2 and p1 are collinear and p1 lies on segment p2q2
    if (o3 === 0 && checkCollinear(p2, p1, q2)) return true

    // p2, q2 and q1 are collinear and q1 lies on segment p2q2
    if (o4 === 0 && checkCollinear(p2, q1, q2)) return true

    return false
}

/**
 * check if the point p lies inside the polygon[] with n vertices
 * @param {number[]} polygon
 * @param {number} n
 * @param {Vector2} p
 * @returns {boolean}
 */
function isInside(polygon, n, p) {
    // There must be at least 3 vertices in polygon[]
    if (n < 3) {
        return false
    }

    // Create a point for line segment from p to infinite
    v1.set(0xffffff, p.y)
    // Count intersections of the above line
    // with sides of polygon
    let count = 0, i = 0
    do {
        const next = (i + 1) % n
        // Check if the line segment from 'p' to 'v1' intersects with the line
        // segment from 'polygon[i]' to 'polygon[next]'
        if (checkIntersect(polygon[i], polygon[next], p, v1)) {
            // If the point 'p' is collinear with line segment 'i-next',
            // then check if it lies on segment.
            // If it lies return true, otherwise false
            if (checkOrientation(polygon[i], p, polygon[next]) === 0) {
                return checkCollinear(polygon[i], p, polygon[next])
            }

            count++
        }
        i = next
    } while (i !== 0)
    // Return true if count is odd, false otherwise
    return (count % 2 === 1) // Same as (count%2 == 1)
}

/**
 * check whether the arch is showed in the viewport
 * @param {number} cornerPosX
 * @param {number} cornerPosY
 * @param {number} r
 * @param {number} outerWidth
 * @param {number} startRadian
 * @param {number} viewportScale
 * @param {Transform2D} transform
 * @returns {bool}
 */
function isArchInView(cornerPosX, cornerPosY, r, outerWidth, startRadian, viewportScale, transform) {
    const segments = Num.clamp(Math.ceil(r * viewportScale * 0.2), 2, 48)
    const step = PI_2 / segments
    const checkPoint = new Vector2(r + outerWidth, 0)
    for (let i = 0; i <= segments; i++) {
        const radian = startRadian + step * i
        checkPoint.set(r + outerWidth, 0).rotate(radian)
            .add(cornerPosX, cornerPosY)
        transform.xform(checkPoint, checkPoint)
        if (containViewRect(checkPoint.x, checkPoint.y)) {
            return true
        }
    }
    return false
}

const state = {
    layer_id: 0,
    img_id: 0,
    pip_id: 0,
    /** @type {number[]} */
    vertices: [],
    /** @type {number[]} */
    indices: [],
}
/**
 * @param {Pane} pane
 * @param {number} pip_id
 * @param {number} img_id
 */
const getCommand = (pane, pip_id = 0, img_id = 0) => {
    state.layer_id = pane.layer_id
    state.img_id = img_id
    state.pip_id = pip_id
    state.vertices.length = 0
    state.indices.length = 0
    return state
}
const pushBrush = () => {
    if (state.vertices.length===0 || state.indices.length===0) return
    const v_ptr = allocTempFloat32Array(state.vertices)
    const i_ptr = allocTempUint32Array(state.indices)
    if (!v_ptr || !i_ptr) return false
    WASM().pushBrush(state.layer_id, state.pip_id, state.img_id, v_ptr, state.vertices.length, i_ptr, state.indices.length)
}

const canvas = document.createElement('canvas')
let _viewport = null
const ctx = canvas.getContext('2d', {
    willReadFrequently: true,
})
/**
 * @typedef {object} ImageInfo
 * @property {number} x
 * @property {number} y
 * @property {number} w
 * @property {number} h
 * @property {number} pixelRatio
 */
/**
 * @typedef {object} ImageAtlas
 * @property {number} image image id
 * @property {boolean} valid
 * @property {ImageInfo} imageInfo
 */
/** @type {Map<string, ImageAtlas>}  */
const imageAtlases = new Map()
/**
 * @param {string} src image src
 */
export const loadImage = (src) => {
    if (imageAtlases.has(src)) {
        return imageAtlases.get(src)
    }

    /** @type {ImageAtlas} */
    const imageAtlas = {
        image: null,
        valid: false,
        imageInfo: {
            x: 0, y: 0,
            w: 0, h: 0,
            pixelRatio: _viewport.pixelRatio * 5
        },
    }
    imageAtlases.set(src, imageAtlas)

    const image = new Image()
    image.src = src
    image.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        const pixelRatio = imageAtlas.imageInfo.pixelRatio
        canvas.width = Math.max(image.width * pixelRatio, 1)
        canvas.height = Math.max(image.height * pixelRatio, 1)

        ctx.scale(pixelRatio, pixelRatio)
        ctx.drawImage(image, 0, 0)

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
        const pixels = imageData.data

        const ptr = allocTempUint8Array(pixels)
        if (ptr) {
            const id = WASM().makeBrushImage(ptr, pixels.length, canvas.width, canvas.height)
            imageAtlas.image = id
            imageAtlas.valid = true
        }
        imageAtlas.imageInfo.x = 0
        imageAtlas.imageInfo.y = 0
        imageAtlas.imageInfo.w = canvas.width
        imageAtlas.imageInfo.h = canvas.height
        imageAtlas.imageInfo.pixelRatio = pixelRatio

        imageAtlases.set(src, imageAtlas)
    }

    return imageAtlas
}

/** @typedef {Map<string, boolean>} fontBank */
const fontBank = new Map()
const defaultFontFamily = 'Arial'
/**
 * @param {string} char one character
 * @param {string} fontFamily
 * @param {number} fontsize unit px
 * @param fontSize
 * @param {"normal"|"bold"|"100|200|300|400|500|600|700|800|900} [fontWeight] default normal; normal(=400), bold(=700)
 * @param {"normal"|"italic"|"oblique"} [fontStyle] default normal
 */
export const loadChar = (char, fontFamily, fontSize, fontWeight = 400, fontStyle = 'normal') => {
    const bandKey = `${fontFamily}`

    let atlasKey = `${defaultFontFamily}-${fontWeight}-${fontStyle}-${fontSize}-${char}`
    let font = `${fontWeight} ${fontSize}px ${defaultFontFamily}`
    if (fontBank.has(bandKey)) {
        const loaded = fontBank.get(bandKey)

        if (loaded) {
            atlasKey = `${fontFamily}-${fontWeight}-${fontStyle}-${fontSize}-${char}`
            if (imageAtlases.has(atlasKey)) return imageAtlases.get(atlasKey)

            font = `${fontWeight} ${fontSize}px ${fontFamily}`
        }
    } else {
        fontBank.set(bandKey, false)
        new FontFaceObserver(fontFamily)
            .load()
            .then(() => {
                fontBank.set(bandKey, true)
            }).catch(() => {
                console.log('%c Font %s can not be loaded correctly', 'background: red; color: white', bandKey)
            })
    }
    if (imageAtlases.has(atlasKey)) return imageAtlases.get(atlasKey)

    /** @type {ImageAtlas} */
    const imageAtlas = {
        image: null,
        valid: false,
        imageInfo: {
            x: 0, y: 0,
            w: 0, h: 0,
            pixelRatio: _viewport.pixelRatio * 5
        },
    }
    imageAtlases.set(atlasKey, imageAtlas)

    const pixelRatio = imageAtlas.imageInfo.pixelRatio
    ctx.font = font
    const text = ctx.measureText(`${char}`)
    canvas.width = Math.max((text.width) * pixelRatio, 1)
    canvas.height = Math.max((text.fontBoundingBoxAscent + text.fontBoundingBoxDescent) * pixelRatio, 1)
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    ctx.font = font
    ctx.textBaseline = 'top'
    ctx.fillStyle = '#ffffff'
    ctx.globalAlpha = 1
    ctx.scale(pixelRatio, pixelRatio)
    ctx.fillText(char, 0, 0)

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    const pixels = imageData.data

    const ptr = allocTempUint8Array(pixels)
    if (ptr) {
        const id = WASM().makeBrushImage(ptr, pixels.length, canvas.width, canvas.height)
        imageAtlas.image = id
        imageAtlas.valid = true
    }
    imageAtlas.imageInfo.x = 0
    imageAtlas.imageInfo.y = 0
    imageAtlas.imageInfo.w = canvas.width
    imageAtlas.imageInfo.h = canvas.height

    return imageAtlas
}
