import React, { HTMLAttributes, useEffect, useRef, useState } from 'react'

interface ErrorTextComponentProps extends HTMLAttributes<HTMLDivElement> {
  showErrorTips?: boolean
  errorTips?: string
  containerClassName?: string
}

const ErrorTextComponent = ({
  showErrorTips,
  errorTips,
  containerClassName = '',
  className = '',
  children,
  ...props
}: ErrorTextComponentProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [clientWith, setClientWith] = useState(0)
  useEffect(() => {
    if (ref.current?.firstElementChild) {
      setClientWith(ref.current.firstElementChild.clientWidth)
    }
  }, [ref.current?.firstElementChild?.clientWidth])

  return (
    <div {...props} className={`relative w-full ${containerClassName}`} ref={ref}>
      {children}
      {showErrorTips && !!errorTips && (
        <div
          style={{
            width: clientWith
          }}
          className={`text-12 text-left text-rubin-50 mt-4 ${className}`}
        >
          {errorTips}
        </div>
      )}
    </div>
  )
}
export default ErrorTextComponent
