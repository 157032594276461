import EventEmitter from 'eventemitter3'
import {
    Mode,
    ToolType,
    PaintType,
    EditMode,
    DirectionType,
    CreateElementToolType,
    ContainerElementType
} from '@phase-software/types'
import { Vector2 } from '@phase-software/data-utils'
import States from './states'
import {
    Hover,
    ElementSelection,
    Events,
    EditModeEventNameMap,
    ActiveToolEventNameMap
} from './constants'

const AMOUNT = 1
const MORE_AMOUNT = 10
const VECTOR_MAP = {
    [DirectionType.LEFT]: new Vector2(-AMOUNT, 0),
    [DirectionType.RIGHT]: new Vector2(AMOUNT, 0),
    [DirectionType.UP]: new Vector2(0, -AMOUNT),
    [DirectionType.DOWN]: new Vector2(0, AMOUNT)
}
const VECTOR_MORE_MAP = {
    [DirectionType.LEFT]: new Vector2(-MORE_AMOUNT, 0),
    [DirectionType.RIGHT]: new Vector2(MORE_AMOUNT, 0),
    [DirectionType.UP]: new Vector2(0, -MORE_AMOUNT),
    [DirectionType.DOWN]: new Vector2(0, MORE_AMOUNT)
}
const GRADIENT_DIR_MAP = {
    [DirectionType.UP]: AMOUNT,
    [DirectionType.DOWN]: -AMOUNT,
    [DirectionType.LEFT]: -AMOUNT,
    [DirectionType.RIGHT]: AMOUNT
}
const GRADIENT_DIR_MORE_MAP = {
    [DirectionType.UP]: MORE_AMOUNT,
    [DirectionType.DOWN]: -MORE_AMOUNT,
    [DirectionType.LEFT]: -MORE_AMOUNT,
    [DirectionType.RIGHT]: MORE_AMOUNT
}

const OPPOSITE_DIRECTION = {
    [DirectionType.UP]: DirectionType.DOWN,
    [DirectionType.DOWN]: DirectionType.UP,
    [DirectionType.LEFT]: DirectionType.RIGHT,
    [DirectionType.RIGHT]: DirectionType.LEFT,
    [DirectionType.HORIZONTAL]: DirectionType.VERTICAL,
    [DirectionType.VERTICAL]: DirectionType.HORIZONTAL,
    [DirectionType.CLOCKWISE]: DirectionType.COUNTERCLOCKWISE,
    [DirectionType.COUNTERCLOCKWISE]: DirectionType.CLOCKWISE,
    [DirectionType.INWARD]: DirectionType.OUTWARD,
    [DirectionType.OUTWARD]: DirectionType.INWARD,
    [DirectionType.START]: DirectionType.END,
    [DirectionType.END]: DirectionType.START,
}

const FireOrStart = {
    FIRE: 'FIRE',
    START: 'START'
}

const EVENT_QUEUE_MAX_LENGTH = 2

const gradientPaintTypes = [
    PaintType.GRADIENT_LINEAR,
    PaintType.GRADIENT_RADIAL,
    PaintType.GRADIENT_ANGULAR,
    PaintType.GRADIENT_DIAMOND
]

/** Editor Action Manager */
export default class EAM extends EventEmitter {
    constructor(dataStore) {
        super()

        this.dataStore = dataStore
        this.states = new States(dataStore)
        this._startAction = null
        this._prevAction = null
        // Cache IS event if start an action
        // so that it can used if we need to resume some actions.
        this._ISevent = null
        this._initPan()
        this._initEventQueue()
    }

    get hoverType() {
        return this.states.hover
    }

    exitEditor() {
        if (this.states.mode !== Mode.DESIGN) {
            this.stopAnimation()
        }
        if (this.states.editMode !== EditMode.ELEMENT) {
            this.activateElementEditMode()
        }

        if (this.states.activeTool === ToolType.HAND) {
            this._initPan()
        }

        this.fire(Events.EXIT_EDITOR)
    }

    _initPan() {
        this._pan = {
            hold: false,
            triggered: false
        }
    }

    _initEventQueue() {
        this._eventQ = {
            /** @type {[Event]} */
            events: new Array(EVENT_QUEUE_MAX_LENGTH),
            /** @type {[FireOrStart]} */
            fireOrStart: new Array(EVENT_QUEUE_MAX_LENGTH),
            /** @type {[{ modifier: bool|undefined, shift: bool|undefined } | undefined]} */
            mods: new Array(EVENT_QUEUE_MAX_LENGTH),
            length: 0
        }
    }

    /**
     * @param {Event} event
     * @param {object} [mods]
     */
    _enqueueFire(event, mods = undefined) {
        const idx = this._eventQ.length
        this._eventQ.fireOrStart[idx] = FireOrStart.FIRE
        this._eventQ.events[idx] = event
        this._eventQ.mods[idx] = mods
        this._eventQ.length++
    }

    /**
     * @param {Event} event
     * @param {object} [mods]
     */
    _enqueueStartAction(event, mods = undefined) {
        const idx = this._eventQ.length
        this._eventQ.fireOrStart[idx] = FireOrStart.START
        this._eventQ.events[idx] = event
        this._eventQ.mods[idx] = mods
        this._eventQ.length++
    }

    _flushEventQueue(eventData) {
        for (let i = 0; i < this._eventQ.length; i++) {
            switch (this._eventQ.fireOrStart[i]) {
                case FireOrStart.FIRE:
                    this.fire(this._eventQ.events[i], eventData, this._eventQ.mods[i],
                        {
                             snapToGrid: this.dataStore.data.snapToPixelGrid,
                             snapToObject: this.dataStore.data.snapToObject
                        })
                    break
                case FireOrStart.START:
                    this.startAction(this._eventQ.events[i], eventData, this._eventQ.mods[i])
                    break
            }
        }
        this._eventQ.length = 0
    }

    switchMode(mode) {
        this.closeModal()
        this.states.switchMode(mode)
        this.fire(Events.SWITCH_MODE, mode)
        if (this.states.mode !== Mode.DESIGN) {
            this.stopAnimation()
        }
    }

    activateElementEditMode(options) {
        const currentEditMode = this.dataStore.get('editMode')
        if (currentEditMode === EditMode.ELEMENT) {
            return
        }
        this.states.activateElementEditMode(options)
        this.fire(Events[`DEACTIVATE_${EditModeEventNameMap[currentEditMode]}`])
    }

    activateShapeMode(options) {
        if (this.states.activeTool === ToolType.COMMENT) return
        this.closeModal()
        this.dataStore.setFeature('editOrigin', false)
        this.fire(Events.ACTIVATE_SHAPE_MODE)
        if (this.states.isSingleElementSelection && !this.dataStore.isInspectingState) {
            this.states.activateShapeMode(options)
        }
    }

    activateTextMode() {
        this.closeModal()
        this.states.activateTextMode()
        this.fire(Events.ACTIVATE_TEXT_MODE)
    }

    activateGradientHandlesMode(options) {
        this.states.activateGradientHandlesMode(options)
        this.fire(Events.ACTIVATE_GRADIENT_MODE)
    }

    startAction(action, eventData, keys) {
        this._ISevent = eventData
        if (![Events.START_PANNING, Events.START_AREA_SELECT_ELEMENT].includes(action)) {
            this.stopAnimation()
        }
        this._startAction = action.substr(6)
        this._prevAction = this._startAction
        this.fire(Events[action], eventData, keys, { 
            snapToGrid: this.dataStore.data.snapToPixelGrid,
            snapToObject: this.dataStore.data.snapToObject
        })
    }

    updateAction(eventData = {}, keys = {}) {
        this._ISevent = eventData
        if (this.states.space || this._pan.hold) {
            this._startAction = 'PANNING'
            this.states.changeMouse(true)
            this._pan.hold = true
            this._pan.triggered = true
            this.stopAnimation()
        }

        // If has startAction, then you can do its updateAction
        if (this._startAction) {
            this.fire(Events[`UPDATE_${this._startAction}`], eventData, keys, {
                snapToGrid: this.dataStore.data.snapToPixelGrid,
                snapToObject: this.dataStore.data.snapToObject
            })
        }
    }

    endAction() {
        this._ISevent = null
        if ((this._startAction && this.states.space) || this.states.activeTool === ToolType.HAND) {
            // If is HAND tool, then refire ACTIVATE_PAN event to set cursor to grab.
            this.activatePan()
        } else if (this.states.activeTool !== ToolType.PEN) {
            if (this._startAction !== 'PANNING') {
                this.states.changeMouse(false)
                this.states.changeSpace(false)
                this._pan.hold = false
                if (this.states.activeTool === ToolType.COMMENT) {
                    this.fire(Events.COMMENT_DEACTIVATE_PAN)
                } else {
                    this.fire(Events.DEACTIVATE_PAN)
                }
            }
        }

        // If has startAction, then you should do its endAction
        if (this._startAction) {
            this.fire(Events[`END_${this._startAction}`])
            this._startAction = null
        }

        if (
            this.states.activeTool !== ToolType.PEN &&
            this.states.activeTool !== ToolType.HAND &&
            this.states.isCreationTool
        ) {
            this.setLastGeneralTool()
        }
    }

    spaceOn() {
        if (this._startAction) {
            return
        }
        this.activatePan()
    }

    spaceOff() {
        this.deactivatePan()
    }

    activatePan() {
        this.states.changeSpace(true)
        this.fire(Events.ACTIVATE_PAN)
    }

    deactivatePan() {
        this.states.changeSpace(false)
        // If still holding space and mouse, and receive end action from one of them,
        // just set space and _pan.hold to false.
        // It will re-trigger again if still hold one of them.
        // Don't fire END_PANNING and DEACTIVATE_PAN event.
        if (this.states.mouse) {
            this.states.changeSpace(false)
            this._pan.hold = false
            return
        }

        this._startAction = null
        this.fire(Events.END_PANNING)
        this.fire(Events.DEACTIVATE_PAN)
    }

    _hoverElement(eventData, keys) {
        this.fire(Events.HOVER_ELEMENT, eventData, keys)
    }

    mouseMove(eventData, { modifier = false, alt = false, shift = false } = { modifier: false, alt: false, shift: false }) {
        this._ISevent = eventData
        this.states.startHover()

        // if (alt) {
        //     // Only allow to drag-duplicate when press alt key
        //     this._hoverElement(eventData, { modifier, alt })
        // } else
        if (this.states.elementSelection === ElementSelection.NONE || this.states.space) {
            switch (this.states.activeTool) {
                case ToolType.SELECT:
                case ToolType.SCALE:
                    this._hoverElement(eventData, { modifier, alt })
                    break
                case ToolType.PEN:
                    this.fire(Events.HOVER_CREATE_PATH, eventData, { shift }, { 
                        snapToGrid: this.dataStore.data.snapToPixelGrid,
                        snapToObject: this.dataStore.data.snapToObject
                    })
            }
        } else {
            switch (this.states.activeTool) {
                case ToolType.SELECT:
                case ToolType.SCALE:
                    if (this.states.activeTool === ToolType.SELECT && this.states.editMode === EditMode.SHAPE) {
                        if (alt) {
                            this.fire(Events.SWITCH_BEND_TOOL, eventData)
                        } else {
                            this.fire(Events.HOVER_CELL, eventData, { shift }, { 
                                snapToGrid: this.dataStore.data.snapToPixelGrid,
                                snapToObject: this.dataStore.data.snapToObject
                            })
                        }
                    } else {
                        this._hoverElement(eventData, { modifier, alt })
                        if (this.states.isGradientHandlesMode) {
                            this.fire(Events.HOVER_GRADIENT_HANDLE, eventData)
                        } else {
                            this.fire(Events.HOVER_BOX_HANDLE, eventData)
                            this.fire(Events.HOVER_MOTION_POINT, eventData)
                            this.fire(Events.HOVER_ORIGIN, eventData)
                        }
                        // TODO: figure out the condition for scrollbar hover
                        // this.fire(Events.HOVER_SCROLLBAR, eventData)
                    }
                    break
                case ToolType.PEN:
                    this.fire(Events.HOVER_CELL, eventData, { shift }, {
                        snapToGrid: this.dataStore.data.snapToPixelGrid,
                        snapToObject: this.dataStore.data.snapToObject
                    })
                    if (this.states.cellSelection) {
                        this.fire(Events.HOVER_CELL_WITH_SELECTION, eventData, { toggle: modifier }, {
                            snapToGrid: this.dataStore.data.snapToPixelGrid,
                            snapToObject: this.dataStore.data.snapToObject
                        })
                    }
                    break
            }
        }

        this.states.endHover()
    }

    changeHover(hoverType) {
        if (!hoverType) {
            return
        }
        this.states.changeHover(hoverType)
    }

    changeHoverSelected(hoverSelectedElement) {
        this.states.changeHoverSelected(hoverSelectedElement)
    }

    leftClickMove(eventData, { modifier = false, shift = false, alt = false } = { modifier: false, shift: false, alt: false }) {
        this._ISevent = eventData
        if (this.states.activeTool === ToolType.COMMENT) return
        if (this.states.space) {
            this._enqueueStartAction(Events.START_PANNING)
        } else {
            switch (this.states.editMode) {
                case EditMode.SHAPE:
                    if (this.states.activeTool === ToolType.PEN) {
                        if (this.states.hover === Hover.CURVE_CONTROL || this.states.hover === Hover.VERTEX) {
                            this._enqueueFire(Events.SELECT_CELL)
                            this._enqueueStartAction(Events.START_DRAG_CELL, { shift })
                        } else if (this.states.hover === Hover.NONE) { // Not hand over any thing or vertex
                            this._enqueueStartAction(Events.START_DRAW_PATH, { modifier })
                        } else if (this.states.hover === Hover.ENDPOINT) {
                            this._enqueueStartAction(Events.START_DRAW_PATH_ON_VERTEX, { modifier })
                        } else if (this.states.hover === Hover.EDGE) {
                            this._enqueueStartAction(Events.START_DRAW_PATH_ON_EDGE, { modifier })
                        }
                    } else {
                        if (modifier || shift) {
                            if (this.states.hover === Hover.NONE) {
                                this._enqueueStartAction(Events.START_AREA_SELECT_CELL)
                            } else if (this.states.hover === Hover.VERTEX
                                || this.states.hover === Hover.CURVE_CONTROL
                                || this.states.hover === Hover.ENDPOINT) {
                                // do the mouse action detecting in the drag cell process
                                // if the mouse is down and shift is pressed, check whether
                                // the mouse is moved. If it moved then do dragging, if no then do
                                // multiple select behavior
                                this._enqueueStartAction(Events.START_DRAG_CELL, { shift })
                            }
                        } else {
                            if (this.states.hover === Hover.NONE) {
                                this._enqueueFire(Events.DESELECT_CELL)
                                this._enqueueStartAction(Events.START_AREA_SELECT_CELL)
                            } else if (this.states.hover === Hover.VERTEX
                                || this.states.hover === Hover.CURVE_CONTROL
                                || this.states.hover === Hover.ENDPOINT) {
                                this._enqueueFire(Events.SELECT_CELL)
                                this._enqueueStartAction(Events.START_DRAG_CELL, { shift, alt })
                            }
                        }
                    }
                    break
                case EditMode.TEXT:
                    this._enqueueStartAction(Events.START_MOVE_TEXT_CARET, { shift })
                    break
                case EditMode.ELEMENT:
                    switch (this.states.activeTool) {
                        case ToolType.RECTANGLE:
                        case ToolType.CONTAINER:
                        case ToolType.ELLIPSE:
                            this._enqueueStartAction(Events.START_CREATE_ELEMENT)
                            break
                        case ToolType.PEN:
                            this._enqueueStartAction(Events.START_DRAW_PATH, { modifier })
                            break
                        default:
                            if (this.states.hoverSelected) {
                                this._enqueueStartAction(Events.START_DRAG_ELEMENT, { modifier, shift, alt })
                            } else {
                                switch (this.states.hover) {
                                    case Hover.NONE:
                                        // If not have shift, deselect element first.
                                        if (!shift) {
                                            this._enqueueFire(Events.DESELECT_ELEMENT)
                                        }
                                        this._enqueueFire(Events.DESELECT_MOTION_POINT)

                                        // TODO: might be useful later, keep it for now
                                        // Originally is a holding modifier behavior
                                        // this._enqueueStartAction(Events.START_ZOOM_TO_SELECTION)
                                        if(this.dataStore.get('state') !== 'INSPECTING') {
                                            this._enqueueStartAction(Events.START_AREA_SELECT_ELEMENT)
                                        }                 

                                        break
                                    case Hover.SCROLLBAR:
                                        if (this.states.activeTool === ToolType.SELECT || this.states.activeTool === ToolType.SCALE) {
                                            this._enqueueStartAction(Events.START_DRAG_SCROLLBAR)
                                        }
                                        break
                                    case Hover.ELEMENT:
                                        if (this.states.activeTool === ToolType.SELECT || this.states.activeTool === ToolType.SCALE) {
                                            this._enqueueStartAction(Events.START_DRAG_ELEMENT, { modifier, shift, alt })
                                        }
                                        break
                                    case Hover.MULTIPLE_SELECTION_BOUND:
                                        if (this.states.activeTool === ToolType.SELECT || this.states.activeTool === ToolType.SCALE) {
                                            this._enqueueStartAction(Events.START_DRAG_MULTI_ELEMENTS, { modifier, shift, alt })
                                        }
                                        break
                                    case Hover.ORIGIN:
                                        this._enqueueStartAction(Events.START_DRAG_ORIGIN)
                                        break
                                    case Hover.RESIZE_HANDLE:
                                        if (this.states.activeTool === ToolType.SELECT) {
                                            this._enqueueStartAction(Events.START_RESIZE_ELEMENT)
                                        } else if (this.states.activeTool === ToolType.SCALE) {
                                            this._enqueueStartAction(Events.START_SCALE_ELEMENT)
                                        }
                                        break
                                    case Hover.ROTATE_HANDLE:
                                        if (this.states.activeTool === ToolType.SELECT || this.states.activeTool === ToolType.SCALE) {
                                            this._enqueueStartAction(Events.START_ROTATE_ELEMENT, { shift })
                                        }
                                        break
                                    case Hover.MOTION_POINT:
                                        this._enqueueStartAction(Events.START_DRAG_MOTION_POINT, { shift })
                                        break
                                }
                            }
                            break
                    }
                    break
                case EditMode.GRADIENT_HANDLES:
                    if (this.states.isHoverOnGradientReference) {
                        this.fire(Events.ADD_NEW_GRADIENT_STOP)
                    } else if (this.states.isHoverOnGradientHandle) {
                        this._enqueueStartAction(Events.START_DRAG_GRADIENT_HANDLE, { shift })
                    } else {
                        this._enqueueFire(Events.SELECT_ELEMENT, { shift: false })
                    }
                    break

                case EditMode.MOTION_PATH:
                    switch (this.states.hover) {
                        case Hover.NONE:
                            this._enqueueFire(Events.DESELECT_ELEMENT)
                            break
                        case Hover.MOTION_POINT:
                            this._enqueueStartAction(Events.START_DRAG_MOTION_POINT, { shift })
                            break
                        case Hover.ELEMENT:
                            this._enqueueFire(Events.DESELECT_MOTION_POINT)
                            this._enqueueStartAction(Events.START_DRAG_ELEMENT, { modifier, shift, alt })
                            break
                        case Hover.RESIZE_HANDLE:
                            if (this.states.activeTool === ToolType.SELECT) {
                                this._enqueueStartAction(Events.START_RESIZE_ELEMENT)
                            } else if (this.states.activeTool === ToolType.SCALE) {
                                this._enqueueStartAction(Events.START_SCALE_ELEMENT)
                            }
                            break
                        case Hover.ROTATE_HANDLE:
                            if (this.states.activeTool === ToolType.SELECT || this.states.activeTool === ToolType.SCALE) {
                                this._enqueueStartAction(Events.START_ROTATE_ELEMENT, { shift })
                            }
                            break
                    }

                    break
            }
        }

        this._flushEventQueue(eventData)
    }

    middleClickMove(eventData) {
        this.startAction(Events.START_PANNING, eventData)
    }

    rightClickMove(eventData) {
        this.startAction(Events.START_PANNING, eventData)
    }

    doubleLeftClick(eventData) {
        this.fire(Events.DOUBLE_LEFT_CLICK, eventData)
    }

    dragOver(eventData) {
        this.fire(Events.DRAG_OVER, eventData)
    }

    dragEnd(eventData) {
        this.fire(Events.DRAG_END, eventData)
    }

    drop(eventData) {
        if (eventData.dataTransfer.files.length === 0) return

        this.fire(Events.DROP_WITH_FILES, eventData)
    }

    insertImage(options) {
        this.fire(Events.INSERT_IMAGE, options)
    }

    insertSvg() {
        this.fire(Events.INSERT_SVG)
    }

    selectElementSibling(direction) {
        if (direction === DirectionType.UP) {
            this.fire(Events.SELECT_PREVIOUS_ELEMENT)
        } else if (direction === DirectionType.DOWN) {
            this.fire(Events.SELECT_NEXT_ELEMENT)
        }
    }

    moveTextToEdge(direction, { modifier = false, shift = false } = { modifier: false, shift: false }) {
        if (shift) {
            if (modifier) {
                this.fire(Events.EXPAND_TEXT_SELECTION_TO_TEXT_START, direction)
            } else {
                this.fire(Events.EXPAND_TEXT_SELECTION_TO_LINE_START, direction)
            }
        } else {
            if (modifier) {
                this.fire(Events.MOVE_TEXT_CARET_TO_TEXT_START, direction)
            } else {
                this.fire(Events.MOVE_TEXT_CARET_TO_LINE_START, direction)
            }
        }
    }

    enter(eventData, { shift = false } = { shift: false }) {
        if (this.states.inputFocus) {
            return
        }

        if (shift) {
            if (this.states.isEditMode) {
                this.activateElementEditMode()
                this.closeModal()
            } else {
                this.fire(Events.SELECT_PARENT_ELEMENT)
            }
            return
        }

        switch (this.states.editMode) {
            case EditMode.TEXT:
                this.fire(Events.TEXT_INSERT_NEW_LINE)
                break
            case EditMode.SHAPE:
                if (this.states.activeTool === ToolType.PEN) {
                    this.setLastGeneralTool()
                }
                this.activateElementEditMode()
                break
            case EditMode.ELEMENT:
                if (this.states.isPathElement) {
                    this.activateShapeMode()
                } else {
                    switch (this.states.elementSelection) {
                        case ElementSelection.NONE:
                            this.fire(Events.SELECT_FIRST_ELEMENT)
                            break
                        case ElementSelection.TEXT:
                            this.activateTextMode()
                            break
                        default:
                            this.fire(Events.SELECT_CHILD_ELEMENT)
                            this._hoverElement(eventData, { modifier: false, alt: false })
                            break
                    }
                }
                break
        }
    }

    escape(eventData) {
        if (this.states.inputFocus) {
            return
        }

        if (this.dataStore.get('state') === 'VERSIONING') {
            this.fire(Events.LEAVE_VERSION_PREVIEW)
            return
        }

        const currentTool = this.states.activeTool

        if (currentTool === ToolType.EYE_DROPPER) {
            this.setLastGeneralTool()
            return
        }


        if (currentTool === ToolType.SCALE) {
            this.activateSelectTool()
            if (this.states.dataStore.getFeature('editOrigin')) {
                return
            }
        }
        if (this.states.isCreationTool && currentTool !== ToolType.PEN || currentTool === ToolType.HAND) {
            this.setLastGeneralTool()
            if (this.states.dataStore.getFeature('editOrigin')) {
                return
            }
        }
        if (this.states.currentModal) {
            this.closeModal()
            return
        }
        if (currentTool === ToolType.COMMENT) {
            if (this.dataStore.isEditingState || this.dataStore.isInspectingState) {
                this.setLastGeneralTool()
            } else {
                this.activateHandTool()
            }
            return
        }
        if (this.dataStore.get('state') === 'INSPECTING' && currentTool === ToolType.SELECT) {
            this.fire(Events.TOGGLE_INSPECTING)
            return
        }

        if (currentTool === ToolType.PEN) {
            this.handlePenToolEscapeActions()
        } else {
            this.handleOtherToolEscapeActions(eventData)
        }

        this._prevAction = null
    }

    handlePenToolEscapeActions() {
        if (this.states.cellSelection) {
            this.fire(Events.DESELECT_CELL)
        } else {
            this.setLastGeneralTool()
            this.activateElementEditMode()
        }
    }

    // The function handles actions from a tool that is not the pen tool
    handleOtherToolEscapeActions(eventData) {
        this.endAction();

        if (this.states.isEditMode) {
            if (this.states.isShapeMode && this.states.cellSelection) {
                this.fire(Events.DESELECT_CELL);
            } else if (this.states.editMode === EditMode.MOTION_PATH) {
                this.fire(Events.DESELECT_MOTION_POINT);
            } else {
                this.activateElementEditMode();
            }
        } else if (this.states.keyframeSelection) {
            this.fire(Events.DESELECT_MOTION_POINT);
            this.fire(Events.DESELECT_KEYFRAME);
        } else if (this.dataStore.getFeature('editOrigin')) {
            this.dataStore.setFeature('editOrigin', false)
        } else if (this.states.elementSelection !== ElementSelection.NONE) {
            this.fire(Events.DESELECT_MOTION_POINT);
            this.fire(Events.DESELECT_ELEMENT, eventData);
        }
    }

    delete(eventData, { modifier = false } = { modifier: false }) {
        if (this.states.inputFocus) {
            return
        }

        const focusLayerItemId = this.dataStore.selection.get('focusedLayer');
        const focusLayerPaintType = this.dataStore.editor.getLayerProp(focusLayerItemId, 'paintType')

        if (this.states.currentModal?.startsWith('PaintModal') && !gradientPaintTypes.includes(focusLayerPaintType)) {
            const layerItemId = this.states.currentModal.split('-')[1]
            this.fire(Events.DELETE_LAYER, layerItemId)
        } else if (this.states.editMode === EditMode.MOTION_PATH) {
            this.fire(Events.DELETE_MOTION_POINT)
        } else if (this.states.keyframeSelection && this.states.mode === Mode.ACTION) {
            this.fire(Events.DELETE_KEYFRAME)
        } else if (this.states.elementSelection !== ElementSelection.NONE) {
            switch (this.states.editMode) {
                case EditMode.ELEMENT:
                    this.fire(Events.DELETE_ELEMENT)
                    if (eventData !== undefined) {
                        this.fire(Events.HOVER_ELEMENT, eventData, { modifier: false, alt: false })
                    }
                    break
                case EditMode.SHAPE:
                    if (this.states.cellSelection) {
                        this.fire(Events.DELETE_CELL)
                    }
                    break
                case EditMode.TEXT:
                    if (eventData !== undefined) {
                        this.fire(Events.DELETE_TEXT, eventData, { modifier })
                    }
                    break
                case EditMode.GRADIENT_HANDLES:
                    this.fire(Events.DELETE_GRADIENT_STOP)
                    break
            }
        }
    }

    arrowKey(dir, { shift = false, modifier = false } = { shift: false, modifier: false }) {
        // UI will handle the inputFocus === true case
        if (this.states.inputFocus) {
            return
        }

        if (this.states.activeTool === ToolType.EYE_DROPPER) {
            this.setLastGeneralTool()
            return
        }

        if (!this.states.dataStoreLoaded) {
            return
        }

        switch (this.states.editMode) {
            case EditMode.MOTION_PATH: {
                if (modifier) {
                    return
                }
                this.stopAnimation()

                const delta = shift ? VECTOR_MORE_MAP[dir] : VECTOR_MAP[dir]
                this.fire(Events.MOVE_MOTION_POINTS_SELECTION_KEY, delta)
                break
            }
            case EditMode.ELEMENT:
                if (modifier) {
                    return
                }
                if (this.states.elementSelection === ElementSelection.NONE) {
                    if (shift) {
                        this.fire(Events.MOVE_VIEWPORT_KEY, VECTOR_MORE_MAP[OPPOSITE_DIRECTION[dir]])
                    } else {
                        this.fire(Events.MOVE_VIEWPORT_KEY, VECTOR_MAP[OPPOSITE_DIRECTION[dir]])
                    }
                } else {
                    this.stopAnimation()
                    if (shift) {
                        this.fire(Events.MOVE_ELEMENT_SELECTION_KEY, VECTOR_MORE_MAP[dir])
                    } else {
                        this.fire(Events.MOVE_ELEMENT_SELECTION_KEY, VECTOR_MAP[dir])
                    }
                }
                break
            case EditMode.TEXT:
                if (shift) {
                    if (modifier) {
                        this.fire(Events.EXPAND_TEXT_SELECTION_TO_NEXT_WORD_KEY, dir)
                    } else {
                        this.fire(Events.EXPAND_TEXT_SELECTION_KEY, dir)
                    }
                } else {
                    if (modifier) {
                        this.fire(Events.MOVE_TEXT_CARET_TO_NEXT_WORD_KEY, dir)
                    } else {
                        this.fire(Events.MOVE_TEXT_CARET_KEY, dir)
                    }
                }
                break
            case EditMode.SHAPE:
                if (this.states.cellSelection && !modifier) {
                    if (shift) {
                        this.fire(Events.MOVE_CELL_SELECTION_KEY, VECTOR_MORE_MAP[dir])
                    } else {
                        this.fire(Events.MOVE_CELL_SELECTION_KEY, VECTOR_MAP[dir])
                    }
                }
                break
            case EditMode.GRADIENT_HANDLES:
                if (this.states.gradientStopSelection && !modifier && GRADIENT_DIR_MORE_MAP[dir] && GRADIENT_DIR_MAP[dir]) {
                    this.fire(
                        Events.MOVE_GRADIENT_HANDLE_KEY,
                        shift ? GRADIENT_DIR_MORE_MAP[dir] : GRADIENT_DIR_MAP[dir]
                    )
                }
                break
        }
    }

    undo() {
        // force clean input focus status before undo
        this.changeInputFocus(false)
        this.fire(Events.UNDO)
        if (this.states.activeTool === ToolType.EYE_DROPPER) {
            this.setLastGeneralTool()
        }
        if (
            this._prevAction === 'DRAW_PATH' &&
            (this.states._prevEditMode === EditMode.SHAPE || this.states.isShapeMode) &&
            this._ISevent
        ) {
            this.setActiveTool(ToolType.PEN)
            this.fire(Events.HOVER_CELL_WITH_SELECTION, this._ISevent, { reconnect: true }, {
                snapToGrid: this.dataStore.data.snapToPixelGrid,
                snapToObject: this.dataStore.data.snapToObject
            })
        }
    }

    redo() {
        // force clean input focus status before redo
        this.changeInputFocus(false)
        this.fire(Events.REDO)
        if (this.states.activeTool === ToolType.EYE_DROPPER) {
            this.setLastGeneralTool()
        }
        if (
            this._prevAction === 'DRAW_PATH' &&
            (this.states._prevEditMode === EditMode.SHAPE || this.states.isShapeMode) &&
            this._ISevent
        ) {
            this.setActiveTool(ToolType.PEN)
            this.fire(Events.HOVER_CELL_WITH_SELECTION, this._ISevent, { reconnect: true })
        }
    }

    zoomIn(eventData) {
        this.fire(Events.ZOOM_IN)
        if (eventData) this.mouseMove(eventData)
    }

    zoomOut(eventData) {
        this.fire(Events.ZOOM_OUT)
        if (eventData) this.mouseMove(eventData)
    }

    zoomReset() {
        this.fire(Events.ZOOM_RESET)
    }

    zoomFitContent() {
        this.fire(Events.ZOOM_FIT_CONTENT)
    }

    zoomFitSelection() {
        this.fire(Events.ZOOM_FIT_SELECTION)
    }

    zoomCenterSelection() {
        this.fire(Events.ZOOM_CENTER_SELECTION)
    }

    zoomInToPos(e) {
        this.fire(Events.ZOOM_IN_TO_POINTER, e)
    }
    zoomOutToPos(e) {
        this.fire(Events.ZOOM_OUT_TO_POINTER, e)
    }

    zoomInToPosMac(e) {
        this.fire(Events.MAC_PINCH_ZOOM_IN_TO_POINTER, e)
    }
    zoomOutToPosMac(e) {
        this.fire(Events.MAC_PINCH_ZOOM_OUT_TO_POINTER, e)
    }

    changeZoom(zoom) {
        this.fire(Events.ZOOM_TO_VALUE, zoom)
    }

    wheelPanAndZoom(eventData, { modifier = false, ctrl = false } = { modifier: false, ctrl: false }) {
        if (modifier && !ctrl) {
            this.fire(Events.ZOOM_TO_POINTER, eventData)
        } else if (!modifier && ctrl) {
            this.fire(Events.MAC_ZOOM_TO_POINTER, eventData)
        } else {
            this.fire(Events.MOVE_VIEWPORT_WHEEL, eventData)
            if (this._prevAction === 'DRAW_PATH' && this._ISevent) {
                this.fire(Events.HOVER_CELL_WITH_SELECTION, this._ISevent, { reconnect: true })
            }
        }
        this.mouseMove(eventData)
    }

    editOrigin(enable) {
        this.stopAnimation()

        if (enable) {
            // check dataStore is hideOrigin, if true set it to false to show origin
            if (this.dataStore.data.hideOrigin) {
                this.states.prevHideOrigin = this.dataStore.data.hideOrigin
                this.states.toggleOrigin()
            }
        } else if (this._startAction === 'DRAG_ORIGIN') {
            this.endAction()
        }

        if (!enable && this.states.prevHideOrigin) {
            this.states.toggleOrigin()
            this.states.prevHideOrigin = false
        }

        this.fire(Events.EDIT_ORIGIN, enable)
    }

    setActiveTool(tool, options) {
        if (this.states.activeTool === tool) {
            return
        }

        // Should not allow the user to toggle the tool when the state is not EDITING (= VIEWING)
        if (!this.dataStore.isEditingState && !this.dataStore.isInspectingState && tool !== ToolType.HAND && tool !== ToolType.COMMENT) {
            return
        }

        if (this.states.mode === Mode.ACTION && tool in CreateElementToolType && !(tool === ToolType.PEN && this.states.isShapeMode)) {
            this.switchMode(Mode.DESIGN)
        }

        // Should always close gradient modal and switch back to element mode
        if (
            this.states.isGradientHandlesMode &&
            tool !== ToolType.HAND &&
            tool !== ToolType.EYE_DROPPER &&
            tool !== this.states.lastGeneralTool
        ) {

            this.activateElementEditMode(options)
        }

        const shapeModeAllowedTools = [ToolType.SELECT, ToolType.PEN, ToolType.HAND, ToolType.INSERT]
        if (this.states.isShapeMode) {
            if (!shapeModeAllowedTools.includes(tool)) {
                this.activateElementEditMode()
            }
        }

        if (tool === ToolType.COMMENT) {
            if (this.dataStore.get('state') === 'VERSIONING') {
                this.fire(Events.LEAVE_VERSION_PREVIEW)
            }
            this.dataStore.setFeature('editOrigin', false)
        }

        const previousTool = this.states.activeTool
        this.states.setActiveTool(tool, options)

        if (this.states.activeTool === ToolType.HAND) {
            this.activatePan()
        } else if (previousTool === ToolType.HAND) {
            this.deactivatePan()
        }
        if (tool in CreateElementToolType) {
            if (tool === ToolType.PEN) {
                if (this.states.isTextMode || this.states.isGradientHandlesMode) {
                    this.activateElementEditMode(options)
                }
            } else {
                if (this.states.isEditMode) {
                    this.activateElementEditMode(options)
                    // Call it again to make sure the tool is set correctly
                    this.states.setActiveTool(tool, options)
                }
            }
            this.dataStore.setFeature('editOrigin', false)
        }
        this.fire(Events[`ACTIVATE_${ActiveToolEventNameMap[tool]}`], { previousTool })
    }

    setLastGeneralTool() {
        this.setActiveTool(this.states.lastGeneralTool)
    }

    activateSelectTool() {
        this.setActiveTool(ToolType.SELECT)
    }

    activateScaleTool() {
        this.setActiveTool(ToolType.SCALE)
    }

    activateHandTool() {
        this.setActiveTool(ToolType.HAND)
    }

    activateRectangleTool() {
        this.setActiveTool(ToolType.RECTANGLE)
    }

    activateContainerTool() {
        this.setActiveTool(ToolType.CONTAINER)
    }

    activateEllipseTool() {
        this.setActiveTool(ToolType.ELLIPSE)
    }

    activatePolygonTool() {
        this.setActiveTool(ToolType.POLYGON)
    }

    activateStarTool() {
        this.setActiveTool(ToolType.STAR)
    }

    activateLineTool() {
        this.setActiveTool(ToolType.LINE)
    }

    activateArrowTool() {
        this.setActiveTool(ToolType.ARROW)
    }

    activatePenTool(options) {
        this.setActiveTool(ToolType.PEN, options)
    }

    activateCommentTool() {
        this.setActiveTool(ToolType.COMMENT)
    }

    activateEyeDropperTool() {
        this.setActiveTool(ToolType.EYE_DROPPER)
    }

    toggleEyeDropperTool() {
        if (this.states.activeTool === ToolType.EYE_DROPPER) {
            this.setLastGeneralTool()
        } else if (this.states.elementSelection !== ElementSelection.NONE) {
            this.activateEyeDropperTool()
        }
    }

    increaseCornerRadius() {
        this.fire(Events.INCREASE_CORNER_RADIUS)
    }

    decreaseCornerRadius() {
        this.fire(Events.DECREASE_CORNER_RADIUS)
    }

    groupElements(modifier) {
        this.fire(
            Events.GROUP_ELEMENTS,
            modifier.alt
                ? ContainerElementType.CONTAINER
                : ContainerElementType.NORMAL_GROUP
        )
    }

    booleanGroupElements(booleanType) {
        this.fire(Events.BOOLEAN_GROUP_ELEMENTS, booleanType)
    }

    maskGroupElements() {
        this.fire(Events.MASK_GROUP_ELEMENTS)
    }

    ungroupElements() {
        this.fire(Events.UNGROUP_ELEMENTS)
    }

    copy(contentType) {
        this.fire(Events.COPY, contentType)
    }

    cut(contentType) {
        this.fire(Events.CUT, contentType)
    }

    paste(e) {
        if (e.dataTransfer.files.length) {
            this.fire(Events.PASTE_WITH_FILES, e)
        } else {
            this.fire(Events.PASTE, e)
        }
    }

    duplicate() {
        if (this.states.mode === Mode.ACTION && this.states.keyframeSelection) {
            this.fire(Events.DUPLICATE_KEYFRAME)
        } else if (this.states.editMode === EditMode.SHAPE) {
            this.fire(Events.DUPLICATE_CELL)
        } else {
            this.duplicateElement()
        }
    }

    duplicateElement() {
        this.fire(Events.DUPLICATE_ELEMENT)
    }

    selectAll() {
        switch (this.states.editMode) {
            case EditMode.MOTION_PATH:
                this.fire(Events.SELECT_ALL_MOTION_POINTS)
                break
            case EditMode.SHAPE:
                this.fire(Events.SELECT_ALL_CELLS)
                break
            case EditMode.TEXT:
                this.fire(Events.SELECT_ALL_TEXT)
                break
            default:
                if (this.states.isGradientHandlesMode) {
                    this.activateElementEditMode()
                }
                this.fire(Events.SELECT_ALL_ELEMENTS)
                break
        }
    }

    toggleExpand() {
        this.fire(Events.TOGGLE_EXPAND)
    }

    toggleVisible() {
        this.fire(Events.TOGGLE_VISIBLE)
    }

    toggleLock() {
        this.fire(Events.TOGGLE_LOCK)
    }

    openModal(modalKey) {
        if (modalKey?.includes('Modal') && this.states.currentBasicModal !== modalKey && modalKey !== null) {
            this.closeModal(this.states.currentBasicModal)
        }
        this.states.changeCurrentModal(modalKey)
        this.fire(Events.OPEN_MODAL)
    }

    closeModal(modalKey) {
        const targetModal = modalKey || this.states.currentModal

        if (targetModal) {
            this.fire(Events.CLOSE_MODAL, targetModal)

            if (this.states.currentModal === modalKey || !modalKey) {
                this.states.changeCurrentModal(null)
            }
        }
        if (this.states.isGradientHandlesMode) {
            this.activateElementEditMode()
        }
    }

    changeInputFocus(focus) {
        this.states.changeInputFocus(focus)
        if (this.states.inputFocus) {
            this.stopAnimation()
        }
    }

    playAnimation() {
        if (this.states.animation) {
            return
        }
        this.fire(Events.PLAY_ANIMATION)
    }

    stopAnimation() {
        if (!this.states.animation) {
            return
        }
        this.fire(Events.STOP_ANIMATION)
    }

    resetAnimation() {
        if (!this.states.animation) {
            return
        }
        this.fire(Events.RESET_ANIMATION)
    }

    toggleAnimation() {
        if (this._pan.triggered) {
            this._pan.triggered = false
            return
        }
        if (!this.dataStore.isActionMode) {
            return
        }

        if (this.states.inputFocus) {
            return
        }
        if (this.states.animation) {
            this.stopAnimation()
        } else {
            this.playAnimation()
        }
    }

    align(direction) {
        if (this.dataStore.get('editMode') === EditMode.SHAPE && this.dataStore.editor.hasCurveControl()) {
            return
        }
        this.fire(Events.ALIGN, direction)
    }

    toggleSnapToPixelGrid() {
        this.states.toggleSnapToPixelGrid()
        this.fire(Events.TOGGLE_SNAP_TO_PIXEL_GRID)
    }

    toggleSnapToObject() {
        this.states.toggleSnapToObject()
        this.fire(Events.TOGGLE_SNAP_TO_OBJECT)
    }

    distribute(direction) {
        if (this.dataStore.get('editMode') === EditMode.SHAPE && this.dataStore.editor.hasCurveControl()) {
            return
        }
        this.fire(Events.DISTRIBUTE, direction)
    }

    setActiveGradientStop(idx) {
        this.states.setActiveGradientStop(idx)
    }

    cancelExportMedia() {
        this.fire(Events.CANCEL_EXPORT_MEDIA)
    }

    exportMedia(settings) {
        // Give a delay to wait all input fields disabled.
        // So, no UI will be block when we start to export and wait for generating media files.
        setTimeout(() => {
            this.fire(Events.EXPORT_MEDIA, settings)
        }, 200)
    }

    exportProgress(progress) {
        this.fire(Events.EXPORT_PROGRESS, progress)
    }

    exportFinish(file, options) {
        this.fire(Events.EXPORT_FINISH, file, options)
    }

    showNotification(infoType = 'info', msg) {
        this.fire(
            Events.SHOW_NOTIFICATION,
            {
                type: infoType,
                content: msg
            }
        )
    }

    fire(type, data, options, config) {
        if (this.states.canFireEvent(type)) {
            this.emit(type, data, options, config)
        }
    }

    clear() {
        this.states.clear()
    }

    toggleOrigin() {
        if (this.dataStore.getFeature('editOrigin')) return

        this.states.toggleOrigin()
        this.fire(Events.TOGGLE_ORIGIN)
    }

    toggleRuler() {
        this.fire(Events.TOGGLE_RULER)
    }

    toggleInterface() {
        this.fire(Events.TOGGLE_INTERFACE)
    }

    toggleCommentVisibility() {
        this.fire(Events.TOGGLE_COMMENT_VISIBILITY)
    }

    bringToFront() {
        this.fire(Events.BRING_TO_FRONT)
    }

    sendToBack() {
        this.fire(Events.SEND_TO_BACK)
    }

    moveForward() {
        this.fire(Events.MOVE_FORWARD)
    }

    moveBackward() {
        this.fire(Events.MOVE_BACKWARD)
    }

    togglePresencePreference() {
        this.fire(Events.TOGGLE_PRESENCE)
    }

    toggleInspecting() {
        this.fire(Events.TOGGLE_INSPECTING)
    }
}
