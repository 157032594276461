import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { FileFieldsFragment, ProjectFieldsFragment } from '../../generated/graphql'
import useHeapAnalytics from '../../hooks/useHeapAnalytics'
import { useSetNotification } from '../../providers/NotificationProvider'
import { useProfile } from '../../providers/ProfileProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { track } from '../../services/heapAnalytics'
import { isValidEmail } from '../../utils/validator'
import { Button, Dialog, Icon, LinkSwitcher, ScrollView, Select, TextArea } from '../shared'
import ListItemWithContent from '../shared/List/ListItemWithContent'
import { MenuOptionProps } from '../shared/Menu/Menu.types'
import { isSeparatorOption, translateMenuOptions } from '../shared/Menu/utils'
import { TextAreaTagItemProps } from '../shared/TextArea'
import BaseShareCollaboratorAction from './BaseShareCollaboratorAction'
import {
  COLLABORATOR_PERMISSION_OPTIONS,
  DEFAULT_PERMISSION_OPTIONS_BY_TYPE,
  getShareTypeDisplayName
} from './shareHelper'
import { AllPermissionEnum, CollaboratorPermission, ShareTypeEnum } from './shareTypes'

export type BaseShareDialogProps = {
  collaboratorList: MenuOptionProps[]
  defaultPermission?: AllPermissionEnum
  isEditable?: boolean
  /**
   * The pathname that will be appended to the base URL to create the full URL to be copied.
   */
  linkPathname: string
  name: ProjectFieldsFragment['name'] | FileFieldsFragment['name']
  onClose: () => void
  onCollaboratorPermissionChange: (collaborator: MenuOptionProps, permission: CollaboratorPermission) => Promise<void>
  onDefaultPermissionChange?: (permission: AllPermissionEnum) => Promise<void>
  onInviteCollaborators: (
    invitedCollaborators: TextAreaTagItemProps[],
    permission: CollaboratorPermission
  ) => Promise<void>
  onRemoveCollaborator: (collaborator: MenuOptionProps) => Promise<void>
  onResendInvitation: (collaborator: MenuOptionProps) => Promise<void>
  onCancelInvitation: (collaborator: MenuOptionProps) => Promise<void>
  open: boolean
  teamMemberList?: MenuOptionProps[]
  type?: ShareTypeEnum
  fileId?: string
  projectId: string
}

const BaseShareDialog = ({
  collaboratorList,
  defaultPermission,
  isEditable = false,
  linkPathname,
  name,
  onClose,
  onCollaboratorPermissionChange,
  onDefaultPermissionChange,
  onInviteCollaborators,
  onRemoveCollaborator,
  onResendInvitation,
  onCancelInvitation,
  open,
  teamMemberList,
  type,
  fileId,
  projectId
}: BaseShareDialogProps) => {
  const { t } = useTranslation(['workspace', 'common'])
  const currentUser = useProfile()
  const [invitedCollaborators, setInvitedCollaborators] = useState<TextAreaTagItemProps[]>([])
  const [invitedPermission, setInvitedPermission] = useState(CollaboratorPermission.CAN_REVIEW)
  const [invitedCollaboratorsError, setInvitedCollaboratorsError] = useState('')
  const { space, teamName } = useHeapAnalytics()
  const { workspaceData } = useWorkspaceContext()

  const TRANSLATED_DEFAULT_PERMISSION_OPTIONS_BY_TYPE = useMemo(() => {
    return translateMenuOptions(t, DEFAULT_PERMISSION_OPTIONS_BY_TYPE[type || 'project'], { ns: 'workspace' })
  }, [t, type])

  const checkIsCurrentUser = (email: MenuOptionProps['value']) => email === currentUser.email

  const existingCollaboratorList = useMemo(
    () => new Set(collaboratorList.map((item) => String(item.value).toLowerCase())),
    [collaboratorList]
  )

  const validateInvitedCollaborator = ({ value: email }: TextAreaTagItemProps) =>
    isValidEmail(email) && !checkIsCurrentUser(email) && !existingCollaboratorList.has(email)

  const checkInvitedCollaboratorsAllValid = () => {
    if (invitedCollaborators.length === 0) {
      setInvitedCollaboratorsError(t('please_enter_at_least_one_email'))
      return false
    }
    if (!invitedCollaborators.every(({ value }) => isValidEmail(value))) {
      setInvitedCollaboratorsError(t('some_emails_are_invalid'))
      return false
    }
    if (!invitedCollaborators.every(validateInvitedCollaborator)) {
      setInvitedCollaboratorsError(t('users_already_a_team_member_or_invited'))
      return false
    }
    setInvitedCollaboratorsError('')
    return true
  }

  const handleInviteCollaborators = async () => {
    if (!checkInvitedCollaboratorsAllValid()) return

    await onInviteCollaborators?.(invitedCollaborators, invitedPermission)

    setInvitedCollaborators([])

    const accessType = invitedPermission === CollaboratorPermission.CAN_EDIT ? 'edit' : 'view'

    const location = projectId === workspaceData.draftProjectId ? 'drafts' : 'project'
    if (fileId) {
      // track invitation sent to file by every user
      invitedCollaborators.forEach(() => {
        track('Invitation To File Sent', {
          space,
          location,
          teamName,
          projectId,
          fileId,
          accessType
        })
      })
    }

    if (type === 'project') {
      invitedCollaborators.forEach(() => {
        track('Invite To Project Sent', {
          space,
          location,
          teamName,
          projectId,
          accessType
        })
      })
    }
  }

  const filterTeamMember = (searchValue: string, teamMember: MenuOptionProps) => {
    if (checkIsCurrentUser(teamMember.value) || existingCollaboratorList.has(String(teamMember.value).toLowerCase())) {
      return false
    }
    return (
      teamMember.name?.toLowerCase().startsWith(searchValue.trim().toLowerCase()) ||
      String(teamMember.value).toLowerCase().startsWith(searchValue.trim().toLowerCase())
    )
  }

  const sortedCollaboratorList = useMemo(() => {
    const copyCollaboratorList = [...collaboratorList]
    copyCollaboratorList.sort((a, b) => b.isPending - a.isPending || b.isCreator - a.isCreator)
    return copyCollaboratorList
  }, [collaboratorList])

  const getDefaultPermissionName = useMemo(() => {
    if (!defaultPermission || !type) return ''
    const defaultPermissionOption = DEFAULT_PERMISSION_OPTIONS_BY_TYPE[type].find(
      (option): option is MenuOptionProps => !isSeparatorOption(option) && option.value === defaultPermission
    )
    return defaultPermissionOption?.name || ''
  }, [defaultPermission, type])

  const translatedOptions = useMemo(
    () => translateMenuOptions(t, COLLABORATOR_PERMISSION_OPTIONS, { ns: 'workspace' }),
    [t]
  )

  return (
    <Dialog
      title={t('invite_collaborators')}
      onCancel={onClose}
      footer={<DialogFooter linkPathname={linkPathname} />}
      bodyClassName="px-0"
      open={open}
    >
      {/* @ts-ignore TODO: fix after refactor of ScrollView */}
      <ScrollView className="h-[300px]">
        <div className="py-8 px-16 flex items-start gap-8">
          <TextArea
            disabled={!isEditable}
            placeholder={teamMemberList ? t('enter_user_name_or_email') : t('enter_user_email')}
            allowTags
            defaultTags={invitedCollaborators}
            error={invitedCollaboratorsError}
            onChangeTags={setInvitedCollaborators}
            customTagValidator={validateInvitedCollaborator}
            showSearch
            filterOption={teamMemberList ? filterTeamMember : undefined}
            searchMenuOptions={teamMemberList}
            wrapperClassName="basis-full"
            rightComponent={
              <Select
                // @ts-ignore TODO: fix after refactor of Select
                focusOnSelect={false}
                disabled={!isEditable}
                value={invitedPermission}
                onChange={setInvitedPermission}
                options={translatedOptions}
                size="l"
              />
            }
          />
          <Button
            disabled={!isEditable}
            size="l"
            onClick={handleInviteCollaborators}
            data-test-id="invite-btn"
            className="flex-shrink-0"
          >
            {t('common:invite')}
          </Button>
        </div>
        {type && (
          <div className="h-40 px-16 flex items-center gap-8 text-light-overlay-60">
            {isEditable ? (
              <>
                <div className="whitespace-nowrap">
                  {t('all_collaborators', { type: t(getShareTypeDisplayName(type)).toLowerCase() })}
                </div>
                <Select
                  // @ts-ignore TODO: fix after refactor of Select
                  value={defaultPermission}
                  options={TRANSLATED_DEFAULT_PERMISSION_OPTIONS_BY_TYPE}
                  onChange={onDefaultPermissionChange}
                  className="max-w-min"
                />
              </>
            ) : (
              t('all_collaborators_with_permission', {
                type: t(getShareTypeDisplayName(type)).toLowerCase(),
                permission: t(getDefaultPermissionName).toLowerCase()
              })
            )}
          </div>
        )}

        {sortedCollaboratorList.map((collaborator) => (
          <ListItemWithContent
            key={collaborator.id}
            size="l"
            title={collaborator.name}
            description={collaborator.isPending ? t('pending') : collaborator.value}
            avatarAlt={collaborator.name || ''}
            avatarSrc={collaborator.avatarImage || ''}
            tag={checkIsCurrentUser(collaborator.value) ? t('common:you') : ''}
            after={
              <BaseShareCollaboratorAction
                type={type}
                name={name}
                collaborator={collaborator}
                onPermissionChange={onCollaboratorPermissionChange}
                onResendInvitation={onResendInvitation}
                onCancelInvitation={onCancelInvitation}
                onRemove={onRemoveCollaborator}
                isCurrentUser={checkIsCurrentUser(collaborator.value)}
                isEditable={isEditable}
                fileId={fileId}
                projectId={projectId}
              />
            }
          />
        ))}
      </ScrollView>
    </Dialog>
  )
}

export default BaseShareDialog

const DialogFooter = ({ linkPathname }: { linkPathname: BaseShareDialogProps['linkPathname'] }) => {
  const { t } = useTranslation(['workspace', 'common'])

  const history = useHistory()
  const { addNotification } = useSetNotification()
  const copyLinkToClipboard = () => {
    const fullUrl = new URL(history.createHref({ pathname: linkPathname }), window.location.origin)
    navigator.clipboard.writeText(fullUrl.toString())

    addNotification({
      type: 'success',
      content: t('common:link_copied')
    })
  }

  return (
    <div className="p-16 border-t border-solid border-neutral-60 text-white">
      <LinkSwitcher onClick={copyLinkToClipboard} className="flex items-center gap-4">
        <Icon name="Link" interactive={false} useCurrentColor />
        {t('workspace:copy_link')}
      </LinkSwitcher>
    </div>
  )
}
