// eslint-disable-next-line no-unused-vars
import { Test_LineCap } from './line-caps'
import {
    MorphExample1,
    MorphExample2,
    MorphExample4,
    MorphExample5,
} from './path-morphing'
import {
    // eslint-disable-next-line no-unused-vars
    TrimPathExample1,
    // eslint-disable-next-line no-unused-vars
    TrimPathExample2,
    // eslint-disable-next-line no-unused-vars
    TrimPathExample3,
} from './trim-path'
// import {
//     Test_Boolean
// } from './boolean'

/** @typedef {import('../dino').DemoData} DemoData */

/** @type {DemoData[]} */
export const demos = [
    // Test_Boolean,

    Test_LineCap,

    MorphExample1,
    MorphExample2,
    MorphExample4,
    MorphExample5,

    // TrimPathExample1,
    // TrimPathExample2,
    // TrimPathExample3,
]
