import React, { useEffect, useState } from 'react'

import { useUndoRedo } from '../../../hooks/useUndoRedo'
import { isUndoOrRedoKeyPressed } from '../../../utils/keyboard'
import Tooltip from '../Tooltip'

type RenameTextProps = {
  className?: string
  originalText: string
  onFinish: (originalText: string) => void
  onActive?: () => void
  onCancel?: () => void
}

const RenameText = ({ className = '', originalText, onFinish, onActive, onCancel }: RenameTextProps) => {
  const [text, setText] = useState(originalText)
  const [isEditing, setIsEditing] = useState(false)
  const [isOnComposition, setIsOnComposition] = useState(false)

  const { undo, redo, updateUndoHistory, clearUndoHistory, commitChange, initiateDelete, finalizeDelete } =
    useUndoRedo()

  const handlePaste = () => {
    commitChange(text)
  }

  const handleCut = () => {
    commitChange(text)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isOnComposition) updateUndoHistory(text)
    setText(e.target.value)
  }

  const handleDoubleClick = () => {
    setIsEditing(true)
    onActive?.()
  }

  const handleCompositionStart = () => {
    commitChange(text)
    setIsOnComposition(true)
  }

  const handleCompositionEnd = () => {
    setIsOnComposition(false)
  }

  const handleBlur = () => {
    clearUndoHistory()
    onFinish(text)
    setIsEditing(false)
    onCancel?.()
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget) {
      e.target.select()
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isUndoOrRedoKeyPressed(e) && !isOnComposition) {
      e.preventDefault()
      if (e.shiftKey) {
        redo(text, (lastValue: string) => setText(lastValue))
      } else {
        undo(text, (lastValue: string) => setText(lastValue))
      }
    }
    if (e.key === 'Enter') {
      handleBlur()
    }
    if (e.key === 'Escape') {
      clearUndoHistory()
      setText(originalText)
      onFinish(originalText)
      setIsEditing(false)
      onCancel?.()
    }
    if (e.key === 'Backspace' && !isOnComposition) {
      initiateDelete(text)
    }
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !isOnComposition) {
      finalizeDelete()
    }
  }

  const preventDefault = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  useEffect(() => {
    setText(originalText)
  }, [originalText])

  return isEditing ? (
    <input
      onMouseDown={preventDefault}
      onClick={preventDefault}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      onPaste={handlePaste}
      onCut={handleCut}
      onCompositionStart={handleCompositionStart}
      onCompositionEnd={handleCompositionEnd}
      value={text}
      className={`input-system-no-handle min-w-0 flex-grow rounded-xs bg-white text-neutral-90 p-4 -ml-4 ${className}`}
      autoFocus
    />
  ) : (
    <Tooltip content={originalText} visibleOnOverflow>
      <div className="flex-grow min-w-0 truncate py-8" onDoubleClick={handleDoubleClick}>
        {originalText}
      </div>
    </Tooltip>
  )
}

export default React.memo(RenameText)
