import { useEffect } from 'react'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'

const SetDefaultPlayHeadTime = () => {
  const dataStore = useDataStore()
  useEffect(() => {
    const interactionManager = dataStore.interaction
    const responseId = interactionManager._getCurrentResponseId()
    const actionId = interactionManager._getCurrentActionId()

    const elementTrack = interactionManager.getElementTrackMap(responseId)
    const maxTime = interactionManager.getActionMaxTime(actionId)
    if (!elementTrack.size) {
      dataStore.transition.setPlayheadTime(maxTime / 2)
    }
  }, [dataStore])
  return null
}

export default SetDefaultPlayHeadTime
