/**
 * @typedef {import('../geometry/PathData').PathData} PathData
 */

const path_regex = /([ml](\s?-?((\d+(\.\d+)?)|(\.\d+)))[,\s-]+(-?((\d+(\.\d+)?)|(\.\d+))))|([hv](\s?-?((\d+(\.\d+)?)|(\.\d+))))|(c(\s?-?((\d+(\.\d+)?)|(\.\d+)))([,\s-]+(-?((\d+(\.\d+)?)|(\.\d+)))){5})|(q(\s?-?((\d+(\.\d+)?)|(\.\d+)))([,\s-]+(-?((\d+(\.\d+)?)|(\.\d+)))){3}|(t(\s?-?((\d+(\.\d+)?)|(\.\d+)))[,\s-]+(-?((\d+(\.\d+)?)|(\.\d+)))){1})|(a(\s?-?((\d+(\.\d+)?)|(\.\d+)))([,\s-]+(-?((\d+(\.\d+)?)|(\.\d+)))){2}[,\s]+[01][,\s]+[01][,\s]?([,\s]+(-?((\d+(\.\d+)?)|(\.\d+)))){2})|(s(\s?-?((\d+(\.\d+)?)|(\.\d+)))([,\s-]+(-?((\d+(\.\d+)?)|(\.\d+)))){3})|z/ig

/**
 * @param {number} px point x
 * @param {number} py point y
 * @param {number} ox mirror origin x
 * @param {number} oy mirror origin y
 */
function mirrorPoint(px, py, ox, oy) {
    const dx = ox - px
    const dy = oy - py
    return [
        ox + dx,
        oy + dy,
    ]
}

const arcToCubic = (() => {
    /**
     * @param {number} degrees
     */
    const degToRad = (degrees) => (Math.PI * degrees) / 180
    /**
     * @param {number} x
     * @param {number} y
     * @param {number} rot
     */
    const rotate = (x, y, rot) => [
        x * Math.cos(rot) - y * Math.sin(rot),
        x * Math.sin(rot) + y * Math.cos(rot),
    ]
    /**
     * @param {number} x1
     * @param {number} y1
     * @param {number} x2
     * @param {number} y2
     * @param {number} r1
     * @param {number} r2
     * @param {number} angle
     * @param {number} largeArcFlag
     * @param {number} sweepFlag
     * @param {number[]} recursive
     */
    return function arcToCubic(x1, y1, x2, y2, r1, r2, angle, largeArcFlag, sweepFlag, recursive = null) {
        const angleRad = degToRad(angle)
        /** @type {number[][]} */
        let params = []

        let f1 = 0, f2 = 0, cx = 0, cy = 0
        if (recursive) {
            [f1, f2, cx, cy] = recursive
        } else {
            // eslint-disable-next-line no-param-reassign, no-multi-assign
            [x1, y1] = rotate(x1, y1, -angleRad);
            // eslint-disable-next-line no-param-reassign
            [x2, y2] = rotate(x2, y2, -angleRad)

            const x = (x1 - x2) / 2
            const y = (y1 - y2) / 2
            let h = (x * x) / (r1 * r1) + (y * y) / (r2 * r2)
            if (h > 1) {
                h = Math.sqrt(h)
                // eslint-disable-next-line no-param-reassign
                r1 *= h
                // eslint-disable-next-line no-param-reassign
                r2 *= h
            }

            const sign = (largeArcFlag === sweepFlag) ? -1 : 1

            const r1Pow = r1 * r1
            const r2Pow = r2 * r2

            const left = r1Pow * r2Pow - r1Pow * y * y - r2Pow * x * x
            const right = r1Pow * y * y + r2Pow * x * x

            const k = sign * Math.sqrt(Math.abs(left / right))

            cx = k * r1 * y / r2 + (x1 + x2) / 2
            cy = k * -r2 * x / r1 + (y1 + y2) / 2

            f1 = Math.asin(parseFloat(((y1 - cy) / r2).toFixed(9)))
            f2 = Math.asin(parseFloat(((y2 - cy) / r2).toFixed(9)))

            if (x1 < cx) {
                f1 = Math.PI - f1
            }
            if (x2 < cx) {
                f2 = Math.PI - f2
            }

            if (f1 < 0) {
                f1 = Math.PI * 2 + f1
            }
            if (f2 < 0) {
                f2 = Math.PI * 2 + f2
            }

            if (sweepFlag && f1 > f2) {
                f1 -= Math.PI * 2
            }
            if (!sweepFlag && f2 > f1) {
                f2 -= Math.PI * 2
            }
        }

        let df = f2 - f1

        if (Math.abs(df) > (Math.PI * 120 / 180)) {
            const f2old = f2
            const x2old = x2
            const y2old = y2

            if (sweepFlag && f2 > f1) {
                f2 = f1 + (Math.PI * 120 / 180) * (1)
            }
            else {
                f2 = f1 + (Math.PI * 120 / 180) * (-1)
            }

            // eslint-disable-next-line no-param-reassign
            x2 = cx + r1 * Math.cos(f2)
            // eslint-disable-next-line no-param-reassign
            y2 = cy + r2 * Math.sin(f2)
            params = arcToCubic(x2, y2, x2old, y2old, r1, r2, angle, 0, sweepFlag, [f2, f2old, cx, cy])
        }

        df = f2 - f1

        const c1 = Math.cos(f1)
        const s1 = Math.sin(f1)
        const c2 = Math.cos(f2)
        const s2 = Math.sin(f2)
        const t = Math.tan(df / 4)
        const hx = 4 / 3 * r1 * t
        const hy = 4 / 3 * r2 * t

        const m1 = [x1, y1]
        const m2 = [x1 + hx * s1, y1 - hy * c1]
        const m3 = [x2 + hx * s2, y2 - hy * c2]
        const m4 = [x2, y2]

        m2[0] = 2 * m1[0] - m2[0]
        m2[1] = 2 * m1[1] - m2[1]

        if (recursive) {
            return [m2, m3, m4].concat(params)
        }
        else {
            params = [m2, m3, m4].concat(params)
            const curves = []
            for (let i = 0; i < params.length; i += 3) {
                const r1 = rotate(params[i][0], params[i][1], angleRad)
                const r2 = rotate(params[i + 1][0], params[i + 1][1], angleRad)
                const r3 = rotate(params[i + 2][0], params[i + 2][1], angleRad)
                curves.push([
                    'c',
                    [r1[0], r1[1], r2[0], r2[1], r3[0], r3[1]],
                    [r3[0], r3[1]],
                ])
            }
            return curves
        }
    }
})()

// (point, last_element, command) => [kind, params, curr_point] | null
const element_grammar = {
    M: (_, __, command) => [
        'm',
        command.slice(1),
        command.slice(1),
    ],
    m: (point, _, command) => element_grammar.M(point, _, [
        command[0],
        point[0] + command[1],
        point[1] + command[2],
    ]),

    L: (_, __, command) => [
        'l',
        command.slice(1),
        command.slice(1),
    ],
    l: (point, _, command) => element_grammar.L(point, _, [
        command[0],
        point[0] + command[1],
        point[1] + command[2],
    ]),
    H: (point, _, command) => [
        'l',
        [command[1], point[1]],
        [command[1], point[1]],
    ],
    h: (point, _, command) => element_grammar.H(point, _, [
        command[0],
        point[0] + command[1],
        point[1],
    ]),
    V: (point, _, command) => [
        'l',
        [point[0], command[1]],
        [point[0], command[1]],
    ],
    v: (point, _, command) => element_grammar.V(point, _, [
        command[0],
        point[0],
        point[1] + command[1],
    ]),

    Q: (_, __, command) => [
        'q',
        command.slice(1),
        command.slice(3),
    ],
    q: (point, _, command) => element_grammar.Q(point, _, [
        command[0],
        point[0] + command[1], point[1] + command[2],
        point[0] + command[3], point[1] + command[4],
    ]),
    T: (_, last_element, command) => {
        if (last_element.kind !== "q") {
            console.warn(`Previous command of "T" isn't "Q/q"`)
            return ['l', command.slice(1), command.slice(1)]
        }
        const [cx, cy] = mirrorPoint(
            last_element.params[0], last_element.params[1], // control point
            last_element.params[2], last_element.params[3]  // end point
        )
        return [
            'q',
            [cx, cy, command[1], command[2]],
            command.slice(1),
        ]
    },
    t: (point, last_element, command) => element_grammar.T(point, last_element, [
        command[0],
        point[0] + command[1],
        point[1] + command[2],
    ]),

    C: (_, __, command) => [
        'c',
        command.slice(1),
        command.slice(5),
    ],
    c: (point, _, command) => element_grammar.C(point, _, [
        command[0],
        point[0] + command[1], point[1] + command[2],
        point[0] + command[3], point[1] + command[4],
        point[0] + command[5], point[1] + command[6],
    ]),
    S: (_, last_element, command) => {
        if (last_element.kind !== "c") {
            console.warn(`Previous command of "S" isn't "C/c"`)
            return ['l', command.slice(3), command.slice(3)]
        }
        const [c1x, c1y] = mirrorPoint(
            last_element.params[2], last_element.params[3], // control point
            last_element.params[4], last_element.params[5]  // end point
        )
        return [
            'c',
            [c1x, c1y, command[1], command[2], command[3], command[4]],
            command.slice(3),
        ]
    },
    s: (point, last_element, command) => element_grammar.S(point, last_element, [
        command[0],
        point[0] + command[1], point[1] + command[2],
        point[0] + command[3], point[1] + command[4],
    ]),

    A: (point, _, command) => {
        const r1 = Math.abs(command[1])
        const r2 = Math.abs(command[2])
        const angle = command[3]
        const largeArcFlag = command[4]
        const sweepFlag = command[5]
        const x = command[6]
        const y = command[7]
        const cx = point[0]
        const cy = point[1]
        if (r1 === 0 || r2 === 0) {
            return ['c', [cx, cy, x, y, x, y], [x, y]]
        }
        if (cx === x && cy === y) {
            return null
        }
        return arcToCubic(
            cx, cy,
            x, y,
            r1, r2,
            angle,
            largeArcFlag,
            sweepFlag
        )
    },
    a: (point, _, command) => element_grammar.A(point, _, [
        command[0],
        command[1], command[2],
        command[3],
        command[4],
        command[5],
        point[0] + command[6], point[1] + command[7],
    ]),

    // eslint-disable-next-line no-unused-vars
    Z: (point, _, __) => ['z', [], point],
    // eslint-disable-next-line no-unused-vars
    z: (point, _, __) => element_grammar.Z(point, _, __),
}

/**
 * @param {string} xml
 * @param {string} tag
 */
function xmlNodeToDict(xml, tag) {
    // eslint-disable-next-line no-useless-escape
    const regex = new RegExp(`<${tag}(((\\s?\\w+(-\\w+)*\\s?)?=\\"([^\\"]*)\\")+)\\s?\/?>`, "gmi")
    const match = regex.exec(xml)
    if (match) {
        const attr_str = match[1].trim()
        const attrs = attr_str.split(/"\s+/)
        return attrs.map(attr => {
            const tokens = attr.split('=')
            return [
                tokens[0].trim(),                       // key
                tokens[1].trim().replace(/^"|"$/g, ''), // value
            ]
        }).reduce((dict, pair) => {
            dict[pair[0]] = pair[1]
            return dict
        }, Object.create(null))
    }
    return []
}

/**
 * @param {number} x
 * @param {number} y
 * @param {number} rx
 * @param {number} ry
 */
function ellipseToCubic(x, y, rx, ry) {
    const magic = 0.55228475
    const cx_len = rx * magic
    const cy_len = ry * magic

    return (
        `M${x - rx},${y}`
        +
        `C${x - rx},${y + cy_len} ${x - cx_len},${y + ry} ${x},${y + ry}`
        +
        `C${x + cx_len},${y + ry} ${x + rx},${y + cy_len} ${x + rx},${y}`
        +
        `C${x + rx},${y - cy_len} ${x + cx_len},${y - ry} ${x},${y - ry}`
        +
        `C${x - cx_len},${y - ry} ${x - rx},${y - cy_len} ${x - rx},${y}`
    )
}

/**
 * @param {number} x
 * @param {number} y
 * @param {number} r
 */
function circleToCubic(x, y, r) {
    return ellipseToCubic(x, y, r, r)
}

// eslint-disable-next-line no-useless-escape
const xml_node_regex = /<([^ >!\/]+)[^>]*>/g
/**
 * Collect all primitive shapes and path data attributes inside an SVG:
 *  turn: <svg...> <rect /> <path d="path data 1"/> </svg>
 *  into: ["MLLLZ", "path data 1", ...]
 * @param {string} svg SVG XML string
 * @returns {string[]}
 */
export function collectPathData(svg) {
    // eslint-disable-next-line no-param-reassign
    svg = svg.trim()
    const nodes = svg.match(xml_node_regex)
    if (!nodes) return []

    return nodes.map(node => {
        // eslint-disable-next-line no-param-reassign
        node = node.trim()

        if (node.startsWith("<rect")) {
            const props = xmlNodeToDict(node, "rect")
            const x = parseFloat(props.x)
            const y = parseFloat(props.y)
            const w = parseFloat(props.width)
            const h = parseFloat(props.height)
            return `M${x},${y}L${x+w},${y}L${x+w},${y+h}L${x},${y+h}z`
        } else if (node.startsWith("<circle")) {
            const props = xmlNodeToDict(node, "circle")
            const cx = parseFloat(props.cx)
            const cy = parseFloat(props.cy)
            const r = parseFloat(props.r)
            return circleToCubic(cx, cy, r)
        } else if (node.startsWith("<ellipse")) {
            const props = xmlNodeToDict(node, "ellipse")
            const cx = parseFloat(props.cx)
            const cy = parseFloat(props.cy)
            const rx = parseFloat(props.rx)
            const ry = parseFloat(props.ry)
            return ellipseToCubic(cx, cy, rx, ry)
        } else if (node.startsWith("<line")) {
            const props = xmlNodeToDict(node, "line")
            const x1 = parseFloat(props.x1)
            const y1 = parseFloat(props.y1)
            const x2 = parseFloat(props.x2)
            const y2 = parseFloat(props.y2)
            return `M${x1},${y1}L${x2},${y2}`
        } else if (node.startsWith("<polyline")) {
            const props = xmlNodeToDict(node, "polyline")
            const points = props.points.split(/[,\s-]+/g).map(s => parseFloat(s))
            let path = `M${points[0]},${points[1]}`
            for (let i = 2; i < points.length; i += 2) {
                path += `L${points[i]},${points[i+1]}`
            }
            return path
        } else if (node.startsWith("<polygon")) {
            const props = xmlNodeToDict(node, "polygon")
            const points = props.points.split(/[,\s-]+/g).map(s => parseFloat(s))
            let path = `M${points[0]},${points[1]}`
            for (let i = 2; i < points.length; i += 2) {
                path += `L${points[i]},${points[i + 1]}`
            }
            path += 'z'
            return path
        } else if (node.startsWith("<path")) {
            const props = xmlNodeToDict(node, "path")
            const path = props.d
            return path
        }
    }).filter(dict => !!dict)
}

/**
 * convert path data into elements of only "m, l, q, c, a, z" kinds and uses only absolute positions
 *
 * @param {string} pathStr SVG path data attribute (something like "M0,0L100,0L100,100Z")
 * @returns {PathData}
 */
export function parseSVGPathData(pathStr) {
    let curr_point = [0, 0]
    /** @type {{ kind: "m"|"l"|"q"|"c"|"a"|"z", params: number[] }} */
    let last_element = null
    const pathData = pathStr.match(path_regex)
        .reduce((paths, command) => {
            const data = [command[0]]
            if (!command[0].match(/z/gi)) {
                const params = command.substring(1)
                    .trim()
                    .match(/-?((\d+(\.\d+)?)|(\.\d+))/gi)
                    .map(s => Number.parseFloat(s))

                data.length = params.length + 1
                for (let i = 0; i < params.length; i++) {
                    data[i + 1] = params[i]
                }
            }
            const result = element_grammar[data[0]](curr_point, last_element, data)
            if (result) {
                if (Array.isArray(result[0])) {
                    for (const [kind, params, point] of result) {
                        curr_point = point
                        last_element = { kind, params }
                        paths.push(last_element)
                    }
                } else {
                    const [kind, params, point] = result
                    curr_point = point
                    last_element = { kind, params }
                    paths.push(last_element)
                }
            }
            return paths
        }, [])
        .reduce((out, path)=> {
            out.commands.push(path.kind)
            out.vertices.push(...path.params)
            return out
        }, {commands: [], vertices: []})
    pathData.commands = pathData.commands.map(convertCommand)
    return pathData
}

/**
 * @param {PathData} data
 * @param {boolean} [lineBreakPerElement]
 */
export function pathDataToString(data, lineBreakPerElement = false) {
    let svg = ""
    let i = 0
    for (let c = 0; c < data.commands.length; ++c) {
        switch (data.commands[c]) {
            // M
            case 1: {
                const x = data.vertices[i++]
                const y = data.vertices[i++]
                svg += `M${x},${y}${lineBreakPerElement ? '\n' : ' '}`
            } break
            // L
            case 2: {
                const x = data.vertices[i++]
                const y = data.vertices[i++]
                svg += `L${x},${y}${lineBreakPerElement ? '\n' : ' '}`
            } break
            // Q
            case 3: {
                const cpx = data.vertices[i++]
                const cpy = data.vertices[i++]
                const x = data.vertices[i++]
                const y = data.vertices[i++]
                svg += `Q${cpx},${cpy},${x},${y}${lineBreakPerElement ? '\n' : ' '}`
            } break
            // C
            case 4: {
                const cpx1 = data.vertices[i++]
                const cpy1 = data.vertices[i++]
                const cpx2 = data.vertices[i++]
                const cpy2 = data.vertices[i++]
                const x = data.vertices[i++]
                const y = data.vertices[i++]
                svg += `C${cpx1},${cpy1},${cpx2},${cpy2},${x},${y}${lineBreakPerElement ? '\n' : ' '}`
            } break
        }
    }
    return svg.trimEnd()
}

const convertCommand = (c) => [0, 'm', 'l', 'q', 'c'].indexOf(c)
