import * as HME from 'h264-mp4-encoder'
import { VideoQuality } from '@phase-software/types'
import Encoder from '../encoder'

/** @type {object} */
const COMPRESS_QUALITY = {
    [VideoQuality.ULTRA]: 10,
    [VideoQuality.HIGH]: 23,
    [VideoQuality.MEDIUM]: 36,
    [VideoQuality.LOW]: 49,
}

export class MP4Encoder extends Encoder {
    constructor() {
        super()

        this._encoder = null
        this._rawWidth = 0
        this._rawlHeight = 0
    }

    async init(options) {
        this._encoder = await HME.createH264MP4Encoder()
        this._rawWidth = options.width
        this._rawlHeight = options.height
        // Size must be a multiple of 2
        this._encoder.width = options.width + options.width % 2
        this._encoder.height = options.height + options.height % 2
        this._encoder.frameRate = options.fps
        this._encoder.speed = options.speed
        this._encoder.quantizationParameter = COMPRESS_QUALITY[options.quality]
        this._encoder.initialize()
    }

    static getEstimatedSize({ width, height, fps, duration, speed, videoQuality }){
        const adjustedDuration = duration / speed // Adjust duration based on speed
        const colorBits = 8 // Assuming 8 bits per color channel
        const channels = 4 // Assuming RGBA
        const quantizationParameter = COMPRESS_QUALITY[videoQuality] // COMPRESS_QUALITY[options.quality]

        const baselineCoefficient = 0.05 // 0.1 to 0.25 is a typical range
        const sizeInKilobytes = baselineCoefficient * (width * height * channels * colorBits * fps * adjustedDuration) / (8 * 1024 * quantizationParameter)

        return sizeInKilobytes
    }

    /**
     * Add frame data to encoder
     * @param {Uint8Array} pixels
     */
    addFrame(pixels) {
        let paddedPixels = pixels
        // Padding the pixel array when neccessary
        if (pixels.length !== this._encoder.width * this._encoder.height * 4) {
            paddedPixels = new Uint8Array(this._encoder.width * this._encoder.height * 4).fill(0)
            let i = 0
            while ((i * 4) < pixels.length) {
                const row = Math.floor(i / this._rawWidth)
                const col = i % this._rawWidth
                const ii = row * this._encoder.width + col
                paddedPixels[ii * 4 + 0] = pixels[i * 4 + 0]
                paddedPixels[ii * 4 + 1] = pixels[i * 4 + 1]
                paddedPixels[ii * 4 + 2] = pixels[i * 4 + 2]
                paddedPixels[ii * 4 + 3] = pixels[i * 4 + 3]
                i++
            }
        }
        this._encoder.addFrameRgba(paddedPixels)
    }

    /**
     * Get encoder data
     * @returns {Uint8Array} bytes
     */
    getData() {
        return this._encoder.FS.readFile(this._encoder.outputFilename)
    }

    /**
     * Finish adding the last frame to encoder
     */
    finalize() {
        this._encoder.finalize()
    }

    /**
     * End of the encoder
     */
    end() {
        this._encoder.delete()
    }

    /**
     * Cancel encoder
     */
    cancel() {
        this.finalize()
        this.end()
    }
}
