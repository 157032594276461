import { Rect2, CalcCubicInflections } from '../math'
import { Command } from '../geometry/PathData'
import { WASM } from '../wasm/platforms/wasm'
import { CubicBez } from '../geometry/bezier-shape/CubicBez'
import { QuadBez } from '../geometry/bezier-shape/QuadBez'

/** @typedef {import('../gfx').Gfx} Gfx */
/** @typedef {import('../gfx').Gfx_Buffer_t} Gfx_Buffer_t */
/** @typedef {import('../math/Vector2').Vector2} Vector2 */
/** @typedef {import('../geometry/PathData').PathData} PathData */
/** @typedef {import('./TextResource').TextResource} TextResource */

const ARTIFACT_SPLIT_THRESHOLD = 1e-8

export function VectorResource() {
    /** @type {number} */
    this.id = 0
    /**
     * 0: base
     * 1: mod
     * 2..: stroke[geo_type-2]
     * @type {number}
     */
    this.geo_type = 0

    /** @type {Data} */
    this.data = { type: 'RAW' }

    /** @type {PathData} */
    this.path = null

    /** @type {number[]} */
    this.vertices = null

    /** @type {Float32Array} */
    this.triangles = null

    this.bounds = new Rect2()

    /** @type {[number, number] | null} */
    this.rect = null
}
VectorResource.prototype = {
    constructor: VectorResource,

    getData() {
        return this.triangles
    },

    setDataType(type) {
        this.data.type = type
    },

    /**
     * @param {PathData} data
     */
    setPathDirectly(data) {
        this.path = data
        this._dirty = true
    },

    clear() {
        this.vertices = null
        this.triangles = null
        if (this.buffer) {
            this.gfx.destroyBuffer(this.buffer)
            this.buffer = null
            this.vertLen = 0
        }
        this.bounds.set(0, 0, 0, 0)
    },

    free() {
        this.clear()
        this.path = null
    },

    buildTrianglesJS() {
        this.clear()

        if (!this.path) return

        const { commands, vertices } = this.path
        if(commands.length === 0 || vertices.length === 0) {
            this.triangles = []
            this.vertices = []
            this.bounds.set(0, 0, 0, 0)
            return
        }

        // cover geometry, right now it is just a rectangle
        const verts = [
            0, 0,
            0,0,0,

            1, 0,
            0,0,0,

            0, 1,
            0,0,0,

            1, 1,
            0,0,0,

            0, 0,
            1,0,0,

            1, 1,
            1,0,0,
        ]
        this.vertices = verts

        let minX = Number.MAX_SAFE_INTEGER, maxX = Number.MIN_SAFE_INTEGER
        let minY = Number.MAX_SAFE_INTEGER, maxY = Number.MIN_SAFE_INTEGER

        let i = 0
        let x1 = 0, y1 = 0
        let x2 = 0, y2 = 0
        let cx = 0, cy = 0
        let c1x = 0, c1y = 0
        let c2x = 0, c2y = 0

        let firstX = 0
        let firstY = 0

        let pCount = 0

        const end = () => {
            const tmp = new Rect2(minX, minY, maxX - minX, maxY - minY)
            this.bounds.merge_with(tmp)

            minX = Number.MAX_SAFE_INTEGER
            maxX = Number.MIN_SAFE_INTEGER
            minY = Number.MAX_SAFE_INTEGER
            maxY = Number.MIN_SAFE_INTEGER
        }

        // calc bounds and fill polygon
        for (const command of commands) {
            x1 = x2
            y1 = y2

            switch (command) {
                case Command.M: {
                    x1 = vertices[i++]
                    y1 = vertices[i++]
                    x2 = x1
                    y2 = y1

                    minX = Math.min(minX, x1)
                    minY = Math.min(minY, y1)
                    maxX = Math.max(maxX, x1)
                    maxY = Math.max(maxY, y1)

                    if (i === 2) {
                        this.bounds.set(x1, y1, 0, 0)
                    } else {
                        end()
                    }

                    firstX = x1
                    firstY = y1

                    pCount = 0
                } break
                case Command.L: {
                    x2 = vertices[i++]
                    y2 = vertices[i++]

                    minX = Math.min(minX, x2)
                    minY = Math.min(minY, y2)
                    maxX = Math.max(maxX, x2)
                    maxY = Math.max(maxY, y2)
                } break
                case Command.Q: {
                    cx = vertices[i++]
                    cy = vertices[i++]
                    x2 = vertices[i++]
                    y2 = vertices[i++]

                    const bounds = new QuadBez().initN(x1, y1, cx, cy, x2, y2).getBounds()
                    minX = Math.min(minX, bounds.x)
                    minY = Math.min(minY, bounds.y)
                    maxX = Math.max(maxX, bounds.x + bounds.width)
                    maxY = Math.max(maxY, bounds.y + bounds.height)
                } break
                case Command.C: {
                    c1x = vertices[i++]
                    c1y = vertices[i++]
                    c2x = vertices[i++]
                    c2y = vertices[i++]
                    x2 = vertices[i++]
                    y2 = vertices[i++]

                    let curve = new CubicBez().initWith4PointsN(x1, y1, c1x, c1y, c2x, c2y, x2, y2)
                    // TODO: this artifact fix is for cubic curves only
                    if (!curve.isStraight()) {
                        const THRESHOLD = 1e-6
                        const OFFSET = 1e-6
                        let fixed = false
                        if ((x1 - c1x) * (x1 - c1x) + (y1 - c1y) * (y1 - c1y) < THRESHOLD) {
                            const nl = Math.max(Math.hypot(c2x - x2, c2y - y2), THRESHOLD)
                            const nx = (c2x - x2) / nl
                            const ny = (c2y - y2) / nl
                            c1x += nx * OFFSET
                            c1y += ny * OFFSET

                            vertices[i - 6] = c1x
                            vertices[i - 5] = c1y

                            fixed = true
                        }
                        if ((x2 - c2x) * (x2 - c2x) + (y2 - c2y) * (y2 - c2y) < THRESHOLD) {
                            const nl = Math.max(Math.hypot(c1x - x1, c1y - y1), THRESHOLD)
                            const nx = (c1x - x1) / nl
                            const ny = (c1y - y1) / nl
                            c2x += nx * OFFSET
                            c2y += ny * OFFSET

                            vertices[i - 4] = c2x
                            vertices[i - 3] = c2y

                            fixed = true
                        }

                        if (fixed) {
                            curve = new CubicBez().initWith4PointsN(x1, y1, c1x, c1y, c2x, c2y, x2, y2)
                        }
                    }
                    const bounds = curve.getBounds()
                    minX = Math.min(minX, bounds.x)
                    minY = Math.min(minY, bounds.y)
                    maxX = Math.max(maxX, bounds.x + bounds.width)
                    maxY = Math.max(maxY, bounds.y + bounds.height)
                } break
            }

            // polygon triangles
            if (command !== Command.M) {
                pCount++
                if (pCount >= 2) {
                    PushSolidTri(verts,
                        firstX, firstY,
                        x1, y1,
                        x2, y2
                    )
                }
            }
        }

        if (i > 2) {
            end()
        }

        // curve triangles
        i = 0
        for (let idx = 0, len = commands.length; idx < len; idx++) {
            const c = commands[idx]

            x1 = x2
            y1 = y2

            switch (c) {
                case Command.M: {
                    x1 = vertices[i++]
                    y1 = vertices[i++]
                    x2 = x1
                    y2 = y1

                    firstX = x1
                    firstY = y1
                } break
                case Command.L: {
                    x2 = vertices[i++]
                    y2 = vertices[i++]
                } break
                case Command.Q: {
                    cx = vertices[i++]
                    cy = vertices[i++]
                    x2 = vertices[i++]
                    y2 = vertices[i++]

                    AddQuadTriangles(verts,
                        x1, y1,
                        cx, cy,
                        x2, y2
                    )
                } break
                case Command.C: {
                    c1x = vertices[i++]
                    c1y = vertices[i++]
                    c2x = vertices[i++]
                    c2y = vertices[i++]
                    x2 = vertices[i++]
                    y2 = vertices[i++]

                    AddCubicTriangles(verts,
                        x1, y1,
                        c1x, c1y,
                        c2x, c2y,
                        x2, y2,
                        SPLIT_LIMIT,
                        -1
                    )
                } break
            }
        }

        // this.triangles = new Float32Array(this.vertices)
    },

    /**
     * Check if the mouse is inside the shape
     * @param {Vector2} mousePos - world mouse position
     * @returns {bool}
     */
    isPointInside(mousePos) {
        if (this.id > 0) {
            return WASM().isPointInsideGeometry(this.id, mousePos.x, mousePos.y) > 0
        }
        return false
    },
}

/**
 * @typedef {[number,number, number,number, number,number, number,number]} CubicData_t
 *
 * @param {CubicData_t} curve
 * @param {number} t
 * @param {CubicData_t} r_left
 * @param {CubicData_t} r_right
 */
function SplitCubicBezier(curve, t, r_left, r_right) {
    const x1 = curve[0]
    const y1 = curve[1]
    const c1x = curve[2]
    const c1y = curve[3]
    const c2x = curve[4]
    const c2y = curve[5]
    const x2 = curve[6]
    const y2 = curve[7]

    const x01 = (c1x - x1) * t + x1
    const y01 = (c1y - y1) * t + y1
    const x12 = (c2x - c1x) * t + c1x
    const y12 = (c2y - c1y) * t + c1y
    const x23 = (x2 - c2x) * t + c2x
    const y23 = (y2 - c2y) * t + c2y

    const x012 = (x12 - x01) * t + x01
    const y012 = (y12 - y01) * t + y01
    const x123 = (x23 - x12) * t + x12
    const y123 = (y23 - y12) * t + y12

    const x0123 = (x123 - x012) * t + x012
    const y0123 = (y123 - y012) * t + y012

    r_left[0] = x1
    r_left[1] = y1
    r_left[2] = x01
    r_left[3] = y01
    r_left[4] = x012
    r_left[5] = y012
    r_left[6] = x0123
    r_left[7] = y0123

    r_right[0] = x0123
    r_right[1] = y0123
    r_right[2] = x123
    r_right[3] = y123
    r_right[4] = x23
    r_right[5] = y23
    r_right[6] = x2
    r_right[7] = y2
}

/**
 * @param {number} p1x
 * @param {number} p1y
 * @param {number} p2x
 * @param {number} p2y
 * @param {number} p3x
 * @param {number} p3y
 * @returns {number}
 */
function Orientation(p1x, p1y, p2x, p2y, p3x, p3y) {
    return (p2y - p1y) * (p3x - p2x) - (p3y - p2y) * (p2x - p1x)
}

const inflections = [0, 0]
const one_third = 1 / 3
const two_third = 2 / 3

/**
 * @param {number[]} r_out
 * @param {number} x1
 * @param {number} y1
 * @param {number} cx
 * @param {number} cy
 * @param {number} x2
 * @param {number} y2
 */
function AddQuadTriangles(r_out, x1, y1, cx, cy, x2, y2) {
    r_out.push(
        x1, y1,   0.0, 0.0, 0.0,
        cx, cy,   0.5, 0.0, 0.5,
        x2, y2,   1.0, 1.0, 1.0,
    )
}

/**
 * @param {number} type
 * @returns {(keyof typeof CurveTypes) | "unknown"}
 */
function CurveTypeName(type) {
    for (const name in CurveTypes) {
        if (CurveTypes[name] === type) {
            return name
        }
    }
    return "unknown"
}

const DEBUG = false
const SPLIT_LIMIT = 10
/**
 * @param {number[]} r_out
 * @param {number} x1
 * @param {number} y1
 * @param {number} c1x
 * @param {number} c1y
 * @param {number} c2x
 * @param {number} c2y
 * @param {number} x2
 * @param {number} y2
 * @param {number} splitLimit
 * @param {0 | -1 | +1} parentOrientation
 */
function AddCubicTriangles(r_out, x1, y1, c1x, c1y, c2x, c2y, x2, y2, splitLimit, parentOrientation = 0) {
    if (splitLimit === 0) return
    vec3_Set(b0, x1, y1, 1)
    vec3_Set(b1, c1x, c1y, 1)
    vec3_Set(b2, c2x, c2y, 1)
    vec3_Set(b3, x2, y2, 1)

    vec2_Cross(cross1, b3, b2)
    vec2_Cross(cross2, b0, b3)
    vec2_Cross(cross3, b1, b0)

    const a1 = vec2_Dot(b0, cross1)
    const a2 = vec2_Dot(b1, cross2)
    const a3 = vec2_Dot(b2, cross3)

    const d1 = a1 - 2 * a2 + 3 * a3
    const d2 = -a2 + 3 * a3
    const d3 = 3 * a3

    let type = DetectCurveType(d1, d2, d3)
    if (DEBUG) {
        const split = SPLIT_LIMIT - splitLimit
        // eslint-disable-next-line prefer-template
        console.log(`${CurveTypeName(type)}${(split > 0) ? ("(" + split + ")") : ""}`)
    }

    const ori_a = Orientation(x1, y1, x2, y2, c1x, c1y)
    const ori_b = Orientation(x1, y1, x2, y2, c2x, c2y)
    let ori = Math.sign((Math.abs(ori_a) > Math.abs(ori_b)) ? ori_a : ori_b)

    /** @type {number[]} */
    let klm = null
    let flip = false

    switch (type) {
        case CurveTypes.LineOrPoint: {
            /* line */

            klm = SetKLM(
                0, 0, 0,
                0, 0, 0,
                0, 0, 0,
                0, 0, 0
            )
        } break
        case CurveTypes.Quadratic: {
            /* quad */

            klm = SetKLM(
                0,
                0,
                0,

                one_third,
                0,
                one_third,

                two_third,
                one_third,
                two_third,

                1,
                1,
                1
            )

            flip = (d3 < 0)

            if (ori > 0) {
                flip = !flip
            }
        } break
        case CurveTypes.Cusp: {
            /* cusp */

            const t1 = Math.sqrt(4 * d1 * d3 - 3 * d2 * d2)
            const ls = d2 - t1
            const lt = 2 * d1
            const ms = d2 + t1
            const mt = lt

            const lt_ls = lt - ls
            const mt_ms = mt - ms
            klm = SetKLM(
                ls * ms,
                ls * ls * ms,
                ls * ms * ms,

                one_third * (-ls * mt - lt * ms + 3 * ls * ms),
                -one_third * ls * (ls * (mt - 3 * ms) + 2 * lt * ms),
                -one_third * ms * (ls * (2 * mt - 3 * ms) + lt * ms),

                one_third * (lt * (mt - 2 * ms) + ls * (3 * ms - 2 * mt)),
                one_third * (lt - ls) * (ls * (2 * mt - 3 * ms) + lt * ms),
                one_third * (mt - ms) * (ls * (mt - 3 * ms) + 2 * lt * ms),

                lt_ls * mt_ms,
                -(lt_ls * lt_ls) * mt_ms,
                -lt_ls * mt_ms * mt_ms
            )
        } break
        case CurveTypes.Loop: {
            /* loop */

            const t1 = Math.sqrt(4 * d1 * d3 - 3 * d2 * d2)
            const ls = d2 - t1
            const lt = 2 * d1
            const ms = d2 + t1
            const mt = lt

            const ql = ls / lt
            if (0 < ql && ql < 1) {
                if (Math.abs(ql) > ARTIFACT_SPLIT_THRESHOLD && Math.abs(1 - ql) > ARTIFACT_SPLIT_THRESHOLD) {
                    type = CurveTypes.LoopA
                }
            }

            const qm = ms / mt
            if (0 < qm && qm < 1) {
                if (Math.abs(qm) > ARTIFACT_SPLIT_THRESHOLD && Math.abs(1 - qm) > ARTIFACT_SPLIT_THRESHOLD) {
                    type = CurveTypes.LoopB
                }
            }

            if (DEBUG) {
                if (type !== CurveTypes.Loop) {
                    console.log(`-> ${CurveTypeName(type)}`)
                }
                console.log(`   ori:${ori}, p_ori:${parentOrientation}`)
            }

            const lt_ls = lt - ls
            const mt_ms = mt - ms
            klm = SetKLM(
                ls * ms,
                ls * ls * ms,
                ls * ms * ms,

                one_third * (-ls * mt - lt * ms + 3 * ls * ms),
                -one_third * ls * (ls * (mt - 3 * ms) + 2 * lt * ms),
                -one_third * ms * (ls * (2 * mt - 3 * ms) + lt * ms),

                one_third * (lt * (mt - 2 * ms) + ls * (3 * ms - 2 * mt)),
                one_third * (lt - ls) * (ls * (2 * mt - 3 * ms) + lt * ms),
                one_third * (mt - ms) * (ls * (mt - 3 * ms) + 2 * lt * ms),

                lt_ls * mt_ms,
                -(lt_ls * lt_ls) * mt_ms,
                -lt_ls * mt_ms * mt_ms
            )

            if (splitLimit === SPLIT_LIMIT) {
                flip = ((d1 > 0 && klm[0] < 0) || (d1 < 0 && klm[0] > 0))
            }

            switch (type) {
                case CurveTypes.LoopA: {
                    const left = [], right = []

                    // LoopA can be split into 2 parts:
                    // 1. one small arc basically is the open version of LoopB's close segment (means no self intersection here)
                    // 2. one flatter curve
                    // So we should use first point and 2 control points for orientation
                    const ori_loop_a = Math.sign(Orientation(x1, y1, c1x, c1y, c2x, c2y))
                    if (DEBUG) {
                        console.log(`   LoopA.ori = ${ori_loop_a}`)
                    }
                    ori = ori_loop_a

                    SplitCubicBezier([
                        x1,y1, c1x,c1y, c2x,c2y, x2,y2
                    ], ql, left, right)

                    AddCubicTriangles(r_out,
                        left[0], left[1],
                        left[2], left[3],
                        left[4], left[5],
                        left[6], left[7],
                        splitLimit-1,
                        ori
                    )
                    AddCubicTriangles(r_out,
                        right[0], right[1],
                        right[2], right[3],
                        right[4], right[5],
                        right[6], right[7],
                        splitLimit-1,
                        -ori
                    )
                    PushSolidTri(r_out,
                        left[0], left[1],
                        right[0], right[1],
                        right[6], right[7]
                    )

                    return
                }
                case CurveTypes.LoopB: {
                    const left = [], right = []

                    // LoopB can be split into 2 parts:
                    // 1. one close arc (begin and end point are the same)
                    // 2. one flat curve from the arc's join
                    // So we should use first point and 2 control points for orientation just like LoopA
                    const ori_loop_b = Math.sign(Orientation(x1, y1, c1x, c1y, c2x, c2y))
                    if (DEBUG) {
                        console.log(`   LoopB.ori = ${ori_loop_b}`)
                    }
                    ori = ori_loop_b

                    SplitCubicBezier([
                        x1,y1, c1x,c1y, c2x,c2y, x2,y2
                    ], qm, left, right)

                    AddCubicTriangles(r_out,
                        left[0], left[1],
                        left[2], left[3],
                        left[4], left[5],
                        left[6], left[7],
                        splitLimit-1,
                        -ori
                    )
                    AddCubicTriangles(r_out,
                        right[0], right[1],
                        right[2], right[3],
                        right[4], right[5],
                        right[6], right[7],
                        splitLimit-1,
                        ori
                    )
                    PushSolidTri(r_out,
                        left[0], left[1],
                        right[0], right[1],
                        right[6], right[7]
                    )

                    return
                }
            }
        } break
        case CurveTypes.Serpentine: {
            /* serp */

            // split serpertine into 2 loop curves
            const splits = CalcCubicInflections(inflections,
                x1, y1,
                c1x, c1y,
                c2x, c2y,
                x2, y2
            )

            if (splits === 0) {
                const t1 = Math.sqrt(9 * d2 * d2 - 12 * d1 * d3)
                const ls = 3 * d2 - t1
                const lt = 6 * d1
                const ms = 3 * d2 + t1
                const mt = lt

                klm = SetKLM(
                    ls * ms,
                    ls * ls * ls,
                    ms * ms * ms,

                    one_third * (3 * ls * ms - ls * mt - lt * ms),
                    ls * ls * (ls - lt),
                    ms * ms * (ms - mt),

                    one_third * (lt * (mt - 2 * ms) + ls * (3 * ms - 2 * mt)),
                    (lt - ls) * (lt - ls) * ls,
                    (mt - ms) * (mt - ms) * ms,

                    (lt - ls) * (mt - ms),
                    -((lt - ls) * (lt - ls) * (lt - ls)),
                    -((mt - ms) * (mt - ms) * (mt - ms))
                )

                flip = d1 < 0
                if (ori > 0) {
                    flip = !flip
                }
            } else {
                const left = [], right = []

                SplitCubicBezier([
                    x1, y1,
                    c1x, c1y,
                    c2x, c2y,
                    x2, y2,
                ], inflections[0], left, right)

                AddCubicTriangles(r_out,
                    left[0], left[1],
                    left[2], left[3],
                    left[4], left[5],
                    left[6], left[7],
                    splitLimit-1
                )
                AddCubicTriangles(r_out,
                    right[0], right[1],
                    right[2], right[3],
                    right[4], right[5],
                    right[6], right[7],
                    splitLimit-1
                )
                PushSolidTri(r_out,
                    left[0], left[1],
                    right[0], right[1],
                    right[6], right[7]
                )

                return
            }
        } break
    }

    if (splitLimit < SPLIT_LIMIT) {
        if (type !== CurveTypes.Serpentine) {
            if (parentOrientation !== ori) {
                flip = !flip
            }
            flip = parentOrientation < 0
        }
    } else {
        if (type === CurveTypes.Loop) {
            if (ori > 0) {
                flip = !flip
            }
        }
    }

    if (flip) {
        FlipKLM(klm)
    }

    Triangulation(r_out, type, x1, y1, c1x, c1y, c2x, c2y, x2, y2, klm)
}

const CURVE_EPS = 1e-8

const Triangulation = (() => {
    const vertices = [
        { x: -1, y: -1, k: -1, l: -1, m: -1 },
        { x: -1, y: -1, k: -1, l: -1, m: -1 },
        { x: -1, y: -1, k: -1, l: -1, m: -1 },
        { x: -1, y: -1, k: -1, l: -1, m: -1 },
    ]

    /**
     * @param {number} idx
     * @param {number} x
     * @param {number} y
     * @param {number} k
     * @param {number} l
     * @param {number} m
     */
    function SetVert(idx, x, y, k, l, m) {
        vertices[idx].x = x
        vertices[idx].y = y
        vertices[idx].k = k
        vertices[idx].l = l
        vertices[idx].m = m
    }

    /**
     * @param {number} x1
     * @param {number} y1
     * @param {number} cx1
     * @param {number} cy1
     * @param {number} cx2
     * @param {number} cy2
     * @param {number} x2
     * @param {number} y2
     * @returns {number}
     */
    function CalcCurveControlsConvex(x1, y1, cx1, cy1, cx2, cy2, x2, y2) {
        return (
            (x2 - x1) * (cy1 - y1) - (y2 - y1) * (cx1 - x1)
        ) * (
            (x2 - x1) * (cy2 - y1) - (y2 - y1) * (cx2 - x1)
        )
    }

    /**
     * @param {number[]} r_out
     * @param {number} type
     * @param {number} x0
     * @param {number} y0
     * @param {number} x1
     * @param {number} y1
     * @param {number} x2
     * @param {number} y2
     * @param {number} x3
     * @param {number} y3
     * @param {number[]} klm
     */
    return function Triangulation(r_out, type, x0, y0, x1, y1, x2, y2, x3, y3, klm) {
        SetVert(0, x0, y0, klm[0], klm[1], klm[2])
        SetVert(1, x1, y1, klm[3], klm[4], klm[5])
        SetVert(2, x2, y2, klm[6], klm[7], klm[8])
        SetVert(3, x3, y3, klm[9], klm[10], klm[11])

        switch (type) {
            case CurveTypes.Quadratic: {
                PushTriWithVertex(r_out,
                    vertices[0],
                    vertices[1],
                    vertices[2]
                )
                PushTriWithVertex(r_out,
                    vertices[0],
                    vertices[2],
                    vertices[3]
                )
            } break
            case CurveTypes.Cusp: {
                const convex = CalcCurveControlsConvex(x0, y0, x1, y1, x2, y2, x3, y3)

                if (convex > 0) {
                    /* convex */

                    PushTriWithVertex(r_out,
                        vertices[0],
                        vertices[1],
                        vertices[2]
                    )
                    PushTriWithVertex(r_out,
                        vertices[0],
                        vertices[1],
                        vertices[3]
                    )
                } else if (convex < 0) {
                    /* concave */

                    PushTriWithVertex(r_out,
                        vertices[0],
                        vertices[2],
                        vertices[3]
                    )
                    PushTriWithVertex(r_out,
                        vertices[0],
                        vertices[3],
                        vertices[1]
                    )
                } else {
                    /* line? */

                    PushTriWithVertex(r_out,
                        vertices[0],
                        vertices[1],
                        vertices[2]
                    )
                    PushTriWithVertex(r_out,
                        vertices[0],
                        vertices[2],
                        vertices[3]
                    )
                }
            } break
            case CurveTypes.Loop: {
                PushTriWithVertex(r_out,
                    vertices[0],
                    vertices[1],
                    vertices[2]
                )
                PushTriWithVertex(r_out,
                    vertices[0],
                    vertices[2],
                    vertices[3]
                )
            } break
            case CurveTypes.LoopA: {
                PushTriWithVertex(r_out,
                    vertices[0],
                    vertices[1],
                    vertices[2]
                )
                PushTriWithVertex(r_out,
                    vertices[0],
                    vertices[2],
                    vertices[3]
                )
            } break
            case CurveTypes.LoopB: {
                PushTriWithVertex(r_out,
                    vertices[0],
                    vertices[1],
                    vertices[2]
                )
                PushTriWithVertex(r_out,
                    vertices[0],
                    vertices[1],
                    vertices[3]
                )
            } break
            case CurveTypes.Serpentine: {
                const convex = CalcCurveControlsConvex(x0, y0, x1, y1, x2, y2, x3, y3)
                if (convex > 0) {
                    /* convex */

                    PushTriWithVertex(r_out,
                        vertices[0],
                        vertices[1],
                        vertices[2]
                    )
                    PushTriWithVertex(r_out,
                        vertices[0],
                        vertices[2],
                        vertices[3]
                    )
                } else if (convex < 0) {
                    /* concave */

                    PushTriWithVertex(r_out,
                        vertices[0],
                        vertices[2],
                        vertices[3]
                    )
                    PushTriWithVertex(r_out,
                        vertices[0],
                        vertices[3],
                        vertices[1]
                    )

                    PushSolidTri(r_out,
                        x0, y0,
                        x2, y2,
                        x3, y3
                    )
                } else {
                    /* line? */

                    PushTriWithVertex(r_out,
                        vertices[0],
                        vertices[2],
                        vertices[3]
                    )
                    PushTriWithVertex(r_out,
                        vertices[0],
                        vertices[1],
                        vertices[3]
                    )
                }
            } break
        }
    }
})()

/**
 * @param {number[]} r_out
 * @param {number[]} a
 * @param {number[]} b
 * @returns {number[]}
 */
function vec2_Cross(r_out, a, b) {
    const ax = a[0], ay = a[1], az = a[2]
    const bx = b[0], by = b[1], bz = b[2]
    r_out[0] = ay * bz - az * by
    r_out[1] = az * bx - ax * bz
    r_out[2] = ax * by - ay * bx
    return r_out
}

/**
 * @param {number[]} a
 * @param {number[]} b
 * @returns {number}
 */
function vec2_Dot(a, b) {
    return a[0] * b[0] + a[1] * b[1] + a[2] * b[2]
}

/**
 * @param {number[]} vec
 * @param {number} x
 * @param {number} y
 * @param {number} z
 * @returns {number[]}
 */
function vec3_Set(vec, x, y, z) {
    vec[0] = x
    vec[1] = y
    vec[2] = z
    return vec
}

/** @enum {number} */
const CurveTypes = {
    Serpentine: 0,

    Loop: 10,
    LoopA: 11,
    LoopB: 12,

    CuspInflectionAtInf: 20,
    CuspCuspAtInf: 21,
    Cusp: 22, // CuspInf or CuspCusp

    Quadratic: 30,

    LineOrPoint: 40,
}

/**
 * @param {number} num
 * @returns {boolean}
 */
function IsZero(num) {
    return (num >= 0) ? (num < CURVE_EPS) : (num > -CURVE_EPS)
}

/**
 * @param {number} d1
 * @param {number} d2
 * @param {number} d3
 * @returns {CurveTypes}
 */
function DetectCurveType(d1, d2, d3) {
    const D = 3 * d2 * d2 - 4 * d1 * d3
    const discriminant = d1 * d1 * D

    if (IsZero(discriminant)) {
        if (IsZero(d1) && IsZero(d2)) {
            if (IsZero(d3)) {
                return CurveTypes.LineOrPoint
            } else {
                return CurveTypes.Quadratic
            }
        }
        if (!IsZero(d1)) {
            return CurveTypes.Cusp
        }
        if (D < 0) {
            return CurveTypes.Loop
        }
        return CurveTypes.Serpentine
    }

    if (discriminant > 0) {
        return CurveTypes.Serpentine
    }

    return CurveTypes.Loop
}

/* @Incomplete Add "LoopA" and "LoopB" checks */
// /**
//  * @param {number} d1
//  * @param {number} d2
//  * @param {number} d3
//  * @returns {CurveTypes}
//  */
// function DetectCurveTypeDetailed(d1, d2, d3) {
//     const D = 3 * d2 * d2 - 4 * d1 * d3

//     if (!IsZero(d1)) {
//         if (D > CURVE_EPS) {
//             return CurveTypes.Serpentine
//         } else if (D < -CURVE_EPS) {
//             return CurveTypes.Loop
//         } else {
//             return CurveTypes.CuspInflectionAtInf
//         }
//     } else if (!IsZero(d2)) {
//         return CurveTypes.CuspCuspAtInf
//     } else if (IsZero(d3)) {
//         return CurveTypes.LineOrPoint
//     } else {
//         return CurveTypes.Quadratic
//     }
// }

const b0 = [0, 0, 0]
const b1 = [0, 0, 0]
const b2 = [0, 0, 0]
const b3 = [0, 0, 0]

const cross1 = [0, 0]
const cross2 = [0, 0]
const cross3 = [0, 0]

const C = [
    0, 0, 0,
    0, 0, 0,
    0, 0, 0,
    0, 0, 0,
]

/**
 * @param {number} m00
 * @param {number} m10
 * @param {number} m20
 * @param {number} m01
 * @param {number} m11
 * @param {number} m21
 * @param {number} m02
 * @param {number} m12
 * @param {number} m22
 * @param {number} m03
 * @param {number} m13
 * @param {number} m23
 * @returns {number[]}
 */
function SetKLM(m00, m10, m20, m01, m11, m21, m02, m12, m22, m03, m13, m23) {
    C[ 0] = m00
    C[ 1] = m10
    C[ 2] = m20
    C[ 3] = m01
    C[ 4] = m11
    C[ 5] = m21
    C[ 6] = m02
    C[ 7] = m12
    C[ 8] = m22
    C[ 9] = m03
    C[10] = m13
    C[11] = m23
    return C
}
/**
 * @param {number[]} klm
 * @returns {number[]}
 */
function FlipKLM(klm) {
    klm[0] = -klm[0]; klm[ 1] = -klm[ 1]
    klm[3] = -klm[3]; klm[ 4] = -klm[ 4]
    klm[6] = -klm[6]; klm[ 7] = -klm[ 7]
    klm[9] = -klm[9]; klm[10] = -klm[10]
    return klm
}

/**
 * @param {number[]} r_out
 * @param {{ x: number, y: number, k: number, l: number, m: number }} v0
 * @param {{ x: number, y: number, k: number, l: number, m: number }} v1
 * @param {{ x: number, y: number, k: number, l: number, m: number }} v2
 */
function PushTriWithVertex(r_out, v0, v1, v2) {
    r_out.push(
        v0.x, v0.y,  v0.k, v0.l, v0.m,
        v1.x, v1.y,  v1.k, v1.l, v1.m,
        v2.x, v2.y,  v2.k, v2.l, v2.m
    )
}
/**
 * @param {number[]} r_out
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 * @param {number} x3
 * @param {number} y3
 */
function PushSolidTri(r_out, x1, y1, x2, y2, x3, y3) {
    r_out.push(
        x1, y1, 1.0, 1.0, 1.0,
        x2, y2, 1.0, 1.0, 1.0,
        x3, y3, 1.0, 1.0, 1.0
    )
}

// /**
//  * @typedef {number[]} Vertices [x,y,k,l,m, ...]
//  * @param {Vertices} verts
//  * @param {number} A pointer to A
//  * @param {number} B pointer to B
//  * @param {number} C pointer to C
//  * @param {number} x
//  * @param {number} y
//  * @returns {number}
//  */
// function intersect(verts, A, B, C, x, y) {
//     const inv_det = 1 / ((verts[B+1] - verts[C+1]) * (verts[A] - verts[C]) + (verts[C] - verts[B]) * (verts[A+1] - verts[C+1]))
//     const factor_alpha = (verts[B+1] - verts[C+1]) * (x - verts[C]) + (verts[C] - verts[B]) * (y - verts[C+1])
//     const factor_beta = (verts[C+1] - verts[A+1]) * (x - verts[C]) + (verts[A] - verts[C]) * (y - verts[C+1])

//     const alpha = factor_alpha * inv_det
//     const beta = factor_beta  * inv_det
//     const gamma = 1.0 - alpha - beta

//     if (
//         (alpha < 0 || alpha > 1)
//         ||
//         (beta < 0 || beta > 1)
//         ||
//         (gamma < 0 || gamma > 1)
//     ) {
//         /* P is outside triangle ABC */
//         return 0
//     }

//     const k = verts[A+2] * alpha + verts[B+2] * beta + verts[C+2] * gamma
//     const l = verts[A+3] * alpha + verts[B+3] * beta + verts[C+3] * gamma
//     const m = verts[A+4] * alpha + verts[B+4] * beta + verts[C+4] * gamma

//     const ccw = (verts[A] * verts[B+1] + verts[B] * verts[C+1] + verts[C] * verts[A+1]) > (verts[B] * verts[A+1] + verts[C] * verts[B+1] + verts[A] * verts[C+1])
//     const dir = ccw ? 1 : -1

//     return (k * k * k <= l * m) ? dir : 0
// }
