import { FunctionComponent, SVGProps } from 'react'

import { ReactComponent as Action } from './svgs/Action.svg'
import { ReactComponent as Add } from './svgs/Add.svg'
import { ReactComponent as AddWithOutline } from './svgs/AddWithOutline.svg'
import { ReactComponent as AdvAdd } from './svgs/AdvAdd.svg'
import { ReactComponent as AlignCenter } from './svgs/AlignCenter.svg'
import { ReactComponent as AlignJustified } from './svgs/AlignJustified.svg'
import { ReactComponent as AlignLeft } from './svgs/AlignLeft.svg'
import { ReactComponent as AlignRight } from './svgs/AlignRight.svg'
import { ReactComponent as Angle } from './svgs/Angle.svg'
import { ReactComponent as AngleDown16 } from './svgs/AngleDown16.svg'
import { ReactComponent as AngleLeft16 } from './svgs/AngleLeft16.svg'
import { ReactComponent as AngleLeft24 } from './svgs/AngleLeft24.svg'
import { ReactComponent as AngleRight16 } from './svgs/AngleRight16.svg'
import { ReactComponent as AngleRight24 } from './svgs/AngleRight24.svg'
import { ReactComponent as AngleTop16 } from './svgs/AngleTop16.svg'
import { ReactComponent as Animated } from './svgs/Animated.svg'
import { ReactComponent as Archived } from './svgs/Archived.svg'
import { ReactComponent as ArrowDirectionDown } from './svgs/ArrowDirectionDown.svg'
import { ReactComponent as ArrowDirectionLeft } from './svgs/ArrowDirectionLeft.svg'
import { ReactComponent as ArrowDirectionRight } from './svgs/ArrowDirectionRight.svg'
import { ReactComponent as ArrowDirectionUp } from './svgs/ArrowDirectionUp.svg'
import { ReactComponent as ArrowDown12 } from './svgs/ArrowDown12.svg'
import { ReactComponent as ArrowDown } from './svgs/ArrowDown.svg'
import { ReactComponent as ArrowLeft } from './svgs/ArrowLeft.svg'
import { ReactComponent as ArrowLeftDown } from './svgs/ArrowLeftDown.svg'
import { ReactComponent as ArrowLeftUp } from './svgs/ArrowLeftUp.svg'
import { ReactComponent as ArrowRight } from './svgs/ArrowRight.svg'
import { ReactComponent as ArrowRightDown } from './svgs/ArrowRightDown.svg'
import { ReactComponent as ArrowRightUp } from './svgs/ArrowRightUp.svg'
import { ReactComponent as ArrowSortDown } from './svgs/ArrowSortDown.svg'
// Arrow for sorting
import { ReactComponent as ArrowSortLeft } from './svgs/ArrowSortLeft.svg'
import { ReactComponent as ArrowSortRight } from './svgs/ArrowSortRight.svg'
import { ReactComponent as ArrowSortUp } from './svgs/ArrowSortUp.svg'
import { ReactComponent as ArrowUp } from './svgs/ArrowUp.svg'
import { ReactComponent as AspectRatio } from './svgs/AspectRatio.svg'
import { ReactComponent as AutoOrientOff } from './svgs/AutoOrientOff.svg'
import { ReactComponent as AutoOrientOn } from './svgs/AutoOrientOn.svg'
import { ReactComponent as Bell } from './svgs/Bell.svg'
import { ReactComponent as BlendOff } from './svgs/BlendOff.svg'
import { ReactComponent as BlendOn } from './svgs/BlendOn.svg'
import { ReactComponent as Bolt } from './svgs/Bolt.svg'
import { ReactComponent as Brush } from './svgs/Brush.svg'
import { ReactComponent as Capitalize } from './svgs/Capitalize.svg'
import { ReactComponent as CharacterSpacing } from './svgs/CharacterSpacing.svg'
import { ReactComponent as Chat } from './svgs/Chat24.svg'
import { ReactComponent as Check } from './svgs/Check.svg'
import { ReactComponent as CheckBold } from './svgs/CheckBold.svg'
import { ReactComponent as CircleArrowFill } from './svgs/CircleArrowFill.svg'
import { ReactComponent as CircleArrowOutline } from './svgs/CircleArrowOutline.svg'
import { ReactComponent as Clock } from './svgs/Clock.svg'
import { ReactComponent as Code } from './svgs/Code.svg'
import { ReactComponent as Cog } from './svgs/Cog.svg'
import { ReactComponent as Collapse } from './svgs/Collapse.svg'
import { ReactComponent as Collapsed } from './svgs/Collapsed.svg'
import { ReactComponent as Comment } from './svgs/Comment.svg'
import { ReactComponent as Compound } from './svgs/Compound.svg'
import { ReactComponent as Contain } from './svgs/Contain.svg'
import { ReactComponent as Container } from './svgs/Container.svg'
import { ReactComponent as Copy } from './svgs/Copy.svg'
import { ReactComponent as Cross12 } from './svgs/Cross12.svg'
import { ReactComponent as Cross } from './svgs/Cross.svg'
import { ReactComponent as Crosshair } from './svgs/Crosshair.svg'
import { ReactComponent as Danger } from './svgs/Danger.svg'
import { ReactComponent as DeleteCircle } from './svgs/DeleteCircle.svg'
// workspace
import { ReactComponent as Doc } from './svgs/Doc.svg'
import { ReactComponent as Dot } from './svgs/Dot.svg'
import { ReactComponent as Download } from './svgs/Download.svg'
import { ReactComponent as Dribble } from './svgs/Dribble.svg'
import { ReactComponent as Dropper } from './svgs/Dropper.svg'
import { ReactComponent as Duplicate } from './svgs/Duplicate.svg'
import { ReactComponent as EaseCustom } from './svgs/EaseCustom.svg'
import { ReactComponent as EaseIn } from './svgs/EaseIn.svg'
import { ReactComponent as EaseInBack } from './svgs/EaseInBack.svg'
import { ReactComponent as EaseInOut } from './svgs/EaseInOut.svg'
import { ReactComponent as EaseInOutBack } from './svgs/EaseInOutBack.svg'
import { ReactComponent as EaseOut } from './svgs/EaseOut.svg'
import { ReactComponent as EaseOutBack } from './svgs/EaseOutBack.svg'
import { ReactComponent as Else } from './svgs/Else.svg'
import { ReactComponent as Emoji } from './svgs/Emoji.svg'
import { ReactComponent as EndRound } from './svgs/EndRound.svg'
import { ReactComponent as EndStraight } from './svgs/EndStraight.svg'
import { ReactComponent as EraseOff } from './svgs/EraseOff.svg'
import { ReactComponent as EraseOn } from './svgs/EraseOn.svg'
import { ReactComponent as Error } from './svgs/Error.svg'
import { ReactComponent as Exclamation } from './svgs/Exclamation.svg'
import { ReactComponent as ExpandLess } from './svgs/ExpandLess.svg'
import { ReactComponent as ExpandMore } from './svgs/ExpandMore.svg'
import { ReactComponent as Export24 } from './svgs/Export24.svg'
import { ReactComponent as Eye } from './svgs/Eye.svg'
import { ReactComponent as EyeClose } from './svgs/EyeClose.svg'
import { ReactComponent as Facebook } from './svgs/Facebook.svg'
import { ReactComponent as Figma } from './svgs/Figma.svg'
import { ReactComponent as FilledAngle } from './svgs/FilledAngle.svg'
import { ReactComponent as Filter } from './svgs/Filter.svg'
import { ReactComponent as Fold } from './svgs/Fold.svg'
import { ReactComponent as Folder } from './svgs/Folder.svg'
import { ReactComponent as FolderOutline } from './svgs/FolderOutline.svg'
import { ReactComponent as FontLoader } from './svgs/FontLoader.svg'
import { ReactComponent as FontSize } from './svgs/FontSize.svg'
import { ReactComponent as GeometryArrow } from './svgs/GeometryArrow.svg'
import { ReactComponent as GeometryDifference } from './svgs/GeometryDifference.svg'
import { ReactComponent as GeometryIntersect } from './svgs/GeometryIntersect.svg'
import { ReactComponent as GeometryMask } from './svgs/GeometryMask.svg'
import { ReactComponent as GeometryMaskArrow } from './svgs/GeometryMaskArrow.svg'
import { ReactComponent as GeometryNone } from './svgs/GeometryNone.svg'
import { ReactComponent as GeometrySubtract } from './svgs/GeometrySubtract.svg'
import { ReactComponent as GeometryUnion } from './svgs/GeometryUnion.svg'
import { ReactComponent as Google } from './svgs/Google.svg'
import { ReactComponent as GridView } from './svgs/GridView.svg'
import { ReactComponent as HAlignCenter } from './svgs/HAlignCenter.svg'
import { ReactComponent as HAlignLeft } from './svgs/HAlignLeft.svg'
import { ReactComponent as HAlignRight } from './svgs/HAlignRight.svg'
// alignment tools
import { ReactComponent as HSpacing } from './svgs/HSpacing.svg'
import { ReactComponent as Hand } from './svgs/Hand.svg'
import { ReactComponent as Help } from './svgs/Help.svg'
import { ReactComponent as Hexagon } from './svgs/Hexagon.svg'
import { ReactComponent as Idea } from './svgs/Idea.svg'
import { ReactComponent as If } from './svgs/If.svg'
import { ReactComponent as Image } from './svgs/Image.svg'
// notification
import { ReactComponent as Info } from './svgs/Info.svg'
import { ReactComponent as InsertImage } from './svgs/InsertImage.svg'
import { ReactComponent as Instagram } from './svgs/Instagram.svg'
import { ReactComponent as JoinBevel } from './svgs/JoinBevel.svg'
// stroke join shape
import { ReactComponent as JoinConcave } from './svgs/JoinConcave.svg'
import { ReactComponent as JoinMiter } from './svgs/JoinMiter.svg'
import { ReactComponent as JoinNone } from './svgs/JoinNone.svg'
import { ReactComponent as JoinRound } from './svgs/JoinRound.svg'
import { ReactComponent as JumpToEnd } from './svgs/JumpToEnd.svg'
import { ReactComponent as JumpToStart } from './svgs/JumpToStart.svg'
import { ReactComponent as KeyboardArrowDown } from './svgs/KeyboardArrowDown.svg'
import { ReactComponent as KeyboardArrowLeft } from './svgs/KeyboardArrowLeft.svg'
import { ReactComponent as KeyboardArrowRight } from './svgs/KeyboardArrowRight.svg'
import { ReactComponent as KeyboardArrowUp } from './svgs/KeyboardArrowUp.svg'
import { ReactComponent as Label } from './svgs/Label.svg'
import { ReactComponent as LabelMask } from './svgs/LabelMask.svg'
import { ReactComponent as Line } from './svgs/Line.svg'
import { ReactComponent as LineArrow } from './svgs/LineArrow.svg'
import { ReactComponent as LineArrowRound } from './svgs/LineArrowRound.svg'
import { ReactComponent as LineSpacing } from './svgs/LineSpacing.svg'
import { ReactComponent as Linear } from './svgs/Linear.svg'
import { ReactComponent as Link } from './svgs/Link.svg'
import { ReactComponent as ListView } from './svgs/ListView.svg'
import { ReactComponent as Loading } from './svgs/Loading.svg'
import { ReactComponent as Location } from './svgs/Location.svg'
import { ReactComponent as Lock } from './svgs/Lock.svg'
import { ReactComponent as LockOff } from './svgs/LockOff.svg'
import { ReactComponent as LockOn } from './svgs/LockOn.svg'
import { ReactComponent as Logout } from './svgs/Logout.svg'
import { ReactComponent as Loop } from './svgs/Loop.svg'
import { ReactComponent as LoopOff } from './svgs/LoopOff.svg'
import { ReactComponent as LoopOn } from './svgs/LoopOn.svg'
import { ReactComponent as LowerCase } from './svgs/LowerCase.svg'
import { ReactComponent as MiniArrowDown } from './svgs/MiniArrowDown.svg'
import { ReactComponent as MiniArrowUp } from './svgs/MiniArrowUp.svg'
import { ReactComponent as Mix } from './svgs/Mix.svg'
import { ReactComponent as More } from './svgs/More.svg'
import { ReactComponent as NewProject } from './svgs/NewProject.svg'
import { ReactComponent as Next } from './svgs/Next.svg'
import { ReactComponent as NormalGroup } from './svgs/NormalGroup.svg'
import { ReactComponent as Offset } from './svgs/Offset.svg'
import { ReactComponent as OpenLink } from './svgs/OpenLink.svg'
import { ReactComponent as Operations } from './svgs/Operations.svg'
import { ReactComponent as Or } from './svgs/Or.svg'
import { ReactComponent as Origin } from './svgs/Origin.svg'
import { ReactComponent as OriginCenter } from './svgs/OriginCenter.svg'
import { ReactComponent as Oval } from './svgs/Oval.svg'
import { ReactComponent as ParagraphIndent } from './svgs/ParagraphIndent.svg'
import { ReactComponent as ParagraphSpacing } from './svgs/ParagraphSpacing.svg'
import { ReactComponent as Path } from './svgs/Path.svg'
import { ReactComponent as Pause24 } from './svgs/Pause24.svg'
import { ReactComponent as Pause } from './svgs/Pause.svg'
import { ReactComponent as PauseOutline } from './svgs/PauseOutline.svg'
import { ReactComponent as Pen } from './svgs/Pen.svg'
import { ReactComponent as PendingUser16 } from './svgs/PendingUser16.svg'
import { ReactComponent as PendingUser28 } from './svgs/PendingUser28.svg'
import { ReactComponent as PendingUser32 } from './svgs/PendingUser32.svg'
import { ReactComponent as Pentagon } from './svgs/Pentagon.svg'
import { ReactComponent as Phase } from './svgs/Phase.svg'
import { ReactComponent as Play24 } from './svgs/Play24.svg'
import { ReactComponent as Play } from './svgs/Play.svg'
import { ReactComponent as PlayCircle } from './svgs/PlayCircle.svg'
// Playback control
import { ReactComponent as PlayOutline } from './svgs/PlayOutline.svg'
import { ReactComponent as PlayTriangle } from './svgs/PlayTriangle.svg'
import { ReactComponent as Plus } from './svgs/Plus.svg'
import { ReactComponent as PlusCircle } from './svgs/PlusCircle.svg'
import { ReactComponent as PointAsymmetric } from './svgs/PointAsymmetric.svg'
import { ReactComponent as PointIndependent } from './svgs/PointIndependent.svg'
import { ReactComponent as PointMirror } from './svgs/PointMirror.svg'
import { ReactComponent as PointStraight } from './svgs/PointStraight.svg'
import { ReactComponent as Previous } from './svgs/Previous.svg'
// setting
import { ReactComponent as Profile } from './svgs/Profile.svg'
import { ReactComponent as Question } from './svgs/Question.svg'
import { ReactComponent as Radius } from './svgs/Radius.svg'
import { ReactComponent as Reaction } from './svgs/Reaction.svg'
import { ReactComponent as Rectangle } from './svgs/Rectangle.svg'
import { ReactComponent as RectangleH } from './svgs/RectangleH.svg'
import { ReactComponent as RectangleV } from './svgs/RectangleV.svg'
import { ReactComponent as Refresh } from './svgs/Refresh.svg'
import { ReactComponent as Remove } from './svgs/Remove.svg'
import { ReactComponent as Repeat24 } from './svgs/Repeat24.svg'
import { ReactComponent as Replace } from './svgs/Replace.svg'
import { ReactComponent as Resolve } from './svgs/Resolve.svg'
import { ReactComponent as ResolvedThread } from './svgs/ResolvedThread.svg'
import { ReactComponent as Rotate } from './svgs/Rotate.svg'
import { ReactComponent as RotateAngle } from './svgs/RotateAngle.svg'
import { ReactComponent as ScaleX } from './svgs/ScaleX.svg'
import { ReactComponent as ScaleY } from './svgs/ScaleY.svg'
import { ReactComponent as Screen } from './svgs/Screen.svg'
import { ReactComponent as Search } from './svgs/Search.svg'
import { ReactComponent as SearchCircle } from './svgs/SearchCircle.svg'
import { ReactComponent as Select } from './svgs/Select.svg'
import { ReactComponent as Setting } from './svgs/Setting.svg'
import { ReactComponent as Sketch } from './svgs/Sketch.svg'
import { ReactComponent as SkewX } from './svgs/SkewX.svg'
import { ReactComponent as SkewY } from './svgs/SkewY.svg'
import { ReactComponent as Sliders } from './svgs/Sliders.svg'
import { ReactComponent as Speed } from './svgs/Speed.svg'
import { ReactComponent as SquareArrowFill } from './svgs/SquareArrowFill.svg'
import { ReactComponent as SquareArrowFillRound } from './svgs/SquareArrowFillRound.svg'
import { ReactComponent as SquareArrowOutline } from './svgs/SquareArrowOutline.svg'
import { ReactComponent as SquareArrowOutlineRound } from './svgs/SquareArrowOutlineRound.svg'
import { ReactComponent as Stack } from './svgs/Stack.svg'
import { ReactComponent as Star } from './svgs/Star.svg'
import { ReactComponent as Step } from './svgs/Step.svg'
import { ReactComponent as Stop24 } from './svgs/Stop24.svg'
import { ReactComponent as Strikethrough } from './svgs/Strikethrough.svg'
import { ReactComponent as StrokeWidth } from './svgs/StrokeWidth.svg'
import { ReactComponent as Success } from './svgs/Success.svg'
import { ReactComponent as Template } from './svgs/Template.svg'
import { ReactComponent as Text } from './svgs/Text.svg'
import { ReactComponent as Timeline } from './svgs/Timeline.svg'
// Color picker
import { ReactComponent as Toggle } from './svgs/Toggle.svg'
import { ReactComponent as Trash } from './svgs/Trash.svg'
import { ReactComponent as Triangle } from './svgs/Triangle.svg'
import { ReactComponent as TriangleArrowFill } from './svgs/TriangleArrowFill.svg'
import { ReactComponent as TriangleArrowFillRound } from './svgs/TriangleArrowFillRound.svg'
import { ReactComponent as TriangleArrowOutline } from './svgs/TriangleArrowOutline.svg'
import { ReactComponent as TriangleArrowOutlineRound } from './svgs/TriangleArrowOutlineRound.svg'
import { ReactComponent as TriangleDown } from './svgs/TriangleDown.svg'
import { ReactComponent as TriangleLeft } from './svgs/TriangleLeft.svg'
import { ReactComponent as TriangleRight } from './svgs/TriangleRight.svg'
import { ReactComponent as TriangleUp } from './svgs/TriangleUp.svg'
import { ReactComponent as Trigger } from './svgs/Trigger.svg'
// effects
import { ReactComponent as TrimPath } from './svgs/TrimPath.svg'
import { ReactComponent as TutorialVideo } from './svgs/TutorialVideo.svg'
import { ReactComponent as Twitter } from './svgs/Twitter.svg'
import { ReactComponent as Unanimated } from './svgs/Unanimated.svg'
import { ReactComponent as Underline } from './svgs/Underline.svg'
import { ReactComponent as Undo } from './svgs/Undo.svg'
import { ReactComponent as Unfold } from './svgs/Unfold.svg'
import { ReactComponent as Unlink } from './svgs/Unlink.svg'
import { ReactComponent as UnreadComment } from './svgs/UnreadComment.svg'
import { ReactComponent as UpperCase } from './svgs/UpperCase.svg'
import { ReactComponent as Users24 } from './svgs/Users24.svg'
import { ReactComponent as VAlignBottom } from './svgs/VAlignBottom.svg'
import { ReactComponent as VAlignMiddle } from './svgs/VAlignMiddle.svg'
import { ReactComponent as VAlignTop } from './svgs/VAlignTop.svg'
import { ReactComponent as VSpacing } from './svgs/VSpacing.svg'
import { ReactComponent as VersionHistory } from './svgs/VersionHistory.svg'
import { ReactComponent as Warning } from './svgs/Warning.svg'
import { ReactComponent as WordSpacing } from './svgs/WordSpacing.svg'
import { ReactComponent as Zoom } from './svgs/Zoom.svg'
import { ReactComponent as ZoomIn } from './svgs/ZoomIn.svg'
import { ReactComponent as ZoomOut } from './svgs/ZoomOut.svg'

interface SvgIconMap {
  [key: string]: FunctionComponent<SVGProps<SVGSVGElement>>
}

const svgs: SvgIconMap = {
  AutoOrientOn,
  AutoOrientOff,
  Action,
  Add,
  AddWithOutline,
  AdvAdd,
  AlignCenter,
  AlignJustified,
  AlignLeft,
  AlignRight,
  Angle,
  AngleLeft24,
  AngleRight16,
  AngleRight24,
  AngleLeft16,
  AngleTop16,
  AngleDown16,
  Animated,
  Archived,
  ArrowDirectionDown,
  ArrowDirectionLeft,
  ArrowDirectionRight,
  ArrowDirectionUp,
  ArrowDown,
  ArrowDown12,
  ArrowLeft,
  ArrowLeftDown,
  ArrowLeftUp,
  ArrowRight,
  ArrowRightDown,
  ArrowRightUp,
  ArrowSortDown,
  ArrowSortLeft,
  ArrowSortRight,
  ArrowSortUp,
  ArrowUp,
  AspectRatio,
  Bell,
  BlendOff,
  BlendOn,
  Bolt,
  Brush,
  Capitalize,
  CharacterSpacing,
  Chat,
  Check,
  CheckBold,
  CircleArrowFill,
  CircleArrowOutline,
  Clock,
  Code,
  Cog,
  Collapse,
  Collapsed,
  Comment,
  Compound,
  Contain,
  Container,
  Copy,
  Cross,
  Cross12,
  VersionHistory,
  Crosshair,
  Danger,
  DeleteCircle,
  Doc,
  Dot,
  Download,
  Dribble,
  Dropper,
  Duplicate,
  EaseCustom,
  EaseIn,
  EaseInBack,
  EaseInOut,
  EaseInOutBack,
  EaseOut,
  EaseOutBack,
  Else,
  Emoji,
  EndRound,
  EndStraight,
  EraseOff,
  EraseOn,
  Error,
  Exclamation,
  ExpandLess,
  ExpandMore,
  Export24,
  Eye,
  EyeClose,
  Facebook,
  Filter,
  Figma,
  FilledAngle,
  Fold,
  Folder,
  FontLoader,
  FontSize,
  GeometryArrow,
  GeometryDifference,
  GeometryIntersect,
  GeometryMask,
  GeometryMaskArrow,
  GeometryNone,
  GeometrySubtract,
  GeometryUnion,
  Google,
  GridView,
  HAlignCenter,
  HAlignLeft,
  HAlignRight,
  Hand,
  Help,
  Hexagon,
  HSpacing,
  Idea,
  If,
  Image,
  InsertImage,
  Info,
  Instagram,
  JoinBevel,
  JoinConcave,
  JoinMiter,
  JoinNone,
  JoinRound,
  JumpToEnd,
  JumpToStart,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  Label,
  LabelMask,
  Line,
  Linear,
  LineArrow,
  LineArrowRound,
  LineSpacing,
  Link,
  ListView,
  Loading,
  Location,
  Lock,
  LockOff,
  LockOn,
  Logout,
  Loop,
  LoopOff,
  LoopOn,
  LowerCase,
  MiniArrowDown,
  MiniArrowUp,
  Mix,
  More,
  Next,
  NormalGroup,
  Offset,
  OpenLink,
  Operations,
  Or,
  Origin,
  OriginCenter,
  Oval,
  ParagraphIndent,
  ParagraphSpacing,
  Path,
  Pause,
  PauseOutline,
  Pause24,
  Pen,
  PendingUser16,
  PendingUser28,
  PendingUser32,
  Pentagon,
  Phase,
  Play,
  Play24,
  PlayCircle,
  PlayOutline,
  PlayTriangle,
  Plus,
  PlusCircle,
  PointAsymmetric,
  PointIndependent,
  PointMirror,
  PointStraight,
  Previous,
  Profile,
  Question,
  Radius,
  Reaction,
  Rectangle,
  RectangleH,
  RectangleV,
  Refresh,
  Remove,
  Repeat24,
  Replace,
  Resolve,
  ResolvedThread,
  Rotate,
  RotateAngle,
  ScaleX,
  ScaleY,
  Screen,
  Search,
  SearchCircle,
  Select,
  Setting,
  Sketch,
  SkewX,
  SkewY,
  Sliders,
  Speed,
  SquareArrowFill,
  SquareArrowFillRound,
  SquareArrowOutline,
  SquareArrowOutlineRound,
  Stack,
  Star,
  Step,
  Stop24,
  Strikethrough,
  StrokeWidth,
  Success,
  Template,
  Text,
  Timeline,
  Toggle,
  Trash,
  Triangle,
  TriangleArrowFill,
  TriangleArrowFillRound,
  TriangleArrowOutline,
  TriangleArrowOutlineRound,
  TriangleDown,
  TriangleLeft,
  TriangleRight,
  TriangleUp,
  Trigger,
  TrimPath,
  TutorialVideo,
  Twitter,
  Unanimated,
  Underline,
  Undo,
  Unfold,
  Unlink,
  UnreadComment,
  UpperCase,
  Users24,
  VAlignBottom,
  VAlignMiddle,
  VAlignTop,
  VSpacing,
  Warning,
  WordSpacing,
  Zoom,
  ZoomIn,
  ZoomOut,
  FolderOutline,
  NewProject
}

export default svgs
