import { ElementType } from '@phase-software/types'
import { isNull } from '@phase-software/data-utils'
import { SCREEN_STYLE, SCREEN_FONT_SIZE } from '../constants'



/** @typedef {import('../gfx').Gfx_Image_t} Gfx_Image_t */
/** @typedef {import('../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('../overlay/Overlay').Pane} Pane */
/** @typedef {import('../Viewport').Viewport} Viewport */

/** @typedef {{ w: number, h: number, distW: number, distH: number }} TextImageInfo */
/** @typedef {{ name: string, canvas: HTMLCanvasElement, image: Gfx_Image_t, info: TextImageInfo }} CacheEntry */

/** @type {VisualServer} the injected visual server */
let _visualServer = null

/** @type {Pane} the pane draws screen names */
let _pane = null

/** @type {Map<string, CacheEntry>} */
const cache = new Map()

/**
 * Initialize the functionality of drawing screen names
 * @param {VisualServer} visualServer
 * @param {number} index
 */
export function init(visualServer, index) {
    _visualServer = visualServer
    _pane = _visualServer.overlay.createPane(index)
}

/**
 * Destory the resoucre of global rendering engine
 */
export function destory(){
    for (const entry of cache.values()) {
        _pane.gfx.destroyImage(entry.image)
    }
}

export function update() {
    _pane.clear()

    for (const child of _visualServer.dataStore.workspace.children) {
        const node = _visualServer.getRenderItemOfElement(child)
        if (
            isNull(node) ||
            child.get('elementType') !== ElementType.SCREEN ||
            !child.get('visible')
        ) {
            continue
        }

        const name = child.get('name')
        const T = _visualServer.viewport.projectionTransform.clone()
            .append(node.transform.world)

        _pane.fillStyle(SCREEN_STYLE.color)
        _pane.drawText(T.tx, T.ty - SCREEN_STYLE.padding, name, 'Roboto', SCREEN_FONT_SIZE, 'left', 'bottom')
    }
}

export function clear() {
    _pane.clear()
}
