import {
    IDType,
    Unit,
    PropComponentType,
    EntityType,
    EasingType,
    FrameType,
    TriggerType,
    LogicType,
    OperationType,
    InteractionEntityType,
    ChangeType,
    PaintType,
    EventFlag
} from '@phase-software/types'
import {
    arrEquals,
    isObj,
    parseSceneTreeChanges,
    Undoable,
    Change,
    NO_FIRE,
    id,
    isId,
    loadId
} from '@phase-software/data-utils'

import { easePoints } from '@phase-software/transition-manager'
import { LayerListKeyList, LayerTypeMapLayerListKey, LayerListKeySet } from '../../dist'
import {
    DEFAULT_MOTION_PATH_VALUE,
    MAX_INTERACTION_TIME,
    GRADIENT_PAINT_SET,
    AVAILABLE_UNIT_CHANGE,
    ALWAYS_UPDATE_WITH_UNIT_CHANGE,
    UNIT_CHANGE_PROPS_MAP,
    SHOULD_UPDATE_ALL_KFS_WITH_UNIT_CHANGES,
    EFFECT_TYPE_NAME_MAP,
    EFFECT_TYPE_VALUE_MAP
} from '../constant'
import { canElementApplyEffect, generateLayerPropertyTrackKey, generatePropertyTrackKey } from '../utils'
import { getNextName, pick, getTriggerOptions, findIndexToInsert, isPaintPropKey, getTimeAtStep } from './utils'
import {
    childListMap,
    nonAnimatableKeySet,
    GENERAL_PROPERTY_GROUP_MAP,
    generalKeySet,
    generalChildKeySet,
    effectChildKeySet
} from './constants'



class Manager extends Undoable {
    constructor(dataStore, data = {}) {
        super(dataStore, ChangeType.ENTITY, 'INTERACTION_CHANGES')
        this.dataStore = dataStore
        this._cacheKeyframeGroupByTime = new WeakMap()

        this.load(data)

        this.dataStore.workspace.on('SCENE_TREE_CHANGES', this._onSceneTreeChanges.bind(this))
    }

    _flushCacheBeforeFire(changes) {
        changes.UPDATE.forEach((change, entityId) => {
            const entity = this.data.entityMap.get(entityId)
            if (!entity) {
                return
            }
            switch (entity.type) {
                case InteractionEntityType.PROPERTY_TRACK: {
                    if (change.has('keyFrameList')) {
                        this.flushKfByTime(entityId)
                    }
                    break
                }
                case InteractionEntityType.KEY_FRAME: {
                    if (change.has('time')) {
                        this.flushKfByTime(entityId)
                    }
                    break
                }
            }
        })
    }

    fire(undoable = true, options = {}) {
        super.fire(undoable, options)
        this.dataStore.library.fire(undoable)
    }

    emit(changes, options) {
        this._flushCacheBeforeFire(changes)
        super.emit(changes, options)
    }

    _debug() {
        if (process.env.NODE_ENV !== 'production') {
            const { entityMap } = this.save()
            const responseId = this._getCurrentResponseId()
            return Object.entries(entityMap[responseId].elementTrackMap).reduce((acc, [elementId, elementTrackId]) => {
                const elementTrack = entityMap[elementTrackId]
                elementTrack.id = elementTrackId
                elementTrack.propertyTrackMap = Object.entries(elementTrack.propertyTrackMap).reduce((acc, [trackKey, trackId]) => {
                    const propertyTrack = entityMap[trackId]
                    propertyTrack.id = trackId
                    propertyTrack.keyFrameList = propertyTrack.keyFrameList.map(keyFrameId => ({ id: keyFrameId, ...entityMap[keyFrameId] }))
                    acc[trackKey] = propertyTrack
                    return acc
                }, {})
                acc[elementId] = elementTrack
                return acc
            }, {})
        }
    }

    // TODO: add tests for this
    _onSceneTreeChanges(changes, options) {
        // don't do this if we're in undo or redo
        if (this.dataStore.inUndo || this.dataStore.inRedo) {
            return
        }
        const { removed, moved } = parseSceneTreeChanges(changes)


        for (const elTrackMap of this._getElementTrackMapsForAllActions()) {
            removed.forEach(elementId => {
                const root = this.dataStore.getById(elementId)
                for (const child of this.dataStore.traverseSubtree(root)) {
                    const childId = child.get('id')
                    const elementTrackId = elTrackMap.get(childId)
                    if (!elementTrackId || moved.has(childId)) {
                        continue
                    }

                    this._deleteElementTrack(elementTrackId)
                }
            })
        }

        this.fire(options.undoable)
    }

    /**
     * @yields {Map<string, ElementTrack>}
     */
    *_getElementTrackMapsForAllActions() {
        let actionId, responseId
        for (actionId of this.getActionList()) {
            for (responseId of this.getResponseList(actionId)) {
                yield this.getElementTrackMap(responseId)
            }
        }
    }

    _getTransitionTime() {
        return Math.round(this.dataStore.transition.currentTime / 10) * 10
    }

    _getCurrentActionId() {
        return this.getActionList()[0]
    }

    // FIXME: should use selection in the future
    _getCurrentResponseId() {
        const currentActionId = this._getCurrentActionId()
        const responseList = this.getResponseList(currentActionId)
        return responseList[0]
    }

    _delete(entityId) {
        const entity = this.data.entityMap.get(entityId)

        // in case of recursive delete may delete same entity twice
        if (entity) {
            this.deletedMap.set(entity.id, entity)
            this.data.entityMap.delete(entity.id)
        }
    }

    _restore(entityId) {
        const entity = this.deletedMap.get(entityId)
        this.data.entityMap.set(entity.id, entity)
        this.deletedMap.delete(entity.id)
    }

    _load(entity) {
        if (entity.id) {
            loadId(entity.id, IDType.INTERACTION_MANAGER_COMPONENT)
        }

        switch (entity.type) {
            case InteractionEntityType.ACTION:
                return this._loadAction(entity)
            case InteractionEntityType.TRIGGER:
                return this._loadTrigger(entity)
            case InteractionEntityType.RESPONSE:
                return this._loadResponse(entity)
            case InteractionEntityType.CONDITION:
                return this._loadCondition(entity)
            case InteractionEntityType.ELEMENT_TRACK:
                return this._loadElementTrack(entity)
            case InteractionEntityType.PROPERTY_TRACK:
                return this._loadPropertyTrack(entity)
            case InteractionEntityType.KEY_FRAME:
                return this._loadKeyFrame(entity)
        }
    }

    _loadAction(data) {
        return pick(data, [
            'id',
            'type',
            'name',
            'responseList',
            'triggerList',
            'looping',
            'speed',
            'maxTime'
        ])
    }

    _loadTrigger(data) {
        return pick(data, [
            'id',
            'type',
            'actionId',
            'elementId',
            'triggerType',
            'options'
        ])
    }

    _loadResponse(data) {
        const response = pick(data, [
            'id',
            'type',
            'actionId',
            'name',
            'conditionList',
            'elementTrackMap'
        ])
        response.elementTrackMap = new Map(Object.entries(response.elementTrackMap))
        return response
    }

    _loadCondition(data) {
        return pick(data, [
            'id',
            'type',
            'responseId',
            'logic',
            'operation',
            'elementId'
        ])
    }

    _loadElementTrack(data) {
        const elementTrack = pick(data, [
            'id',
            'type',
            'responseId',
            'elementId',
            'propertyTrackMap',
            ...LayerListKeyList
        ])
        elementTrack.propertyTrackMap = new Map(Object.entries(elementTrack.propertyTrackMap))
        return elementTrack
    }

    _loadPropertyTrack(data) {
        const propertyTrack = pick(data, [
            'id',
            'key',
            'type',
            'elementTrackId',
            'parentId',
            'children',
            'keyFrameList'
        ])
        propertyTrack.children = new Set(propertyTrack.children)
        return propertyTrack
    }

    _loadKeyFrame(data) {
        // TODO: make these arrays to be a constant
        return pick(data, [
            'id',
            'type',
            'frameType',
            'trackId',
            'easingType',
            'animatable',
            'bezier',
            'steps',
            'time',
            'value',
            'unit',
            'delta',
            'ref'
        ])
    }

    _watchElement(element) {
        if (this.watchSet.has(element.get('id'))) {
            return
        }
        this.watchSet.add(element.get('id'))
    }

    _unwatchElement(element) {
        if (!this.watchSet.has(element.get('id'))) {
            return
        }
        this.watchSet.delete(element.get('id'))
    }

    // TODO: might not need this when we'll have multi-actions
    ensureActionExists() {
        const actionList = this.getActionList()
        if (!actionList.length) {
            const actionId = this.addAction()
            this.addResponse(actionId)
        }
    }

    load(data = {}) {
        this.deletedMap = new Map()
        this.watchSet = new Set()

        this.data = {}
        this.data.id = data.id || id()
        const actionList = data.actionList || []

        this.data.actionList = actionList
        this.data.entityMap = this.parseEntityData(data.entityMap)
    }

    parseEntityData(entityMapData = {}) {
        const entityMap = new Map()
        Object.entries(entityMapData).forEach(([id, entityData]) => {
            // restore id inside the data; id not stored in entity object to save space
            const entity = this._load({ id, ...entityData })
            if (entity) {
                entityMap.set(id, entity)
            }
        })
        return entityMap
    }

    /**
     * Saves element track data to clipboard
     * @param      {string}  elementTrackId   The element track identifier
     * @param      {object}  clipboardContent  The clipboard content
     * @returns    {object}  updated clipboard content
     */
    saveElementTrack(elementTrackId, clipboardContent) {
        const elementTrack = this.getElementTrack(elementTrackId)

        if (!elementTrack) {
            return clipboardContent
        }

        const data = this._saveEntity(elementTrack)
        clipboardContent.interaction.set(elementTrack.id, data)

        elementTrack.propertyTrackMap.forEach((propertyTrackId) => {
            this.savePropertyTrack(propertyTrackId, clipboardContent)
        })

        return clipboardContent
    }

    /**
     * Saves property track data to clipboard
     * @param      {string}  propertyTrackId   The property track identifier
     * @param      {object}  clipboardContent  The clipboard content
     * @returns    {object}  updated clipboard content
     */
    savePropertyTrack(propertyTrackId, clipboardContent) {
        const propertyTrack = this.getPropertyTrack(propertyTrackId)

        if (!propertyTrack) {
            return clipboardContent
        }

        const data = this._saveEntity(propertyTrack)
        clipboardContent.interaction.set(propertyTrack.id, data)

        propertyTrack.keyFrameList.forEach((keyFrameId) => {
            this.saveKeyFrame(keyFrameId, clipboardContent)
        })

        return clipboardContent
    }

    /**
     * Saves keyframe data to clipboard
     * @param      {string}  keyFrameId        The key frame identifier
     * @param      {object}  clipboardContent  The clipboard content
     * @returns    {object}  updated clipboard content
     */
    saveKeyFrame(keyFrameId, clipboardContent) {
        const keyFrame = this.getKeyFrame(keyFrameId)

        if (!keyFrame) {
            return clipboardContent
        }

        const data = this._saveEntity(keyFrame)
        clipboardContent.interaction.set(keyFrame.id, data)

        if (keyFrame.ref) {
            const data = this.dataStore.library.getComponent(keyFrame.ref).save()
            clipboardContent.library.set(keyFrame.id, data)
            clipboardContent.mapKeyFrameToLibrary[keyFrame.id] = data.id
        }

        return clipboardContent
    }

    updateKeyFrameData(kfId, newValue, fire = true) {
        this._updateKeyFrameData(kfId, { value: newValue })
        if (fire) {
            this.fire()
        }
    }

    _saveEntityMap() {
        const map = {}
        this.data.entityMap.forEach((entity, key) => {
            const data = this._saveEntity(entity)
            map[key] = data
        })
        return map
    }

    _saveEntity(entity) {
        const obj = Object.entries(entity).reduce((acc, [prop, value]) => {
            if (value instanceof Map) {
                acc[prop] = Object.fromEntries(value)
            } else if (value instanceof Set) {
                acc[prop] = Array.from(value)
            } else if (Array.isArray(value)) {
                acc[prop] = value.slice()
            } else {
                acc[prop] = value
            }
            return acc
        }, {})

        return obj
    }

    save() {
        return {
            actionList: this.data.actionList,
            entityMap: this._saveEntityMap()
        }
    }

    undo(changes) {
        changes.DELETE.forEach(entityId => {
            this._restore(entityId)
        })
        changes.UPDATE.forEach((change, entityId) => {
            if (entityId === this.data.id) {
                const { before, after, index, fromIndex, toIndex } = change.get(
                    'actionList'
                )
                const actionList = this.getActionList()
                if (changes.CREATE.size) {
                    const currIndex = actionList.indexOf(after[index])
                    actionList.splice(currIndex, 1)
                } else if (changes.DELETE.size) {
                    actionList.splice(index, 0, before[index])
                } else {
                    const currFromIndex = actionList.indexOf(after[fromIndex])
                    const currToIndex = actionList.indexOf(after[toIndex])
                    this._reorderList(actionList, currFromIndex, currToIndex)
                }
            } else {
                const entity = this.data.entityMap.get(entityId)
                // entity may be deleted by other tabs can't find it
                if (entity) {
                    change.forEach(({ before }, key) => {
                        entity[key] = before
                    })
                }
            }
        })
        changes.CREATE.forEach(entityId => {
            this._delete(entityId)
        })

        super.undo(changes)
    }

    redo(changes, options) {
        changes.CREATE.forEach(entityId => {
            this._restore(entityId)
        })

        changes.UPDATE.forEach((change, entityId) => {
            if (entityId === this.data.id) {
                const { before, after, index, fromIndex, toIndex } = change.get(
                    'actionList'
                )
                const actionList = this.getActionList()
                if (changes.CREATE.size) {
                    actionList.splice(index, 0, after[index])
                } else if (changes.DELETE.size) {
                    const currIndex = actionList.indexOf(before[index])
                    actionList.splice(currIndex, 1)
                } else {
                    const currFromIndex = actionList.indexOf(before[fromIndex])
                    const currToIndex = actionList.indexOf(before[toIndex])
                    this._reorderList(actionList, currFromIndex, currToIndex)
                }
            } else {
                const entity = this.data.entityMap.get(entityId)
                if (entity) {
                    change.forEach(({ after }, key) => {
                        entity[key] = isObj(after) ? { ...after } : after
                    })
                }
            }
        })

        changes.DELETE.forEach(entityId => {
            this._delete(entityId)
        })

        super.redo(changes, options)
    }

    _reorderList(list, fromIndex, toIndex) {
        if (
            fromIndex === toIndex ||
            fromIndex < 0 ||
            fromIndex >= list.length ||
            toIndex < 0 ||
            toIndex >= list.length
        ) {
            return false
        }
        list.splice(toIndex, 0, list.splice(fromIndex, 1)[0])
        return true
    }

    _createAction() {
        const nameList = this.getActionList().map(
            actionId => this.getAction(actionId).name
        )
        const name = getNextName('Action', nameList)
        const action = {
            id: id(IDType.INTERACTION_MANAGER_COMPONENT),
            type: InteractionEntityType.ACTION,
            name,
            responseList: [],
            triggerList: [],
            looping: true,
            speed: 1,
            maxTime: 2000
        }
        this.data.entityMap.set(action.id, action)
        this.changes.create([action.id])
        return action
    }

    _createResponse(actionId) {
        const nameList = this.getResponseList(actionId).map(
            actionId => this.getResponse(actionId).name
        )
        const name = getNextName('Do', nameList)
        const response = {
            id: id(IDType.INTERACTION_MANAGER_COMPONENT),
            type: InteractionEntityType.RESPONSE,
            actionId,
            name,
            conditionList: [],
            elementTrackMap: new Map()
        }
        this.data.entityMap.set(response.id, response)
        this.changes.create([response.id])
        return response
    }

    _createTrigger(actionId) {
        const trigger = {
            id: id(IDType.INTERACTION_MANAGER_COMPONENT),
            type: InteractionEntityType.TRIGGER,
            actionId,
            elementId: null,
            triggerType: TriggerType.CLICK,
            options: getTriggerOptions(TriggerType.CLICK)
        }
        this.data.entityMap.set(trigger.id, trigger)
        this.changes.create([trigger.id])
        return trigger
    }

    _createElementTrack(responseId, elementId) {
        const elementTrack = {
            id: id(IDType.INTERACTION_MANAGER_COMPONENT),
            type: InteractionEntityType.ELEMENT_TRACK,
            responseId,
            elementId,
            propertyTrackMap: new Map(),
            fills: [],
            strokes: [],
            shadows: [],
            innerShadows: []
        }
        this.data.entityMap.set(elementTrack.id, elementTrack)
        this.changes.create([elementTrack.id])
        return elementTrack
    }

    _createPropertyTrack(elementTrackId, key, prefix) {
        const trackId = id(IDType.INTERACTION_MANAGER_COMPONENT)
        const propertyTrack = {
            id: trackId,
            key: `${prefix ? `${prefix}.` : ''}${key ? key : trackId}`,
            type: InteractionEntityType.PROPERTY_TRACK,
            elementTrackId,
            parentId: null,
            children: new Set(),
            keyFrameList: []
        }
        this.data.entityMap.set(propertyTrack.id, propertyTrack)
        this.changes.create([propertyTrack.id])
        return propertyTrack
    }

    _createKeyFrame(propertyTrackId, keyframeData = {}) {
        const defaultData = {
            steps: 1,
            animatable: true,
            unit: Unit.PIXEL,
            time: 0,
            value: undefined,
            ref: null,
            delta: false,
            frameType: FrameType.EXPLICIT
        };
        const data = { ...defaultData, ...keyframeData };

        const easingType = data.easingType || (data.animatable ? EasingType.LINEAR : EasingType.STEP_END);
        const bezier = data.bezier || easePoints[easingType];

        const keyFrame = {
            ...data,
            easingType,
            bezier,
            id: id(IDType.INTERACTION_MANAGER_COMPONENT),
            type: InteractionEntityType.KEY_FRAME,
            trackId: propertyTrackId,
        };

        this.data.entityMap.set(keyFrame.id, keyFrame);
        this.changes.create([keyFrame.id]);
        return keyFrame;
    }

    _createCondition(responseId) {
        const condition = {
            id: id(IDType.INTERACTION_MANAGER_COMPONENT),
            type: InteractionEntityType.CONDITION,
            responseId,
            logic: LogicType.AND,
            operation: OperationType.NONE,
            elementId: null
        }
        this.data.entityMap.set(condition.id, condition)
        this.changes.create([condition.id])
        return condition
    }

    _cloneAction(action) {
        const newAction = this._createAction()

        newAction.name = `${action.name} Copy`

        const responseList = action.responseList.map(responseId => {
            const newResponse = this._cloneResponse(
                this.getResponse(responseId),
                newAction.id
            )
            return newResponse.id
        })
        newAction.responseList = responseList

        const triggerList = action.triggerList.map(triggerId => {
            const newTrigger = this._cloneTrigger(
                this.getTrigger(triggerId),
                newAction.id
            )
            return newTrigger.id
        })
        newAction.triggerList = triggerList

        return newAction
    }

    _cloneResponse(response, actionId) {
        const newResponse = this._createResponse(actionId)

        const conditionList = response.conditionList.map(conditionId => {
            const newCondition = this._cloneCondition(
                this.getCondition(conditionId),
                newResponse.id
            )
            return newCondition.id
        })
        newResponse.conditionList = conditionList

        const newElementTrackMap = new Map()
        response.elementTrackMap.forEach((elementTrackId, elementId) => {
            const newElementTrack = this._cloneElementTrack(
                this.getElementTrack(elementTrackId),
                newResponse.id
            )
            if (newElementTrack) {
                newElementTrackMap.set(elementId, newElementTrack.id)
            }
        })
        newResponse.elementTrackMap = newElementTrackMap

        return newResponse
    }

    _cloneTrigger(trigger, actionId) {
        const newTrigger = this._createTrigger(actionId)

        newTrigger.option = { ...trigger.option }

        return newTrigger
    }

    _cloneCondition(condition, responseId) {
        const newCondition = this._createCondition(responseId)

        newCondition.logic = condition.logic
        newCondition.operation = condition.operation
        newCondition.elementId = condition.elementId

        return newCondition
    }

    _cloneElementTrack(elementTrack, responseId, newElementId, entityMap) {
        const elementId = newElementId || elementTrack.elementId
        const newElementTrack = this._createElementTrack(responseId, elementId)

        if (entityMap) {
            entityMap.set(newElementTrack.id, newElementTrack)
        }

        childListMap.get('ROOT').forEach(key => {
            const trackId = elementTrack.propertyTrackMap.get(key)
            if (!trackId) {
                return
            }

            const propTrack = this._clonePropertyTrack(
                this.getPropertyTrack(trackId, entityMap),
                newElementTrack.id,
                null,
                entityMap
            )

            newElementTrack.propertyTrackMap.set(propTrack.key, propTrack.id)
        })

        if (!newElementTrack.propertyTrackMap.size) {
            if (entityMap) {
                entityMap.delete(newElementTrack.id)
            }
            this.data.entityMap.delete(newElementTrack.id)
            this.changes.removeFromCreate([newElementTrack.id])
            return null
        }

        return newElementTrack
    }

    _clonePropertyTrack(propertyTrack, elementTrackId, parentId = null, entityMap) {
        // prop track key rules:
        // generic key: 'x', 'y'
        // group key: 'position', 'dimensions'
        // layer prop key: `${cuid}.${prop}`
        // base layer key: cuid equal to layerId
        // non-base layer key: cuid equal to id
        const propKeys = propertyTrack.key.split('.')
        const isLayerPropKey = propKeys.length === 2
        const newElementTrack = this.getElementTrack(elementTrackId)
        let prefix
        let key = isLayerPropKey ? propKeys[1] : propKeys[0]
        const isLayerKey = !generalKeySet.has(key) && (isId(key, IDType.LAYER) || isId(key, IDType.INTERACTION_MANAGER_COMPONENT))

        if (isLayerKey) {
            const isBaseLayer = propertyTrack.id !== propertyTrack.key
            if (isBaseLayer) {
                const elementTrack = this.getElementTrack(propertyTrack.elementTrackId, entityMap)
                const listName = this.getPropertyTrack(parentId, entityMap).key

                const element = this.dataStore.getById(elementTrack.elementId)
                const listComponentId = element.base[listName][0]
                const listComponent = this.dataStore.library.getComponent(listComponentId)
                const index = listComponent.layers.indexOf(propertyTrack.key)

                const newElement = this.dataStore.getById(newElementTrack.elementId)
                const newListComponentId = newElement.base[listName][0]
                const newListComponent = this.dataStore.library.getComponent(newListComponentId)

                key = newListComponent.layers[index]
            } else {
                // make key as undefined to generate the new ID
                key = undefined
            }
        } else if (isLayerPropKey) {
            prefix = this.getPropertyTrack(parentId, entityMap).key
        } else {
            key = propKeys[0]
        }

        const newPropertyTrack = this._createPropertyTrack(elementTrackId, key, prefix)
        newPropertyTrack.parentId = parentId

        newElementTrack.propertyTrackMap.set(newPropertyTrack.key, newPropertyTrack.id)

        if (entityMap) {
            entityMap.set(newPropertyTrack.id, newPropertyTrack)
        }

        // non-base layer
        if (newPropertyTrack.id === newPropertyTrack.key) {
            const listName = this.getPropertyTrack(parentId).key
            newElementTrack[listName].push(newPropertyTrack.id)
        }

        const oriElementTrack = this.getElementTrack(propertyTrack.elementTrackId, entityMap)
        propertyTrack.children.forEach(trackKey => {
            const subId = oriElementTrack.propertyTrackMap.get(trackKey)
            const subTrack = this._clonePropertyTrack(
                this.getPropertyTrack(subId, entityMap),
                elementTrackId,
                newPropertyTrack.id,
                entityMap
            )
            newPropertyTrack.children.add(subTrack.key)
            newElementTrack.propertyTrackMap.set(subTrack.key, subTrack.id)
        })

        const oriElement = this.dataStore.getById(oriElementTrack.elementId)
        const newElement = this.dataStore.getById(newElementTrack.elementId)
        const vMap = new Map()
        const remapVertexId = oriElement?.basePath && newElement?.basePath
        if (remapVertexId) {
            const oriVertexIdList = Array.from(oriElement.basePath.keys())
            const newVertexIdList = Array.from(newElement.basePath.keys())
            oriVertexIdList.forEach((oriVertexId, index) => {
                vMap.set(oriVertexId, newVertexIdList[index])
            })
        }

        const keyFrameList = propertyTrack.keyFrameList.map(keyFrameId => {
            const newKeyFrame = this._cloneKeyFrame(
                this.getKeyFrame(keyFrameId, entityMap),
                newPropertyTrack.id,
                entityMap
            )
            if (remapVertexId && key === 'pathMorphing') {
                newKeyFrame.value = newKeyFrame.value.map(vertex => {
                    const id = vMap.get(vertex.id)
                    return { ...vertex, id }
                })
            }
            return newKeyFrame.id
        })
        newPropertyTrack.keyFrameList = keyFrameList

        return newPropertyTrack
    }

    _cloneKeyFrame(keyFrame, propertyTrackId, entityMap) {
        const newKeyFrame = this._createKeyFrame(propertyTrackId, keyFrame)

        if (entityMap) {
            entityMap.set(newKeyFrame.id, newKeyFrame)
        }

        // don't clone the library component if clone by paste
        if (keyFrame.ref) {
            if (entityMap) {
                newKeyFrame.ref = keyFrame.ref
            } else {
                newKeyFrame.ref = this.dataStore.library.cloneComponent(keyFrame.ref, NO_FIRE)
            }
        }

        return newKeyFrame
    }

    _deleteAction(actionId) {
        const action = this.getAction(actionId)
        action.responseList.forEach(responseId => {
            this._deleteResponse(responseId)
        })
        action.triggerList.forEach(triggerId => {
            this._deleteTrigger(triggerId)
        })
        this.changes.delete([actionId])
        this._delete(actionId)
    }

    _deleteResponse(responseId) {
        const response = this.getResponse(responseId)
        response.conditionList.forEach(conditionId => {
            this._deleteCondition(conditionId)
        })
        response.elementTrackMap.forEach(elementTrackId => {
            this._deleteElementTrack(elementTrackId)
        })
        this.changes.delete([responseId])
        this._delete(responseId)
    }

    _deleteTrigger(triggerId) {
        this.changes.delete([triggerId])
        this._delete(triggerId)
    }

    _deleteCondition(conditionId) {
        this.changes.delete([conditionId])
        this._delete(conditionId)
    }

    _deleteElementTrack(elementTrackId) {
        const elementTrack = this.getElementTrack(elementTrackId)
        if (!elementTrack) {
            return
        }
        elementTrack.propertyTrackMap.forEach(propertyTrackId => {
            this._deletePropertyTrack(propertyTrackId)
        })

        const response = this.getResponse(elementTrack.responseId)
        const before = new Map(response.elementTrackMap)
        response.elementTrackMap.delete(elementTrack.elementId)
        this.changes.update(response.id, 'elementTrackMap', new Change({ before, after: new Map(response.elementTrackMap) }))

        this.changes.delete([elementTrackId])
        this._delete(elementTrackId)
    }

    _deletePropertyTrack(propertyTrackId, recursiveDelete = true) {
        const propertyTrack = this.getPropertyTrack(propertyTrackId)
        const elementTrack = this.getElementTrack(propertyTrack.elementTrackId)
        propertyTrack.keyFrameList.forEach(keyFrameId => {
            this._deleteKeyFrame(keyFrameId, false)
        })
        propertyTrack.children.forEach(childKey => {
            const childId = elementTrack.propertyTrackMap.get(childKey)
            this._deletePropertyTrack(childId, false)
        })
        const before = new Map(elementTrack.propertyTrackMap)
        elementTrack.propertyTrackMap.delete(propertyTrack.key)
        this.changes.update(
            elementTrack.id,
            'propertyTrackMap',
            new Change({
                before,
                after: new Map(elementTrack.propertyTrackMap)
            })
        )
        this.changes.delete([propertyTrackId])
        this._delete(propertyTrackId)

        // non-base layer track
        if (propertyTrack.key === propertyTrack.id) {
            const layerListTrack = this.getPropertyTrack(propertyTrack.parentId)
            if (layerListTrack) {
                const listName = layerListTrack.key
                const before = elementTrack[listName].slice()
                const index = elementTrack[listName].indexOf(propertyTrack.id)
                elementTrack[listName].splice(index, 1)
                this.changes.update(
                    elementTrack.id,
                    listName,
                    new Change({
                        before,
                        after: elementTrack[listName].slice(),
                        index
                    })
                )
            }
        }

        if (recursiveDelete) {
            const parentTrack = this.getPropertyTrack(propertyTrack.parentId)
            if (parentTrack) {
                const before = new Set(parentTrack.children)
                parentTrack.children.delete(propertyTrack.key)
                this.changes.update(parentTrack.id, 'children', new Change({ before, after: new Set(parentTrack.children) }))
                if (!parentTrack.children.size) {
                    this._deletePropertyTrack(parentTrack.id)
                }
            } else if (!elementTrack.propertyTrackMap.size) {
                this._deleteElementTrack(propertyTrack.elementTrackId)
            }
        }
    }

    _deleteKeyFrame(keyFrameId, recursiveDelete = true) {
        const keyFrame = this.getKeyFrame(keyFrameId)
        if (!keyFrame) {
            return
        }

        const propertyTrack = this.getPropertyTrack(keyFrame.trackId)
        const before = propertyTrack.keyFrameList.slice()
        const index = propertyTrack.keyFrameList.indexOf(keyFrameId)
        if (keyFrame.ref) {
            this.dataStore.library.deleteProperty(keyFrame.ref, false)
        }

        propertyTrack.keyFrameList.splice(index, 1)
        this.changes.update(keyFrame.trackId, 'keyFrameList', new Change({
            before,
            after: propertyTrack.keyFrameList.slice(),
            index
        }))

        this.changes.delete([keyFrameId])
        this._delete(keyFrameId)

        if (recursiveDelete) {
            if (!propertyTrack.keyFrameList.length) {
                this._deletePropertyTrack(propertyTrack.id)
            }
        }
    }

    getEntity(entityId) {
        return this.data.entityMap.get(entityId)
    }

    addAction(index = undefined) {
        const actionList = this.getActionList()
        if (index < 0 || index > actionList.length) {
            return false
        }
        const action = this._createAction()
        const idx = index === undefined ? actionList.length : index

        const before = actionList.slice()
        actionList.splice(idx, 0, action.id)


        this.changes.update(
            this.data.id,
            'actionList',
            new Change({
                before,
                after: actionList.slice(),
                index: idx
            })
        )
        this.fire()

        return action.id
    }

    getActionMaxTime(actionId) {
        const action = this.getAction(actionId)
        if (!action) {
            return
        }
        return action.maxTime
    }

    setActionMaxTime(actionId, maxTime) {
        if (isNaN(maxTime)) {
            return false
        }

        const action = this.getAction(actionId)
        if (!action) {
            return false
        }

        // soft limit maxTime to 2 secs to 2 mins
        let time = Number(maxTime)
        if (time < 100) {
            time = 100
        }
        if (time > 60 * 1000) {
            time = 60 * 1000
        }

        if (action.maxTime === time) {
            return false
        }

        const before = action.maxTime
        action.maxTime = time


        this.changes.update(
            action.id,
            'maxTime',
            new Change({
                before,
                after: action.maxTime
            })
        )
        this.fire()

        return true
    }


    getAction(actionId) {
        const action = this.data.entityMap.get(actionId)
        if (!action || action.type !== InteractionEntityType.ACTION) {
            return
        }
        return action
    }

    getActionList() {
        return this.data.actionList
    }

    deleteAction(actionId) {
        const action = this.getAction(actionId)
        if (!action) {
            return false
        }

        this._deleteAction(actionId)

        const actionList = this.getActionList()
        const before = actionList.slice()
        const index = actionList.indexOf(actionId)
        actionList.splice(index, 1)

        this.changes.update(
            this.data.id,
            'actionList',
            new Change({ before, after: actionList, index })
        )
        this.fire()

        return true
    }

    cloneAction(actionId) {
        const action = this.getAction(actionId)
        if (!action) {
            return false
        }
        const actionList = this.getActionList()
        const before = actionList.slice()
        const newAction = this._cloneAction(action)

        const index = actionList.length
        actionList.splice(index, 0, newAction.id)

        this.changes.update(
            this.data.id,
            'actionList',
            new Change({ before, after: actionList.slice(), index })
        )
        this.fire()

        return newAction.id
    }

    setActionName(actionId, name) {
        const action = this.getAction(actionId)
        if (!action) {
            return false
        }
        if (action.name === name) {
            return false
        }

        const before = action.name
        action.name = name

        this.changes.update(
            actionId,
            'name',
            new Change({ before, after: name })
        )
        this.fire()

        return true
    }

    reorderAction(fromIndex, toIndex) {
        const actionList = this.getActionList()
        const before = actionList.slice()

        const updated = this._reorderList(actionList, fromIndex, toIndex)
        if (updated) {
            this.changes.update(
                this.data.id,
                'actionList',
                new Change({
                    before,
                    after: actionList.slice(),
                    fromIndex,
                    toIndex
                })
            )
            this.fire()
        }

        return updated
    }

    addResponse(actionId, index = undefined) {
        const action = this.getAction(actionId)
        if (!action) {
            return false
        }
        if (index < 0 || index > action.responseList.length) {
            return false
        }
        const response = this._createResponse(actionId)
        const idx = index === undefined ? action.responseList.length : index
        const before = action.responseList.slice()
        action.responseList.splice(idx, 0, response.id)

        this.changes.update(
            actionId,
            'responseList',
            new Change({
                before,
                after: action.responseList.slice(),
                index: idx
            })
        )
        this.fire()
        return response.id
    }

    getResponseList(actionId) {
        const action = this.getAction(actionId)
        if (!action) {
            return
        }
        return action.responseList
    }

    getResponse(responseId) {
        const response = this.data.entityMap.get(responseId)
        if (!response || response.type !== InteractionEntityType.RESPONSE) {
            return
        }
        return response
    }

    cloneResponse(responseId) {
        const response = this.getResponse(responseId)
        if (!response) {
            return false
        }
        const responseList = this.getResponseList(response.actionId)
        const before = responseList.slice()
        const index = responseList.length

        const newResponse = this._cloneResponse(response, response.actionId)

        responseList.push(newResponse.id)

        this.changes.update(
            response.actionId,
            'responseList',
            new Change({
                before,
                after: responseList.slice(),
                index
            })
        )
        this.fire()

        return newResponse.id
    }

    getElementTrackIdByElementId(elementId) {
        const responseId = this._getCurrentResponseId()
        const elementTrackMap = this.getElementTrackMap(responseId)
        return elementTrackMap.get(elementId)
    }

    cloneElementTrack(elementTrackId, newElementId, fire = true, entityMap) {
        const elementTrack = this.getElementTrack(elementTrackId, entityMap)
        if (!elementTrack) {
            return false
        }

        const responseId = this._getCurrentResponseId()
        const newElementTrack = this._cloneElementTrack(elementTrack, responseId, newElementId, entityMap)
        if (!newElementTrack) {
            return null
        }

        const response = this.getResponse(responseId)
        const before = new Map(response.elementTrackMap)
        response.elementTrackMap.set(newElementId, newElementTrack.id)

        this.changes.update(
            response.id,
            'elementTrackMap',
            new Change({
                before,
                after: new Map(response.elementTrackMap)
            })
        )

        if (fire) {
            this.fire()
        }

        return newElementTrack.id
    }

    deleteResponse(responseId) {
        const response = this.getResponse(responseId)
        if (!response) {
            return false
        }

        const responseList = this.getResponseList(response.actionId)
        const before = responseList.slice()
        this._deleteResponse(responseId)

        const index = responseList.indexOf(responseId)
        responseList.splice(index, 1)

        this.changes.update(
            response.actionId,
            'responseList',
            new Change({
                before,
                after: responseList.slice(),
                index
            })
        )
        this.fire()
        return true
    }

    setResponseName(responseId, name) {
        const response = this.getResponse(responseId)

        if (!response) {
            return false
        }
        if (response.name === name) {
            return false
        }

        const before = response.name

        response.name = name

        this.changes.update(
            response.id,
            'name',
            new Change({
                before,
                after: response.name
            })
        )
        this.fire()

        return true
    }

    addTrigger(actionId, index = undefined) {
        const action = this.getAction(actionId)
        if (!action) {
            return false
        }

        const triggerList = action.triggerList
        if (index < 0 || index > triggerList.length) {
            return false
        }
        const trigger = this._createTrigger(actionId)
        const before = triggerList.slice()
        const idx = index === undefined ? triggerList.length : index

        triggerList.splice(idx, 0, trigger.id)

        this.changes.update(
            actionId,
            'triggerList',
            new Change({
                before,
                after: triggerList.slice(),
                index: idx
            })
        )
        this.fire()
        return trigger.id
    }

    getTriggerList(actionId) {
        const action = this.getAction(actionId)
        if (!action) {
            return
        }
        return action.triggerList
    }

    getTrigger(triggerId) {
        const trigger = this.data.entityMap.get(triggerId)
        if (!trigger || trigger.type !== InteractionEntityType.TRIGGER) {
            return
        }
        return trigger
    }

    cloneTrigger(triggerId) {
        const trigger = this.getTrigger(triggerId)
        if (!trigger) {
            return false
        }
        const newTrigger = this._cloneTrigger(trigger, trigger.actionId)

        const triggerList = this.getTriggerList(trigger.actionId)
        const before = triggerList.slice()
        const index = triggerList.length

        triggerList.push(newTrigger.id)

        this.changes.update(
            trigger.actionId,
            'triggerList',
            new Change({
                before,
                after: triggerList.slice(),
                index
            })
        )
        this.fire()

        return newTrigger.id
    }

    deleteTrigger(triggerId) {
        const trigger = this.getTrigger(triggerId)
        if (!trigger) {
            return false
        }

        this._deleteTrigger(triggerId)

        const triggerList = this.getTriggerList(trigger.actionId)
        const before = triggerList.slice()
        const index = triggerList.indexOf(triggerId)
        triggerList.splice(index, 1)

        this.changes.update(
            trigger.actionId,
            'triggerList',
            new Change({
                before,
                after: triggerList.slice(),
                index
            })
        )
        this.fire()
        return true
    }

    setTriggerElement(triggerId, elementId) {
        const trigger = this.getTrigger(triggerId)

        if (!trigger) {
            return false
        }

        const element = this.dataStore.getById(elementId)
        if (!element || element.get('type') !== EntityType.ELEMENT) {
            return false
        }

        if (trigger.elementId === element.get('id')) {
            return false
        }
        const before = trigger.elementId
        trigger.elementId = element.get('id')

        this.changes.update(
            triggerId,
            'elementId',
            new Change({
                before,
                after: elementId
            })
        )
        this.fire()
        return true
    }

    setTriggerType(triggerId, triggerType) {
        const trigger = this.getTrigger(triggerId)

        if (!trigger) {
            return false
        }

        if (!Object.values(TriggerType).includes(triggerType)) {
            return false
        }
        const before = trigger.triggerType
        const optionsBefore = { ...trigger.options }
        trigger.triggerType = triggerType
        trigger.options = getTriggerOptions(triggerType)
        this.changes.update(
            triggerId,
            'options',
            new Change({
                before: optionsBefore,
                after: trigger.options
            })
        )
        this.changes.update(
            triggerId,
            'triggerType',
            new Change({
                before,
                after: triggerType
            })
        )
        this.fire()
        return true
    }

    setTriggerOptions(triggerId, changes) {
        const trigger = this.getTrigger(triggerId)

        if (!trigger) {
            return false
        }

        if (typeof changes !== 'object' || changes === null) {
            return false
        }

        const before = { ...trigger.options }

        const dirtyKeys = new Set()
        Object.entries(changes).forEach(([key, value]) => {
            if (Object.prototype.hasOwnProperty.call(trigger.options, key)) {
                if (trigger.options[key] !== value) {
                    trigger.options[key] = value
                    dirtyKeys.add(key)
                }
            }
        })

        if (dirtyKeys.size === 0) {
            return false
        }

        this.changes.update(
            triggerId,
            'options',
            new Change({
                before,
                after: trigger.options
            })
        )
        this.fire()

        return true
    }

    addCondition(responseId, index = undefined) {
        const response = this.getResponse(responseId)

        if (!response) {
            return false
        }

        const conditionList = response.conditionList
        const before = conditionList.slice()
        if (index < 0 || index > conditionList.length) {
            return false
        }
        const condition = this._createCondition(responseId)
        const idx = index === undefined ? conditionList.length : index
        conditionList.splice(idx, 0, condition.id)

        this.changes.update(
            responseId,
            'conditionList',
            new Change({
                before,
                after: conditionList.slice(),
                index: idx
            })
        )
        this.fire()

        return condition.id
    }

    getConditionList(responseId) {
        const response = this.getResponse(responseId)

        if (!response) {
            return
        }

        return response.conditionList
    }

    getCondition(conditionId) {
        const condition = this.data.entityMap.get(conditionId)
        if (!condition || condition.type !== InteractionEntityType.CONDITION) {
            return
        }
        return condition
    }

    cloneCondition(conditionId) {
        const condition = this.getCondition(conditionId)

        if (!condition) {
            return false
        }

        const newCondition = this._cloneCondition(condition, condition.responseId)

        const conditionList = this.getConditionList(condition.responseId)
        const before = conditionList.slice()
        const index = conditionList.length
        conditionList.push(newCondition.id)

        this.changes.update(
            newCondition.responseId,
            'conditionList',
            new Change({
                before,
                after: conditionList,
                index
            })
        )
        this.fire()
        return newCondition.id
    }

    deleteCondition(conditionId) {
        const condition = this.getCondition(conditionId)
        if (!condition) {
            return false
        }

        this._deleteCondition(conditionId)

        const conditionList = this.getConditionList(condition.responseId)
        const before = conditionList.slice()
        const index = conditionList.indexOf(conditionId)
        conditionList.splice(index, 1)

        this.changes.update(
            condition.responseId,
            'conditionList',
            new Change({
                before,
                after: conditionList,
                index
            })
        )
        this.fire()
        return true
    }

    setConditionLogic(conditionId, logic) {
        const condition = this.getCondition(conditionId)
        if (!condition) {
            return false
        }

        if (!Object.values(LogicType).includes(logic)) {
            return false
        }

        if (condition.logic === logic) {
            return false
        }
        const before = condition.logic
        condition.logic = logic

        this.changes.update(
            conditionId,
            'logic',
            new Change({
                before,
                after: condition.logic
            })
        )
        this.fire()

        return true
    }

    setConditionElement(conditionId, elementId) {
        const condition = this.getCondition(conditionId)

        if (!condition) {
            return false
        }

        if (condition.elementId === elementId) {
            return false
        }

        const element = this.dataStore.getById(elementId)
        if (!element || element.get('type') !== EntityType.ELEMENT) {
            return false
        }

        // unbind old event listeners
        if (condition.elementId) {
            const element = this.dataStore.getById(condition.elementId)
            this._unwatchElement(element)
        }
        this._watchElement(element)

        const before = condition.elementId
        condition.elementId = elementId

        this.changes.update(
            conditionId,
            'elementId',
            new Change({
                before,
                after: condition.elementId
            })
        )
        this.fire()

        return true
    }

    setConditionOperation(conditionId, operation) {
        const condition = this.getCondition(conditionId)
        if (!condition) {
            return false
        }

        if (!Object.values(OperationType).includes(operation)) {
            return false
        }

        if (condition.operation === operation) {
            return false
        }
        const before = condition.operation
        condition.operation = operation

        this.changes.update(
            conditionId,
            'operation',
            new Change({
                before,
                after: condition.operation
            })
        )
        this.fire()
        return true
    }

    getElementTrackMap(responseId) {
        const response = this.getResponse(responseId)

        if (!response) {
            return
        }

        return response.elementTrackMap
    }

    getAnimatiedElementMap() {
        const responseList = this.getResponseList(this.getActionList()[0])
        const response = this.getResponse(responseList[0])
        if (!response) {
            return
        }

        return response.elementTrackMap
    }

    addElementTrack(responseId, elementId) {
        const response = this.getResponse(responseId)
        if (!response) {
            return false
        }

        const element = this.dataStore.getById(elementId)
        if (!element || element.get('type') !== EntityType.ELEMENT) {
            return false
        }

        if (response.elementTrackMap.has(elementId)) {
            return false
        }

        const elementTrack = this._createElementTrack(responseId, elementId)

        const before = new Map(response.elementTrackMap)
        response.elementTrackMap.set(elementId, elementTrack.id)

        this.changes.update(
            responseId,
            'elementTrackMap',
            new Change({
                before,
                after: new Map(response.elementTrackMap)
            })
        )
        this.fire()

        return elementTrack.id
    }

    getElementTrack(elementTrackId, entityMap) {
        const map = entityMap || this.data.entityMap
        const elementTrack = map.get(elementTrackId)

        if (!elementTrack || elementTrack.type !== InteractionEntityType.ELEMENT_TRACK) {
            return
        }

        return elementTrack
    }

    getElementTrackKeyFrameGroupByTime(elementTrackId, filteredKeySet) {
        const elementTrack = this.getElementTrack(elementTrackId)
        if (!elementTrack) {
            return
        }

        const map = {}
        elementTrack.propertyTrackMap.forEach((trackId, trackKey) => {
            if (filteredKeySet && !filteredKeySet.has(trackKey)) {
                return
            }
            const keyFrameList = this.getKeyFrameList(trackId)
            this.groupKeyframeByTime(keyFrameList, map)
        })

        return map
    }

    deleteElementTrack(elementTrackId) {
        const elementTrack = this.getElementTrack(elementTrackId)
        if (!elementTrack) {
            return false
        }

        this._deleteElementTrack(elementTrackId)

        this.fire()
        return true
    }

    deleteElementPropertyTrack(elementId, trackKey, fire = true) {
        const elementTrackId = this.getElementTrackIdByElementId(elementId)
        const propertyTrackMap = this.getPropertyTrackMap(elementTrackId)
        if (!propertyTrackMap) {
            return false
        }

        const trackId = propertyTrackMap.get(trackKey)
        if (!trackId) {
            return false
        }

        this._deletePropertyTrack(trackId)

        if (fire) {
            this.fire()
        }

        return true
    }

    getPropertyTrackMapByElementId(elementId) {
        const responseId = this._getCurrentResponseId()
        const elementTrackMap = this.getElementTrackMap(responseId)
        const elementTrackId = elementTrackMap.get(elementId)
        return this.getPropertyTrackMap(elementTrackId)
    }

    getPropertyTrackMap(elementTrackId, entityMap) {
        const elementTrack = this.getElementTrack(elementTrackId, entityMap)

        if (!elementTrack) {
            return
        }

        return elementTrack.propertyTrackMap
    }

    getPropertyTrack(propertyTrackId, entityMap) {
        const map = entityMap || this.data.entityMap
        const propertyTrack = map.get(propertyTrackId)
        if (
            !propertyTrack ||
            propertyTrack.type !== InteractionEntityType.PROPERTY_TRACK
        ) {
            return
        }
        return propertyTrack
    }

    getPropertyTrackByElementIdAndPropKey(elementId, propKey) {
        const elementTrackId = this.getElementTrackIdByElementId(elementId)
        const elementTrack = this.getElementTrack(elementTrackId)
        if (!elementTrack) {
            return
        }
        const propertyTrackId = elementTrack.propertyTrackMap.get(propKey)
        return this.getPropertyTrack(propertyTrackId)
    }

    getPropertyTrackKeyFrameGroupByTime(propertyTrackId) {
        const propertyTrack = this.getPropertyTrack(propertyTrackId)
        if (!propertyTrack) {
            return
        }
        const queue = [propertyTrack.key]
        const map = {}
        const propTrackMap = this.getPropertyTrackMap(propertyTrack.elementTrackId)

        while (queue.length) {
            const trackKey = queue.shift()
            const trackId = propTrackMap.get(trackKey)
            const track = this.getPropertyTrack(trackId)
            if (!track) {
                continue
            }
            queue.push(...track.children)
            const keyFrameList = this.getKeyFrameList(trackId)
            this.groupKeyframeByTime(keyFrameList, map)
        }
        return map
    }

    getKeyframeListByTime(time) {
        const responseId = this._getCurrentResponseId()
        const elementTrackMap = this.getElementTrackMap(responseId)
        const keyframeList = []
        elementTrackMap.forEach(elementTrackId => {
            const elementTrack = this.getElementTrack(elementTrackId)
            elementTrack.propertyTrackMap.forEach(propertyTrackId => {
                const keyFrameList = this.getKeyFrameList(propertyTrackId)
                if (keyFrameList) {
                    keyFrameList.forEach(keyFrameId => {
                        const keyFrame = this.getKeyFrame(keyFrameId)
                        if (keyFrame.time === time) {
                            keyframeList.push(keyFrameId)
                        }
                    })
                }
            })
        })
        return keyframeList
    }

    groupKeyframeEntityByTime(keyframeList = [], initialMap = {}) {
        return keyframeList.reduce((acc, keyframe) => {
            acc[keyframe.time] = acc[keyframe.time] || []
            acc[keyframe.time].push(keyframe.id)
            return acc
        }, initialMap)
    }

    groupKeyframeByTime(keyframeIdList = [], initialMap = {}) {
        const keyframeList = keyframeIdList.map(id => this.getKeyFrame(id)).filter(Boolean)
        return this.groupKeyframeEntityByTime(keyframeList, initialMap)
    }

    /**
     * Calculates if the selected keyframes are overlapped.
     *
     * @returns {boolean} true if any of the selected keyframes overlap, false otherwise.
     */
    getIsSelectedKeyframesOverlapped() {
        const selectedKeyframeIds = this.dataStore.selection.get('kfs')

        if (!selectedKeyframeIds.length) {
            return
        }
        const selectedKeyframes = selectedKeyframeIds.map(id => this.getKeyFrame(id)).filter(Boolean)
        return selectedKeyframes.some(selectedKeyframe => this.getKeyFrameByTime(selectedKeyframe.trackId, selectedKeyframe.time, selectedKeyframe.id))
    }

    getAllKeyFramesByTime(propertyTrackId, time, refId) {
        const keyFrameList = this.getKeyFrameList(propertyTrackId)
        if (!keyFrameList) {
            return []
        }
        const keyFrames = keyFrameList.map(id => this.getKeyFrame(id))
        return keyFrames.filter(keyFrame => keyFrame.id !== refId && keyFrame.time === time)
    }

    getKeyFrameByTime(propertyTrackId, time, refId) {
        const keyFrameList = this.getKeyFrameList(propertyTrackId)
        if (!keyFrameList) {
            return
        }
        const keyFrames = keyFrameList.map(id => this.getKeyFrame(id)).filter(Boolean)
        return keyFrames.find(keyFrame => keyFrame.id !== refId && keyFrame.time === time)
    }

    getKeyFrameList(propertyTrackId) {
        const propertyTrack = this.getPropertyTrack(propertyTrackId)
        if (!propertyTrack) {
            return []
        }
        return propertyTrack.keyFrameList
    }

    // @deprecate
    addKeyFrame(propertyTrackId, time, value, fire = true) {
        const propertyTrack = this.getPropertyTrack(propertyTrackId)
        if (!propertyTrack) {
            return false
        }

        const existKeyFrame = this.getKeyFrameByTime(propertyTrackId, time)
        if (existKeyFrame) {
            return false
        }

        const keyFrame = this._createKeyFrame(propertyTrackId, { time, value })

        const before = propertyTrack.keyFrameList.slice()
        propertyTrack.keyFrameList.push(keyFrame.id)

        this.changes.update(
            propertyTrack.id,
            'keyFrameList',
            new Change({
                before,
                after: propertyTrack.keyFrameList.slice(),
                index: before.length
            })
        )
        if (fire) {
            this.fire()
        }

        return keyFrame.id
    }

    flushKfByTime(entityId) {
        const entity = this.data.entityMap.get(entityId)
        if (!entity) {
            return
        }

        // recursive flush first
        switch (entity.type) {
            case InteractionEntityType.PROPERTY_TRACK: {
                if (entity.parentId) {
                    this.flushKfByTime(entity.parentId)
                } else {
                    this.flushKfByTime(entity.elementTrackId)
                }
                break
            }

            case InteractionEntityType.KEY_FRAME: {
                this.flushKfByTime(entity.trackId)
            }
        }

        this._cacheKeyframeGroupByTime.delete(entity)
    }

    getKeyframeIdList(trackId, time) {
        const track = this.data.entityMap.get(trackId)
        if (!track) {
            return []
        }

        if (this._cacheKeyframeGroupByTime.has(track)) {
            const map = this._cacheKeyframeGroupByTime.get(track)
            return map[time] || []
        }


        switch (track.type) {
            case InteractionEntityType.ELEMENT_TRACK: {
                const map = this.getElementTrackKeyFrameGroupByTime(trackId)
                this._cacheKeyframeGroupByTime.set(track, map)
                return map[time] || []
            }

            case InteractionEntityType.PROPERTY_TRACK: {
                const map = this.getPropertyTrackKeyFrameGroupByTime(trackId)
                this._cacheKeyframeGroupByTime.set(track, map)
                return map[time] || []
            }
            default:
                return []
        }
    }

    getKeyFrame(keyFrameId, entityMap) {
        const map = entityMap || this.data.entityMap
        const keyFrame = map.get(keyFrameId)
        if (
            !keyFrame ||
            keyFrame.type !== InteractionEntityType.KEY_FRAME
        ) {
            return
        }
        return keyFrame
    }

    getElementIdByKeyFrame(keyFrameId) {
        const keyFrame = this.data.entityMap.get(keyFrameId)
        if (!keyFrame) {
            return null
        }
        
        const propertyTrack = this.data.entityMap.get(keyFrame.trackId)
        const elementTrack = this.data.entityMap.get(propertyTrack.elementTrackId)
        return elementTrack.elementId
    }

    // TODO: test
    setSelectedKeyFrameEasingType(easingType) {
        const kfSelection = this.dataStore.selection.get('kfs')
        kfSelection.forEach(kfId => {
            this.setKeyFrameEasingType(kfId, easingType, false)
        })
        this.fire()
    }

    getMaxKeyframeOffset(keyframes) {
        const currentPlayheadTime = this._getTransitionTime()
        const offsets = keyframes.map((keyframe) => currentPlayheadTime - keyframe.time)
        return Math.max(...offsets)
    }

    // TODO: test
    setSelectedKeyFrameBezier(bezier) {
        const kfSelection = this.dataStore.selection.get('kfs')
        kfSelection.forEach(kfId => {
            this.setKeyFrameBezier(kfId, bezier, false)
        })
        this.fire()
    }

    getKeyframesTimeRange(keyframeList) {
        const keyframes = keyframeList.map(kfId => this.getKeyFrame(kfId)).filter(Boolean)
        const times = keyframes.map(kf => kf.time)
        return {
            min: Math.min(...times),
            max: Math.max(...times)
        }
    }

    getSelectedKeyframesTimeRange() {
        const kfSelection = this.dataStore.selection.get('kfs')
        return this.getKeyframesTimeRange(kfSelection)
    }

    getAllKeyframes(excludedSet = new Set()) {
        const list = []
        this.data.entityMap.forEach((entity) => {
            if (entity.type === InteractionEntityType.KEY_FRAME && !excludedSet.has(entity.id)) {
                list.push(entity)
            }
        })
        return list
    }

    getKeyframeTimeList(keyframeList) {
        return Array.from(new Set(keyframeList.map(keyframe => keyframe.time))).sort((a, b) => a - b)
    }


    _cacheKeyframeList(keyframeList) {
        // FIXME: (stretch-keyframe) better name
        this._cache = this._cache || this.groupKeyframeByTime(keyframeList)
        return this._cache
    }
    _clearCachedSelectedKeyframeList() {
        this._cache = undefined
    }

    stretchKeyframeList(keyframeList, startTime, endTime, finished = false) {
        const actionId = this._getCurrentActionId()
        const actionMaxTime = this.getActionMaxTime(actionId)
        const keyframesGroupByTime = this._cacheKeyframeList(keyframeList)
        const timeList = Object.keys(keyframesGroupByTime).map(Number).sort((a, b) => a - b)

        // normalize
        const minTime = timeList[0]
        const maxTime = timeList[timeList.length - 1]
        const timeRange = maxTime - minTime

        const clampedStartTime = Math.max(0, Math.min(actionMaxTime, startTime))
        const clampedEndTime = Math.max(0, Math.min(actionMaxTime, endTime))
        const scale = (clampedEndTime - clampedStartTime) / timeRange

        /*
        Keyframe replace order
        1. dragged stretch keyframe (t)
        2. stretch keyframe (t)
        2. time (selected)
        4. un-selected
        */
        const isDragEnd = startTime === timeList[0]
        const rest = timeList.slice(1, -1).reverse()
        const replaceOrder = isDragEnd ? [maxTime, minTime, ...rest] : [minTime, maxTime, ...rest]
        replaceOrder.forEach((time) => {
            const kfIdList = keyframesGroupByTime[time]
            const offsetTime = time - minTime
            const scaledTime = getTimeAtStep(offsetTime * scale + clampedStartTime)

            kfIdList.forEach(kfId => {
                this.setKeyFrameTime(kfId, scaledTime, {replace: finished, fire: false })
            })
        })

        if (finished) {
            // TODO: DRY sort keyframes
            const trackIds = new Set(keyframeList.map(keyframeId => this.getKeyFrame(keyframeId)?.trackId).filter(Boolean))

            // sort keyFrames by time
            for (const tid of trackIds) {
                const track = this.getPropertyTrack(tid)

                const before = [...track.keyFrameList]
                track.keyFrameList.sort((kfA, kfB) => this.getKeyFrame(kfA).time - this.getKeyFrame(kfB).time)

                const after = [...track.keyFrameList]
                if (!arrEquals(before, after)) {
                    this.changes.update(tid, 'keyFrameList', new Change({
                        before,
                        after
                    }))
                }
            }

            this._clearCachedSelectedKeyframeList()
        }

        this.fire(true, {flag: EventFlag.FROM_INTERACTION_CONTINUOUSLY_CHANGE})
    }

    // TODO: test
    setSelectedKeyFrameTimeByOffset(offset, replace = false) {
        const actionId = this._getCurrentActionId()
        const action = this.getAction(actionId)
        const kfSelection = this.dataStore.selection.get('kfs')
        const keyFrames = kfSelection.map(kfId => this.getKeyFrame(kfId))
        const kfTimes = keyFrames.map((kf) => kf.time)

        let acceptedOffset = offset
        if (offset < 0) {
            const low = Math.min(...kfTimes)
            if (low + offset <= 0) {
                acceptedOffset = 0 - low
            }
        } else {
            const high = Math.max(...kfTimes)
            if (high + offset >= action.maxTime) {
                acceptedOffset = action.maxTime - high
            }
        }

        keyFrames.forEach(kf => {
            this.setKeyFrameTime(kf.id, kf.time + acceptedOffset, { replace, fire: false })
        })

        // TODO: DRY sort keyframes
        const trackIds = new Set(keyFrames.map(kf => kf.trackId))

        // sort keyFrames by time
        for (const tid of trackIds) {
            const track = this.getPropertyTrack(tid)

            const before = [...track.keyFrameList]
            track.keyFrameList.sort((kfA, kfB) => this.getKeyFrame(kfA).time - this.getKeyFrame(kfB).time)

            const after = [...track.keyFrameList]
            if (!arrEquals(before, after)) {
                this.changes.update(tid, 'keyFrameList', new Change({
                    before,
                    after
                }))
            }
        }

        if (!this.changes.isEmpty()) {
            this.fire()
        }
    }

    // TODO: test
    deleteSelectedKeyFrame() {
        const kfSelection = new Set(this.dataStore.selection.get('kfs'))

        // Delete kfs
        kfSelection.forEach(keyFrameId => {
            this._deleteKeyFrame(keyFrameId)
        })

        this.fire()
        this.dataStore.selection.clearKFs()
    }

    // TODO: test
    duplicateSelectedKeyFrame() {
        const kfSelection = this.dataStore.selection.get('kfs')
        const keyFrames = kfSelection.map(kfId => this.getKeyFrame(kfId))
        const maxTimeOffset = this.getMaxKeyframeOffset(keyFrames)

        // clone kf by offset time
        const newKfIds = keyFrames.map(kf => this.cloneKeyFrame(kf.id, maxTimeOffset + kf.time, false)).filter(Boolean)

        const trackIds = new Set(keyFrames.map(kf => kf.trackId))

        // sort keyFrames by time
        for (const tid of trackIds) {
            const track = this.getPropertyTrack(tid)

            const before = [...track.keyFrameList]
            track.keyFrameList.sort((kfA, kfB) => this.getKeyFrame(kfA).time - this.getKeyFrame(kfB).time)

            const after = [...track.keyFrameList]
            if (!arrEquals(before, after)) {
                this.changes.update(tid, 'keyFrameList', new Change({
                    before,
                    after
                }))
            }
        }

        if (!this.changes.isEmpty()) {
            this.fire()
        }
        return newKfIds
    }

    setKeyFrameTime(keyFrameId, time, { replace = false, fire = true } = { replace: false, fire: true }) {
        const keyFrame = this.getKeyFrame(keyFrameId)

        if (!keyFrame) {
            return false
        }

        if (keyFrame.time === time && !replace) {
            return false
        }

        // replace the old KeyFrame(s) which at the same time
        if (replace) {
            const existKeyFrameList = this.getAllKeyFramesByTime(keyFrame.trackId, time, keyFrame.id)
            if (existKeyFrameList.length) {
                existKeyFrameList.forEach(existKeyFrame => {
                    this._deleteKeyFrame(existKeyFrame.id)
                })
            }
        }

        if (keyFrame.time !== time) {
            const before = keyFrame.time
            keyFrame.time = time

            this.changes.update(keyFrameId, 'time', new Change({
                before,
                after: keyFrame.time
            }))
        }

        if (fire) {
            this.fire()
        }

        return true
    }

    setKeyFrameFrameType(keyFrameId, frameType) {
        const keyFrame = this.getKeyFrame(keyFrameId)
        if (!keyFrame) {
            return false
        }

        if (!Object.values(FrameType).includes(frameType)) {
            return false
        }

        if (keyFrame.frameType === frameType) {
            return false
        }

        const before = keyFrame.frameType
        keyFrame.frameType = frameType

        this.changes.update(keyFrameId, 'frameType', new Change({
            before,
            after: keyFrame.frameType
        }))
        this.fire()

        return true
    }

    setKeyFrameEasingType(keyFrameId, easingType, fire = true) {
        const keyFrame = this.getKeyFrame(keyFrameId)
        if (!keyFrame) {
            return false
        }

        if (!keyFrame.animatable) {
            return false
        }

        if (!Object.values(EasingType).includes(easingType)) {
            return false
        }

        if (keyFrame.easingType === easingType) {
            return false
        }

        const before = keyFrame.easingType
        keyFrame.easingType = easingType

        this.changes.update(keyFrameId, 'easingType', new Change({
            before,
            after: keyFrame.easingType
        }))

        this.setKeyFrameBezier(keyFrameId, easePoints[easingType], false)

        if (fire) {
            this.fire()
        }
        return true
    }

    setKeyFrameBezier(keyFrameId, bezier, fire = true) {
        const keyFrame = this.getKeyFrame(keyFrameId)
        if (!keyFrame) {
            return false
        }

        if (!keyFrame.animatable) {
            return false
        }

        if (!Array.isArray(bezier) || bezier.length !== 4) {
            return false
        }

        if (bezier.every((v, i) => v === keyFrame.bezier[i])) {
            return false
        }

        const before = keyFrame.bezier
        keyFrame.bezier = bezier

        this.changes.update(keyFrameId, 'bezier', new Change({
            before,
            after: keyFrame.bezier
        }))

        const easingType = Object.entries(easePoints).find(([, points]) => arrEquals(bezier, points))
        if (easingType === undefined) {
            this.setKeyFrameEasingType(keyFrameId, EasingType.CUSTOM, false)
        } else {
            this.setKeyFrameEasingType(keyFrameId, Number(easingType[0]), false)
        }

        if (fire) {
            this.fire()
        }

        return true
    }

    setKeyFrameValue(keyFrameId, value, fire = true) {
        const keyFrame = this.getKeyFrame(keyFrameId)
        if (!keyFrame) {
            return false
        }

        if (keyFrame.value === value) {
            return false
        }

        const before = keyFrame.value
        keyFrame.value = value

        this.changes.update(keyFrameId, 'value', new Change({
            before,
            after: keyFrame.value
        }))

        if (keyFrame.frameType !== FrameType.EXPLICIT) {
            const before = keyFrame.frameType 
            keyFrame.frameType = FrameType.EXPLICIT
            this.changes.update(keyFrameId, 'frameType', new Change({
                before,
                after: keyFrame.frameType
            }))
        }

        if (fire) {
            this.fire()
        }

        return true
    }

    cloneKeyFrame(keyFrameId, time, fire = true) {
        const keyFrame = this.getKeyFrame(keyFrameId)

        if (!keyFrame) {
            return false
        }

        if (keyFrame.time === time || time > MAX_INTERACTION_TIME) {
            return false
        }

        const keyFrameList = this.getKeyFrameList(keyFrame.trackId)
        const before = keyFrameList.slice()

        // delete the origin KeyFrame which at the specified time
        const existKeyFrame = this.getKeyFrameByTime(keyFrame.trackId, time, keyFrame.id)
        if (existKeyFrame) {
            this._deleteKeyFrame(existKeyFrame.id)
        }

        const newKeyFrame = this._cloneKeyFrame({ ...keyFrame, time }, keyFrame.trackId)
        const index = keyFrameList.length
        keyFrameList.push(newKeyFrame.id)

        this.changes.update(
            keyFrame.trackId,
            'keyFrameList',
            new Change({
                before,
                after: keyFrameList,
                index
            })
        )

        if (fire) {
            this.fire()
        }

        return newKeyFrame.id
    }

    deleteKeyFrame(keyFrameId, fire = true) {
        const keyFrame = this.getKeyFrame(keyFrameId)
        if (!keyFrame) {
            return false
        }

        this._deleteKeyFrame(keyFrameId)
        this.dataStore.selection.removeKFs([keyFrameId], {commit: false})
        if (fire) {
            this.fire()
        }

        return true
    }

    deleteKeyFrameByElementProp(elementId, propKey, typeKey, time = this._getTransitionTime()) {
        const responseId = this._getCurrentResponseId()
        const elementTrackMap = this.getElementTrackMap(responseId)
        const elementTrackId = elementTrackMap.get(elementId)
        const elementTrack = this.getElementTrack(elementTrackId)
        if (!elementTrack) { return }

        const propertyKey = generatePropertyTrackKey(
            propKey,
            typeKey
        )
        const trackId = elementTrack.propertyTrackMap.get(propertyKey)
        const track = this.getPropertyTrack(trackId)
        if (!track) { return }

        const kf = this.getKeyFrameByTime(track.id, time)
        if (kf) {
            this.deleteKeyFrame(kf.id)
        }
    }

    /**
     * Set Action looping
     * @param {string} actionId
     * @param {boolean} looping
     * @returns {boolean}
     */
    setActionLooping(actionId, looping) {
        const action = this.getAction(actionId)
        if (!action) {
            return false
        }
        if (action.looping === looping) {
            return false
        }

        const before = action.looping
        action.looping = looping

        this.changes.update(
            actionId,
            'looping',
            new Change({ before, after: looping })
        )
        this.fire()

        return true
    }

    /**
     * Set Action speed
     * @param {string} actionId
     * @param {number} speed
     * @returns {boolean}
     */
    setActionSpeed(actionId, speed) {
        const action = this.getAction(actionId)
        if (!action) {
            return false
        }
        if (action.speed === speed) {
            return false
        }

        const before = action.speed
        action.speed = speed

        this.changes.update(
            actionId,
            'speed',
            new Change({ before, after: speed })
        )

        this.fire()

        return true
    }


    _getOrCreateElementTrack(elementId) {
        const responseId = this._getCurrentResponseId()
        const elementTrackMap = this.getElementTrackMap(responseId)
        const elementTrackId = elementTrackMap.get(elementId)
        let elementTrack = this.getElementTrack(elementTrackId)
        if (!elementTrack) {
            elementTrack = this._createElementTrack(responseId, elementId)
            const response = this.getResponse(responseId)
            const before = new Map(response.elementTrackMap)
            response.elementTrackMap.set(elementId, elementTrack.id)
            this.changes.update(response.id, 'elementTrackMap', new Change({ before, after: new Map(response.elementTrackMap) }))
        }
        return elementTrack
    }

    _createPropertyTrackRecursive(elementTrack, trackKeyList) {
        const trackList = []
        trackKeyList.reduce((child, key) => {
            const trackId = elementTrack.propertyTrackMap.get(key)
            let track = this.getPropertyTrack(trackId)
            if (!track) {
                track = this._createPropertyTrack(elementTrack.id, key)
                const before = new Map(elementTrack.propertyTrackMap)
                elementTrack.propertyTrackMap.set(track.key, track.id)
                this.changes.update(elementTrack.id, 'propertyTrackMap', new Change({ before, after: new Map(elementTrack.propertyTrackMap) }))
            }
            if (child) {
                if (!track.children.has(child.key)) {
                    child.parentId = track.id
                    const before = new Set(track.children)
                    track.children.add(child.key)
                    this.changes.update(track.id, 'children', new Change({ before, after: new Set(track.children) }))
                }
            }
            trackList.push(track)
            return track
        }, null)
        return trackList
    }

    _upsertRefKeyFrame(track, time, value, paintType, propKey, frameType = FrameType.EXPLICIT) {
        let kf = this.getKeyFrameByTime(track.id, time)
        if (kf) {
            if (kf.frameType !== frameType) {
                this.setKeyFrameFrameType(kf.id, frameType)
            }
            this._updateKeyFrameRefData(kf.id, { [propKey]: value }, false)
        } else {
            let paintId = ''
            let createGradient = false
            let refId = ''
            let computedLayer = null
            const layerId = track.key.split('.')[0]
            const layer = this.dataStore.library.getComponent(layerId)
            if (propKey === 'gradientStops' || propKey === 'gradientTransform') {
                createGradient = true
            }
            if (createGradient || propKey === 'paintType') {
                const elementTrack = this.getElementTrack(track.elementTrackId)
                const element = this.dataStore.getById(elementTrack.elementId)
                computedLayer = element.computedStyle.getComputedLayerById(layerId)
                const firstKF = this.getKeyFrame(track.keyFrameList[0])
                if (firstKF) {
                    refId = firstKF ? firstKF.ref : layer.paintId
                    if (this.dataStore.library.getComponent(refId).paintType !== PaintType.SOLID) {
                        createGradient = true
                    }
                }
            }

            if (createGradient) {
                const gradientData = {
                    gradientStops: propKey === 'gradientStops' ? value : computedLayer.get('gradientStops'),
                    gradientTransform: propKey === 'gradientTransform' ? value : computedLayer.get('gradientTransform')
                }
                if (refId && computedLayer) {
                    paintId = this.dataStore.library.cloneComponent(refId, NO_FIRE, gradientData)
                } else {
                    paintId = this.dataStore.library.addProperty(
                        PropComponentType.PAINT,
                        {
                            paintType,
                            ...gradientData
                        },
                        false
                    )
                }
            } else {
                paintId = this.dataStore.library.addProperty(
                    PropComponentType.PAINT,
                    {
                        paintType,
                        [propKey]: value
                    },
                    false
                )
            }
            const animatable = paintType !== PaintType.IMAGE && !nonAnimatableKeySet.has(propKey)
            kf = this._createKeyFrame(track.id, { time, ref: paintId, animatable, frameType })
            const before = track.keyFrameList.slice()

            // find index by time
            const index = track.keyFrameList.length === 0
                ? 0
                : findIndexToInsert(0, track.keyFrameList.length - 1, time, idx =>
                    this.getKeyFrame(track.keyFrameList[idx]).time
                )

            track.keyFrameList.splice(index, 0, kf.id)
            this.changes.update(
                track.id,
                'keyFrameList',
                new Change({
                    before,
                    after: track.keyFrameList.slice(),
                    index
                })
            )
        }
        return kf
    }

    _getFullUpdateValue(key, particalValue, kfValue = undefined) {
        let mergedValue = particalValue
        switch (key) {
            case 'motionPath': {
                mergedValue = {...DEFAULT_MOTION_PATH_VALUE, ...kfValue, ...particalValue}
                break
            }
        }
        return mergedValue
    }

    upsertKeyframeToPropertyTrack(track, { time, value, delta = false, frameType = FrameType.EXPLICIT, easingType, bezier }) {
        let kf = this.getKeyFrameByTime(track.id, time)
        const unit = this._getPropUnit(track)

        if (kf) {
            const newValue = this._getFullUpdateValue(track.key, value, kf.value)
            if (kf.frameType !== frameType) {
                this.setKeyFrameFrameType(kf.id, frameType)
            }

            this.setKeyFrameEasingType(kf.id, easingType, false)
            this._updateKeyFrameData(kf.id, { value: newValue, delta, unit })

        } else {
            const newValue = this._getFullUpdateValue(track.key, value)
            const animatable = !nonAnimatableKeySet.has(track.key)
            kf = this._createKeyFrame(track.id, { time, value: newValue, unit, delta, animatable, frameType, easingType, bezier })
            const before = track.keyFrameList.slice()

            // find index by time
            const index = track.keyFrameList.length === 0
                ? 0
                : findIndexToInsert(0, track.keyFrameList.length - 1, time, idx =>
                    this.getKeyFrame(track.keyFrameList[idx]).time
                )

            track.keyFrameList.splice(index, 0, kf.id)
            this.changes.update(
                track.id,
                'keyFrameList',
                new Change({
                    before,
                    after: track.keyFrameList.slice(),
                    index
                })
            )
        }
        return kf
    }

    _getPropUnit(track) {
        let unit = Unit.PIXEL
        const unitChange = AVAILABLE_UNIT_CHANGE.get(track.key)
        if (unitChange) {
            const elementTrack = this.getElementTrack(track.elementTrackId)
            const element = this.dataStore.getElement(elementTrack.elementId)
            const component = this.dataStore.library.getComponent(element.base[track.key])
            unit = component[unitChange]
        }

        return unit
    }

    addLayer(elementId, layerType) {
        const elementTrack = this._getOrCreateElementTrack(elementId)
        const layerListKey = LayerTypeMapLayerListKey[layerType]

        // use ID as key for layer track
        const trackKeyList = [undefined, layerListKey]
        const [layerTrack] = this._createPropertyTrackRecursive(elementTrack, trackKeyList)

        const opacityTrack = this._createPropertyTrack(elementTrack.id, 'opacity', layerTrack.key)
        opacityTrack.parentId = layerTrack.id

        let before = new Set(layerTrack.children)
        layerTrack.children.add(opacityTrack.key)
        this.changes.update(
            layerTrack.id,
            'children',
            new Change({
                before,
                after: new Set(layerTrack.children)
            })
        )

        before = new Map(elementTrack.propertyTrackMap)
        elementTrack.propertyTrackMap.set(opacityTrack.key, opacityTrack.id)
        this.changes.update(
            elementTrack.id,
            'propertyTrackMap',
            new Change({
                before,
                after: new Map(elementTrack.propertyTrackMap)
            })
        )

        before = elementTrack[layerListKey].slice()
        elementTrack[layerListKey].push(layerTrack.id)
        this.changes.update(
            elementTrack.id,
            layerListKey,
            new Change({
                before,
                after: elementTrack[layerListKey].slice()
            })
        )

        const time = this._getTransitionTime()
        this.upsertKeyframeToPropertyTrack(opacityTrack, { time, value: 1 })

        this.fire()

        return layerTrack.id
    }

    reorderLayer(elementId, layerKey, toIndex) {
        const elementTrack = this._getOrCreateElementTrack(elementId)
        const trackId = elementTrack.propertyTrackMap.get(layerKey)
        const track = this.getPropertyTrack(trackId)
        const layerListTrack = this.getPropertyTrack(track.parentId)
        const layerListKey = layerListTrack.key

        const fromIndex = elementTrack[layerListKey].indexOf(track.id)
        const before = elementTrack[layerListKey].slice()
        this._reorderList(elementTrack[layerListKey], fromIndex, toIndex)
        this.changes.update(
            elementTrack.id,
            layerListKey,
            new Change({
                before,
                after: elementTrack[layerListKey].slice(),
                fromIndex,
                toIndex
            })
        )
    }

    _updateKeyFrameRefData(keyFrameId, data, fire) {
        const keyFrame = this.getKeyFrame(keyFrameId)
        const paintId = keyFrame.ref
        this.changes.update(keyFrame.id, 'ref', new Change({
            before: keyFrame.ref, after: keyFrame.ref
        }))
        this.dataStore.library.setProperty(paintId, data, true, fire)
    }

    _updateKeyFrameData(keyFrameId, { value, delta = false, unit = Unit.PIXEL }) {
        const kf = this.getKeyFrame(keyFrameId)
        if (kf.value !== value) {
            const before = kf.value
            kf.value = value
            this.changes.update(kf.id, 'value', new Change({ before, after: value }))
        }
        if (kf.delta !== delta) {
            const before = kf.delta
            kf.delta = delta
            this.changes.update(kf.id, 'delta', new Change({ before, after: delta }))
        }
        if (kf.unit !== unit) {
            const before = kf.unit
            kf.unit = unit
            this.changes.update(kf.id, 'unit', new Change({ before, after: unit }))
        }
    }

    setEffect(elementId, effectId, propKey, value, frameType) {
        const elementTrack = this._getOrCreateElementTrack(elementId)
        const effect = this.dataStore.library.getComponent(effectId)
        const effectKey = EFFECT_TYPE_NAME_MAP[effect.effectType]
        const propertyTrackKey = generatePropertyTrackKey(propKey, effectKey)
        const time = this._getTransitionTime()
        const trackId = elementTrack.propertyTrackMap.get(propertyTrackKey)
        let propertyTrack = this.getPropertyTrack(trackId)
        if (!propertyTrack) {
            const trackKeyList = [propertyTrackKey, effectKey]
            propertyTrack = this._createPropertyTrackRecursive(elementTrack, trackKeyList)[0]
        }
        const keyframe = this.upsertKeyframeToPropertyTrack(propertyTrack, { time, value, delta: false, frameType })

        this.fire()
        return keyframe.id
    }

    setLayer(elementId, layerKey, propKey, value, meta, frameType) {
        const elementTrack = this._getOrCreateElementTrack(elementId)
        const propertyTrackKey = generatePropertyTrackKey(propKey, layerKey)
        const time = this._getTransitionTime()
        const trackId = elementTrack.propertyTrackMap.get(propertyTrackKey)

        let propertyTrack = this.getPropertyTrack(trackId)
        const newValue = value
        if (!propertyTrack) {
            const layerPropKey = generateLayerPropertyTrackKey(propKey, layerKey)
            const trackKeyList = [layerPropKey, layerKey, LayerTypeMapLayerListKey[meta.layerType]]
            propertyTrack = this._createPropertyTrackRecursive(elementTrack, trackKeyList)[0]
        }

        this._updateRelatedKeyFrameList(propertyTrack, propKey, newValue)

        let kf = this.getKeyFrameByTime(propertyTrack.id, time)
        if (!(kf && propKey === 'paintType')) {
            if (isPaintPropKey(propKey)) {
                kf = this._upsertRefKeyFrame(propertyTrack, time, newValue, meta.paintType, propKey, frameType)
            } else {
                kf = this.upsertKeyframeToPropertyTrack(propertyTrack, { time, value: newValue, delta: false, frameType })
            }
        }

        this.fire()
        return kf.id
    }

    upsertKeyframeToElement(element, propertyKey, keyframeData) {
        if (generalChildKeySet.has(propertyKey)) {
            return this._upsertGeneralKeyframeProperty(element.get('id'), propertyKey, keyframeData)
        } else if (effectChildKeySet.has(propertyKey)) {
            return this._upsertEffectKeyframeProperty(element, propertyKey, keyframeData)
        } else {
            console.warn(`Keyframe property ${propertyKey} is not allowed to be pasted.`)
            return null
        }
    }

    _upsertGeneralKeyframeProperty(elementId, propertyKey, keyframeData) {
        const generalPropertyGroupKey = GENERAL_PROPERTY_GROUP_MAP[propertyKey]

        return this._upsertElementKeyframe(elementId, keyframeData, [propertyKey, generalPropertyGroupKey])
    }

    _upsertEffectKeyframeProperty(element, propertyKey, keyframeData) {
        const [effectTypeKey] = propertyKey.split('.')
        const effectTypeValue = EFFECT_TYPE_VALUE_MAP[effectTypeKey]

        if (!canElementApplyEffect(element, effectTypeValue)) {
            return null
        }

        this.dataStore.library.addEffect(element.base.effects[0], this.dataStore.editor.effectList.length, { effectType: effectTypeValue })
        return this._upsertElementKeyframe(element.get('id'), keyframeData, [propertyKey, effectTypeKey])
    }

    _upsertElementKeyframe(elementId, keyframeData, propertyTrackKeyList) {
        const elementTrack = this._getOrCreateElementTrack(elementId)
        const [newPropertyTrack] = this._createPropertyTrackRecursive(elementTrack, propertyTrackKeyList)
        return this.upsertKeyframeToPropertyTrack(newPropertyTrack, keyframeData)
    }

    /**
     * Update related KeyFrame list
     * @param {PropertyTrack} track
     * @param {string} propKey
     * @param {number|object} newValue
     */
    _updateRelatedKeyFrameList(track, propKey, newValue) {
        switch (propKey) {
            case 'paintType': {
                const existsKeyFrameList = this.getKeyFrameList(track.id)
                existsKeyFrameList.forEach(keyFrameId => {
                    const keyFrame = this.getKeyFrame(keyFrameId)
                    const paintComponent = this.dataStore.library.getComponent(keyFrame.ref)
                    const oldPaintType = paintComponent.paintType
                    const paintData = { [propKey]: newValue }
                    // Should also update ref component gradient and color if change paintType
                    if (newValue === PaintType.SOLID && GRADIENT_PAINT_SET.has(oldPaintType)) {
                        const firstGradientStopColor = paintComponent.gradientStops[0].color
                        paintData.color = [...firstGradientStopColor]
                    } else if (oldPaintType === PaintType.SOLID && GRADIENT_PAINT_SET.has(newValue)) {
                        const [r, g, b] = paintComponent.color
                        paintData.gradientStops = [
                            { color: [r, g, b, 1], position: 0 },
                            { color: [r, g, b, 0], position: 1 }
                        ]
                    }
                    this._updateKeyFrameRefData(keyFrameId, paintData, false)
                })
                break
            }
            case 'origin': {
                const existsKeyFrameList = this.getKeyFrameList(track.id)
                const elementId = this.getElementTrack(track.elementTrackId).elementId
                existsKeyFrameList.forEach(keyFrameId => {
                    const keyFrame = this.getKeyFrame(keyFrameId)
                    // If kf unit is the same as new unit, then no need to change.
                    if (keyFrame.unit === newValue.originXUnit) {
                        return
                    }

                    const widthData = this.dataStore.transition.getPropertyValueByTime(elementId, 'width', keyFrame.time)
                    const heightData = this.dataStore.transition.getPropertyValueByTime(elementId, 'height', keyFrame.time)
                    const newData = {
                        originXUnit: newValue.originXUnit,
                        originYUnit: newValue.originYUnit
                    }

                    switch (newValue.originXUnit) {
                        case Unit.PIXEL:
                            newData.originX = keyFrame.value.originX * widthData.width / 100
                            newData.originY = keyFrame.value.originY * heightData.height / 100
                            break
                        case Unit.PERCENT:
                            newData.originX = (keyFrame.value.originX / widthData.width) * 100
                            newData.originY = (keyFrame.value.originY / heightData.height) * 100
                            break
                    }

                    this._updateKeyFrameData(keyFrameId, { value: newData, delta: false, unit: newValue.originXUnit })
                })
                break
            }
        }
    }

    /**
     * Update KeyFrame list with unit has changed
     * @param {string} elementId
     * @param {Map} changes
     */
    updateKeyFrameListWithUnitChange(elementId, changes) {
        const changePropsMap = new Map()
        ALWAYS_UPDATE_WITH_UNIT_CHANGE.forEach((dataKey) => {
            const data = changes.get(dataKey)
            const propKey = UNIT_CHANGE_PROPS_MAP.get(dataKey)
            if (data && !changePropsMap.has(propKey)) {
                changePropsMap.set(propKey, { [dataKey]: data.value })
            }
        })

        changePropsMap.forEach((data, propKey) => {
            const elementTrackId = this.getElementTrackIdByElementId(elementId)
            const elementTrack = this.getElementTrack(elementTrackId)
            if (!elementTrack) {
                return
            }
            const propertyTrackId = elementTrack.propertyTrackMap.get(propKey)
            const propertyTrack = this.getPropertyTrack(propertyTrackId)
            if (!propertyTrack) {
                return
            }

            this._updateRelatedKeyFrameList(propertyTrack, propKey, data)
        })
    }

    deleteLayer(elementId, layerKey, fire = true) {
        const responseId = this._getCurrentResponseId()
        const elementTrackMap = this.getElementTrackMap(responseId)
        const elementTrackId = elementTrackMap.get(elementId)
        const elementTrack = this.getElementTrack(elementTrackId)

        if (!elementTrack || elementTrack.elementId !== elementId) return false

        const layerTrackId = elementTrack.propertyTrackMap.get(layerKey)
        const layerTrack = this.getPropertyTrack(layerTrackId)

        if (!layerTrack) return false

        this._deletePropertyTrack(layerTrackId)

        if (fire) {
            this.fire()
        }

        return true
    }

    setProperty(elementId, propKey, value, frameType, delta = false, options = { fire: true }) {
        const trackIds = this.setProperties([{
            elementId,
            propKey,
            delta,
            frameType,
            value
        }], options)
        return trackIds[0]
    }

    setProperties(changes, options = { fire: true }) {
        const trackIds = []
        const time = this._getTransitionTime()

        changes.forEach((change) => {
            const { elementId, propKey, value, delta = false, frameType } = change
            const elementTrack = this._getOrCreateElementTrack(elementId)
            const propertyTrackKey = generatePropertyTrackKey(propKey)
            const trackKeyList = [propertyTrackKey]
            const trackGroupKey = GENERAL_PROPERTY_GROUP_MAP[propertyTrackKey]

            if (trackGroupKey) {
                trackKeyList.push(trackGroupKey)
            }

            const [propertyTrack] = this._createPropertyTrackRecursive(elementTrack, trackKeyList)
            this.upsertKeyframeToPropertyTrack(propertyTrack, { time, value, delta, frameType })
            trackIds.push(propertyTrack.id)

            const unitChangeKey = SHOULD_UPDATE_ALL_KFS_WITH_UNIT_CHANGES.get(propertyTrackKey)
            const shouldUpdateAllKfs = unitChangeKey && unitChangeKey.some((key) => value[key] !== undefined)
            if (shouldUpdateAllKfs) {
                this._updateRelatedKeyFrameList(propertyTrack, propertyTrackKey, value)
            }
        })

        if (options.fire) {
            this.fire()
        }

        return trackIds
    }

    getChildList(entityId) {
        const entity = this.data.entityMap.get(entityId)
        if (!entity) {
            return []
        }

        if (entity.type === InteractionEntityType.ELEMENT_TRACK) {
            return childListMap.get('ROOT')
                .filter(key => entity.children.has(key))
                .map(key => entity.propertyTrackMap.get(key))
        }
        if (entity.type === InteractionEntityType.PROPERTY_TRACK) {
            const elementTrack = this.getElementTrack(entity.elementTrackId)
            if (!entity.children.size) { // prop, LAYER.prop
                return []
            }
            if (childListMap.has(entity.key)) { // prop group & layer list
                if (LayerListKeySet.has(entity.key)) { // layer list
                    // keep non-exists track in the list for the index mapping
                    const element = this.dataStore.getById(elementTrack.elementId)
                    return Array.from(element.computedStyle[entity.key])
                        .map((cl) => elementTrack.propertyTrackMap.get(cl.get('layerId') || cl.get('trackId')))
                } else { // prop group
                    return childListMap.get(entity.key)
                        .filter(key => entity.children.has(key))
                        .map(key => elementTrack.propertyTrackMap.get(key))
                }
            } else { // LAYER
                return childListMap.get('LAYER')
                    .filter(key => entity.children.has(`${entity.key}.${key}`))
                    .map(key => elementTrack.propertyTrackMap.get(`${entity.key}.${key}`))
            }
        }
    }

    deleteEffect(elementId, effectId) {
        const effect = this.dataStore.library.getComponent(effectId)
        const effectKey = EFFECT_TYPE_NAME_MAP[effect.effectType]
        const elementTrackId = this.getElementTrackIdByElementId(elementId)
        const elementTrack = this.getElementTrack(elementTrackId)
        if (!elementTrack) {
            return
        }
        const trackId = elementTrack.propertyTrackMap.get(effectKey)

        if (trackId) {
            this._deletePropertyTrack(trackId)
        }
        this.fire()
    }
}

export default Manager

/** @typedef {string} ID */

/**
 * @typedef {object} Action
 * @property {ID} id
 * @property {'ACTION'} type
 * @property {string} name
 * @property {ID[]} responseList
 * @property {ID[]} triggerList
 */

/** @typedef {'CLICK' | 'DOUBLE_CLICK' | 'DRAG' | 'EDGE_SWIPE' | 'FORCE_TAP' | 'HOVER' | 'KEY_PRESS' | 'LOAD' | 'LONG_PRESS' | 'MANY_CLICK' | 'MOUSE_MOVE' | 'PINCH' | 'PRESS' | 'ROTATE' | 'SCROLL' | 'SWIPE'} TriggerType */

/**
 * @typedef {object} Trigger
 * @property {ID} id
 * @property {'TRIGGER'} type
 * @property {ID} actionId
 * @property {ID} elementId
 * @property {TriggerType} triggerType
 */

/**
 * @typedef {object} Response
 * @property {ID} id
 * @property {'RESPONSE'} type
 * @property {ID} actionId
 * @property {string} name
 * @property {ID[]} conditionList
 * @property {Map<ID | ID>} elementTrackMap
 */

/** @typedef {'AND' | 'OR'} Logic */
/** @typedef {'HAS_STYLE' | 'NOT_HAVE_STYLE' | 'NONE'} Operation */

/**
 * @typedef {object} Condition
 * @property {ID} id
 * @property {'CONDITION'} type
 * @property {ID} responseId
 * @property {Logic} logic
 * @property {Operation} operation
 * @property {ID} elementId
 */

/**
 * @typedef {object} ElementTrack
 * @property {ID} id
 * @property {'ELEMENT_TRACK'} type
 * @property {ID} responseId
 * @property {ID} elementId
 * @property {Map<ID | ID>} propertyTrackMap
 */

/**
 * @typedef {object} PropertyTrack
 * @property {ID} id
 * @property {'PROPERTY_TRACK'} type
 * @property {string} key
 * @property {ID} elementTrackId
 * @property {ID} parentId
 * @property {Set<string>} children
 * @property {ID[]} keyFrameList
 */

/** @typedef {'EXPLICIT' | 'INITIAL'} FrameType */
/** @typedef {'LINEAR' | 'EASE' | 'EASE_IN' | 'EASE_OUT' | 'EASE_IN_OUT' | 'EASE_IN_SIN' | 'EASE_OUT_SINE' | 'EASE_IN_OUT_SINE' | 'EASE_IN_QUAD' | 'EASE_OUT_QUAD' | 'EASE_IN_OUT_QUAD' | 'EASE_IN_CUBIC' | 'EASE_OUT_CUBIC' | 'EASE_IN_OUT_CUBIC' | 'EASE_IN_QUART' | 'EASE_OUT_QUART' | 'EASE_IN_OUT_QUART' | 'EASE_IN_QUINT' | 'EASE_OUT_QUINT' | 'EASE_IN_OUT_QUINT' | 'EASE_IN_EXPO' | 'EASE_OUT_EXPO' | 'EASE_IN_OUT_EXPO' | 'EASE_IN_CIRC' | 'EASE_OUT_CIRC' | 'EASE_IN_OUT_CIRC' | 'EASE_IN_BACK' | 'EASE_OUT_BACK' | 'EASE_IN_OUT_BACK'} EasingType */
/** @typedef {Array<[number | number | number | number]>} Bezier */

/**
 * @typedef {object} KeyFrame
 * @property {ID} id
 * @property {'KEY_FRAME'} type
 * @property {FrameType} frameType
 * @property {EasingType} easingType
 * @property {Bezier} bezier
 * @property {number} steps
 * @property {number} time
 * @property {boolean} delta
 * @property {any} value
 * @property {ID} ref
 */

/** @typedef {Action | Response | Trigger | Condition | ElementTrack | PropertyTrack | KeyFrame} Entity */

/**
 * @typedef {object} InteractionData
 * @property {ID[]} actionList
 * @property {Map<ID | Entity>} entityMap
 */
