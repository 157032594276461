import { FontHelper } from '@phase-software/data-store/src/media/FontHelper'
import fontkit from 'fontkit'
import { makeOuterMostContourCCW } from '../geometry/utils'
import { Resource } from "../Resource"
import { PathData, Command } from '../geometry/PathData'

/** @typedef {import('fontkit').Font} Font */
/** @typedef {import('fontkit').Glyph} Glyph */

export class FontResource extends Resource {
    constructor() {
        super()

        /** @type {Font} */
        this._font = null

        /**
         * Cached inverted fontkit glyph paths
         * @type {Record<number, PathData>}
         */
        this._cache = {}
    }

    /** @returns {Font|null} */
    getData() {
        return this._font
    }

    /**
     * @param {string} fontName
     */
    setFontName(fontName) {
        FontHelper.instance().getURL(fontName)
            .then(url => fetch(url))
            .then(res => res.arrayBuffer())
            .then(buf => {
                this._font = fontkit.create(Buffer.from(buf))
                this._dirty = true
            })
            // TODO: depending on error, retry or use a fallback
            // .catch(err => {})
    }

    /**
     * @param {Glyph} glyph
     * @returns {PathData}
     */
    getGlyphPath(glyph) {
        if (!glyph || !this._font) return null

        let path = this._cache[glyph.id]
        if (path) return path

        path = commandsToContours(glyph.path.commands)

        // TODO: see if this is still necessary
        makeOuterMostContourCCW(path)

        this._cache[glyph.id] = path
        return path
    }
}

/**
 * get initial set of lines
 * @param {import('fontkit').PathCommand[]} commands
 * @returns {PathData}
 */
function commandsToContours(commands) {
    if (commands.length === 0) return null

    const path = new PathData()

    /** @type {number} */
    let firstX = null
    /** @type {number} */
    let firstY = null

    /** @type {number} */
    let lastX = null
    /** @type {number} */
    let lastY = null

    const close = () => {
        if (firstX !== null && firstY !== null && (firstX !== lastX || firstY !== lastY)) {
            path.commands.push(Command.L)
            path.vertices.push(firstX, -firstY)

            firstX = null
            firstY = null
        }
    }

    for (const { command, args } of commands) {
        const f = command[0]
        if (f === 'm') { // moveTo
            close()

            path.commands.push(Command.M)
            path.vertices.push(args[0], -args[1])
            firstX = args[0]
            firstY = args[1]
            lastX = args[0]
            lastY = args[1]
        } else if (f === 'l') { // lineTo
            path.commands.push(Command.L)
            path.vertices.push(args[0], -args[1])
            lastX = args[0]
            lastY = args[1]
        } else if (f === 'q') { // quadraticCurveTo
            path.commands.push(Command.Q)
            path.vertices.push(args[0], -args[1], args[2], -args[3])
            lastX = args[2]
            lastY = args[3]
        } else if (f === 'b') { // bezierCurveTo
            path.commands.push(Command.C)
            path.vertices.push(args[0], -args[1], args[2], -args[3], args[4], -args[5])
            lastX = args[4]
            lastY = args[5]
        }
    }

    close()

    return path
}
