import { IDType } from '@phase-software/types'
import { Stats, genItMap, genItFilter, itFilterMap, isId } from '@phase-software/data-utils'
import ElementStack from '../components/ElementStack'
import {
  NON_REPEATABLE_PROPERTIES,
  REPEATABLE_PROPERTIES,
  SUB_PROPERTIES,
  EFFECT_PROPERTIES,
  SUB_EFFECT_PROPERTIES
} from '../components/instance'
import { MERGE_STACK_SUB_PROPERTIES, SWITCH_COMPONENT_PROPS, DELTA_PROP_KEY_SET } from '../constant'
import {
  cacheNonRepeatablePropertyBaseValue,
  cacheAllRepeatablePropertyBaseValue
} from '../utils/data'


/** @typedef {import('@phase-software/data-store/src/DataStore').DataStore} DataStore */
/** @typedef {import('@phase-software/data-store/src/interaction/Manager').default} Interaction */
/** @typedef {import('../index').default} TransitionManager */

const SHOULD_UPDATE_ANIMATION_FOR_KEYFRAME = new Set(['keyFrameList', 'children', 'time', 'frameType', 'easingType', 'bezier', 'ref'])

class Cache {
  /**
   *
   * @param {TransitionManager} manager
   */
  constructor(manager) {
    /** @type {TransitionManager} */
    this.manager = manager
    this._maxKFTime = -1 // -1 means there is no kf in all ElementStacks
    /** @type {Map<string, ElementStack>} map of ElementStack ID to ElementStack instance; ElementStack ID is same as Element ID */
    this.elementsStack = new Map()
    this.customEvents = new Map()
    this.dataMap = new Map()
    this._kfDiffsValid = new Map()
    this.isProcessingChangeEvent = false
    this._handleIMChangeFunc = this._handleIMChange.bind(this)
    this.watches()
  }

  watches() {
    // FIXME: restore after TM ready
    this.interaction.on('INTERACTION_CHANGES', this._handleIMChangeFunc)
  }

  unwatch() {
    this.interaction.off('INTERACTION_CHANGES', this._handleIMChangeFunc)
  }

  /** @returns {DataStore} */
  get dataStore() {
    return this.manager.dataStore
  }

  /** @returns {Interaction} */
  get interaction() {
    return this.manager.interaction
  }

  /**
   * Add component to data Map
   * @param {object} com
   */
  addToMap(com) {
    this.dataMap.set(com.id, com)
  }

  /**
   * Delete component from data Map
   * @param {string} id
   */
  deleteFromMap(id) {
    this.dataMap.delete(id)
  }

  /**
   * Get component from data Map
   * @param {string} id
   * @returns {*}
   */
  getById(id) {
    return this.dataMap.get(id)
  }

  /**
   * Add custom event from outside
   * @param {string} key
   * @param {Function} fn
   */
  addCustomEvent(key, fn) {
    this.customEvents.set(key, fn);
  }

  /**
   * Reload elements in the whole workspace
   */
  reloadElements() {
    this.elementsStack.forEach((elementStack) => {
      const element = this.dataStore.getById(elementStack.id)
      element.computedStyle.reload()
    })
  }

  /**
   * Add element stack
   * @param {ElementStack} elementStack
   */
  addElementStack(elementStack) {
    this.elementsStack.set(elementStack.id, elementStack);
    this.addToMap(elementStack)
  }

  /**
   * Get element stack by id
   * @param {string} elementId
   * @returns {ElementStack}
   */
  getElementStack(elementId) {
    return this.elementsStack.get(elementId);
  }

  /**
   * Get element stack by track id
   * @param {string} elementTrackId
   * @returns {ElementStack}
   */
  getElementStackByTrackId(elementTrackId) {
    for (const [, elementStack] of this.elementsStack) {
      if (elementTrackId === elementStack.trackId) {
        return elementStack
      }
    }
  }

  /**
   * Delete a element stack by id
   * @private
   * @param {string} elementId
   */
  _deleteElementStack(elementId) {
    // Only Action mode need to handle non-base
    if (this.dataStore.isActionMode) {
      const elementStack = this.elementsStack.get(elementId)

      // only reset computed data when element still exists
      if (this.dataStore.hasElement(elementId)) {
        elementStack.resetAllComputedData()
      }
    }

    // update element animation status before removing the stack from stacks
    this.updateElementAnimationStatus(false, [elementId])
    this.elementsStack.delete(elementId)
    this.deleteFromMap(elementId)
  }

  /**
   * Init element stack
   * @param {string} elementId
   * @param {ElementTrack} elementTrack
   */
  initElementStack(elementId, elementTrack) {
    if (!this.elementsStack.has(elementId)) {
      const newElementStack = new ElementStack(
        this.manager,
        elementTrack
      )
      newElementStack.resetStartTime(this.manager.currentElapsedTime - this.manager.currentTime)
      this.addElementStack(newElementStack)
      this.updateElementAnimationStatus(true, [elementId])
    }
  }

  /**
   * Cache all elements base value
   */
  cacheAllElementsBaseValue() {
    this.elementsStack.forEach((elementStack) => {
      elementStack.cacheBaseValue()
    })
  }

  /**
   * Cache specific element base value
   * @param {string} elementId
   * @param {string} propKey
   */
  cacheSpecificElementBaseValue(elementId, propKey) {
    const elementStack = this.elementsStack.get(elementId)
    if (elementStack) {
      elementStack.cachePropertyBaseValueByCategory(propKey)
    }
  }

  /**
   * Assign actions to the Element
   * @param {ElementTrack} elementTrack
   * @param {number} [startTime=0]
   */
  assignElementActions(elementTrack, startTime = 0) {
    const { elementId, propertyTrackMap } = elementTrack
    this._addElementProperties(elementId, propertyTrackMap, startTime)
  }

  /**
   * Update element with animation data
   * @param {number} time
   * @param {string[]} [elementIds] list of elements (specified by ID) to update animation data for.
   *                                If not specified, will update for all element stacks
   * @param {bool} [updateParentsBounds=false]
   */
  updateElementData(time, elementIds, updateParentsBounds = false) {
    Stats.begin('tm/updateElementData')
    const list = elementIds
      ? itFilterMap(elementIds, id => this.elementsStack.has(id), id => this.elementsStack.get(id))
      : this.elementsStack
    const parents = new Set()

    list.forEach(elementStack => {
      parents.add(this.dataStore.getParentOf(elementStack.element))
      elementStack.updateComputedData(time)
    })

    if (updateParentsBounds) {
      // Recalculate all parents bounds at once if they are computed group
      parents.forEach((parent) => {
        if (parent && parent.isContainer && parent.isComputedGroup) {
          parent.recalculateBounds()
        }
      })
    }

    parents.clear()

    const kfDiffs = this._getElementsPropsStateChange(time, list)
    this.fireKFInfoChange(kfDiffs)
    Stats.end('tm/updateElementData')
  }

  /**
   * Set animation status for all elements that are being animated
   * @param {boolean} isAnimated
   * @param {string[]} elementIds  list of elements (specified by ID) to update animation status for.
   *                                If not specified, will update for all element stacks
   */
  updateElementAnimationStatus(isAnimated, elementIds) {
    const list = elementIds
      ? genItFilter(elementIds, id => this.elementsStack.has(id))
      : genItMap(this.elementsStack.values(), elStack => elStack.id)
    for (const elId of list) {
      const element = this.dataStore.getById(elId)
      element.set('isAnimated', isAnimated)
    }
  }

  /**
   * Update element computed style by element ids
   * @param {string[]} elementIds
   */
  updateElementComputedStyleByElementIds(elementIds) {
    for (const elId of elementIds) {
        if (this.dataStore.hasElement(elId)) {
            const element = this.dataStore.getById(elId)
            element.computedStyle.reload(true)
        }
    }
  }

  /**
   * Clear all element stacks
   */
  clearElementsStack() {
    this.elementsStack.forEach((elementStack) => {
      elementStack.clear()
    })
    this.elementsStack.clear()
  }

  /**
   * Reset element cache startTime
   * @param {number} time
   */
  resetElementStartTime(time) {
    Stats.begin('tm/resetElementStartTime')
    this.elementsStack.forEach((elementStack) => {
      elementStack.resetStartTime(time)
    })
    Stats.end('tm/resetElementStartTime')
  }

  /**
   * Add element property stacks
   * @private
   * @param {string} elementId
   * @param {Map} propertyTrackMap
   * @param {number} [startTime=0]
   */
  _addElementProperties(elementId, propertyTrackMap, startTime = 0) {
    const elementStack = this.getElementStack(elementId)
    if (elementStack) {
      elementStack.addProperties(propertyTrackMap, startTime)
    }
  }

  /**
   * Delete a property stack from the element
   * @private
   * @param {string} elementId
   * @param {string} propertyTrackId
   */
  _deleteElementProperty(elementId, propertyTrackId) {
    const elementStack = this.getElementStack(elementId)
    if (elementStack) {
      elementStack.deleteProperty(propertyTrackId)
    }
  }

  /**
   * Add child property stack
   * @private
   * @param {string} parentTrackId
   * @param {PropertyTrack} childTrack
   * @param {string} key
   * @param {number} [startTime=0]
   */
  _addChildProperty(parentTrackId, childTrack, key, startTime = 0) {
    if (!SUB_PROPERTIES[key] && !SUB_EFFECT_PROPERTIES[key]) {
      return
    }

    const parentStack = this.getById(parentTrackId)
    // If parent stack does not exist, then do nothing
    if (!parentStack) {
      return
    }

    const elementStack = parentStack.elementStack
    const newTrack = {
      ...childTrack,
      key
    }
    const childStack = elementStack._addProperty(newTrack, parentTrackId, startTime)
    parentStack.addChildren(childStack)
  }

  /**
   * Update keyframes order
   * @param {string} propertyTrackId
   */
  updateKeyFrameOrder(propertyTrackId) {
    const propertyStack = this.getById(propertyTrackId)
    if (propertyStack && propertyStack.type === 'PROPERTY') {
      const propertyTrack = this.interaction.getPropertyTrack(propertyTrackId)
      const newKFList = propertyTrack ? propertyTrack.keyFrameList : []
      propertyStack.updateKeyFrameOrder(newKFList)
    }
  }

  /**
   * Add a new keyframe to a property stack
   * @private
   * @param {string} propertyTrackId
   * @param {object} newKF
   */
  _addKeyFrame(propertyTrackId, newKF) {
    const propertyStack = this.getById(propertyTrackId)
    if (propertyStack) {
      propertyStack.addKF(newKF)
    }
  }

  /**
   * Delete a keyframe from a property stack
   * @private
   * @param {string} propertyTrackId
   * @param {string} deletedKFId
   */
  _deleteKeyFrame(propertyTrackId, deletedKFId) {
    const propertyStack = this.getById(propertyTrackId)
    if (propertyStack) {
      propertyStack.deleteKF(deletedKFId)
    }
  }

  /**
   * Update a keyframe from a property stack
   * @param {string} propertyTrackId
   * @param {string} keyFrameId
   * @param {object} data
   */
  _updateKeyFrame(propertyTrackId, keyFrameId, data) {
    const propertyStack = this.getById(propertyTrackId)
    if (propertyStack) {
      propertyStack.updateKF(keyFrameId, data)
    }
  }

  /**
   * Update default KF for all ElementStacks
   */
  updateDefaultKF() {
    this.elementsStack.forEach((elementStack) => {
      elementStack.updateDefaultKF();
    });
  }

  /**
   * Update default KF for all ElementStacks
   */
  updateDefaultKFAndInterval() {
    this.elementsStack.forEach((elementStack) => {
      elementStack.updateDefaultKFAndInterval();
    });
  }

  /**
   * Update specific property default keyframe and interval
   * @param {string} elementId
   * @param {string} propKey
   */
  updateSpecificPropertyDefaultKFAndInterval(elementId, propKey) {
    const elementStack = this.elementsStack.get(elementId)
    if (elementStack) {
      elementStack.updateSpecificPropertyDefaultKFAndInterval(propKey)
    }
  }

  updateMaxKFTime() {
    let max = -1
    this.elementsStack.forEach((elementStack) => {
      const elementLastKFTime = elementStack.getMaxKFTime()
      max = Math.max(max, elementLastKFTime)
    })

    this._maxKFTime = max
  }

  getMaxKFTime() {
    return this._maxKFTime
  }

  /**
   * Handle Interaction Manager changes
   * @private
   * @param {object} changes
   */
  _handleIMChange(changes) {
    this.isProcessingChangeEvent = !this.dataStore.inUndoRedo
    let shouldUpdateAnimation = changes.DELETE.size
    // Should maintain track ADD/DELETE with CREATE/DELETE events, and skip children UPDATE.
    this.dataStore.startTransaction()
    this._handleIMDELETE(changes.DELETE)
    this._handleIMCREATE(changes.CREATE)
    shouldUpdateAnimation = this._handleIMUPDATE(changes.UPDATE) || shouldUpdateAnimation

    if (shouldUpdateAnimation) {
      this._updateAnimation()
    }
    this.updateMaxKFTime()
    this.dataStore.endTransaction()

    this.isProcessingChangeEvent = false
  }

  /**
   * Handle Interaction Manager CREATE changes
   * @private
   * @param {Set} CREATE
   */
  _handleIMCREATE(CREATE) {
    const elementTrackIds = new Set()

    for (const instanceId of CREATE) {
      // TODO: Skip belows for MVP
      // 1. Action
      // 2. Response
      // 3. Trigger
      // 4. Condition
      const elementTrack = this.interaction.getElementTrack(instanceId)
      if (elementTrack) {

        // in case drag and duplicate simulation, the elementTrack was created without element
        if (!this.dataStore.hasElement(elementTrack.elementId)) {
            continue
        }
        elementTrackIds.add(instanceId)
        this.initElementStack(elementTrack.elementId, elementTrack)
        continue
      }

      const propertyTrack = this.interaction.getPropertyTrack(instanceId)
      if (
        propertyTrack &&
        !elementTrackIds.has(propertyTrack.elementTrackId) &&
        !this.getById(propertyTrack.id)
      ) {
        const startTime = this.manager.currentElapsedTime - this.manager.currentTime
        if (propertyTrack.key.includes('.')) {
          const keys = propertyTrack.key.split('.')
          const propKey = keys[1]
          this._addChildProperty(propertyTrack.parentId, propertyTrack, propKey, startTime)
        } else {
          const elementTrackId = propertyTrack.elementTrackId
          const elementTrack = this.interaction.getElementTrack(elementTrackId)
          const propertyTrackMap = new Map([[propertyTrack.key, propertyTrack.id]])
          this._addElementProperties(elementTrack.elementId, propertyTrackMap, startTime)
        }
        continue
      }

      // handle adding keyframe here only when it is created together with the new track;
      // in all other cases we need index of KF and this needs to be handled in handleIMUPDATE()
      const keyframe = this.interaction.getKeyFrame(instanceId)
      if (
        keyframe &&
        !this.getById(keyframe.id)
      ) {
        // Only need to get all keyframes of its propertyTrack.
        this._addKeyFrame(keyframe.trackId, keyframe)
        this.updateKeyFrameOrder(keyframe.trackId)
      }
    }
  }

  /**
   * Handle Interaction Manager DELETE changes
   * @private
   * @param {Set} DELETE
   */
  _handleIMDELETE(DELETE) {
    const removedElementStacks = new Set()
    const removedPropertyStacks = new Map()
    const removedKeyframeStacks = new Set()
    const parents = new Set()

    for (const instanceId of DELETE) {
      // TODO: Skip belows for MVP
      // 1. Action
      // 2. Response
      // 3. Trigger
      // 4. Condition
      // FIXME: When we get this changes, the instance is already being removed.
      // So, we need to update this part with cache dataMap
      // And check with its parent to remove itself.

      const stack = this.getById(instanceId)
      const elementStack = this.getElementStackByTrackId(instanceId)
      if (!stack && !elementStack) {
        continue
      }

      // Is ELEMENT
      if (elementStack) {
        removedElementStacks.add(elementStack)
        this._deleteElementStack(elementStack.id)
        parents.add(this.dataStore.getParentOf(elementStack.element))
        continue
      }

      // Is PROPERTY
      if (stack.elementStack) {
        if (DELETE.has(stack.elementStack.trackId)) {
          continue
        }

        if (stack.parentId) {
          if (DELETE.has(stack.parentId)) {
            continue
          }

          const parentStack = this.getById(stack.parentId)
          if (parentStack) {
            removedPropertyStacks.set(parentStack.id, parentStack)
            removedPropertyStacks.set(stack.id, stack)
            parentStack.removeChildren(stack)
            parents.add(this.dataStore.getParentOf(stack.elementStack.element))
          }
        } else {
          removedPropertyStacks.set(stack.id, stack)
          const elementStack = stack.elementStack
          this._deleteElementProperty(elementStack.id, stack.id)
          parents.add(this.dataStore.getParentOf(stack.elementStack.element))
        }
        continue
      }

      // If keyframe's track will be removed, then no need to do update for the keyframe.
      if (DELETE.has(stack.trackId)) {
        continue
      }
      // If is not ELEMENT and not PROPERTY type, that will be KEYFRAME type.
      removedKeyframeStacks.add(stack)
      this._deleteKeyFrame(stack.trackId, stack.id)
      this.updateKeyFrameOrder(stack.trackId)
      const propertyTrack = this.dataStore.interaction.getPropertyTrack(stack.trackId)
      const elementTrack = this.dataStore.interaction.getElementTrack(propertyTrack.elementTrackId)
      const element = this.dataStore.getElement(elementTrack.elementId)
      parents.add(this.dataStore.getParentOf(element))

      this._updateAnimation()
    }

    if (parents.size) {
      parents.forEach((parent) => {
        if (parent && parent.isContainer && parent.isComputedGroup) {
          parent.recalculateBounds()
        }
      })

      parents.clear()
    }

    this._handleRemovePropStates(
      removedElementStacks,
      removedPropertyStacks,
      removedKeyframeStacks
    )
  }

  /**
   * Handle removed stacks prop state change
   * @param {Set<ElementStack>} removedElementStacks
   * @param {Map<string, PropertyStack>} removedPropertyStacks
   * @param {Set<object>} removedKeyframeStacks kf object
   */
  _handleRemovePropStates(removedElementStacks, removedPropertyStacks, removedKeyframeStacks) {
    const kfDiffs = []
    let hasRemoved = false
    if (removedElementStacks.size) {
      hasRemoved = true
      removedElementStacks.forEach((elementStack) => {
        elementStack.resetAllStates()
        this._aggregateKFStatesChange(
          elementStack.id,
          elementStack.getKFDiffs(),
          kfDiffs
        )
        elementStack.clearKFChanges()
      })
      // Once the element stack is deleted, the element style should be updated.
      // Otherwise the screen renderer remains at the last frame.
      this.updateElementComputedStyleByElementIds([...removedElementStacks].map(elStack => elStack.id))
    }
    if (removedPropertyStacks.size) {
      hasRemoved = true
      removedPropertyStacks.forEach((propertyStack) => {
        const { key, clKeyCache, ceKeyCache, parentId, elementStack } = propertyStack
        if (parentId) {
          // Child of a layer
          const parentStack = removedPropertyStacks.get(parentId)
          elementStack.deletePropState(key, parentStack.key, parentStack.clKeyCache)
        } else if (REPEATABLE_PROPERTIES[key]) {
          // Layer
          elementStack.deleteLayerState(key, clKeyCache)
        } else if (EFFECT_PROPERTIES[key]) {
          // Effect
          elementStack.deleteEffectState(key, ceKeyCache)
        } else {
          // Non-Repeatable properties
          elementStack.deletePropState(key)
        }
        this._aggregateKFStatesChange(
          elementStack.id,
          elementStack.getKFDiffs(),
          kfDiffs
        )
        elementStack.clearKFChanges()
      })
    }
    if (removedKeyframeStacks.size) {
      hasRemoved = true
      removedKeyframeStacks.forEach((kf) => {
        const propertyStack = this.getById(kf.trackId)
        const elementStack = propertyStack.elementStack
        this._aggregateKFStatesChange(
          elementStack.id,
          elementStack.getKFDiffs(this.manager.currentElapsedTime),
          kfDiffs
        )
        elementStack.clearKFChanges()
      })
    }
    if (hasRemoved) {
      this.fireKFInfoChange(kfDiffs)
    }
  }

  /**
   * Handle Interaction Manager UPDATE changes
   * @private
   * @param {Map} UPDATE
   * @returns {bool}
   */
  _handleIMUPDATE(UPDATE) {
    // handle UPDATE
    let shouldUpdateAnimation = false
    for (const [instanceId, propChange] of UPDATE) {
      for (const [eventName, change] of propChange) {
        switch (eventName) {
          case 'keyFrameList':
            this._handleKeyFrameListChange(instanceId, change)
            break
          case 'time':
          case 'frameType':
          case 'easingType':
          case 'bezier':
            this._handleKeyFrameDataChange(instanceId, eventName, change)
            break
          case 'value':
          case 'ref':
            this._handleKeyFrameDataChange(instanceId, eventName, change)
            this.updateKeyFrameOrder(instanceId)
            break
          default:
            break
        }

        if (SHOULD_UPDATE_ANIMATION_FOR_KEYFRAME.has(eventName)) {
          shouldUpdateAnimation = true
        }
      }
    }

    return shouldUpdateAnimation
  }

  /**
   * Handle keyframe list map change
   * @param {string} propertyTrackId
   * @param {Change} change
   */
  _handleKeyFrameListChange(propertyTrackId, change) {
    if (change.before.length === change.after.length) {
      this.updateKeyFrameOrder(propertyTrackId)
    }
  }

  /**
   * Handle keyframe data change
   * @param {string} keyFrameId
   * @param {string} propName
   * @param {Change} change
   */
  _handleKeyFrameDataChange(keyFrameId, propName, change) {
    const keyFrame = this.interaction.getKeyFrame(keyFrameId)
    if (!keyFrame) {
      return
    }
    const propertyTrackId = keyFrame.trackId
    const data = { [propName]: change.after }

    this._updateKeyFrame(propertyTrackId, keyFrameId, data)
  }

  _aggregateKFStatesChange(elementId, kfDiffs, out) {
    if (!Array.isArray(out)) {
      console.warn('Missing the initial out array for recording diff result')
      return
    }

    const element = this.dataStore.getById(elementId)
    kfDiffs.forEach((diff) => {
      if (diff.layerName) {
        const cl = element.computedStyle.getComputedLayer(diff.layerName, diff.clKey)
        diff.computedLayer = cl

        if (!this._kfDiffsValid.has(cl)) {
          this._kfDiffsValid.set(cl, new Set())
        }
        const valids = this._kfDiffsValid.get(cl)
        if (valids.has(diff.prop)) {
          return
        }
        valids.add(diff.prop)
      } else if (diff.effectName) {
        const ce = element.computedStyle.getComputedEffectById(diff.ceKey)
        diff.computedEffect = ce
        if (!this._kfDiffsValid.has(ce)) {
          this._kfDiffsValid.set(ce, new Set())
        }
        const valids = this._kfDiffsValid.get(ce)
        if (valids.has(diff.prop)) {
          return
        }
        valids.add(diff.prop)
      } else if (out.find(d => !d.computedLayer && d.prop === diff.prop)) {
        return
      }

      out.push(diff)
    })
  }

  /**
   * Get props state change by time in an elements for specified element stacks.
   * If elements stacks are not specified will get for all element stacks
   * @param {number} time
   * @param {ElementStack[] | Map<string, ElementStack>} [stacks]
   * @returns {Array}
   */
  _getElementsPropsStateChange(time, stacks) {
    const allKFDiffs = []
    const list = stacks ? stacks : this.elementsStack
    list.forEach(elementStack => {
      // Aggregate keyframe state change by key.
      // Each prop only need to fire once.
      this._aggregateKFStatesChange(
        elementStack.id,
        elementStack.getKFDiffs(time),
        allKFDiffs
      )
      elementStack.clearKFChanges()
    })
    return allKFDiffs
  }

  /**
   * Fire event for keyframes states change information
   * @param {Array} kfDiffs
   */
  fireKFInfoChange(kfDiffs) {
    if (kfDiffs.length) {
      this.manager.fire('KFINFO_CHANGES', kfDiffs)
      this._kfDiffsValid.clear()
    }
  }

  /**
   * Get current keyframe state by property
   * @param {string} elementId
   * @param {string} propKey
   * @param {string} layerOrEffectName
   * @param {string} cKey
   * @returns {FrameType}
   */
  getElementKeyframeState(elementId, propKey, layerOrEffectName, cKey) {
    const elementStack = this.elementsStack.get(elementId)
    if (elementStack) {
      return elementStack.getKeyframeState(propKey, layerOrEffectName, cKey)
    }
  }

  /**
   * Get keyframe value by id
   * @param {string} kfId
   * @returns {number|object}
   */
  getKeyframeValue(kfId) {
    const kf = this.getById(kfId)
    const trackStack = this.getById(kf.trackId)
    return trackStack.actions[0].getKFValue(kf)
  }

  /**
   * Get property value by time for specific element
   * @param {string} elementId
   * @param {string} propKey
   * @param {number} time
   * @returns {number|object}
   */
  getPropertyValueByTime(elementId, propKey, time) {
    const elementStack = this.getElementStack(elementId)
    const key = SWITCH_COMPONENT_PROPS[propKey] || propKey
    if (elementStack) {
      const propertyStack = elementStack.getPropertyStackByKey(key)

      if (propertyStack) {
        return propertyStack.getAnimateData(time)
      }

      if (MERGE_STACK_SUB_PROPERTIES[propKey]) {
        return elementStack.getBaseValueByPropKey(propKey)
      }
      return { [propKey]: elementStack.getBaseValueByPropKey(propKey) }
    }

    const element = this.dataStore.getElement(elementId)
    if (!element) {
      return 'Element does not exist'
    }

    const data = {}
    if (NON_REPEATABLE_PROPERTIES[key]) {
      cacheNonRepeatablePropertyBaseValue(data, this.dataStore, element, key)
      if (MERGE_STACK_SUB_PROPERTIES[key]) {
        return data[key]
      }
      return data
    }
    if (REPEATABLE_PROPERTIES[key]) {
      cacheAllRepeatablePropertyBaseValue(data, this.dataStore, element, key)
      return data
    }

    return 'Invalid property'
  }

  getPropertyValue(elementId, propKey) {
    const elementStack = this.getElementStack(elementId)
    const key = SWITCH_COMPONENT_PROPS[propKey] || propKey
    if (elementStack) {
      if (MERGE_STACK_SUB_PROPERTIES[propKey]) {
        return elementStack.getBaseValueByPropKey(propKey)
      }
      return { [propKey]: elementStack.getBaseValueByPropKey(propKey) }
    }

    const element = this.dataStore.getElement(elementId)
    if (!element) {
      return 'Element does not exist'
    }

    const data = {}
    if (NON_REPEATABLE_PROPERTIES[key]) {
      cacheNonRepeatablePropertyBaseValue(data, this.dataStore, element, key)
      if (MERGE_STACK_SUB_PROPERTIES[key]) {
        return data[key]
      }
      return data
    }
    if (REPEATABLE_PROPERTIES[key]) {
      cacheAllRepeatablePropertyBaseValue(data, this.dataStore, element, key)
      return data
    }

    return 'Invalid property'
  }

  getPropertyTrackValueByTime(elementId, propertyTrackKey, time) {
    const elementStack = this.getElementStack(elementId)

    if (elementStack) {
      const { stack: propertyStack, propKey, propIdentifier } = elementStack.getPropertyStackByTrackKey(propertyTrackKey)

      if (propertyStack) {
        if (DELTA_PROP_KEY_SET.has(propIdentifier)) {
          const dataKey = SWITCH_COMPONENT_PROPS[propIdentifier]
          return propertyStack.getAnimateData(time)[dataKey] - elementStack.getBaseValueByPropKey(dataKey)
        }

        if (propIdentifier === 'contentAnchor') {
          const { contentAnchorX, contentAnchorY } = propertyStack.getAnimateData(time)
          const { contentAnchorX: baseContentAnchorX, contentAnchorY: baseContentAnchorY } = elementStack.getBaseValueByPropKey(propIdentifier)

          return { contentAnchorX: contentAnchorX - baseContentAnchorX, contentAnchorY: contentAnchorY - baseContentAnchorY }
        }

        // layer stack
        if (isId(propIdentifier, IDType.INTERACTION_MANAGER_COMPONENT)) {
          return propertyStack.getComputedData(time)[propKey]
        }

        return propertyStack.getAnimateData(time)[propIdentifier]
      }
    }

    return 'Invalid property'
  }

  getPropertyWorkingInterval(elementId, propKey, time) {
    const workingTime = time ?? this.manager.currentTime
    const elementStack = this.getElementStack(elementId)
    if (!elementStack || !elementStack[propKey]) return null

    return elementStack[propKey].getWorkingInterval(workingTime)
  }

  /**
   * Update animation for current frame
   * @private
   */
  _updateAnimation() {
    if (this.dataStore.isActionMode) {
      this.updateElementData(this.manager.currentElapsedTime)
    }
  }

  /**
   * Clear all caches
   */
  clearAll() {
    this.clearElementsStack();
  }

  _debug() {
    const result = {}
    this.elementsStack.forEach((elementStack) => {
      result[elementStack.id] = elementStack._debug()
    })

    console.log(result)
  }
}

export default Cache;
