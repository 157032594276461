import { VideoQuality } from '@phase-software/types'
import { Color } from '../../math/Color'
import IS from '../../input-system'
import { takeSnapshot } from '../../utils/snapshot'
import { pauseRenderer, resumeRenderer } from '../../status'
import { waitFrame } from './utils'

/** @typedef {import('@phase-software/data-store/src/DataStore').DataStore} DataStore */
/** @typedef {import('../../visual_server/VisualServer').VisualServer} VisualServer */

const GIF_TRANSPARENT_COLOR = 0x00b140 // use green screen color as the transparent color

export default class Recorder {
    /**
     * @param {VisualServer} visualServer
     * @param {DataStore} dataStore
     */
    constructor(visualServer, dataStore) {
        this.visualServer = visualServer
        this.dataStore = dataStore

        this._encoder = null
        this.init()
    }

    init() {
        this.onProgress = () => { }
        this.onComplete = () => { }

        this._rafId = null
        this._isEnabled = false
        this._ctx = null
        /** @type {Uint8ClampedArray} */
        this._imageData = null
        this._imageSize = { width: 0, height: 0 }
        this.type = null

        this.settings = {
            startTime: 0,
            currTime: 0,
            endTime: 0,
            iterTime: 0,
            fps: 30,
            loop: true,
            speed: 1,
            quality: VideoQuality.HIGH,
            transparent: false,
            gifTransparentColor: NaN
        }

        this.exportFile = null
    }

    /**
     * Set media encoder to recorder
     * @param {Encoder} encoder
     */
    setEncoder(encoder) {
        this._encoder = encoder
    }

    get width() {
        return this._imageSize.width
    }

    get height() {
        return this._imageSize.height
    }

    /**
     * Enable to record media
     */
    enable() {
        this._isEnabled = true
    }

    /**
     * Disable to record media
     */
    disable() {
        this._isEnabled = false
        this.end()
        resumeRenderer()
    }

    /**
     * Set media type
     * @param {MediaType} type
     */
    setMediaType(type) {
        this.type = type
    }

    /**
     * Set output media viewport size
     * @param {number} width
     * @param {number} height
     */
    setOutputSize(width, height) {
        this._imageSize.width = width
        this._imageSize.height = height
    }

    /**
     * Update recorder settings
     * @param {object} newSettings
     */
    updateSetting(newSettings) {
        this.settings = { ...this.settings, ...newSettings }
        this.settings.currTime = this.settings.startTime
    }

    /**
     * Record canvas for exporting video
     * @param {Function} onProgress
     * @param {Function} onComplete
     */
    async start(onProgress, onComplete) {
        this.onProgress = onProgress
        this.onComplete = onComplete
        pauseRenderer()
        await this.processNextFrame()
        resumeRenderer()
    }

    /**
     * Render next frame
     * @returns {Promise}
     */
    processNextFrame() {
        return new Promise((resolve) => {
            const render = async () => {
                await this.frameProcessingLoop(resolve)
            }
            this._rafId = window.requestAnimationFrame(render)
        })
    }

    /**
     * Render loop function for each frame
     * @param {Promise.resolve} resolve
     */
    async frameProcessingLoop(resolve) {
        if (!this._isEnabled) {
            this._encoder.cancel()
            // resume current render loop
            this.end()
            resolve()
            return
        }
        await this._captureScreen(this.settings.currTime)
        this._encoder.addFrame(this._imageData, {
            width: this._imageSize.width,
            height: this._imageSize.height,
            ...this.settings
        })
        if (this.settings.currTime < this.settings.endTime) {
            this.settings.currTime += this.settings.iterTime

            let progress = (this.settings.currTime - this.settings.startTime) / (this.settings.endTime - this.settings.startTime) * 100
            // Ensures progress does not exceed 100%
            progress = Math.min(progress, 100)

            this.onProgress(progress)
            await this.processNextFrame()
        } else {
            this._encoder.finalize()
            this.onComplete(this._encoder.getData())
            this._encoder.end()
            this.end()
        }
        resolve()
    }

    /**
     * Capture screen
     * @param {number} t
     */
    async _captureScreen(t) {
        const DS = this.dataStore
        const VS = this.visualServer

        // seek
        DS.transition.setPlayheadTime(t, true, false)

        await waitFrame()
        {
            const screen = DS.workspace.children[0]
            const screenFillLayers = VS.getRenderItemOfElement(screen).fillLayers

            // setup background color
            const noFillColors = screenFillLayers.every(layer => layer.paint.params.opacity < 0.01)
            const clearColor = new Color(1, 1, 1, 1)
            if (this.settings.transparent && noFillColors) {
                this.settings.gifTransparentColor = GIF_TRANSPARENT_COLOR
                // set the background color to the GIF transparent color to achieve transparency
                clearColor.set_with_hex(this.settings.gifTransparentColor)
            }

            // draw screen
            this._imageData = takeSnapshot(screen.get("id"), clearColor)
            VS.commit()
        }
    }

    /**
     * End record canvas
     */
    end() {
        IS.resume()
        this.dataStore.transition.resume()
        // Force update properties status for Design Mode
        this.dataStore.editor.reloadPropertiesAndStates(new Map([['mode', '']]))
        window.cancelAnimationFrame(this._rafId)
    }

    clear() {
        this.init()
    }
}
