import { EventFlag } from '@phase-software/types'
import ElementKFStates from '../helpers/ElementKFStates'
import {
  cacheNonRepeatablePropertyBaseValue,
  cacheAllRepeatablePropertyBaseValue,
  cacheRepeatablePropertyBaseValue,
  cacheAllNonBaseLayerBaseValue,
  cacheEffectPropertyBaseValue,
  cacheCustomPropertyBaseValue
} from '../utils/data'
import {
  NON_REPEATABLE_PROPERTIES,
  NON_REPEATABLE_PROPERTIES_KEYS,
  REPEATABLE_PROPERTIES,
  REPEATABLE_PROPERTIES_KEYS,
  EFFECT_PROPERTIES,
  EFFECT_PROPERTIES_KEYS,
  CUSTOM_PROPERTIES,
  CUSTOM_PROPERTIES_KEYS,
  PROP_CLASSES
} from './instance'

/** @typedef {import('@phase-software/data-store/src/DataStore').DataStore} DataStore */
/** @typedef {import('../index').default} TransitionManager */

class ElementStack {
  /**
   * @param {TransitionManager} manager
   * @param {object} data
   */
  constructor(manager, data) {
    /** @type {TransitionManager} */
    this.manager = manager
    this.id = data.elementId
    this.trackId = data.id
    this.init()
    this.load(data)
  }

  /** @returns {DataStore} */
  get dataStore() {
    return this.manager.dataStore
  }

  /** @returns {Interaction} */
  get interaction() {
    return this.manager.interaction
  }

  get element() {
    return this.dataStore.getById(this.id)
  }

  /**
   * Initial Node stack
   */
  init() {
    this.cacheBaseValue()
    this._kfStates = new ElementKFStates(this)
    this._maxKFTime = -1 // -1 means there is no kf in ElementStack
    this.propertyStacksMap = new Map()
    for (const propKey of NON_REPEATABLE_PROPERTIES_KEYS) {
      this[propKey] = undefined
    }
    for (const layerName of REPEATABLE_PROPERTIES_KEYS) {
      this[layerName] = []
    }
    this.effects = new Map()
    for (const propKey of CUSTOM_PROPERTIES_KEYS) {
      this[propKey] = undefined
    }
  }

  load(data) {
    data.propertyTrackMap.forEach((propertyTrackId) => {
      const propertyTrack = this.interaction.getPropertyTrack(propertyTrackId)
      if (propertyTrack) {
        this.addProperties(new Map([[propertyTrack.key, propertyTrack.id]]))
      }
    })
    // Init all prop states after create all caches
    this._kfStates.init()
  }

  /**
   * Cache non-repeatable property base value with one property
   * @param {string} propKey
   */
  cacheNonRepeatablePropertyBaseValue(propKey) {
    cacheNonRepeatablePropertyBaseValue(this._base, this.dataStore, this.element, propKey)
  }

  /**
   * Cache base value for all properties
   */
  cacheBaseValue() {
    this._base = { effects: {} }
    NON_REPEATABLE_PROPERTIES_KEYS.forEach((propKey) => {
      cacheNonRepeatablePropertyBaseValue(this._base, this.dataStore, this.element, propKey)
    })
    REPEATABLE_PROPERTIES_KEYS.forEach((layerKey) => {
      cacheAllRepeatablePropertyBaseValue(this._base, this.dataStore, this.element, layerKey)
      cacheAllNonBaseLayerBaseValue(this._base, this[layerKey])
    })
    EFFECT_PROPERTIES_KEYS.forEach((effectType) => {
      cacheEffectPropertyBaseValue(this._base, this.dataStore, this.element, effectType)
    })
    CUSTOM_PROPERTIES_KEYS.forEach((propKey) => {
      cacheCustomPropertyBaseValue(this._base, this.dataStore, this.element, propKey)
    })
  }

  cachePropertyBaseValueByCategory(propKey) {
    if (propKey in NON_REPEATABLE_PROPERTIES) {
      cacheNonRepeatablePropertyBaseValue(this._base, this.dataStore, this.element, propKey)
    } else if (propKey in REPEATABLE_PROPERTIES) {
      cacheAllRepeatablePropertyBaseValue(this._base, this.dataStore, this.element, propKey)
    } else if (propKey in EFFECT_PROPERTIES) {
      cacheEffectPropertyBaseValue(this._base, this.dataStore, this.element, propKey)
    } else if (propKey in CUSTOM_PROPERTIES) {
      cacheCustomPropertyBaseValue(this._base, this.dataStore, this.element, propKey)
    }
  }

  cacheSingleNonBaseLayerBaseValue(layerKey, layerId, layerData) {
    cacheRepeatablePropertyBaseValue(this._base, layerKey, layerId, layerData)
  }

  /**
   * Get base value for one property by propKey
   * @param {string} propKey
   * @returns {number|object}
   */
  getBaseValueByPropKey(propKey) {
    return this._base[propKey]
  }

  /**
   * Get keyframe states for all properties by time
   * @param {number} time
   * @returns {Array}
   */
  getKFDiffs(time) {
    if (time !== undefined) {
      this._kfStates.checkChanges(time)
    }
    return this._kfStates.getChanges()
  }

  /**
   * Reset all props state
   */
  resetAllStates() {
    this._kfStates.resetAll()
  }

  /**
   * Clear cached keyframe state changes
   */
  clearKFChanges() {
    this._kfStates.clearChanges()
  }

  /**
   * Get current keyframe state by property
   * @param {string} propKey
   * @param {string} layerOrEffectName
   * @param {string} cKey
   * @returns {FrameType}
   */
  getKeyframeState(propKey, layerOrEffectName, cKey) {
    return this._kfStates.getPropState(propKey, layerOrEffectName, cKey)
  }

  /**
   * Add propertyStack to map
   * @param {PropertyStack} propertyStack
   */
  addPropertyStackToMap(propertyStack) {
    this.propertyStacksMap.set(propertyStack.id, propertyStack)
    this.manager.cache.addToMap(propertyStack)
  }

  /**
   * Delete propertyStack from map
   * @param {string} propertyStackId
   */
  deletePropertyStackFromMap(propertyStackId) {
    this.propertyStacksMap.delete(propertyStackId)
    this.manager.cache.deleteFromMap(propertyStackId)
  }

  /**
   * Remove property stack and reset the property value
   * @param {PropertyStack} propertyStack
   */
  deletePropertyStack(propertyStack) {
    this.resetComputedData(propertyStack)
    this.deletePropertyStackFromMap(propertyStack.id)
  }

  /**
   * Get propertyStack by id
   * @param {string} id
   * @returns {PropertyStack}
   */
  getPropertyStack(id) {
    return this.propertyStacksMap.get(id)
  }

  /**
   * Get propertyStack by property key
   * @param {string} propKey
   * @returns {PropertyStack}
   */
  getPropertyStackByKey(propKey) {
    return this[propKey]
  }

  getPropertyStackByTrackKey(trackKey) {
    const [propIdentifier, propKey] = trackKey.split('.');

    if (trackKey.includes('.')) {
      if (propIdentifier in EFFECT_PROPERTIES) {
        return { propIdentifier, propKey, stack: this.effects.get(propIdentifier).children.get(propKey) }
      }

      if (this.propertyStacksMap.get(propIdentifier)) {
        return { propIdentifier, propKey, stack: this.propertyStacksMap.get(propIdentifier) }
      }
    }

    return { propIdentifier, propKey, stack: this[propIdentifier] }
  }

  getMaxKFTime() {
    return this._maxKFTime
  }

  updateMaxKFTime(time) {
    this._maxKFTime = Math.max(this._maxKFTime, time)
  }

  /**
   * Get property value from base
   * @param {PropertyStack} propertyStack
   * @returns {*}
   */
  getBaseValue(propertyStack) {
    if (propertyStack.key in REPEATABLE_PROPERTIES) {
      return this._base[propertyStack.key][propertyStack.clId]
    } else if (propertyStack.key in NON_REPEATABLE_PROPERTIES) {
      return this._base[propertyStack.dataKey]
    } else if (propertyStack.key in EFFECT_PROPERTIES) {
      return this._base.effects[propertyStack.key]
    } else if (propertyStack.key in CUSTOM_PROPERTIES) {
      return this._base[propertyStack.key]
    }
  }

  /**
   * Resets computed data for the property to base value
   * @param {PropertyStack} propertyStack
   */
  resetComputedData(propertyStack) {
    const propKey = propertyStack.key

    // In Transition Manager cache stack, only detail properties of a layer stack can have parentId.
    // For non-repeatable properties stacks, they won't have parentId.
    // The parentId here means the id of its parent property stack.
    if (propKey in NON_REPEATABLE_PROPERTIES && !propertyStack.parentId) {
      // If is x, y, width, height, opacity of element, or rotation stack,
      // they don't have parentId.
      let changes = {}
      // FIXME: (motion-path)
      if (propKey === 'motionPath') {
        const baseValue = propertyStack.getBaseValue()
        changes = { translateX: baseValue.pos[0],  translateY: baseValue.pos[1], orientRotation: 0 }
      } else if (propKey === 'contentAnchor') {
        changes = propertyStack.getBaseValue()
      } else {
        changes = { [propertyStack.dataKey]: propertyStack.getBaseValue() }
      }

      this.element.computedStyle.sets(changes, { undoable: false, flags: 1 })
    } else if (propKey in REPEATABLE_PROPERTIES) {
      // If is fill, stroke, shadow or innerShadow stack.
      propertyStack.resetComputedLayer()
    } else if (propKey in CUSTOM_PROPERTIES) {
      propertyStack.resetComputedData()
    } else {
      const parentStack = this.manager.cache.getById(propertyStack.parentId)
      if (parentStack) {
        // If is opacity of layer, paint stack or some other detail properties stacks.
        parentStack.resetComputedData(propKey)
      }
    }
  }

  /**
   * Reset computed data for all properties to base value
   */
  resetAllComputedData() {
    const element = this.element
    const nonRepeatablePropertiesData = {}
    let count = 0
    for (const propKey of NON_REPEATABLE_PROPERTIES_KEYS) {
      const propertyStack = this[propKey]
      if (propertyStack) {
        // FIXME: (motion-path)
        if (propKey === 'motionPath') {
            const baseValue = propertyStack.getBaseValue()
            nonRepeatablePropertiesData.translateX = baseValue.pos[0]
            nonRepeatablePropertiesData.translateY = baseValue.pos[1]
            nonRepeatablePropertiesData.orientRotation = 0
            count++
        } else if (propertyStack.basePropKeys.length) {
          const baseValue = propertyStack.getBaseValue()
          propertyStack.basePropKeys.forEach((key) => {
            nonRepeatablePropertiesData[key] = baseValue[key]
            count++
          })
        } else {
          nonRepeatablePropertiesData[propertyStack.dataKey] = propertyStack.getBaseValue()
          count++
        }
      }
    }

    if (count) {
      element.computedStyle.sets(nonRepeatablePropertiesData, { undoable: false, flags: 1 })
    }

    for (const propKey of REPEATABLE_PROPERTIES_KEYS) {
      for (const layerStack of this[propKey]) {
        if (layerStack.isBaseLayer()) {
          layerStack.resetComputedLayer()
        } else {
          element.computedStyle.deleteNonBaseComputedLayer(propKey, layerStack.id)
        }
      }
    }

    this.effects.forEach((effectStack) => {
      effectStack.resetComputedEffect()
    })

    for (const propKey of CUSTOM_PROPERTIES_KEYS) {
      if (this[propKey]) {
        this[propKey].resetComputedData()
      }
    }
  }

  /**
   * Get computed non-repeatable properties data by time
   * @param {number} time
   * @returns {object}
   */
  getComputedNonRepeatablePropertiesData(time) {
    // TODO:
    //  consider optimizing this method

    // Aggregate all non-repeatable properties and set to ComputedStyle at once
    const nonRepeatablePropertiesData = {}
    let count = 0
    for (const propKey of NON_REPEATABLE_PROPERTIES_KEYS) {
      if (!this[propKey]) {
        continue
      }

      const animateData = this[propKey].getAnimateData(time)
      if (animateData === null || animateData === undefined) {
        continue
      }

      for (const key in animateData) {
        nonRepeatablePropertiesData[key] = animateData[key]
        count++
      }
    }

    return count ? nonRepeatablePropertiesData : null
  }

  /**
   * Update Element transition properties
   * @param {number} time
   */
  updateComputedData(time) {
    const nonRepeatablePropertiesData = this.getComputedNonRepeatablePropertiesData(time)
    if (nonRepeatablePropertiesData) {
      this.element.computedStyle.sets(
        nonRepeatablePropertiesData,
        { undoable: false, flags: EventFlag.FROM_ANIMATION }
      )
    }

    // Repeatable properties need to set to its ComputedLayer data one by one
    for (const listName of REPEATABLE_PROPERTIES_KEYS) {
      for (const layerStack of this[listName]) {
        layerStack.updateComputedData(time)
      }
    }

    this.effects.forEach((effectStack) => {
      effectStack.updateComputedData(time)
    })

    for (const propKey of CUSTOM_PROPERTIES_KEYS) {
      if (this[propKey]) {
        this[propKey].updateComputedData(time)
      }
    }
  }

  /**
   * Add properties by propertyTracks
   * @param {Map} propertyTrackMap
   * @param {number} [startTime=0]
   */
  addProperties(propertyTrackMap, startTime = 0) {
    propertyTrackMap.forEach((propertyTrackId, propKey) => {
      // No need to create stack if is already exist
      if (this.propertyStacksMap.has(propertyTrackId)) {
        return
      }

      const propertyTrack = this.interaction.getPropertyTrack(propertyTrackId)
      if (NON_REPEATABLE_PROPERTIES[propKey] && !propertyTrack.key.includes('.') || CUSTOM_PROPERTIES[propKey]) {
        this.addUniqueProperty(propertyTrack, startTime)
      } else if (EFFECT_PROPERTIES[propKey]) {
        // Should cache effect base value when create effect stack
        cacheEffectPropertyBaseValue(this._base, this.dataStore, this.element, propKey)
        this.addEffectProperty(propertyTrack.key, propertyTrack, propertyTrackMap, startTime)
      } else {
        // LayerComponent Track does not have parentId.
        // Transition Manager also no need to take care about LayerComponent Track.
        if (!propertyTrack.parentId) {
          return
        }

        const parentTrack = this.interaction.getPropertyTrack(propertyTrack.parentId)
        if (REPEATABLE_PROPERTIES[parentTrack.key]) {
          this.addRepeatableProperty(parentTrack.key, propertyTrack, propertyTrackMap, startTime)
        }
      }
    })
  }

  /**
   * Add a non-repeatable propertyStack and custom propertyStack
   * @param {object} propertyTrack
   * @param {number} startTime
   */
  addUniqueProperty(propertyTrack, startTime) {
    const propKey = propertyTrack.key
    let propertyStack = this[propKey]
    if (propertyStack) {
      propertyStack.addAction(propertyTrack.keyFrameList, startTime)
    } else {
      propertyStack = this._addProperty(propertyTrack, null, startTime)
      this[propertyTrack.key] = propertyStack
      this.addPropertyStackToMap(propertyStack)
    }
  }

  /**
   * Add a repeatable propertyStack
   * @param {string} listName
   * @param {object} propertyTrack
   * @param {Map} propertyTrackMap
   * @param {number} startTime
   */
  addRepeatableProperty(listName, propertyTrack, propertyTrackMap, startTime) {
    const elementTrack = this.interaction.getElementTrack(propertyTrack.elementTrackId)
    const children = propertyTrack.children
    let current = this[listName].find((ps) => ps.id === propertyTrack.id)
    const addNewLayer = !current

    if (current) {
      children.forEach((childKey) => {
        const childId = elementTrack.propertyTrackMap.get(childKey)
        const childTrack = this.interaction.getPropertyTrack(childId)

        const childStack = current.children.get(childKey)
        if (childStack) {
          childStack.addAction(childTrack.keyFrameList, startTime)
        } else {
          const newChildStack = this._addProperty(childTrack, propertyTrack.id)
          current.addChildren(newChildStack)
          newChildStack.init()
          this.addPropertyStackToMap(newChildStack)
        }
      })
    } else {
      current = this._addProperty(propertyTrack, null, startTime, listName)
      this.addPropertyStackToMap(current)
      this[listName].push(current)
    }

    this._createComputedLayer(listName, propertyTrack)

    if (addNewLayer) {
      cacheRepeatablePropertyBaseValue(this._base, listName, current.id, current.getInitValue())
      children.forEach((childKey) => {
        const childId = elementTrack.propertyTrackMap.get(childKey)
        const childTrack = this.interaction.getPropertyTrack(childId)
        const childStack = this._addProperty(childTrack, propertyTrack.id, startTime)
        current.addChildren(childStack)
        childStack.init()
        this.addPropertyStackToMap(childStack)
      })
    }
  }

  createNonBaseComputedLayers() {
    for (const listName of REPEATABLE_PROPERTIES_KEYS) {
      for (const layerStack of this[listName]) {
        const propertyTrack = this.interaction.getPropertyTrack(layerStack.id)
        if (propertyTrack) {
          this._createComputedLayer(listName, propertyTrack)
        }
      }
    }
  }

  /**
   * Add non-base ComputedLayer in ComputedStyle
   * @private
   * @param {string} listName
   * @param {{key: string, id: string} | PropertyStack} propertyTrack
   */
  _createComputedLayer(listName, propertyTrack) {
    const element = this.element
    const layerComponentId = element.base[listName][0]
    const layerComponent = this.dataStore.library.getComponent(layerComponentId)
    const layers = layerComponent.layers
    const computedStyle = element.computedStyle
    const isBaseLayer = layers.includes(propertyTrack.key)

    // Check if the index is larger than the count of base layers
    if (isBaseLayer) {
      computedStyle.addBaseComputedLayer(listName, propertyTrack.key)
    } else {
      const idx = [...element.computedStyle[listName]].findIndex((cl) => propertyTrack.id === cl.get('id'))
      computedStyle.addNonBaseComputedLayer(listName, propertyTrack.id, idx)
    }
  }

  /**
   * Add a repeatable propertyStack
   * @param {string} effectName
   * @param {object} parentTrack
   * @param {Map} propertyTrackMap
   * @param {number} startTime
   */
  addEffectProperty(effectName, parentTrack, propertyTrackMap, startTime) {
    let parentStack = this.effects.get(effectName)
    if (!parentStack) {
      parentStack = this._addProperty(parentTrack, null, startTime)
      this.effects.set(effectName, parentStack)
      this.addPropertyStackToMap(parentStack)
    }

    for (const [propKey, propertyTrackId] of propertyTrackMap) {
      if (!EFFECT_PROPERTIES[propKey]) {
        continue
      }

      const propertyTrack = this.interaction.getPropertyTrack(propertyTrackId)
      const allPropertyTrackMap = this.dataStore.interaction.getPropertyTrackMap(propertyTrack.elementTrackId)
      propertyTrack.children.forEach((childKey) => {
        const key = childKey.split('.')[1]
        const childTrackId = allPropertyTrackMap.get(childKey)
        const childTrack = this.dataStore.interaction.getPropertyTrack(childTrackId)
        let propertyStack = parentStack.children.get(key)
        if (propertyStack) {
          propertyStack.addAction(childTrack.keyFrameList, startTime)
        } else {
          propertyStack = this._addProperty(childTrack, parentStack.id, startTime)
          parentStack.addChildren(propertyStack)
          this.addPropertyStackToMap(propertyStack)
        }
      })
    }
  }

  /**
   * Add a new propertyStack
   * @param {object} propertyTrack
   * @param {string} parentId
   * @param {number} startTime
   * @param {string} listName
   * @returns {PropertyStack}
   */
  _addProperty(propertyTrack, parentId, startTime, listName) {
    const id = propertyTrack.id
    const keys = propertyTrack.key.split('.')
    const key = keys.pop()
    const propKey = listName || key
    const propClass = PROP_CLASSES[propKey]
    if (!propClass) {
      return null
    }

    // Only LayerStack will use computedLayerId
    const computedLayerId = propertyTrack.id === propertyTrack.key
      ? propertyTrack.id
      : propertyTrack.key
    const kfs = propertyTrack.keyFrameList
    const propertyStack = new propClass(
      this,
      {
        id,
        parentId,
        kfs,
        startTime,
        propKey,
        propertyTrack,
        computedLayerId
      }
    )
    propertyStack.init()

    return propertyStack
  }

  /**
   * Delete property stack
   * @param {string} propertyTrackId
   */
  deleteProperty(propertyTrackId) {
    const propertyStack = this.propertyStacksMap.get(propertyTrackId)
    if (!propertyStack) {
      return
    }

    const propKey = propertyStack.key
    if ((NON_REPEATABLE_PROPERTIES[propKey] && !propertyStack.parentId) || CUSTOM_PROPERTIES[propKey]) {
      // Delete non-repeatable or custom property
      if (propertyStack) {
        propertyStack.clear()
        this[propKey] = undefined
        this.deletePropertyStack(propertyStack)
      }
    } else if (REPEATABLE_PROPERTIES[propKey]) {
      // Delete repeatable property
      const propertyStackIdx = this[propKey].findIndex((ps) => ps.id === propertyTrackId)
      if (propertyStackIdx > -1) {
        propertyStack.children.forEach((child) => {
          this.deleteProperty(child.id)
        })

        propertyStack.clear()
        this[propKey].splice(propertyStackIdx, 1)
        this.deletePropertyStack(propertyStack)

        // Remove the non-base computedLayer
        if (!propertyStack.isBaseLayer()) {
          this.element.computedStyle.deleteNonBaseComputedLayer(propKey, propertyTrackId)
        }
      }
    } else if (EFFECT_PROPERTIES[propKey]) {
      propertyStack.children.forEach((childStack) => {
        propertyStack.removeChildren(childStack)
      })
      this.deletePropertyStack(propertyStack)
      this.effects.delete(propertyStack.key)
    } else {
      // Delete child property under any property
      const parentStack = this.getPropertyStack(propertyStack.parentId)
      if (parentStack) {
        parentStack.removeChildren(propertyStack)
      }
      this.deletePropertyStack(propertyStack)
    }
  }

  /**
   * Update default KF for all PropertyStacks
   */
  updateDefaultKF() {
    this.propertyStacksMap.forEach((propertyStack) => {
      propertyStack.updateDefaultKF()
    })
  }

  /**
   * Update default KF for all PropertyStacks
   */
  updateDefaultKFAndInterval() {
    this.propertyStacksMap.forEach((propertyStack) => {
      propertyStack.updateDefaultKFAndInterval()
    })
  }

  /**
   * Update specific property default keyframe and interval
   * @param {string} propKey
   */
  updateSpecificPropertyDefaultKFAndInterval(propKey) {
    const propertyStack = this.getPropertyStack(propKey)
    if (propertyStack) {
      propertyStack.updateDefaultKFAndInterval()
    }
  }

  /**
   * Reset start time
   * @param {number} time
   */
  resetStartTime(time) {
    this.propertyStacksMap.forEach((propertyStack) => {
      propertyStack.resetStartTime(time)
    })
  }

  /**
   * Clear the ElementStack and all its children
   */
  clear() {
    this.propertyStacksMap.forEach((propertyStack) => {
      propertyStack.clear()
      this.deletePropertyStackFromMap(propertyStack.id)
    })
  }

  /**
   * Delete single prop state
   * @param {string} propKey
   * @param {string} layerOrEffectName
   * @param {string} cKey
   */
  deletePropState(propKey, layerOrEffectName, cKey) {
    this._kfStates.deletePropState(propKey, layerOrEffectName, cKey)
  }

  /**
   * Delete layer state
   * @param {string} listName
   * @param {string} clKey
   */
  deleteLayerState(listName, clKey) {
    this._kfStates.deleteLayerState(listName, clKey)
  }

  /**
   * Delete effect state
   * @param {string} effectName
   * @param {string} ceKey
   */
  deleteEffectState(effectName, ceKey) {
    this._kfStates.deleteEffectState(effectName, ceKey)
  }

  _debug() {
    const result = {}
    NON_REPEATABLE_PROPERTIES_KEYS.forEach((propKey) => {
      const propStack = this[propKey]
      if (propStack) {
        result[propKey] = propStack.actions[0]._interval
      }
    })
    REPEATABLE_PROPERTIES_KEYS.forEach((layerName) => {
      if (this[layerName].length) {
        result[layerName] = []
        this[layerName].forEach((layerStack) => {
          const layerResult = {}
          layerStack.children.forEach((childStack) => {
            layerResult[childStack.key] = childStack.actions[0]._interval
          })
          result[layerName].push(layerResult)
        })
      }
    })
    if (this.effects.size) {
      result.effects = {}
      this.effects.forEach((effectStack) => {
        result.effects[effectStack.key] = {}
        effectStack.children.forEach((childStack) => {
          result.effects[effectStack.key][childStack.key] = childStack.actions[0]._interval
        })
      })
    }
    CUSTOM_PROPERTIES_KEYS.forEach((propKey) => {
      const propStack = this[propKey]
      if (propStack) {
        result[propKey] = propStack.actions[0]._interval
      }
    })
    result.states = this._kfStates.debug()

    return result
  }
}

export default ElementStack;
