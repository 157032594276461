import { debounce, first, flow, groupBy, keys, mapValues, values } from 'lodash'
import {
    Mode,
    EditMode,
    EntityType,
    ElementType,
    ToolType,
    GeneralToolType,
    BooleanOperation,
    GeometryType,
    PaintType,
    ContainerElementType,
    CreateElementToolType
} from '@phase-software/types'
import TransitionManager from '@phase-software/transition-manager';
import {
    setAdd,
    AABB,
    NO_COMMIT,
    NOT_UNDOABLE,
    NO_FIRE,
    Stats
} from '@phase-software/data-utils'
import { ImageManager } from '../dist'
import { MeshManager } from '../index'
import EAM from './Eam'
import { Setter } from './Setter'
import { Selection } from './Selection'
import Clipboard from './Clipboard'
import { Editor } from './Editor'
import Library from './Library'
import { Workspace } from './Workspace'
import { Screen } from './Screen'
import { ElementNameCounter } from './ElementNameCounter'
import Undo from './Undo'
import { Watcher } from './Watcher'
import { FontHelper } from './media/FontHelper'
import InteractionManager from './interaction/Manager'
import ImageExport from './export/ImageExport'
/**
 * TODO: Should import from MeshManager directly after we convert all files to TS.
 */
import { createElement, createNewElement, canBeChildOf } from './utils'
import { ARROW_KEY_DEBOUNCE_TIME } from './constant';

/** @typedef {import('@phase-software/renderer/src/DrawInfo').DrawInfo} DrawInfo */
/** @typedef {import('./Element').Element} Element */
/** @typedef {import('./Element').ElementType} ElementType */
/** @typedef {import('./interaction/Manager').ActionData} InteractionData */
/** @typedef {import('./component/PropertyComponent').PropertyComponentData} PropertyComponentData */


const UNDO_EVENTS = ['ADD-ACTION', 'REMOVE-ACTION', 'WORKSPACE_LIST_CHANGES', 'WORKSPACE_CHANGE']
const UNDO_CHANGES = ['editMode', 'mode', 'features']

const boundsBuf = new AABB()

const TRANSACTION_MAP = {
    SELECTION: null
}

const BOOLEAN_NAME = {
    [BooleanOperation.UNION]: 'Union',
    [BooleanOperation.SUBTRACT]: 'Subtract',
    [BooleanOperation.INTERSECT]: 'Intersect',
    [BooleanOperation.DIFFERENCE]: 'Difference',
}

/**
 * @property {Workspace[]} [ADD]          Workspaces that were added to DataStore
 * @property {Set<string>} [REMOVE]       Workspaces that were removed from DataStore
 * @property {number[]} [REORDER]        tuple [fromIndex, toIndex] describing how Workspaces were reordered
 */
export class WorkspaceListChanges {

    /**
     * @param {Workspace} workspace
     * @returns {WorkspaceListChanges} itself
     */
    add(workspace) {
        if (!this.ADD) {
            this.ADD = []
        }
        this.ADD.push(workspace)
        return this
    }

    /**
     * @param {Workspace} workspace
     * @param {number} index
     * @returns {WorkspaceListChanges} itself
     */
    remove(workspace, index) {
        if (!this.REMOVE) {
            this.REMOVE = []
        }
        this.REMOVE.push({
            workspace,
            index
        })
        return this
    }

    /**
     * @param  {number} fromIndex
     * @param  {number} toIndex
     * @returns {WorkspaceListChanges} itself
     */
    reorder(fromIndex, toIndex) {
        this.REORDER = [fromIndex, toIndex]
        return this
    }
}

/**
 * Data Store
 * @fires 'LOAD-START'
 * @fires 'LOAD'
 * @fires 'FONT-LIST-LOAD'
 * @fires 'WORKSPACE_LIST_CHANGES'
 * @fires 'ADD-ACTION'
 * @fires 'REMOVE-ACTION'
 */
export class DataStore extends Setter {
    static version = '1.5.0'

    /**
     * @param {DataStoreData} [data]
     */
    constructor(data) {
        super(null, data)
        this.dataStore = this
        this.version = DataStore.version

        setAdd(this.undoEvents, UNDO_EVENTS)
        setAdd(this.undoChanges, UNDO_CHANGES)

        this.isLoaded = false
        this.on('LOAD-START', () => {
            this.isLoaded = false
        })
        this.on('LOAD', () => {
            this.isLoaded = true
        })

        this.addUndoFn = this.addUndo.bind(this)
        this._transactionCount = 0
        // TODO: Do we need different delay of commit?
        this._debounceCommitUndo = debounce(() => { this.commitUndo() }, ARROW_KEY_DEBOUNCE_TIME)
    }

    /**
     * find an element by id
     * @param {string} id
     * @returns {Element}
     */
    getById(id) {
        return this.map[id]
    }

    init() {
        super.init()

        this.data.state = 'EDITING'
        this.data.mode = Mode.DESIGN
        this.data.editMode = EditMode.ELEMENT

        this.data.activeTool = ToolType.SELECT
        this.data.lastActiveTool = ToolType.SELECT
        this.data.lastGeneralTool = ToolType.SELECT

        this.data.undo = new Undo(this)
    }

    /**
     * creates a blank datastore
     * @protected
     */
    create() {
        super.create()
        this.emit('LOAD-START')

        this.data.type = EntityType.DATA_STORE
        this.data.editMode = EditMode.ELEMENT
        this.data.preview = null
        this.data.previewZoom = 'FIT'
        this.data.hideOrigin = false
        this.data.snapToPixelGrid = true
        this.data.snapToObject = true
        this.data.features = new Map([
            ['editOrigin', false]
        ])

        this.fontHelper = FontHelper.instance()

        this.fileMeta = {}

        this.nameCounter = new ElementNameCounter()

        this.clipboard = new Clipboard(this)

        this.images = new ImageManager(this)
        this.library = new Library(this)

        // create one empty workspace
        // TODO: figure out if we need to create workspace at the DS creation time
        this.workspaceList = []
        this.workspace = new Watcher()

        this.interaction = new InteractionManager(this)
        // TODO: might not need this when we'll have multi-actions
        this.interaction.ensureActionExists()

        this.transition = new TransitionManager(this, this.interaction)


        this.selection = new Selection(this)
        this.eam = new EAM(this)

        this.imageExport = new ImageExport(this)

        this.editor = new Editor(this)

        this.meshManager = MeshManager
        this.emit('LOAD')
    }

    /**
     * loads a serialized datastore
     * @param {DataStoreData} data
     * @fires 'LOAD'
     */
    load(data) {
        this.emit('LOAD-START')
        this.map = {}
        super.load(data)

        this.data.id = data.phaseFileId
        this.data.preview = data.preview ? this.map[data.preview] : null
        this.data.previewZoom = data.previewZoom || 'FIT'

        if (this.images) {
            this.images.clear()
            if (data.images) {
                this.images.loadImages(data.images)
            }
        } else {
            this.images = new ImageManager(this, data.images)
        }

        if (this.nameCounter) {
            this.nameCounter.reset()
        } else {
            this.nameCounter = new ElementNameCounter()
        }

        if (this.clipboard) {
            this.clipboard.init()
        } else {
            this.clipboard = new Clipboard(this)
        }

        if (this.library) {
            this.library.load(data.library)
        } else {
            this.library = new Library(this, data.library)
        }

        // load workspaces
        this.workspaceList = []
        for (const workspaceData of data.workspaceList) {
            const workspace = new Workspace(this, workspaceData)
            workspace.set('parent', this)
            this.workspaceList.push(workspace)
        }
        // select workspace
        if (this.workspace) {
            this.workspace.change(data.workspace ? this.getById(data.workspace) : this.workspaceList[0], false)
        } else {
            this.workspace = new Watcher(data.workspace ? this.getById(data.workspace) : this.workspaceList[0])
        }

        if (this.interaction) {
            this.interaction.load(data.interaction)
        } else {
            this.interaction = new InteractionManager(this, data.interaction)
        }
        // TODO: might not need this when we'll have multi-actions
        this.interaction.ensureActionExists()

        if (this.transition) {
            this.transition.loadInteraction(this.interaction)
        } else {
            this.transition = new TransitionManager(this, this.interaction)
        }

        if (!this.selection) {
            this.selection = new Selection(this)
        }
        this.selection.clear(NOT_UNDOABLE)

        if (!this.eam) {
            this.eam = new EAM(this)
        }

        this.imageExport = new ImageExport(this, data.imageExport)

        if (this.editor) {
            this.editor.init()
        } else {
            this.editor = new Editor(this)
        }

        this.meshManager = MeshManager

        this.emit('LOAD')
    }

    get isDesignMode() {
        return this.get('mode') === Mode.DESIGN
    }

    get isActionMode() {
        return this.get('mode') === Mode.ACTION
    }

    get isEditingState() {
        return this.get('state') === 'EDITING'
    }

    get isInspectingState() {
        return this.get('state') === 'INSPECTING'
    }

    get inUndoRedo() {
        const { inUndo, inRedo } = this.get('undo')
        return inUndo || inRedo
    }

    /**
     * Serializes the given elements and returns the serialized data.
     * @param {Element[]} elements
     * @returns {SerializedElementsContent} serialized data
     */
    serializeElements(elements) {
        const serializedData = {
            elements: [],
            images: {},
            interaction: new Map(),
            library: new Map(),
            mapElementToTrack: {},
            mapKeyFrameToLibrary: {}
        }

        for (const element of elements) {
            this.cloneComponentsOfSubtree(element, serializedData)

            const serializedElementData = element.save(true)
            serializedData.elements.push(serializedElementData)
        }

        serializedData.library = Array.from(serializedData.library.values())
        serializedData.interaction = Object.fromEntries(serializedData.interaction)

        return serializedData
    }

    cloneComponentsOfSubtree(element, serializedData) {
        for (const traversedElement of this.dataStore.traverseSubtree(element)) {
            const { id, base } = traversedElement.gets('id', 'base')
            base.saveComponentList(serializedData.library)

            this._cloneImages(base, serializedData.images)

            const elementTrackId = this.dataStore.interaction.getElementTrackIdByElementId(id)
            if (elementTrackId) {
                serializedData.mapElementToTrack[id] = elementTrackId
                this.dataStore.interaction.saveElementTrack(elementTrackId, serializedData)
            }
        }
    }
    _cloneImages(base, images) {
        base.fills.forEach(fillComponentId => {
            const fillComponent = this.dataStore.library.getComponent(fillComponentId)
            fillComponent.layers.forEach(fillId => {
                const fill = this.dataStore.library.getLayer(fillId)
                const imageId = fill.paint.imageId
                if (imageId) {
                    const image = this.dataStore.images.getImage(imageId)
                    if (image) {
                        images[imageId] = image.save()
                    }
                }
            })
        })
    }

    /**
     * Deserializes an array of element data objects and creates new elements using the provided library components.
     *
     * @param {SerializedElementsContent} content - The serialized data to deserialize.    
     * @returns {Array} The array of newly created elements
     */

    deserializeElements({ elements, library }) {
        const libraryMap = this.dataStore.library.cloneComponentList(library)

        const handleRename = (name) => {
            if (!name) return name
            const newName = name.split('.');
            return newName[0]
        }

        const { newElements } = this.processElements(elements, libraryMap, null, handleRename)

        return newElements
    }

    processElements(elements, libraryMap, offset = null, handleRename = null) {
        const newElements = []
        const elementIdMap = new Map()

        for (const elementData of elements) {
            const originalIdList = []
            const queue = [elementData]
            while (queue.length) {
                const el = queue.shift()
                if (el.children) {
                    queue.push(...el.children)
                }
                originalIdList.push(el.id)
            }

            this.clipboard.remapComponentsInSubtree(elementData, libraryMap)

            if (offset) {
                this.clipboard.updatePastedElementPosition(elementData, offset)
            }

            if (handleRename) {
                elementData.name = handleRename(elementData.name)
            }

            const el = this.dataStore.createElement(elementData.elementType, elementData)
            elementIdMap.set(elementData.id, el.get('id'))

            const newElementData = el.save()
            const newQueue = [newElementData]
            while (newQueue.length) {
                const originalId = originalIdList.shift()
                const currentEl = newQueue.shift()
                if (currentEl.children) {
                    newQueue.push(...currentEl.children)
                }
                elementIdMap.set(originalId, currentEl.id)
            }
            newElements.push(el)
        }

        return { newElements, elementIdMap }
    }

    /**
     * serializes a datastore
     * @returns {DataStoreData}
     */
    save() {
        if (!this.isLoaded) {
            return null
        }
        const data = super.save()

        data.version = this.version

        data.library = this.library.save()
        // save workspaces
        data.workspaceList = this.workspaceList.map(workspace => workspace.save())

        data.interaction = this.interaction.save()

        data.images = this.images.save()
        data.preview = this.data.preview ? this.data.preview.get('id') : null
        data.previewZoom = this.data.previewZoom
        data.imageExport = this.imageExport.save()
        return data
    }

    // ////////////////////////////// //
    //         Workspaces             //
    // ////////////////////////////// //

    /**
     * Selects workspace
     * @param  {Workspace} workspace
     * @param  {boolean} [commit=true]
     * @returns {boolean}
     */
    selectWorkspace(workspace, commit = true) {
        const index = this.workspaceList.indexOf(workspace)
        if (index === -1) {
            return false
        }
        const original = this.workspace.watched
        this.workspace.change(workspace)
        this.selection.clear(NO_COMMIT)
        this.fire('WORKSPACE_CHANGE', {
            original,
            value: workspace
        })
        if (commit) {
            this.commitUndo()
        }
        return true
    }

    /**
     * Creates a new empty workspace and adds it to the end of the list
     * @param  {Workspace} workspace
     * @param  {number} index
     * @returns {Workspace}
     * @fires WORKSPACE_LIST_CHANGES
     */
    addWorkspace(workspace = new Workspace(this), index = this.workspaceList.length) {
        workspace.set('parent', this)
        this.workspaceList.splice(index, 0, workspace)
        this.fire('WORKSPACE_LIST_CHANGES', new WorkspaceListChanges().add(workspace))
        // select workspace and commit later
        this.selectWorkspace(workspace, false)
        this.commitUndo()
        return workspace
    }

    /**
     * Removes workspace from the datastore
     * @param  {Workspace} workspace
     * @returns {boolean}         true if was removed successfully; false otherwise
     */
    removeWorkspace(workspace) {
        // should not remove last remaining workspace
        if (this.workspaceList.length === 1) {
            return false
        }
        const index = this.workspaceList.indexOf(workspace)
        // Workspace already not in the list
        if (index === -1) {
            return false
        }
        // select different workspace if the one being deleted it currently selected
        if (this.workspace.watched === workspace) {
            const newSelected = this.workspaceList[index > 0 ? index - 1 : index + 1]
            // select workspace and commit later
            this.selectWorkspace(newSelected, false)
        }
        this.workspaceList.splice(index, 1)
        this.fire('WORKSPACE_LIST_CHANGES', new WorkspaceListChanges().remove(workspace, index))
        this.commitUndo()
        return true
    }

    /**
     * Moves workspace to a new place in the list
     * @param  {number} fromIndex
     * @param  {number} toIndex
     * @returns {boolean}
     */
    moveWorkspace(fromIndex, toIndex) {
        if (
            fromIndex === toIndex ||
            fromIndex < 0 ||
            fromIndex >= this.workspaceList.length ||
            toIndex < 0 ||
            toIndex >= this.workspaceList.length
        ) {
            return false
        }
        this.workspaceList.splice(toIndex, 0, ...this.workspaceList.splice(fromIndex, 1))
        this.fire('WORKSPACE_LIST_CHANGES', new WorkspaceListChanges().reorder(fromIndex, toIndex))
        this.commitUndo()
        return true
    }

    /**
     * changes workspace based on name
     * @param {string} name
     */
    setWorkspaceByName(name) {
        for (const workspace of this.workspaceList) {
            if (workspace.get('name') === name) {
                this.selectWorkspace(workspace)
                return
            }
        }
        throw new Error(`Could not find workspace with name of ${name}`)
    }

    /**
     * Creates a new empty screen and adds it to the current workspace
     * @param  {Screen} screen
     * @returns {Screen}
     */
    addScreenToCurrentWorkspace(screen = new Screen(this)) {
        // WORKAROUND: This should be delete after we refactor scene tree.
        //     Currently, we don't allow to use 'addChildrenAt' to add children to workspace.
        //     And this only used to add a screen to workspace for create a new file.
        const workspace = this.workspace.watched
        if (!workspace) {
            return
        }

        screen.set('name', 'Screen')
        const defaultSize = 500
        const halfSize = defaultSize / 2
        screen.setBaseProp('dimensions', { width: defaultSize, height: defaultSize })
        screen.setBaseProp('translate', { translateX: halfSize, translateY: halfSize })
        screen.setBaseProp('referencePoint', { referencePointX: halfSize, referencePointY: halfSize })
        const fillId = this.library.addLayer(screen.base.fills[0])
        const fill = this.library.getLayer(fillId)

        this.library.setProperty(fill.paintId, { color: [1, 1, 1, 1] })

        workspace.children.push(screen)
        return screen
    }

    /** clears DataStore */
    clear() {
        this.isLoaded = false
        this.selection.clear()
        this.transition.clear()
        this.workspaceList.forEach(workspace => {
            workspace.clear()
        })
        this.workspaceList = []
        this.workspace.change(null, false)
        this.library.clear()
        this.eam.clear()
        this.nameCounter.reset()
        this.features = new Map([
            ['editOrigin', false]
        ]);
    }

    /**
     * loads fonts and begins load for images
     */
    async sync() {
        // TODO: re-enable font helper when it is ready
        // await this.fontHelper.loadFontList()
        this.emit('FONT-LIST-LOAD')
    }

    /**
     * @param {Setter} owner
     * @param {string} type
     * @param {(Array|object)} data
     */
    refire(owner, type, data) {
        this.emit(owner.id || owner.get('id'), { owner, type, data })
    }

    addUndo(owner, type, data) {
        this.get('undo').add(owner, type, data)
    }

    commitUndo() {
        this.get('undo').commit()
    }

    debounceCommitUndo() {
        this._debounceCommitUndo()
    }

    clearUndo() {
        this.get('undo').clear()
    }

    /**
     * creates element based on type
     * @param {ElementType} type
     * @param {ElementData} data
     * @returns {Element}
     */
    createElement(type, data) {
        return createElement(this, type, data)
    }

    getContainableTarget(expectedTarget) {
        if (expectedTarget) {
            if (this.hasElement(expectedTarget.get('id'))) {
                return expectedTarget
            }
            return this.getContainableTarget()
        }

        const selectedElements = this.selection.get('elements')
        const target = selectedElements[selectedElements.length - 1]
        if (!target) {
            return this.workspace.children[0] // SCREEN
        }
        if (target.children) {
            return target
        }
        return target.get('parent')
    }

    getLocalPos(target, worldPos) {
        const targetNode = this.drawInfo.vs.indexer.nodeMap.get(target.get('id'))
        return targetNode.item.transform.world.clone().affine_inverse().xform(worldPos)
    }

    getAssetInsertIndex(targetContainer) {

        const [lastSelectedElement] = this.selection.get('elements').slice(-1)
        const numberOfTargetChildren = targetContainer.children.length

        if (!lastSelectedElement) {
            return numberOfTargetChildren
        }

        const selectedElIndex = targetContainer.children.findIndex((child) => child.get('id') === lastSelectedElement.get('id'))

        return selectedElIndex === -1 || selectedElIndex === numberOfTargetChildren - 1
            ? numberOfTargetChildren
            : selectedElIndex + 1
    }

    positionNewElement(targetContainer, elements, worldPos) {
        const size = targetContainer.get('size')
        const centerPos = {
            x: size.width / 2,
            y: size.height / 2
        }
        if (worldPos) {
            const locaPos = this.getLocalPos(targetContainer, worldPos)
            centerPos.x = locaPos.x
            centerPos.y = locaPos.y
        }
        elements.reduce(
            (basePos, element, index) => {
                if (index === 0) {
                    element.setBaseProp('translate', {
                        translateX: basePos.x,
                        translateY: basePos.y
                    })
                    basePos.x += element.get('width') / 2 + 40
                    basePos.y -= element.get('height') / 2
                } else {
                    element.setBaseProp('translate', {
                        translateX: basePos.x + element.get('width') / 2,
                        translateY: basePos.y + element.get('height') / 2
                    })
                    basePos.x += element.get('width') + 40
                }
                return basePos
            },
            centerPos
        )
    }

    createImageElement(imageMetaList, target, worldPos) {
        const targetContainer = this.getContainableTarget(target)

        const newElementList = imageMetaList.map(({ image, dimensions, name }) => {
            const element = this.createElement(ElementType.PATH, {
                geometryType: GeometryType.RECTANGLE,
                name
            })
            element.setBaseProp('dimensions', dimensions)
            element.setBaseProp('referencePoint', {
                referencePointX: dimensions.width / 2,
                referencePointY: dimensions.height / 2

            })

            const fillComponent = this.library.getComponent(element.base.fills[0])
            const fill = this.library.getLayer(fillComponent.layers[0])
            this.library.setProperty(fill.paint.id, { paintType: PaintType.IMAGE, imageId: image.data.id })

            return element
        })
        const screen = this.workspace.watched.children[0]

        const insertIndex = this.getAssetInsertIndex(targetContainer)

        this.addChildrenAt(screen, newElementList, screen.children.length, NO_COMMIT)
        this.addChildrenAt(targetContainer, newElementList, insertIndex, NO_COMMIT)

        // reposition
        this.positionNewElement(targetContainer, newElementList, worldPos)

        this.selection.selectElements(newElementList)
    }

    /**
     * Creates new element of specified type at specified position
     * Optionally can specify size. If geometryType is not specified, geometry type considered a Rectangle
     * @param {ElementType} type
     * @param {object} position
     * @param {number} position.x
     * @param {number} position.y
     * @param {object} size
     * @param {number} size.width
     * @param {number} size.height
     * @param {GeometryType} geometryType
     * @param {object} containerData
     * @param {BooleanOperation} containerData.booleanType
     * @param {bool} containerData.isMask
     * @param {bool} containerData.invert
     * @param {ContainerElementType} containerData.containerType
     * @returns {Element}
     */
    createNewElement(type, position, size, geometryType, containerData) {
        return createNewElement(this, type, position, size, geometryType, containerData)
    }

    /**
     * Check if `child` can be a child of `parent`
     * @param  {Element} child
     * @param  {Element} parent
     * @returns {boolean}            true if can; false otherwise
     */
    canBeChildOf(child, parent) {
        return canBeChildOf(child, parent)
    }

    /**
     * Creates a new property of provided class, loads property from data or find it in the library by reference
     * @param  { PropComponentType } typeOrClass    a type of PropertyComponent
     * @param  {PropData} propData
     * @returns {PropretyComponent}     instance of classOrClassName
     */
    createComponent(typeOrClass, propData) {
        return this.library.addProperty(this, typeOrClass, propData)
    }

    /**
     * get element by id in the current Workspace
     * @param {string} id
     * @returns {Element}
     */
    getElement(id) {
        return this.workspace.watched.getElement(id)
    }

    /**
     * check if element with id exists in element order map
     * @param {string} id
     * @returns {Element}
     */
    hasElement(id) {
        return this.workspace.watched.hasElement(id)
    }


    /**
     * Preorder traversal of the subtree within the current Workspace
     * @param {string | Element}  root
     * @param {bool} [includeRoot=true]  set to false to not include the root itself in the iterable
     * @yields {string | Element}
     */
    *traverseSubtree(root, includeRoot) {
        let el
        for (el of this.workspace.watched.traverseSubtree(root, includeRoot)) {
            yield el
        }
    }

    /**
     * Calculates order of all elements in current workspace
     */
    updateElementOrder() {
        this.workspace.watched.updateElementOrder()
    }

    /**
     * Sorts a list of elements based on their order in Scene tree of current Workspace
     * @param {Element[]} elementList
     * @param {bool} inPlace
     * @returns {Element[]} `elementList` if `inPlace` is true; new sorted array of Elements otherwise
     */
    sortElements(elementList, inPlace = true) {
        return this.workspace.watched.sortElements(elementList, inPlace)
    }

    /**
     * Find the top most (as displayed in UI) element from the list
     * @param {Iterable<Element>} elementList
     * @returns {Element}       top most element from the `elementList`
     */
    getTopMostElement(elementList) {
        return this.workspace.watched.getTopMostElement(elementList)
    }

    /**
     * Find the bottom most (as displayed in UI) element from the list
     * @param {Iterable<Element>} elementList
     * @returns {Element}       bottom most element from the `elementList`
     */
    getBottomMostElement(elementList) {
        return this.workspace.watched.getBottomMostElement(elementList)
    }

    /**
     * Find the deepest (as displayed in UI) element from the list
     * @param {Iterable<Element>} elementList
     * @returns {Element}       deepest element from the `elementList`
     */
    getDeepestElement(elementList) {
        return this.workspace.watched.getDeepestElement(elementList)
    }

    /**
     * Check if specified `element` is a descendant of specified `ancestor` in a current Workspace
     * @param {Element} element
     * @param {Element} ancestor
     * @returns {bool}  true if `element` is a descendant of the `ancestor`; false otherwise
     */
    isDescendantOf(element, ancestor) {
        return this.workspace.watched.isDescendantOf(element, ancestor)
    }

    /**
     * Get parent of the element in current Workspace (considering specifics of the Container elements and stopping at Workspace level)
     * @param {Element} element
     * @returns {Element | undefined}
     */
    getParentOf(element) {
        return this.workspace.watched.getParentOf(element)
    }

    /**
     * Get common ancestor of two elements in current Workspace. Workspace itself doesn't count as a common ancestor
     * @param {Element} element1
     * @param {Element} element2
     * @returns {Element | undefined}
     */
    getCommonAncestor(element1, element2) {
        return this.workspace.watched.getCommonAncestor(element1, element2)
    }

    /**
     * @param {string} elementId
     * @returns {bool} true element is workspace; false otherwise
     */
    isWorkspace(elementId) {
        const p = this.getById(elementId)
        if (!p) return false
        return p.get('type') === EntityType.WORKSPACE
    }

    /**
     * Adds children to the end of the specified parent in current workspace
     * @param {Element} parent      new parent
     * @param {Element[]} children
     * @param {object} [options]
     * @param {boolean} [options.updateOrder=true]  true if this method should update the element order; set to false to not update it
     * @param {boolean} [options.fire=true]      true if this method should fire any events; set to false to not fire
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     * @returns {false | Element[]} elements that were successfully added
     */
    addChildren(parent, children, options) {
        return this.workspace.watched.addChildren(parent, children, options)
    }

    /**
     * Adds children to the end of the specified parent in current workspace
     * @param {Element} parent      new parent
     * @param {Element[]} children
     * @param {number} index
     * @param {object} [options]
     * @param {boolean} [options.updateOrder=true]  true if this method should update the element order; set to false to not update it
     * @param {boolean} [options.fire=true]      true if this method should fire any events; set to false to not fire
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     * @returns {false | Element[]}                  elements that were successfully added
     */
    addChildrenAt(parent, children, index, options) {
        return this.workspace.watched.addChildrenAt(parent, children, index, options)
    }

    /**
     * Removed successive number of children at specified index from specified parent in current workspace
     * @param {Element} parent
     * @param {number} index           index at which to start removing elements
     * @param {number} [howMany=1]     by default removes 1 element
     * @param {object} [options]
     * @param {boolean} [options.updateOrder=true]  true if this method should update the element order; set to false to not update it
     * @param {Group} [options.newParent=null]   if defined will set parent of removed element to be `newParent`
     * @param {boolean} [options.fire=true]      true if this method should fire any events; set to false to not fire
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     * @returns {Element[]}              list of Elements that were removed
     */
    removeChildrenAt(parent, index, howMany = 1, options) {
        return this.workspace.watched.removeChildrenAt(parent, index, howMany, options)
    }

    /**
     * Removes children from the specified parent in current workspace
     * @param {Element} parent
     * @param {Element[]} children
     * @param {object} [options]
     * @param {boolean} [options.updateOrder=true]  true if this method should update the element order; set to false to not update it
     * @param {Group} [options.newParent=null]   if defined will set parent of removed element to be `newParent`
     * @param {boolean} [options.fire=true]      true if this method should fire any events; set to false to not fire
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     * @returns {Element[]}              list of Elements that were removed
     */
    removeChildren(parent, children, options) {
        return this.workspace.watched.removeChildren(parent, children, options)
    }

    /**
     * Delete elements in current workspace
     * @param {Iterable<Elements>} elements
     * @param {object} [options]
     * @param {boolean} [options.fire=true]      true if this method should fire any events; set to false to not fire
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    deleteElements(elements, options) {
        this.workspace.watched.deleteElements(elements, options)
    }

    /**
     * Delete elements in selection in current workspace.
     * Will fire events and commit undo unless specified otherwise
     * @param {object} [options]
     * @param {boolean} [options.fire=true]      true if this method should fire any events; set to false to not fire
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    deleteSelectedElements(options) {
        const selection = this.selection.get('elements')
        if (selection.length && selection[0].get('elementType') === ElementType.SCREEN) {
            return
        }
        // change selection first to preserve correct undo / redo order
        this.selection.clearElements(NO_COMMIT)
        this.deleteElements(selection, options)
    }

    get inUndo() {
        return this.data.undo.inUndo > 0
    }

    get inRedo() {
        return this.data.undo.inRedo > 0
    }

    get undoList() {
        return this.data.undo.undoList
    }

    get redoList() {
        return this.data.undo.redoList
    }

    _filterOutScreenOf(elements) {
        return elements.filter((el) => el.get('elementType') !== ElementType.SCREEN)
    }

    _getInsertSibling(element) {
        const children = element.get('parent').children
        const curIdx = children.indexOf(element)
        return children[curIdx + 1]
    }

    /**
     * Add elements to a new container
     * @param {[Element]} elements - should be order list of elements
     * @param {Container} container
     */
    addElementsToContainer(elements, container) {
        if (this.isActionMode) {
            return
        }

        // Screen element can't be grouped
        // Filter out the elements which is not in the same level
        const ancestor = this.getParentOf(container)
        const groupedElements = elements.filter((el) => {
            const parent = this.getParentOf(el)
            return el !== container &&
                el.get('elementType') !== ElementType.SCREEN &&
                parent === ancestor
        })
        if (!groupedElements.length) {
            return
        }

        // Get offsets of the grouped elements
        const offsets = []
        const containerPos = container.get('position')
        for (const el of groupedElements) {
            const pos = el.get('position')
            offsets.push({
                x: pos.x - containerPos.x,
                y: pos.y - containerPos.y
            })
        }

        // Add grouped elements to container
        this.addChildren(container, groupedElements, NO_COMMIT)

        // Update grouped elements position base on the new container
        for (let i = 0; i < offsets.length; i++) {
            const el = groupedElements[i]
            const elPos = offsets[i]
            el.set('position', elPos, NOT_UNDOABLE)
        }

        // Reset selection to container
        this.selection.selectElements([container])
    }

    /**
     * Change the visible attribute of selected elements
     * */
    toggleElementsVisible() {
        this.toggleElementsAttribute('visible', true)
    }

    toggleElementsAttribute(attributeName, defaultValue) {
        const selectedElements = this.selection.get('elements')
        let allTheSame = true
        let compare

        for (const element of selectedElements) {
            const next = element.get(attributeName)
            if (compare === undefined) {
                compare = next
            } else if (compare !== next) {
                allTheSame = false
                break
            }
        }

        selectedElements.forEach(el => {
            el.set(attributeName, allTheSame ? !el.get(attributeName) : defaultValue)
        })

        this.commitUndo()
    }

    /**
     * Change the locked attribute of selected elements
     * */
    toggleElementsLocked() {
        this.toggleElementsAttribute('locked', false)
    }

    /**
     * Group selected elements
     * @param {ContainerElementType} containerType
     */
    groupElements(containerType) {
        // Screen element can't be grouped
        const groupedElements = this._filterOutScreenOf(this.selection.get('elements'))
        if (!groupedElements.length) {
            return
        }

        // Get the ancestor for the new container
        const lastElement = groupedElements[groupedElements.length - 1]
        const ancestor = this.getParentOf(lastElement)

        // Get bounds of the grouped elements; bounds is the size of the new container
        const bounds = this.drawInfo.getSelectionBoundsWorld(boundsBuf, containerType === ContainerElementType.NORMAL_GROUP)

        const parentNode = this.drawInfo.vs.indexer.getNode(ancestor.get('id'))
        bounds.copy(parentNode.item.transform.worldInv.xform_rect(bounds))

        // Create a new container and override position and dimensions data
        const containerData = {
            booleanType: BooleanOperation.NONE,
            isMask: false,
            invert: false,
            containerType
        }
        const container = [this.createNewElement(ElementType.CONTAINER, bounds.min, bounds, null, containerData)]

        // Get next sibling of the top most element before adding/removing any element
        //  for the purpose of getting the correct index for the new container
        const nextSibling = this._getInsertSibling(lastElement)
        // Remove elements from their respective parents
        groupedElements.forEach((el) => {
            this.removeChildren(el.get('parent'), [el], NO_FIRE)
        })

        // Add grouped elements to container
        this.addChildren(container[0], groupedElements, NO_FIRE)
        // Get insert position by nextSibling after removed grouped elements from their parents
        const insertIndex = nextSibling
            ? ancestor.children.indexOf(nextSibling)
            : ancestor.children.length
        // Add container into ancestor by index
        this.addChildrenAt(ancestor, container, insertIndex, NO_FIRE)
        this.workspace.watched.fireSceneTreeChanges()

        // Reset selection to container
        this.selection.selectElements(container)
    }

    booleanGroupElements(newBooleanType) {
        // Screen element can't be grouped
        /** @type {Element[]} */
        const groupedElements = this._filterOutScreenOf(this.selection.get('elements'))
        if (!groupedElements.length || this.get('editMode') === EditMode.SHAPE) {
            return
        }

        if (groupedElements.length === 1 && groupedElements[0].isContainer && groupedElements[0].isBooleanType()) {
            const booleanGroup = groupedElements[0]
            const oldBooleanType = booleanGroup.get('booleanType')
            // Do nothing if is same booleanType
            if (oldBooleanType === newBooleanType) {
                return
            }

            this.startTransaction()
            // if the current name is matched to default name of boolean type,
            // then change the name to the new default name of boolean type
            if (booleanGroup.get('name') === BOOLEAN_NAME[oldBooleanType]) {
                booleanGroup.set('name', BOOLEAN_NAME[newBooleanType])
            }
            booleanGroup.set('booleanType', newBooleanType)
            booleanGroup.recalculateBounds()
            this.endTransaction()
            this.drawInfo.vs.getRenderItemOfElement(groupedElements[0]).update(1 | 2)
            this.commitUndo()
            return
        }

        // Get the ancestor for the new container
        const lastElement = groupedElements[groupedElements.length - 1]
        const ancestor = this.getParentOf(lastElement)
        const bounds = this.drawInfo.getBooleanlocalBounds(newBooleanType, ancestor, groupedElements)
        // Create a new container and override position and dimensions data
        const containerData = {
            booleanType: newBooleanType,
            isMask: false,
            invert: false,
            containerType: ElementType.BOOLEAN_CONTAINER
        }
        const container = this.createNewElement(ElementType.BOOLEAN_CONTAINER, bounds, bounds, GeometryType.RECTANGLE, containerData)
        container.set('name', BOOLEAN_NAME[newBooleanType], { undoable: false })

        this.startTransaction()
        // copy the layer sets from child
        const styleOwner = newBooleanType === BooleanOperation.SUBTRACT ? groupedElements[0] : lastElement
        container.sets({
            blendMode: styleOwner.get('blendMode'),
            opacity: styleOwner.get('opacity')
        }, { undoable: false })
        // copy fills layer set
        const layerFillsId = styleOwner.base.fills[0]
        const fillsComp = this.library.getComponent(layerFillsId)
        const layerBordersId = styleOwner.base.strokes[0]
        const bordersComp = this.library.getComponent(layerBordersId)
        if (fillsComp.layers.length > 0) {
            for (let index = 0; index < fillsComp.layers.length; index++) {
                this.library.cloneLayerToLayerComponent(fillsComp.layers[index], container.data.base.fills[0], false)
            }
            // copy borders layer set
            if (bordersComp.layers.length > 0) {
                for (let index = 0; index < bordersComp.layers.length; index++) {
                    this.library.cloneLayerToLayerComponent(bordersComp.layers[index], container.data.base.strokes[0], false)
                }
            }
        } else if (bordersComp.layers.length > 0) {
            // if the styleOwner only has stroke layer then set boolean group fill layer set by styleOwner's border color
            for (let index = 0; index < bordersComp.layers.length; index++) {
                const paintData = this.library.getLayer(bordersComp.layers[index]).paint.save()
                const layerId = this.library.addLayer(container.data.base.fills[0], index, {})
                const paintId = this.library.getLayer(layerId).paintId
                this.library.setProperty(paintId, paintData)
            }
        }
        this.library.fire()
        this.endTransaction()

        // Get next sibling of the top most element before adding/removing any element
        // for the purpose of getting the correct index for the new container
        const nextSibling = this._getInsertSibling(lastElement)
        // Remove elements from their respective parents
        groupedElements.forEach((el) => {
            this.removeChildren(el.get('parent'), [el], NO_FIRE)
        })

        // Add grouped elements to container
        this.addChildren(container, groupedElements, NO_FIRE)
        // Get insert position by nextSibling after removed grouped elements from their parents
        const insertIndex = nextSibling
            ? ancestor.children.indexOf(nextSibling)
            : ancestor.children.length
        // Add container into ancestor by index
        this.addChildrenAt(ancestor, [container], insertIndex, NO_FIRE)
        this.workspace.watched.fireSceneTreeChanges()
        ancestor.recalculateBounds()

        // Reset selection to container
        this.selection.selectElements([container])
    }

    maskGroupElements() {
        // Screen element can't be grouped
        const groupedElements = this._filterOutScreenOf(this.selection.get('elements'))
        if (!groupedElements.length || this.get('editMode') === EditMode.SHAPE) {
            return
        }

        // Get the ancestor for the new container
        const lastElement = groupedElements[groupedElements.length - 1]
        const ancestor = this.getParentOf(lastElement)

        // Get bounds of the mask element; bounds is the size of the new container
        const bounds = this.drawInfo.getElementBounds(boundsBuf, lastElement, true)

        const containerData = {
            booleanType: BooleanOperation.NONE,
            isMask: true,
            containerType: ElementType.MASK_CONTAINER
        }
        // Create a new container and override position and dimensions data
        const container = [this.createNewElement(ElementType.MASK_CONTAINER, bounds.min, bounds, GeometryType.RECTANGLE, containerData)]
        container[0].set('name', 'Mask Group', { undoable: false })

        // Get next sibling of the top most element before adding/removing any element
        // for the purpose of getting the correct index for the new container
        const nextSibling = this._getInsertSibling(lastElement)
        // Remove elements from their respective parents
        groupedElements.forEach((el) => {
            this.removeChildren(el.get('parent'), [el], NO_FIRE)
        })

        // Add grouped elements to container
        this.addChildren(container[0], groupedElements, NO_FIRE)
        // Get insert position by nextSibling after removed grouped elements from their parents
        const insertIndex = nextSibling
            ? ancestor.children.indexOf(nextSibling)
            : ancestor.children.length
        // Add container into ancestor by index
        this.addChildrenAt(ancestor, container, insertIndex, NO_FIRE)
        this.workspace.watched.fireSceneTreeChanges()

        // Reset selection to container
        this.selection.selectElements(container)
    }

    /**
     * Ungroup selected elements
     */
    ungroupCurrentSelection() {
        // Screen element can't be ungrouped
        const groupedElements = this._filterOutScreenOf(this.selection.get('elements'))
        if (!groupedElements.length) {
            return
        }
        const newSelection = []
        const containers = []
        groupedElements.forEach((el) => {
            // Only Container can be ungrouped
            if (!el.isContainer) {
                newSelection.push(el)
                return
            }
            containers.push(el)
            el.children.forEach((child) => newSelection.push(child))
        })

        // Remove containers from the element selection
        this.selection.selectElements(newSelection, NO_COMMIT)

        // Ungroup Containers
        containers.forEach((container) => {
            this.ungroupContainer(container)
        })
        this.workspace.watched.fireSceneTreeChanges()

        // Ungroup doesn't fire INTERACTION_CHANGES event, need to update TM manually
        if (this.isActionMode) {
            this.transition.updateDefaultKFAndInterval()
            const elementIds = newSelection.map(el => el.get('id'))
            this.transition.updateElementData(elementIds)
        }

        this.commitUndo()
    }

    /**
     * Ungroup a container
     * @param {Container} container
     */
    ungroupContainer(container) {
        container.disconnectWithChildren()

        // Turn off autoOrient
        this.getElement(container.get('id')).set('autoOrient', false)
        // Remove group element track
        const elementTrackId = this.interaction.getElementTrackIdByElementId(container.get('id'))
        this.interaction.deleteElementTrack(elementTrackId)

        // Force update element node
        this.drawInfo.updateNodes()

        const ancestor = this.getParentOf(container)
        const index = ancestor.children.indexOf(container)
        const children = container.children
        const childrenCount = children.length
        // Add children to the ancestor
        this.addChildrenAt(ancestor, children, index, NO_FIRE)
        // Remove the container
        this.removeChildrenAt(ancestor, index + childrenCount, 1, NO_FIRE)
        container.clear()
    }


    /**
     * Group the elements by their parent
     * @param {Array<string>} elementIds
     * @returns {object} key: parentId value: array of elementId
     * */
    groupElementsByParent(elementIds) {
        const idMaps = elementIds.map(id => {
            const parentId = this.getParentOf(this.getElement(id)).get('id');
            return { [parentId]: id }
        })
        const groupMaps = groupBy(idMaps, flow([keys, first]));
        return mapValues(groupMaps, (value) => value.map(m => first(values(m))))
    }

    /**
     * Get prev and next brother node ids for element
     * @param {string} elementId
     * @returns {object}
     * */
    getElementBrotherNodeIds(elementId) {
        const container = this.getParentOf(this.getElement(elementId));
        const childrenIds = container.children.map(c => c.get('id')).reverse();
        const index = childrenIds.findIndex(c => c === elementId);
        return {
            prevId: childrenIds[index - 1],
            nextId: childrenIds[index + 1]
        }
    }

    /**
     * Filter need reorder elements and the insert target id
     * @param {string} containerId
     * @param {Array<string>} reorderIds
     * @param {'top' | 'bottom'} type
     * @returns {object}
     * */
    filterReorderElementsAndFindTargetId(containerId, reorderIds, type) {
        const container = this.getElement(containerId);
        const childrenIds = container.children.map(c => c.get('id')).reverse();
        switch (type) {
            case 'top':
                return this._handleElementsAndTargetForMoveTop(
                    reorderIds,
                    childrenIds
                )
            case 'bottom':
                return this._handleElementsAndTargetForMoveBottom(
                    reorderIds,
                    childrenIds
                )
        }
    }

    /**
     * Filter need reorder elements
     * @param {string} containerId
     * @param {Array<string>} reorderIds
     * @param {'forward' | 'backward'} type
     * @returns {object}
     * */
    filterReorderElements(containerId, reorderIds, type) {
        const container = this.getElement(containerId);
        const childrenIds = container.children.map(c => c.get('id')).reverse();
        switch (type) {
            case 'forward':
                return this._handleElementsForMoveForward(
                    reorderIds,
                    childrenIds
                )
            case 'backward':
                return this._handleElementsForMoveBackward(
                    reorderIds,
                    childrenIds
                )
        }
    }

    /**
     * Handle filter elements and find the target for move to top
     * @param {Array<string>} reorderIds
     * @param {Array<string>} childrenIds
     * @returns {object}
     * */
    _handleElementsAndTargetForMoveTop(reorderIds, childrenIds) {
        let targetIndex = 0;
        const filteredIds = reorderIds.filter(elId => {
            const elIndex = childrenIds.indexOf(elId);
            const prevIds = childrenIds.slice(0, elIndex);
            const isPrevAllNeedReorder = prevIds.every(prevId => reorderIds.includes(prevId));
            if (isPrevAllNeedReorder && elIndex >= targetIndex) {
                targetIndex = elIndex + 1;
            }
            return !isPrevAllNeedReorder;
        })
        return {
            filteredIds,
            targetId: childrenIds[targetIndex]
        }
    }

    /**
     * Handle filter elements and find the target for move to bottom
     * @param {Array<string>} reorderIds
     * @param {Array<string>} childrenIds
     * @returns {object}
     * */
    _handleElementsAndTargetForMoveBottom(reorderIds, childrenIds) {
        let targetIndex = childrenIds.length - 1;
        const filteredIds = reorderIds.filter(elId => {
            const elIndex = childrenIds.indexOf(elId);
            const nextIds = childrenIds.slice(elIndex + 1);
            const isNextAllNeedReorder = nextIds.every(nextId => reorderIds.includes(nextId));
            if (isNextAllNeedReorder && elIndex <= targetIndex) {
                targetIndex = elIndex - 1;
            }
            return !isNextAllNeedReorder;
        })
        return {
            filteredIds,
            targetId: childrenIds[targetIndex]
        }
    }

    /**
     * Handle filter elements for move forward
     * @param {Array<string>} reorderIds
     * @param {Array<string>} childrenIds
     * @returns {Array<string>}
     * */
    _handleElementsForMoveForward(reorderIds, childrenIds) {
        return reorderIds.filter(elId => {
            const elIndex = childrenIds.indexOf(elId);
            const prevIds = childrenIds.slice(0, elIndex);
            const isPrevAllNeedReorder = prevIds.every(prevId => reorderIds.includes(prevId));
            return !isPrevAllNeedReorder;
        })
    }

    /**
     * Handle filter elements for move backward
     * @param {Array<string>} reorderIds
     * @param {Array<string>} childrenIds
     * @returns {Array<string>}
     * */
    _handleElementsForMoveBackward(reorderIds, childrenIds) {
        return reorderIds.filter(elId => {
            const elIndex = childrenIds.indexOf(elId);
            const nextIds = childrenIds.slice(elIndex + 1);
            const isNextAllNeedReorder = nextIds.every(nextId => reorderIds.includes(nextId));
            return !isNextAllNeedReorder;
        })
    }

    /**
     * Undo workspace related changes
     * @param {string} type
     * @param {*} changes
     */
    undo(type, changes) {
        super.undo(type, changes)

        switch (type) {
            case 'WORKSPACE_LIST_CHANGES':
                if (changes.ADD) {
                    changes.ADD.forEach(workspace => this.removeWorkspace(workspace))
                } else if (changes.REMOVE) {
                    changes.REMOVE.forEach(({ workspace, index }) => {
                        this.addWorkspace(workspace, index)
                    })
                } else if (changes.REORDER) {
                    this.moveWorkspace(changes.REORDER[1], changes.REORDER[0])
                } else {
                    throw new Error(`not implemented in WorkspaceList undo`)
                }
                break

            case 'WORKSPACE_CHANGE':
                this.selectWorkspace(changes.original, false)
                break
        }
    }

    /**
     * Undo workspace related changes
     * @param {string} type
     * @param {*} changes
     */
    redo(type, changes) {
        super.redo(type, changes)

        switch (type) {
            case 'WORKSPACE_LIST_CHANGES':
                if (changes.ADD) {
                    changes.ADD.forEach(workspace => this.addWorkspace(workspace))
                } else if (changes.REMOVE) {
                    changes.REMOVE.forEach(({ workspace }) => {
                        this.removeWorkspace(workspace)
                    })
                } else if (changes.REORDER) {
                    this.moveWorkspace(changes.REORDER[0], changes.REORDER[1])
                } else {
                    throw new Error(`not implemented in WorkspaceList undo`)
                }
                break

            case 'WORKSPACE_CHANGE':
                this.selectWorkspace(changes.value, false)
                break
        }
    }

    cachePathBaseValue(el) {
        if (el.children) {
            el.children.forEach((child) => this.cachePathBaseValue(child))
        }

        if (el.canMorph) {
            el.cachePathBaseValue()
        }
    }

    switchState(targetState) {
        if (this.get('state') === targetState) {
            return
        }

        if (targetState === 'VERSIONING' && this.get('features').get('editOrigin')) {
            this.setFeature('editOrigin', false, { undoable: false, commit: false })
        }


        this.set('state', targetState)
        
        if(targetState === 'INSPECTING') {
            this.eam.setActiveTool(ToolType.SELECT)
        } else {
            this.eam.setActiveTool(targetState === 'EDITING' ? this.data.lastActiveTool : ToolType.HAND)
        }
        
    }

    /**
     * Switch mode and commit undo
     * @param {Mode} targetMode
     */
    switchMode(targetMode) {
        if (this.get('mode') === targetMode) {
            return
        }
        if (targetMode in Mode) {
            if (this.isDesignMode) {
                this.workspaceList.forEach((workspace) => {
                    workspace.children.forEach((el) => this.cachePathBaseValue(el))
                })
                if (this.get('activeTool') !== ToolType.HAND && this.get('activeTool') !== ToolType.COMMENT) {
                    this.eam.setActiveTool(this.data.lastGeneralTool)
                }
            }

            this.startTransaction()
            this.switchEditMode(EditMode.ELEMENT, NO_COMMIT)

            this.set('mode', targetMode)

            this.endTransaction()
            this.commitUndo()
        } else {
            throw new Error(`Unknown mode: ${targetMode}`)
        }
    }

    /**
     *
     * @param {EditMode} editMode
     * @param {object} options
     * @param {boolean} [options.undoable]
     * @param {boolean} [options.commit] commit the changes to the undo
     */
    switchEditMode(editMode, { undoable = true, commit = true } = { undoable: true, commit: true }) {
        const currEditMode = this.get('editMode')
        if (currEditMode === editMode) {
            return
        }

        switch (editMode) {
            case EditMode.ELEMENT:
                if (currEditMode === EditMode.SHAPE) {
                    this.selection.clearVertices(NO_COMMIT)
                    this.selection.set('hoverVertex', null, NO_COMMIT)
                    const element = this.selection.get('elements')[0]
                    if (element) {
                        element.get('geometry').execute('stopEditing')
                    }
                }
                this.eam.setActiveTool(this.data.lastGeneralTool)
                break
            case EditMode.SHAPE:
                this.eam.setActiveTool(ToolType.SELECT)
                break;
            case EditMode.TEXT:
            case EditMode.GRADIENT_HANDLES:
            case EditMode.MOTION_PATH:
                break
            default:
                throw new Error(`Unknown editMode: ${editMode}`)
        }

        this.set('editMode', editMode, { commit, undoable })

        if (undoable && commit) this.commitUndo()
    }

    /**
     * @param {any} tool
     * @param {boolean} fire
     */
    setActiveTool(tool, fire = true) {
        const changes = {}
        changes.activeTool = tool

        if (this.isEditingState) {
            changes.lastActiveTool = tool
        }
        if (tool in GeneralToolType) {
            changes.lastGeneralTool = tool
        }
        if (fire) this.sets(changes, NOT_UNDOABLE)
    }

    /**
     * Injects draw info interface from renderer into DS
     * @param {DrawInfo} drawInfo
     */
    injectDrawInfo(drawInfo) {
        this.drawInfo = drawInfo

        this.fire('drawInfo', drawInfo, NOT_UNDOABLE)
    }

    /**
     * Start the transaction by initializing transaction map
     */
    startTransaction() {
        this._transactionCount++
        for (const type in TRANSACTION_MAP) {
            if (!TRANSACTION_MAP[type]) {
                TRANSACTION_MAP[type] = new Map()
            }
        }
    }

    /**
     * Update the record to different transaction map
     * @param {Setter} owner
     * @param {ChangesEvent} changes
     */
    updateTransaction(owner, changes) {
        for (const type in TRANSACTION_MAP) {
            const transaction = TRANSACTION_MAP[type]
            if (!transaction) continue

            if (type === 'SELECTION') {
                this.selection.updateTransaction(transaction, owner, changes)
            }
        }
    }

    /**
     * Handle the transaction map separately with different purpose
     */
    endTransaction() {
        // Only execute and clear at the last end transaction
        if (--this._transactionCount) {
            return
        }
        for (const type in TRANSACTION_MAP) {
            if (!TRANSACTION_MAP[type]) continue
            if (type === 'SELECTION') {
                Stats.begin('ds/transaction')
                this.selection.endTransaction(TRANSACTION_MAP[type])
                Stats.end('ds/transaction')
            }
            TRANSACTION_MAP[type] = null
        }
    }

    forceUpdateLayerListToRenderer() {
        this.workspace.watched.forceUpdateLayerListToRenderer()
    }

    /**
     * @param {string} name
     * @returns {boolean}
     */
    getFeature(name) {
        return !!this.get('features').get(name)
    }

    /**
     * @param {string} name
     * @param {boolean} value
     * @param {object} options
     * @param {boolean} [options.undoable]
     * @param {boolean} [options.commit] commit the changes to the undo
     */
    setFeature(name, value, { undoable = true, commit = true } = { undoable: true, commit: true }) {
        const currValue = this.get('features').get(name)
        if (currValue === value) {
            return
        }

        switch (name) {
            case 'editOrigin': {
                if (value) {
                    const selectedElements = this.selection.get('elements')
                    if (
                        selectedElements.length === 0 ||
                        selectedElements.some(el => el.get('elementType') === ElementType.SCREEN)
                    ) return

                    const activeTool = this.get('activeTool');
                    if (activeTool in CreateElementToolType || activeTool === ToolType.COMMENT) {
                        this.eam.setActiveTool(this.data.lastGeneralTool)
                    }
                }

                if (this.get('editMode') !== EditMode.ELEMENT &&
                    this.get('editMode') !== EditMode.GRADIENT_HANDLES) {
                    return
                }

                this.eam.editOrigin(value)
                break;
            }
        }

        const newFeatures = new Map(this.get('features'))
        this.set('features', newFeatures.set(name, value), { commit, undoable })

        if (undoable && commit) {
            this.commitUndo()
        }
    }

    updateGroupProperties(rootArray, relocate) {
        this.drawInfo.updateNodes()
        const stack = Array.isArray(rootArray) ? rootArray : [rootArray]
        while (stack.length) {
            const curr = stack.pop()
            if (curr.isNormalGroup) {
                if (!this.dataStore.drawInfo?.isNodePresent(curr.get('id'))) {
                    console.warn(curr.get('id'), 'has no render node instance')
                    continue
                }
                const bounds = this.dataStore.drawInfo.getLatestLocalBounds(curr.get('id'))
                curr.updatePropertiesByBounds(bounds, relocate)
            }
            if (curr.children) {
                for (let i = curr.children.length; i > -1; i--) {
                    if (curr.children[i]) {
                        stack.push(curr.children[i])
                    }
                }
            }
        }
    }
}

/**
 * @typedef {object} PropData
 * @property {string} [refId]                       id of the Property if it's shared in the Library
 * @property {PropertyComponentData} [data]         data of the Property to load it from
 */

/** @typedef {import('./media/ImageResource').ImageData} ImageData */
/** @typedef {'VIEWING'|'EDITING'|'PREVIEW'|'VERSIONING'} State */
/** @typedef {'FIT'|'FILL'} PreviewZoom */


/**
 * @typedef {Setter} DataStoreData
 * @property {WorkspaceData[]} workspaceList
 * @property {InteractionData[]} interaction
 * @property {Record<string, ImageData>} images
 * @property {State} state
 * @property {Mode} mode
 * @property {PreviewZoom} previewZoom
 * // extra props (not-serializable)
 * @property {string} workspace     id of the workspace to select
 */


/**
 * @typedef {object} SerializedElementsContent serialized content of the elements
 * @property {object[]} elements    list of serialized elements
 * @property {object[]} library     list of serialized components of elements
 *                                   (nested components added before their parents)
 * @property {object[]} interaction list of serialized interactions of elements
 */
