import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { LayerListKey, LayerListKeySet } from '@phase-software/data-store'
import { PaintType } from '@phase-software/types'

import useEditorImage from '../../../hooks/useEditorImage'
import { useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import { useElement, useElementActions } from '../../../providers/dataStore/ElementProvider'
import { useInteractionActions } from '../../../providers/dataStore/InteractionProvider'
import { ColorBox, ElementIcon, RenameText } from '../../shared'
import { effectPropertyNameMap, layerPropertyNameMap, paintTypeNameMap, propertyNameMap } from '../constant'

type ElementTrackProps = {
  id: string
}

const ElementTrack = ({ id }: ElementTrackProps) => {
  const element = useElement((o) => o[id])
  const { setElementName } = useElementActions()

  const handleRename = useCallback(
    (newName: string) => {
      const trimmedNewName = newName.trim()
      if (trimmedNewName !== element?.name) {
        setElementName(id, trimmedNewName)
      }
    },
    [element?.name, id, setElementName]
  )

  return (
    <div className="flex items-center pr-8 flex-grow min-w-0" data-test-id={`tracklist-element-${id}`}>
      <ElementIcon
        elementType={element.elementType}
        containerType={element.containerType}
        geometry={element.geometry}
        booleanType={element.booleanType}
        isMask={element.isMask}
        active
      />
      <RenameText originalText={element.name} onFinish={handleRename} />
    </div>
  )
}

type PaintPreviewProps = {
  trackId: string
  parentKey: string
  index: number
}

const PaintPreview = ({ trackId, parentKey, index }: PaintPreviewProps) => {
  const { getImageUrl } = useEditorImage()
  const { getElementIdByPropertyTrack } = useInteractionActions()
  const { getComputedLayerByElementId } = useDataStoreActions()
  const elementId = getElementIdByPropertyTrack(trackId)
  const data = getComputedLayerByElementId(elementId, parentKey, index)

  const { color, paintType, gradientStops, opacity, imageId } = data
  const [r, g, b] = color ?? [0, 0, 0]
  const c = useMemo(() => [r, g, b, opacity], [r, g, b, opacity])
  const imageUrl = getImageUrl(imageId)

  return <ColorBox color={c} paintType={paintType} gradientStops={gradientStops} size="s" imageUrl={imageUrl} />
}

type PropertyTrackProps = {
  id: string
  trackKey: string
  parentKey?: string
  index: number
}

const PropertyTrack = ({ id, trackKey, parentKey, index }: PropertyTrackProps) => {
  const { getTrackKeyFramePaintType } = useInteractionActions()
  const { t } = useTranslation('file')

  if (parentKey && LayerListKeySet.has(parentKey as LayerListKey)) {
    return (
      <div className="flex items-center gap-4">
        <PaintPreview trackId={id} parentKey={parentKey} index={index} />
        {t(`property_name.${propertyNameMap[parentKey]}`)} {index + 1}
      </div>
    )
  }

  if (effectPropertyNameMap[trackKey]) {
    return <>{t(`effect_type.${effectPropertyNameMap[trackKey]}`)}</>
  }

  if (!trackKey.includes('.')) {
    return <>{t(`property_name.${propertyNameMap[trackKey]}`) || trackKey}</>
  }

  const [, layerKey] = trackKey.split('.')

  if (layerKey === 'paint') {
    const paintType: PaintType = getTrackKeyFramePaintType(id)
    return <>{t(`paint_type.${paintTypeNameMap[paintType]}`)}</>
  }
  return <>{t(`layer_property.${layerPropertyNameMap[layerKey]}`) || layerKey}</>
}

type TrackHeaderProps = {
  data: {
    elementId?: string
    propKey: string
    parentKey?: string
    index: number
    id: string
  }
}
const TrackHeader = ({ data }: TrackHeaderProps) => {
  if (data.elementId) {
    return <ElementTrack id={data.elementId} />
  }
  return (
    <div data-test-id={`tracklist-element-${data.id}`} className="inline-flex">
      <PropertyTrack id={data.id} trackKey={data.propKey} parentKey={data.parentKey} index={data.index} />
    </div>
  )
}

export default TrackHeader
