import { Color } from "../math"
import { VisualServer } from "../visual_server/VisualServer"
import { WASM } from "../wasm/platforms/wasm"
import { app } from "../wasm/platforms/web/web"
/**
 * @param {string} rootID
 * @param {Color} clearColor
 * @param {number} scale
 */
export function takeSnapshot(rootID, clearColor = null) {
    const VS = VisualServer.instance()
    const root = VS.indexer.getNode(rootID)

    const updateList = [...VS.updateList.values(), root.item]
    VS.indexer.updateNodes(updateList)
    VS.updateList.clear()
    VS.storage.update()

    return renderSnapshot(rootID, clearColor).pixels
}

const clearColor = Color.hex(0x000000)

/**
 * @param {string} rootID
 * @param {'png'|'jpeg'|'webp'} format
 * @returns {Promise<Blob>}
 */
export function takeSnapshotAsBlob(rootID, format = 'png') {
    return new Promise((resolve, reject) => {
        const VS = VisualServer.instance()
        const root = VS.indexer.getNode(rootID)

        if (!root) {
            return reject(`cannot take snapshot of non-exist element "${rootID}"`)
        }

        const { pixels, width, height } = renderSnapshot(rootID, clearColor)
        if (!pixels) {
            return reject(`failed to take snapshot of "${rootID}"`)
        }
        // TODO: maybe just return resolve(new Blob([new Uint8ClampedArray(pixels)]))
        const canvas = document.createElement("canvas")
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext("2d")
        ctx.putImageData(new ImageData(new Uint8ClampedArray(pixels), width, height), 0, 0)

        return canvas.toBlob(resolve, `image/${format}`)
    })
}

/**
 * @param {string} rootID
 * @param {Color} clearColor
 * @returns {{pixels: Uint8ClampedArray, width: number, height: number}}
 */
function renderSnapshot(rootID, clearColor) {
    const VS = VisualServer.instance()

    WASM().prepareSnapshotGroups()
    const { x, y, width, height } = VS.drawNodesForSnapshot(rootID, {
        ignoreRoot: false,
        clearColor,
    })
    WASM().snapshot(x, y, width, height, clearColor.r, clearColor.g, clearColor.b, clearColor.a)

    return { pixels: app.pixels(), width, height }
}