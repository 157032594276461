import { cloneDeep } from 'lodash'
import { Events, Hover } from '@phase-software/data-store'
import {
    ElementType,
    EntityType,
    EditMode,
    ToolType,
    FrameType,
    EventFlag
} from '@phase-software/types'
import { NO_COMMIT } from '@phase-software/data-utils'
import { ElementSelection } from '@phase-software/data-store/src/Eam/constants'
import { Vector2 } from '../../math'
import { setDraggedElement, enableSnapping, disableSnapping } from '../../panes'
import {
    HitTest,
    findScalableNode,
    findHoverSelection,
    findHoveredScalableNode,
    findHoveredTopNode,
    findHoveredTopNodeEqOrBelowDepth,
    searchNodesAtPoint,
    searchOverlapsWithBox,
    searchScreenNamesAtPoint,
    _isPointInNode
} from '../../visual_server/HitTest'
import { SCREEN_FONT_SIZE } from '../../constants'
import { initMotionSetCursor } from '../../panes/MotionPathHelper'
import { initVertexSelection } from './vertex'
import { initSetOriginTool } from './setOriginTool'


/** @typedef {import('@phase-software/data-store/src/layer/ComputedLayer').ComputedLayer} ComputedLayer */
/** @typedef {import('@phase-software/data-store/src/Workspace').Workspace} Workspace */
/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('@phase-software/data-store/src/Group').Group} Group */
/** @typedef {import('../../resources/VectorResource').VectorResource} VectorResource */
/** @typedef {import('../../visual_server/RenderItem').RenderItem} RenderItem */
/** @typedef {import('../../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('../handles/Snapping').Snapping} Snapping */

const MOTION_PATH_KEYS = ['motionPath']

/** @type {VisualServer} */
let _visualServer

/** @type {HitTest} */
let hitTest

/**
 * @param {VisualServer} visualServer
 * @param {import('..').SetLocalCursorStateFn} setLocalCursorState
 */
export function initSelection(visualServer, setLocalCursorState) {
    const { dataStore, viewport } = visualServer

    _visualServer = visualServer

    /** @type {HitTest} */
    hitTest = new HitTest(visualServer)

    initVertexSelection(_visualServer, setLocalCursorState)

    initSetOriginTool(_visualServer, hitTest, setLocalCursorState)

    initMotionSetCursor(setLocalCursorState)

    dataStore.eam.on(Events.INCREASE_CORNER_RADIUS, () => {
        const selected = dataStore.selection.get('elements')
        for (const element of selected) {
            const g = element.get('geometry')
            if (!g) return
            const vertices = dataStore.selection.get('vertices')
            if (vertices.length > 0) {
                for (const vertex of vertices) {
                    let cR = vertex.cornerRadius === null ? element.get('cornerRadius') : vertex.cornerRadius
                    cR += 1
                    vertex.cornerRadius = cR
                    console.log(`DEBUG: Increase corner radius: ${cR}`)
                }
            } else {
                const cR = element.get('cornerRadius') + 1
                element.set('cornerRadius', cR)
                console.log(`DEBUG: Increase corner radius: ${cR}`)
            }
        }
    })

    dataStore.eam.on(Events.DECREASE_CORNER_RADIUS, () => {
        const selected = dataStore.selection.get('elements')
        for (const element of selected) {
            const g = element.get('geometry')
            if (!g) return
            const vertices = dataStore.selection.get('vertices')
            if (vertices.length > 0) {
                for (const vertex of vertices) {
                    let cR = vertex.cornerRadius === null ? element.get('cornerRadius') : vertex.cornerRadius
                    cR = Math.max(0, cR - 1)
                    vertex.cornerRadius = cR
                    console.log(`DEBUG: Decrease corner radius: ${cR}`)
                }
            } else {
                const cR = Math.max(0, element.get('cornerRadius') - 1)
                element.set('cornerRadius', cR)
                console.log(`DEBUG: Decrease corner radius: ${cR}`)
            }
        }
    })

    dataStore.eam.on(Events.SELECT_ALL_ELEMENTS, _selectAll)
    dataStore.eam.on(Events.SELECT_PARENT_ELEMENT, () => {
        _selectParentNode()
    })
    dataStore.eam.on(Events.SELECT_CHILD_ELEMENT, () => {
        _enterSelectedNode()
    })
    dataStore.eam.on(Events.SELECT_FIRST_ELEMENT, () => {
        const children = dataStore.workspace.children
        dataStore.selection.selectElements([children[children.length - 1]])
    })
    dataStore.eam.on(Events.SELECT_NEXT_ELEMENT, () => {
        _selectElement('NEXT')
    })
    dataStore.eam.on(Events.SELECT_PREVIOUS_ELEMENT, () => {
        _selectElement('PREVIOUS')
    })

    dataStore.eam.on(Events.HOVER_ELEMENT, ({ mousePos }, keys) => {
        const p = viewport.toWorld(mousePos)
        // Find hover selected element
        const hoverSelected = dataStore.getById(findHoverSelection(hitTest.state, p, visualServer.selection, false))
        dataStore.eam.changeHoverSelected(hoverSelected)
        if (visualServer.selection.anySelected) {
            findHoveredTopNodeEqOrBelowDepth(
                hitTest.state,
                p,
                visualServer.selection,
                keys.modifier
            )
        } else if (dataStore.workspace.isEmpty()) {
            hitTest.state.hovered = null
        } else {
            const rootId = dataStore.workspace.children[0].get('id')
            findHoveredTopNode(
                hitTest.state,
                keys.modifier,
                p,
                rootId,
                rootId,
                false
            )
        }
        const element = dataStore.getById(hitTest.state.hovered)
        dataStore.selection.set('hover', element)

        if (element) {
            dataStore.eam.changeHover(Hover.ELEMENT)
        } else if (
            dataStore.eam.states.elementSelection === ElementSelection.MULTIPLE &&
            _visualServer.selection.bounds.has_point(p) &&
            visualServer.dataStore.get('activeTool') !== ToolType.SCALE
        ) {
            dataStore.eam.changeHover(Hover.MULTIPLE_SELECTION_BOUND)
        }

        setLocalCursorState('default')
        if (keys.alt && dataStore.eam.hoverType === Hover.ELEMENT && element && !element.isScreen && dataStore.get('editMode') === EditMode.ELEMENT) {
            setLocalCursorState('duplicate')
        }
    })

    dataStore.eam.on(Events.DRAG_OVER, ({ mousePos }) => {
        const p = viewport.toWorld(mousePos)
        findHoveredScalableNode(hitTest.state, p, dataStore.workspace.children[0].get('id'))

        const element = dataStore.getById(hitTest.state.hovered)
        dataStore.selection.set('dragOver', element)
    })

    dataStore.eam.on(Events.DRAG_END, () => {
        hitTest.state.hovered = null
        dataStore.selection.set('dragOver', null)
    })

    dataStore.eam.on(Events.DROP_WITH_FILES, ({ mousePos }) => {
        const p = viewport.toWorld(mousePos)
        findHoveredScalableNode(hitTest.state, p, dataStore.workspace.children[0].get('id'))

        const element = dataStore.getById(hitTest.state.hovered)
        dataStore.selection.set('dragOver', element)
    })

    dataStore.eam.on(Events.DOUBLE_LEFT_CLICK, (e) => {
        // there is no more functionality in path editing mode
        if (dataStore.eam.states.editMode === EditMode.SHAPE) {
            dataStore.eam.activateElementEditMode()
            return
        }
        const selection = dataStore.selection.get('elements')
        // mouse position in world space
        const mousePos = viewport.toWorld(e.mousePos)

        // find the top node, passing the double click flag then this will return the top children at mouse point at
        // if there is no children, this will return container itself
        if (!visualServer.selection.first) return
        findHoveredTopNode(hitTest.state, e.modifier, mousePos, visualServer.selection.first.id, visualServer.selection.first.id, true)

        const element = dataStore.getById(hitTest.state.hovered)
        if (!element) return // The element still can be undefined
        const node = visualServer.getRenderItemOfElement(element)
        const elementType = element.get('elementType')
        switch (elementType) {
            case ElementType.TEXT:
                if (node.bounds.containsPoint(mousePos, node.transform.worldInv)) {
                    dataStore.selection.selectElements([element])
                    // hitTest.state.selected = [hitTest.state.hovered]
                    // TODO: check if text editing is undoable and should be part of same undo
                    // commit as select action above
                    dataStore.eam.activateTextMode()
                    return
                }
                break
            case ElementType.SCREEN:
            case ElementType.CONTAINER:
                dataStore.selection.set('hover', element)
                dataStore.selection.selectElements([element])
                // hitTest.state.selected = [hitTest.state.hovered]
                break
            case ElementType.PATH:
                // If the clicked element is not in the selection, that means you are selecting the child of a Container.
                if (!selection.includes(element)) {
                    dataStore.selection.set('hover', element)
                    dataStore.selection.selectElements([element])
                    // hitTest.state.selected = [hitTest.state.hovered]
                    return
                }
                if (dataStore.isActionMode) {
                    // Still need to fire event for UI
                    dataStore.eam.activateShapeMode()
                    return
                }

                dataStore.selection.selectElements([element], NO_COMMIT)
                // hitTest.state.selected = [hitTest.state.hovered]

                if (dataStore.eam.states.isShapeMode) {
                    dataStore.eam.activateElementEditMode()
                } else {
                    dataStore.eam.activateShapeMode()
                }
                break
        }
        e.handled = false
    })

    const selectElement = (e, keys, options) => {
        if (keys.shift) {
            _addOrRemoveNodeFromSelection(options)
        } else {
            _select(options)
        }
    }

    dataStore.eam.on(Events.SELECT_ELEMENT, selectElement)
    dataStore.eam.on(Events.DESELECT_ELEMENT, (e) => {
        if (dataStore.selection.get('hover')) {
            e.handled = false
        }
        _deselectAllNodes()
    })

    {
        /** @type {Array<{ translate:Vector2, position: duVector2, element: Element, parentId:string }>} */
        const entries = []
        const _initialMousePos = new Vector2()
        /** @type {Snapping} */
        const snapping = _visualServer.snapping
        const initSelectedBoundPos = new Vector2()
        const DRAG_THRESHOLD = 2
        const originalSelectionData = new Map()
        const reverseClonedMap = new Map()
        let moved = false
        let startPos = null
        let selectedOnStart = false
        let hasPressedModifier = false
        let hasPressedShift = false
        let hasPressedAlt = false
        let hasDuplicate = false
        let previousAltStatus = undefined
        let originalSelection = []
        let newSelection = []
        let clonedMap = null
        let originalEditOriginState = null
        let originalUndoListCount = -1
        let selectNewElementChanges = { hasChanged: false }

        const _toggleOriginalElement = (element, alt, enableHide = true) => {
            const { visible, locked } = originalSelectionData.get(element.get('id'))
            const visibleWithLocked = alt ? visible : locked
            element.sets({
                visible: enableHide ? visibleWithLocked : visible,
                virtualDisplay: alt ? undefined : (visible || undefined),
                virtualSelected: alt ? undefined : true,
                locked,
                virtualLocked: locked
            }, { ...NO_COMMIT, undoable: false })
        }

        const _toggleNewElement = (newElement, originalElement, alt) => {
            const { visible, locked } = originalSelectionData.get(originalElement.get('id'))
            newElement.sets({
                visible: locked && !alt ? false : visible,
                virtualDisplay: alt ? undefined : false,
                virtualSelected: true,
                locked: false,
                virtualLocked: locked
            }, { ...NO_COMMIT, undoable: false })
        }

        const _simulateDragElementsAtBeginning = (alt) => {
            originalEditOriginState = dataStore.getFeature('editOrigin')
            const keyframeSelection = [...dataStore.selection.get('kfs')]
            const currentSelection = dataStore.selection.get('elements')
            if (currentSelection[0] && currentSelection[0].isScreen) {
                return
            }

            hasDuplicate = true
            // Cache original element selection and its data
            originalSelection = [...currentSelection]
            originalSelection.forEach((element) => {
                const elData = element.gets(
                    'id',
                    'visible',
                    'locked',
                    'translateX',
                    'translateY'
                )

                MOTION_PATH_KEYS.forEach((propKey) => {
                    const keyFrameId = _findPropertyKeyFrameId(elData.id, propKey)
                    if (keyFrameId) {
                        const keyFrame = dataStore.interaction.getKeyFrame(keyFrameId)
                        elData.keyFrameData = cloneDeep(keyFrame.value)
                    }
                })
                originalSelectionData.set(
                    elData.id,
                    elData
                )
            })

            clonedMap = dataStore.clipboard.duplicate({ commit: false, fire: false, flags: EventFlag.FROM_DRAG_DUPLICATE })
            clonedMap.forEach((newElementId, originalElementId) => reverseClonedMap.set(newElementId, originalElementId))
            newSelection = [...dataStore.selection.get('elements')]

            newSelection.forEach((element, idx) => {
                const originalElement = originalSelection[idx]
                _toggleNewElement(element, originalElement, alt)
            })
            originalSelection.forEach((originalElement) => {
                _toggleOriginalElement(originalElement, alt, false)
            })

            if (alt) {
                _onlySelectNewKeyframes()
            } else {
                dataStore.selection.selectOnlyKFs(keyframeSelection, NO_COMMIT)
            }
            dataStore.setFeature('editOrigin', originalEditOriginState, { commit: false, undoable: false })
        }

        const _applyPositionChangeToOriginalElements = (reset = false, force = false) => {
            if (!hasDuplicate || (!dataStore.isActionMode && !force)) {
                return
            }

            const translates = new Map()
            newSelection.forEach((element) => {
                translates.set(element.get('id'), element.get('translate'))
            })

            originalSelection.forEach((originalElement) => {
                const originalElementId = originalElement.get('id')
                const newElementId = clonedMap.get(originalElementId)
                const translate = translates.get(newElementId)
                const { visible, locked } = originalSelectionData.get(originalElementId)
                if (reset) {
                    const resetData = {
                        visible,
                        locked
                    }
                    originalElement.sets(resetData, { ...NO_COMMIT, undoable: false })
                }
                if (!locked && translate) {
                    const translateData = {
                        translateX: translate.x,
                        translateY: translate.y
                    }
                    originalElement.sets(translateData)
                }

                return originalElement
            })
        }

        const _onlySelectPositionKeyframesByElements = (elements) => {
            const newKeyFrames = []
            const currentTime = dataStore.transition.currentTime

            elements.forEach((element) => {
                const elementId = element.get('id')
                const elementTrackId = dataStore.interaction.getElementTrackIdByElementId(elementId)
                const elementTrack = dataStore.interaction.getElementTrack(elementTrackId)
                if (!elementTrack) {
                    return
                }

                MOTION_PATH_KEYS.forEach((propKey) => {
                    const state = dataStore.transition.getElementKeyframeState(elementId, propKey)
                    if (state === FrameType.EXPLICIT || state === FrameType.INITIAL) {
                        const interval = dataStore.transition.getPropertyWorkingInterval(elementId, propKey, currentTime)
                        const keyFrameId = interval?.end.id
                        if (keyFrameId) {
                            newKeyFrames.push(keyFrameId)
                        }
                    }
                })
            })

            dataStore.selection.selectOnlyKFs(newKeyFrames, NO_COMMIT)
        }

        const _onlySelectNewKeyframes = () => {
            _onlySelectPositionKeyframesByElements(newSelection)
        }

        const _resetKeyframeSelection = () => {
            if (!hasDuplicate) {
                return
            }

            _onlySelectPositionKeyframesByElements(originalSelection)
        }

        const _resetElementSelection = () => {
            if (!hasDuplicate) {
                return
            }

            dataStore.deleteElements(newSelection, NO_COMMIT)
            dataStore.selection.selectElements(originalSelection, NO_COMMIT)
        }

        const _resetSimulateDragElements = () => {
            if (!hasDuplicate) {
                return
            }

            originalSelection.forEach((element) => {
                element.sets({
                    virtualDisplay: undefined,
                    virtualSelected: undefined,
                    virtualLocked: undefined
                }, { ...NO_COMMIT, undoable: false })
            })
        }

        const _resetDuplicatedElements = (wasLocked = false) => {
            if (!hasDuplicate) {
                return
            }

            newSelection.forEach((element) => {
                element.sets({
                    locked: wasLocked,
                    virtualDisplay: undefined,
                    virtualSelected: undefined,
                    virtualLocked: undefined
                }, { ...NO_COMMIT, undoable: false })
            })
        }

        const _clearChanges = (undoGroup) => {
            // Clear SCENE_TREE_CHANGES
            if (undoGroup.events.filter((event) => event.type === 'SCENE_TREE_CHANGES').length > 1) {
                undoGroup.events = undoGroup.events.filter((event) => event.type !== 'SCENE_TREE_CHANGES')
            }

            const originalElementIds = new Set(originalSelection.map((e) => e.get('id')))
            undoGroup.events = undoGroup.events.filter((event) => {
                // Remove the element changes which is from simulation
                if (hasDuplicate &&
                    !hasPressedAlt &&
                    event.type === 'CHANGES' &&
                    event.owner.get('type') === EntityType.ELEMENT &&
                    !originalElementIds.has(event.owner.get('id'))
                ) {
                    return false
                }

                switch (event.type) {
                    case 'SELECT': {
                        const elementChanges = event.event.get('elements')
                        if (elementChanges) {
                            const beforeElementIds = JSON.stringify(elementChanges.before.map((el) => el.get('id')))
                            const afterElementIds = JSON.stringify(elementChanges.after.map((el) => el.get('id')))

                            if (selectNewElementChanges.hasChanged) {
                                // If has change element selection, then keep the element selection changes without simulation changes
                                const elementBeforeChanges = JSON.stringify(selectNewElementChanges.before)
                                const elementAfterChanges = JSON.stringify(selectNewElementChanges.after)
                                return (elementBeforeChanges === beforeElementIds &&  elementAfterChanges === afterElementIds) ||
                                    (elementBeforeChanges === afterElementIds &&  elementAfterChanges === beforeElementIds)
                            } else if (elementChanges.before.length === elementChanges.after.length) {
                                // If not have change element selection, then keep the element selection changes with real elements
                                const beforeElementsExist = elementChanges.before.every((el) => originalElementIds.has(el.get('id')))
                                const afterElementsExist = elementChanges.after.every((el) => originalElementIds.has(el.get('id')))
                                return beforeElementsExist && afterElementsExist && beforeElementIds !== afterElementIds
                            }

                            return true
                        }

                        // Only keep keyframe selection in action mode
                        const keyframeChanges = event.event.get('kfs')
                        if (keyframeChanges && !dataStore.isActionMode) {
                            return false
                        }

                        return true
                    }
                    case 'CHANGES': {
                        const featuresChange = event.event.get('features')
                        if (featuresChange) {
                            // If not have element selection change, then we don't need to change editOrigin feature status
                            if (!selectNewElementChanges.hasChanged) {
                                return false
                            }

                            return featuresChange.value.get('editOrigin') === dataStore.getFeature('editOrigin')
                        }

                        return true
                    }
                }

                return true
            })
        }

        const _mergeUndo = () => {
            const undo = dataStore.get('undo')
            const nextNewUndo = undo.undoList[originalUndoListCount]
            if (!nextNewUndo) {
                _clearChanges(undo.currentGroup)
                return
            }

            const currentUndoGroup = undo.currentGroup
            for (let i = undo.undoList.length - 1; i >= originalUndoListCount; i--) {
                const undoCommit = undo.undoList[i]
                currentUndoGroup.events.unshift(...undoCommit.events)
                undo.undoList.pop()
            }

            // Clean up duplicate SCENE_TREE_CHANGES
            _clearChanges(currentUndoGroup)

        }

        const _clearSimulateCommit = () => {
            if (!hasDuplicate) {
                return
            }

            const undo = dataStore.get('undo')
            if (originalSelection.length === 1 && originalSelection[0].get('locked')) {
                undo.currentGroup.clear()
            } else {
                _mergeUndo()
            }
        }

        const _updateNewElementBasePosition = () => {
            if (!dataStore.isActionMode || !hasDuplicate) {
                return
            }

            originalSelection.forEach((originalElement) => {
                const originalElementId = originalElement.get('id')
                const newElementId = clonedMap.get(originalElementId)
                const newElement = dataStore.getElement(newElementId)
                const originalBaseTranslate = originalElement.getBaseValue('translate')
                const originalTranslate = originalElement.get('translate')
                const currentTranslate = newElement.get('translate')
                const newBaseTranslate = {
                    translateX: currentTranslate.x - (originalTranslate.x - originalBaseTranslate.translateX),
                    translateY: currentTranslate.y - (originalTranslate.y - originalBaseTranslate.translateY)
                }

                newElement.setBaseProp('translate', newBaseTranslate, false)
                dataStore.transition.cacheSpecificElementBaseValue(newElementId, 'motionPath')
            })
        }

        const _findPropertyKeyFrameId = (elementId, propKey) => {
            let keyFrameId = null
            const state = dataStore.transition.getElementKeyframeState(elementId, propKey)
            if (state === FrameType.EXPLICIT || state === FrameType.INITIAL) {
                const interval = dataStore.transition.getPropertyWorkingInterval(elementId, propKey, dataStore.transition.currentTime)
                keyFrameId = interval.end.id
            }

            return keyFrameId
        }

        const _resetPositionChange = () => {
            if (!dataStore.isActionMode || !hasDuplicate) {
                return
            }

            let fireIMChanges = false
            originalSelection.forEach((originalElement) => {
                const originalElementId = originalElement.get('id')
                const { translateX, translateY, keyFrameData } = originalSelectionData.get(originalElementId)
                originalElement.sets({ translateX, translateY }, { commit: false, undoable: false })
                MOTION_PATH_KEYS.forEach((propKey) => {
                    const keyFrameId = _findPropertyKeyFrameId(originalElementId, propKey)
                    if (keyFrameData) {
                        dataStore.interaction.updateKeyFrameData(keyFrameId, keyFrameData, false)
                    } else {
                        if (keyFrameId) {
                            fireIMChanges = true
                            dataStore.interaction.deleteKeyFrame(keyFrameId, false)
                        }
                    }
                })
            })

            newSelection.forEach((newElement) => {
                const newElementId = newElement.get('id')
                const originalElementId = reverseClonedMap.get(newElementId)
                const { keyFrameData } = originalSelectionData.get(originalElementId)

                MOTION_PATH_KEYS.forEach((propKey) => {
                    const keyFrameId = _findPropertyKeyFrameId(newElementId, propKey)
                    if (keyFrameData) {
                        dataStore.interaction.updateKeyFrameData(keyFrameId, keyFrameData, false)
                    } else if (keyFrameId) {
                        fireIMChanges = true
                        dataStore.interaction.deleteKeyFrame(keyFrameId, false)
                    }
                })
            })

            if (fireIMChanges) {
                dataStore.interaction.fire()
            }
        }

        const _initialDragSnapping = () => {
            _collectMovableElement(entries, snapping)

            _initDragElemSnapping(snapping)
            // when selecting screen element entries' length will be 0 because of not allow to drag
            // screen element and in this case will use AABB's position as selection position
            if (_visualServer.selection.single && entries[0]) {
                initSelectedBoundPos.copy(entries[0].position)
            } else {
                initSelectedBoundPos.set(_visualServer.selection.bounds.x, _visualServer.selection.bounds.y)
            }
        }

        const _startDragElemet = (e, { modifier, shift, alt }) => {
            originalUndoListCount = dataStore.get('undo').undoList.length
            moved = false
            selectedOnStart = false
            setDraggedElement(null)
            hasPressedModifier = modifier
            hasPressedShift = shift
            hasPressedAlt = alt
            if(dataStore.get('state') === 'INSPECTING') hasPressedShift = false

            startPos = e.mousePos.clone()
            const w = viewport.toWorld(startPos)
            const selectedElements = dataStore.selection.get('elements')
            const hoverElement = dataStore.selection.get('hover')
            const hoverSelected = dataStore.getById(findHoverSelection(hitTest.state, w, visualServer.selection, true))
            // If not hover on the selected element, and the hovered element is not in the element selection list.
            // Then we select it at very beginning.
            if (!hoverSelected && hoverElement && !selectedElements.includes(hoverElement)) {
                selectNewElementChanges.hasChanged = true
                selectNewElementChanges.before = dataStore.selection.get('elements').map(e => e.get('id'))
                selectElement({ mousePos: startPos }, { shift: hasPressedShift, modifier: hasPressedModifier })
                selectNewElementChanges.after = dataStore.selection.get('elements').map(e => e.get('id'))
                selectedOnStart = true
            }

            _initialMousePos.copy(w)

            previousAltStatus = undefined
        }

        const _updateDragElement = ({ mousePos }, { shift, modifier, alt }, { snapToGrid = true, snapToObject = true}) => {
            if(dataStore.get('state') === 'INSPECTING') return

            if (!moved && !hasDuplicate) {
                _simulateDragElementsAtBeginning(alt)
                _initialDragSnapping()
            }
            if (!entries[0]) return

            hasPressedAlt = alt
            // If has been moved, then it should be able to move until release mouse.
            const canMove = mousePos.distance_to(startPos) >= DRAG_THRESHOLD || moved
            const hasToggleAlt = previousAltStatus !== hasPressedAlt
            if (hasDuplicate && hasToggleAlt) {
                originalSelection.forEach((originalElement) => {
                    const originalElementId = originalElement.get('id')
                    const newElementId = clonedMap.get(originalElementId)
                    const newElement = dataStore.getElement(newElementId)
                    _toggleOriginalElement(originalElement, alt)
                    _toggleNewElement(newElement, originalElement, alt)
                })

                if (alt) {
                    setLocalCursorState('duplicate')
                    _resetPositionChange()
                    _onlySelectNewKeyframes()
                } else {
                    setLocalCursorState('default')
                    _applyPositionChangeToOriginalElements()
                    _resetKeyframeSelection()
                }
            }

            previousAltStatus = hasPressedAlt

            if (!canMove) {
                return
            }

            if (!moved) {
                setDraggedElement(entries[0].element.get('id'))
                dataStore.selection.set('hover', null)
            }
            moved = true

            hasPressedModifier = modifier
            hasPressedShift = shift
            if (hasDuplicate) {
                // Show/Hide snapping on canvas
                if (entries.length === 1 && entries[0].element.get('virtualLocked') && !hasPressedAlt) {
                    setLocalCursorState('default')
                    disableSnapping()
                } else {
                    enableSnapping()
                }
            }

            const newPos = initSelectedBoundPos.clone()
            const currWorldMouse = viewport.toWorld(mousePos)
            const moveDelta = currWorldMouse.clone().sub(_initialMousePos)
            snapping.updateMoved(moveDelta)
            if (snapToGrid) {
                if (moveDelta.x !== 0) {
                    _initialMousePos.x = Math.round(_initialMousePos.x)
                    currWorldMouse.x = Math.round(currWorldMouse.x)
                }
                if (moveDelta.y !== 0) {
                    _initialMousePos.y = Math.round(_initialMousePos.y)
                    currWorldMouse.y = Math.round(currWorldMouse.y)
                }
            }

            // snap to axis and diagonal
            const delta = currWorldMouse.clone().sub(_initialMousePos)
            const newDelta = snapping.snapAxisAndDiagonal(delta, shift, 'ELEMENT')
            delta.copy(newDelta)

            if (snapToGrid) {
                // only snap the component of moveDelta which is not equal 0
                const extraDelta = snapping.snapElementsPosToGrid(newPos, moveDelta)
                delta.add(extraDelta)
            }
            if (snapToObject) {
                // snap to element
                snapping.updateSnapMovingData(true)
                const extraDelta = snapping.comparingVertices(delta, snapToGrid)
                delta.add(extraDelta)
            }

            _visualServer.dataStore.startTransaction()
            const isActionMode = dataStore.isActionMode
            for (const { translate, element, parentId } of entries) {
                newPos.copy(translate).add(delta)
                if (parentId) {
                    _visualServer.drawInfo.toObjectPosition(parentId, newPos, newPos)
                }

                const canHaveAnimation = !alt && !element.get('virtualLocked') && isActionMode
                const options = { ...NO_COMMIT, interaction: canHaveAnimation, force: canHaveAnimation }
                element.sets({ translateX: newPos.x, translateY: newPos.y }, options)
                if (hasDuplicate && !alt) {
                    const originalElement = dataStore.getElement(reverseClonedMap.get(element.get('id')))
                    const { locked } = originalSelectionData.get(originalElement.get('id'))
                    if (!locked || hasPressedAlt) {
                        originalElement.sets({ translateX: newPos.x, translateY: newPos.y }, options)
                    }
                }
                if (dataStore.isActionMode) {
                    _updateNewElementBasePosition()
                }

                // If drag element without opt key, then we should apply new position
                // to original element to update position keyframe
                if (!hasPressedAlt) {
                    _applyPositionChangeToOriginalElements()
                }
            }

            if (hasDuplicate && hasToggleAlt && alt) {
                originalSelection.forEach((originalElement) => {
                    const { translateX, translateY } = originalSelectionData.get(originalElement.get('id'))
                    originalElement.sets({ translateX, translateY }, NO_COMMIT)
                })
            }
            _visualServer.dataStore.endTransaction()

            // If has movement, need to check keyframes selection again
            if (hasDuplicate && hasToggleAlt) {
                if (alt) {
                    _onlySelectNewKeyframes()
                } else {
                    _resetKeyframeSelection()
                }
            }
        }

        const _endDragElement = () => {
            previousAltStatus = undefined
            setDraggedElement(null)
            _resetSimulateDragElements()
            const didDuplicate = moved && hasPressedAlt
            if (hasDuplicate) {
                if ((moved && !hasPressedAlt) || !moved) {
                    _applyPositionChangeToOriginalElements(true, true)
                    _resetElementSelection()
                    _resetKeyframeSelection()
                } else if (didDuplicate) {
                    _resetDuplicatedElements()
                    _updateNewElementBasePosition()
                }
                updateHitTest()

                if (!didDuplicate) {
                    _clearSimulateCommit()
                } else if (hasPressedAlt) {
                    newSelection.forEach((newElement, idx) => {
                        const originalElement = originalSelection[idx]
                        if (originalElement.get('locked')) {
                            _resetDuplicatedElements(true)
                        }
                    })

                    // FIXME: We should remove the additional MESH undo in Geometry.
                    // STR: Undo drag-duplicate path element will crash
                    const currentUndoGroup = dataStore.get('undo').currentGroup
                    const hasS = currentUndoGroup.events.find((event) => event.type === 'SCENE_TREE_CHANGES')
                    const hasM = currentUndoGroup.events.findIndex((event) => event.type === 'MESH_CHANGES')
                    if (hasS && hasM > -1) {
                        currentUndoGroup.events.splice(hasM, 1)
                    }
                }

                if (!didDuplicate && originalEditOriginState !== null) {
                    dataStore.setFeature('editOrigin', originalEditOriginState, { commit: false, undoable: false })
                }
                if (didDuplicate) {
                    dataStore.selection.selectElements(newSelection, { force: true, commit: false, undoable: false })
                }
            } else if (!moved && !selectedOnStart) {
                selectElement({ mousePos: startPos }, { shift: hasPressedShift, modifier: hasPressedModifier })
            }

            hasDuplicate = false
            selectNewElementChanges = { hasChanged: false }
            originalSelectionData.clear()
            reverseClonedMap.clear()

            // cancelMouseEdge()
            dataStore.commitUndo()
            snapping.setEndSnapping()
            snapping.endSnapMovingElementToElement()
        }

        dataStore.eam
            .on(Events.START_DRAG_ELEMENT, _startDragElemet)
            .on(Events.UPDATE_DRAG_ELEMENT, _updateDragElement)
            .on(Events.END_DRAG_ELEMENT, _endDragElement)



        const _startDragMultipleElements = (e, { modifier, shift, alt }) => {
            originalUndoListCount = dataStore.get('undo').undoList.length
            moved = false
            setDraggedElement(null)
            hasPressedModifier = modifier
            hasPressedShift = shift
            hasPressedAlt = alt
            startPos = e.mousePos.clone()
            const w = viewport.toWorld(startPos)
            _initialMousePos.copy(w)
        }

        dataStore.eam
            .on(Events.START_DRAG_MULTI_ELEMENTS, _startDragMultipleElements)
            .on(Events.UPDATE_DRAG_MULTI_ELEMENTS, _updateDragElement)
            .on(Events.END_DRAG_MULTI_ELEMENTS, _endDragElement)
    }

    dataStore.eam.on(Events.MOVE_ELEMENT_SELECTION_KEY, _moveSelection)
    dataStore.eam.on(Events.MOVE_MOTION_POINTS_SELECTION_KEY, _moveMotionPoints)

    /**
     *
     * @param {Array<{ position: duVector2, element: Element }>} entries
     */
    function _collectMovableElement(entries) {
        entries.splice(0)
        for (const { element } of _visualServer.selection.iter()) {
            // Future: remove type equal ElementType.SCREEN to move around the screen element
            if (element.isLocked() || element.get('elementType') === ElementType.SCREEN) {
                continue
            }
            const parentId = getParent(element)?.get('id')
            const position = new Vector2()
            const translate = new Vector2()
            if (parentId) {
                _visualServer.drawInfo.toWorldPosition(parentId, element.get('position'), position)
                _visualServer.drawInfo.toWorldPosition(parentId, element.get('translate'), translate)
            } else {
                position.copy(element.get('position'))
                translate.copy(element.get('translate'))
            }
            entries.push({ translate, position, element, parentId })
        }
    }

    /**
     *
     * @param {Snapping} snapping
     */
    function _initDragElemSnapping(snapping) {
        // update selection bounds for tracking selection origin
        _visualServer.selection.updateBounds()
        snapping.setSnappingOriginalPos(_visualServer.selection.bounds.center)
        // get selected element OAB for snapping moving element to element
        snapping.setSelectedElementOAB()
    }

}

/**
 * Filter for the unlocked element
 * @param {Element} element
 * @returns {boolean}
 */
function _lockedFilter(element) {
    return !element.get('locked')
}

function _selectAll() {
    const selection = _visualServer.dataStore.selection
    const selectedElements = selection.get('elements')
    if (selectedElements.length) {
        let parent
        for (const element of selectedElements) {
            const p = element.get('parent')
            if (!parent) {
                parent = p
            } else if (parent === p) {
                continue
            } else {
                selection.selectElements(_visualServer.dataStore.workspace.children.filter(_lockedFilter))
                return
            }
        }
        selection.selectElements(parent.children.filter(_lockedFilter))

    } else {
        selection.selectElements(_visualServer.dataStore.workspace.children.filter(_lockedFilter))
    }
}

function _enterSelectedNode() {
    const selected = _visualServer.dataStore.selection.get('elements')
    if (!selected.length) return
    const newSelection = []
    selected.forEach((el) => {
        const elementType = el.get('elementType')
        switch (elementType) {
            case ElementType.CONTAINER:
            case ElementType.SCREEN:
                newSelection.push(...el.children)
                break
            default:
                newSelection.push(el)
        }
    })
    if (newSelection.length) {
        _visualServer.dataStore.selection.selectElements(newSelection)
    }
}

function _selectParentNode() {
    const selected = _visualServer.dataStore.selection.get('elements')
    if (!selected.length) return

    const newSelection = []
    selected.forEach((el) => {
        const parent = getParent(el)
        if (parent.get('type') !== EntityType.WORKSPACE) {
            newSelection.push(parent)
        }
    })
    if (newSelection.length) {
        _visualServer.dataStore.selection.selectElements(newSelection)
    }
}

/**
 * @param {string} direction
 */
function _selectElement(direction) {
    const selected = _visualServer.dataStore.selection.get('elements')
    const searchForElement = direction === 'NEXT' ? searchForNextElement : searchForPrevElement
    if (selected.length === 0) {
        /** @type {Element[]} */
        const children = _visualServer.dataStore.workspace.children
        _visualServer.dataStore.selection.selectElements([searchForElement(children)])
    } else {
        const parent = selected[0].get('parent')
        // Should not change selection if elements belongs to different group
        if (selected.length === 1) {
            if (parent.children.length > 1) {
                const index = parent.children.indexOf(selected[0])
                _visualServer.dataStore.selection.selectElements([searchForElement(parent.children, index)])
            }
        } else if (selected.every(e => e.get('parent') === parent)) {
            // Should change selection to the first/last selected one if elements are in the same group
            if (direction === 'NEXT') {
                _visualServer.dataStore.selection.selectElements(selected.slice(0, 1))
            } else {
                _visualServer.dataStore.selection.selectElements(selected.slice(-1))
            }
        }
    }
}

/**
 * @param {Element[]} elements
 * @param {number} [index]
 * @returns {Element}
 */
function searchForNextElement(elements, index = -1) {
    // index -> len
    for (let i = index + 1; i < elements.length; i++) {
        if (elements[i].get('visible')) return elements[i]
    }
    // 0 -> index
    for (let i = 0; i < index; i++) {
        if (elements[i].get('visible')) return elements[i]
    }
}

/**
 * @param {Element[]} elements
 * @param {number} [index]
 * @returns {Element}
 */
function searchForPrevElement(elements, index = elements.length) {
    // index -> 0
    for (let i = index - 1; i >= 0; i--) {
        if (elements[i].get('visible')) return elements[i]
    }
    // len -> index
    for (let i = elements.length - 1; i > index; i--) {
        if (elements[i].get('visible')) return elements[i]
    }
}

function _deselectAllNodes() {
    _visualServer.dataStore.selection.clear()
}

/**
 * @param options
 */
function _select(options) {
    const selection = _visualServer.dataStore.selection
    const element = selection.get('hover')
    selection.selectElements(element ? [element] : [], options)
    // hitTest.state.selected = element ? [element.get("id")] : []
    activateElementEditMode()
}

function activateElementEditMode() {
    const eam = _visualServer.dataStore.eam
    if (eam.states.isGradientHandlesMode || eam.states.isTextMode) {
        eam.activateElementEditMode()
    }
}

/**
 * @param options
 */
function _addOrRemoveNodeFromSelection(options) {
    const element = _visualServer.dataStore.selection.get('hover')
    if (element) _visualServer.dataStore.selection.toggleElements([element], options)
}

/**
 * Clear the screen name of hit test
 */
export function clearScreenNameHitTest() {
    if (hitTest?.state.compute.screen_name) hitTest.state.compute.screen_name.clear()
}

/**
 * Add screen name to hit test
 * @param {SceneNode} node
 */
export function addScreenNameToHitTest(node) {
    _addScreenNodeHitTest(node)
}

/**
 * Find all elements (their vectors and bounds) at the point
 * @param {Vector2} point
 * @returns {Set<Element>}
 */
export function findElementsAt(point) {
    const elementIds = searchNodesAtPoint(hitTest.state.compute, hitTest.state.scene.node_bank, _visualServer.dataStore.workspace.children[0].get('id'), point, true)
    const screenIds = searchScreenNamesAtPoint(hitTest.state.compute, point)

    /** @type {Map<string, Element>} */
    const elements = new Set()
    for (const id of elementIds) {
        /** @type {Element} */
        const element = _visualServer.dataStore.getById(id)
        const elementType = element.get('elementType')
        // Don't merge the conditions for debugging
        if (elementType === ElementType.SCREEN || element.isBooleanType() || element.isMaskGroup()) continue
        if (
            elementType === ElementType.CONTAINER ||
            element.computedStyle.onlyHasShadowLayer()
        ) {
            elements.add(element)
        }
    }
    for (const id of screenIds) {
        elements.add(_visualServer.dataStore.getById(id))
    }
    return elements
}

/**
 * Find the innermost and topmost Scalable element at the point
 *
 * Scalable = screen or container or normal group (not include boolean group or mask group)
 *
 * @param {Vector2} point world position of mouse
 */
export function findScalableElementAt(point) {
    const id = findScalableNode(hitTest.state, point, _visualServer.dataStore.workspace.children[0].get('id'))
    return _visualServer.dataStore.getById(id)
}

/**
 * Find top most element at the point
 * @param {Vector2} point
 * @returns {Element | null}
 */
export function findTopMostElementAt(point) {
    const sameLevel = new Set()
    const notSameLevel = new Set()
    const targets = findElementsAt(point)
    targets.forEach((el) => {
        const result = getAncestor(el)
        if (result.sameLevel) {
            sameLevel.add(result.ancestor)
        } else {
            notSameLevel.add(result.ancestor)
        }
    })

    // If has elements are in the same level of one of the selected elements,
    // then find the top most element with those elements.
    // Otherwise, we find it with the elements which are not in the same level with
    // the selected elements.
    const elements = sameLevel.size ? sameLevel : notSameLevel
    if (elements.size === 0) return null
    if (elements.size === 1) return elements.values().next().value
    return _visualServer.dataStore.getTopMostElement(elements)
}

/**
 * @returns {Hittest}
 */
export function getHitTest() {
    return hitTest
}

/**
 * @param {SceneNode} rootNode
 */
export function initHitTestWithRoot(rootNode) {
    hitTest.state.scene.root = rootNode.id
    hitTest.state.scene.addNewNode(hitTest.state.compute, rootNode, null)
}

/**
 * @param {SceneNode} node
 * @param {string} parent_id
 */
export function addNewNodeHitTest(node, parent_id) {
    hitTest.state.scene.addNewNode(hitTest.state.compute, node, parent_id)
}

/**
 * @param {SceneNode} node
 */
function _addScreenNodeHitTest(node) {
    const element = _visualServer.dataStore.getById(node.id)
    const selectable = element.get('visible') && !element.get('locked')
    const zoom = _visualServer.dataStore.workspace.get('scale')

    // Get screen name's width and height, but not draw it
    const pane = _visualServer.overlay.panes[0]
    const nameSize = pane.measureText(element.get('name'), 'Roboto', SCREEN_FONT_SIZE)
    hitTest.state.scene.addScreenNode(hitTest.state.compute, node, selectable, zoom, nameSize)
}

export function updateHitTest() {
    if (!_visualServer.indexer.root) return
    hitTest.update()
}

/**
 * @param {string} node_id
 */
export function deleteHitTest(node_id) {
    hitTest.state.compute.bounds.delete(node_id)
    hitTest.state.compute.transform.delete(node_id)
    hitTest.state.scene.node_bank.delete(node_id)
}

/**
 * @param point
 * @param node_id
 */
export function isPointInNodeHitTest(point, node_id) {
    return _isPointInNode(hitTest.state.compute, hitTest.state.scene.node_bank, node_id, point)
}

/**
 *
 * @param {Rect2} box
 * @returns {Set<Element>}
 */
export function searchNodesBySelectAreaBox(box) {
    const ctx = hitTest.state.compute
    const node_bank = hitTest.state.scene.node_bank
    const node_ids = []
    const screen_id = _visualServer.dataStore.workspace.children[0].get('id')
    if (box.containsRect(ctx.bounds.get(screen_id).world)) {
        node_ids.push(screen_id)
    } else {
        const node = node_bank.get(screen_id)
        for (let i = 0; i < node.children.length; i++) {
            node_ids.push(node.children[i].id)
        }
    }
    const list = searchOverlapsWithBox(ctx, node_bank, node_ids, box, true)
    const elements = new Set()
    for (const elementId of list) {
        elements.add(_visualServer.dataStore.getById(elementId))
    }
    return elements
}

/**
 *
 * @param {Rect2} box
 * @returns {Set<Element>}
 */
export function searchNodesBySnappingRect(box) {
    const ctx = hitTest.state.compute
    const node_bank = hitTest.state.scene.node_bank
    const node_ids = []
    // eslint-disable-next-line no-unused-vars
    for (const [key, _] of node_bank) {
        node_ids.push(key)
    }
    const list = searchOverlapsWithBox(ctx, node_bank, node_ids, box)
    const elements = new Set()
    for (const elementId of list) {
        elements.add(_visualServer.dataStore.getById(elementId))
    }
    return elements
}

/**
 * Check if the element is the sibling of one of the selected element
 * @param {Set} selected
 * @param {Element} element
 * @returns {bool}
 */
function isSibling(selected, element) {
    const siblings = getParent(element).children
    for (const el of selected) {
        if (siblings.includes(el)) {
            return true
        }
    }

    return false
}

/**
 * returns the right ancestor based on our selection rules
 * @param {Element} element
 * @returns {[Element, bool]} [element, isSibling]
 */
function getAncestor(element) {
    if (!element) {
        return {
            ancestor: null,
            sameLevel: false
        }
    }

    const selected = new Set(_visualServer.dataStore.selection.get('elements'))
    // return element if it's selected
    if (selected.has(element) || isSibling(selected, element)) {
        return {
            ancestor: element,
            sameLevel: true
        }
    }

    // return ancestor element
    let current = element
    /* eslint-disable no-constant-condition */
    while (true) {
        // return current element if is the sibling of one of the selected element
        if (isSibling(selected, current)) {
            return {
                ancestor: current,
                sameLevel: true
            }
        }

        const parent = getParent(current)
        // return container if it's selected
        if (parent.get('elementType') === ElementType.CONTAINER && selected.has(parent)) {
            return {
                ancestor: parent,
                sameLevel: false
            }
        }

        // return current element if its parent is a top level element
        if (parent.get('type') === EntityType.WORKSPACE || parent.get('elementType') === ElementType.SCREEN) {
            return {
                ancestor: current,
                sameLevel: false
            }
        }

        current = parent
    }
}

/**
 * returns the parent of the passed in element ignoring groups
 * @param {Element} element
 * @returns {Element}
 */
function getParent(element) {
    const parent = element.get('parent')
    if (parent.get('type') === EntityType.DATA_STORE) {
        return null
    }
    // if parent is group, return scroll group
    if (parent.get('elementType') === ElementType.GROUP) {
        return parent.get('parent')
    }
    return parent
}

/**
 * @param delta
 */
function _moveMotionPoints(delta) {
    _visualServer.dataStore.editor.setMotionPathProps(delta, null, {delta: true, commit: false})
    _visualServer.dataStore.debounceCommitUndo()
}

/** @param {Vector2} delta */
function _moveSelection(delta) {
    /** @type {Element[]} */
    const elements = _visualServer.dataStore.selection.get('elements')
    // Not allow to move selection if includes Screen element.
    if (elements.includes(_visualServer.dataStore.workspace.children[0])) {
        return
    }

    _visualServer.dataStore.startTransaction()
    const newPos = new Vector2()
    for (const element of elements) {
        if (element.isLocked()) continue

        const parentId = getParent(element)?.get('id')
        const elementTranslate = new Vector2(...element.get('computedStyle').data.translate)
        if (parentId) {
            _visualServer.drawInfo.toWorldPosition(parentId, elementTranslate, newPos)
        } else {
            newPos.copy(elementTranslate)
        }

        const elementNewTranslate = new Vector2().copy(elementTranslate).add(delta)
        element.sets({ translateX: elementNewTranslate.x, translateY: elementNewTranslate.y })
    }
    _visualServer.dataStore.endTransaction()

    _visualServer.dataStore.debounceCommitUndo()
}
