import { BooleanOperation } from "@phase-software/types"
import Stats from '@phase-software/data-utils/src/Stats'
import { WASM, getCurrentVersion } from "../wasm/platforms/wasm"
import { Snapping } from "../actions/handles/Snapping"
import { SnappingPath } from "../actions/handles/SnappingPath"
import { Overlay } from "../overlay/Overlay"
import { CreateTileRenderer, TileSet } from '../tile_renderer/TileRenderer'
import { Viewport } from '../Viewport'
import { DrawInfo } from '../DrawInfo'
import { SpatialCache } from './SpatialCache'
import { VisualStorage } from './VisualStorage'
import {
    RenderItem,
} from './RenderItem'
import { Selection } from './Selection'

/** @typedef {import('@phase-software/data-store').DataStore} DataStore */
/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('../math').Color} Color */
/** @typedef {import('../math').Transform2D} Transform2D */
/** @typedef {import("../gfx/gfx").Gfx_Pass_t} Gfx_Pass_t */
/** @typedef {import("../gfx/gfx").Gfx_Image_t} Gfx_Image_t */
/** @typedef {import('../overlay/Overlay').Pane} Pane */
/** @typedef {import('../tile_renderer/TileRenderer').TileRenderer} TileRenderer */
/** @typedef {import("../tile_renderer/TileRenderer").Gfx_Pass_t} Gfx_Pass_t */

/** @type {VisualServer} */
let singleton = null

export class VisualServer {
    static instance() { return singleton }

    /**
     * @param {HTMLCanvasElement} canvas
     * @param {DataStore} dataStore
     * @param {boolean} useLowDPR
     */
    constructor(canvas, dataStore, useLowDPR = false) {
        this.canvas = canvas
        this.dataStore = dataStore

        this.viewport = new Viewport(canvas, useLowDPR)
        this.selection = new Selection(this)
        this.snapping = new Snapping(this)
        this.snappingPath = new SnappingPath(this)

        this.drawInfo = new DrawInfo(this)
        // TODO: see if there is better way to do this
        this.drawInfo.inject()

        /* systems */

        /** @type {VisualStorage} */
        this.storage = new VisualStorage(this)
        /** @type {Overlay} */
        this.overlay = new Overlay(this.gfx, this.storage, this.viewport)
        /** @type {TileRenderer} */
        this.tileRenderer = CreateTileRenderer(this.gfx)
        /** @type {TileSet} */
        this.renderTileset = new TileSet()
        /** @type {TileSet} */
        this.snapshotTileset = new TileSet()
        /** @type {SpatialCache} */
        this.indexer = new SpatialCache(this)
        /** @type {{ pass: Gfx_Pass_t, image: Gfx_Image_t }} */
        this.snapshotPass = {
            pass: null,
            image: null,
        }

        /** @type {RenderItem} */
        this.root = null

        /** @type {Set<RenderItem>} */
        this.updateList = new Set()

        /** @type {Record<string, RenderItem>} */
        this.itemMap = Object.create(null)

        singleton = this
    }

    currentVersion() {
        return getCurrentVersion()
    }

    /**
     * @param {import("../input-system/Action").ISEvent} e
     * @param {string} type
     * @returns {bool} canceled
     */
    fakeEvent(e, type) {
        const event = new MouseEvent(type, {
            view: window,
            bubbles: true,
            cancelable: true,
            button: 0,
            clientX: e.mousePos.x,
            clientY: e.mousePos.y
        })
        return this.canvas.dispatchEvent(event)
    }

    /**
     * @param {Color} color
     * @param {number} alpha
     */
    setBackgroundColor(color, alpha = 1) {
        WASM().setBackgroundColor(color.r, color.g, color.b, alpha)
    }

    commit() { }

    /* RenderItem API */

    clear() {
        // cleanup systems
        this.indexer.clear()
        this.tileRenderer.clear()
        this.overlay.clearPanes()
        this.storage.clear()

        // reset states
        this.selection.reset()
        this.viewport.reset()

        // cleanup node instances
        for (const id in this.itemMap) {
            this.itemMap[id].clear()
        }
        this.itemMap = Object.create(null)

        // cleanup ref containers
        this.updateList = new Set()
        this.root = null

        return true
    }

    /**
     * @param {string} id
     */
    setRoot(id) {
        const node = this.itemMap[id]
        if (!node) {
            console.error(`Cannot set root to null!`)
            return
        }

        this.root = node
        this.viewport.resize()
    }

    /**
     * @param {string} id
     * @returns {RenderItem}
     */
    makeRenderItem(id) {
        const node = new RenderItem(id)
        node.visualServer = this

        this.itemMap[id] = node

        return node
    }

    /**
     * @param {string} id
     */
    destroyRenderItem(id) {
        const item = this.itemMap[id]
        if (item) {
            item.clear()
            item.freed = true
            delete this.itemMap[id]
        }
    }

    /**
     * @param {string} id
     * @returns {RenderItem}
     */
    getRenderItem(id) {
        return this.itemMap[id] || null
    }

    /**
     * @param {Element} element
     * @returns {RenderItem}
     */
    getRenderItemOfElement(element) {
        if (!element) return null
        return this.getRenderItem(element.get('id'))
    }

    drawNodes() {
        if (!this.root) return

        const tileset = this.renderTileset

        this.tileRenderer.setViewport(
            this.viewport.x * this.viewport.pixelRatio,
            this.viewport.y * this.viewport.pixelRatio,
            this.viewport.scale * this.viewport.pixelRatio
        )

        Stats.begin("draw/build render list")
        const renderInfo = this.indexer.buildRenderList(
            this.viewport.x,
            this.viewport.y,
            this.viewport.width,
            this.viewport.height,
            this.tileRenderer.tileScaleLevel,
            this.viewport.scale,
            tileset
        )
        Stats.end("draw/build render list")
        if (!renderInfo) {
            return
        }

        const {
            startCol, startRow,
            endCol, endRow,
            level,
            groups,
        } = renderInfo

        if (groups.length) {
            this.tileRenderer.composeTiles(tileset, groups)
        }
        this.tileRenderer.drawTiles(tileset, startCol, startRow, endCol, endRow, level)

        // if (this.debug.active) {
        //     this.debug.tileRange.startCol = startCol
        //     this.debug.tileRange.startRow = startRow
        //     this.debug.tileRange.endCol = endCol
        //     this.debug.tileRange.endRow = endRow
        //     this.debug.tileRange.level = level
        // }
    }

    /**
     * @typedef Options
     * @property {"scale" | "innerBox" | "outerBox"} [mode]
     * @property {number} [customScale]
     * @property {number} [customWidth]
     * @property {number} [customHeight]
     * @property {boolean} [ignoreRoot]
     * @property {Color} [clearColor]
     *
     * @param {string} rootID
     * @param {Options} options
     */
    drawNodesForSnapshot(rootID, { ignoreRoot = false, clearColor } = {}) {
        let root = this.indexer.getNode(rootID)
        if (ignoreRoot) {
            root = root?.getFirstChild()
        }

        if (!root) {
            return {
                width: 0,
                height: 0,
                pixels: null,
            }
        }

        // we only support screen area as export size no matter it's clipping or not
        const { x, y, width, height } = root.boundsWorldAABB
        const scale = 2
        const zoom = 1
        this.tileRenderer.setViewport(-x * scale, -y * scale, zoom * scale)

        // build commands and compose tiles
        const tileset = this.snapshotTileset
        const renderInfo = this.indexer.buildRenderList(
            -x, -y, width, height,
            this.tileRenderer.tileScaleLevel,
            zoom,
            tileset,
            root.id
        )
        if (!renderInfo) {
            return {
                width: 0,
                height: 0,
                pixels: null,
            }
        }

        const {
            startCol, startRow,
            endCol, endRow,
            level,
            groups,
        } = renderInfo

        if (groups.length) {
            this.tileRenderer.composeSnapshotTiles(tileset, groups, clearColor)
        }
        this.tileRenderer.drawSnapshotTiles(tileset, startCol, startRow, endCol, endRow, level)

        return {
            x, y, width, height
        }
    }

    /**
     * @param {number} width
     * @param {number} height
     */
    prepareSnapshotPass(width, height) {
        // eslint-disable-next-line no-unused-vars
        const { pass, image } = this.snapshotPass
        if (image && image.cmn.width === width && image.cmn.height === height) {
            return this.snapshotPass
        }

        // const { gfx } = this
        // if (image) {
        //     gfx.destroyImage(image)
        // }
        // if (pass) {
        //     gfx.destroyPass(pass)
        // }

        // this.snapshotPass.image = gfx.makeImage({
        //     label: `snapshot`,
        //     renderTarget: true,
        //     width,
        //     height,
        //     pixelFormat: Gfx_PixelFormat.RGBA8,
        //     minFilter: Gfx_Filter.LINEAR,
        //     magFilter: Gfx_Filter.LINEAR,
        //     wrapU: Gfx_Wrap.CLAMP_TO_EDGE,
        //     wrapV: Gfx_Wrap.CLAMP_TO_EDGE,
        // })
        // this.snapshotPass.pass = gfx.makePass({
        //     label: `snapshot`,
        //     colorAttachments: [
        //         { image: this.snapshotPass.image },
        //     ],
        // })
        return this.snapshotPass
    }

    /**
     * @param {string} id
     */
    updateTransformRecursively(id) {
        const item = this.indexer.nodeMap.get(id)
        if (!item) return
        this.indexer.updateTransformRecursively(item)
        this.selection.updateBounds()
        return true
    }

    _isBooleanMaskContainer(node) {
        const element = node.visualServer.dataStore.getElement(node.id)
        const booleanType = element.get('booleanType')
        return booleanType !== undefined && booleanType !== BooleanOperation.NONE
    }

    /**
     * Iterates over world bounds of nodes specified by list of IDs
     * @param {Iterable<string>} ids
     */
    *_getWorldBoundsOf(ids) {
        for (const id of ids) {
            yield this.indexer.getNode(id).boundsWorldVisualZeroAABB
        }
    }
}
