import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router'

import { Reference, StoreObject, useApolloClient } from '@apollo/client'
import { LoadingStatus } from '@phase-software/types'

import { ProjectFieldsFragment } from '../../generated/graphql'
import useHeapAnalytics from '../../hooks/useHeapAnalytics'
import { useWorkspaceActions } from '../../hooks/useWorkspaceActions'
import { useSetNotification } from '../../providers/NotificationProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { WORKSPACE_PROJECT } from '../../routeConstants'
import { track } from '../../services/heapAnalytics'
import { getWorkspaceDraftsPath } from '../../utils/pathGenerators'
import { Dialog, Text } from '../shared'

type DeleteProjectDialogProps = {
  id: ProjectFieldsFragment['id']
  name: ProjectFieldsFragment['name']
  open: boolean
  onClose: () => void
}

const DeleteProjectDialog = ({ id, name, open, onClose }: DeleteProjectDialogProps) => {
  const { t } = useTranslation(['workspace', 'common'])
  const client = useApolloClient()
  const history = useHistory()
  const { workspaceData } = useWorkspaceContext()
  const match = useRouteMatch(WORKSPACE_PROJECT) as {
    params?: { projectId: string; workspaceSlug: string }
  }
  const { deleteProject } = useWorkspaceActions(workspaceData.type, workspaceData.id)
  const { addNotification } = useSetNotification()
  const { space, teamName } = useHeapAnalytics()

  const [isDeleting, setIsDeleting] = useState(false)

  const handleDelete = async () => {
    if (isDeleting) return
    setIsDeleting(true)
    try {
      await deleteProject(id)
      client.cache.evict({ id: `projects:${id}` })
      client.cache.modify({
        fields: {
          files(existingFileList, { readField }) {
            return existingFileList.filter(
              (fileRef: Reference | StoreObject | undefined) => id !== readField('project_id', fileRef)
            )
          }
        }
      })

      addNotification({
        type: 'success',
        content: (
          <Trans
            i18nKey="workspace:deleted"
            values={{ name }}
            components={[<span key="project-deleted" className="font-semibold" />]}
          />
        )
      })

      track('Project Deleted', { projectId: id, space, teamName })
      onClose()

      if (match?.params?.projectId === id && match?.params?.workspaceSlug) {
        history.push(getWorkspaceDraftsPath(workspaceData.type, workspaceData.slug))
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsDeleting(false)
    }
  }
  return (
    <Dialog
      data-test-id="delete-confirm-dialog"
      size="xs"
      title={t('delete_project')}
      dangerConfirmBtnText={t('delete')}
      cancelBtnText={t('common:button.cancel')}
      onDangerConfirm={handleDelete}
      onCancel={onClose}
      disableDangerConfirm={isDeleting}
      showProgressButton
      progressStatus={isDeleting ? LoadingStatus.WAITING : LoadingStatus.INITIAL}
      open={open}
    >
      <Text ellipsis={false} className="font-medium break-all">
        <Trans
          i18nKey="workspace:delete_project_confirmation"
          values={{ name }}
          components={[
            <span
              key="delete-project-confirmation"
              className="text-white font-semibold"
              data-test-id="delete-project-confirmation"
            />
          ]}
        >
          <span className="text-white font-semibold">{name}</span> deleted
        </Trans>
      </Text>
    </Dialog>
  )
}

export default DeleteProjectDialog
