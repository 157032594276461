import { useEffect } from 'react'
import { Mode } from '@phase-software/types'
import { useDataStore } from '../../providers/dataStore/DataStoreProvider'
import { useSetUI } from '../../providers/dataStore/UIProvider'

const SubscribeModeChange = () => {
  const dataStore = useDataStore()
  const setUI = useSetUI()

  useEffect(() => {
    if (!dataStore) return
    let mode = Mode.DESIGN
    const updateMode = (newMode) => {
      mode = newMode
      setUI((s) => ({
        ...s,
        mode
      }))
    }
    const updateState = (newState) => {
      setUI((s) => ({
        ...s,
        isEditingState: newState === 'EDITING',
        isVersioningState: newState === 'VERSIONING',
        isInspectingState: newState === 'INSPECTING'
      }))
    }

    dataStore.on('mode', updateMode)
    dataStore.on('state', updateState)
    return () => {
      dataStore.off('mode', updateMode)
      dataStore.off('state', updateState)
    }
  }, [dataStore, setUI])

  return null
}

export default SubscribeModeChange
