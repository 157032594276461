import { CapShape, TrimPathMode } from '@phase-software/types'

/** @typedef {import('../dino').LottieBezierData} LottieBezierData */
/** @typedef {import('../dino').DemoData} DemoData */

/** @type {LottieBezierData} */
const starData = {
    "i": [
        [
            1.275,
            -0.001
        ],
        [
            0.284,
            1.242
        ],
        [
            9.72,
            2.16
        ],
        [
            -0.327,
            1.467
        ],
        [
            -1.03,
            0.23
        ],
        [
            -2.2,
            9.73
        ],
        [
            -1.469,
            -0.34
        ],
        [
            -0.235,
            -1.016
        ],
        [
            -9.73,
            -2.15
        ],
        [
            0.34,
            -1.47
        ],
        [
            1.017,
            -0.234
        ],
        [
            2.16,
            -9.729
        ]
    ],
    "o": [
        [
            -1.274,
            -0.005
        ],
        [
            -2.2,
            -9.729
        ],
        [
            -1.466,
            -0.326
        ],
        [
            0.229,
            -1.03
        ],
        [
            9.72,
            -2.2
        ],
        [
            0.339,
            -1.469
        ],
        [
            1.017,
            0.234
        ],
        [
            2.2,
            9.73
        ],
        [
            1.469,
            0.339
        ],
        [
            -0.234,
            1.017
        ],
        [
            -9.73,
            2.2
        ],
        [
            -0.28,
            1.244
        ]
    ],
    "v": [
        [
            -0.002,
            18.072
        ],
        [
            -2.662,
            15.943
        ],
        [
            -15.812,
            2.783
        ],
        [
            -17.876,
            -0.464
        ],
        [
            -15.812,
            -2.527
        ],
        [
            -2.662,
            -15.688
        ],
        [
            0.612,
            -17.732
        ],
        [
            2.658,
            -15.688
        ],
        [
            15.818,
            -2.538
        ],
        [
            17.863,
            0.738
        ],
        [
            15.818,
            2.783
        ],
        [
            2.658,
            15.943
        ]
    ],
    "c": true,
}

/** @type {LottieBezierData} */
const lineBGData = {
    "i": [
        [
            128,
            -52
        ],
        [
            157.035,
            0
        ],
        [
            108.926,
            -56.701
        ],
        [
            0,
            0
        ],
        [
            0,
            0
        ],
        [
            0,
            0
        ],
        [
            21.466,
            28.622
        ]
    ],
    "o": [
        [
            -128,
            52
        ],
        [
            -148,
            0
        ],
        [
            -146,
            76
        ],
        [
            0,
            0
        ],
        [
            0,
            0
        ],
        [
            0,
            0
        ],
        [
            -48,
            -64
        ]
    ],
    "v": [
        [
            272,
            -297.5
        ],
        [
            -14,
            68.5
        ],
        [
            -304,
            -15.5
        ],
        [
            -530,
            180.5
        ],
        [
            -530,
            460.5
        ],
        [
            496,
            460.5
        ],
        [
            510,
            -115.5
        ]
    ],
    "c": true,
}

/** @type {LottieBezierData} */
const lineData = {
    "i": [
        [
            0,
            0
        ],
        [
            -146,
            76
        ],
        [
            -148,
            0
        ],
        [
            -128,
            52
        ],
        [
            -48,
            -64
        ]
    ],
    "o": [
        [
            0,
            0
        ],
        [
            108.926,
            -56.701
        ],
        [
            157.035,
            0
        ],
        [
            128,
            -52
        ],
        [
            21.466,
            28.622
        ]
    ],
    "v": [
        [
            -530,
            180.5
        ],
        [
            -304,
            -15.5
        ],
        [
            -14,
            68.5
        ],
        [
            272,
            -297.5
        ],
        [
            510,
            -115.5
        ]
    ],
    "c": false,
}

/** @type {DemoData} */
export const TrimPathExample1 = {
    name: "Trim Path Golden Example 1",
    width: 880,
    height: 880,

    tree: [
        {
            name: 'star',
            shape: {
                type: 'path',
                path: starData,
            },
            fills: [
                [1, 0.770244538784, 0.038924634457, 1],
            ],
            transform: {
                scale: 0.88,
                position: [500, 500],
            },
        },
        {
            name: 'line1',
            shape: {
                type: 'path',
                path: 'M550,500 L596.5925826289068,525.8819045102521 M538.3022221559489,532.1393804843269 L557.3576436351046,581.9152044288992 M508.6824088833465,549.2403876506104 L491.2844257252342,599.6194698091746 M475,543.3012701892219 L429.28932188134524,570.7106781186548 M453.01536896070456,517.1010071662835 L400.3805301908254,508.7155742747658 M453.01536896070456,482.8989928337166 L418.0847955711008,442.64235636489536 M475,456.6987298107781 L474.1180954897479,403.4074173710932 M508.6824088833465,450.7596123493896 L542.2618261740699,409.36922129633496 M538.3022221559489,467.860619515673 L590.630778703665,457.73817382593',
            },
            strokes: [
                {
                    lineWidth: 16,
                    paint: {
                        gradientType: "radial",
                        colorStops: [
                            { position: 0.6, color: [1, 0.770244538784, 0.038924634457, 1] },
                            { position: 1.0, color: [0.33, 0.84, 0.93, 1] },
                        ],
                    },
                    cap: CapShape.ROUND,
                },
            ],
            trim: {
                type: TrimPathMode.SIMULTANEOUSLY,
            },
            visible: false,
            transform: {
                scale: 0.88,
            },
        },
    ],
    animation: {
        tracks: [
            {
                node: 'star',
                key: 'transform.position',
                kfs: [
                    { time: 0, value: [880 * 0.2, 880 * 1.2], frameIn: [0.667, 1], frameOut: [0.333, 0] },
                    { time: 1, value: [440, 440] },
                ],
            },
            {
                node: 'star',
                key: 'transform.scale',
                kfs: [
                    { time: 0, value: [0, 0], frameIn: [0.667, 1], frameOut: [0.333, 0] },
                    { time: 0.8, value: [0.3, 0.3], frameIn: [0.667, 1], frameOut: [0.333, 0] },
                    { time: 1.5, value: [1.6, 1.6] },
                ],
            },
            {
                node: 'star',
                key: 'opacity.value',
                kfs: [
                    { time: 0.8, value: 1, frameIn: [0.667, 1], frameOut: [0.333, 0] },
                    { time: 1.5, value: 0 },
                ],
            },
            // {
            //     node: 'line1',
            //     key: 'transform.position',
            //     kfs: [
            //         { time: 0, value: [-880 * 0.3, 500 * 1.2], frameIn: [0.667, 1], frameOut: [0.333, 0] },
            //         { time: 1, value: [0, 0] },
            //     ],
            // },
            {
                node: 'line1',
                key: 'visible',
                kfs: [
                    { time: 0, value: false },
                    { time: 0.8, value: true },
                ]
            },
            {
                node: 'line1',
                key: 'trim.begin',
                kfs: [
                    { time: 1.3, value: 0, frameIn: [0.667, 1], frameOut: [0.333, 0] },
                    { time: 1.5, value: 1 },
                ],
            },
            {
                node: 'line1',
                key: 'trim.end',
                kfs: [
                    { time: 0.85, value: 0, frameIn: [0.667, 1], frameOut: [0.333, 0] },
                    { time: 1.5,  value: 1},
                ],
            },
        ],
    },
}

/** @type {DemoData} */
export const Example1_variant = {
    name: "Trim Path Golden Example 1 (individual)",
    width: 880,
    height: 880,

    tree: [
        {
            name: 'star',
            shape: {
                type: 'path',
                path: starData,
            },
            fills: [
                [ 1, 0.770244538784, 0.038924634457, 1 ],
            ],
            transform: {
                scale: 0.88,
                position: [500, 500],
            },
        },
        {
            name: 'line1',
            shape: {
                type: 'path',
                path: 'M550,500 L596.5925826289068,525.8819045102521 M538.3022221559489,532.1393804843269 L557.3576436351046,581.9152044288992 M508.6824088833465,549.2403876506104 L491.2844257252342,599.6194698091746 M475,543.3012701892219 L429.28932188134524,570.7106781186548 M453.01536896070456,517.1010071662835 L400.3805301908254,508.7155742747658 M453.01536896070456,482.8989928337166 L418.0847955711008,442.64235636489536 M475,456.6987298107781 L474.1180954897479,403.4074173710932 M508.6824088833465,450.7596123493896 L542.2618261740699,409.36922129633496 M538.3022221559489,467.860619515673 L590.630778703665,457.73817382593',
            },
            strokes: [
                {
                    lineWidth: 16,
                    paint: {
                        gradientType: "radial",
                        colorStops: [
                            { position: 0.6, color: [1, 0.770244538784, 0.038924634457, 1] },
                            { position: 1.0, color: [0.33, 0.84, 0.93, 1] },
                        ],
                    },
                    cap: "round",
                },
            ],
            trim: {
                type: "individual",
            },
            visible: false,
            transform: {
                scale: 0.88,
            },
        },
    ],
    animation: {
        tracks: [
            {
                node: 'star',
                key: 'transform.position',
                kfs: [
                    { time: 0, value: [880 * 0.2, 880 * 1.2], frameIn: [0.667, 1], frameOut: [0.333, 0] },
                    { time: 1, value: [440, 440] },
                ],
            },
            {
                node: 'star',
                key: 'transform.scale',
                kfs: [
                    { time: 0, value: 0, frameIn: [0.667, 1], frameOut: [0.333, 0] },
                    { time: 0.8, value: 0.3, frameIn: [0.667, 1], frameOut: [0.333, 0] },
                    { time: 1.5, value: 1.6 },
                ],
            },
            {
                node: 'star',
                key: 'opacity.value',
                kfs: [
                    { time: 0.8, value: 1, frameIn: [0.667, 1], frameOut: [0.333, 0] },
                    { time: 1.5, value: 0 },
                ],
            },
            // {
            //     node: 'line1',
            //     key: 'transform.position',
            //     kfs: [
            //         { time: 0, value: [-880 * 0.3, 500 * 1.2], frameIn: [0.667, 1], frameOut: [0.333, 0] },
            //         { time: 1, value: [0, 0] },
            //     ],
            // },
            {
                node: 'line1',
                key: 'visible',
                kfs: [
                    { time: 0, value: false },
                    { time: 0.8, value: true },
                ]
            },
            {
                node: 'line1',
                key: 'trim.begin',
                kfs: [
                    { time: 1.3, value: 0, frameIn: [0.667, 1], frameOut: [0.333, 0] },
                    { time: 1.5, value: 1 },
                ],
            },
            {
                node: 'line1',
                key: 'trim.end',
                kfs: [
                    { time: 0.85, value: 0, frameIn: [0.667, 1], frameOut: [0.333, 0] },
                    { time: 1.5,  value: 1},
                ],
            },
        ],
    },
}

/** @type {DemoData} */
export const TrimPathExample2 = {
    name: "Trim Path Golden Example 2",
    width: 880,
    height: 880,

    tree: [
        {
            name: 'background',
            shape: {
                type: 'path',
                path: lineBGData,
            },
            fills: [
                [0.261810660362, 0.261810660362, 0.261810660362, 1],
            ],
            transform: {
                position: [384, 564.5],
            },
        },
        {
            name: 'curveLine',
            shape: {
                type: 'path',
                path: lineData,
            },
            strokes: [
                {
                    lineWidth: 29,
                    paint: [0.99428914388, 1, 0.28581495098, 1],
                    dashPattern: [3, 1],
                },
            ],
            transform: {
                position: [384, 564.5],
            },
        },
        {
            name: 'graph',
            shape: {
                type: 'path',
                path: 'M125,1000 L125,530 M370,1000 L370,630 M700,1000 L700,260',
            },
            strokes: [
                { lineWidth: 122, paint: '#00FFFF' },
            ],
        },
        {
            name: 'frameLine',
            shape: {
                type: 'rect',
                width: 848,
                height: 848,
            },
            strokes: [{
                lineWidth: 32,
                paint: [1, 0.615928619983, 0.28581495098, 1],
                cap: CapShape.ROUND,
            }],
            transform: {
                position: [16, 16],
            },
        },
    ],
    animation: {
        tracks: [
            {
                node: 'curveLine',
                key: 'trim.end',
                kfs: [
                    { time: 0, value: 0, frameIn: [0.833, 1], frameOut: [0.167, 0] },
                    { time: 5, value: 1 },
                ],
            },
            {
                node: 'graph',
                key: 'trim.mode',
                kfs: [
                    { time: 0, value: 'individual' },
                ],
            },
            {
                node: 'graph',
                key: 'trim.end',
                kfs: [
                    { time: 0, value: 0, frameIn: [0.833, 0.833], frameOut: [0.167, 0.167] },
                    { time: 2.5, value: 0, frameIn: [0.833, 0.833], frameOut: [0.167, 0.167] },
                    { time: 5, value: 1 },
                ],
            },
            {
                node: 'frameLine',
                key: 'setVisible()',
                kfs: [
                    { time: 0, value: false },
                    { time: 5, value: true },
                ],
            },
            {
                node: 'frameLine',
                key: 'trim.begin',
                kfs: [
                    { time: 5, value: 0, frameIn: [0.833, 0.833], frameOut: [0.167, 0.167] },
                ],
            },
            {
                node: 'frameLine',
                key: 'trim.end',
                kfs: [
                    { time: 5, value: 0, frameIn: [0.833, 0.833], frameOut: [0.167, 0.167] },
                    { time: 6, value: 0.1 },
                ],
            },
            {
                node: 'frameLine',
                key: 'trim.offset',
                kfs: [
                    { time: 5, value: 0.6 },
                    { time: 6, value: 0.6 },
                    { time: 15, value: 1.6 },
                ],
            },
        ],
    },
}

/** @type {DemoData} */
export const TrimPathExample3 = {
    name: "Trim Path Golden Example 3",
    width: 880,
    height: 880,

    tree: [
        {
            name: 'curveLine',
            shape: {
                type: 'path',
                path: lineData,
            },
            strokes: [
                {
                    lineWidth: 29,
                    paint: '#FFFFFF',
                    cap: CapShape.TRIANGLE_ARROW_SOLID,
                },
            ],
            transform: {
                position: [384, 564.5],
            },
        }
    ],
    animation: {
        tracks: [
            {
                node: 'curveLine',
                key: 'trim.end',
                kfs: [
                    { time: 0, value: 0, frameIn: [0.833, 0.833], frameOut: [0.167, 0.167] },
                    { time: 5, value: 0.5 },
                ],
            },
        ],
    },
}
