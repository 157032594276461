import { EventEmitter } from 'eventemitter3'
import { interpret } from 'xstate/lib/interpreter'
import { Vector2 } from '../math'
import { machine } from './states'
import { KEYBOARD_CODE_MAP_KEY, IS_MACINTOSH } from './constants'

/** @typedef {import('./Action').Action} Action */
/** @typedef {import('./KeyCombo').KeyCombo} KeyCombo */

const modifyKeySet = new Set(['Alt', 'Shift', 'Modifier', 'Control'])

// const CAN_START_DRAG = 3

const shouldSkip = (e) => {
    const target = e.target
    const targetIsInput = target.tagName === 'INPUT' || target.tagName === 'TEXTAREA'
    if (targetIsInput || target.tagName === 'BUTTON') {
        if (!target.classList.contains('input-system-handle-event') ||
            target.classList.contains('input-system-no-handle')) {
            return true
        }
    }
}

export class Manager extends EventEmitter {
    /**
     * @param {Element|Window} [targetElement]
     */
    constructor(targetElement = window) {
        super()

        this._paused = false
        this._targetElement = null

        /** @type {Map<string, Action>} */
        this._actions = new Map()
        /** @type {Map<string, Action[]>} */
        this._keys = new Map()
        /** @type {Map<symbol, Action[]>} */
        this._groups = new Map()
        /** @type {Set<string>} */
        this._key = null
        this._mouse = null
        this._modifiers = {
            modifier: false, // ctrl / command (macOS)
            ctrl: false,
            shift: false,
            alt: false
        }

        /** @type {Set<Action>} */
        this._activeActionSet = new Set()

        this._lastLeftClick = {
            timestamp: 0,
            pos: new Vector2()
        }
        this._lastMouseMove = {
            event: null,
            pos: new Vector2()
        }

        this._targetKeyDownBinding = null
        this._targetKeyUpBinding = null
        this._targetMouseDownBinding = null
        this._targetMouseMoveBinding = null
        this._targetMouseUpBinding = null
        this._targetMouseWheelBinding = null
        this._targetTouchStartBinding = null
        this._targetTouchMoveBinding = null
        this._targetTouchEndBinding = null
        this._targetResetBinding = null

        this._hoverElementsBoundsMap = new Map()

        /** @type {AddEventListenerOptions} */
        this._eventOpts = { passive: false }

        this.mousePos = new Vector2()
        this.wheelDelta = new Vector2()
        this.dataTransfer = {
            files: [],
            items: []
        }

        /** @type {import('./Action').ISEvent} */
        this.event = {
            handled: true,
            mousePos: this.mousePos,
            wheelDelta: this.wheelDelta,
            dataTransfer: this.dataTransfer,
        }

        this.service = interpret(machine).start()

        this.ro = new ResizeObserver(() => {
            this._clearHoverElementsBounds()
        })
        this.watch(targetElement)
    }

    /**
     * @param {Action} action
     */
    addAction(action) {
        this._actions.set(action.name, action)
        this._updateActions()
    }

    /**
     * @param {string} name
     * @returns {Action}
     */
    getAction(name) {
        return this._actions.get(name)
    }

    /**
     * @param {Action} action
     */
    removeAction(action) {
        this._actions.delete(action.name)
    }

    /**
     * @param {Element|Window} targetElement
     */
    watch(targetElement) {
        this.stop()

        this._targetElement = targetElement

        /** @type {(e: KeyboardEvent) => void} */
        this._targetKeyDownBinding = e => {
            if (!e.key) return
            if (shouldSkip(e)) {
                return
            }

            const key = this.preprocessKeyboardEvent(e)
            this.checkModifierState(e)

            this._key = key
            if (modifyKeySet.has(key)) {
                this._key = null

                // prevent repeat firing modifier key
                if (e.repeat) {
                    return
                }
                this.emit('MODIFIER_KEY_CHANGES', this._modifiers)
            }

            this._trigger(this._key, e)

        }

        /** @type {(e: KeyboardEvent) => void} */
        this._targetKeyUpBinding = e => {
            if (!e.key) return

            const key = this.preprocessKeyboardEvent(e)
            this.checkModifierState(e)

            // in macOS the release non-modifier key will not fire keyup event if you hold command,
            // so the key should be reset if release any modifier key
            if (this._key === key || modifyKeySet.has(key)) {
                this._key = null
                if (modifyKeySet.has(key)) {
                    this.emit('MODIFIER_KEY_CHANGES', this._modifiers)
                }
            }
            this._trigger(this._key, e)


        }
        window.addEventListener('keydown', this._targetKeyDownBinding, this._eventOpts)
        window.addEventListener('keyup', this._targetKeyUpBinding, this._eventOpts)

        const mouseButtonNames = {
            0: 'LeftClick',
            1: 'MiddleClick',
            2: 'RightClick'
        }
        /** @type {Set<string>} */
        let pressedMouseButton = null
        const startPos = { x: 0, y: 0 }
        /** @type {(e: MouseEvent) => void} */
        this._targetMouseDownBinding = e => {
            this._setMousePosition(e)
            startPos.x = e.clientX
            startPos.y = e.clientY

            const name = mouseButtonNames[e.button]
            pressedMouseButton = e.button
            this._mouse = name
            this._trigger(name, e)
        }
        /** @type {(e: MouseEvent) => void} */
        this._targetMouseMoveBinding = e => {
            this._setMousePosition(e)

            const isRightTarget = e.target === this._targetElement
            if (pressedMouseButton === null) {
                this._mouse = null
                this._trigger('MouseMove', e, isRightTarget)
            } else {
                // If the distance from mouse down is smaller than 3 px, then we would not fire Move event.
                // commit this feature for now because it could make scale or resize teleport a little bit.
                // if (distance(startPos.x, startPos.y, e.clientX, e.clientY) > CAN_START_DRAG) {
                const name = mouseButtonNames[pressedMouseButton]
                this._mouse = `${name}Move`
                this._trigger(`${name}Move`, e)
                // }
            }
        }
        /** @type {(e: MouseEvent) => void} */
        this._targetMouseUpBinding = e => {
            if (pressedMouseButton !== e.button) {
                return
            }
            this._setMousePosition(e)
            pressedMouseButton = null
            const name = mouseButtonNames[e.button]
            const isRightTarget = e.target === this._targetElement

            this._mouse = null

            this._trigger(`${name}Up`, e, isRightTarget)
            this._trigger(this._mouse, e, isRightTarget)

            if (name === 'LeftClick') {
                const now = performance.now()
                if (
                    now - this._lastLeftClick.timestamp < 500 &&
                    this._lastLeftClick.pos.equals(this.mousePos)
                ) {
                    this._lastLeftClick.timestamp = 0
                    this._trigger('DoubleLeftClick', e, isRightTarget)

                } else {
                    this._lastLeftClick.timestamp = now
                    this._lastLeftClick.pos.copy(this.mousePos)
                }
            }

        }
        this._targetElement.addEventListener('mousedown', this._targetMouseDownBinding, this._eventOpts)

        window.addEventListener('mousemove', this._targetMouseMoveBinding, this._eventOpts)
        window.addEventListener('mouseup', this._targetMouseUpBinding, this._eventOpts)

        /** @type {(e: WheelEvent) => void} */
        this._targetMouseWheelBinding = e => {
            this.checkModifierState(e)
            if (e.deltaX !== 0) {
                const keyCode = Math.sign(e.deltaX) === 1 ? 'WheelXPositive' : 'WheelXNegative'
                this.wheelDelta.set(e.deltaX, 0)
                this._trigger(keyCode, e)
            }
            if (e.deltaY !== 0) {
                const keyCode = Math.sign(e.deltaY) === 1 ? 'WheelYPositive' : 'WheelYNegative'
                this.wheelDelta.set(0, e.deltaY)
                this._trigger(keyCode, e)
            }
        }
        this._targetElement.addEventListener('wheel', this._targetMouseWheelBinding, this._eventOpts)

        /** @type {number} */
        let _touchId = null

        /** @type {(e: TouchEvent) => void} */
        this._targetTouchStartBinding = e => {
            if (_touchId !== null) this._targetTouchEndBinding(e)
            if (e.targetTouches.length !== 1) return
            _touchId = e.targetTouches[0].identifier

            this._setMousePosition(e)

            const name = mouseButtonNames[0]
            pressedMouseButton = name
            this._key = name
            this._trigger(name, e)
        }
        /** @type {(e: TouchEvent) => void} */
        this._targetTouchMoveBinding = e => {
            if (e.targetTouches[0].identifier !== _touchId) return

            this._setMousePosition(e)

            const name = mouseButtonNames[0]
            if (pressedMouseButton === name) {
                this._trigger(name, e)
            }
        }
        /** @type {(e: TouchEvent) => void} */
        this._targetTouchEndBinding = e => {
            if (_touchId === null) return
            _touchId = null

            this._setMousePosition(e)

            const name = mouseButtonNames[0]
            pressedMouseButton = null
            this._key = null
            this._trigger(name, e, true)
        }
        this._targetElement.addEventListener('touchstart', this._targetTouchStartBinding, this._eventOpts)
        this._targetElement.addEventListener('touchmove', this._targetTouchMoveBinding, this._eventOpts)
        this._targetElement.addEventListener('touchend', this._targetTouchEndBinding, this._eventOpts)

        this._targetDragEnterBinding = e => {
            this._setMousePosition(e)

            const isRightTarget = e.target === this._targetElement
            this._trigger('DragOver', e, isRightTarget)
        }
        this._targetDragOverBinding = e => {
            e.preventDefault()
            this._setMousePosition(e)

            const isRightTarget = e.target === this._targetElement
            this._trigger('DragOver', e, isRightTarget)
        }
        this._targetDragLeaveBinding = e => {
            this._setMousePosition(e)

            const isRightTarget = e.target === this._targetElement
            this._trigger('DragEnd', e, isRightTarget)
        }
        this._targetDropBinding = e => {
            e.preventDefault()
            this._setDataTransfer(e)
            this._setMousePosition(e)

            const isRightTarget = e.target === this._targetElement
            this._trigger('Drop', e, isRightTarget)
        }

        document.body.addEventListener('dragenter', this._targetDragEnterBinding, this._eventOpts)
        document.body.addEventListener('dragleave', this._targetDragLeaveBinding, this._eventOpts)
        document.body.addEventListener('dragover', this._targetDragOverBinding, this._eventOpts)
        window.addEventListener('drop', this._targetDropBinding, this._eventOpts)

        this._pasteBinding = e => {
            if (shouldSkip(e)) {
                return
            }
            this._setDataTransfer(e)
            this._trigger('Paste', e)
        }
        this._copyBinding = e => {
            if (shouldSkip(e)) {
                return
            }
            this._setDataTransfer(e)
            this._trigger('Copy', e)
        }

        document.addEventListener('paste', this._pasteBinding, this._eventOpts)
        document.addEventListener('copy', this._copyBinding, this._eventOpts)

        /** @type {EventListener} */
        this._targetResetBinding = () => {
            this.releaseAllKeys()
        }
        this._targetElement.addEventListener('focus', this._targetResetBinding, this._eventOpts)
        this._targetElement.addEventListener('blur', this._targetResetBinding, this._eventOpts)
        document.addEventListener("visibilitychange", this._targetResetBinding, this._eventOpts)


        if (this._targetElement instanceof Element) {
            this.ro.observe(this._targetElement)
        }
    }

    stop() {
        if (!this._targetElement) return

        window.removeEventListener('keydown', this._targetKeyDownBinding, this._eventOpts)
        window.removeEventListener('keyup', this._targetKeyUpBinding, this._eventOpts)

        this._targetElement.removeEventListener('mousedown', this._targetMouseDownBinding, this._eventOpts)
        window.removeEventListener('mousemove', this._targetMouseMoveBinding, this._eventOpts)
        window.removeEventListener('mouseup', this._targetMouseUpBinding, this._eventOpts)
        this._targetElement.removeEventListener('wheel', this._targetMouseWheelBinding, this._eventOpts)

        this._targetElement.removeEventListener('touchstart', this._targetTouchStartBinding, this._eventOpts)
        this._targetElement.removeEventListener('touchmove', this._targetTouchMoveBinding, this._eventOpts)
        this._targetElement.removeEventListener('touchend', this._targetTouchEndBinding, this._eventOpts)

        document.body.removeEventListener('dragenter', this._targetDragEnterBinding, this._eventOpts)
        document.body.removeEventListener('dragleave', this._targetDragLeaveBinding, this._eventOpts)
        document.body.removeEventListener('dragover', this._targetDragOverBinding, this._eventOpts)
        window.removeEventListener('drop', this._targetDropBinding, this._eventOpts)

        document.removeEventListener('paste', this._pasteBinding, this._eventOpts)
        document.removeEventListener('copy', this._copyBinding, this._eventOpts)

        this._targetElement.removeEventListener('focus', this._targetResetBinding, this._eventOpts)
        this._targetElement.removeEventListener('blur', this._targetResetBinding, this._eventOpts)
        document.removeEventListener("visibilitychange", this._targetResetBinding, this._eventOpts)

        this.ro.disconnect()

        this._targetElement = null
    }

    endActiveAction() {
        const currentKeyCombo = this._getKeyCombo(this._key)
        this._activeActionSet.forEach(action => {
            if (!this._key || !action.canBeActive(currentKeyCombo)) {
                action.end()
                this._activeActionSet.delete(action)
            }
        })
    }

    releaseAllKeys() {
        if (this._paused) return
        this._key = null
        this._mouse = null
        this._modifiers = {
            modifier: false,
            ctrl: false,
            shift: false,
            alt: false
        }
        this.emit('MODIFIER_KEY_CHANGES', this._modifiers)
        this.endActiveAction()
    }

    pause() {
        if (this._paused) return
        this.releaseAllKeys()
        this._paused = true
    }

    resume() {
        this._paused = false
    }

    reset() {
        this.releaseAllKeys()
        this._actions.clear()
    }

    /**
     * @param {MouseEvent|TouchEvent} event
     */
    _setMousePosition(event) {
        if (event instanceof MouseEvent) {
            this.mousePos.set(event.clientX, event.clientY)
        } else {
            const touch = event.changedTouches[0]
            this.mousePos.set(touch.clientX, touch.clientY)
        }
        if (this._targetElement instanceof Element) {
            if (this._hoverElementsBoundsMap.has(this._targetElement)) {
                const box = this._hoverElementsBoundsMap.get(this._targetElement)
                this.mousePos.sub(box)
            } else {
                const box = this._targetElement.getBoundingClientRect()
                this._hoverElementsBoundsMap.set(this._targetElement, box)
                this.mousePos.sub(box)
            }
        }
    }

    _setDataTransfer(event) {
        switch (event.type) {
            case 'drop': {
                this.dataTransfer.files = [...event.dataTransfer.files]
                this.dataTransfer.items = []
                break
            }
            case 'copy': {
                this.dataTransfer.files = []
                this.dataTransfer.items = []
                break
            }
            case 'paste': {
                this.dataTransfer.files = [...event.clipboardData.files]
                this.dataTransfer.items = [...event.clipboardData.items]
                break
            }
        }

    }

    _clearHoverElementsBounds() {
        this._hoverElementsBoundsMap.clear()
    }

    _updateActions() {
        this._keys.clear()
        this._groups.clear()

        for (const [, action] of this._actions) {
            for (const kC of action._keyCombos) {
                const existing = this._keys.get(kC.triggerKeyCode)
                if (existing) {
                    existing.push(action)
                } else {
                    this._keys.set(kC.triggerKeyCode, [action])
                }
            }

            if (action.group) {
                const existing = this._groups.get(action.group)
                if (existing) {
                    existing.push(action)
                } else {
                    this._groups.set(action.group, [action])
                }
            }
        }

        /** @param {Map<any, Action[]>} map */
        const sortInnerGroups = (map) => {
            for (const [, group] of map) {
                group.sort((a, b) => {
                    if (b.state.level !== a.state.level) {
                        return b.state.level - a.state.level
                    }
                    return a.fallback - b.fallback
                })
            }
        }
        sortInnerGroups(this._keys)
        sortInnerGroups(this._groups)

        const special = new Set(['LeftClickMove', 'MiddleClickMove', 'RightClickMove', 'DoubleLeftClick'])

        this._keys = new Map([...this._keys.entries()]
            .sort((a, b) =>
                (b[0] && special.has(b[0]) ? 1 : 0) -
                (a[0] && special.has(a[0]) ? 1 : 0)
            ))
    }

    _getKeyCombo(keyCode) {
        return {
            key: keyCode || this._key || this._mouse,
            modifiers: this._modifiers
        }
    }

    /**
     * @param {string} [keyCode]
     * @param {Event} [event]
     * @param {boolean} [isRightTarget=true] - if false ignores the event that might trigger an action
     */
    _trigger(keyCode, event, isRightTarget = true) {
        this.checkModifierState(event)
        if (this._paused) return
        const keyCombo = this._getKeyCombo(keyCode)
        let handled = false
        if (keyCombo.key && keyCombo.key.includes('ClickUp')) {
            this.endActiveAction()
            handled = true
        }

        if (!handled) {
            // fallback to mouse move event when user press/release modifier keys
            if (!keyCombo.key) {
                this.endActiveAction()
                keyCombo.key = 'MouseMove'
            }
            const actions = this._keys.get(keyCombo.key)
            if (actions) {
                for (const action of actions) {
                    if (isRightTarget && action.trigger(keyCombo, this.event)) {
                        this._activeActionSet.add(action)
                        handled = true
                        break
                    }
                }
            }
        }

        if (handled) {
            event.preventDefault()
        }
    }

    /**
     * @param {KeyboardEvent} e
     * @returns {string}
     */
    preprocessKeyboardEvent(e) {
        const key = KEYBOARD_CODE_MAP_KEY[e.code] || e.key
        if (key === (IS_MACINTOSH ? 'Meta' : 'Control')) return 'Modifier'
        return key
    }

    /**
     * check the state of modifier keys
     * @param {KeyboardEvent|MouseEvent|WheelEvent|TouchEvent} e
     */
    checkModifierState(e) {
        this._modifiers.ctrl = e.ctrlKey
        this._modifiers.shift = e.shiftKey
        this._modifiers.alt = e.altKey
        this._modifiers.modifier = IS_MACINTOSH ? e.metaKey : e.ctrlKey
    }

    /**
     * @param {Record<string, string[]>} keybinds
     */
    importKeybinds(keybinds) {
        if (!keybinds) return

        for (const [name, action] of this._actions) {
            if (keybinds[name] !== undefined) {
                action.keyCombos = keybinds[name]
            }
        }

        this._updateActions()
    }

    /**
     * @param {boolean} [changedOnly=true]
     * @returns {Record<string, string[]>}
     */
    exportKeybinds(changedOnly = true) {
        /** @type {Record<string, string[]>} */
        const changedKeybinds = {}
        for (const [name, action] of this._actions) {
            if (!changedOnly || !action.usesDefaultKeyCombo) {
                changedKeybinds[name] = action.keyCombos
            }
        }
        return changedKeybinds
    }
}
