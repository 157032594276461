import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFeatureValue } from '@growthbook/growthbook-react'

import { IfPermissionGranted, PERMISSIONS, useAccessControl } from '../../access-control'
import CreateProjectDialog from '../../components/modals/CreateProjectDialog'
import {
  Avatar,
  Icon,
  NavigationButton,
  NavigationItem,
  ScrollView,
  SidebarTitle,
  Tooltip
} from '../../components/shared'
import { ProjectListLoading, SidebarTitleLoading } from '../../components/shared/Skeleton'
import { FEATURE_KEYS } from '../../growthbook-feature-keys'
import { useNormalProjectsQuery } from '../../hooks/useWorkspace'
import { useProfile } from '../../providers/ProfileProvider'
import { ProjectPermissionProvider } from '../../providers/ProjectPermissionProvider'
import { useSkeletonLoadingContext } from '../../providers/SkeletonLoadingProvider'
import { useSetTutorial } from '../../providers/TutorialProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { TutorialType } from '../../tutorials'
import {
  getPersonalSharedPath,
  getWorkspaceArchivePath,
  getWorkspaceDraftsPath,
  getWorkspaceTemplatePath
} from '../../utils/pathGenerators'
import { BuiltInTemplateType } from '../Templates'
import AddNewTeamButton from './AddNewTeamButton'
import ProjectNavigationItem from './ProjectNavigationItem'
import WorkspacePopover from './WorkspacePopover'

const TUTORIAL_KEY = 'DASHBOARD'
const COMMUNITY_LINK = 'https://community.phase.com/'

const WorkspaceSidebar = () => {
  const { t } = useTranslation('workspace')
  const profile = useProfile()
  const { workspaceData, isWorkspaceDataLoading } = useWorkspaceContext()
  const { userHasPermission } = useAccessControl()
  const { workspaceFileListReady, updateWorkspaceSidebarReady } = useSkeletonLoadingContext()
  const { startVideoTutorial } = useSetTutorial()
  const builtInTemplate = useFeatureValue<BuiltInTemplateType>(FEATURE_KEYS.TEMPLATE, {
    value: false,
    projectId: ''
  })

  const { data: projectListData, loading: isProjectListLoading } = useNormalProjectsQuery({
    workspaceId: workspaceData.id,
    isPersonalWorkspace: workspaceData.isPersonal
  })
  const popoverTriggerRef = useRef<HTMLDivElement>(null)
  const [createProjectDialogOpen, setCreateProjectDialogOpen] = useState(false)
  const [workspacePopoverOpen, setWorkspacePopoverOpen] = useState(false)

  const openCreateProjectDialog = () => setCreateProjectDialogOpen(true)
  const closeCreateProjectDialog = () => setCreateProjectDialogOpen(false)

  const openWorkspaceMenu = () => {
    setWorkspacePopoverOpen(!workspacePopoverOpen)
  }
  const openCommunity = () => window.open(COMMUNITY_LINK, '_blank')
  const handleLearningCenterOnClick = useCallback(() => {
    startVideoTutorial(TutorialType[TUTORIAL_KEY], true)
  }, [startVideoTutorial])

  useEffect(() => {
    if (!isProjectListLoading && !isWorkspaceDataLoading) {
      updateWorkspaceSidebarReady(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProjectListLoading, isWorkspaceDataLoading])

  return (
    <div className="flex flex-col max-h-screen bg-neutral-90">
      {workspaceFileListReady && !isProjectListLoading && !isWorkspaceDataLoading ? (
        <>
          <SidebarTitle
            ref={popoverTriggerRef}
            className="gap-14"
            onClick={openWorkspaceMenu}
            dataTestId="workspace-name"
          >
            <Avatar size="m" src={workspaceData.avatarUrl} alt={workspaceData.name || ''} className="flex-shrink-0" />
            <div className="flex items-center min-w-0 w-full">
              <Tooltip content={workspaceData.name} visibleOnOverflow>
                <div className="truncate pr-8">{workspaceData.name}</div>
              </Tooltip>
              <Icon name="KeyboardArrowDown" size="l" interactive={false} />
            </div>
          </SidebarTitle>
          {workspaceData.isPersonal && (
            <>
              <AddNewTeamButton currentWorkspaceSlug={workspaceData.slug} />
              <div
                className="w-full border-t border-solid border-neutral-80 my-12"
                data-test-id="add-new-team-divider"
              />
            </>
          )}
          <NavigationItem to={getWorkspaceDraftsPath(workspaceData.type, workspaceData.slug)} iconName="Doc">
            {t('drafts')}
          </NavigationItem>
          {workspaceData.isPersonal && (
            <NavigationItem to={getPersonalSharedPath(workspaceData.slug)} iconName="Users24">
              {t('shared')}
            </NavigationItem>
          )}
          <NavigationItem to={getWorkspaceArchivePath(workspaceData.type, workspaceData.slug)} iconName="Archived">
            {t('archive')}
          </NavigationItem>
          {builtInTemplate.value && (
            <IfPermissionGranted perform={PERMISSIONS.VIEW_TEMPLATE}>
              <NavigationItem to={getWorkspaceTemplatePath(workspaceData.type, workspaceData.slug)} iconName="Template">
                {t('templates')}
              </NavigationItem>
            </IfPermissionGranted>
          )}
        </>
      ) : (
        <SidebarTitleLoading />
      )}
      <div className="w-full border-t border-solid border-neutral-80 my-12" />
      {workspaceFileListReady && !isProjectListLoading && !isWorkspaceDataLoading ? (
        <>
          {/* @ts-ignore TODO: fix after refactor of ScrollView */}
          <ScrollView className="pb-2">
            {userHasPermission(PERMISSIONS.CREATE_PROJECT) && (
              <NavigationButton icon="NewProject" onClick={openCreateProjectDialog} data-test-id="project-title">
                <div>{t('new_project')}</div>
              </NavigationButton>
            )}
            {projectListData?.projects.map((project) => (
              <ProjectPermissionProvider key={project.id} id={project.id}>
                <ProjectNavigationItem
                  workspaceType={workspaceData.type}
                  workspaceSlug={workspaceData.slug}
                  {...project}
                />
              </ProjectPermissionProvider>
            ))}
          </ScrollView>
        </>
      ) : (
        <ProjectListLoading />
      )}
      <CreateProjectDialog
        workspaceId={workspaceData.id}
        workspaceType={workspaceData.type}
        workspaceSlug={workspaceData.slug}
        open={createProjectDialogOpen}
        onClose={closeCreateProjectDialog}
      />
      {workspaceFileListReady && !isProjectListLoading && !isWorkspaceDataLoading && (
        <div className="mt-auto py-12 border-t border-solid border-neutral-80">
          <NavigationButton icon="Chat" onClick={openCommunity} data-test-id="user-dashboard-community">
            {t('community')}
          </NavigationButton>
          <NavigationButton
            icon="TutorialVideo"
            onClick={handleLearningCenterOnClick}
            data-test-id="user-dashboard-video-tutorial"
          >
            {t('tutorial_video')}
          </NavigationButton>
        </div>
      )}
      <WorkspacePopover
        triggerRef={popoverTriggerRef}
        open={workspacePopoverOpen}
        onClose={() => setWorkspacePopoverOpen(false)}
        list={profile.workspaceList}
        currentWorkspaceType={workspaceData.type}
        currentWorkspaceSlug={workspaceData.slug}
      />
    </div>
  )
}

export default WorkspaceSidebar
