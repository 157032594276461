/**
 * @typedef {object} WASM
 * @property {WebAssembly.Memory} memory
 *
 * lib_main.zig
 * @property {() => void} deinit
 * @property {() => boolean} tick
 * @property {() => void} draw
 * @property {() => number} queryFrameIndex
 *
 * @property {(evt_type: number, button: number, x: number, y: number) => void} mouseEvent
 * @property {(evt_type: number, key_code: number, char_code: number, key_repeat: boolean, modifiers: number) => void} keyEvent
 * @property {(evt_type: number, w: number, h: number) => void} resize
 *
 * mem.zig
 * @property {(len: number) => number} allocUint8Array
 * @property {(ptr: number, len: number) => void} freeUint8Array
 *
 * @property {(len: number) => number} allocUint16Array
 * @property {(ptr: number, len: number) => void} freeUint16Array
 *
 * @property {(len: number) => number} allocFloat32Array
 * @property {(ptr: number, len: number) => void} freeFloat32Array
 *
 * @property {(len: number) => number} allocTempUint8Array
 * @property {(len: number) => number} allocTempUint16Array
 * @property {(len: number) => number} allocTempUint32Array
 * @property {(len: number) => number} allocTempFloat32Array
 * @property {(len: number) => number} allocTempFloat64Array
 *
 * draw.zig
 * @property {(width: number, height: number) => number} makeImageWithTexture
 * @property {(id: number) => void} destroyImage
 */

/** @type {WASM} */
let wasm

/** @type {WebGL2RenderingContext} */
let gl

/**
 * @param {any} WASM
 * @param {WebGL2RenderingContext} wgl
 */
export const init = (WASM, wgl) => {
    wasm = WASM
    gl = wgl
    utf_decoder = new TextDecoder()
}

let shader_uid = 0
let program_uid = 0
let buffer_uid = 0
let vao_uid = 0
let renderbuffer_uid = 0
let framebuffer_uid = 0
let texture_uid = 0
let uniform_loc_uid = 0
let sampler_uid = 0

/** @type {Map<number, WebGLShader>} */
const shader_pool = new Map()
/** @type {Map<number, WebGLProgram>} */
const program_pool = new Map()
/** @type {Map<number, WebGLBuffer>} */
const buffer_pool = new Map()
/** @type {Map<number, WebGLVertexArrayObject>} */
const vao_pool = new Map()
/** @type {Map<number, WebGLRenderbuffer>} */
const renderbuffer_pool = new Map()
/** @type {Map<number, WebGLFramebuffer>} */
const framebuffer_pool = new Map()
/** @type {Map<number, WebGLTexture>} */
const texture_pool = new Map()
/**
 * key = program_id.uniform_name
 * @type {Map<string, number>}
 */
const uniform_loc_index_table = new Map()
/** @type {Map<number, loc: WebGLUniformLocation>} */
const uniform_loc_pool = new Map()
/** @type {Map<number, WebGLSampler>} */
const sampler_pool = new Map()

let utf_decoder

const buffers = new Map()
export const getBuffer = (id) => buffers.get(id)
export const cleanBuffer = () => {
    buffers.clear()
}

let pixels = null

export const app = {
    read: (x, y, width, height) => {
        pixels = new Uint8Array(4 * width * height)
        gl.readPixels(x, y, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixels)
    },
    log: (level, str_ptr, str_len) => {
        const str = utf_decoder.decode(getUint8Array(str_ptr, str_len))
        switch (level) {
            case 0: console.error(str); break
            case 1: console.error(str); break
            case 2: console.warn(str); break
            default: console.info(str); break
        }
    },

    width: () => gl.drawingBufferWidth,
    height: () => gl.drawingBufferHeight,
    now: () => performance.now(),
    dpiScale: () => Math.max(window.devicePixelRatio, 2),

    pixels: () => pixels,
}
export const wgl = {
    getParameter: (pname) => Number(gl.getParameter(pname)),
    getCurrentProgram: () => findKey(program_pool, gl.getParameter(gl.CURRENT_PROGRAM)),
    getFramebufferBinding: () => findKey(framebuffer_pool, gl.getParameter(gl.FRAMEBUFFER_BINDING)),
    getError: () => gl.getError(),

    enable: (x) => { gl.enable(x) },
    disable: (x) => { gl.disable(x) },

    blendColor: (r, g, b, a) => { gl.blendColor(r, g, b, a) },

    clearBufferfv: (buffer, drawbuffer, value_ptr) => {
        switch (buffer) {
            case gl.COLOR: {
                gl.clearBufferfv(buffer, drawbuffer, getFloat32Array(value_ptr, 4))
                break
            }
            case gl.DEPTH: {
                gl.clearBufferfv(buffer, drawbuffer, getFloat32Array(value_ptr, 1))
                break
            }
            default: {
                console.warn(`gl.clearBufferfv() with buffer=${buffer} is not supported`)
                break
            }
        }
    },
    clearBufferfi: (buffer, drawbuffer, depth, stencil) => {
        gl.clearBufferfi(buffer, drawbuffer, depth, stencil)
    },
    clearBufferiv: (buffer, drawbuffer, value_ptr) => {
        if (buffer === gl.STENCIL) {
            gl.clearBufferiv(buffer, drawbuffer, getInt32Array(value_ptr, 1))
        } else {
            console.warn(`gl.clearBufferiv() with buffer=${buffer} is not supported`)
        }
    },
    readBuffer: (source) => { gl.readBuffer(source) },

    viewport: (x, y, width, height) => { gl.viewport(x, y, width, height) },
    scissor: (x, y, width, height) => { gl.scissor(x, y, width, height) },

    polygonOffset: (factor, units) => { gl.polygonOffset(factor, units) },
    frontFace: (mode) => { gl.frontFace(mode) },
    cullFace: (mode) => { gl.cullFace(mode) },
    colorMask: (red, green, blue, alpha) => { gl.colorMask(red > 0, green > 0, blue > 0, alpha > 0) },
    depthMask: (flag) => { gl.depthMask(flag > 0) },
    depthFunc: (x) => { gl.depthFunc(x) },
    stencilMask: (mask) => { gl.stencilMask(mask) },
    stencilFunc: (func, ref, mask) => { gl.stencilFunc(func, ref, mask) },
    stencilFuncSeparate: (face, func, ref, mask) => { gl.stencilFuncSeparate(face, func, ref, mask) },
    stencilOp: (fail, zfail, zpass) => { gl.stencilOp(fail, zfail, zpass) },
    stencilOpSeparate: (face, fail, zfail, zpass) => { gl.stencilOpSeparate(face, fail, zfail, zpass) },
    blendEquationSeparate: (modeRGB, modeAlpha) => { gl.blendEquationSeparate(modeRGB, modeAlpha) },
    blendFuncSeparate: (srcRGB, dstRGB, srcAlpha, dstAlpha) => { gl.blendFuncSeparate(srcRGB, dstRGB, srcAlpha, dstAlpha) },

    createShader: (type) => {
        shader_pool.set(++shader_uid, gl.createShader(type))
        return shader_uid
    },
    shaderSource: (id, src_ptr, src_len) => {
        gl.shaderSource(shader_pool.get(id), utf_decoder.decode(getUint8Array(src_ptr, src_len)))
    },
    deleteShader: (id) => {
        gl.deleteShader(shader_pool.get(id))
        shader_pool.delete(id)
    },
    compileShader: (id) => { gl.compileShader(shader_pool.get(id)) },
    getProgramLinkStatus: (id) => (gl.getProgramParameter(program_pool.get(id), gl.LINK_STATUS) ? 1 : 0),
    printShaderInfoLog: (id) => {
        const info = gl.getShaderInfoLog(shader_pool.get(id))
        console.warn(info)
    },

    createProgram: () => {
        program_pool.set(++program_uid, gl.createProgram())
        return program_uid
    },
    attachShader: (progId, shadId) => {
        gl.attachShader(program_pool.get(progId), shader_pool.get(shadId))
    },
    linkProgram: (id) => { gl.linkProgram(program_pool.get(id)) },
    useProgram: (id) => { gl.useProgram(program_pool.get(id)) },
    deleteProgram: (id) => {
        gl.deleteProgram(program_pool.get(id))
        program_pool.delete(id)
    },
    getShaderCompileStatus: (id) => (gl.getShaderParameter(shader_pool.get(id), gl.COMPILE_STATUS) ? 1 : 0),
    printProgramInfoLog: (id) => {
        const info = gl.getProgramInfoLog(program_pool.get(id))
        console.warn(info)
    },

    createBuffer: () => {
        buffer_pool.set(++buffer_uid, gl.createBuffer())
        return buffer_uid
    },
    deleteBuffer: (id) => {
        gl.deleteBuffer(buffer_pool.get(id))
        buffer_pool.delete(id)
    },
    createVertexArray: () => {
        vao_pool.set(++vao_uid, gl.createVertexArray())
        return vao_uid
    },
    bindVertexArray: (id) => {
        gl.bindVertexArray(vao_pool.get(id))
    },
    deleteVertexArray: (id) => {
        gl.deleteVertexArray(vao_pool.get(id))
        vao_pool.delete(id)
    },
    bindBuffer: (target, bufId) => { gl.bindBuffer(target, buffer_pool.get(bufId)) },
    bufferData: (target, size, _, usage) => { gl.bufferData(target, size, usage) },
    bufferSubData: (target, offset, len, ptr) => {
        gl.bufferSubData(target, offset, getUint8Array(ptr, len))
    },

    enableVertexAttribArray: (index) => { gl.enableVertexAttribArray(index) },
    disableVertexAttribArray: (index) => { gl.disableVertexAttribArray(index) },
    vertexAttribPointer: (index, size, type, normalized, stride, offset) => {
        gl.vertexAttribPointer(index, size, type, normalized > 0, stride, offset)
    },
    vertexAttribDivisor: (index, divisor) => { gl.vertexAttribDivisor(index, divisor) },
    getAttribLocation: (prog_id, namePtr, nameLen) => {
        const prog = program_pool.get(prog_id)
        const name = utf_decoder.decode(getUint8Array(namePtr, nameLen))
        return gl.getAttribLocation(prog, name)
    },

    createTexture: () => {
        texture_pool.set(++texture_uid, gl.createTexture())
        return texture_uid
    },
    deleteTexture: (id) => {
        gl.deleteTexture(texture_pool.get(id))
        texture_pool.delete(id)
    },
    bindTexture: (target, texId) => { gl.bindTexture(target, texture_pool.get(texId)) },
    texImage2D: (target, level, internalformat, width, height, border, format, type, pixelsPtr, pixelsLen) => {
        gl.texImage2D(target, level, internalformat, width, height, border, format, type, getUint8Array(pixelsPtr, pixelsLen))
    },
    texImage2DFromSource: (target, level, internalformat, width, height, border, format, type, source) => {
        gl.texImage2D(target, level, internalformat, width, height, border, format, type, source)
    },
    texSubImage2D: (target, level, xoffset, yoffset, width, height, format, type, pixelsPtr, pixelsLen) => {
        gl.texSubImage2D(target, level, xoffset, yoffset, width, height, format, type, getUint8Array(pixelsPtr, pixelsLen))
    },
    activeTexture: (slot) => { gl.activeTexture(slot) },
    pixelStorei: (pname, param) => {
        gl.pixelStorei(pname, param)
    },

    createSampler: () => {
        sampler_pool.set(++sampler_uid, gl.createSampler())
        return sampler_uid
    },
    deleteSampler: (id) => {
        gl.deleteSampler(sampler_pool.get(id))
        sampler_pool.delete(id)
    },
    bindSampler: (unit, id) => { gl.bindSampler(unit, sampler_pool.get(id)) },
    samplerParameteri: (id, pname, param) => { gl.samplerParameteri(sampler_pool.get(id), pname, param) },
    samplerParameterf: (id, pname, param) => { gl.samplerParameterf(sampler_pool.get(id), pname, param) },

    createFramebuffer: () => {
        framebuffer_pool.set(++framebuffer_uid, gl.createFramebuffer())
        return framebuffer_uid
    },
    deleteFramebuffer: (id) => {
        gl.deleteFramebuffer(framebuffer_pool.get(id))
        framebuffer_pool.delete(id)
    },
    invalidateFramebuffer: (target, num, ptr) => { gl.invalidateFramebuffer(target, getInt32Array(ptr, num)) },
    framebufferTexture2D: (target, attachment, textarget, texId, level) => { gl.framebufferTexture2D(target, attachment, textarget, texture_pool.get(texId), level) },
    framebufferTextureLayer: (target, attachment, texId, level, layer) => { gl.framebufferTextureLayer(target, attachment, texture_pool.get(texId), level, layer) },
    framebufferRenderbuffer: (target, attachment, renderbuffertarget, renderbufferId) => gl.framebufferRenderbuffer(target, attachment, renderbuffertarget, renderbuffer_pool.get(renderbufferId)),
    bindFramebuffer: (target, id) => gl.bindFramebuffer(target, framebuffer_pool.get(id)),
    checkFramebufferStatus: (target) => gl.checkFramebufferStatus(target),
    blitFramebuffer: (srcX0, srcY0, srcX1, srcY1, dstX0, dstY0, dstX1, dstY1, mask, filter) => {
        gl.blitFramebuffer(srcX0, srcY0, srcX1, srcY1, dstX0, dstY0, dstX1, dstY1, mask, filter)
    },

    createRenderbuffer: () => {
        renderbuffer_pool.set(++renderbuffer_uid, gl.createRenderbuffer())
        return renderbuffer_uid
    },
    deleteRenderbuffer: (id) => {
        gl.deleteRenderbuffer(renderbuffer_pool.get(id))
        renderbuffer_pool.delete(id)
    },
    renderbufferStorageMultisample: (target, samples, internalFormat, width, height) => { gl.renderbufferStorageMultisample(target, samples, internalFormat, width, height) },
    bindRenderbuffer: (target, id) => { gl.bindRenderbuffer(target, renderbuffer_pool.get(id)) },

    drawBuffers: (ptr, len) => { gl.drawBuffers(getInt32Array(ptr, len)) },

    getUniformLocation: (prog_id, namePtr, nameLen) => {
        const prog = program_pool.get(prog_id)
        const name = utf_decoder.decode(getUint8Array(namePtr, nameLen))
        const key = `${prog_id}.${name}`
        let index = uniform_loc_index_table.get(key)
        if (index) {
            return index
        }
        index = ++uniform_loc_uid
        uniform_loc_index_table.set(key, index)
        const loc = gl.getUniformLocation(prog, name)
        uniform_loc_pool.set(index, loc)
        return index
    },
    uniform1fv: (id, count, ptr) => { gl.uniform1fv(uniform_loc_pool.get(id), getFloat32Array(ptr, 1 * count)) },
    uniform2fv: (id, count, ptr) => { gl.uniform2fv(uniform_loc_pool.get(id), getFloat32Array(ptr, 2 * count)) },
    uniform3fv: (id, count, ptr) => { gl.uniform3fv(uniform_loc_pool.get(id), getFloat32Array(ptr, 3 * count)) },
    uniform4fv: (id, count, ptr) => { gl.uniform4fv(uniform_loc_pool.get(id), getFloat32Array(ptr, 4 * count)) },
    uniform1iv: (id, count, ptr) => { gl.uniform1iv(uniform_loc_pool.get(id), getInt32Array(ptr, 1 * count)) },
    uniform2iv: (id, count, ptr) => { gl.uniform2iv(uniform_loc_pool.get(id), getInt32Array(ptr, 2 * count)) },
    uniform3iv: (id, count, ptr) => { gl.uniform3iv(uniform_loc_pool.get(id), getInt32Array(ptr, 3 * count)) },
    uniform4iv: (id, count, ptr) => { gl.uniform4iv(uniform_loc_pool.get(id), getInt32Array(ptr, 4 * count)) },
    uniform1i: (id, value) => { gl.uniform1i(uniform_loc_pool.get(id), value) },
    uniformMatrix4fv: (id, count, transpose, ptr) => { gl.uniformMatrix4fv(uniform_loc_pool.get(id), transpose > 0, getFloat32Array(ptr, 16 * count)) },

    drawElements: (mode, count, type, offset) => { gl.drawElements(mode, count, type, offset) },
    drawElementsInstanced: (mode, count, type, offset, instanceCount) => { gl.drawElementsInstanced(mode, count, type, offset, instanceCount) },
    drawArrays: (mode, first, count) => { gl.drawArrays(mode, first, count) },
    drawArraysInstanced: (mode, first, count, instanceCount) => { gl.drawArraysInstanced(mode, first, count, instanceCount) },
}

/**
 * @param {Array<string|number>} ary
 */
export const allocTempUint8Array = (ary) => {
    if (!ary || ary.length === 0) return null
    const ptr = wasm.allocTempUint8Array(ary.length)
    const slc = getUint8Array(ptr, ary.length)
    slc.set(ary)
    return ptr
}

/**
 * @param {Array<string|number>} ary
 */
export const allocTempUint16Array = (ary) => {
    if (!ary || ary.length === 0) return null
    const ptr = wasm.allocTempUint16Array(ary.length)
    const slc = getUint16Array(ptr, ary.length)
    slc.set(ary)
    return ptr
}

/**
 * @param {Array<string|number>} ary
 */
export const allocTempUint32Array = (ary) => {
    if (!ary || ary.length === 0) return null
    const ptr = wasm.allocTempUint32Array(ary.length)
    const slc = getUint32Array(ptr, ary.length)
    slc.set(ary)
    return ptr
}

/**
 * @param {Array<string|number>} ary
 */
export const allocTempFloat32Array = (ary) => {
    if (!ary || ary.length === 0) return null
    const ptr = wasm.allocTempFloat32Array(ary.length)
    const slc = getFloat32Array(ptr, ary.length)
    slc.set(ary)
    return ptr
}

/**
 * @param {Array<string|number>} ary
 */
export const allocTempFloat64Array = (ary) => {
    if (!ary || ary.length === 0) return null
    const ptr = wasm.allocTempFloat64Array(ary.length)
    const slc = getFloat64Array(ptr, ary.length)
    slc.set(ary)
    return ptr
}

/**
 * @param {number} ptr
 * @param {number} len
 * @returns {Uint8Array}
 */
export const getUint8Array = (ptr, len) => {
    if (ptr === 0) return null
    const arr = new Uint8Array(wasm.memory.buffer, ptr, len)
    return arr
}

/**
 * @param {number} ptr
 * @param {number} len
 * @returns {Uint16Array}
 */
export const getUint16Array = (ptr, len) => {
    if (ptr === 0) return null
    const arr = new Uint16Array(wasm.memory.buffer, ptr, len)
    return arr
}

/**
 * @param {number} ptr
 * @param {number} len
 * @returns {Uint32Array}
 */
export const getUint32Array = (ptr, len) => {
    if (ptr === 0) return null
    const arr = new Uint32Array(wasm.memory.buffer, ptr, len)
    return arr
}

/**
 * @param {number} ptr
 * @param {number} len
 * @returns {Int32Array}
 */
export const getInt32Array = (ptr, len) => {
    if (ptr === 0) return null
    const arr = new Int32Array(wasm.memory.buffer, ptr, len)
    return arr
}

/**
 * @param {number} ptr
 * @param {number} len
 * @returns {Float32Array}
 */
export const getFloat32Array = (ptr, len) => {
    if (ptr === 0) return null
    const arr = new Float32Array(wasm.memory.buffer, ptr, len)
    return arr
}

/**
 * @param {number} ptr
 * @param {number} len
 * @returns {Float64Array}
 */
export const getFloat64Array = (ptr, len) => {
    if (ptr === 0) return null
    const arr = new Float64Array(wasm.memory.buffer, ptr, len)
    return arr
}

/**
 * @template T
 * @param {Map<number, T>} pool
 * @param {T} val
 */
const findKey = (pool, val) => {
    for (const pair of pool.entries()) {
        if (pair[1] == val) {
            return pair[0]
        }
    }
    return 0
}

/**
 * @param {HTMLImageElement | HTMLCanvasElement | HTMLVideoElement | ImageData | ImageBitmap} img
 */
export const createTexture = (img) => {
    const texture_id = wgl.createTexture()
    wgl.bindTexture(gl.TEXTURE_2D, texture_id)
    wgl.texImage2DFromSource(gl.TEXTURE_2D, 0, gl.RGBA8, img.width, img.height, 0, gl.RGBA, gl.UNSIGNED_BYTE, img)
    const id = wasm.makeImageWithTexture(img.width, img.height, texture_id)
    return id
}

/**
 * @param {number} id
 */
export const deleteTexture = (id) => {
    wasm.destroyImage(id)
}
