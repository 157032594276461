import { EventEmitter } from 'eventemitter3'
import { MIN_ZOOM, MAX_ZOOM } from "./constants"
import { Transform2D, Vector2, Rect2, Num } from "./math"
/**
 * @fires 'update' update event from transform
 * @fires 'resize' Forward resize event from canvas
 */
export class Viewport extends EventEmitter {
    /**
     * @param {HTMLCanvasElement} canvas
     * @param {boolean} useLowDPR
     */
    constructor(canvas, useLowDPR = false) {
        super()

        this.x = 0
        this.y = 0

        this.width = 256
        this.height = 256

        this.scale = 1

        this.useLowDPR = useLowDPR

        this.pixelRatio = this.useLowDPR ? window.devicePixelRatio : Math.max(window.devicePixelRatio, 2)

        this.canvas = canvas

        this.projectionTransform = new Transform2D()
        this.invProjectionTransform = new Transform2D()

        this.reset()
    }

    reset() {
        this.x = 0
        this.y = 0

        this.width = 256
        this.height = 256

        this.scale = 1

        this.pixelRatio = this.useLowDPR ? window.devicePixelRatio : Math.max(window.devicePixelRatio, 2)

        this.projectionTransform.identity()
        this.invProjectionTransform.identity()

        return true
    }

    get rect() {
        return new Rect2(0, 0, this.width, this.height)
    }

    get rectW() {
        return this.toWorldRect(this.rect)
    }

    get realWidth() {
        return Math.floor(this.width * this.pixelRatio)
    }

    get realHeight() {
        return Math.floor(this.height * this.pixelRatio)
    }

    /** @private */
    updateTransforms() {
        this.projectionTransform
            .set(this.scale, 0, 0, this.scale, this.x, this.y)

        this.invProjectionTransform
            .copy(this.projectionTransform)
            .affine_inverse()

        this.emit('update')
    }

    resize() {
        const container = this.canvas.parentElement
        if (!container) return

        const box = container.getBoundingClientRect()

        const boxWidth = Math.ceil(box.width)
        const boxHeight = Math.ceil(box.height)

        if (this.width === boxWidth && this.height === boxHeight) return

        this.width = boxWidth
        this.height = boxHeight

        this.canvas.style.width = `${this.width}px`
        this.canvas.style.height = `${this.height}px`

        // never resize canvas to 0
        if (this.width * this.height === 0) return

        this.canvas.width = this.realWidth
        this.canvas.height = this.realHeight

        this.emit('resize')
        this.emit('update')
    }

    /**
     * converts a vector from world space to screen space coordinates
     * @param {Vector2} v
     * @returns {Vector2}
     */
    toScreen(v) {
        return this.projectionTransform.xform(v)
    }

    /**
     * converts a vector from screen space to world space coordinates
     * @param {Vector2} v
     * @returns {Vector2}
     */
    toWorld(v) {
        return this.invProjectionTransform.xform(v)
    }

    /**
     * converts a rect from world space to screen space coordinates
     * @param {Rect2} rect
     * @returns {Rect2}
     */
    toScreenRect(rect) {
        return this.projectionTransform.xform_rect(rect)
    }

    /**
     * converts a rect from screen space to world space coordinates
     * @param {Rect2} rect
     * @returns {Rect2}
     */
    toWorldRect(rect) {
        return this.invProjectionTransform.xform_rect(rect)
    }

    /** @returns {number} */
    getZoom() {
        return this.scale
    }

    /** @param {number} zoom */
    setZoom(zoom) {
        const rect = this.rectW
        this.scale = Num.clamp(zoom, MIN_ZOOM, MAX_ZOOM)
        this.moveTo(rect)
    }

    /** @param {Vector2} offset */
    offsetPos(offset) {
        this.x += offset.x
        this.y += offset.y
        this.updateTransforms()
    }

    /**
     * zoom viewport to pos (in or out)
     * @param {Vector2} pos
     * @param {number} mult
     */
    zoomToPos(pos, mult) {
        const oldPoint = this.toWorld(pos)
        this.setZoom(this.scale * mult)
        const newPoint = this.toScreen(oldPoint)
        const d = pos.clone().sub(newPoint)
        this.offsetPos(d)
    }

    /**
     * @param {Rect2} rect - in world space
     * @param {number} [padding]
     * @param {number} [u] - in UV space [0, 1]
     * @param {number} [v] - in UV space [0, 1]
     * @param {boolean} [forceWidthContainment]
     */
    focus(rect, padding = 25, u = 0.5, v = 0.5, forceWidthContainment = false) {
        const _r = rect.grow(padding)
        this.scale = forceWidthContainment
            ? this.width / _r.width
            : Math.min(this.width / _r.width, this.height / _r.height)
        this.moveTo(_r, u, v)
    }

    /**
     * move at a point on the given rect taking into account u, v coords
     * @param {Rect2} rect - in world space
     * @param {number} [u] - in UV space [0, 1]
     * @param {number} [v] - in UV space [0, 1]
     */
    moveTo(rect, u = 0.5, v = 0.5) {
        const _r = this.rect
        const v0 = getPos(_r, u, v)
        const v1 = getPos(rect, u, v).scale(this.scale)
        this.x = v0.x - v1.x
        this.y = v0.y - v1.y
        this.updateTransforms()
    }
}

/**
 * @param {Rect2} rect
 * @param {number} u - in UV space [0, 1]
 * @param {number} v - in UV space [0, 1]
 * @returns {Vector2}
 */
function getPos(rect, u, v) {
    return new Vector2(rect.x + u * rect.width, rect.y + v * rect.height)
}
