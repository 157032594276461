import { CapShape, JoinShape } from '@phase-software/types'
import { deg2rad } from '../math'
import { pathDataFromLottieBezier } from "../dino"

/** @typedef {import('../dino').DemoData} DemoData */

/**
 * @param base
 * @param holes
 * @param {number} [frameRate = 60]
 */
function pathDataKeyframesFromLottieShape(base, holes = [], frameRate = 60) {
    const invFr = 1 / frameRate
    return base.ks.k
        .map((kf, i) => {
            const basePath = pathDataFromLottieBezier(kf.s)
            for (const hole of holes) {
                const holePath = pathDataFromLottieBezier(hole.ks.k[i].s)
                basePath.commands.push(...holePath.commands)
                basePath.vertices.push(...holePath.vertices)
            }
            return ({
                time: kf.t * invFr,
                value: basePath
            })
        })
}

const morph01Kfs = (() => {
    const base = {
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
            "a": 1,
            "k": [
                {
                    "i": {
                        "x": 0.62,
                        "y": 1
                    },
                    "o": {
                        "x": 0.85,
                        "y": 0
                    },
                    "t": 0,
                    "s": [
                        {
                            "i": [
                                [
                                    -98.859,
                                    0
                                ],
                                [
                                    0,
                                    -98.859
                                ],
                                [
                                    98.859,
                                    0
                                ],
                                [
                                    0,
                                    98.859
                                ]
                            ],
                            "o": [
                                [
                                    98.859,
                                    0
                                ],
                                [
                                    0,
                                    98.859
                                ],
                                [
                                    -98.859,
                                    0
                                ],
                                [
                                    0,
                                    -98.859
                                ]
                            ],
                            "v": [
                                [
                                    0,
                                    -179
                                ],
                                [
                                    179,
                                    0
                                ],
                                [
                                    0,
                                    179
                                ],
                                [
                                    -179,
                                    0
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.62,
                        "y": 1
                    },
                    "o": {
                        "x": 0.85,
                        "y": 0
                    },
                    "t": 25.84,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    -2,
                                    -180.234
                                ],
                                [
                                    74.744,
                                    -47.309
                                ],
                                [
                                    151.489,
                                    85.617
                                ],
                                [
                                    -155.489,
                                    85.617
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.62,
                        "y": 1
                    },
                    "o": {
                        "x": 0.85,
                        "y": 0
                    },
                    "t": 37.494,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    -2,
                                    -180.234
                                ],
                                [
                                    74.744,
                                    -47.309
                                ],
                                [
                                    151.489,
                                    85.617
                                ],
                                [
                                    -155.489,
                                    85.617
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.62,
                        "y": 1
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0
                    },
                    "t": 68,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    125,
                                    -130
                                ],
                                [
                                    125,
                                    122
                                ],
                                [
                                    -127,
                                    122
                                ],
                                [
                                    -127,
                                    -130
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.62,
                        "y": 1
                    },
                    "o": {
                        "x": 0.85,
                        "y": 0
                    },
                    "t": 81,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    125,
                                    -130
                                ],
                                [
                                    125,
                                    122
                                ],
                                [
                                    -127,
                                    122
                                ],
                                [
                                    -127,
                                    -130
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.62,
                        "y": 1
                    },
                    "o": {
                        "x": 0.85,
                        "y": 0
                    },
                    "t": 104,
                    "s": [
                        {
                            "i": [
                                [
                                    -98.859,
                                    0
                                ],
                                [
                                    0,
                                    -98.859
                                ],
                                [
                                    98.859,
                                    0
                                ],
                                [
                                    0,
                                    98.859
                                ]
                            ],
                            "o": [
                                [
                                    98.859,
                                    0
                                ],
                                [
                                    0,
                                    98.859
                                ],
                                [
                                    -98.859,
                                    0
                                ],
                                [
                                    0,
                                    -98.859
                                ]
                            ],
                            "v": [
                                [
                                    0,
                                    -179
                                ],
                                [
                                    179,
                                    0
                                ],
                                [
                                    0,
                                    179
                                ],
                                [
                                    -179,
                                    0
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "t": 112,
                    "s": [
                        {
                            "i": [
                                [
                                    -98.859,
                                    0
                                ],
                                [
                                    0,
                                    -98.859
                                ],
                                [
                                    98.859,
                                    0
                                ],
                                [
                                    0,
                                    98.859
                                ]
                            ],
                            "o": [
                                [
                                    98.859,
                                    0
                                ],
                                [
                                    0,
                                    98.859
                                ],
                                [
                                    -98.859,
                                    0
                                ],
                                [
                                    0,
                                    -98.859
                                ]
                            ],
                            "v": [
                                [
                                    0,
                                    -179
                                ],
                                [
                                    179,
                                    0
                                ],
                                [
                                    0,
                                    179
                                ],
                                [
                                    -179,
                                    0
                                ]
                            ],
                            "c": true
                        }
                    ]
                }
            ],
            "ix": 2
        },
        "nm": "Tracciato 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
    }
    return pathDataKeyframesFromLottieShape(base, [], 50)
})()

const morph02Kfs = (() => {
    const base = {
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
            "a": 1,
            "k": [
                {
                    "i": {
                        "x": 0.667,
                        "y": 1
                    },
                    "o": {
                        "x": 0.333,
                        "y": 0
                    },
                    "t": 0,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    -136.644,
                                    129
                                ],
                                [
                                    -126.123,
                                    129
                                ],
                                [
                                    -111.395,
                                    129
                                ],
                                [
                                    -90.353,
                                    129
                                ],
                                [
                                    -69.312,
                                    129
                                ],
                                [
                                    -66.371,
                                    121.138
                                ],
                                [
                                    -62.253,
                                    110.131
                                ],
                                [
                                    -56.37,
                                    94.406
                                ],
                                [
                                    -50.488,
                                    78.682
                                ],
                                [
                                    -34.99,
                                    78.682
                                ],
                                [
                                    -13.293,
                                    78.682
                                ],
                                [
                                    17.704,
                                    78.682
                                ],
                                [
                                    48.7,
                                    78.682
                                ],
                                [
                                    51.811,
                                    86.544
                                ],
                                [
                                    56.166,
                                    97.551
                                ],
                                [
                                    62.388,
                                    113.276
                                ],
                                [
                                    68.61,
                                    129
                                ],
                                [
                                    79.357,
                                    129
                                ],
                                [
                                    94.402,
                                    129
                                ],
                                [
                                    115.896,
                                    129
                                ],
                                [
                                    137.39,
                                    129
                                ],
                                [
                                    84.176,
                                    0.852
                                ],
                                [
                                    30.962,
                                    -127.296
                                ],
                                [
                                    0.916,
                                    -127.296
                                ],
                                [
                                    -29.13,
                                    -127.296
                                ],
                                [
                                    -82.887,
                                    0.852
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.162,
                        "y": 1
                    },
                    "o": {
                        "x": 0.333,
                        "y": 0
                    },
                    "t": 4,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -4.456,
                                    0.087
                                ],
                                [
                                    -0.808,
                                    0.174
                                ],
                                [
                                    -4.348,
                                    0.287
                                ],
                                [
                                    -0.633,
                                    0.4
                                ],
                                [
                                    -4.135,
                                    0.529
                                ],
                                [
                                    -0.383,
                                    0.657
                                ],
                                [
                                    -2.22,
                                    6.233
                                ],
                                [
                                    0,
                                    0.965
                                ],
                                [
                                    -1.932,
                                    5.946
                                ],
                                [
                                    0.191,
                                    0.466
                                ],
                                [
                                    -10.431,
                                    0.408
                                ],
                                [
                                    0.325,
                                    0.35
                                ],
                                [
                                    -10.306,
                                    0.287
                                ],
                                [
                                    0.441,
                                    0.224
                                ],
                                [
                                    0.999,
                                    0.167
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -1.066,
                                    1.282
                                ],
                                [
                                    0,
                                    1.964
                                ],
                                [
                                    0.732,
                                    1.166
                                ],
                                [
                                    1.165,
                                    0.683
                                ],
                                [
                                    1.431,
                                    0.283
                                ],
                                [
                                    1.365,
                                    0
                                ],
                                [
                                    15.673,
                                    -36.913
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0.849,
                                    0
                                ],
                                [
                                    4.456,
                                    -0.087
                                ],
                                [
                                    0.807,
                                    -0.175
                                ],
                                [
                                    4.348,
                                    -0.287
                                ],
                                [
                                    0.633,
                                    -0.4
                                ],
                                [
                                    4.135,
                                    -0.528
                                ],
                                [
                                    0.383,
                                    -0.657
                                ],
                                [
                                    2.22,
                                    -6.234
                                ],
                                [
                                    0,
                                    -0.583
                                ],
                                [
                                    1.933,
                                    -5.946
                                ],
                                [
                                    -0.191,
                                    -0.466
                                ],
                                [
                                    10.431,
                                    -0.408
                                ],
                                [
                                    -0.325,
                                    -0.35
                                ],
                                [
                                    10.306,
                                    -0.287
                                ],
                                [
                                    -0.882,
                                    -0.449
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    1.73,
                                    -0.566
                                ],
                                [
                                    1.065,
                                    -1.281
                                ],
                                [
                                    0,
                                    -1.798
                                ],
                                [
                                    -0.733,
                                    -1.165
                                ],
                                [
                                    -1.166,
                                    -0.682
                                ],
                                [
                                    -1.432,
                                    -0.282
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -19.933,
                                    46.947
                                ]
                            ],
                            "v": [
                                [
                                    -131.428,
                                    129
                                ],
                                [
                                    -117.132,
                                    129
                                ],
                                [
                                    -102.836,
                                    129
                                ],
                                [
                                    -93.065,
                                    128.869
                                ],
                                [
                                    -83.357,
                                    128.476
                                ],
                                [
                                    -73.811,
                                    127.783
                                ],
                                [
                                    -64.526,
                                    126.753
                                ],
                                [
                                    -55.56,
                                    125.361
                                ],
                                [
                                    -46.969,
                                    123.582
                                ],
                                [
                                    -42.051,
                                    110.535
                                ],
                                [
                                    -37.707,
                                    97.025
                                ],
                                [
                                    -33.794,
                                    84.521
                                ],
                                [
                                    -30.168,
                                    72.191
                                ],
                                [
                                    -9.465,
                                    70.88
                                ],
                                [
                                    11.038,
                                    69.744
                                ],
                                [
                                    31.354,
                                    68.789
                                ],
                                [
                                    51.495,
                                    68.022
                                ],
                                [
                                    57.255,
                                    88.787
                                ],
                                [
                                    67.776,
                                    125.25
                                ],
                                [
                                    102.939,
                                    122.306
                                ],
                                [
                                    138.925,
                                    122.871
                                ],
                                [
                                    83.95,
                                    -12.18
                                ],
                                [
                                    43.003,
                                    -117.361
                                ],
                                [
                                    15.722,
                                    -120.145
                                ],
                                [
                                    -25.21,
                                    -121.174
                                ],
                                [
                                    -85.085,
                                    -16.824
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 1
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0
                    },
                    "t": 13,
                    "s": [
                        {
                            "i": [
                                [
                                    -8.492,
                                    -62.071
                                ],
                                [
                                    -11.158,
                                    2.224
                                ],
                                [
                                    -9.173,
                                    0.194
                                ],
                                [
                                    -4.246,
                                    0.246
                                ],
                                [
                                    -4.201,
                                    0.629
                                ],
                                [
                                    -3.906,
                                    0.901
                                ],
                                [
                                    -3.745,
                                    1.401
                                ],
                                [
                                    -3.425,
                                    1.516
                                ],
                                [
                                    -2.087,
                                    2.97
                                ],
                                [
                                    -1.202,
                                    5.108
                                ],
                                [
                                    -1.147,
                                    5.04
                                ],
                                [
                                    0.027,
                                    4.246
                                ],
                                [
                                    -2.534,
                                    3.166
                                ],
                                [
                                    -4.025,
                                    0.915
                                ],
                                [
                                    -3.762,
                                    1.207
                                ],
                                [
                                    -3.335,
                                    1.403
                                ],
                                [
                                    -3.276,
                                    -1.067
                                ],
                                [
                                    -0.635,
                                    -3.775
                                ],
                                [
                                    -8.466,
                                    -9.164
                                ],
                                [
                                    -18.533,
                                    0.386
                                ],
                                [
                                    3.072,
                                    16.488
                                ],
                                [
                                    22.265,
                                    43.968
                                ],
                                [
                                    16.56,
                                    21.656
                                ],
                                [
                                    9.333,
                                    1.056
                                ],
                                [
                                    10.725,
                                    -6.214
                                ],
                                [
                                    15.222,
                                    -32.358
                                ]
                            ],
                            "o": [
                                [
                                    1.243,
                                    9.09
                                ],
                                [
                                    11.158,
                                    -2.224
                                ],
                                [
                                    4.248,
                                    -0.09
                                ],
                                [
                                    4.246,
                                    -0.246
                                ],
                                [
                                    3.953,
                                    -0.592
                                ],
                                [
                                    3.906,
                                    -0.901
                                ],
                                [
                                    3.399,
                                    -1.272
                                ],
                                [
                                    3.425,
                                    -1.516
                                ],
                                [
                                    2.972,
                                    -4.23
                                ],
                                [
                                    1.202,
                                    -5.108
                                ],
                                [
                                    0.9,
                                    -3.954
                                ],
                                [
                                    -0.027,
                                    -4.246
                                ],
                                [
                                    2.469,
                                    -3.084
                                ],
                                [
                                    4.025,
                                    -0.915
                                ],
                                [
                                    3.28,
                                    -1.053
                                ],
                                [
                                    3.335,
                                    -1.403
                                ],
                                [
                                    3.64,
                                    1.185
                                ],
                                [
                                    2.07,
                                    12.303
                                ],
                                [
                                    12.579,
                                    13.615
                                ],
                                [
                                    16.768,
                                    -0.349
                                ],
                                [
                                    -9.026,
                                    -48.451
                                ],
                                [
                                    -12.316,
                                    -24.321
                                ],
                                [
                                    -5.706,
                                    -7.461
                                ],
                                [
                                    -12.316,
                                    -1.393
                                ],
                                [
                                    -28.98,
                                    16.791
                                ],
                                [
                                    -15.222,
                                    32.358
                                ]
                            ],
                            "v": [
                                [
                                    -119.69,
                                    129
                                ],
                                [
                                    -98.113,
                                    135.963
                                ],
                                [
                                    -64.641,
                                    129
                                ],
                                [
                                    -51.89,
                                    128.553
                                ],
                                [
                                    -39.208,
                                    127.296
                                ],
                                [
                                    -27.389,
                                    125.103
                                ],
                                [
                                    -15.884,
                                    121.698
                                ],
                                [
                                    -5.307,
                                    117.819
                                ],
                                [
                                    3.302,
                                    111.393
                                ],
                                [
                                    9.133,
                                    97.149
                                ],
                                [
                                    12.228,
                                    81.69
                                ],
                                [
                                    12.666,
                                    69.047
                                ],
                                [
                                    15.554,
                                    57.586
                                ],
                                [
                                    25.75,
                                    52.202
                                ],
                                [
                                    37.886,
                                    49.635
                                ],
                                [
                                    47.838,
                                    45.246
                                ],
                                [
                                    57.783,
                                    44.036
                                ],
                                [
                                    54.107,
                                    54.915
                                ],
                                [
                                    65.605,
                                    90.532
                                ],
                                [
                                    113.351,
                                    119.041
                                ],
                                [
                                    153.491,
                                    88.704
                                ],
                                [
                                    83.442,
                                    -41.502
                                ],
                                [
                                    49.808,
                                    -116.05
                                ],
                                [
                                    22.183,
                                    -121.611
                                ],
                                [
                                    -14.96,
                                    -123.378
                                ],
                                [
                                    -90.031,
                                    -56.594
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0,
                        "y": 1
                    },
                    "o": {
                        "x": 0.331,
                        "y": 0
                    },
                    "t": 19,
                    "s": [
                        {
                            "i": [
                                [
                                    -5.307,
                                    -38.795
                                ],
                                [
                                    -16.544,
                                    1.39
                                ],
                                [
                                    -5.733,
                                    0.121
                                ],
                                [
                                    -4.905,
                                    0.391
                                ],
                                [
                                    -4.821,
                                    0.868
                                ],
                                [
                                    -4.399,
                                    1.344
                                ],
                                [
                                    -4.061,
                                    1.962
                                ],
                                [
                                    -3.521,
                                    2.384
                                ],
                                [
                                    -2.346,
                                    3.644
                                ],
                                [
                                    -1.271,
                                    5.399
                                ],
                                [
                                    -0.718,
                                    5.775
                                ],
                                [
                                    0.277,
                                    4.079
                                ],
                                [
                                    -1.064,
                                    3.246
                                ],
                                [
                                    -1.12,
                                    1.523
                                ],
                                [
                                    -1.469,
                                    1.705
                                ],
                                [
                                    -1.738,
                                    1.815
                                ],
                                [
                                    -0.849,
                                    -0.056
                                ],
                                [
                                    2.318,
                                    -1.905
                                ],
                                [
                                    -5.291,
                                    -5.727
                                ],
                                [
                                    -14.48,
                                    3.726
                                ],
                                [
                                    1.92,
                                    15.646
                                ],
                                [
                                    15.905,
                                    30.649
                                ],
                                [
                                    13.517,
                                    15.391
                                ],
                                [
                                    9.724,
                                    1.43
                                ],
                                [
                                    10.413,
                                    -3.884
                                ],
                                [
                                    9.514,
                                    -20.223
                                ]
                            ],
                            "o": [
                                [
                                    0.777,
                                    5.681
                                ],
                                [
                                    16.544,
                                    -1.39
                                ],
                                [
                                    4.962,
                                    -0.056
                                ],
                                [
                                    4.905,
                                    -0.391
                                ],
                                [
                                    4.665,
                                    -0.845
                                ],
                                [
                                    4.398,
                                    -1.344
                                ],
                                [
                                    3.844,
                                    -1.881
                                ],
                                [
                                    3.521,
                                    -2.384
                                ],
                                [
                                    2.898,
                                    -4.43
                                ],
                                [
                                    1.271,
                                    -5.399
                                ],
                                [
                                    0.562,
                                    -4.055
                                ],
                                [
                                    -0.277,
                                    -4.079
                                ],
                                [
                                    1.023,
                                    -3.194
                                ],
                                [
                                    1.12,
                                    -1.523
                                ],
                                [
                                    1.168,
                                    -1.608
                                ],
                                [
                                    1.738,
                                    -1.815
                                ],
                                [
                                    -0.124,
                                    -0.481
                                ],
                                [
                                    1.294,
                                    7.689
                                ],
                                [
                                    14.479,
                                    -12.188
                                ],
                                [
                                    13.375,
                                    -3.701
                                ],
                                [
                                    -5.642,
                                    -35.169
                                ],
                                [
                                    -9.689,
                                    -18.367
                                ],
                                [
                                    -6.735,
                                    -6.517
                                ],
                                [
                                    -11.59,
                                    -1.639
                                ],
                                [
                                    -18.112,
                                    10.494
                                ],
                                [
                                    -9.514,
                                    20.223
                                ]
                            ],
                            "v": [
                                [
                                    -111.865,
                                    129
                                ],
                                [
                                    -79.238,
                                    133.352
                                ],
                                [
                                    -39.178,
                                    129
                                ],
                                [
                                    -24.371,
                                    128.364
                                ],
                                [
                                    -9.775,
                                    126.51
                                ],
                                [
                                    3.839,
                                    123.256
                                ],
                                [
                                    16.545,
                                    118.327
                                ],
                                [
                                    27.805,
                                    112.119
                                ],
                                [
                                    36.817,
                                    103.267
                                ],
                                [
                                    42.802,
                                    88.375
                                ],
                                [
                                    45.517,
                                    71.467
                                ],
                                [
                                    45.4,
                                    59.052
                                ],
                                [
                                    46.035,
                                    47.85
                                ],
                                [
                                    49.186,
                                    41.237
                                ],
                                [
                                    53.007,
                                    36.858
                                ],
                                [
                                    57.731,
                                    31.204
                                ],
                                [
                                    61.976,
                                    28.046
                                ],
                                [
                                    52.008,
                                    32.333
                                ],
                                [
                                    65.945,
                                    29.508
                                ],
                                [
                                    94.497,
                                    19.388
                                ],
                                [
                                    101.694,
                                    -2.721
                                ],
                                [
                                    83.103,
                                    -61.05
                                ],
                                [
                                    54.344,
                                    -115.177
                                ],
                                [
                                    26.49,
                                    -122.589
                                ],
                                [
                                    -8.128,
                                    -124.847
                                ],
                                [
                                    -93.328,
                                    -83.107
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.667,
                        "y": 1
                    },
                    "o": {
                        "x": 0.333,
                        "y": 0
                    },
                    "t": 29,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -6.003,
                                    0.633
                                ],
                                [
                                    -5.854,
                                    1.267
                                ],
                                [
                                    -5.219,
                                    2.082
                                ],
                                [
                                    -4.587,
                                    2.896
                                ],
                                [
                                    -3.68,
                                    3.831
                                ],
                                [
                                    -2.777,
                                    4.768
                                ],
                                [
                                    -1.388,
                                    5.883
                                ],
                                [
                                    0,
                                    6.999
                                ],
                                [
                                    0.694,
                                    3.801
                                ],
                                [
                                    1.386,
                                    3.38
                                ],
                                [
                                    1.87,
                                    2.957
                                ],
                                [
                                    2.353,
                                    2.534
                                ],
                                [
                                    2.776,
                                    2.082
                                ],
                                [
                                    3.196,
                                    1.629
                                ],
                                [
                                    7.24,
                                    1.21
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -7.726,
                                    9.293
                                ],
                                [
                                    0,
                                    14.242
                                ],
                                [
                                    5.306,
                                    8.45
                                ],
                                [
                                    8.445,
                                    4.949
                                ],
                                [
                                    10.374,
                                    2.053
                                ],
                                [
                                    9.893,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    6.154,
                                    0
                                ],
                                [
                                    6.003,
                                    -0.633
                                ],
                                [
                                    5.852,
                                    -1.267
                                ],
                                [
                                    5.219,
                                    -2.081
                                ],
                                [
                                    4.585,
                                    -2.896
                                ],
                                [
                                    3.68,
                                    -3.831
                                ],
                                [
                                    2.775,
                                    -4.766
                                ],
                                [
                                    1.388,
                                    -5.883
                                ],
                                [
                                    0,
                                    -4.222
                                ],
                                [
                                    -0.694,
                                    -3.801
                                ],
                                [
                                    -1.388,
                                    -3.377
                                ],
                                [
                                    -1.87,
                                    -2.957
                                ],
                                [
                                    -2.353,
                                    -2.534
                                ],
                                [
                                    -2.776,
                                    -2.082
                                ],
                                [
                                    -6.397,
                                    -3.258
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    12.546,
                                    -4.101
                                ],
                                [
                                    7.721,
                                    -9.288
                                ],
                                [
                                    0,
                                    -13.032
                                ],
                                [
                                    -5.311,
                                    -8.445
                                ],
                                [
                                    -8.45,
                                    -4.944
                                ],
                                [
                                    -10.379,
                                    -2.048
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    -98.823,
                                    129
                                ],
                                [
                                    -47.781,
                                    129
                                ],
                                [
                                    3.261,
                                    129
                                ],
                                [
                                    21.495,
                                    128.05
                                ],
                                [
                                    39.28,
                                    125.199
                                ],
                                [
                                    55.885,
                                    120.176
                                ],
                                [
                                    70.593,
                                    112.71
                                ],
                                [
                                    82.99,
                                    102.62
                                ],
                                [
                                    92.675,
                                    89.723
                                ],
                                [
                                    98.919,
                                    73.751
                                ],
                                [
                                    101.001,
                                    54.428
                                ],
                                [
                                    99.959,
                                    42.393
                                ],
                                [
                                    96.838,
                                    31.622
                                ],
                                [
                                    91.95,
                                    22.121
                                ],
                                [
                                    85.616,
                                    13.884
                                ],
                                [
                                    77.922,
                                    6.961
                                ],
                                [
                                    68.964,
                                    1.395
                                ],
                                [
                                    48.511,
                                    -5.302
                                ],
                                [
                                    48.511,
                                    -6.026
                                ],
                                [
                                    78.919,
                                    -26.117
                                ],
                                [
                                    90.503,
                                    -61.412
                                ],
                                [
                                    82.539,
                                    -93.63
                                ],
                                [
                                    61.905,
                                    -113.721
                                ],
                                [
                                    33.669,
                                    -124.219
                                ],
                                [
                                    3.261,
                                    -127.296
                                ],
                                [
                                    -98.823,
                                    -127.296
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.667,
                        "y": 1
                    },
                    "o": {
                        "x": 0.333,
                        "y": 0
                    },
                    "t": 41,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -6.003,
                                    0.633
                                ],
                                [
                                    -5.854,
                                    1.267
                                ],
                                [
                                    -5.219,
                                    2.082
                                ],
                                [
                                    -4.587,
                                    2.896
                                ],
                                [
                                    -3.68,
                                    3.831
                                ],
                                [
                                    -2.777,
                                    4.768
                                ],
                                [
                                    -1.388,
                                    5.883
                                ],
                                [
                                    0,
                                    6.999
                                ],
                                [
                                    0.694,
                                    3.801
                                ],
                                [
                                    1.386,
                                    3.38
                                ],
                                [
                                    1.87,
                                    2.957
                                ],
                                [
                                    2.353,
                                    2.534
                                ],
                                [
                                    2.776,
                                    2.082
                                ],
                                [
                                    3.196,
                                    1.629
                                ],
                                [
                                    7.24,
                                    1.21
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -7.726,
                                    9.293
                                ],
                                [
                                    0,
                                    14.242
                                ],
                                [
                                    5.306,
                                    8.45
                                ],
                                [
                                    8.445,
                                    4.949
                                ],
                                [
                                    10.374,
                                    2.053
                                ],
                                [
                                    9.893,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    6.154,
                                    0
                                ],
                                [
                                    6.003,
                                    -0.633
                                ],
                                [
                                    5.852,
                                    -1.267
                                ],
                                [
                                    5.219,
                                    -2.081
                                ],
                                [
                                    4.585,
                                    -2.896
                                ],
                                [
                                    3.68,
                                    -3.831
                                ],
                                [
                                    2.775,
                                    -4.766
                                ],
                                [
                                    1.388,
                                    -5.883
                                ],
                                [
                                    0,
                                    -4.222
                                ],
                                [
                                    -0.694,
                                    -3.801
                                ],
                                [
                                    -1.388,
                                    -3.377
                                ],
                                [
                                    -1.87,
                                    -2.957
                                ],
                                [
                                    -2.353,
                                    -2.534
                                ],
                                [
                                    -2.776,
                                    -2.082
                                ],
                                [
                                    -6.397,
                                    -3.258
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    12.546,
                                    -4.101
                                ],
                                [
                                    7.721,
                                    -9.288
                                ],
                                [
                                    0,
                                    -13.032
                                ],
                                [
                                    -5.311,
                                    -8.445
                                ],
                                [
                                    -8.45,
                                    -4.944
                                ],
                                [
                                    -10.379,
                                    -2.048
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    -98.823,
                                    129
                                ],
                                [
                                    -47.781,
                                    129
                                ],
                                [
                                    3.261,
                                    129
                                ],
                                [
                                    21.495,
                                    128.05
                                ],
                                [
                                    39.28,
                                    125.199
                                ],
                                [
                                    55.885,
                                    120.176
                                ],
                                [
                                    70.593,
                                    112.71
                                ],
                                [
                                    82.99,
                                    102.62
                                ],
                                [
                                    92.675,
                                    89.723
                                ],
                                [
                                    98.919,
                                    73.751
                                ],
                                [
                                    101.001,
                                    54.428
                                ],
                                [
                                    99.959,
                                    42.393
                                ],
                                [
                                    96.838,
                                    31.622
                                ],
                                [
                                    91.95,
                                    22.121
                                ],
                                [
                                    85.616,
                                    13.884
                                ],
                                [
                                    77.922,
                                    6.961
                                ],
                                [
                                    68.964,
                                    1.395
                                ],
                                [
                                    48.511,
                                    -5.302
                                ],
                                [
                                    48.511,
                                    -6.026
                                ],
                                [
                                    78.919,
                                    -26.117
                                ],
                                [
                                    90.503,
                                    -61.412
                                ],
                                [
                                    82.539,
                                    -93.63
                                ],
                                [
                                    61.905,
                                    -113.721
                                ],
                                [
                                    33.669,
                                    -124.219
                                ],
                                [
                                    3.261,
                                    -127.296
                                ],
                                [
                                    -98.823,
                                    -127.296
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 0.833
                    },
                    "o": {
                        "x": 0.333,
                        "y": 0
                    },
                    "t": 44,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -6.035,
                                    0.115
                                ],
                                [
                                    -5.941,
                                    0.76
                                ],
                                [
                                    -5.378,
                                    1.625
                                ],
                                [
                                    -4.819,
                                    2.492
                                ],
                                [
                                    -3.996,
                                    3.5
                                ],
                                [
                                    -3.176,
                                    4.513
                                ],
                                [
                                    -1.889,
                                    5.741
                                ],
                                [
                                    -0.602,
                                    6.974
                                ],
                                [
                                    1.977,
                                    7.67
                                ],
                                [
                                    1.386,
                                    3.38
                                ],
                                [
                                    1.87,
                                    2.957
                                ],
                                [
                                    2.353,
                                    2.534
                                ],
                                [
                                    2.776,
                                    2.082
                                ],
                                [
                                    3.196,
                                    1.629
                                ],
                                [
                                    7.24,
                                    1.21
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -7.726,
                                    9.293
                                ],
                                [
                                    0.349,
                                    14.238
                                ],
                                [
                                    5.518,
                                    8.314
                                ],
                                [
                                    8.567,
                                    4.734
                                ],
                                [
                                    10.422,
                                    1.792
                                ],
                                [
                                    9.89,
                                    -0.243
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    6.131,
                                    0.529
                                ],
                                [
                                    6.035,
                                    -0.115
                                ],
                                [
                                    5.939,
                                    -0.759
                                ],
                                [
                                    5.379,
                                    -1.625
                                ],
                                [
                                    4.817,
                                    -2.491
                                ],
                                [
                                    3.996,
                                    -3.5
                                ],
                                [
                                    3.174,
                                    -4.509
                                ],
                                [
                                    1.888,
                                    -5.741
                                ],
                                [
                                    0.363,
                                    -4.207
                                ],
                                [
                                    -1.977,
                                    -7.67
                                ],
                                [
                                    -1.388,
                                    -3.377
                                ],
                                [
                                    -1.87,
                                    -2.957
                                ],
                                [
                                    -2.353,
                                    -2.534
                                ],
                                [
                                    -2.776,
                                    -2.082
                                ],
                                [
                                    -6.397,
                                    -3.258
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    12.546,
                                    -4.101
                                ],
                                [
                                    7.721,
                                    -9.288
                                ],
                                [
                                    -0.319,
                                    -13.028
                                ],
                                [
                                    -5.517,
                                    -8.312
                                ],
                                [
                                    -8.569,
                                    -4.735
                                ],
                                [
                                    -10.426,
                                    -1.792
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    -99.728,
                                    127.002
                                ],
                                [
                                    -48.875,
                                    131.39
                                ],
                                [
                                    1.978,
                                    135.779
                                ],
                                [
                                    20.227,
                                    136.4
                                ],
                                [
                                    38.191,
                                    135.089
                                ],
                                [
                                    55.166,
                                    131.514
                                ],
                                [
                                    70.462,
                                    125.339
                                ],
                                [
                                    83.68,
                                    116.353
                                ],
                                [
                                    94.438,
                                    104.335
                                ],
                                [
                                    102.032,
                                    88.959
                                ],
                                [
                                    105.768,
                                    69.887
                                ],
                                [
                                    102.615,
                                    50.134
                                ],
                                [
                                    96.838,
                                    31.622
                                ],
                                [
                                    91.95,
                                    22.121
                                ],
                                [
                                    85.616,
                                    13.884
                                ],
                                [
                                    77.922,
                                    6.961
                                ],
                                [
                                    68.964,
                                    1.395
                                ],
                                [
                                    48.511,
                                    -5.302
                                ],
                                [
                                    48.511,
                                    -6.026
                                ],
                                [
                                    78.919,
                                    -26.117
                                ],
                                [
                                    108.065,
                                    -58.045
                                ],
                                [
                                    99.313,
                                    -90.058
                                ],
                                [
                                    78.193,
                                    -109.637
                                ],
                                [
                                    49.708,
                                    -119.44
                                ],
                                [
                                    19.234,
                                    -121.771
                                ],
                                [
                                    -82.82,
                                    -119.268
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 1
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    },
                    "t": 49,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -1.777,
                                    0.034
                                ],
                                [
                                    -1.749,
                                    0.224
                                ],
                                [
                                    -1.583,
                                    0.478
                                ],
                                [
                                    -1.419,
                                    0.734
                                ],
                                [
                                    -1.177,
                                    1.03
                                ],
                                [
                                    -0.935,
                                    1.329
                                ],
                                [
                                    -0.556,
                                    1.69
                                ],
                                [
                                    -0.177,
                                    2.053
                                ],
                                [
                                    0.582,
                                    2.258
                                ],
                                [
                                    0.408,
                                    0.995
                                ],
                                [
                                    0.551,
                                    0.871
                                ],
                                [
                                    0.693,
                                    0.746
                                ],
                                [
                                    0.817,
                                    0.613
                                ],
                                [
                                    0.941,
                                    0.48
                                ],
                                [
                                    -1.756,
                                    11.489
                                ],
                                [
                                    -3.856,
                                    0.852
                                ],
                                [
                                    -2.275,
                                    2.736
                                ],
                                [
                                    0.085,
                                    17.851
                                ],
                                [
                                    1.625,
                                    2.448
                                ],
                                [
                                    2.522,
                                    1.394
                                ],
                                [
                                    3.069,
                                    0.528
                                ],
                                [
                                    2.912,
                                    -0.072
                                ],
                                [
                                    25.406,
                                    -41.386
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    1.805,
                                    0.156
                                ],
                                [
                                    1.777,
                                    -0.034
                                ],
                                [
                                    1.749,
                                    -0.223
                                ],
                                [
                                    1.584,
                                    -0.478
                                ],
                                [
                                    1.418,
                                    -0.733
                                ],
                                [
                                    1.177,
                                    -1.031
                                ],
                                [
                                    0.934,
                                    -1.328
                                ],
                                [
                                    0.556,
                                    -1.69
                                ],
                                [
                                    0.107,
                                    -1.239
                                ],
                                [
                                    -0.582,
                                    -2.258
                                ],
                                [
                                    -0.409,
                                    -0.994
                                ],
                                [
                                    -0.551,
                                    -0.871
                                ],
                                [
                                    -0.693,
                                    -0.746
                                ],
                                [
                                    -0.817,
                                    -0.613
                                ],
                                [
                                    -1.883,
                                    -0.959
                                ],
                                [
                                    0.93,
                                    -2.663
                                ],
                                [
                                    7.22,
                                    -1.987
                                ],
                                [
                                    2.273,
                                    -2.735
                                ],
                                [
                                    -0.068,
                                    -24.554
                                ],
                                [
                                    -1.624,
                                    -2.447
                                ],
                                [
                                    -2.523,
                                    -1.394
                                ],
                                [
                                    -3.07,
                                    -0.528
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -12.99,
                                    21.16
                                ]
                            ],
                            "v": [
                                [
                                    -102.149,
                                    107.303
                                ],
                                [
                                    -57.162,
                                    119.052
                                ],
                                [
                                    -9.492,
                                    129.039
                                ],
                                [
                                    12.445,
                                    133.595
                                ],
                                [
                                    35.77,
                                    133.895
                                ],
                                [
                                    58.645,
                                    129.182
                                ],
                                [
                                    77.821,
                                    119.406
                                ],
                                [
                                    82.856,
                                    105.832
                                ],
                                [
                                    88.841,
                                    98.298
                                ],
                                [
                                    90.647,
                                    86.209
                                ],
                                [
                                    89.577,
                                    69.068
                                ],
                                [
                                    83.541,
                                    49.845
                                ],
                                [
                                    76.42,
                                    30.419
                                ],
                                [
                                    71.453,
                                    17.668
                                ],
                                [
                                    65.866,
                                    7.211
                                ],
                                [
                                    58.348,
                                    1.745
                                ],
                                [
                                    51.378,
                                    -4.976
                                ],
                                [
                                    45.064,
                                    -17.187
                                ],
                                [
                                    61.252,
                                    -22.793
                                ],
                                [
                                    78.577,
                                    -31.556
                                ],
                                [
                                    109.59,
                                    -64.947
                                ],
                                [
                                    95.928,
                                    -96.385
                                ],
                                [
                                    70.116,
                                    -114.513
                                ],
                                [
                                    37.825,
                                    -121.522
                                ],
                                [
                                    5.361,
                                    -119.594
                                ],
                                [
                                    -84.822,
                                    -74.233
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 0.833
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0
                    },
                    "t": 51,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -5.51,
                                    15.778
                                ],
                                [
                                    -5.465,
                                    1.208
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -0.025,
                                    19.359
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    36.008,
                                    -58.656
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    1.318,
                                    -3.774
                                ],
                                [
                                    4.998,
                                    -1.105
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0.037,
                                    -29.364
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -18.41,
                                    29.99
                                ]
                            ],
                            "v": [
                                [
                                    -103.159,
                                    99.083
                                ],
                                [
                                    -60.62,
                                    113.904
                                ],
                                [
                                    -14.278,
                                    126.226
                                ],
                                [
                                    9.198,
                                    132.425
                                ],
                                [
                                    34.76,
                                    133.397
                                ],
                                [
                                    60.097,
                                    128.209
                                ],
                                [
                                    80.892,
                                    116.93
                                ],
                                [
                                    82.512,
                                    101.442
                                ],
                                [
                                    86.505,
                                    95.779
                                ],
                                [
                                    85.896,
                                    85.062
                                ],
                                [
                                    82.821,
                                    68.726
                                ],
                                [
                                    75.581,
                                    49.724
                                ],
                                [
                                    67.9,
                                    29.917
                                ],
                                [
                                    62.9,
                                    15.81
                                ],
                                [
                                    57.625,
                                    4.426
                                ],
                                [
                                    50.18,
                                    -0.432
                                ],
                                [
                                    44.039,
                                    -7.634
                                ],
                                [
                                    43.625,
                                    -22.147
                                ],
                                [
                                    66.568,
                                    -29.789
                                ],
                                [
                                    78.434,
                                    -33.826
                                ],
                                [
                                    110.226,
                                    -67.827
                                ],
                                [
                                    94.515,
                                    -99.025
                                ],
                                [
                                    66.745,
                                    -116.547
                                ],
                                [
                                    32.867,
                                    -122.391
                                ],
                                [
                                    -0.428,
                                    -118.685
                                ],
                                [
                                    -85.657,
                                    -55.441
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 1
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    },
                    "t": 52,
                    "s": [
                        {
                            "i": [
                                [
                                    -0.598,
                                    -4.904
                                ],
                                [
                                    -2.244,
                                    -2.363
                                ],
                                [
                                    -2.151,
                                    -1.083
                                ],
                                [
                                    -1.558,
                                    -0.387
                                ],
                                [
                                    -1.723,
                                    0.089
                                ],
                                [
                                    -1.661,
                                    0.645
                                ],
                                [
                                    -0.92,
                                    1.315
                                ],
                                [
                                    0.438,
                                    0.572
                                ],
                                [
                                    0.508,
                                    0.343
                                ],
                                [
                                    0.456,
                                    0.269
                                ],
                                [
                                    0.676,
                                    0.449
                                ],
                                [
                                    3.877,
                                    3.228
                                ],
                                [
                                    0.411,
                                    1.115
                                ],
                                [
                                    0.172,
                                    0.99
                                ],
                                [
                                    -0.021,
                                    0.923
                                ],
                                [
                                    -0.201,
                                    0.721
                                ],
                                [
                                    -0.366,
                                    0.444
                                ],
                                [
                                    -5.025,
                                    13.482
                                ],
                                [
                                    -4.979,
                                    1.072
                                ],
                                [
                                    -1.395,
                                    0.101
                                ],
                                [
                                    -5.171,
                                    18.566
                                ],
                                [
                                    1.528,
                                    1.202
                                ],
                                [
                                    1.893,
                                    0.43
                                ],
                                [
                                    2.009,
                                    -0.226
                                ],
                                [
                                    1.62,
                                    -1.033
                                ],
                                [
                                    32.723,
                                    -53.726
                                ]
                            ],
                            "o": [
                                [
                                    0.394,
                                    3.235
                                ],
                                [
                                    1.658,
                                    1.746
                                ],
                                [
                                    1.434,
                                    0.722
                                ],
                                [
                                    1.675,
                                    0.416
                                ],
                                [
                                    1.78,
                                    -0.092
                                ],
                                [
                                    1.496,
                                    -0.581
                                ],
                                [
                                    0.413,
                                    -0.59
                                ],
                                [
                                    -0.372,
                                    -0.487
                                ],
                                [
                                    -0.439,
                                    -0.297
                                ],
                                [
                                    -0.699,
                                    -0.412
                                ],
                                [
                                    -4.203,
                                    -2.79
                                ],
                                [
                                    -0.913,
                                    -0.76
                                ],
                                [
                                    -0.348,
                                    -0.942
                                ],
                                [
                                    -0.158,
                                    -0.91
                                ],
                                [
                                    0.017,
                                    -0.749
                                ],
                                [
                                    0.154,
                                    -0.555
                                ],
                                [
                                    0.239,
                                    -0.29
                                ],
                                [
                                    1.452,
                                    -3.306
                                ],
                                [
                                    5.619,
                                    -1.13
                                ],
                                [
                                    5.572,
                                    -0.405
                                ],
                                [
                                    1.823,
                                    -25.629
                                ],
                                [
                                    -1.526,
                                    -1.2
                                ],
                                [
                                    -1.972,
                                    -0.448
                                ],
                                [
                                    -1.909,
                                    0.215
                                ],
                                [
                                    -3.883,
                                    2.477
                                ],
                                [
                                    -17.976,
                                    29.735
                                ]
                            ],
                            "v": [
                                [
                                    -103.561,
                                    89.742
                                ],
                                [
                                    -62.49,
                                    110.679
                                ],
                                [
                                    -16.938,
                                    124.663
                                ],
                                [
                                    7.394,
                                    131.774
                                ],
                                [
                                    34.199,
                                    133.12
                                ],
                                [
                                    60.904,
                                    127.668
                                ],
                                [
                                    82.599,
                                    115.554
                                ],
                                [
                                    83.055,
                                    100.476
                                ],
                                [
                                    84.991,
                                    94.545
                                ],
                                [
                                    83.09,
                                    84.686
                                ],
                                [
                                    78.405,
                                    69.584
                                ],
                                [
                                    59.716,
                                    45.035
                                ],
                                [
                                    51.723,
                                    25.016
                                ],
                                [
                                    46.705,
                                    10.155
                                ],
                                [
                                    42.035,
                                    -2.252
                                ],
                                [
                                    35.74,
                                    -8.249
                                ],
                                [
                                    31.58,
                                    -16.254
                                ],
                                [
                                    32.78,
                                    -29.407
                                ],
                                [
                                    53.928,
                                    -36.441
                                ],
                                [
                                    66.912,
                                    -39.71
                                ],
                                [
                                    110.58,
                                    -69.428
                                ],
                                [
                                    93.73,
                                    -100.492
                                ],
                                [
                                    64.872,
                                    -117.677
                                ],
                                [
                                    30.111,
                                    -122.874
                                ],
                                [
                                    -3.645,
                                    -118.18
                                ],
                                [
                                    -86.508,
                                    -55.815
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 1
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0
                    },
                    "t": 56,
                    "s": [
                        {
                            "i": [
                                [
                                    -3.911,
                                    -32.083
                                ],
                                [
                                    -14.682,
                                    -15.459
                                ],
                                [
                                    -14.071,
                                    -7.084
                                ],
                                [
                                    -10.196,
                                    -2.532
                                ],
                                [
                                    -11.275,
                                    0.58
                                ],
                                [
                                    -10.869,
                                    4.218
                                ],
                                [
                                    -6.022,
                                    8.602
                                ],
                                [
                                    2.863,
                                    3.745
                                ],
                                [
                                    3.322,
                                    2.244
                                ],
                                [
                                    2.985,
                                    1.759
                                ],
                                [
                                    4.423,
                                    2.936
                                ],
                                [
                                    25.366,
                                    21.121
                                ],
                                [
                                    2.69,
                                    7.292
                                ],
                                [
                                    1.122,
                                    6.475
                                ],
                                [
                                    -0.14,
                                    6.041
                                ],
                                [
                                    -1.314,
                                    4.719
                                ],
                                [
                                    -2.395,
                                    2.907
                                ],
                                [
                                    -2.34,
                                    0.758
                                ],
                                [
                                    -2.286,
                                    0.32
                                ],
                                [
                                    -9.126,
                                    0.663
                                ],
                                [
                                    -33.691,
                                    14.169
                                ],
                                [
                                    9.999,
                                    7.862
                                ],
                                [
                                    12.386,
                                    2.812
                                ],
                                [
                                    13.145,
                                    -1.481
                                ],
                                [
                                    10.598,
                                    -6.76
                                ],
                                [
                                    14.516,
                                    -26.403
                                ]
                            ],
                            "o": [
                                [
                                    2.58,
                                    21.163
                                ],
                                [
                                    10.849,
                                    11.423
                                ],
                                [
                                    9.383,
                                    4.724
                                ],
                                [
                                    10.957,
                                    2.721
                                ],
                                [
                                    11.644,
                                    -0.599
                                ],
                                [
                                    9.789,
                                    -3.799
                                ],
                                [
                                    2.703,
                                    -3.861
                                ],
                                [
                                    -2.434,
                                    -3.185
                                ],
                                [
                                    -2.871,
                                    -1.94
                                ],
                                [
                                    -4.573,
                                    -2.695
                                ],
                                [
                                    -27.501,
                                    -18.254
                                ],
                                [
                                    -5.973,
                                    -4.973
                                ],
                                [
                                    -2.274,
                                    -6.165
                                ],
                                [
                                    -1.032,
                                    -5.954
                                ],
                                [
                                    0.114,
                                    -4.897
                                ],
                                [
                                    1.01,
                                    -3.628
                                ],
                                [
                                    1.564,
                                    -1.898
                                ],
                                [
                                    2.196,
                                    -0.712
                                ],
                                [
                                    9.062,
                                    -1.27
                                ],
                                [
                                    36.454,
                                    -2.65
                                ],
                                [
                                    11.725,
                                    -4.931
                                ],
                                [
                                    -9.984,
                                    -7.85
                                ],
                                [
                                    -12.9,
                                    -2.929
                                ],
                                [
                                    -12.491,
                                    1.408
                                ],
                                [
                                    -25.402,
                                    16.204
                                ],
                                [
                                    -15.571,
                                    28.323
                                ]
                            ],
                            "v": [
                                [
                                    -105.787,
                                    37.973
                                ],
                                [
                                    -72.857,
                                    92.804
                                ],
                                [
                                    -31.679,
                                    116
                                ],
                                [
                                    -2.607,
                                    128.169
                                ],
                                [
                                    31.088,
                                    131.586
                                ],
                                [
                                    65.375,
                                    124.672
                                ],
                                [
                                    92.059,
                                    107.928
                                ],
                                [
                                    86.064,
                                    95.121
                                ],
                                [
                                    76.598,
                                    87.703
                                ],
                                [
                                    67.541,
                                    82.605
                                ],
                                [
                                    53.928,
                                    74.342
                                ],
                                [
                                    -28.217,
                                    19.044
                                ],
                                [
                                    -37.938,
                                    -2.15
                                ],
                                [
                                    -43.057,
                                    -21.187
                                ],
                                [
                                    -44.373,
                                    -39.267
                                ],
                                [
                                    -44.296,
                                    -51.576
                                ],
                                [
                                    -37.476,
                                    -64.033
                                ],
                                [
                                    -27.331,
                                    -69.645
                                ],
                                [
                                    -16.131,
                                    -73.308
                                ],
                                [
                                    3.054,
                                    -72.319
                                ],
                                [
                                    112.54,
                                    -78.299
                                ],
                                [
                                    89.378,
                                    -108.624
                                ],
                                [
                                    54.49,
                                    -123.943
                                ],
                                [
                                    14.838,
                                    -125.549
                                ],
                                [
                                    -21.476,
                                    -115.381
                                ],
                                [
                                    -91.225,
                                    -57.89
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.667,
                        "y": 1
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0
                    },
                    "t": 61,
                    "s": [
                        {
                            "i": [
                                [
                                    -8.448,
                                    -39.541
                                ],
                                [
                                    -3.89,
                                    -9.251
                                ],
                                [
                                    -6.838,
                                    -6.637
                                ],
                                [
                                    -9.32,
                                    -3.62
                                ],
                                [
                                    -10.995,
                                    0
                                ],
                                [
                                    -9.251,
                                    4.223
                                ],
                                [
                                    -6.304,
                                    7.24
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    5.094,
                                    -2.68
                                ],
                                [
                                    6.838,
                                    0
                                ],
                                [
                                    4.959,
                                    2.146
                                ],
                                [
                                    3.551,
                                    3.821
                                ],
                                [
                                    2.011,
                                    5.364
                                ],
                                [
                                    0,
                                    6.436
                                ],
                                [
                                    -2.011,
                                    5.298
                                ],
                                [
                                    -3.62,
                                    3.89
                                ],
                                [
                                    -5.028,
                                    2.212
                                ],
                                [
                                    -6.033,
                                    0
                                ],
                                [
                                    -5.229,
                                    -2.143
                                ],
                                [
                                    -3.218,
                                    -4.424
                                ],
                                [
                                    -0.011,
                                    8.604
                                ],
                                [
                                    9.719,
                                    3.554
                                ],
                                [
                                    10.056,
                                    0
                                ],
                                [
                                    9.383,
                                    -3.485
                                ],
                                [
                                    6.97,
                                    -6.502
                                ],
                                [
                                    19.957,
                                    -35.321
                                ]
                            ],
                            "o": [
                                [
                                    7.108,
                                    31.269
                                ],
                                [
                                    3.887,
                                    9.251
                                ],
                                [
                                    6.838,
                                    6.637
                                ],
                                [
                                    9.317,
                                    3.62
                                ],
                                [
                                    12.067,
                                    0
                                ],
                                [
                                    9.251,
                                    -4.223
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -3.086,
                                    4.292
                                ],
                                [
                                    -5.097,
                                    2.684
                                ],
                                [
                                    -5.901,
                                    0
                                ],
                                [
                                    -4.962,
                                    -2.143
                                ],
                                [
                                    -3.554,
                                    -3.821
                                ],
                                [
                                    -2.011,
                                    -5.361
                                ],
                                [
                                    0,
                                    -6.3
                                ],
                                [
                                    2.011,
                                    -5.295
                                ],
                                [
                                    3.62,
                                    -3.887
                                ],
                                [
                                    5.028,
                                    -2.212
                                ],
                                [
                                    5.496,
                                    0
                                ],
                                [
                                    5.229,
                                    2.146
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -6.017,
                                    -19.888
                                ],
                                [
                                    -9.723,
                                    -3.551
                                ],
                                [
                                    -10.86,
                                    0
                                ],
                                [
                                    -9.386,
                                    3.488
                                ],
                                [
                                    -6.973,
                                    6.505
                                ],
                                [
                                    -12.139,
                                    22.58
                                ]
                            ],
                            "v": [
                                [
                                    -110.505,
                                    39.309
                                ],
                                [
                                    -85.766,
                                    76.465
                                ],
                                [
                                    -49.081,
                                    105.773
                                ],
                                [
                                    -14.413,
                                    123.913
                                ],
                                [
                                    27.416,
                                    129.775
                                ],
                                [
                                    70.653,
                                    121.135
                                ],
                                [
                                    103.225,
                                    98.927
                                ],
                                [
                                    80.012,
                                    69.519
                                ],
                                [
                                    69.519,
                                    77.459
                                ],
                                [
                                    51.35,
                                    76.719
                                ],
                                [
                                    33.693,
                                    66.24
                                ],
                                [
                                    17.705,
                                    48.846
                                ],
                                [
                                    5.945,
                                    26.266
                                ],
                                [
                                    0.706,
                                    2.299
                                ],
                                [
                                    1.985,
                                    -20.871
                                ],
                                [
                                    9.108,
                                    -39.361
                                ],
                                [
                                    21.057,
                                    -51.984
                                ],
                                [
                                    33.166,
                                    -58.212
                                ],
                                [
                                    51.907,
                                    -54.719
                                ],
                                [
                                    77.395,
                                    -50.33
                                ],
                                [
                                    114.854,
                                    -88.771
                                ],
                                [
                                    84.241,
                                    -118.223
                                ],
                                [
                                    42.235,
                                    -131.34
                                ],
                                [
                                    -3.19,
                                    -128.708
                                ],
                                [
                                    -42.523,
                                    -112.078
                                ],
                                [
                                    -96.794,
                                    -60.339
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "t": 69,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    -20.51
                                ],
                                [
                                    -7.002,
                                    -16.652
                                ],
                                [
                                    -12.308,
                                    -11.946
                                ],
                                [
                                    -16.776,
                                    -6.516
                                ],
                                [
                                    -19.791,
                                    0
                                ],
                                [
                                    -16.652,
                                    7.602
                                ],
                                [
                                    -11.346,
                                    13.032
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    9.169,
                                    -4.825
                                ],
                                [
                                    12.308,
                                    0
                                ],
                                [
                                    8.926,
                                    3.863
                                ],
                                [
                                    6.392,
                                    6.878
                                ],
                                [
                                    3.62,
                                    9.655
                                ],
                                [
                                    0,
                                    11.584
                                ],
                                [
                                    -3.62,
                                    9.536
                                ],
                                [
                                    -6.516,
                                    7.002
                                ],
                                [
                                    -9.05,
                                    3.982
                                ],
                                [
                                    -10.86,
                                    0
                                ],
                                [
                                    -9.412,
                                    -3.858
                                ],
                                [
                                    -5.792,
                                    -7.964
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    17.495,
                                    6.397
                                ],
                                [
                                    18.1,
                                    0
                                ],
                                [
                                    16.89,
                                    -6.273
                                ],
                                [
                                    12.546,
                                    -11.703
                                ],
                                [
                                    7.116,
                                    -16.652
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    20.034
                                ],
                                [
                                    6.997,
                                    16.652
                                ],
                                [
                                    12.308,
                                    11.946
                                ],
                                [
                                    16.771,
                                    6.516
                                ],
                                [
                                    21.72,
                                    0
                                ],
                                [
                                    16.652,
                                    -7.602
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -5.554,
                                    7.726
                                ],
                                [
                                    -9.174,
                                    4.83
                                ],
                                [
                                    -10.622,
                                    0
                                ],
                                [
                                    -8.931,
                                    -3.858
                                ],
                                [
                                    -6.397,
                                    -6.878
                                ],
                                [
                                    -3.62,
                                    -9.65
                                ],
                                [
                                    0,
                                    -11.341
                                ],
                                [
                                    3.62,
                                    -9.531
                                ],
                                [
                                    6.516,
                                    -6.997
                                ],
                                [
                                    9.05,
                                    -3.982
                                ],
                                [
                                    9.893,
                                    0
                                ],
                                [
                                    9.412,
                                    3.863
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -10.86,
                                    -12.308
                                ],
                                [
                                    -17.5,
                                    -6.392
                                ],
                                [
                                    -19.548,
                                    0
                                ],
                                [
                                    -16.895,
                                    6.278
                                ],
                                [
                                    -12.551,
                                    11.708
                                ],
                                [
                                    -7.121,
                                    16.652
                                ]
                            ],
                            "v": [
                                [
                                    -116.382,
                                    -8.51
                                ],
                                [
                                    -105.884,
                                    46.514
                                ],
                                [
                                    -76.924,
                                    89.411
                                ],
                                [
                                    -33.303,
                                    117.104
                                ],
                                [
                                    21.54,
                                    126.878
                                ],
                                [
                                    79.098,
                                    115.475
                                ],
                                [
                                    121.09,
                                    84.524
                                ],
                                [
                                    78.012,
                                    43.98
                                ],
                                [
                                    55.93,
                                    62.804
                                ],
                                [
                                    23.712,
                                    70.044
                                ],
                                [
                                    -5.61,
                                    64.252
                                ],
                                [
                                    -28.597,
                                    48.143
                                ],
                                [
                                    -43.62,
                                    23.346
                                ],
                                [
                                    -49.05,
                                    -8.51
                                ],
                                [
                                    -43.62,
                                    -39.823
                                ],
                                [
                                    -28.416,
                                    -64.62
                                ],
                                [
                                    -5.067,
                                    -81.091
                                ],
                                [
                                    24.798,
                                    -87.064
                                ],
                                [
                                    53.758,
                                    -81.272
                                ],
                                [
                                    76.564,
                                    -63.534
                                ],
                                [
                                    118.556,
                                    -105.526
                                ],
                                [
                                    76.021,
                                    -133.581
                                ],
                                [
                                    22.626,
                                    -143.174
                                ],
                                [
                                    -32.036,
                                    -133.762
                                ],
                                [
                                    -76.2,
                                    -106.793
                                ],
                                [
                                    -105.703,
                                    -64.258
                                ]
                            ],
                            "c": true
                        }
                    ]
                }
            ],
            "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
    }
    const hole1 = {
        "ind": 1,
        "ty": "sh",
        "ix": 2,
        "ks": {
            "a": 1,
            "k": [
                {
                    "i": {
                        "x": 0.99,
                        "y": 0.99
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0
                    },
                    "t": 0,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    -96.314,
                                    83.088
                                ],
                                [
                                    -96.289,
                                    83.109
                                ],
                                [
                                    -96.259,
                                    83.134
                                ],
                                [
                                    -96.223,
                                    83.164
                                ],
                                [
                                    -96.178,
                                    83.202
                                ],
                                [
                                    -96.122,
                                    83.249
                                ],
                                [
                                    -96.048,
                                    83.31
                                ],
                                [
                                    -95.95,
                                    83.392
                                ],
                                [
                                    -95.812,
                                    83.508
                                ],
                                [
                                    -95.614,
                                    83.673
                                ],
                                [
                                    -95.417,
                                    83.838
                                ],
                                [
                                    -95.421,
                                    83.829
                                ],
                                [
                                    -95.425,
                                    83.817
                                ],
                                [
                                    -95.431,
                                    83.803
                                ],
                                [
                                    -95.439,
                                    83.784
                                ],
                                [
                                    -95.449,
                                    83.759
                                ],
                                [
                                    -95.463,
                                    83.723
                                ],
                                [
                                    -95.484,
                                    83.673
                                ],
                                [
                                    -95.504,
                                    83.622
                                ],
                                [
                                    -95.539,
                                    83.599
                                ],
                                [
                                    -95.582,
                                    83.571
                                ],
                                [
                                    -95.636,
                                    83.535
                                ],
                                [
                                    -95.707,
                                    83.489
                                ],
                                [
                                    -95.801,
                                    83.426
                                ],
                                [
                                    -95.934,
                                    83.338
                                ],
                                [
                                    -96.124,
                                    83.213
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 0.833
                    },
                    "o": {
                        "x": 0.333,
                        "y": 0
                    },
                    "t": 4,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    -96.314,
                                    83.088
                                ],
                                [
                                    -96.289,
                                    83.109
                                ],
                                [
                                    -96.259,
                                    83.134
                                ],
                                [
                                    -96.223,
                                    83.164
                                ],
                                [
                                    -96.178,
                                    83.202
                                ],
                                [
                                    -96.122,
                                    83.249
                                ],
                                [
                                    -96.048,
                                    83.31
                                ],
                                [
                                    -95.95,
                                    83.392
                                ],
                                [
                                    -95.812,
                                    83.508
                                ],
                                [
                                    -95.614,
                                    83.673
                                ],
                                [
                                    -95.417,
                                    83.838
                                ],
                                [
                                    -95.421,
                                    83.829
                                ],
                                [
                                    -95.425,
                                    83.817
                                ],
                                [
                                    -95.431,
                                    83.803
                                ],
                                [
                                    -95.439,
                                    83.784
                                ],
                                [
                                    -95.449,
                                    83.759
                                ],
                                [
                                    -95.463,
                                    83.723
                                ],
                                [
                                    -95.484,
                                    83.673
                                ],
                                [
                                    -95.504,
                                    83.622
                                ],
                                [
                                    -95.539,
                                    83.599
                                ],
                                [
                                    -95.582,
                                    83.571
                                ],
                                [
                                    -95.636,
                                    83.535
                                ],
                                [
                                    -95.707,
                                    83.489
                                ],
                                [
                                    -95.801,
                                    83.426
                                ],
                                [
                                    -95.934,
                                    83.338
                                ],
                                [
                                    -96.124,
                                    83.213
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 0.833
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    },
                    "t": 13,
                    "s": [
                        {
                            "i": [
                                [
                                    0.945,
                                    -0.695
                                ],
                                [
                                    0.568,
                                    -0.261
                                ],
                                [
                                    0.664,
                                    -0.174
                                ],
                                [
                                    0.76,
                                    -0.087
                                ],
                                [
                                    0.856,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -0.845,
                                    -0.074
                                ],
                                [
                                    -0.727,
                                    -0.147
                                ],
                                [
                                    -0.609,
                                    -0.221
                                ],
                                [
                                    -0.491,
                                    -0.294
                                ],
                                [
                                    -0.368,
                                    -0.381
                                ],
                                [
                                    -0.245,
                                    -0.468
                                ],
                                [
                                    -0.123,
                                    -0.555
                                ],
                                [
                                    0,
                                    -0.642
                                ],
                                [
                                    0.473,
                                    -1.025
                                ]
                            ],
                            "o": [
                                [
                                    -0.473,
                                    0.348
                                ],
                                [
                                    -0.568,
                                    0.261
                                ],
                                [
                                    -0.664,
                                    0.174
                                ],
                                [
                                    -0.76,
                                    0.087
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0.963,
                                    0
                                ],
                                [
                                    0.845,
                                    0.074
                                ],
                                [
                                    0.727,
                                    0.147
                                ],
                                [
                                    0.609,
                                    0.221
                                ],
                                [
                                    0.49,
                                    0.294
                                ],
                                [
                                    0.368,
                                    0.381
                                ],
                                [
                                    0.245,
                                    0.468
                                ],
                                [
                                    0.123,
                                    0.555
                                ],
                                [
                                    0,
                                    1.356
                                ],
                                [
                                    -0.473,
                                    1.025
                                ]
                            ],
                            "v": [
                                [
                                    -61.585,
                                    80.913
                                ],
                                [
                                    -63.13,
                                    81.84
                                ],
                                [
                                    -64.957,
                                    82.51
                                ],
                                [
                                    -67.069,
                                    82.923
                                ],
                                [
                                    -69.461,
                                    83.08
                                ],
                                [
                                    -70.96,
                                    83.113
                                ],
                                [
                                    -73.061,
                                    83.156
                                ],
                                [
                                    -76.068,
                                    83.213
                                ],
                                [
                                    -79.047,
                                    83.295
                                ],
                                [
                                    -78.908,
                                    80.971
                                ],
                                [
                                    -78.769,
                                    77.669
                                ],
                                [
                                    -78.772,
                                    72.781
                                ],
                                [
                                    -78.775,
                                    67.891
                                ],
                                [
                                    -77.374,
                                    67.881
                                ],
                                [
                                    -75.414,
                                    67.868
                                ],
                                [
                                    -72.612,
                                    67.85
                                ],
                                [
                                    -69.813,
                                    67.825
                                ],
                                [
                                    -67.117,
                                    67.9
                                ],
                                [
                                    -64.774,
                                    68.195
                                ],
                                [
                                    -62.795,
                                    68.731
                                ],
                                [
                                    -61.177,
                                    69.483
                                ],
                                [
                                    -59.928,
                                    70.471
                                ],
                                [
                                    -59.058,
                                    71.713
                                ],
                                [
                                    -58.572,
                                    73.203
                                ],
                                [
                                    -58.482,
                                    74.937
                                ],
                                [
                                    -59.326,
                                    78.42
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.667,
                        "y": 1
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    },
                    "t": 19,
                    "s": [
                        {
                            "i": [
                                [
                                    2.035,
                                    -1.498
                                ],
                                [
                                    1.225,
                                    -0.562
                                ],
                                [
                                    1.431,
                                    -0.374
                                ],
                                [
                                    1.638,
                                    -0.187
                                ],
                                [
                                    1.844,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -1.82,
                                    -0.159
                                ],
                                [
                                    -1.565,
                                    -0.317
                                ],
                                [
                                    -1.311,
                                    -0.476
                                ],
                                [
                                    -1.057,
                                    -0.634
                                ],
                                [
                                    -0.792,
                                    -0.821
                                ],
                                [
                                    -0.529,
                                    -1.009
                                ],
                                [
                                    -0.264,
                                    -1.196
                                ],
                                [
                                    0,
                                    -1.383
                                ],
                                [
                                    1.018,
                                    -2.209
                                ]
                            ],
                            "o": [
                                [
                                    -1.018,
                                    0.749
                                ],
                                [
                                    -1.225,
                                    0.562
                                ],
                                [
                                    -1.431,
                                    0.374
                                ],
                                [
                                    -1.638,
                                    0.187
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    2.075,
                                    0
                                ],
                                [
                                    1.82,
                                    0.159
                                ],
                                [
                                    1.565,
                                    0.317
                                ],
                                [
                                    1.311,
                                    0.476
                                ],
                                [
                                    1.056,
                                    0.634
                                ],
                                [
                                    0.792,
                                    0.821
                                ],
                                [
                                    0.529,
                                    1.009
                                ],
                                [
                                    0.264,
                                    1.196
                                ],
                                [
                                    0,
                                    2.921
                                ],
                                [
                                    -1.018,
                                    2.209
                                ]
                            ],
                            "v": [
                                [
                                    -21.497,
                                    78.402
                                ],
                                [
                                    -24.852,
                                    80.376
                                ],
                                [
                                    -28.824,
                                    81.79
                                ],
                                [
                                    -33.415,
                                    82.644
                                ],
                                [
                                    -38.62,
                                    82.938
                                ],
                                [
                                    -41.914,
                                    82.955
                                ],
                                [
                                    -46.526,
                                    82.978
                                ],
                                [
                                    -53.118,
                                    83.007
                                ],
                                [
                                    -59.695,
                                    83.05
                                ],
                                [
                                    -59.623,
                                    77.851
                                ],
                                [
                                    -59.551,
                                    70.548
                                ],
                                [
                                    -59.553,
                                    60.028
                                ],
                                [
                                    -59.554,
                                    49.507
                                ],
                                [
                                    -56.531,
                                    49.502
                                ],
                                [
                                    -52.298,
                                    49.495
                                ],
                                [
                                    -46.251,
                                    49.486
                                ],
                                [
                                    -40.205,
                                    49.473
                                ],
                                [
                                    -34.371,
                                    49.692
                                ],
                                [
                                    -29.3,
                                    50.387
                                ],
                                [
                                    -24.998,
                                    51.567
                                ],
                                [
                                    -21.461,
                                    53.221
                                ],
                                [
                                    -18.708,
                                    55.391
                                ],
                                [
                                    -16.752,
                                    58.119
                                ],
                                [
                                    -15.598,
                                    61.402
                                ],
                                [
                                    -15.25,
                                    65.238
                                ],
                                [
                                    -16.847,
                                    72.887
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.667,
                        "y": 1
                    },
                    "o": {
                        "x": 0.333,
                        "y": 0
                    },
                    "t": 29,
                    "s": [
                        {
                            "i": [
                                [
                                    3.196,
                                    -2.353
                                ],
                                [
                                    1.923,
                                    -0.882
                                ],
                                [
                                    2.247,
                                    -0.588
                                ],
                                [
                                    2.572,
                                    -0.294
                                ],
                                [
                                    2.896,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -2.858,
                                    -0.249
                                ],
                                [
                                    -2.458,
                                    -0.498
                                ],
                                [
                                    -2.059,
                                    -0.747
                                ],
                                [
                                    -1.66,
                                    -0.995
                                ],
                                [
                                    -1.244,
                                    -1.29
                                ],
                                [
                                    -0.83,
                                    -1.584
                                ],
                                [
                                    -0.415,
                                    -1.878
                                ],
                                [
                                    0,
                                    -2.172
                                ],
                                [
                                    1.599,
                                    -3.469
                                ]
                            ],
                            "o": [
                                [
                                    -1.599,
                                    1.177
                                ],
                                [
                                    -1.923,
                                    0.882
                                ],
                                [
                                    -2.247,
                                    0.588
                                ],
                                [
                                    -2.572,
                                    0.294
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    3.258,
                                    0
                                ],
                                [
                                    2.858,
                                    0.249
                                ],
                                [
                                    2.458,
                                    0.498
                                ],
                                [
                                    2.059,
                                    0.747
                                ],
                                [
                                    1.659,
                                    0.995
                                ],
                                [
                                    1.244,
                                    1.29
                                ],
                                [
                                    0.83,
                                    1.584
                                ],
                                [
                                    0.415,
                                    1.878
                                ],
                                [
                                    0,
                                    4.587
                                ],
                                [
                                    -1.599,
                                    3.469
                                ]
                            ],
                            "v": [
                                [
                                    21.18,
                                    75.729
                                ],
                                [
                                    15.896,
                                    78.817
                                ],
                                [
                                    9.641,
                                    81.023
                                ],
                                [
                                    2.412,
                                    82.347
                                ],
                                [
                                    -5.789,
                                    82.788
                                ],
                                [
                                    -10.993,
                                    82.788
                                ],
                                [
                                    -18.278,
                                    82.788
                                ],
                                [
                                    -28.686,
                                    82.788
                                ],
                                [
                                    -39.093,
                                    82.788
                                ],
                                [
                                    -39.093,
                                    74.53
                                ],
                                [
                                    -39.093,
                                    62.968
                                ],
                                [
                                    -39.093,
                                    46.452
                                ],
                                [
                                    -39.093,
                                    29.936
                                ],
                                [
                                    -34.342,
                                    29.936
                                ],
                                [
                                    -27.69,
                                    29.936
                                ],
                                [
                                    -18.188,
                                    29.936
                                ],
                                [
                                    -8.685,
                                    29.936
                                ],
                                [
                                    0.489,
                                    30.309
                                ],
                                [
                                    8.463,
                                    31.429
                                ],
                                [
                                    15.24,
                                    33.296
                                ],
                                [
                                    20.818,
                                    35.909
                                ],
                                [
                                    25.173,
                                    39.337
                                ],
                                [
                                    28.284,
                                    43.647
                                ],
                                [
                                    30.151,
                                    48.839
                                ],
                                [
                                    30.773,
                                    54.914
                                ],
                                [
                                    28.373,
                                    66.997
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.667,
                        "y": 1
                    },
                    "o": {
                        "x": 0.333,
                        "y": 0
                    },
                    "t": 41,
                    "s": [
                        {
                            "i": [
                                [
                                    3.196,
                                    -2.353
                                ],
                                [
                                    1.923,
                                    -0.882
                                ],
                                [
                                    2.247,
                                    -0.588
                                ],
                                [
                                    2.572,
                                    -0.294
                                ],
                                [
                                    2.896,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -2.858,
                                    -0.249
                                ],
                                [
                                    -2.458,
                                    -0.498
                                ],
                                [
                                    -2.059,
                                    -0.747
                                ],
                                [
                                    -1.66,
                                    -0.995
                                ],
                                [
                                    -1.244,
                                    -1.29
                                ],
                                [
                                    -0.83,
                                    -1.584
                                ],
                                [
                                    -0.415,
                                    -1.878
                                ],
                                [
                                    0,
                                    -2.172
                                ],
                                [
                                    1.599,
                                    -3.469
                                ]
                            ],
                            "o": [
                                [
                                    -1.599,
                                    1.177
                                ],
                                [
                                    -1.923,
                                    0.882
                                ],
                                [
                                    -2.247,
                                    0.588
                                ],
                                [
                                    -2.572,
                                    0.294
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    3.258,
                                    0
                                ],
                                [
                                    2.858,
                                    0.249
                                ],
                                [
                                    2.458,
                                    0.498
                                ],
                                [
                                    2.059,
                                    0.747
                                ],
                                [
                                    1.659,
                                    0.995
                                ],
                                [
                                    1.244,
                                    1.29
                                ],
                                [
                                    0.83,
                                    1.584
                                ],
                                [
                                    0.415,
                                    1.878
                                ],
                                [
                                    0,
                                    4.587
                                ],
                                [
                                    -1.599,
                                    3.469
                                ]
                            ],
                            "v": [
                                [
                                    21.18,
                                    75.729
                                ],
                                [
                                    15.896,
                                    78.817
                                ],
                                [
                                    9.641,
                                    81.023
                                ],
                                [
                                    2.412,
                                    82.347
                                ],
                                [
                                    -5.789,
                                    82.788
                                ],
                                [
                                    -10.993,
                                    82.788
                                ],
                                [
                                    -18.278,
                                    82.788
                                ],
                                [
                                    -28.686,
                                    82.788
                                ],
                                [
                                    -39.093,
                                    82.788
                                ],
                                [
                                    -39.093,
                                    74.53
                                ],
                                [
                                    -39.093,
                                    62.968
                                ],
                                [
                                    -39.093,
                                    46.452
                                ],
                                [
                                    -39.093,
                                    29.936
                                ],
                                [
                                    -34.342,
                                    29.936
                                ],
                                [
                                    -27.69,
                                    29.936
                                ],
                                [
                                    -18.188,
                                    29.936
                                ],
                                [
                                    -8.685,
                                    29.936
                                ],
                                [
                                    0.489,
                                    30.309
                                ],
                                [
                                    8.463,
                                    31.429
                                ],
                                [
                                    15.24,
                                    33.296
                                ],
                                [
                                    20.818,
                                    35.909
                                ],
                                [
                                    25.173,
                                    39.337
                                ],
                                [
                                    28.284,
                                    43.647
                                ],
                                [
                                    30.151,
                                    48.839
                                ],
                                [
                                    30.773,
                                    54.914
                                ],
                                [
                                    28.373,
                                    66.997
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 1
                    },
                    "o": {
                        "x": 0.333,
                        "y": 0
                    },
                    "t": 44,
                    "s": [
                        {
                            "i": [
                                [
                                    3.196,
                                    -2.353
                                ],
                                [
                                    1.923,
                                    -0.882
                                ],
                                [
                                    2.247,
                                    -0.588
                                ],
                                [
                                    2.572,
                                    -0.294
                                ],
                                [
                                    2.896,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -2.858,
                                    -0.249
                                ],
                                [
                                    -2.458,
                                    -0.498
                                ],
                                [
                                    -2.059,
                                    -0.747
                                ],
                                [
                                    -1.66,
                                    -0.995
                                ],
                                [
                                    -1.244,
                                    -1.29
                                ],
                                [
                                    -0.83,
                                    -1.584
                                ],
                                [
                                    -0.415,
                                    -1.878
                                ],
                                [
                                    0,
                                    -2.172
                                ],
                                [
                                    1.599,
                                    -3.469
                                ]
                            ],
                            "o": [
                                [
                                    -1.599,
                                    1.177
                                ],
                                [
                                    -1.923,
                                    0.882
                                ],
                                [
                                    -2.247,
                                    0.588
                                ],
                                [
                                    -2.572,
                                    0.294
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    3.258,
                                    0
                                ],
                                [
                                    2.858,
                                    0.249
                                ],
                                [
                                    2.458,
                                    0.498
                                ],
                                [
                                    2.059,
                                    0.747
                                ],
                                [
                                    1.659,
                                    0.995
                                ],
                                [
                                    1.244,
                                    1.29
                                ],
                                [
                                    0.83,
                                    1.584
                                ],
                                [
                                    0.415,
                                    1.878
                                ],
                                [
                                    0,
                                    4.587
                                ],
                                [
                                    -1.599,
                                    3.469
                                ]
                            ],
                            "v": [
                                [
                                    21.18,
                                    75.729
                                ],
                                [
                                    15.896,
                                    78.817
                                ],
                                [
                                    9.641,
                                    81.023
                                ],
                                [
                                    2.412,
                                    82.347
                                ],
                                [
                                    -5.789,
                                    82.788
                                ],
                                [
                                    -10.993,
                                    82.788
                                ],
                                [
                                    -18.278,
                                    82.788
                                ],
                                [
                                    -28.686,
                                    82.788
                                ],
                                [
                                    -39.093,
                                    82.788
                                ],
                                [
                                    -39.093,
                                    74.53
                                ],
                                [
                                    -39.093,
                                    62.968
                                ],
                                [
                                    -39.093,
                                    46.452
                                ],
                                [
                                    -39.093,
                                    29.936
                                ],
                                [
                                    -34.342,
                                    29.936
                                ],
                                [
                                    -27.69,
                                    29.936
                                ],
                                [
                                    -18.188,
                                    29.936
                                ],
                                [
                                    -8.685,
                                    29.936
                                ],
                                [
                                    0.489,
                                    30.309
                                ],
                                [
                                    8.463,
                                    31.429
                                ],
                                [
                                    15.24,
                                    33.296
                                ],
                                [
                                    20.818,
                                    35.909
                                ],
                                [
                                    25.173,
                                    39.337
                                ],
                                [
                                    28.284,
                                    43.647
                                ],
                                [
                                    30.151,
                                    48.839
                                ],
                                [
                                    30.773,
                                    54.914
                                ],
                                [
                                    28.373,
                                    66.997
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 1
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0
                    },
                    "t": 49,
                    "s": [
                        {
                            "i": [
                                [
                                    1.683,
                                    -0.067
                                ],
                                [
                                    1.723,
                                    -0.071
                                ],
                                [
                                    2.967,
                                    -0.092
                                ],
                                [
                                    3.584,
                                    -0.025
                                ],
                                [
                                    3.523,
                                    0.036
                                ],
                                [
                                    0.665,
                                    0.064
                                ],
                                [
                                    0.376,
                                    0.09
                                ],
                                [
                                    0,
                                    0.108
                                ],
                                [
                                    -0.376,
                                    0.089
                                ],
                                [
                                    -0.678,
                                    0.065
                                ],
                                [
                                    -0.941,
                                    0.037
                                ],
                                [
                                    -1.129,
                                    0
                                ],
                                [
                                    -0.979,
                                    -0.036
                                ],
                                [
                                    -0.602,
                                    -0.074
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    1.82,
                                    0.059
                                ],
                                [
                                    1.882,
                                    0
                                ],
                                [
                                    -0.804,
                                    -0.078
                                ],
                                [
                                    -0.897,
                                    -0.149
                                ],
                                [
                                    -1.104,
                                    -0.215
                                ],
                                [
                                    -1.488,
                                    -0.267
                                ],
                                [
                                    -1.843,
                                    -0.258
                                ],
                                [
                                    -2.023,
                                    -0.238
                                ],
                                [
                                    -2.116,
                                    -0.211
                                ],
                                [
                                    -2.058,
                                    -0.174
                                ],
                                [
                                    -0.299,
                                    -0.207
                                ]
                            ],
                            "o": [
                                [
                                    -1.433,
                                    0.094
                                ],
                                [
                                    -2.301,
                                    0.143
                                ],
                                [
                                    -2.968,
                                    0.092
                                ],
                                [
                                    -3.409,
                                    0.024
                                ],
                                [
                                    -0.929,
                                    -0.036
                                ],
                                [
                                    -0.665,
                                    -0.064
                                ],
                                [
                                    -0.376,
                                    -0.09
                                ],
                                [
                                    0,
                                    -0.106
                                ],
                                [
                                    0.376,
                                    -0.089
                                ],
                                [
                                    0.678,
                                    -0.065
                                ],
                                [
                                    0.941,
                                    -0.037
                                ],
                                [
                                    1.029,
                                    0
                                ],
                                [
                                    0.979,
                                    0.036
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -1.129,
                                    -0.115
                                ],
                                [
                                    -1.82,
                                    -0.059
                                ],
                                [
                                    0.886,
                                    0
                                ],
                                [
                                    0.804,
                                    0.078
                                ],
                                [
                                    0.897,
                                    0.149
                                ],
                                [
                                    1.104,
                                    0.215
                                ],
                                [
                                    1.486,
                                    0.266
                                ],
                                [
                                    1.843,
                                    0.258
                                ],
                                [
                                    2.023,
                                    0.238
                                ],
                                [
                                    2.116,
                                    0.211
                                ],
                                [
                                    2.259,
                                    0.368
                                ],
                                [
                                    0.299,
                                    0.207
                                ]
                            ],
                            "v": [
                                [
                                    31.57,
                                    80.912
                                ],
                                [
                                    22.356,
                                    80.782
                                ],
                                [
                                    14.454,
                                    81.134
                                ],
                                [
                                    4.627,
                                    81.307
                                ],
                                [
                                    -5.771,
                                    81.289
                                ],
                                [
                                    -15.622,
                                    81.139
                                ],
                                [
                                    -24.644,
                                    80.908
                                ],
                                [
                                    -32.669,
                                    80.612
                                ],
                                [
                                    -39.564,
                                    80.32
                                ],
                                [
                                    -37.983,
                                    79.031
                                ],
                                [
                                    -35.555,
                                    77.818
                                ],
                                [
                                    -32.449,
                                    76.703
                                ],
                                [
                                    -29.437,
                                    75.698
                                ],
                                [
                                    -20.254,
                                    75.863
                                ],
                                [
                                    -9.075,
                                    75.473
                                ],
                                [
                                    -6.687,
                                    75.212
                                ],
                                [
                                    -5.429,
                                    75.122
                                ],
                                [
                                    -2.894,
                                    75.24
                                ],
                                [
                                    -0.342,
                                    75.58
                                ],
                                [
                                    2.661,
                                    76.126
                                ],
                                [
                                    6.549,
                                    76.854
                                ],
                                [
                                    11.542,
                                    77.641
                                ],
                                [
                                    17.342,
                                    78.385
                                ],
                                [
                                    23.551,
                                    79.059
                                ],
                                [
                                    29.812,
                                    79.637
                                ],
                                [
                                    33.648,
                                    80.5
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 1
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0
                    },
                    "t": 51,
                    "s": [
                        {
                            "i": [
                                [
                                    1.683,
                                    -0.067
                                ],
                                [
                                    1.723,
                                    -0.071
                                ],
                                [
                                    2.967,
                                    -0.092
                                ],
                                [
                                    3.584,
                                    -0.025
                                ],
                                [
                                    3.523,
                                    0.036
                                ],
                                [
                                    0.665,
                                    0.064
                                ],
                                [
                                    0.376,
                                    0.09
                                ],
                                [
                                    0,
                                    0.108
                                ],
                                [
                                    -0.376,
                                    0.089
                                ],
                                [
                                    -0.678,
                                    0.065
                                ],
                                [
                                    -0.941,
                                    0.037
                                ],
                                [
                                    -1.129,
                                    0
                                ],
                                [
                                    -0.979,
                                    -0.036
                                ],
                                [
                                    -0.602,
                                    -0.074
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    1.82,
                                    0.059
                                ],
                                [
                                    1.882,
                                    0
                                ],
                                [
                                    -0.804,
                                    -0.078
                                ],
                                [
                                    -0.897,
                                    -0.149
                                ],
                                [
                                    -1.104,
                                    -0.215
                                ],
                                [
                                    -1.488,
                                    -0.267
                                ],
                                [
                                    -1.843,
                                    -0.258
                                ],
                                [
                                    -2.023,
                                    -0.238
                                ],
                                [
                                    -2.116,
                                    -0.211
                                ],
                                [
                                    -2.058,
                                    -0.174
                                ],
                                [
                                    -0.299,
                                    -0.207
                                ]
                            ],
                            "o": [
                                [
                                    -1.433,
                                    0.094
                                ],
                                [
                                    -2.301,
                                    0.143
                                ],
                                [
                                    -2.968,
                                    0.092
                                ],
                                [
                                    -3.409,
                                    0.024
                                ],
                                [
                                    -0.929,
                                    -0.036
                                ],
                                [
                                    -0.665,
                                    -0.064
                                ],
                                [
                                    -0.376,
                                    -0.09
                                ],
                                [
                                    0,
                                    -0.106
                                ],
                                [
                                    0.376,
                                    -0.089
                                ],
                                [
                                    0.678,
                                    -0.065
                                ],
                                [
                                    0.941,
                                    -0.037
                                ],
                                [
                                    1.029,
                                    0
                                ],
                                [
                                    0.979,
                                    0.036
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -1.129,
                                    -0.115
                                ],
                                [
                                    -1.82,
                                    -0.059
                                ],
                                [
                                    0.886,
                                    0
                                ],
                                [
                                    0.804,
                                    0.078
                                ],
                                [
                                    0.897,
                                    0.149
                                ],
                                [
                                    1.104,
                                    0.215
                                ],
                                [
                                    1.486,
                                    0.266
                                ],
                                [
                                    1.843,
                                    0.258
                                ],
                                [
                                    2.023,
                                    0.238
                                ],
                                [
                                    2.116,
                                    0.211
                                ],
                                [
                                    2.259,
                                    0.368
                                ],
                                [
                                    0.299,
                                    0.207
                                ]
                            ],
                            "v": [
                                [
                                    31.57,
                                    80.912
                                ],
                                [
                                    22.356,
                                    80.782
                                ],
                                [
                                    14.454,
                                    81.134
                                ],
                                [
                                    4.627,
                                    81.307
                                ],
                                [
                                    -5.771,
                                    81.289
                                ],
                                [
                                    -15.622,
                                    81.139
                                ],
                                [
                                    -24.644,
                                    80.908
                                ],
                                [
                                    -32.669,
                                    80.612
                                ],
                                [
                                    -39.564,
                                    80.32
                                ],
                                [
                                    -37.983,
                                    79.031
                                ],
                                [
                                    -35.555,
                                    77.818
                                ],
                                [
                                    -32.449,
                                    76.703
                                ],
                                [
                                    -29.437,
                                    75.698
                                ],
                                [
                                    -20.254,
                                    75.863
                                ],
                                [
                                    -9.075,
                                    75.473
                                ],
                                [
                                    -6.687,
                                    75.212
                                ],
                                [
                                    -5.429,
                                    75.122
                                ],
                                [
                                    -2.894,
                                    75.24
                                ],
                                [
                                    -0.342,
                                    75.58
                                ],
                                [
                                    2.661,
                                    76.126
                                ],
                                [
                                    6.549,
                                    76.854
                                ],
                                [
                                    11.542,
                                    77.641
                                ],
                                [
                                    17.342,
                                    78.385
                                ],
                                [
                                    23.551,
                                    79.059
                                ],
                                [
                                    29.812,
                                    79.637
                                ],
                                [
                                    33.648,
                                    80.5
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 0.833
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0
                    },
                    "t": 52,
                    "s": [
                        {
                            "i": [
                                [
                                    1.683,
                                    -0.067
                                ],
                                [
                                    1.723,
                                    -0.071
                                ],
                                [
                                    2.967,
                                    -0.092
                                ],
                                [
                                    3.584,
                                    -0.025
                                ],
                                [
                                    3.523,
                                    0.036
                                ],
                                [
                                    0.665,
                                    0.064
                                ],
                                [
                                    0.376,
                                    0.09
                                ],
                                [
                                    0,
                                    0.108
                                ],
                                [
                                    -0.376,
                                    0.089
                                ],
                                [
                                    -0.678,
                                    0.065
                                ],
                                [
                                    -0.941,
                                    0.037
                                ],
                                [
                                    -1.129,
                                    0
                                ],
                                [
                                    -0.979,
                                    -0.036
                                ],
                                [
                                    -0.602,
                                    -0.074
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    1.82,
                                    0.059
                                ],
                                [
                                    1.882,
                                    0
                                ],
                                [
                                    -0.804,
                                    -0.078
                                ],
                                [
                                    -0.897,
                                    -0.149
                                ],
                                [
                                    -1.104,
                                    -0.215
                                ],
                                [
                                    -1.488,
                                    -0.267
                                ],
                                [
                                    -1.843,
                                    -0.258
                                ],
                                [
                                    -2.023,
                                    -0.238
                                ],
                                [
                                    -2.116,
                                    -0.211
                                ],
                                [
                                    -2.058,
                                    -0.174
                                ],
                                [
                                    -0.299,
                                    -0.207
                                ]
                            ],
                            "o": [
                                [
                                    -1.433,
                                    0.094
                                ],
                                [
                                    -2.301,
                                    0.143
                                ],
                                [
                                    -2.968,
                                    0.092
                                ],
                                [
                                    -3.409,
                                    0.024
                                ],
                                [
                                    -0.929,
                                    -0.036
                                ],
                                [
                                    -0.665,
                                    -0.064
                                ],
                                [
                                    -0.376,
                                    -0.09
                                ],
                                [
                                    0,
                                    -0.106
                                ],
                                [
                                    0.376,
                                    -0.089
                                ],
                                [
                                    0.678,
                                    -0.065
                                ],
                                [
                                    0.941,
                                    -0.037
                                ],
                                [
                                    1.029,
                                    0
                                ],
                                [
                                    0.979,
                                    0.036
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -1.129,
                                    -0.115
                                ],
                                [
                                    -1.82,
                                    -0.06
                                ],
                                [
                                    0.886,
                                    0
                                ],
                                [
                                    0.804,
                                    0.078
                                ],
                                [
                                    0.897,
                                    0.149
                                ],
                                [
                                    1.104,
                                    0.215
                                ],
                                [
                                    1.486,
                                    0.266
                                ],
                                [
                                    1.843,
                                    0.258
                                ],
                                [
                                    2.023,
                                    0.238
                                ],
                                [
                                    2.116,
                                    0.211
                                ],
                                [
                                    2.259,
                                    0.368
                                ],
                                [
                                    0.299,
                                    0.207
                                ]
                            ],
                            "v": [
                                [
                                    -259.695,
                                    568.602
                                ],
                                [
                                    -268.909,
                                    568.473
                                ],
                                [
                                    -276.811,
                                    568.825
                                ],
                                [
                                    -286.638,
                                    568.998
                                ],
                                [
                                    -297.036,
                                    568.98
                                ],
                                [
                                    -306.887,
                                    568.83
                                ],
                                [
                                    -315.909,
                                    568.599
                                ],
                                [
                                    -323.934,
                                    568.303
                                ],
                                [
                                    -330.829,
                                    568.011
                                ],
                                [
                                    -329.248,
                                    566.721
                                ],
                                [
                                    -326.82,
                                    565.509
                                ],
                                [
                                    -323.714,
                                    564.394
                                ],
                                [
                                    -320.702,
                                    563.389
                                ],
                                [
                                    -311.519,
                                    563.554
                                ],
                                [
                                    -300.34,
                                    563.163
                                ],
                                [
                                    -297.953,
                                    562.902
                                ],
                                [
                                    -296.694,
                                    562.813
                                ],
                                [
                                    -294.159,
                                    562.931
                                ],
                                [
                                    -291.607,
                                    563.271
                                ],
                                [
                                    -288.604,
                                    563.817
                                ],
                                [
                                    -284.716,
                                    564.545
                                ],
                                [
                                    -279.723,
                                    565.332
                                ],
                                [
                                    -273.924,
                                    566.076
                                ],
                                [
                                    -267.714,
                                    566.75
                                ],
                                [
                                    -261.453,
                                    567.328
                                ],
                                [
                                    -257.617,
                                    568.19
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 0.833
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    },
                    "t": 56,
                    "s": [
                        {
                            "i": [
                                [
                                    1.683,
                                    -0.067
                                ],
                                [
                                    1.723,
                                    -0.071
                                ],
                                [
                                    2.967,
                                    -0.092
                                ],
                                [
                                    3.584,
                                    -0.025
                                ],
                                [
                                    3.523,
                                    0.036
                                ],
                                [
                                    0.665,
                                    0.064
                                ],
                                [
                                    0.376,
                                    0.09
                                ],
                                [
                                    0,
                                    0.108
                                ],
                                [
                                    -0.376,
                                    0.089
                                ],
                                [
                                    -0.678,
                                    0.065
                                ],
                                [
                                    -0.941,
                                    0.037
                                ],
                                [
                                    -1.129,
                                    0
                                ],
                                [
                                    -0.979,
                                    -0.036
                                ],
                                [
                                    -0.602,
                                    -0.074
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    1.82,
                                    0.059
                                ],
                                [
                                    1.882,
                                    0
                                ],
                                [
                                    -0.804,
                                    -0.078
                                ],
                                [
                                    -0.897,
                                    -0.149
                                ],
                                [
                                    -1.104,
                                    -0.215
                                ],
                                [
                                    -1.488,
                                    -0.267
                                ],
                                [
                                    -1.843,
                                    -0.258
                                ],
                                [
                                    -2.023,
                                    -0.238
                                ],
                                [
                                    -2.116,
                                    -0.211
                                ],
                                [
                                    -2.058,
                                    -0.174
                                ],
                                [
                                    -0.299,
                                    -0.207
                                ]
                            ],
                            "o": [
                                [
                                    -1.433,
                                    0.094
                                ],
                                [
                                    -2.301,
                                    0.143
                                ],
                                [
                                    -2.968,
                                    0.092
                                ],
                                [
                                    -3.409,
                                    0.024
                                ],
                                [
                                    -0.929,
                                    -0.036
                                ],
                                [
                                    -0.665,
                                    -0.064
                                ],
                                [
                                    -0.376,
                                    -0.09
                                ],
                                [
                                    0,
                                    -0.106
                                ],
                                [
                                    0.376,
                                    -0.089
                                ],
                                [
                                    0.678,
                                    -0.065
                                ],
                                [
                                    0.941,
                                    -0.037
                                ],
                                [
                                    1.029,
                                    0
                                ],
                                [
                                    0.979,
                                    0.036
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -1.129,
                                    -0.115
                                ],
                                [
                                    -1.82,
                                    -0.06
                                ],
                                [
                                    0.886,
                                    0
                                ],
                                [
                                    0.804,
                                    0.078
                                ],
                                [
                                    0.897,
                                    0.149
                                ],
                                [
                                    1.104,
                                    0.215
                                ],
                                [
                                    1.486,
                                    0.266
                                ],
                                [
                                    1.843,
                                    0.258
                                ],
                                [
                                    2.023,
                                    0.238
                                ],
                                [
                                    2.116,
                                    0.211
                                ],
                                [
                                    2.259,
                                    0.368
                                ],
                                [
                                    0.299,
                                    0.207
                                ]
                            ],
                            "v": [
                                [
                                    -259.695,
                                    568.602
                                ],
                                [
                                    -268.909,
                                    568.473
                                ],
                                [
                                    -276.811,
                                    568.825
                                ],
                                [
                                    -286.638,
                                    568.998
                                ],
                                [
                                    -297.036,
                                    568.98
                                ],
                                [
                                    -306.887,
                                    568.83
                                ],
                                [
                                    -315.909,
                                    568.599
                                ],
                                [
                                    -323.934,
                                    568.303
                                ],
                                [
                                    -330.829,
                                    568.011
                                ],
                                [
                                    -329.248,
                                    566.721
                                ],
                                [
                                    -326.82,
                                    565.509
                                ],
                                [
                                    -323.714,
                                    564.394
                                ],
                                [
                                    -320.702,
                                    563.389
                                ],
                                [
                                    -311.519,
                                    563.554
                                ],
                                [
                                    -300.34,
                                    563.163
                                ],
                                [
                                    -297.953,
                                    562.902
                                ],
                                [
                                    -296.694,
                                    562.813
                                ],
                                [
                                    -294.159,
                                    562.931
                                ],
                                [
                                    -291.607,
                                    563.271
                                ],
                                [
                                    -288.604,
                                    563.817
                                ],
                                [
                                    -284.716,
                                    564.545
                                ],
                                [
                                    -279.723,
                                    565.332
                                ],
                                [
                                    -273.924,
                                    566.076
                                ],
                                [
                                    -267.714,
                                    566.75
                                ],
                                [
                                    -261.453,
                                    567.328
                                ],
                                [
                                    -257.617,
                                    568.19
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 1
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    },
                    "t": 61,
                    "s": [
                        {
                            "i": [
                                [
                                    1.683,
                                    -0.067
                                ],
                                [
                                    1.723,
                                    -0.071
                                ],
                                [
                                    2.967,
                                    -0.092
                                ],
                                [
                                    3.584,
                                    -0.025
                                ],
                                [
                                    3.523,
                                    0.036
                                ],
                                [
                                    0.665,
                                    0.064
                                ],
                                [
                                    0.376,
                                    0.09
                                ],
                                [
                                    0,
                                    0.108
                                ],
                                [
                                    -0.376,
                                    0.089
                                ],
                                [
                                    -0.678,
                                    0.065
                                ],
                                [
                                    -0.941,
                                    0.037
                                ],
                                [
                                    -1.129,
                                    0
                                ],
                                [
                                    -0.979,
                                    -0.036
                                ],
                                [
                                    -0.602,
                                    -0.074
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    1.82,
                                    0.059
                                ],
                                [
                                    1.882,
                                    0
                                ],
                                [
                                    -0.804,
                                    -0.078
                                ],
                                [
                                    -0.897,
                                    -0.149
                                ],
                                [
                                    -1.104,
                                    -0.215
                                ],
                                [
                                    -1.488,
                                    -0.267
                                ],
                                [
                                    -1.843,
                                    -0.258
                                ],
                                [
                                    -2.023,
                                    -0.238
                                ],
                                [
                                    -2.116,
                                    -0.211
                                ],
                                [
                                    -2.058,
                                    -0.174
                                ],
                                [
                                    -0.299,
                                    -0.207
                                ]
                            ],
                            "o": [
                                [
                                    -1.433,
                                    0.094
                                ],
                                [
                                    -2.301,
                                    0.143
                                ],
                                [
                                    -2.968,
                                    0.092
                                ],
                                [
                                    -3.409,
                                    0.024
                                ],
                                [
                                    -0.929,
                                    -0.036
                                ],
                                [
                                    -0.665,
                                    -0.064
                                ],
                                [
                                    -0.376,
                                    -0.09
                                ],
                                [
                                    0,
                                    -0.106
                                ],
                                [
                                    0.376,
                                    -0.089
                                ],
                                [
                                    0.678,
                                    -0.065
                                ],
                                [
                                    0.941,
                                    -0.037
                                ],
                                [
                                    1.029,
                                    0
                                ],
                                [
                                    0.979,
                                    0.036
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -1.129,
                                    -0.115
                                ],
                                [
                                    -1.82,
                                    -0.06
                                ],
                                [
                                    0.886,
                                    0
                                ],
                                [
                                    0.804,
                                    0.078
                                ],
                                [
                                    0.897,
                                    0.149
                                ],
                                [
                                    1.104,
                                    0.215
                                ],
                                [
                                    1.486,
                                    0.266
                                ],
                                [
                                    1.843,
                                    0.258
                                ],
                                [
                                    2.023,
                                    0.238
                                ],
                                [
                                    2.116,
                                    0.211
                                ],
                                [
                                    2.259,
                                    0.368
                                ],
                                [
                                    0.299,
                                    0.207
                                ]
                            ],
                            "v": [
                                [
                                    -259.695,
                                    568.602
                                ],
                                [
                                    -268.909,
                                    568.473
                                ],
                                [
                                    -276.811,
                                    568.825
                                ],
                                [
                                    -286.638,
                                    568.998
                                ],
                                [
                                    -297.036,
                                    568.98
                                ],
                                [
                                    -306.887,
                                    568.83
                                ],
                                [
                                    -315.909,
                                    568.599
                                ],
                                [
                                    -323.934,
                                    568.303
                                ],
                                [
                                    -330.829,
                                    568.011
                                ],
                                [
                                    -329.248,
                                    566.721
                                ],
                                [
                                    -326.82,
                                    565.509
                                ],
                                [
                                    -323.714,
                                    564.394
                                ],
                                [
                                    -320.702,
                                    563.389
                                ],
                                [
                                    -311.519,
                                    563.554
                                ],
                                [
                                    -300.34,
                                    563.163
                                ],
                                [
                                    -297.953,
                                    562.902
                                ],
                                [
                                    -296.694,
                                    562.813
                                ],
                                [
                                    -294.159,
                                    562.931
                                ],
                                [
                                    -291.607,
                                    563.271
                                ],
                                [
                                    -288.604,
                                    563.817
                                ],
                                [
                                    -284.716,
                                    564.545
                                ],
                                [
                                    -279.723,
                                    565.332
                                ],
                                [
                                    -273.924,
                                    566.076
                                ],
                                [
                                    -267.714,
                                    566.75
                                ],
                                [
                                    -261.453,
                                    567.328
                                ],
                                [
                                    -257.617,
                                    568.19
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "t": 69,
                    "s": [
                        {
                            "i": [
                                [
                                    1.683,
                                    -0.067
                                ],
                                [
                                    1.723,
                                    -0.071
                                ],
                                [
                                    2.967,
                                    -0.092
                                ],
                                [
                                    3.584,
                                    -0.025
                                ],
                                [
                                    3.523,
                                    0.036
                                ],
                                [
                                    0.665,
                                    0.064
                                ],
                                [
                                    0.376,
                                    0.09
                                ],
                                [
                                    0,
                                    0.108
                                ],
                                [
                                    -0.376,
                                    0.089
                                ],
                                [
                                    -0.678,
                                    0.065
                                ],
                                [
                                    -0.941,
                                    0.037
                                ],
                                [
                                    -1.129,
                                    0
                                ],
                                [
                                    -0.979,
                                    -0.036
                                ],
                                [
                                    -0.602,
                                    -0.074
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    1.82,
                                    0.059
                                ],
                                [
                                    1.882,
                                    0
                                ],
                                [
                                    -0.804,
                                    -0.078
                                ],
                                [
                                    -0.897,
                                    -0.149
                                ],
                                [
                                    -1.104,
                                    -0.215
                                ],
                                [
                                    -1.488,
                                    -0.267
                                ],
                                [
                                    -1.843,
                                    -0.258
                                ],
                                [
                                    -2.023,
                                    -0.238
                                ],
                                [
                                    -2.116,
                                    -0.211
                                ],
                                [
                                    -2.058,
                                    -0.174
                                ],
                                [
                                    -0.299,
                                    -0.207
                                ]
                            ],
                            "o": [
                                [
                                    -1.433,
                                    0.094
                                ],
                                [
                                    -2.301,
                                    0.143
                                ],
                                [
                                    -2.968,
                                    0.092
                                ],
                                [
                                    -3.409,
                                    0.024
                                ],
                                [
                                    -0.929,
                                    -0.036
                                ],
                                [
                                    -0.665,
                                    -0.064
                                ],
                                [
                                    -0.376,
                                    -0.09
                                ],
                                [
                                    0,
                                    -0.106
                                ],
                                [
                                    0.376,
                                    -0.089
                                ],
                                [
                                    0.678,
                                    -0.065
                                ],
                                [
                                    0.941,
                                    -0.037
                                ],
                                [
                                    1.029,
                                    0
                                ],
                                [
                                    0.979,
                                    0.036
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -1.129,
                                    -0.115
                                ],
                                [
                                    -1.82,
                                    -0.06
                                ],
                                [
                                    0.886,
                                    0
                                ],
                                [
                                    0.804,
                                    0.078
                                ],
                                [
                                    0.897,
                                    0.149
                                ],
                                [
                                    1.104,
                                    0.215
                                ],
                                [
                                    1.486,
                                    0.266
                                ],
                                [
                                    1.843,
                                    0.258
                                ],
                                [
                                    2.023,
                                    0.238
                                ],
                                [
                                    2.116,
                                    0.211
                                ],
                                [
                                    2.259,
                                    0.368
                                ],
                                [
                                    0.299,
                                    0.207
                                ]
                            ],
                            "v": [
                                [
                                    -259.695,
                                    568.602
                                ],
                                [
                                    -268.909,
                                    568.473
                                ],
                                [
                                    -276.811,
                                    568.825
                                ],
                                [
                                    -286.638,
                                    568.998
                                ],
                                [
                                    -297.036,
                                    568.98
                                ],
                                [
                                    -306.887,
                                    568.83
                                ],
                                [
                                    -315.909,
                                    568.599
                                ],
                                [
                                    -323.934,
                                    568.303
                                ],
                                [
                                    -330.829,
                                    568.011
                                ],
                                [
                                    -329.248,
                                    566.721
                                ],
                                [
                                    -326.82,
                                    565.509
                                ],
                                [
                                    -323.714,
                                    564.394
                                ],
                                [
                                    -320.702,
                                    563.389
                                ],
                                [
                                    -311.519,
                                    563.554
                                ],
                                [
                                    -300.34,
                                    563.163
                                ],
                                [
                                    -297.953,
                                    562.902
                                ],
                                [
                                    -296.694,
                                    562.813
                                ],
                                [
                                    -294.159,
                                    562.931
                                ],
                                [
                                    -291.607,
                                    563.271
                                ],
                                [
                                    -288.604,
                                    563.817
                                ],
                                [
                                    -284.716,
                                    564.545
                                ],
                                [
                                    -279.723,
                                    565.332
                                ],
                                [
                                    -273.924,
                                    566.076
                                ],
                                [
                                    -267.714,
                                    566.75
                                ],
                                [
                                    -261.453,
                                    567.328
                                ],
                                [
                                    -257.617,
                                    568.19
                                ]
                            ],
                            "c": true
                        }
                    ]
                }
            ],
            "ix": 2
        },
        "nm": "Path 2",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
    }
    const hole2 = {
        "ind": 2,
        "ty": "sh",
        "ix": 3,
        "ks": {
            "a": 1,
            "k": [
                {
                    "i": {
                        "x": 0.99,
                        "y": 0.99
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0
                    },
                    "t": 0,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    31.686,
                                    29.088
                                ],
                                [
                                    30.418,
                                    29.088
                                ],
                                [
                                    28.947,
                                    29.088
                                ],
                                [
                                    27.219,
                                    29.088
                                ],
                                [
                                    25.163,
                                    29.088
                                ],
                                [
                                    22.674,
                                    29.088
                                ],
                                [
                                    19.604,
                                    29.088
                                ],
                                [
                                    15.726,
                                    29.088
                                ],
                                [
                                    10.685,
                                    29.088
                                ],
                                [
                                    3.898,
                                    29.088
                                ],
                                [
                                    -5.603,
                                    29.088
                                ],
                                [
                                    -19.177,
                                    29.088
                                ],
                                [
                                    -32.75,
                                    29.088
                                ],
                                [
                                    -31.35,
                                    25.308
                                ],
                                [
                                    -29.623,
                                    20.645
                                ],
                                [
                                    -27.441,
                                    14.754
                                ],
                                [
                                    -24.605,
                                    7.096
                                ],
                                [
                                    -20.787,
                                    -3.212
                                ],
                                [
                                    -15.442,
                                    -17.644
                                ],
                                [
                                    -7.806,
                                    -38.261
                                ],
                                [
                                    -0.17,
                                    -58.878
                                ],
                                [
                                    1.199,
                                    -55.098
                                ],
                                [
                                    2.888,
                                    -50.435
                                ],
                                [
                                    5.021,
                                    -44.544
                                ],
                                [
                                    7.794,
                                    -36.886
                                ],
                                [
                                    11.527,
                                    -26.578
                                ],
                                [
                                    16.753,
                                    -12.146
                                ],
                                [
                                    24.22,
                                    8.471
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 0.833
                    },
                    "o": {
                        "x": 0.333,
                        "y": 0
                    },
                    "t": 4,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    31.686,
                                    29.088
                                ],
                                [
                                    30.418,
                                    29.088
                                ],
                                [
                                    28.947,
                                    29.088
                                ],
                                [
                                    27.219,
                                    29.088
                                ],
                                [
                                    25.163,
                                    29.088
                                ],
                                [
                                    22.674,
                                    29.088
                                ],
                                [
                                    19.604,
                                    29.088
                                ],
                                [
                                    15.726,
                                    29.088
                                ],
                                [
                                    10.685,
                                    29.088
                                ],
                                [
                                    3.898,
                                    29.088
                                ],
                                [
                                    -5.603,
                                    29.088
                                ],
                                [
                                    -19.177,
                                    29.088
                                ],
                                [
                                    -32.75,
                                    29.088
                                ],
                                [
                                    -31.35,
                                    25.308
                                ],
                                [
                                    -29.623,
                                    20.645
                                ],
                                [
                                    -27.441,
                                    14.754
                                ],
                                [
                                    -24.605,
                                    7.096
                                ],
                                [
                                    -20.787,
                                    -3.212
                                ],
                                [
                                    -15.442,
                                    -17.644
                                ],
                                [
                                    -7.806,
                                    -38.261
                                ],
                                [
                                    -0.17,
                                    -58.878
                                ],
                                [
                                    1.199,
                                    -55.098
                                ],
                                [
                                    2.888,
                                    -50.435
                                ],
                                [
                                    5.021,
                                    -44.544
                                ],
                                [
                                    7.794,
                                    -36.886
                                ],
                                [
                                    11.527,
                                    -26.578
                                ],
                                [
                                    16.753,
                                    -12.146
                                ],
                                [
                                    24.22,
                                    8.471
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 0.833
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    },
                    "t": 13,
                    "s": [
                        {
                            "i": [
                                [
                                    0.392,
                                    -0.588
                                ],
                                [
                                    0.225,
                                    -0.261
                                ],
                                [
                                    0.254,
                                    -0.227
                                ],
                                [
                                    0.283,
                                    -0.194
                                ],
                                [
                                    0.312,
                                    -0.161
                                ],
                                [
                                    0.33,
                                    -0.136
                                ],
                                [
                                    0.348,
                                    -0.111
                                ],
                                [
                                    0.366,
                                    -0.087
                                ],
                                [
                                    0.383,
                                    -0.062
                                ],
                                [
                                    0.377,
                                    -0.047
                                ],
                                [
                                    0.37,
                                    -0.031
                                ],
                                [
                                    0.363,
                                    -0.016
                                ],
                                [
                                    0.356,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -1.57,
                                    -0.303
                                ],
                                [
                                    -1.177,
                                    -0.606
                                ],
                                [
                                    -0.588,
                                    -1.088
                                ],
                                [
                                    0,
                                    -1.569
                                ],
                                [
                                    0.196,
                                    -0.758
                                ]
                            ],
                            "o": [
                                [
                                    -0.197,
                                    0.294
                                ],
                                [
                                    -0.225,
                                    0.261
                                ],
                                [
                                    -0.254,
                                    0.227
                                ],
                                [
                                    -0.283,
                                    0.194
                                ],
                                [
                                    -0.312,
                                    0.16
                                ],
                                [
                                    -0.33,
                                    0.136
                                ],
                                [
                                    -0.348,
                                    0.111
                                ],
                                [
                                    -0.366,
                                    0.087
                                ],
                                [
                                    -0.384,
                                    0.063
                                ],
                                [
                                    -0.377,
                                    0.047
                                ],
                                [
                                    -0.37,
                                    0.031
                                ],
                                [
                                    -0.363,
                                    0.016
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    1.961,
                                    0
                                ],
                                [
                                    1.57,
                                    0.303
                                ],
                                [
                                    1.177,
                                    0.607
                                ],
                                [
                                    0.588,
                                    1.088
                                ],
                                [
                                    0,
                                    0.928
                                ],
                                [
                                    -0.196,
                                    0.758
                                ]
                            ],
                            "v": [
                                [
                                    31.912,
                                    7.491
                                ],
                                [
                                    30.386,
                                    8.324
                                ],
                                [
                                    28.631,
                                    9.056
                                ],
                                [
                                    26.608,
                                    9.688
                                ],
                                [
                                    24.267,
                                    10.22
                                ],
                                [
                                    21.551,
                                    10.664
                                ],
                                [
                                    18.372,
                                    11.036
                                ],
                                [
                                    14.57,
                                    11.333
                                ],
                                [
                                    9.896,
                                    11.557
                                ],
                                [
                                    3.974,
                                    11.721
                                ],
                                [
                                    -3.839,
                                    11.838
                                ],
                                [
                                    -14.5,
                                    11.908
                                ],
                                [
                                    -25.141,
                                    11.932
                                ],
                                [
                                    -25.76,
                                    9.269
                                ],
                                [
                                    -26.79,
                                    5.984
                                ],
                                [
                                    -28.463,
                                    1.834
                                ],
                                [
                                    -29.676,
                                    -3.56
                                ],
                                [
                                    -26.986,
                                    -13.53
                                ],
                                [
                                    -23.221,
                                    -27.488
                                ],
                                [
                                    -17.842,
                                    -47.428
                                ],
                                [
                                    -12.463,
                                    -67.368
                                ],
                                [
                                    -6.416,
                                    -64.705
                                ],
                                [
                                    -0.144,
                                    -61.42
                                ],
                                [
                                    6.655,
                                    -56.815
                                ],
                                [
                                    12.728,
                                    -50.057
                                ],
                                [
                                    18.006,
                                    -40.254
                                ],
                                [
                                    22.57,
                                    -26.103
                                ],
                                [
                                    27.535,
                                    -9.051
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.667,
                        "y": 1
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    },
                    "t": 19,
                    "s": [
                        {
                            "i": [
                                [
                                    0.844,
                                    -1.268
                                ],
                                [
                                    0.485,
                                    -0.562
                                ],
                                [
                                    0.548,
                                    -0.49
                                ],
                                [
                                    0.609,
                                    -0.418
                                ],
                                [
                                    0.672,
                                    -0.346
                                ],
                                [
                                    0.711,
                                    -0.293
                                ],
                                [
                                    0.749,
                                    -0.24
                                ],
                                [
                                    0.788,
                                    -0.187
                                ],
                                [
                                    0.826,
                                    -0.134
                                ],
                                [
                                    0.812,
                                    -0.101
                                ],
                                [
                                    0.797,
                                    -0.067
                                ],
                                [
                                    0.783,
                                    -0.034
                                ],
                                [
                                    0.768,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -3.381,
                                    -0.653
                                ],
                                [
                                    -2.536,
                                    -1.305
                                ],
                                [
                                    -1.268,
                                    -2.343
                                ],
                                [
                                    0,
                                    -3.381
                                ],
                                [
                                    0.423,
                                    -1.633
                                ]
                            ],
                            "o": [
                                [
                                    -0.423,
                                    0.634
                                ],
                                [
                                    -0.485,
                                    0.562
                                ],
                                [
                                    -0.548,
                                    0.49
                                ],
                                [
                                    -0.609,
                                    0.418
                                ],
                                [
                                    -0.672,
                                    0.346
                                ],
                                [
                                    -0.711,
                                    0.293
                                ],
                                [
                                    -0.749,
                                    0.24
                                ],
                                [
                                    -0.788,
                                    0.187
                                ],
                                [
                                    -0.827,
                                    0.135
                                ],
                                [
                                    -0.812,
                                    0.101
                                ],
                                [
                                    -0.797,
                                    0.067
                                ],
                                [
                                    -0.783,
                                    0.034
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    4.225,
                                    0
                                ],
                                [
                                    3.381,
                                    0.653
                                ],
                                [
                                    2.536,
                                    1.307
                                ],
                                [
                                    1.268,
                                    2.343
                                ],
                                [
                                    0,
                                    1.999
                                ],
                                [
                                    -0.423,
                                    1.633
                                ]
                            ],
                            "v": [
                                [
                                    32.173,
                                    -17.439
                                ],
                                [
                                    30.349,
                                    -15.646
                                ],
                                [
                                    28.266,
                                    -14.068
                                ],
                                [
                                    25.903,
                                    -12.707
                                ],
                                [
                                    23.234,
                                    -11.561
                                ],
                                [
                                    20.254,
                                    -10.603
                                ],
                                [
                                    16.949,
                                    -9.803
                                ],
                                [
                                    13.236,
                                    -9.162
                                ],
                                [
                                    8.985,
                                    -8.68
                                ],
                                [
                                    4.062,
                                    -8.326
                                ],
                                [
                                    -1.802,
                                    -8.074
                                ],
                                [
                                    -9.102,
                                    -7.923
                                ],
                                [
                                    -16.358,
                                    -7.873
                                ],
                                [
                                    -19.307,
                                    -9.246
                                ],
                                [
                                    -23.521,
                                    -10.94
                                ],
                                [
                                    -29.644,
                                    -13.08
                                ],
                                [
                                    -35.529,
                                    -15.861
                                ],
                                [
                                    -34.142,
                                    -25.44
                                ],
                                [
                                    -32.201,
                                    -38.851
                                ],
                                [
                                    -29.427,
                                    -58.009
                                ],
                                [
                                    -26.654,
                                    -77.168
                                ],
                                [
                                    -15.207,
                                    -75.795
                                ],
                                [
                                    -3.644,
                                    -74.101
                                ],
                                [
                                    8.54,
                                    -70.981
                                ],
                                [
                                    18.423,
                                    -65.261
                                ],
                                [
                                    25.485,
                                    -56.041
                                ],
                                [
                                    29.284,
                                    -42.213
                                ],
                                [
                                    31.362,
                                    -29.278
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.667,
                        "y": 1
                    },
                    "o": {
                        "x": 0.333,
                        "y": 0
                    },
                    "t": 29,
                    "s": [
                        {
                            "i": [
                                [
                                    1.326,
                                    -1.991
                                ],
                                [
                                    0.762,
                                    -0.882
                                ],
                                [
                                    0.86,
                                    -0.769
                                ],
                                [
                                    0.957,
                                    -0.656
                                ],
                                [
                                    1.055,
                                    -0.543
                                ],
                                [
                                    1.116,
                                    -0.46
                                ],
                                [
                                    1.176,
                                    -0.377
                                ],
                                [
                                    1.237,
                                    -0.294
                                ],
                                [
                                    1.297,
                                    -0.211
                                ],
                                [
                                    1.275,
                                    -0.159
                                ],
                                [
                                    1.252,
                                    -0.105
                                ],
                                [
                                    1.229,
                                    -0.053
                                ],
                                [
                                    1.206,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -5.31,
                                    -1.026
                                ],
                                [
                                    -3.982,
                                    -2.05
                                ],
                                [
                                    -1.991,
                                    -3.68
                                ],
                                [
                                    0,
                                    -5.309
                                ],
                                [
                                    0.664,
                                    -2.564
                                ]
                            ],
                            "o": [
                                [
                                    -0.665,
                                    0.995
                                ],
                                [
                                    -0.762,
                                    0.882
                                ],
                                [
                                    -0.86,
                                    0.769
                                ],
                                [
                                    -0.957,
                                    0.656
                                ],
                                [
                                    -1.056,
                                    0.543
                                ],
                                [
                                    -1.116,
                                    0.46
                                ],
                                [
                                    -1.176,
                                    0.377
                                ],
                                [
                                    -1.237,
                                    0.294
                                ],
                                [
                                    -1.298,
                                    0.212
                                ],
                                [
                                    -1.275,
                                    0.159
                                ],
                                [
                                    -1.252,
                                    0.105
                                ],
                                [
                                    -1.229,
                                    0.053
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    6.635,
                                    0
                                ],
                                [
                                    5.31,
                                    1.026
                                ],
                                [
                                    3.982,
                                    2.053
                                ],
                                [
                                    1.991,
                                    3.68
                                ],
                                [
                                    0,
                                    3.139
                                ],
                                [
                                    -0.664,
                                    2.564
                                ]
                            ],
                            "v": [
                                [
                                    32.45,
                                    -43.979
                                ],
                                [
                                    30.31,
                                    -41.162
                                ],
                                [
                                    27.877,
                                    -38.685
                                ],
                                [
                                    25.152,
                                    -36.547
                                ],
                                [
                                    22.133,
                                    -34.748
                                ],
                                [
                                    18.874,
                                    -33.243
                                ],
                                [
                                    15.435,
                                    -31.987
                                ],
                                [
                                    11.815,
                                    -30.98
                                ],
                                [
                                    8.015,
                                    -30.223
                                ],
                                [
                                    4.156,
                                    -29.667
                                ],
                                [
                                    0.366,
                                    -29.271
                                ],
                                [
                                    -3.355,
                                    -29.035
                                ],
                                [
                                    -7.008,
                                    -28.956
                                ],
                                [
                                    -12.438,
                                    -28.956
                                ],
                                [
                                    -20.04,
                                    -28.956
                                ],
                                [
                                    -30.9,
                                    -28.956
                                ],
                                [
                                    -41.76,
                                    -28.956
                                ],
                                [
                                    -41.76,
                                    -38.119
                                ],
                                [
                                    -41.76,
                                    -50.947
                                ],
                                [
                                    -41.76,
                                    -69.274
                                ],
                                [
                                    -41.76,
                                    -87.6
                                ],
                                [
                                    -24.565,
                                    -87.6
                                ],
                                [
                                    -7.37,
                                    -87.6
                                ],
                                [
                                    10.548,
                                    -86.061
                                ],
                                [
                                    24.486,
                                    -81.446
                                ],
                                [
                                    33.446,
                                    -72.847
                                ],
                                [
                                    36.432,
                                    -59.364
                                ],
                                [
                                    35.436,
                                    -50.81
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.667,
                        "y": 1
                    },
                    "o": {
                        "x": 0.333,
                        "y": 0
                    },
                    "t": 41,
                    "s": [
                        {
                            "i": [
                                [
                                    1.326,
                                    -1.991
                                ],
                                [
                                    0.762,
                                    -0.882
                                ],
                                [
                                    0.86,
                                    -0.769
                                ],
                                [
                                    0.957,
                                    -0.656
                                ],
                                [
                                    1.055,
                                    -0.543
                                ],
                                [
                                    1.116,
                                    -0.46
                                ],
                                [
                                    1.176,
                                    -0.377
                                ],
                                [
                                    1.237,
                                    -0.294
                                ],
                                [
                                    1.297,
                                    -0.211
                                ],
                                [
                                    1.275,
                                    -0.159
                                ],
                                [
                                    1.252,
                                    -0.105
                                ],
                                [
                                    1.229,
                                    -0.053
                                ],
                                [
                                    1.206,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -5.31,
                                    -1.026
                                ],
                                [
                                    -3.982,
                                    -2.05
                                ],
                                [
                                    -1.991,
                                    -3.68
                                ],
                                [
                                    0,
                                    -5.309
                                ],
                                [
                                    0.664,
                                    -2.564
                                ]
                            ],
                            "o": [
                                [
                                    -0.665,
                                    0.995
                                ],
                                [
                                    -0.762,
                                    0.882
                                ],
                                [
                                    -0.86,
                                    0.769
                                ],
                                [
                                    -0.957,
                                    0.656
                                ],
                                [
                                    -1.056,
                                    0.543
                                ],
                                [
                                    -1.116,
                                    0.46
                                ],
                                [
                                    -1.176,
                                    0.377
                                ],
                                [
                                    -1.237,
                                    0.294
                                ],
                                [
                                    -1.298,
                                    0.212
                                ],
                                [
                                    -1.275,
                                    0.159
                                ],
                                [
                                    -1.252,
                                    0.105
                                ],
                                [
                                    -1.229,
                                    0.053
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    6.635,
                                    0
                                ],
                                [
                                    5.31,
                                    1.026
                                ],
                                [
                                    3.982,
                                    2.053
                                ],
                                [
                                    1.991,
                                    3.68
                                ],
                                [
                                    0,
                                    3.139
                                ],
                                [
                                    -0.664,
                                    2.564
                                ]
                            ],
                            "v": [
                                [
                                    32.45,
                                    -43.979
                                ],
                                [
                                    30.31,
                                    -41.162
                                ],
                                [
                                    27.877,
                                    -38.685
                                ],
                                [
                                    25.152,
                                    -36.547
                                ],
                                [
                                    22.133,
                                    -34.748
                                ],
                                [
                                    18.874,
                                    -33.243
                                ],
                                [
                                    15.435,
                                    -31.987
                                ],
                                [
                                    11.815,
                                    -30.98
                                ],
                                [
                                    8.015,
                                    -30.223
                                ],
                                [
                                    4.156,
                                    -29.667
                                ],
                                [
                                    0.366,
                                    -29.271
                                ],
                                [
                                    -3.355,
                                    -29.035
                                ],
                                [
                                    -7.008,
                                    -28.956
                                ],
                                [
                                    -12.438,
                                    -28.956
                                ],
                                [
                                    -20.04,
                                    -28.956
                                ],
                                [
                                    -30.9,
                                    -28.956
                                ],
                                [
                                    -41.76,
                                    -28.956
                                ],
                                [
                                    -41.76,
                                    -38.119
                                ],
                                [
                                    -41.76,
                                    -50.947
                                ],
                                [
                                    -41.76,
                                    -69.274
                                ],
                                [
                                    -41.76,
                                    -87.6
                                ],
                                [
                                    -24.565,
                                    -87.6
                                ],
                                [
                                    -7.37,
                                    -87.6
                                ],
                                [
                                    10.548,
                                    -86.061
                                ],
                                [
                                    24.486,
                                    -81.446
                                ],
                                [
                                    33.446,
                                    -72.847
                                ],
                                [
                                    36.432,
                                    -59.364
                                ],
                                [
                                    35.436,
                                    -50.81
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 1
                    },
                    "o": {
                        "x": 0.333,
                        "y": 0
                    },
                    "t": 44,
                    "s": [
                        {
                            "i": [
                                [
                                    1.326,
                                    -1.991
                                ],
                                [
                                    0.762,
                                    -0.882
                                ],
                                [
                                    0.86,
                                    -0.769
                                ],
                                [
                                    0.957,
                                    -0.656
                                ],
                                [
                                    1.055,
                                    -0.543
                                ],
                                [
                                    1.116,
                                    -0.46
                                ],
                                [
                                    1.176,
                                    -0.377
                                ],
                                [
                                    1.237,
                                    -0.294
                                ],
                                [
                                    1.297,
                                    -0.211
                                ],
                                [
                                    1.275,
                                    -0.159
                                ],
                                [
                                    1.252,
                                    -0.105
                                ],
                                [
                                    1.229,
                                    -0.053
                                ],
                                [
                                    1.206,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -5.31,
                                    -1.026
                                ],
                                [
                                    -3.982,
                                    -2.05
                                ],
                                [
                                    -1.991,
                                    -3.68
                                ],
                                [
                                    0,
                                    -5.309
                                ],
                                [
                                    0.664,
                                    -2.564
                                ]
                            ],
                            "o": [
                                [
                                    -0.665,
                                    0.995
                                ],
                                [
                                    -0.762,
                                    0.882
                                ],
                                [
                                    -0.86,
                                    0.769
                                ],
                                [
                                    -0.957,
                                    0.656
                                ],
                                [
                                    -1.056,
                                    0.543
                                ],
                                [
                                    -1.116,
                                    0.46
                                ],
                                [
                                    -1.176,
                                    0.377
                                ],
                                [
                                    -1.237,
                                    0.294
                                ],
                                [
                                    -1.298,
                                    0.212
                                ],
                                [
                                    -1.275,
                                    0.159
                                ],
                                [
                                    -1.252,
                                    0.105
                                ],
                                [
                                    -1.229,
                                    0.053
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    6.635,
                                    0
                                ],
                                [
                                    5.31,
                                    1.026
                                ],
                                [
                                    3.982,
                                    2.053
                                ],
                                [
                                    1.991,
                                    3.68
                                ],
                                [
                                    0,
                                    3.139
                                ],
                                [
                                    -0.664,
                                    2.564
                                ]
                            ],
                            "v": [
                                [
                                    32.45,
                                    -43.979
                                ],
                                [
                                    30.31,
                                    -41.162
                                ],
                                [
                                    27.877,
                                    -38.685
                                ],
                                [
                                    25.152,
                                    -36.547
                                ],
                                [
                                    22.133,
                                    -34.748
                                ],
                                [
                                    18.874,
                                    -33.243
                                ],
                                [
                                    15.435,
                                    -31.987
                                ],
                                [
                                    11.815,
                                    -30.98
                                ],
                                [
                                    8.015,
                                    -30.223
                                ],
                                [
                                    4.156,
                                    -29.667
                                ],
                                [
                                    0.366,
                                    -29.271
                                ],
                                [
                                    -3.355,
                                    -29.035
                                ],
                                [
                                    -7.008,
                                    -28.956
                                ],
                                [
                                    -12.438,
                                    -28.956
                                ],
                                [
                                    -20.04,
                                    -28.956
                                ],
                                [
                                    -30.9,
                                    -28.956
                                ],
                                [
                                    -41.76,
                                    -28.956
                                ],
                                [
                                    -41.76,
                                    -38.119
                                ],
                                [
                                    -41.76,
                                    -50.947
                                ],
                                [
                                    -41.76,
                                    -69.274
                                ],
                                [
                                    -41.76,
                                    -87.6
                                ],
                                [
                                    -24.565,
                                    -87.6
                                ],
                                [
                                    -7.37,
                                    -87.6
                                ],
                                [
                                    10.548,
                                    -86.061
                                ],
                                [
                                    24.486,
                                    -81.446
                                ],
                                [
                                    33.446,
                                    -72.847
                                ],
                                [
                                    36.432,
                                    -59.364
                                ],
                                [
                                    35.436,
                                    -50.81
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 1
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0
                    },
                    "t": 49,
                    "s": [
                        {
                            "i": [
                                [
                                    0.008,
                                    -0.074
                                ],
                                [
                                    0.683,
                                    -0.137
                                ],
                                [
                                    1.724,
                                    -0.206
                                ],
                                [
                                    2.138,
                                    -0.102
                                ],
                                [
                                    1.873,
                                    -0.015
                                ],
                                [
                                    1.665,
                                    0.303
                                ],
                                [
                                    1.431,
                                    0.115
                                ],
                                [
                                    1.109,
                                    0.158
                                ],
                                [
                                    0.787,
                                    0.138
                                ],
                                [
                                    0.465,
                                    0.101
                                ],
                                [
                                    0.18,
                                    0.055
                                ],
                                [
                                    -0.028,
                                    -0.008
                                ],
                                [
                                    0.102,
                                    -0.069
                                ],
                                [
                                    -0.602,
                                    -0.143
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -0.671,
                                    0.993
                                ],
                                [
                                    2.029,
                                    -0.537
                                ],
                                [
                                    1.759,
                                    0.064
                                ],
                                [
                                    1.305,
                                    -0.21
                                ],
                                [
                                    0.74,
                                    -0.299
                                ],
                                [
                                    -18.955,
                                    -0.179
                                ],
                                [
                                    0,
                                    -0.038
                                ],
                                [
                                    -2.24,
                                    -0.155
                                ],
                                [
                                    -3.355,
                                    -0.28
                                ],
                                [
                                    -4.848,
                                    -0.532
                                ],
                                [
                                    -3.528,
                                    -0.686
                                ],
                                [
                                    -2.058,
                                    -0.821
                                ],
                                [
                                    -1.137,
                                    -0.26
                                ]
                            ],
                            "o": [
                                [
                                    -0.595,
                                    0.154
                                ],
                                [
                                    -1.261,
                                    0.275
                                ],
                                [
                                    -1.724,
                                    0.206
                                ],
                                [
                                    -1.962,
                                    0.102
                                ],
                                [
                                    -1.875,
                                    0.015
                                ],
                                [
                                    -1.666,
                                    -0.303
                                ],
                                [
                                    -1.431,
                                    -0.115
                                ],
                                [
                                    -1.108,
                                    -0.158
                                ],
                                [
                                    -0.787,
                                    -0.138
                                ],
                                [
                                    -0.465,
                                    -0.101
                                ],
                                [
                                    -0.18,
                                    -0.055
                                ],
                                [
                                    -0.072,
                                    0.008
                                ],
                                [
                                    0.979,
                                    0.069
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -1.129,
                                    -0.221
                                ],
                                [
                                    0.671,
                                    -0.993
                                ],
                                [
                                    -1.965,
                                    0.52
                                ],
                                [
                                    -8.902,
                                    -0.325
                                ],
                                [
                                    -1.305,
                                    0.21
                                ],
                                [
                                    -0.665,
                                    0.269
                                ],
                                [
                                    18.955,
                                    0.179
                                ],
                                [
                                    2.309,
                                    0.14
                                ],
                                [
                                    3.526,
                                    0.243
                                ],
                                [
                                    5.485,
                                    0.458
                                ],
                                [
                                    4.848,
                                    0.532
                                ],
                                [
                                    3.528,
                                    0.686
                                ],
                                [
                                    2.259,
                                    0.486
                                ],
                                [
                                    1.137,
                                    0.26
                                ]
                            ],
                            "v": [
                                [
                                    35.62,
                                    -87.682
                                ],
                                [
                                    29.223,
                                    -87.975
                                ],
                                [
                                    24.746,
                                    -87.253
                                ],
                                [
                                    18.953,
                                    -86.792
                                ],
                                [
                                    13.199,
                                    -86.618
                                ],
                                [
                                    8.673,
                                    -86.932
                                ],
                                [
                                    3.245,
                                    -86.926
                                ],
                                [
                                    -0.563,
                                    -87.342
                                ],
                                [
                                    -3.403,
                                    -87.787
                                ],
                                [
                                    -5.28,
                                    -88.146
                                ],
                                [
                                    -6.248,
                                    -88.381
                                ],
                                [
                                    -6.476,
                                    -88.452
                                ],
                                [
                                    -6.737,
                                    -88.335
                                ],
                                [
                                    -12.149,
                                    -88.017
                                ],
                                [
                                    -15.566,
                                    -88.771
                                ],
                                [
                                    -28.402,
                                    -90.829
                                ],
                                [
                                    -40.579,
                                    -87.01
                                ],
                                [
                                    -46.908,
                                    -81.627
                                ],
                                [
                                    -53.949,
                                    -85.048
                                ],
                                [
                                    -39.052,
                                    -97.103
                                ],
                                [
                                    -25.192,
                                    -96.213
                                ],
                                [
                                    -24.756,
                                    -96.102
                                ],
                                [
                                    -15.795,
                                    -95.429
                                ],
                                [
                                    -7.61,
                                    -94.875
                                ],
                                [
                                    7.891,
                                    -93.391
                                ],
                                [
                                    20.455,
                                    -91.563
                                ],
                                [
                                    28.835,
                                    -89.302
                                ],
                                [
                                    33.928,
                                    -88.183
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 1
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0
                    },
                    "t": 51,
                    "s": [
                        {
                            "i": [
                                [
                                    0.008,
                                    -0.074
                                ],
                                [
                                    0.683,
                                    -0.137
                                ],
                                [
                                    1.724,
                                    -0.206
                                ],
                                [
                                    2.138,
                                    -0.102
                                ],
                                [
                                    1.873,
                                    -0.015
                                ],
                                [
                                    1.665,
                                    0.303
                                ],
                                [
                                    1.431,
                                    0.115
                                ],
                                [
                                    1.109,
                                    0.158
                                ],
                                [
                                    0.787,
                                    0.138
                                ],
                                [
                                    0.465,
                                    0.101
                                ],
                                [
                                    0.18,
                                    0.055
                                ],
                                [
                                    -0.028,
                                    -0.008
                                ],
                                [
                                    0.102,
                                    -0.069
                                ],
                                [
                                    -0.602,
                                    -0.143
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -0.671,
                                    0.993
                                ],
                                [
                                    2.029,
                                    -0.537
                                ],
                                [
                                    1.759,
                                    0.064
                                ],
                                [
                                    1.305,
                                    -0.21
                                ],
                                [
                                    0.74,
                                    -0.299
                                ],
                                [
                                    -18.955,
                                    -0.179
                                ],
                                [
                                    0,
                                    -0.038
                                ],
                                [
                                    -2.24,
                                    -0.155
                                ],
                                [
                                    -3.355,
                                    -0.28
                                ],
                                [
                                    -4.848,
                                    -0.532
                                ],
                                [
                                    -3.528,
                                    -0.686
                                ],
                                [
                                    -2.058,
                                    -0.821
                                ],
                                [
                                    -1.137,
                                    -0.26
                                ]
                            ],
                            "o": [
                                [
                                    -0.595,
                                    0.154
                                ],
                                [
                                    -1.261,
                                    0.275
                                ],
                                [
                                    -1.724,
                                    0.206
                                ],
                                [
                                    -1.962,
                                    0.102
                                ],
                                [
                                    -1.875,
                                    0.015
                                ],
                                [
                                    -1.666,
                                    -0.303
                                ],
                                [
                                    -1.431,
                                    -0.115
                                ],
                                [
                                    -1.108,
                                    -0.158
                                ],
                                [
                                    -0.787,
                                    -0.138
                                ],
                                [
                                    -0.465,
                                    -0.101
                                ],
                                [
                                    -0.18,
                                    -0.055
                                ],
                                [
                                    -0.072,
                                    0.008
                                ],
                                [
                                    0.979,
                                    0.069
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -1.129,
                                    -0.221
                                ],
                                [
                                    0.671,
                                    -0.993
                                ],
                                [
                                    -1.965,
                                    0.52
                                ],
                                [
                                    -8.902,
                                    -0.325
                                ],
                                [
                                    -1.305,
                                    0.21
                                ],
                                [
                                    -0.665,
                                    0.269
                                ],
                                [
                                    18.955,
                                    0.179
                                ],
                                [
                                    2.309,
                                    0.14
                                ],
                                [
                                    3.526,
                                    0.243
                                ],
                                [
                                    5.485,
                                    0.458
                                ],
                                [
                                    4.848,
                                    0.532
                                ],
                                [
                                    3.528,
                                    0.686
                                ],
                                [
                                    2.259,
                                    0.486
                                ],
                                [
                                    1.137,
                                    0.26
                                ]
                            ],
                            "v": [
                                [
                                    35.62,
                                    -87.682
                                ],
                                [
                                    29.223,
                                    -87.975
                                ],
                                [
                                    24.746,
                                    -87.253
                                ],
                                [
                                    18.953,
                                    -86.792
                                ],
                                [
                                    13.199,
                                    -86.618
                                ],
                                [
                                    8.673,
                                    -86.932
                                ],
                                [
                                    3.245,
                                    -86.926
                                ],
                                [
                                    -0.563,
                                    -87.342
                                ],
                                [
                                    -3.403,
                                    -87.787
                                ],
                                [
                                    -5.28,
                                    -88.146
                                ],
                                [
                                    -6.248,
                                    -88.381
                                ],
                                [
                                    -6.476,
                                    -88.452
                                ],
                                [
                                    -6.737,
                                    -88.335
                                ],
                                [
                                    -12.149,
                                    -88.017
                                ],
                                [
                                    -15.566,
                                    -88.771
                                ],
                                [
                                    -28.402,
                                    -90.829
                                ],
                                [
                                    -40.579,
                                    -87.01
                                ],
                                [
                                    -46.908,
                                    -81.627
                                ],
                                [
                                    -53.949,
                                    -85.048
                                ],
                                [
                                    -39.052,
                                    -97.103
                                ],
                                [
                                    -25.192,
                                    -96.213
                                ],
                                [
                                    -24.756,
                                    -96.102
                                ],
                                [
                                    -15.795,
                                    -95.429
                                ],
                                [
                                    -7.61,
                                    -94.875
                                ],
                                [
                                    7.891,
                                    -93.391
                                ],
                                [
                                    20.455,
                                    -91.563
                                ],
                                [
                                    28.835,
                                    -89.302
                                ],
                                [
                                    33.928,
                                    -88.183
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 0.833
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0
                    },
                    "t": 52,
                    "s": [
                        {
                            "i": [
                                [
                                    0.008,
                                    -0.074
                                ],
                                [
                                    0.683,
                                    -0.137
                                ],
                                [
                                    1.724,
                                    -0.206
                                ],
                                [
                                    2.138,
                                    -0.102
                                ],
                                [
                                    1.873,
                                    -0.015
                                ],
                                [
                                    1.665,
                                    0.303
                                ],
                                [
                                    1.431,
                                    0.115
                                ],
                                [
                                    1.109,
                                    0.158
                                ],
                                [
                                    0.787,
                                    0.138
                                ],
                                [
                                    0.465,
                                    0.101
                                ],
                                [
                                    0.18,
                                    0.055
                                ],
                                [
                                    -0.028,
                                    -0.008
                                ],
                                [
                                    0.102,
                                    -0.069
                                ],
                                [
                                    -0.602,
                                    -0.143
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -0.671,
                                    0.993
                                ],
                                [
                                    2.029,
                                    -0.537
                                ],
                                [
                                    1.759,
                                    0.064
                                ],
                                [
                                    1.305,
                                    -0.21
                                ],
                                [
                                    0.74,
                                    -0.299
                                ],
                                [
                                    -18.955,
                                    -0.179
                                ],
                                [
                                    0,
                                    -0.038
                                ],
                                [
                                    -2.24,
                                    -0.155
                                ],
                                [
                                    -3.355,
                                    -0.28
                                ],
                                [
                                    -4.848,
                                    -0.532
                                ],
                                [
                                    -3.528,
                                    -0.686
                                ],
                                [
                                    -2.058,
                                    -0.821
                                ],
                                [
                                    -1.137,
                                    -0.26
                                ]
                            ],
                            "o": [
                                [
                                    -0.595,
                                    0.154
                                ],
                                [
                                    -1.261,
                                    0.275
                                ],
                                [
                                    -1.724,
                                    0.206
                                ],
                                [
                                    -1.962,
                                    0.102
                                ],
                                [
                                    -1.875,
                                    0.015
                                ],
                                [
                                    -1.666,
                                    -0.303
                                ],
                                [
                                    -1.431,
                                    -0.115
                                ],
                                [
                                    -1.108,
                                    -0.158
                                ],
                                [
                                    -0.787,
                                    -0.138
                                ],
                                [
                                    -0.465,
                                    -0.101
                                ],
                                [
                                    -0.18,
                                    -0.055
                                ],
                                [
                                    -0.072,
                                    0.008
                                ],
                                [
                                    0.979,
                                    0.069
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -1.129,
                                    -0.221
                                ],
                                [
                                    0.671,
                                    -0.993
                                ],
                                [
                                    -1.965,
                                    0.52
                                ],
                                [
                                    -8.902,
                                    -0.325
                                ],
                                [
                                    -1.305,
                                    0.21
                                ],
                                [
                                    -0.665,
                                    0.268
                                ],
                                [
                                    18.955,
                                    0.179
                                ],
                                [
                                    2.309,
                                    0.14
                                ],
                                [
                                    3.526,
                                    0.243
                                ],
                                [
                                    5.485,
                                    0.458
                                ],
                                [
                                    4.848,
                                    0.532
                                ],
                                [
                                    3.528,
                                    0.686
                                ],
                                [
                                    2.259,
                                    0.486
                                ],
                                [
                                    1.137,
                                    0.26
                                ]
                            ],
                            "v": [
                                [
                                    229.797,
                                    -412.81
                                ],
                                [
                                    223.4,
                                    -413.102
                                ],
                                [
                                    218.923,
                                    -412.38
                                ],
                                [
                                    213.13,
                                    -411.92
                                ],
                                [
                                    207.376,
                                    -411.745
                                ],
                                [
                                    202.85,
                                    -412.059
                                ],
                                [
                                    197.422,
                                    -412.053
                                ],
                                [
                                    193.614,
                                    -412.469
                                ],
                                [
                                    190.774,
                                    -412.914
                                ],
                                [
                                    188.897,
                                    -413.274
                                ],
                                [
                                    187.929,
                                    -413.508
                                ],
                                [
                                    187.701,
                                    -413.579
                                ],
                                [
                                    187.44,
                                    -413.463
                                ],
                                [
                                    182.028,
                                    -413.144
                                ],
                                [
                                    178.61,
                                    -413.898
                                ],
                                [
                                    165.775,
                                    -415.957
                                ],
                                [
                                    153.598,
                                    -412.137
                                ],
                                [
                                    147.269,
                                    -406.754
                                ],
                                [
                                    140.228,
                                    -410.175
                                ],
                                [
                                    155.125,
                                    -422.23
                                ],
                                [
                                    168.985,
                                    -421.34
                                ],
                                [
                                    169.421,
                                    -421.229
                                ],
                                [
                                    178.382,
                                    -420.556
                                ],
                                [
                                    186.567,
                                    -420.002
                                ],
                                [
                                    202.068,
                                    -418.518
                                ],
                                [
                                    214.632,
                                    -416.69
                                ],
                                [
                                    223.012,
                                    -414.429
                                ],
                                [
                                    228.105,
                                    -413.311
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 0.833
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    },
                    "t": 56,
                    "s": [
                        {
                            "i": [
                                [
                                    0.008,
                                    -0.074
                                ],
                                [
                                    0.683,
                                    -0.137
                                ],
                                [
                                    1.724,
                                    -0.206
                                ],
                                [
                                    2.138,
                                    -0.102
                                ],
                                [
                                    1.873,
                                    -0.015
                                ],
                                [
                                    1.665,
                                    0.303
                                ],
                                [
                                    1.431,
                                    0.115
                                ],
                                [
                                    1.109,
                                    0.158
                                ],
                                [
                                    0.787,
                                    0.138
                                ],
                                [
                                    0.465,
                                    0.101
                                ],
                                [
                                    0.18,
                                    0.055
                                ],
                                [
                                    -0.028,
                                    -0.008
                                ],
                                [
                                    0.102,
                                    -0.069
                                ],
                                [
                                    -0.602,
                                    -0.143
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -0.671,
                                    0.993
                                ],
                                [
                                    2.029,
                                    -0.537
                                ],
                                [
                                    1.759,
                                    0.064
                                ],
                                [
                                    1.305,
                                    -0.21
                                ],
                                [
                                    0.74,
                                    -0.299
                                ],
                                [
                                    -18.955,
                                    -0.179
                                ],
                                [
                                    0,
                                    -0.038
                                ],
                                [
                                    -2.24,
                                    -0.155
                                ],
                                [
                                    -3.355,
                                    -0.28
                                ],
                                [
                                    -4.848,
                                    -0.532
                                ],
                                [
                                    -3.528,
                                    -0.686
                                ],
                                [
                                    -2.058,
                                    -0.821
                                ],
                                [
                                    -1.137,
                                    -0.26
                                ]
                            ],
                            "o": [
                                [
                                    -0.595,
                                    0.154
                                ],
                                [
                                    -1.261,
                                    0.275
                                ],
                                [
                                    -1.724,
                                    0.206
                                ],
                                [
                                    -1.962,
                                    0.102
                                ],
                                [
                                    -1.875,
                                    0.015
                                ],
                                [
                                    -1.666,
                                    -0.303
                                ],
                                [
                                    -1.431,
                                    -0.115
                                ],
                                [
                                    -1.108,
                                    -0.158
                                ],
                                [
                                    -0.787,
                                    -0.138
                                ],
                                [
                                    -0.465,
                                    -0.101
                                ],
                                [
                                    -0.18,
                                    -0.055
                                ],
                                [
                                    -0.072,
                                    0.008
                                ],
                                [
                                    0.979,
                                    0.069
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -1.129,
                                    -0.221
                                ],
                                [
                                    0.671,
                                    -0.993
                                ],
                                [
                                    -1.965,
                                    0.52
                                ],
                                [
                                    -8.902,
                                    -0.325
                                ],
                                [
                                    -1.305,
                                    0.21
                                ],
                                [
                                    -0.665,
                                    0.268
                                ],
                                [
                                    18.955,
                                    0.179
                                ],
                                [
                                    2.309,
                                    0.14
                                ],
                                [
                                    3.526,
                                    0.243
                                ],
                                [
                                    5.485,
                                    0.458
                                ],
                                [
                                    4.848,
                                    0.532
                                ],
                                [
                                    3.528,
                                    0.686
                                ],
                                [
                                    2.259,
                                    0.486
                                ],
                                [
                                    1.137,
                                    0.26
                                ]
                            ],
                            "v": [
                                [
                                    229.797,
                                    -412.81
                                ],
                                [
                                    223.4,
                                    -413.102
                                ],
                                [
                                    218.923,
                                    -412.38
                                ],
                                [
                                    213.13,
                                    -411.92
                                ],
                                [
                                    207.376,
                                    -411.745
                                ],
                                [
                                    202.85,
                                    -412.059
                                ],
                                [
                                    197.422,
                                    -412.053
                                ],
                                [
                                    193.614,
                                    -412.469
                                ],
                                [
                                    190.774,
                                    -412.914
                                ],
                                [
                                    188.897,
                                    -413.274
                                ],
                                [
                                    187.929,
                                    -413.508
                                ],
                                [
                                    187.701,
                                    -413.579
                                ],
                                [
                                    187.44,
                                    -413.463
                                ],
                                [
                                    182.028,
                                    -413.144
                                ],
                                [
                                    178.61,
                                    -413.898
                                ],
                                [
                                    165.775,
                                    -415.957
                                ],
                                [
                                    153.598,
                                    -412.137
                                ],
                                [
                                    147.269,
                                    -406.754
                                ],
                                [
                                    140.228,
                                    -410.175
                                ],
                                [
                                    155.125,
                                    -422.23
                                ],
                                [
                                    168.985,
                                    -421.34
                                ],
                                [
                                    169.421,
                                    -421.229
                                ],
                                [
                                    178.382,
                                    -420.556
                                ],
                                [
                                    186.567,
                                    -420.002
                                ],
                                [
                                    202.068,
                                    -418.518
                                ],
                                [
                                    214.632,
                                    -416.69
                                ],
                                [
                                    223.012,
                                    -414.429
                                ],
                                [
                                    228.105,
                                    -413.311
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 1
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0.167
                    },
                    "t": 61,
                    "s": [
                        {
                            "i": [
                                [
                                    0.008,
                                    -0.074
                                ],
                                [
                                    0.683,
                                    -0.137
                                ],
                                [
                                    1.724,
                                    -0.206
                                ],
                                [
                                    2.138,
                                    -0.102
                                ],
                                [
                                    1.873,
                                    -0.015
                                ],
                                [
                                    1.665,
                                    0.303
                                ],
                                [
                                    1.431,
                                    0.115
                                ],
                                [
                                    1.109,
                                    0.158
                                ],
                                [
                                    0.787,
                                    0.138
                                ],
                                [
                                    0.465,
                                    0.101
                                ],
                                [
                                    0.18,
                                    0.055
                                ],
                                [
                                    -0.028,
                                    -0.008
                                ],
                                [
                                    0.102,
                                    -0.069
                                ],
                                [
                                    -0.602,
                                    -0.143
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -0.671,
                                    0.993
                                ],
                                [
                                    2.029,
                                    -0.537
                                ],
                                [
                                    1.759,
                                    0.064
                                ],
                                [
                                    1.305,
                                    -0.21
                                ],
                                [
                                    0.74,
                                    -0.299
                                ],
                                [
                                    -18.955,
                                    -0.179
                                ],
                                [
                                    0,
                                    -0.038
                                ],
                                [
                                    -2.24,
                                    -0.155
                                ],
                                [
                                    -3.355,
                                    -0.28
                                ],
                                [
                                    -4.848,
                                    -0.532
                                ],
                                [
                                    -3.528,
                                    -0.686
                                ],
                                [
                                    -2.058,
                                    -0.821
                                ],
                                [
                                    -1.137,
                                    -0.26
                                ]
                            ],
                            "o": [
                                [
                                    -0.595,
                                    0.154
                                ],
                                [
                                    -1.261,
                                    0.275
                                ],
                                [
                                    -1.724,
                                    0.206
                                ],
                                [
                                    -1.962,
                                    0.102
                                ],
                                [
                                    -1.875,
                                    0.015
                                ],
                                [
                                    -1.666,
                                    -0.303
                                ],
                                [
                                    -1.431,
                                    -0.115
                                ],
                                [
                                    -1.108,
                                    -0.158
                                ],
                                [
                                    -0.787,
                                    -0.138
                                ],
                                [
                                    -0.465,
                                    -0.101
                                ],
                                [
                                    -0.18,
                                    -0.055
                                ],
                                [
                                    -0.072,
                                    0.008
                                ],
                                [
                                    0.979,
                                    0.069
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -1.129,
                                    -0.221
                                ],
                                [
                                    0.671,
                                    -0.993
                                ],
                                [
                                    -1.965,
                                    0.52
                                ],
                                [
                                    -8.902,
                                    -0.325
                                ],
                                [
                                    -1.305,
                                    0.21
                                ],
                                [
                                    -0.665,
                                    0.268
                                ],
                                [
                                    18.955,
                                    0.179
                                ],
                                [
                                    2.309,
                                    0.14
                                ],
                                [
                                    3.526,
                                    0.243
                                ],
                                [
                                    5.485,
                                    0.458
                                ],
                                [
                                    4.848,
                                    0.532
                                ],
                                [
                                    3.528,
                                    0.686
                                ],
                                [
                                    2.259,
                                    0.486
                                ],
                                [
                                    1.137,
                                    0.26
                                ]
                            ],
                            "v": [
                                [
                                    229.797,
                                    -412.81
                                ],
                                [
                                    223.4,
                                    -413.102
                                ],
                                [
                                    218.923,
                                    -412.38
                                ],
                                [
                                    213.13,
                                    -411.92
                                ],
                                [
                                    207.376,
                                    -411.745
                                ],
                                [
                                    202.85,
                                    -412.059
                                ],
                                [
                                    197.422,
                                    -412.053
                                ],
                                [
                                    193.614,
                                    -412.469
                                ],
                                [
                                    190.774,
                                    -412.914
                                ],
                                [
                                    188.897,
                                    -413.274
                                ],
                                [
                                    187.929,
                                    -413.508
                                ],
                                [
                                    187.701,
                                    -413.579
                                ],
                                [
                                    187.44,
                                    -413.463
                                ],
                                [
                                    182.028,
                                    -413.144
                                ],
                                [
                                    178.61,
                                    -413.898
                                ],
                                [
                                    165.775,
                                    -415.957
                                ],
                                [
                                    153.598,
                                    -412.137
                                ],
                                [
                                    147.269,
                                    -406.754
                                ],
                                [
                                    140.228,
                                    -410.175
                                ],
                                [
                                    155.125,
                                    -422.23
                                ],
                                [
                                    168.985,
                                    -421.34
                                ],
                                [
                                    169.421,
                                    -421.229
                                ],
                                [
                                    178.382,
                                    -420.556
                                ],
                                [
                                    186.567,
                                    -420.002
                                ],
                                [
                                    202.068,
                                    -418.518
                                ],
                                [
                                    214.632,
                                    -416.69
                                ],
                                [
                                    223.012,
                                    -414.429
                                ],
                                [
                                    228.105,
                                    -413.311
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "t": 69,
                    "s": [
                        {
                            "i": [
                                [
                                    0.008,
                                    -0.074
                                ],
                                [
                                    0.683,
                                    -0.137
                                ],
                                [
                                    1.724,
                                    -0.206
                                ],
                                [
                                    2.138,
                                    -0.102
                                ],
                                [
                                    1.873,
                                    -0.015
                                ],
                                [
                                    1.665,
                                    0.303
                                ],
                                [
                                    1.431,
                                    0.115
                                ],
                                [
                                    1.109,
                                    0.158
                                ],
                                [
                                    0.787,
                                    0.138
                                ],
                                [
                                    0.465,
                                    0.101
                                ],
                                [
                                    0.18,
                                    0.055
                                ],
                                [
                                    -0.028,
                                    -0.008
                                ],
                                [
                                    0.102,
                                    -0.069
                                ],
                                [
                                    -0.602,
                                    -0.143
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -0.671,
                                    0.993
                                ],
                                [
                                    2.029,
                                    -0.537
                                ],
                                [
                                    1.759,
                                    0.064
                                ],
                                [
                                    1.305,
                                    -0.21
                                ],
                                [
                                    0.74,
                                    -0.299
                                ],
                                [
                                    -18.955,
                                    -0.179
                                ],
                                [
                                    0,
                                    -0.038
                                ],
                                [
                                    -2.24,
                                    -0.155
                                ],
                                [
                                    -3.355,
                                    -0.28
                                ],
                                [
                                    -4.848,
                                    -0.532
                                ],
                                [
                                    -3.528,
                                    -0.686
                                ],
                                [
                                    -2.058,
                                    -0.821
                                ],
                                [
                                    -1.137,
                                    -0.26
                                ]
                            ],
                            "o": [
                                [
                                    -0.595,
                                    0.154
                                ],
                                [
                                    -1.261,
                                    0.275
                                ],
                                [
                                    -1.724,
                                    0.206
                                ],
                                [
                                    -1.962,
                                    0.102
                                ],
                                [
                                    -1.875,
                                    0.015
                                ],
                                [
                                    -1.666,
                                    -0.303
                                ],
                                [
                                    -1.431,
                                    -0.115
                                ],
                                [
                                    -1.108,
                                    -0.158
                                ],
                                [
                                    -0.787,
                                    -0.138
                                ],
                                [
                                    -0.465,
                                    -0.101
                                ],
                                [
                                    -0.18,
                                    -0.055
                                ],
                                [
                                    -0.072,
                                    0.008
                                ],
                                [
                                    0.979,
                                    0.069
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -1.129,
                                    -0.221
                                ],
                                [
                                    0.671,
                                    -0.993
                                ],
                                [
                                    -1.965,
                                    0.52
                                ],
                                [
                                    -8.902,
                                    -0.325
                                ],
                                [
                                    -1.305,
                                    0.21
                                ],
                                [
                                    -0.665,
                                    0.268
                                ],
                                [
                                    18.955,
                                    0.179
                                ],
                                [
                                    2.309,
                                    0.14
                                ],
                                [
                                    3.526,
                                    0.243
                                ],
                                [
                                    5.485,
                                    0.458
                                ],
                                [
                                    4.848,
                                    0.532
                                ],
                                [
                                    3.528,
                                    0.686
                                ],
                                [
                                    2.259,
                                    0.486
                                ],
                                [
                                    1.137,
                                    0.26
                                ]
                            ],
                            "v": [
                                [
                                    229.797,
                                    -412.81
                                ],
                                [
                                    223.4,
                                    -413.102
                                ],
                                [
                                    218.923,
                                    -412.38
                                ],
                                [
                                    213.13,
                                    -411.92
                                ],
                                [
                                    207.376,
                                    -411.745
                                ],
                                [
                                    202.85,
                                    -412.059
                                ],
                                [
                                    197.422,
                                    -412.053
                                ],
                                [
                                    193.614,
                                    -412.469
                                ],
                                [
                                    190.774,
                                    -412.914
                                ],
                                [
                                    188.897,
                                    -413.274
                                ],
                                [
                                    187.929,
                                    -413.508
                                ],
                                [
                                    187.701,
                                    -413.579
                                ],
                                [
                                    187.44,
                                    -413.463
                                ],
                                [
                                    182.028,
                                    -413.144
                                ],
                                [
                                    178.61,
                                    -413.898
                                ],
                                [
                                    165.775,
                                    -415.957
                                ],
                                [
                                    153.598,
                                    -412.137
                                ],
                                [
                                    147.269,
                                    -406.754
                                ],
                                [
                                    140.228,
                                    -410.175
                                ],
                                [
                                    155.125,
                                    -422.23
                                ],
                                [
                                    168.985,
                                    -421.34
                                ],
                                [
                                    169.421,
                                    -421.229
                                ],
                                [
                                    178.382,
                                    -420.556
                                ],
                                [
                                    186.567,
                                    -420.002
                                ],
                                [
                                    202.068,
                                    -418.518
                                ],
                                [
                                    214.632,
                                    -416.69
                                ],
                                [
                                    223.012,
                                    -414.429
                                ],
                                [
                                    228.105,
                                    -413.311
                                ]
                            ],
                            "c": true
                        }
                    ]
                }
            ],
            "ix": 2
        },
        "nm": "Path 3",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
    }
    return pathDataKeyframesFromLottieShape(base, [hole1, hole2], 25)
})()

const morph03Kfs_a = (() => {
    const base = {
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
            "a": 1,
            "k": [
                {
                    "i": {
                        "x": 0.2,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 28.666,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    75.708,
                                    25.403
                                ],
                                [
                                    91.708,
                                    25.403
                                ]
                            ],
                            "c": false
                        }
                    ]
                },
                {
                    "t": 60,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    75.751,
                                    70.194
                                ],
                                [
                                    91.751,
                                    70.194
                                ]
                            ],
                            "c": false
                        }
                    ]
                }
            ],
            "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
    }
    return pathDataKeyframesFromLottieShape(base)
})()

const morph03Kfs_b = (() => {
    const base =                         {
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
            "a": 1,
            "k": [
                {
                    "i": {
                        "x": 0.2,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 28.666,
                    "s": [
                        {
                            "i": [
                                [
                                    8.871,
                                    -0.004
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    8.837
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    -8.836
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    -8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    -8.836
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    8.837
                                ]
                            ],
                            "v": [
                                [
                                    56.547,
                                    63.562
                                ],
                                [
                                    -57.484,
                                    63.578
                                ],
                                [
                                    -73.453,
                                    47.594
                                ],
                                [
                                    -73.453,
                                    -2.438
                                ],
                                [
                                    -57.422,
                                    -18.391
                                ],
                                [
                                    56.562,
                                    -18.422
                                ],
                                [
                                    72.594,
                                    -2.422
                                ],
                                [
                                    72.562,
                                    47.578
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.2,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 60,
                    "s": [
                        {
                            "i": [
                                [
                                    8.871,
                                    -0.004
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    8.837
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    -8.836
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    -8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    -8.836
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    8.837
                                ]
                            ],
                            "v": [
                                [
                                    4.548,
                                    42.548
                                ],
                                [
                                    -5.469,
                                    42.563
                                ],
                                [
                                    -21.438,
                                    26.579
                                ],
                                [
                                    -21.438,
                                    18.562
                                ],
                                [
                                    -5.438,
                                    2.562
                                ],
                                [
                                    4.563,
                                    2.55
                                ],
                                [
                                    20.548,
                                    18.562
                                ],
                                [
                                    20.548,
                                    26.579
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.2,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 91.332,
                    "s": [
                        {
                            "i": [
                                [
                                    8.871,
                                    -0.004
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    8.837
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    -8.836
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    -8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    -8.836
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    8.837
                                ]
                            ],
                            "v": [
                                [
                                    9.548,
                                    47.548
                                ],
                                [
                                    -10.469,
                                    47.563
                                ],
                                [
                                    -26.438,
                                    31.579
                                ],
                                [
                                    -26.438,
                                    13.562
                                ],
                                [
                                    -10.438,
                                    -2.438
                                ],
                                [
                                    9.563,
                                    -2.45
                                ],
                                [
                                    25.548,
                                    13.562
                                ],
                                [
                                    25.548,
                                    31.579
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.3,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 122.666,
                    "s": [
                        {
                            "i": [
                                [
                                    8.871,
                                    -0.004
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    8.837
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    -8.836
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    -8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    -8.836
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    8.837
                                ]
                            ],
                            "v": [
                                [
                                    9.548,
                                    47.548
                                ],
                                [
                                    -10.469,
                                    47.563
                                ],
                                [
                                    -26.438,
                                    31.579
                                ],
                                [
                                    -26.438,
                                    13.562
                                ],
                                [
                                    -10.438,
                                    -2.438
                                ],
                                [
                                    9.563,
                                    -2.45
                                ],
                                [
                                    25.548,
                                    13.562
                                ],
                                [
                                    25.548,
                                    31.579
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.3,
                        "y": 1
                    },
                    "o": {
                        "x": 0.7,
                        "y": 0
                    },
                    "t": 373,
                    "s": [
                        {
                            "i": [
                                [
                                    8.871,
                                    -0.004
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    8.837
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    -8.837
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    -8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    -8.836
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    8.837
                                ]
                            ],
                            "v": [
                                [
                                    87.953,
                                    113.156
                                ],
                                [
                                    -88.016,
                                    113.109
                                ],
                                [
                                    -103.984,
                                    97.141
                                ],
                                [
                                    -104.016,
                                    -6.875
                                ],
                                [
                                    -88.016,
                                    -22.859
                                ],
                                [
                                    88.016,
                                    -22.844
                                ],
                                [
                                    104,
                                    -6.859
                                ],
                                [
                                    104,
                                    97.141
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.3,
                        "y": 1
                    },
                    "o": {
                        "x": 0.7,
                        "y": 0
                    },
                    "t": 377,
                    "s": [
                        {
                            "i": [
                                [
                                    8.871,
                                    -0.004
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    8.837
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    -8.836
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    -8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    -8.836
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    8.837
                                ]
                            ],
                            "v": [
                                [
                                    46.547,
                                    53.562
                                ],
                                [
                                    -47.484,
                                    53.578
                                ],
                                [
                                    -63.453,
                                    37.594
                                ],
                                [
                                    -63.453,
                                    7.562
                                ],
                                [
                                    -47.422,
                                    -8.391
                                ],
                                [
                                    46.562,
                                    -8.422
                                ],
                                [
                                    62.594,
                                    7.578
                                ],
                                [
                                    62.562,
                                    37.578
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.3,
                        "y": 1
                    },
                    "o": {
                        "x": 0.7,
                        "y": 0
                    },
                    "t": 397,
                    "s": [
                        {
                            "i": [
                                [
                                    8.871,
                                    -0.004
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    8.837
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    -8.837
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    -8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    -8.836
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    8.837
                                ]
                            ],
                            "v": [
                                [
                                    92.953,
                                    118.156
                                ],
                                [
                                    -93.016,
                                    118.109
                                ],
                                [
                                    -108.984,
                                    102.141
                                ],
                                [
                                    -109.016,
                                    -11.875
                                ],
                                [
                                    -93.016,
                                    -27.859
                                ],
                                [
                                    93.016,
                                    -27.844
                                ],
                                [
                                    109,
                                    -11.859
                                ],
                                [
                                    109,
                                    102.141
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "t": 417,
                    "s": [
                        {
                            "i": [
                                [
                                    8.871,
                                    -0.004
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    8.837
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    -8.837
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    -8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    -8.836
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    8.836,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    8.837
                                ]
                            ],
                            "v": [
                                [
                                    87.953,
                                    113.156
                                ],
                                [
                                    -88.016,
                                    113.109
                                ],
                                [
                                    -103.984,
                                    97.141
                                ],
                                [
                                    -104.016,
                                    -6.875
                                ],
                                [
                                    -88.016,
                                    -22.859
                                ],
                                [
                                    88.016,
                                    -22.844
                                ],
                                [
                                    104,
                                    -6.859
                                ],
                                [
                                    104,
                                    97.141
                                ]
                            ],
                            "c": true
                        }
                    ]
                }
            ],
            "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
    }
    return pathDataKeyframesFromLottieShape(base)
})()

const morph03Kfs_c = (() => {
    const base = {
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
            "a": 1,
            "k": [
                {
                    "i": {
                        "x": 0.2,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 28.666,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    101,
                                    48
                                ],
                                [
                                    101,
                                    64
                                ]
                            ],
                            "c": false
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.2,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 60,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    101.051,
                                    52.625
                                ],
                                [
                                    101.048,
                                    57.523
                                ]
                            ],
                            "c": false
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.2,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 91.332,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    101.051,
                                    51.375
                                ],
                                [
                                    101.048,
                                    58.773
                                ]
                            ],
                            "c": false
                        }
                    ]
                },
                {
                    "t": 122.666015625,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    101.051,
                                    51.375
                                ],
                                [
                                    101.048,
                                    58.773
                                ]
                            ],
                            "c": false
                        }
                    ]
                }
            ],
            "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
    }
    return pathDataKeyframesFromLottieShape(base)
})()

const morph03Kfs_d = (() => {
    const base = {
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
            "a": 1,
            "k": [
                {
                    "i": {
                        "x": 0.2,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 28.666,
                    "s": [
                        {
                            "i": [
                                [
                                    2.209,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    2.209
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    -2.209,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    2.209
                                ]
                            ],
                            "v": [
                                [
                                    13,
                                    1.875
                                ],
                                [
                                    -13,
                                    1.875
                                ],
                                [
                                    -17,
                                    -2.125
                                ],
                                [
                                    -17,
                                    -10.208
                                ],
                                [
                                    17,
                                    -10.208
                                ],
                                [
                                    17,
                                    -2.125
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "t": 60,
                    "s": [
                        {
                            "i": [
                                [
                                    2.209,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    1.831,
                                    -0.081
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    -2.209,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -3.222,
                                    -0.081
                                ]
                            ],
                            "v": [
                                [
                                    6.476,
                                    34.087
                                ],
                                [
                                    -5.196,
                                    34.087
                                ],
                                [
                                    -9.201,
                                    34.143
                                ],
                                [
                                    -9.16,
                                    34.067
                                ],
                                [
                                    10.511,
                                    34.067
                                ],
                                [
                                    10.47,
                                    34.143
                                ]
                            ],
                            "c": true
                        }
                    ]
                }
            ],
            "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
    }
    return pathDataKeyframesFromLottieShape(base)
})()

const morph03Kfs_e = (() => {
    const base = {
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
            "a": 1,
            "k": [
                {
                    "i": {
                        "x": 0.2,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 28.666,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    11,
                                    40
                                ],
                                [
                                    11,
                                    48
                                ]
                            ],
                            "c": false
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.4,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 60,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    35.984,
                                    84.438
                                ],
                                [
                                    35.983,
                                    93.031
                                ]
                            ],
                            "c": false
                        }
                    ]
                },
                {
                    "t": 368,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    21,
                                    40
                                ],
                                [
                                    21,
                                    48
                                ]
                            ],
                            "c": false
                        }
                    ]
                }
            ],
            "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
    }
    return pathDataKeyframesFromLottieShape(base)
})()

const morph03Kfs_f = (() => {
    const base = {
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
            "a": 1,
            "k": [
                {
                    "i": {
                        "x": 0.2,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 28.666,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    9.156,
                                    60.007
                                ],
                                [
                                    9.156,
                                    70.007
                                ]
                            ],
                            "c": false
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.4,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 60,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    34.172,
                                    84.538
                                ],
                                [
                                    34.141,
                                    91.788
                                ]
                            ],
                            "c": false
                        }
                    ]
                },
                {
                    "t": 364,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    19.156,
                                    60.007
                                ],
                                [
                                    19.156,
                                    70.007
                                ]
                            ],
                            "c": false
                        }
                    ]
                }
            ],
            "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
    }
    return pathDataKeyframesFromLottieShape(base)
})()

const morph03Kfs_g = (() => {
    const base = {
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
            "a": 1,
            "k": [
                {
                    "i": {
                        "x": 0.2,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 28.666,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    11,
                                    81
                                ],
                                [
                                    11,
                                    91
                                ]
                            ],
                            "c": false
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.4,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 60,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    35.968,
                                    83.031
                                ],
                                [
                                    35.968,
                                    93.031
                                ]
                            ],
                            "c": false
                        }
                    ]
                },
                {
                    "t": 368,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    21,
                                    81
                                ],
                                [
                                    21,
                                    91
                                ]
                            ],
                            "c": false
                        }
                    ]
                }
            ],
            "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
    }
    return pathDataKeyframesFromLottieShape(base)
})()

const morph03Kfs_h = (() => {
    const base = {
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
            "a": 1,
            "k": [
                {
                    "i": {
                        "x": 0.2,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 28.666,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    3.058,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    2.919,
                                    0.01
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    -2.892,
                                    -0.021
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -3.057,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    19.938,
                                    37.992
                                ],
                                [
                                    19.064,
                                    37.998
                                ],
                                [
                                    5.607,
                                    37.992
                                ],
                                [
                                    -5.653,
                                    37.992
                                ],
                                [
                                    -19.122,
                                    37.998
                                ],
                                [
                                    -19.875,
                                    37.992
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.2,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 60,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    3.058,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0.337,
                                    3.039
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    -0.337,
                                    3.039
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -3.057,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    9.875,
                                    -14
                                ],
                                [
                                    8.468,
                                    1.787
                                ],
                                [
                                    2.505,
                                    7.125
                                ],
                                [
                                    -2.505,
                                    7.125
                                ],
                                [
                                    -8.468,
                                    1.787
                                ],
                                [
                                    -9.875,
                                    -14
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.2,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 91.332,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    3.058,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0.337,
                                    3.039
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    -0.337,
                                    3.039
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -3.057,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    13,
                                    -9
                                ],
                                [
                                    11.593,
                                    3.662
                                ],
                                [
                                    5.63,
                                    9
                                ],
                                [
                                    -5.63,
                                    9
                                ],
                                [
                                    -11.593,
                                    3.662
                                ],
                                [
                                    -13,
                                    -9
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "t": 122.666015625,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    3.058,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0.337,
                                    3.039
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    -0.337,
                                    3.039
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -3.057,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    13,
                                    -9
                                ],
                                [
                                    11.593,
                                    3.662
                                ],
                                [
                                    5.63,
                                    9
                                ],
                                [
                                    -5.63,
                                    9
                                ],
                                [
                                    -11.593,
                                    3.662
                                ],
                                [
                                    -13,
                                    -9
                                ]
                            ],
                            "c": true
                        }
                    ]
                }
            ],
            "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
    }
    return pathDataKeyframesFromLottieShape(base)
})()

const morph03Kfs_i = (() => {
    const base = {
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
            "a": 1,
            "k": [
                {
                    "i": {
                        "x": 0.2,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 28.666,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -3.058,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -2.504,
                                    -0.07
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    2.082,
                                    -0.07
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    3.057,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    -17.031,
                                    -37.587
                                ],
                                [
                                    -16.266,
                                    -37.928
                                ],
                                [
                                    -5.613,
                                    -38.055
                                ],
                                [
                                    5.553,
                                    -38.055
                                ],
                                [
                                    16.201,
                                    -37.928
                                ],
                                [
                                    16.97,
                                    -37.587
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.2,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 60,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -3.058,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -0.337,
                                    -3.038
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0.337,
                                    -3.038
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    3.057,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    -9.875,
                                    14
                                ],
                                [
                                    -8.468,
                                    -1.788
                                ],
                                [
                                    -3.755,
                                    -7.125
                                ],
                                [
                                    2.505,
                                    -7.125
                                ],
                                [
                                    8.468,
                                    -1.788
                                ],
                                [
                                    9.875,
                                    14
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.2,
                        "y": 1
                    },
                    "o": {
                        "x": 0.8,
                        "y": 0
                    },
                    "t": 91.332,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -3.058,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -0.337,
                                    -3.038
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0.337,
                                    -3.038
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    3.057,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    -13,
                                    9
                                ],
                                [
                                    -11.593,
                                    -3.663
                                ],
                                [
                                    -5.63,
                                    -9
                                ],
                                [
                                    5.63,
                                    -9
                                ],
                                [
                                    11.593,
                                    -3.663
                                ],
                                [
                                    13,
                                    9
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "t": 122.666015625,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -3.058,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -0.337,
                                    -3.038
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0.337,
                                    -3.038
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    3.057,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    -13,
                                    9
                                ],
                                [
                                    -11.593,
                                    -3.663
                                ],
                                [
                                    -5.63,
                                    -9
                                ],
                                [
                                    5.63,
                                    -9
                                ],
                                [
                                    11.593,
                                    -3.663
                                ],
                                [
                                    13,
                                    9
                                ]
                            ],
                            "c": true
                        }
                    ]
                }
            ],
            "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
    }
    return pathDataKeyframesFromLottieShape(base)
})()

const morph04Kfs_a = (() => {
    const base = {
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
            "a": 1,
            "k": [
                {
                    "i": {
                        "x": 0.833,
                        "y": 1
                    },
                    "o": {
                        "x": 0.333,
                        "y": 0
                    },
                    "t": 36,
                    "s": [
                        {
                            "i": [
                                [
                                    1.949,
                                    7.795
                                ],
                                [
                                    0.75,
                                    -6.75
                                ],
                                [
                                    -6.975,
                                    -2.36
                                ],
                                [
                                    0.24,
                                    -3.001
                                ],
                                [
                                    11,
                                    6.25
                                ]
                            ],
                            "o": [
                                [
                                    -2,
                                    -8
                                ],
                                [
                                    -0.635,
                                    5.715
                                ],
                                [
                                    6.137,
                                    2.077
                                ],
                                [
                                    -0.5,
                                    6.25
                                ],
                                [
                                    -11,
                                    -6.25
                                ]
                            ],
                            "v": [
                                [
                                    8.5,
                                    -23.25
                                ],
                                [
                                    -7.5,
                                    -22.25
                                ],
                                [
                                    0.75,
                                    -11.25
                                ],
                                [
                                    8.5,
                                    -2.25
                                ],
                                [
                                    -8.25,
                                    2.25
                                ]
                            ],
                            "c": false
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 1
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0
                    },
                    "t": 40,
                    "s": [
                        {
                            "i": [
                                [
                                    1.481,
                                    5.922
                                ],
                                [
                                    -0.103,
                                    -6.956
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -0.561,
                                    -3.757
                                ],
                                [
                                    7.13,
                                    4.064
                                ]
                            ],
                            "o": [
                                [
                                    -0.498,
                                    -5.404
                                ],
                                [
                                    1.096,
                                    7.151
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    1.23,
                                    6.179
                                ],
                                [
                                    -8.357,
                                    -4.748
                                ]
                            ],
                            "v": [
                                [
                                    3.846,
                                    -26.1
                                ],
                                [
                                    -4.884,
                                    -21.728
                                ],
                                [
                                    0.829,
                                    -11.435
                                ],
                                [
                                    5.896,
                                    -2.554
                                ],
                                [
                                    -2.997,
                                    4.553
                                ]
                            ],
                            "c": false
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.233,
                        "y": 1
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0
                    },
                    "t": 44,
                    "s": [
                        {
                            "i": [
                                [
                                    -1.641,
                                    1.547
                                ],
                                [
                                    -0.389,
                                    -0.949
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0.455,
                                    -4.468
                                ],
                                [
                                    0.337,
                                    0.228
                                ]
                            ],
                            "o": [
                                [
                                    2.696,
                                    2.45
                                ],
                                [
                                    -0.449,
                                    5.257
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    3.124,
                                    6.176
                                ],
                                [
                                    -3.718,
                                    -2.113
                                ]
                            ],
                            "v": [
                                [
                                    -4.322,
                                    -31.103
                                ],
                                [
                                    1.665,
                                    -21.473
                                ],
                                [
                                    0.968,
                                    -11.76
                                ],
                                [
                                    0.346,
                                    -2.756
                                ],
                                [
                                    6.223,
                                    8.595
                                ]
                            ],
                            "c": false
                        }
                    ]
                },
                {
                    "t": 52,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    -5.917,
                                    -6.519
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    2.235,
                                    -3.797
                                ],
                                [
                                    -5.107,
                                    -2.847
                                ]
                            ],
                            "o": [
                                [
                                    4.253,
                                    2.806
                                ],
                                [
                                    -2.177,
                                    3.651
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    6.701,
                                    5.953
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    -10.869,
                                    -35.113
                                ],
                                [
                                    6.345,
                                    -21.076
                                ],
                                [
                                    1.079,
                                    -12.021
                                ],
                                [
                                    -3.818,
                                    -3.015
                                ],
                                [
                                    13.612,
                                    11.835
                                ]
                            ],
                            "c": false
                        }
                    ]
                }
            ],
            "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
    }
    return pathDataKeyframesFromLottieShape(base, [], 25)
})()

const morph04Kfs_b = (() => {
    const base = {
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
            "a": 1,
            "k": [
                {
                    "i": {
                        "x": 0.244,
                        "y": 1
                    },
                    "o": {
                        "x": 0.333,
                        "y": 0
                    },
                    "t": 52,
                    "s": [
                        {
                            "i": [
                                [
                                    2.719,
                                    2.25
                                ],
                                [
                                    4.375,
                                    -0.75
                                ],
                                [
                                    5.625,
                                    0.125
                                ],
                                [
                                    1.703,
                                    -2.779
                                ],
                                [
                                    -1.438,
                                    -1.125
                                ],
                                [
                                    -5.25,
                                    0
                                ],
                                [
                                    -7.205,
                                    1.651
                                ]
                            ],
                            "o": [
                                [
                                    -3.125,
                                    -2.094
                                ],
                                [
                                    -2.98,
                                    0.511
                                ],
                                [
                                    -5.625,
                                    -0.125
                                ],
                                [
                                    -1.188,
                                    1.938
                                ],
                                [
                                    3.413,
                                    2.671
                                ],
                                [
                                    5.25,
                                    0
                                ],
                                [
                                    6,
                                    -1.375
                                ]
                            ],
                            "v": [
                                [
                                    24.281,
                                    -23.5
                                ],
                                [
                                    8.75,
                                    -9.25
                                ],
                                [
                                    -7.5,
                                    -18
                                ],
                                [
                                    -24.75,
                                    -3.5
                                ],
                                [
                                    -24.5,
                                    -1.125
                                ],
                                [
                                    -7.125,
                                    -16.5
                                ],
                                [
                                    9,
                                    -7
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "t": 64,
                    "s": [
                        {
                            "i": [
                                [
                                    2.719,
                                    2.25
                                ],
                                [
                                    3,
                                    -0.375
                                ],
                                [
                                    5.625,
                                    0.125
                                ],
                                [
                                    1.703,
                                    -2.779
                                ],
                                [
                                    -1.438,
                                    -1.125
                                ],
                                [
                                    -5.25,
                                    0
                                ],
                                [
                                    -7.39,
                                    -0.148
                                ]
                            ],
                            "o": [
                                [
                                    -3.125,
                                    -2.094
                                ],
                                [
                                    -3,
                                    0.375
                                ],
                                [
                                    -5.625,
                                    -0.125
                                ],
                                [
                                    -1.188,
                                    1.938
                                ],
                                [
                                    3.413,
                                    2.671
                                ],
                                [
                                    5.25,
                                    0
                                ],
                                [
                                    6.25,
                                    0.125
                                ]
                            ],
                            "v": [
                                [
                                    27.406,
                                    -27.875
                                ],
                                [
                                    8.5,
                                    -15.75
                                ],
                                [
                                    -9,
                                    -25.875
                                ],
                                [
                                    -28.125,
                                    -1.5
                                ],
                                [
                                    -28,
                                    2.5
                                ],
                                [
                                    -8.5,
                                    -9.875
                                ],
                                [
                                    9,
                                    0
                                ]
                            ],
                            "c": true
                        }
                    ]
                }
            ],
            "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
    }
    return pathDataKeyframesFromLottieShape(base, [], 25)
})()

const morph04Kfs_c = (() => {
    const base = {
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
            "a": 1,
            "k": [
                {
                    "i": {
                        "x": 0.833,
                        "y": 1
                    },
                    "o": {
                        "x": 0,
                        "y": 0
                    },
                    "t": 4,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    -14.912
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    4.886,
                                    -4.886
                                ],
                                [
                                    7.456,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    4.886,
                                    4.886
                                ],
                                [
                                    0,
                                    7.456
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -14.912,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    7.456
                                ],
                                [
                                    -4.886,
                                    4.886
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -7.456,
                                    0
                                ],
                                [
                                    -4.886,
                                    -4.886
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    -14.912
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    14.912,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    60.375,
                                    -32.625
                                ],
                                [
                                    60.375,
                                    0.375
                                ],
                                [
                                    60.375,
                                    33.375
                                ],
                                [
                                    52.467,
                                    52.467
                                ],
                                [
                                    33.375,
                                    60.375
                                ],
                                [
                                    0.375,
                                    60.375
                                ],
                                [
                                    -32.625,
                                    60.375
                                ],
                                [
                                    -51.717,
                                    52.467
                                ],
                                [
                                    -59.625,
                                    33.375
                                ],
                                [
                                    -59.625,
                                    -32.625
                                ],
                                [
                                    -32.625,
                                    -59.625
                                ],
                                [
                                    33.375,
                                    -59.625
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.667,
                        "y": 1
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0
                    },
                    "t": 11,
                    "s": [
                        {
                            "i": [
                                [
                                    -1.875,
                                    -3.75
                                ],
                                [
                                    0,
                                    -8.625
                                ],
                                [
                                    2.125,
                                    -6.375
                                ],
                                [
                                    4.969,
                                    -4.969
                                ],
                                [
                                    18.5,
                                    0
                                ],
                                [
                                    6.75,
                                    5.625
                                ],
                                [
                                    1.75,
                                    5.312
                                ],
                                [
                                    0,
                                    8.5
                                ],
                                [
                                    -2.909,
                                    7.223
                                ],
                                [
                                    -5.263,
                                    4.299
                                ],
                                [
                                    -15.5,
                                    -0.5
                                ],
                                [
                                    -7.661,
                                    -5.746
                                ]
                            ],
                            "o": [
                                [
                                    2,
                                    5.75
                                ],
                                [
                                    0,
                                    8.625
                                ],
                                [
                                    -2.125,
                                    6.375
                                ],
                                [
                                    -7.25,
                                    7.25
                                ],
                                [
                                    -18,
                                    0
                                ],
                                [
                                    -8.49,
                                    -7.075
                                ],
                                [
                                    -1.776,
                                    -5.39
                                ],
                                [
                                    0,
                                    -8.5
                                ],
                                [
                                    3.625,
                                    -9
                                ],
                                [
                                    4.437,
                                    -3.625
                                ],
                                [
                                    16.755,
                                    0.54
                                ],
                                [
                                    10,
                                    7.5
                                ]
                            ],
                            "v": [
                                [
                                    49,
                                    -19.125
                                ],
                                [
                                    52.125,
                                    0.5
                                ],
                                [
                                    49.25,
                                    18.375
                                ],
                                [
                                    37.625,
                                    37.188
                                ],
                                [
                                    1.375,
                                    51.875
                                ],
                                [
                                    -34.625,
                                    39.875
                                ],
                                [
                                    -49,
                                    18.813
                                ],
                                [
                                    -52.375,
                                    -0.25
                                ],
                                [
                                    -48.5,
                                    -19.875
                                ],
                                [
                                    -31.75,
                                    -41.438
                                ],
                                [
                                    1.375,
                                    -51.625
                                ],
                                [
                                    34.125,
                                    -39.875
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "t": 81,
                    "s": [
                        {
                            "i": [
                                [
                                    -1.875,
                                    -3.75
                                ],
                                [
                                    0,
                                    -8.625
                                ],
                                [
                                    2.125,
                                    -6.375
                                ],
                                [
                                    4.969,
                                    -4.969
                                ],
                                [
                                    18.5,
                                    0
                                ],
                                [
                                    6.75,
                                    5.625
                                ],
                                [
                                    1.75,
                                    5.312
                                ],
                                [
                                    0,
                                    8.5
                                ],
                                [
                                    -2.909,
                                    7.223
                                ],
                                [
                                    -5.263,
                                    4.299
                                ],
                                [
                                    -15.5,
                                    -0.5
                                ],
                                [
                                    -7.661,
                                    -5.746
                                ]
                            ],
                            "o": [
                                [
                                    2,
                                    5.75
                                ],
                                [
                                    0,
                                    8.625
                                ],
                                [
                                    -2.125,
                                    6.375
                                ],
                                [
                                    -7.25,
                                    7.25
                                ],
                                [
                                    -18,
                                    0
                                ],
                                [
                                    -8.49,
                                    -7.075
                                ],
                                [
                                    -1.776,
                                    -5.39
                                ],
                                [
                                    0,
                                    -8.5
                                ],
                                [
                                    3.625,
                                    -9
                                ],
                                [
                                    4.437,
                                    -3.625
                                ],
                                [
                                    16.755,
                                    0.54
                                ],
                                [
                                    10,
                                    7.5
                                ]
                            ],
                            "v": [
                                [
                                    49,
                                    -19.125
                                ],
                                [
                                    52.125,
                                    0.5
                                ],
                                [
                                    49.25,
                                    18.375
                                ],
                                [
                                    37.625,
                                    37.188
                                ],
                                [
                                    1.375,
                                    51.875
                                ],
                                [
                                    -34.625,
                                    39.875
                                ],
                                [
                                    -49,
                                    18.813
                                ],
                                [
                                    -52.375,
                                    -0.25
                                ],
                                [
                                    -48.5,
                                    -19.875
                                ],
                                [
                                    -31.75,
                                    -41.438
                                ],
                                [
                                    1.375,
                                    -51.625
                                ],
                                [
                                    34.125,
                                    -39.875
                                ]
                            ],
                            "c": true
                        }
                    ]
                }
            ],
            "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
    }
    return pathDataKeyframesFromLottieShape(base, [], 25)
})()

const morph04Kfs_d = (() => {
    const base = {
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
            "a": 0,
            "k": {
                "i": [
                    [
                        0,
                        -14.912
                    ],
                    [
                        0,
                        0
                    ],
                    [
                        14.912,
                        0
                    ],
                    [
                        0,
                        0
                    ],
                    [
                        0,
                        14.912
                    ],
                    [
                        0,
                        0
                    ],
                    [
                        -14.912,
                        0
                    ],
                    [
                        0,
                        0
                    ]
                ],
                "o": [
                    [
                        0,
                        0
                    ],
                    [
                        0,
                        14.912
                    ],
                    [
                        0,
                        0
                    ],
                    [
                        -14.912,
                        0
                    ],
                    [
                        0,
                        0
                    ],
                    [
                        0,
                        -14.912
                    ],
                    [
                        0,
                        0
                    ],
                    [
                        14.912,
                        0
                    ]
                ],
                "v": [
                    [
                        60,
                        -33
                    ],
                    [
                        60,
                        33
                    ],
                    [
                        33,
                        60
                    ],
                    [
                        -33,
                        60
                    ],
                    [
                        -60,
                        33
                    ],
                    [
                        -60,
                        -33
                    ],
                    [
                        -33,
                        -60
                    ],
                    [
                        33,
                        -60
                    ]
                ],
                "c": true
            },
            "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
    }
    return [{ time: 0, value: base.ks.k }]
})()

const morph05Kfs = (() => {
    const base = {
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
            "a": 1,
            "k": [
                {
                    "i": {
                        "x": 0.833,
                        "y": 0.922
                    },
                    "o": {
                        "x": 0.333,
                        "y": 0
                    },
                    "t": 33.037,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    25.846,
                                    -86.953
                                ],
                                [
                                    25.288,
                                    -89.953
                                ],
                                [
                                    21.716,
                                    -89.214
                                ],
                                [
                                    20.773,
                                    -86.328
                                ],
                                [
                                    23.603,
                                    -84.709
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 0.883
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0.078
                    },
                    "t": 38.975,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    1.286,
                                    -0.429
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -1.286,
                                    0.429
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    34.812,
                                    -191.135
                                ],
                                [
                                    -20.76,
                                    -24.435
                                ],
                                [
                                    83.474,
                                    -96.116
                                ],
                                [
                                    -18.297,
                                    -81.493
                                ],
                                [
                                    61.359,
                                    -18.572
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.833,
                        "y": 0.922
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0.117
                    },
                    "t": 45.409,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    3.505,
                                    -1.168
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -3.505,
                                    1.168
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    40.354,
                                    -72.435
                                ],
                                [
                                    -60.609,
                                    32.132
                                ],
                                [
                                    76.041,
                                    -63.457
                                ],
                                [
                                    -76.78,
                                    -77.901
                                ],
                                [
                                    55.634,
                                    67.841
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "i": {
                        "x": 0.667,
                        "y": 1
                    },
                    "o": {
                        "x": 0.167,
                        "y": 0.078
                    },
                    "t": 49.677,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    4.936,
                                    -1.645
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -4.936,
                                    1.645
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    1.341,
                                    -286.75
                                ],
                                [
                                    -240.277,
                                    188.638
                                ],
                                [
                                    71.247,
                                    -42.392
                                ],
                                [
                                    -114.503,
                                    -75.584
                                ],
                                [
                                    51.942,
                                    123.58
                                ]
                            ],
                            "c": true
                        }
                    ]
                },
                {
                    "t": 56.0966796875,
                    "s": [
                        {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    6.24,
                                    -2.08
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    -6.24,
                                    2.08
                                ],
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    -5.136,
                                    -339.56
                                ],
                                [
                                    -198.087,
                                    227.87
                                ],
                                [
                                    321.297,
                                    -122.695
                                ],
                                [
                                    -327.783,
                                    -124.801
                                ],
                                [
                                    206.754,
                                    236.118
                                ]
                            ],
                            "c": true
                        }
                    ]
                }
            ],
            "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
    }
    return pathDataKeyframesFromLottieShape(base)
})()

/** @type {DemoData} */
export const MorphExample1 = {
    name: "Morph Example 1",
    width: 1000,
    height: 1000,
    tree: [
        {
            name: 'shape',
            shape: {
                type: 'path',
                path: morph01Kfs[0].value,
            },
            fills: [
                [0.039215686275, 0.811764705882, 0.513725490196, 1],
            ],
            transform: {
                position: [500, 500],
            },
        }
    ],
    animation: {
        mode: "forward",
        tracks: [
            {
                node: 'shape',
                key: 'path',
                kfs: morph01Kfs
            },
            {
                node: 'shape',
                key: 'fill',
                kfs: [
                    { time: 0 / 50, value: [1, 0.874509811401, 0.298039227724, 1], frameIn: [0.833, 0.833], frameOut: [0.167, 0.167] },
                    { time: 26 / 50, value: [1, 0.874509811401, 0.298039227724, 1], frameIn: [0.833, 0.833], frameOut: [0.167, 0.167] },
                    { time: 37 / 50, value: [1, 0.874509811401, 0.298039227724, 1], frameIn: [0.833, 0.833], frameOut: [0.167, 0.167] },
                    { time: 68 / 50, value: [1, 0.266666680574, 0.278431385756, 1], frameIn: [0.833, 0.833], frameOut: [0.167, 0.167] },
                    { time: 81 / 50, value: [1, 0.266666680574, 0.278431385756, 1], frameIn: [0.833, 0.833], frameOut: [0.167, 0.167] },
                    { time: 104 / 50, value: [0.039215686275, 0.811764705882, 0.513725490196, 1], frameIn: [0.833, 0.833], frameOut: [0.167, 0.167] },
                ]
            },
        ]
    }
}

/** @type {DemoData} */
export const MorphExample2 = {
    name: "Morph Example 2",
    width: 1197,
    height: 924,
    tree: [
        {
            name: 'backgrounds',
            shape: {
                type: 'rect',
                width: 1197,
                height: 924,
            },
            fills: ["#FFFFFF"]
        },
        {
            name: 'shape',
            shape: {
                type: 'path',
                path: morph02Kfs[0].value,
            },
            fills: [
                [0, 0, 0, 1]
            ],
            transform: {
                position: [374.517, 669],
                origin: [-136.644, 129],
                scale: [1.69, 1.69],
            }
        }
    ],
    animation: {
        mode: "forward",
        tracks: [
            {
                node: 'shape',
                key: 'transform.rotation',
                kfs: [
                    { time: 0 / 25, value: deg2rad(0) },
                    { time: 3 / 25, value: deg2rad(8) },
                    { time: 14 / 25, value: deg2rad(-44) },
                    { time: 22 / 25, value: deg2rad(9) },
                    { time: 33 / 25, value: deg2rad(0) },
                    { time: 41 / 25, value: deg2rad(0) },
                    { time: 44 / 25, value: deg2rad(8) },
                    { time: 55 / 25, value: deg2rad(-44) },
                    { time: 63 / 25, value: deg2rad(9) },
                    { time: 74 / 25, value: deg2rad(0) },
                    { time: 77 / 25, value: deg2rad(0) },
                ]
            },
            {
                node: 'shape',
                key: 'path',
                kfs: morph02Kfs
            },
        ],
    },
}

/** @type {DemoData} */
export const MorphExample3 = {
    name: "Morph Example 3",
    width: 400,
    height: 400,
    tree: [
        {
            name: 'iPadPowerBtn',
            shape: {
                type: 'path',
                path: morph03Kfs_a[0].value,
            },
            strokes: [
                {
                    lineWidth: 8,
                    paint: "#FFFFFF",
                    cap: CapShape.ROUND,
                    join: JoinShape.MITER,
                    miterLimit: 10,
                },
            ],
            transform: {
                position: [200, 200],
                rotation: deg2rad(270)
            }
        },
        {
            name: 'screen',
            shape: {
                type: 'path',
                path: morph03Kfs_b[0].value,
            },
            strokes: [
                {
                    lineWidth: 8,
                    paint: "#FFFFFF",
                    cap: CapShape.NONE,
                    join: JoinShape.MITER,
                    miterLimit: 10
                },
            ],
            transform: {
                position: [200, 200],
                rotation: deg2rad(270)
            }
        },
        {
            name: 'iPhoneRightBtn',
            shape: {
                type: 'path',
                path: morph03Kfs_c[0].value,
            },
            strokes: [
                {
                    lineWidth: 8,
                    paint: "#FFFFFF",
                    cap: CapShape.ROUND,
                    join: JoinShape.MITER,
                    miterLimit: 10,
                },
            ],
            transform: {
                position: [200, 200],
                rotation: deg2rad(270)
            }
        },
        {
            name: 'iPhoneNotch',
            shape: {
                type: 'path',
                path: morph03Kfs_d[0].value,
            },
            fills: ["#FFFFFF"],
            transform: {
                position: [200, 200],
                rotation: deg2rad(270)
            }
        },
        {
            name: 'iPhoneSwitch',
            shape: {
                type: 'path',
                path: morph03Kfs_e[0].value,
            },
            strokes: [
                {
                    lineWidth: 8,
                    paint: "#FFFFFF",
                    cap: CapShape.ROUND,
                    join: JoinShape.MITER,
                    miterLimit: 10,
                },
            ],
            transform: {
                position: [200, 200],
                rotation: deg2rad(270)
            }
        },
        {
            name: 'iPhoneTopVolumeBtn',
            shape: {
                type: 'path',
                path: morph03Kfs_f[0].value,
            },
            strokes: [
                {
                    lineWidth: 8,
                    paint: "#FFFFFF",
                    cap: CapShape.ROUND,
                    join: JoinShape.MITER,
                    miterLimit: 10,
                },
            ],
            transform: {
                position: [200, 200],
                rotation: deg2rad(270)
            }
        },
        {
            name: 'iPhoneBottomVolumeBtn',
            shape: {
                type: 'path',
                path: morph03Kfs_g[0].value,
            },
            strokes: [
                {
                    lineWidth: 8,
                    paint: "#FFFFFF",
                    cap: CapShape.ROUND,
                    join: JoinShape.MITER,
                    miterLimit: 10,
                },
            ],
            transform: {
                position: [200, 200],
                rotation: deg2rad(270)
            }
        },
        {
            name: 'appleWatchTopStrap',
            shape: {
                type: 'path',
                path: morph03Kfs_h[0].value,
            },
            strokes: [
                {
                    lineWidth: 8,
                    paint: "#FFFFFF",
                    cap: CapShape.ROUND,
                    join: JoinShape.MITER,
                    miterLimit: 10,
                },
            ],
            transform: {
                position: [200, 200],
                rotation: deg2rad(270)
            }
        },
        {
            name: 'appleWatchBottomStrap',
            shape: {
                type: 'path',
                path: morph03Kfs_i[0].value,
            },
            strokes: [
                {
                    lineWidth: 8,
                    paint: "#FFFFFF",
                    cap: CapShape.ROUND,
                    join: JoinShape.MITER,
                    miterLimit: 10,
                },
            ],
            transform: {
                position: [200, 200],
                rotation: deg2rad(270)
            }
        },
    ],
    animation: {
        mode: "forward",
        tracks: [
            {
                node: 'screen',
                key: 'path',
                kfs: morph03Kfs_b
            },
            {
                node: 'iPhoneRightBtn',
                key: 'path',
                kfs: morph03Kfs_c
            },
            {
                node: 'iPhoneNotch',
                key: 'path',
                kfs: morph03Kfs_d
            },
            {
                node: 'iPhoneSwitch',
                key: 'path',
                kfs: morph03Kfs_e
            },
            {
                node: 'iPhoneTopVolumeBtn',
                key: 'path',
                kfs: morph03Kfs_f
            },
            {
                node: 'iPhoneBottomVolumeBtn',
                key: 'path',
                kfs: morph03Kfs_g
            },
            {
                node: 'appleWatchTopStrap',
                key: 'path',
                kfs: morph03Kfs_h
            },
            {
                node: 'appleWatchBottomStrap',
                key: 'path',
                kfs: morph03Kfs_i
            },
            // TODO: animate the border lineWidth of shapeE, shapeF, shapeG, shapeH, shapeI
        ]
    }
}

/** @type {DemoData} */
export const MorphExample4 = {
    name: "Morph Example 4",
    width: 450,
    height: 450,
    tree: [
        {
            name: 'background',
            shape: {
                type: 'path',
                path: morph04Kfs_d[0].value,
            },
            fills: [
                "#FFFFFF",
            ],
            transform: {
                position: [225, 222.8],
                scale: [2.8, 2.8]
            }
        },
        {
            name: 'gradient',
            shape: {
                type: 'path',
                path: morph04Kfs_c[0].value,
            },
            fills: [
                {
                    gradientType: "radial",
                    colorStops: [
                        { position: 0, color: [0.627, 0.953, 1, 1] },
                        { position: 0.5, color: [0.381, 0.735, 0.979, 1] },
                        { position: 1, color: [0.136, 0.517, 0.957, 1] }
                    ],
                    transform: {
                        position: [0.4, 0.75],
                        rotation: deg2rad(90)
                    }
                }
            ],
            transform: {
                position: [225, 222.8]
            }
        },
        {
            name: 'flash',
            shape: {
                type: 'path',
                path: morph04Kfs_b[0].value,
            },
            fills: [
                "#FFFFFF",
            ],
            transform: {
                position: [223.163, 226.619],
                origin: [-0.656, -12.636],
                scale: [2.8, 2.8]
            },
            visible: false,
        },
        {
            name: 's',
            shape: {
                type: 'path',
                path: morph04Kfs_a[0].value,
            },
            strokes: [
                {
                    lineWidth: 5,
                    paint: "#FFFFFF",
                    cap: CapShape.ROUND,
                    join: JoinShape.ROUND
                },
            ],
            trim: {
                begin: 0.99,
                end: 0.99
            },
            transform: {
                position: [225, 222.8],
                origin: [0.137, -11.548],
                scale: [2.8, 2.8]
            },
            visible: false,
        },
    ],
    animation: {
        mode: "forward",
        tracks: [
            {
                node: 'gradient',
                key: "transform.scale",
                kfs: [
                    { time: 0, value: (2.8, 2.8) },
                    { time: 11 / 25, value: [2.8, 2.8], frameIn: [0.667, 1], frameOut: [0.333, 0] },
                    { time: 36 / 25, value: [2.156, 2.156], frameIn: [0.833, 1], frameOut: [0.167, 0] },
                    { time: 52 / 25, value: [2.408, 2.408], frameIn: [0.667, 1], frameOut: [0.167, 0] },
                    { time: 81 / 25, value: [2.408, 2.408] }
                ]
            },
            {
                node: 'gradient',
                key: 'path',
                kfs: morph04Kfs_c
            },
            {
                node: 'flash',
                key: "visible",
                kfs: [
                    { time: 0, value: false },
                    { time: 57 / 25, value: true },
                ]
            },
            {
                node: 'flash',
                key: 'path',
                kfs: morph04Kfs_b
            },
            {
                node: 's',
                key: "transform.rotation",
                kfs: [
                    { time: 36 / 25, value: deg2rad(0), frameIn: [0.233, 1], frameOut: [0.333, 0] },
                    { time: 52 / 25, value: deg2rad(93.428) }
                ]
            },
            {
                node: 's',
                key: "visible",
                kfs: [
                    { time: 11 / 25, value: true },
                    { time: 65 / 25, value: false }
                ]
            },
            {
                node: 's',
                key: "trim.begin",
                kfs: [
                    { time: 11 / 25, value: 0.99, frameIn: [0.364, 1], frameOut: [0.081, 0] },
                    { time: 28 / 25, value: 0.01 }
                ]
            },
            {
                node: 's',
                key: 'path',
                kfs: morph04Kfs_a
            },
        ]
    }
}

/** @type {DemoData} */
export const MorphExample5 = {
    name: "Morph Example 5",
    width: 1000,
    height: 895,
    tree: [
        {
            name: 'star',
            shape: {
                type: 'path',
                path: morph05Kfs[0].value,
            },
            strokes: [
                {
                    lineWidth: 12,
                    paint: [0.995343137255, 0.608348891314, 0.147990193087, 1],
                    cap: CapShape.NONE,
                    join: JoinShape.ROUND,
                },
            ],
            fills: [
                [1, 0.672155402688, 0.300735294118, 0.3]
            ],
            transform: {
                origin: [23.309, -87.331],
                position: [569.784, 459.114],
                scale: [1, 1]
            }
        }
    ],
    animation: {
        mode: "forward",
        tracks: [
            {
                node: 'star',
                key: 'path',
                kfs: morph05Kfs,
            },
        ]
    }
}
