import {
    BlendMode,
    EntityType,
    LayerType,
    OpacityType,
    PropComponentType,
    Unit,
    FrameType,
    ContentAnchorType,
    EventFlag,
    ElementType,
} from '@phase-software/types'
import {
    notNull,
    Vector2,
    createPropTypes,
    PropChange,
    Change,
    MIN_SIZE_THRESHOLD
} from '@phase-software/data-utils'
import {
    LayerListKey,
    LayerListKeyList,
    LayerTypeMapLayerListKey,
    LayerListKeyMapLayerType
} from '../dist'
import { Setter } from './Setter'
import {
    CS_TO_BASE_ALLOWED_KEYS,
    DEFAULT_LAYER_VALUE,
    KEYS_TO_PROP,
} from './constant'
import {
    computeLayer,
    computedLayerWithDefaultValue,
    layerToComputedLayerData
} from './layer/helpers'
import { computeEffect } from './effect/helpers'
import {
    childListMap,
    generalKeySet,
} from './interaction/constants'


/** @typedef {import('./Setter').ChangesEvent} ChangesEvent */
/** @typedef {import('./DataStore').DataStore} DataStore */
/** @typedef {import('./layer/ComputedLayer').ComputedLayer} ComputedLayer */
/** @typedef {import('./layer/Layer').Layer} Layer */
/** @typedef {import('./component/PropertyComponent')} PropertyComponent */
/** @typedef {import('./component/PropertyComponent').Units} Units */
/** @typedef {import('./Element').Element} Element */
/** @typedef {import('./Element').ElementData} ElementData */


const csChildListMap = new Map(childListMap)

const BASE_PROPERTY_NAMES = [
    'translate',
    'dimensions',
    'rotation',
    'referencePoint',
    'contentAnchor',
    'opacity',
    'scale',
    'skew',
    'cornerRadius',
    'blurGaussian',
    'overflow',
    'font',
    'textAlignment',
    'textDecoration',
    'textDirection'
]


const PROP_KEYS_TO_CS_KEYS = {
    position: new Set(['x', 'y']),
    translate: new Set(['translateX', 'translateY']),
    dimensions: new Set(['width', 'height']),
    rotation: new Set(['rotation']),
    referencePoint: new Set(['referencePointX', 'referencePointY']),
    contentAnchor: new Set(['contentAnchorX', 'contentAnchorY', 'contentAnchorXUnit', 'contentAnchorYUnit', 'contentAnchorAutoAdd', 'contentAnchorType']),
    opacity: new Set(['opacity', 'blendMode']),
    scale: new Set(['scaleX', 'scaleY']),
    skew: new Set(['skewX', 'skewY']),
    cornerRadius: new Set(['cornerRadius', 'cornerSmoothing']),
    // scroll: ['scrollX', 'scrollY', 'scrollWidth', 'scrollHeight', 'paddingTop', 'paddingBottom', 'paddingLeft', 'paddingRight'],
    overflow: new Set(['overflowX', 'overflowY', 'scrollX', 'scrollY', 'scrollBarX', 'scrollBarY']),
    font: new Set(['fontFamily', 'fontStyle', 'fontSize', 'fontSizeUnit', 'characterSpacing', 'wordSpacing', 'lineSpacing', 'paragraphSpacing', 'paragraphIndent']),
    textAlignment: new Set(['horizontalAlignment', 'verticalAlignment']),
    textDecoration: new Set(['underline', 'strikethrough', 'cases', 'subSuper']),
    textDirection: new Set(['textDirection']),
    // textSpacing: ['characterSpacing', 'wordSpacing', 'lineSpacing', 'paragraphSpacing', 'paragraphIndent'],

}

// effects & extras
export const PROP_KEYS_TO_CS_KEYS_EXTRA = {
    blurGaussian: new Map([
        ['amount', 'blurGaussianAmount'],
        ['visible', 'blurGaussianVisible']
    ])
}

const DEFAULTS = {
    blurGaussian: false,
    blurGaussianAmount: 0,
    blurGaussianVisible: true
}

const PROP_TYPES = createPropTypes({
    position: { type: Vector2 },
    translate: { type: Vector2 },
    referencePoint: { type: Vector2 },
    contentAnchor: { type: Vector2 },
    size: { type: Vector2 },
    scale: { type: Vector2 },
    skew: { type: Vector2 },
})

const CS_VECTOR_PROPS = new Map([
    ['translate', ['translateX', 'translateY']],
    ['size', ['width', 'height']],
    ['scale', ['scaleX', 'scaleY']],
    ['skew', ['skewX', 'skewY']]
])

const SPECIAL_EVENT_FLAGS = new Set([EventFlag.FROM_MESH_CHANGE, EventFlag.FROM_CHILDREN_CHANGE, EventFlag.FROM_PARENT_CHANGE, EventFlag.FROM_CHANGE_CONTAINER_TYPE])

const getChangesValue = (changes, keys) => {
    return keys.reduce((acc, key) => {
        if (changes.has(key)) {
            acc[key] = changes.get(key).value
        }
        return acc
    }, {})
}

// KEEP here for future unit requirement
// -------------------------------------------
// /**
//  * Get value with unit change
//  * @param {Unit} unit
//  * @param {number} value
//  * @param {number} range
//  * @returns {number}
//  */
// const getValueWithUnitChange = (unit, value, range) => {
//     switch (unit) {
//         case Unit.PIXEL:
//             return value * range * 0.01
//         case Unit.PERCENT:
//             return (value / range) * 100
//     }
// }
// -------------------------------------------

const clearInvalidSetterProps = (data) => {
    delete data.id
    delete data.type
    delete data.layerType
    delete data.componentType
    delete data.appliedTo
}

/**
 * @fires 'CHANGES'
 * @fires 'STYLE_LIST_CHANGES'
 * @fires 'LAYER_LIST_CHANGES'
 */
export default class ComputedStyle extends Setter {
    /**
     * @param {DataStore} dataStore
     * @param {ComputedStyleData} data
     */
    constructor(dataStore, data) {
        super(dataStore, data)

        this.aliases = {
            x: { key: 'position', index: 0 },
            y: { key: 'position', index: 1 },
            translateX: { key: 'translate', index: 0 },
            translateY: { key: 'translate', index: 1 },
            referencePointX: { key: 'referencePoint', index: 0 },
            referencePointY: { key: 'referencePoint', index: 1 },
            contentAnchorX: { key: 'contentAnchor', index: 0 },
            contentAnchorY: { key: 'contentAnchor', index: 1 },
            width: { key: 'size', index: 0 },
            height: { key: 'size', index: 1 },
            scaleX: { key: 'scale', index: 0 },
            scaleY: { key: 'scale', index: 1 },
            skewX: { key: 'skew', index: 0 },
            skewY: { key: 'skew', index: 1 }
        }

        this._propChangesFn = new Map()
        this.propTypes = { ...this.propTypes, ...PROP_TYPES }

        this.elementId = data.element.get('id')
        this.base = data.element.get('base')

        this._applyBaseProps()
        this._bindComputedStyleChangeListener()

        this._setupLiftupParents()
    }

    /**
     * @param  {ComputedStyleData} data
     */
    load(data) {
        super.create()
        this.data.type = EntityType.COMPUTED_STYLE

        this._init(data.element)
    }

    /**
     * Reload ComputedStyle if switch mode
     * @param {boolean} force
     */
    reload(force = false) {
        // Workspace doesn't need to reload style
        if (this.element.get('type') === EntityType.WORKSPACE) {
            return
        }

        this._unbindBaseAndComponentChangeListeners()
        if (this.dataStore.isDesignMode || force) {
            this._applyBaseProps()
        }

        // Should have event handler for all base components changes.
        // And stop handle it whenever we needed
        this._bindBaseAndComponentChangeListeners()

        // REMOVE: It's workaround here. Because we already add non-base computed layer in design mode.
        //         So, it won't re-create non-base computed layer again in action mode.
        //         That means we won't fire LAYER_LIST_CHANGES, so render won't update canvas.
        this.forceFireLayerListChanges()
    }

    /**
     * Clones ComputedStyle and adds binds to a new clonedElement for all components
     * @param  {Element} clonedElement
     * @returns {ComputedStyle}
     */
    clone(clonedElement) {
        const obj = new this.constructor(this.dataStore, { element: clonedElement })
        obj.data.name = this.data.name
        obj.data.type = this.data.type

        obj._init(clonedElement)
        obj._applyBaseProps()
        obj._bindBaseAndComponentChangeListeners()

        return obj
    }

    _init(element) {
        // listener for Base events
        this._baseChangesLn = this._handleBaseChanges.bind(this)
        this.element = element
        this.base = this.element.get('base')

        // keys from Base
        this.data.position = new Vector2(0, 0)
        this.data.translate = new Vector2(0, 0)
        this.data.referencePoint = new Vector2(0, 0)
        this.data.contentAnchor = new Vector2(0, 0)
        this.data.contentAnchorXUnit = Unit.PIXEL
        this.data.contentAnchorYUnit = Unit.PIXEL
        this.data.contentAnchorAutoAdd = false
        this.data.contentAnchorType = ContentAnchorType.CONTENT
        this.data.size = new Vector2(0, 0)
        this.data.scale = new Vector2(1, 1)
        this.data.skew = new Vector2(0, 0)
        this.data.rotation = 0
        this.data.opacity = 1
        this.data.blendMode = BlendMode.PASS_THROUGH

        // auto orient
        this.data.orientRotation = 0

        // only for Paths, Screens and Containers
        this.data.cornerRadius = 0
        this.data.cornerSmoothing = 0
        this.data.meshModifier = null

        // container related keys
        this.data.overflowX = false
        this.data.overflowY = false
        this.data.scrollX = false
        this.data.scrollY = false
        this.data.scrollBarX = false
        this.data.scrollBarY = false

        // text related keys
        this.data.fontFamily = 'Roboto'
        this.data.fontStyle = 'Regular'
        this.data.fontSize = 14
        this.data.fontSizeUnit = 'px'
        this.data.minSize = new Vector2()
        this.data.maxSize = new Vector2()
        this.data.horizontalAlignment = 'LEFT'
        this.data.verticalAlignment = 'TOP'
        this.data.underline = false
        this.data.strikethrough = false
        this.data.cases = 'NORMAL'
        this.data.subSuper = 'NORMAL'
        this.data.textDirection = 'LTR'
        this.data.characterSpacing = 0
        this.data.wordSpacing = 0
        this.data.lineSpacing = 120
        this.data.paragraphSpacing = 0
        this.data.paragraphIndent = 0

        // keys from blur
        this.data.blurGaussian = false
        this.data.blurGaussianAmount = 0
        this.data.blurGaussianVisible = true

        // ComputedLayers cache
        this._cachedComputedLayers = new Map()
        this._unusedComputedLayers = {
            [LayerType.FILL]: new Set(),
            [LayerType.STROKE]: new Set(),
            [LayerType.SHADOW]: new Set(),
            [LayerType.INNER_SHADOW]: new Set()
        }
        this._computedLayerList = {
            fills: [],
            strokes: [],
            shadows: [],
            innerShadows: []
        }

        this._cachedComputedEffects = new Map()
        this._computedEffectMap = new Map()

        this._parentReferencePoint = new Vector2(0, 0)
    }

    get(key, copy) {
        if (key === 'x' || key === 'y') {
            return this.fullPosition[key]
        }

        return super.get(key, copy)
    }

    gets(...keys) {
        const allKeys = new Set(keys)
        let getX = false
        let getY = false
        if (allKeys.has('x')) {
            allKeys.delete('x')
            getX = true
        }
        if (allKeys.has('y')) {
            allKeys.delete('y')
            getY = true
        }

        const result = super.gets(...allKeys.keys())
        if (getX) {
            result.x = this.get('x')
        }
        if (getY) {
            result.y = this.get('y')
        }

        return result
    }

    /**
     * @returns {Iterator<ComputedLayer>}
     */
    get fills() {
        return this._layerListIterator(LayerListKey.FILL)
    }

    /**
     * @returns {Iterator<ComputedLayer>}
     */
    get strokes() {
        return this._layerListIterator(LayerListKey.STROKE)
    }

    /**
     * @returns {Iterator<ComputedLayer>}
     */
    get shadows() {
        return this._layerListIterator(LayerListKey.SHADOW)
    }

    /**
     * @returns {Iterator<ComputedLayer>}
     */
    get innerShadows() {
        return this._layerListIterator(LayerListKey.INNER_SHADOW)
    }

    get effects() {
        return this._effectListIterator()
    }

    get fullPosition() {
        this._parentReferencePoint.copy([0, 0])
        const parent = this.dataStore.getParentOf(this.element)
        if (parent && parent.isComputedGroup) {
            this._parentReferencePoint.copy(parent.get('referencePoint'))
        }

        // 0 size offset
        const { width, height } = this.data.size
        const offsetW = (width < MIN_SIZE_THRESHOLD && width !== 0) ? width * 0.5 : 0
        const offsetH = (height < MIN_SIZE_THRESHOLD && height !== 0) ? height * 0.5 : 0

        return {
            x: offsetW + this.data.translate.x - this.data.referencePoint.x - this.data.contentAnchor.x + this._parentReferencePoint.x,
            y: offsetH + this.data.translate.y - this.data.referencePoint.y - this.data.contentAnchor.y + this._parentReferencePoint.y
        }
    }

    get hasEffect() {
        return this._computedEffectMap.size !== 0
    }

    get hasLayers() {
        if (
            this._computedLayerList.innerShadows.length === 0 &&
            this._computedLayerList.shadows.length === 0 &&
            this._computedLayerList.strokes.length === 0 &&
            this._computedLayerList.fills.length === 0
        ) {
            return false
        }
        return true
    }

    /**
     * Check if the element in the HittestItem only has Shadow type layer
     * @returns {bool}
     */
    onlyHasShadowLayer() {
        if (this._computedLayerList.fills.length || this._computedLayerList.strokes.length) {
            return false
        }

        if (this._computedLayerList.shadows.length || this._computedLayerList.innerShadows.length) {
            return true
        }

        return false
    }

    /**
     * Get computedLayer by id
     * @param {string} id
     * @returns {ComputedLayer}
     */
    getComputedLayerById(id) {
        return this._cachedComputedLayers.get(id)
    }

    getComputedLayer(layerListKey, key) {
        return this._computedLayerList[layerListKey].find((cl) => cl.get('layerId') === key || cl.get('trackId') === key)
    }

    /**
     * Get computedEffect by computedEffectId
     * @param {string} computedEffectId
     * @returns {ComputedEffect}
     */
    getComputedEffectById(computedEffectId) {
        return this._computedEffectMap.get(computedEffectId)
    }

    getComputedEffectByComponentId(componentId) {
        return this._cachedComputedEffects.get(componentId)
    }

    addBaseComputedLayer(layerListKey, layerId, index = -1) {
        const currentComputedLayer = this._computedLayerList[layerListKey]
            .find((cl) => cl.get('layerId') === layerId)
        if (currentComputedLayer) {
            return currentComputedLayer.get('id')
        }

        const idx = index > -1 ? index : this._computedLayerList[layerListKey].length
        const before = this._computedLayerList[layerListKey].map((cl) => cl.get('id'))

        const layer = this.dataStore.library.getLayer(layerId)
        if (!layer) {
            return null
        }

        const cl = this._mapToComputedLayer(layer)
        this._computedLayerList[layerListKey].splice(idx, 0, cl)

        // fire LAYER_LIST_CHANGES event
        const after = this._computedLayerList[layerListKey].map((cl) => cl.get('id'))
        const layerListChanges = new PropChange()
        layerListChanges.update(layerListKey, new Change({
            before,
            after,
            index: idx
        }))
        this.fire('LAYER_LIST_CHANGES', layerListChanges, { flags: 'ADD_NON_BASE_LAYER' })
        this.dataStore.updateTransaction(this, layerListChanges)
        return cl.get('id')
    }

    /**
     * Add non-base ComputedLayer with propertyTrackId
     * @param {string} layerListKey
     * @param {string} propertyTrackId
     * @param {number} index
     * @returns {string}
     */
    addNonBaseComputedLayer(layerListKey, propertyTrackId, index) {
        const currentComputedLayer = this._computedLayerList[layerListKey]
            .find((cl) => cl.get('trackId') === propertyTrackId)
        if (currentComputedLayer) {
            return currentComputedLayer.get('id')
        }

        // Always create a new non-base CL in Action mode
        const idx = index > -1 ? index : this._computedLayerList[layerListKey].length
        const before = this._computedLayerList[layerListKey].map((cl) => cl.get('id'))

        const layerType = LayerListKeyMapLayerType[layerListKey]
        const defaultValue = DEFAULT_LAYER_VALUE[layerType]
        const value = { ...defaultValue, elementId: this.elementId, trackId: propertyTrackId }

        let cl = this._getUnusedComputedLayer(layerType)
        if (cl) {
            this._updateComputedLayerWithDefaultData(cl, value)
        } else {
            cl = computedLayerWithDefaultValue(this.dataStore, layerType, value)
        }
        this._cachedComputedLayers.set(propertyTrackId, cl)
        this._computedLayerList[layerListKey].splice(idx, 0, cl)
        cl.bindLayerChanges()

        // fire LAYER_LIST_CHANGES event
        const after = this._computedLayerList[layerListKey].map((cl) => cl.get('id'))
        const layerListChanges = new PropChange()
        layerListChanges.update(layerListKey, new Change({
            before,
            after,
            index: idx
        }))
        this.fire('LAYER_LIST_CHANGES', layerListChanges, { flags: 'ADD_NON_BASE_LAYER' })
        this.dataStore.updateTransaction(this, layerListChanges)
        return cl.get('id')
    }

    /**
     * Delete a non-base ComputedLayer with propertyTrackId
     * @param {string} layerListKey
     * @param {string} propertyTrackId
     */
    deleteNonBaseComputedLayer(layerListKey, propertyTrackId) {
        const before = this._computedLayerList[layerListKey].map((cl) => cl.get('id'))

        // Only remove non-base CL here
        const computedLayer = this._cachedComputedLayers.get(propertyTrackId)

        // skip if the computedLayer is not exist
        // this only happened when sync the changes from remote
        if (!computedLayer || computedLayer.layerId) {
            return
        }
        const clIdx = this._computedLayerList[layerListKey].findIndex((cl) => cl.get('trackId') === propertyTrackId)
        this._removeCachedComputedLayer(propertyTrackId, layerListKey)

        // fire LAYER_LIST_CHANGES event
        const after = this._computedLayerList[layerListKey].map((cl) => cl.get('id'))
        const layerListChanges = new PropChange()
        layerListChanges.update(layerListKey, new Change({
            before,
            after,
            index: clIdx
        }))
        this.fire('LAYER_LIST_CHANGES', layerListChanges)
        this.dataStore.updateTransaction(this, layerListChanges)
    }

    /**
     * Swap non-base ComputedLayers
     * @param {LayerType} layerType
     * @param {number} fromIndex
     * @param {number} toIndex
     */
    swapNonBaseComputedLayers(layerType, fromIndex, toIndex) {
        const layerListKey = LayerTypeMapLayerListKey[layerType]
        const before = this._computedLayerList[layerListKey].map((cl) => cl.get('id'))
        const fromCL = this._computedLayerList[layerListKey][fromIndex]
        const toCL = this._computedLayerList[layerListKey][toIndex]

        // Only swap non-base CLs here
        if (fromCL.layerId || toCL.layerId) {
            return
        }

        this._swapComputedLayers(layerType, fromIndex, toIndex)

        // fire LAYER_LIST_CHANGES event
        const after = this._computedLayerList[layerListKey].map((cl) => cl.get('id'))
        const layerListChanges = new PropChange()
        layerListChanges.update(layerListKey, new Change({
            before,
            after,
            fromIndex,
            toIndex
        }))
        this.fire('LAYER_LIST_CHANGES', layerListChanges)
        this.dataStore.updateTransaction(this, layerListChanges)
    }

    _getPropValueFrom(data, key, alias) {
        if (data && data[key] !== undefined) {
            return data[key]
        }
        if (data[alias] !== undefined && data[alias][key] !== undefined) {
            return data[alias][key]
        }

        if (alias) {
            return this.data[alias][key]
        }

        return this.data[key]
    }

    /**
     * Retrieve the pivot offset named for aligning the transform within the renderer.
     * @param {object} data
     * @returns {number[]} [originX, originY]
     */
    _getPivotOffset(data) {

        const referencePoint = this._getPropValueFrom(data, 'referencePoint')
        const contentAnchor = this._getPropValueFrom(data, 'contentAnchor')

        return new Vector2(referencePoint.x + contentAnchor.x, referencePoint.y + contentAnchor.y)
    }

    /**
     * Update position by translate
     * @param {object} out
     * @param {object} changes
     * @param {object} overrides
     */
    _updatePositionByTranslate(out, changes, overrides) {
        const data = { ...changes, ...overrides }
        const origin = this._getPivotOffset(data)
        out.x = data && data.translateX !== undefined
            ? data.translateX - origin[0]
            : this.data.translate[0] - origin[0]
        out.y = data && data.translateY !== undefined
            ? data.translateY - origin[1]
            : this.data.translate[1] - origin[1]
    }

    forceFireLayerListChanges() {
        const _layerListChanges = new PropChange()
        for (const layerListKey of LayerListKeyList) {
            const data = this._computedLayerList[layerListKey].map((cl) => cl.get('id'))
            const change = new Change({
                before: data,
                after: data
            })
            _layerListChanges.update(layerListKey, change)
        }

        this.fire('LAYER_LIST_CHANGES', _layerListChanges, { undoable: false, flags: this.data.id })
    }

    /**
     * Applies a Base on top of what's already in the ComputedStyle
     * @private
     */
    _applyBaseProps() {
        this._applyPathBaseProp()
        this._applyNonRepeatableBaseProps()
        this._applyRepeatableBaseProps()
        if (!this.element.isScreen) {
            this._applyEffectBaseProp()
        }
    }

    /**
     * Applies non-repeatable base properties to the ComputedStyle
     * @private
     * @fires 'CHANGES' if has non-repeatable properties changes
     */
    _applyNonRepeatableBaseProps() {
        const _changes = {
            orientRotation: 0
        }

        // apply singular properties (if not inherited)
        for (const propName of BASE_PROPERTY_NAMES) {
            const propId = this.base[propName]
            const prop = this.dataStore.library.getProperty(propId)
            if (!prop) {
                continue
            }
            this._assemblePropChanges(prop, propName, _changes)
            this._assembleExtraPropChanges(prop, propName, _changes)
        }

        this._processPropChanges(_changes)
        // apply changes (if this call is not part of selectStyle() call)
        if (Object.keys(_changes).length) {
            //   set `flags` to counter, to prevent fired CHANGES event to set props back to Base
            this.sets(_changes, { undoable: false, flags: this.data.id })
        }
    }

    /**
     * Applies layer base properties to the ComputedStyle
     * @private
     * @fires 'LAYER_LIST_CHANGES' if has layer list changes
     */
    _applyRepeatableBaseProps() {
        const _layerListChanges = new PropChange()

        // apply layers
        for (const layerListKey of LayerListKeyList) {
            // if base has any layers of this type
            //   mark list of this type as changed
            // Now only have one LayerComponent
            const layerComponentId = this.base[layerListKey][0]
            const layerComponent = this.dataStore.library.getComponent(layerComponentId)
            if (!layerComponent) {
                continue
            }

            const before = this._computedLayerList[layerListKey].map(cl => cl.get('id'))

            // remove all previous CL (if any)
            this._removeAllComputedLayers(layerListKey)

            this._computedLayerList[layerListKey] = layerComponent.layers.map(layerId => {
                const layer = this.dataStore.library.getLayer(layerId)
                if (!layer) {
                    return null
                }

                return this._mapToComputedLayer(layer)
            }).filter(Boolean)

            this._generateNonBaseComputedLayers(layerListKey)

            const change = new Change({
                before,
                after: this._computedLayerList[layerListKey].map((cl) => cl.get('id'))
            })
            _layerListChanges.update(layerListKey, change)
        }

        // fire layer changes event (if this call is not part of selectStyle() call)
        if (!_layerListChanges.isEmpty()) {
            this.fire('LAYER_LIST_CHANGES', _layerListChanges, { undoable: false, flags: this.data.id })
            this.dataStore.updateTransaction(this, _layerListChanges)
        }
    }

    _generateNonBaseComputedLayers(layerListKey) {
        if (!this.dataStore.interaction) {
            return
        }

        const propertyTrackMap = this.dataStore.interaction.getPropertyTrackMapByElementId(this.elementId)
        const layerComponentTrackId = propertyTrackMap && propertyTrackMap.get(layerListKey)
        if (layerComponentTrackId) {
            const layerComponentTrack = this.dataStore.interaction.getPropertyTrack(layerComponentTrackId)
            const computedLayerIds = new Set(this._computedLayerList[layerListKey].map(cl => cl.get('layerId')))
            layerComponentTrack.children.forEach((layerTrackId) => {
                if (!computedLayerIds.has(layerTrackId)) {
                    this.addNonBaseComputedLayer(layerListKey, layerTrackId)
                }
            })
        }
    }

    /**
     * Applies effect base properties to the ComputedStyle
     * @private
     * @fires 'EFFECT_LIST_CHANGES' if has effect list changes
     */
    _applyEffectBaseProp() {
        const _effectListChanges = new PropChange()
        const effectComponent = this.dataStore.library.getComponent(this.base.effects[0])

        // FIXME: remove this after found the root cause of EC not found
        if (!effectComponent) {
            console.error(`EffectComponent not found: ${this.base.effects[0]}`)
            return
        }
        const originalEffects = []
        const newEffects = []
        this._computedEffectMap.forEach(ce => { originalEffects.push(ce.get('id')) })
        this._computedEffectMap = effectComponent.effects.reduce((acc, effectId) => {
            const effect = this.dataStore.library.getEffect(effectId)
            if (!effect) {
                return acc
            }
            const ce = this._mapToComputedEffect(effect)
            acc.set(ce.get('id'), ce)
            return acc
        }, new Map())
        this._computedEffectMap.forEach(ce => { newEffects.push(ce.get('id')) })
        const change = new Change({
            before: originalEffects,
            after: newEffects
        })
        _effectListChanges.update('effect', change)

        this.fire('EFFECT_LIST_CHANGES', _effectListChanges, { undoable: false, flags: this.data.id })
        this.dataStore.updateTransaction(this, _effectListChanges)
    }

    _applyPathBaseProp() {
        const pathMorphingBaseData = this.element.basePath
        if (pathMorphingBaseData) {
            const pointChanges = [...pathMorphingBaseData.values()].map((v) => {
                const change = { id: v.id }
                if (v.pos && v.pos[0] !== undefined) {
                    change.x = v.pos[0]
                }
                if (v.pos && v.pos[1] !== undefined) {
                    change.y = v.pos[1]
                }
                if (v.mirror !== undefined) {
                    change.mirror = v.mirror
                }
                return change
            })
            // The size is reset to the base in the _applyNonRepeatableBaseProps method; therefore, size resetting is not needed here.
            this.element.updateVerticesPosition(pointChanges, false)
        }
    }

    /**
     * Check if two ComputedLayers are both base ComputedLayers or both non-base ComputedLayers
     * @private
     * @param {ComputedLayer} cl1
     * @param {ComputedLayer} cl2
     * @returns {bool}
     */
    _isComputedLayerSameType(cl1, cl2) {
        const cl1LayerId = cl1.get('layerId')
        const cl2LayerId = cl2.get('layerId')
        if ((cl1LayerId && cl2LayerId) || (!cl1LayerId && !cl2LayerId)) {
            return true
        }

        return false
    }

    /**
     * @private
     * @param {LayerType} layerType
     * @returns {ComputedLayer}
     */
    _getUnusedComputedLayer(layerType) {
        let cl = null
        if (this._unusedComputedLayers[layerType].size) {
            cl = [...this._unusedComputedLayers[layerType].values()][0]
            this._unusedComputedLayers[layerType].delete(cl)
        }
        return cl
    }

    /**
     * Listen to base and components event change
     * @private
     */
    _bindBaseAndComponentChangeListeners() {
        this.base.on('BASE_CHANGES', this._baseChangesLn)

        // Listen on PropertyComponent changes
        for (const propName of BASE_PROPERTY_NAMES) {
            const propId = this.base[propName]
            if (!propId) {
                continue
            }
            const prop = this.dataStore.library.getProperty(propId)
            const handler = this._handlePropertyComponentChange.bind(this, propName)
            this._propChangesFn.set(propName, handler)
            if (!prop) {
                continue
            }
            prop.on('CHANGES', handler)
        }

        // Listen on LayerComponent changes
        for (const layerListKey of LayerListKeyList) {
            const layerComponentId = this.base[layerListKey][0]
            const layerComponent = this.dataStore.library.getComponent(layerComponentId)
            const handler = this._handleLayerComponentChange.bind(this, layerListKey)
            this._propChangesFn.set(layerListKey, handler)
            if (!layerComponent) {
                continue
            }
            layerComponent.on('CHANGES', handler)

            // bind all computed layers
            for (const cl of this._computedLayerList[layerListKey]) {
                cl.bindLayerChanges()
            }
        }

        const effectComponent = this.dataStore.library.getComponent(this.base.effects[0])
        const handler = this._handleEffectComponentChange.bind(this)
        if (effectComponent) {
            effectComponent.on('CHANGES', handler)
            this._propChangesFn.set('effect', handler)

            // bind all computed effects
            for (const [, ce] of this._computedEffectMap) {
                ce.bindEffectChanges()
            }
        }
    }

    // TODO: this whole thing desperately needs Observable

    /**
     * Stop listening to base and components event change
     * @private
     */
    _unbindBaseAndComponentChangeListeners() {
        this.base.off('BASE_CHANGES', this._baseChangesLn)

        // unbind PropertyComponent listeners
        for (const propName of BASE_PROPERTY_NAMES) {
            const propId = this.base[propName]
            if (!propId) {
                continue
            }
            const prop = this.dataStore.library.getProperty(propId)
            const handler = this._propChangesFn.get(propName)
            if (!handler || !prop) {
                continue
            }
            prop.off('CHANGES', handler)
        }

        // Listen on LayerComponent changes
        for (const layerListKey of LayerListKeyList) {
            const layerComponentId = this.base[layerListKey][0]
            const layerComponent = this.dataStore.library.getComponent(layerComponentId)
            const handler = this._propChangesFn.get(layerListKey)
            if (!handler || !layerComponent) {
                continue
            }
            layerComponent.off('CHANGES', handler)

            // unbind all computed layers
            for (const cl of this._computedLayerList[layerListKey]) {
                cl.unbindLayerChanges()
            }
        }

        // gc all handlers
        this._propChangesFn = new Map()
    }

    // TODO: add tests for all behaviours related handling Prop changes

    /**
     * Handle PropertyComponent changes and map changes to ComputedStyle
     * @param {string} propName
     * @param {PropChange} changes
     * @private
     */
    _handlePropertyComponentChange(propName, changes) {
        if (!this.dataStore.isDesignMode) {
            return
        }

        const _changes = {}
        const allowedKeys = PROP_KEYS_TO_CS_KEYS[propName] || PROP_KEYS_TO_CS_KEYS_EXTRA[propName]
        const needTranslation = allowedKeys instanceof Map
        for (const [key, change] of changes) {
            if (!allowedKeys.has(key)) {
                continue
            }
            const csKey = needTranslation ? allowedKeys.get(key) : key
            if (notNull(change.after)) {
                _changes[csKey] = change.after
            }
        }
        this._processPropChanges(_changes)

        if (propName === 'translate') {
            const dataChange = {}
            if (changes.get('translateX')) {
                dataChange.translateX = changes.get('translateX').after
            }
            if (changes.get('translateY')) {
                dataChange.translateY = changes.get('translateY').after
            }
            this._updatePositionByTranslate(_changes, dataChange)
        }

        // should not be undoable because Undo for these changes is handled by Library
        //  should provide flags to prevent Editor to set this back to Library again
        this.sets(_changes, { undoable: false, flags: this.data.id })
    }

    /**
     * Handle LayerComponent changes and map changes to ComputedStyle
     * @param {string} layerListKey
     * @param {PropChanges} layerComponentChanges
     * @private
     */
    _handleLayerComponentChange(layerListKey, layerComponentChanges) {
        const _layerListChanges = new PropChange()
        for (const [, change] of layerComponentChanges) {
            const layerListChange = this._handleLayerListChangeInLayerComponent(layerListKey, change)
            _layerListChanges.update(layerListKey, layerListChange)
        }

        // fire layer changes event
        if (!_layerListChanges.isEmpty()) {
            this.fire('LAYER_LIST_CHANGES', _layerListChanges)
            this.dataStore.updateTransaction(this, _layerListChanges)
        }
    }

    /**
     * Handle EffectComponent changes and map changes to ComputedStyle
     * @private
     * @param {PropChanges} effectComponentChanges
     */
    _handleEffectComponentChange(effectComponentChanges) {
        const _effectListChanges = new PropChange()
        const effectChanges = effectComponentChanges.get('effects')
        const effectListChange = this._handleEffectListChangeInEffectComponent(effectChanges)
        _effectListChanges.update('effect', effectListChange)

        // fire effect changes event
        if (!_effectListChanges.isEmpty()) {
            this.fire('EFFECT_LIST_CHANGES', _effectListChanges)
            this.dataStore.updateTransaction(this, _effectListChanges)
        }
    }

    _bindComputedStyleChangeListener() {
        this.on('CHANGES', (changes, options) => {
            if (!options.flags && (changes.has('x') || changes.has('y') || changes.has('position'))) {
                console.warn('Should not direct set position to computedStyle')
            }

            // propagate changes to Base/IM only when:
            // - no flags set
            // - in EDITING state
            // - not during undo/redo(should be handled by changes in UndoGroup)
            const { inUndo, inRedo } = this.dataStore.get('undo')
            this._updateDerivedData(changes, options)
            if (
                !options.undoable ||
                options.flags && !SPECIAL_EVENT_FLAGS.has(options.flags) ||
                this.dataStore.get('state') !== 'EDITING' ||
                (inUndo || inRedo)
            ) {
                return
            }

            // Check if need to update values and kfs when change prop unit
            const actionMode = this.dataStore.isActionMode
            // KEEP here for future unit requirement
            // -------------------------------------------
            // let fireLibraryLater = false
            // if (actionMode) {
            //     ALWAYS_UPDATE_WITH_UNIT_CHANGE.forEach((key) => {
            //         if (changes.has(key)) {
            //             updateToBase.set(key, changes.get(key))
            //             this._updatePropsWithUnitChange(updateToBase, updateToBase, key)
            //         }
            //     })
            //
            //     if (updateToBase.size) {
            //         fireLibraryLater = true
            //         this._updateBaseProp('origin', updateToBase, false)
            //         updateToBase.clear()
            //     }
            // } else {
            //     this.dataStore.interaction.updateKeyFrameListWithUnitChange(this.elementId, changes)
            // }
            // -------------------------------------------
            if (actionMode) {
                // TODO: Computed Group can't have size kf
                /** @todo Move them constant if the props have expanded */
                if (
                    this.element.isScreen ||
                    changes.has('overflowX') ||
                    changes.has('overflowY')
                ) {
                    this._updateBasePropChanges(changes)
                } else if (options.interaction) {
                    this._updateIMGeneralProps(changes, options.force)
                }
            } else {
                // TODO: We can optimize this: go through all keys just once in O(n+k) instead of O(n^2)
                this._updateBasePropChanges(changes)

            }
            // Update mesh because the above update wont pass the Element setBaseProp
            this._updateMeshVertexByBaseSizeChanges(changes, options)
        })
    }

    _updateMeshVertexByBaseSizeChanges(changes, options) {
        if (!this.element.canMorph) {
            return
        }

        if (options.flags && (options.flags !== EventFlag.FROM_PARENT_CHANGE)) {
            return
        }

        if (!(changes.has('size') || changes.has('width') || changes.has('height'))) {
            return
        }

        const geometry = this.element.get('geometry')
        const mesh = geometry.get('mesh')

        let ratioX = 1
        let ratioY = 1
        if (changes.has('size')) {
            const oldSize = changes.get('size').original
            const newSize = changes.get('size').value
            ratioX = newSize[0] / (Math.abs(oldSize[0]) < Number.EPSILON ? 1 : oldSize[0])
            ratioY = newSize[1] / (Math.abs(oldSize[1]) < Number.EPSILON ? 1 : oldSize[1])
        } else if (changes.has('width')) {
            const oldWidth = changes.get('width').original
            const newWidth = changes.get('width').value
            ratioX = newWidth / oldWidth < Number.EPSILON ? 1 : oldWidth
        } else if (changes.has('height')) {
            const oldHeight = changes.get('height').original
            const newHeight = changes.get('height').value
            ratioY = newHeight / oldHeight < Number.EPSILON ? 1 : oldHeight
        }
        for (const [id, cell] of mesh.cellTable) {
            if (cell.type === 'Vertex') {
                mesh.changes.update(id, 'pos', new Change({
                    before: new Vector2(cell.pos[0], cell.pos[1]),
                    after: new Vector2(cell.pos[0] * ratioX, cell.pos[1] * ratioY)
                }))
            }
        }
        mesh.applyChanges(mesh.changes)
        mesh.fire()
    }

    /**
     * Update all derived data, which should not send a event and it can not be assigned directly
     * @param {ChangesEvent} changes  CHANGES event object
     * @param {object} options
     */
    _updateDerivedData(changes, options) {
        const hasReferencePointChange = changes.has('referencePointX') || changes.has('referencePointY') || changes.has('referencePoint')
        const hasContentAnchorChange = changes.has('contentAnchorX') || changes.has('contentAnchorY') || changes.has('contentAnchor')
        const hasTranslateChange = changes.has('translateX') || changes.has('translateY') || changes.has('translate')
        const hasPositionChange = changes.has('x') || changes.has('y') || changes.has('position')
        const hasSizeChange = changes.has('width') || changes.has('height') || changes.has('size')
        const { inUndo, inRedo } = this.dataStore.get('undo')
        if (options.flags || hasTranslateChange || hasContentAnchorChange) {
            const changeKeys = ['translateX', 'translateY']
            if (inUndo || inRedo) {
                changeKeys.push('width', 'height')
            }
            const changeData = getChangesValue(changes, changeKeys)
            this._updatePositionByTranslate(this.data.position, changeData)
            const parent = this.element.get('parent')
            if (parent && parent.isComputedGroup) {
                const offset = parent.get('referencePoint')
                this.data.position.x += offset[0]
                this.data.position.y += offset[1]
            }
        }

        if (options.flags && options.flags === EventFlag.FROM_MESH_CHANGE && hasReferencePointChange && hasSizeChange && !hasTranslateChange && !hasPositionChange) {
            const changeData = getChangesValue(changes, ['referencePointX'])
            const origin = this._getPivotOffset(changeData)
            this.data.position.x = this.data.translate.x - origin[0]
            this.data.position.y = this.data.translate.y - origin[1]
        }

        // Always derive the reference point when the size property of non-morphable elements has been changed
        // This can not be implement in polymorphism because the IM updating wont pass the set function of the element
        if (options.flags && options.flags === EventFlag.FROM_ANIMATION && hasSizeChange && this._isReferencePointAwaysCenter(this.element) && !this.element.canMorph) {
            const referencePoint = this._updateReferencePoint(changes, this.data.referencePoint)
            // Needs to notify transform matrix
            this.set('referencePoint', referencePoint, { undoable: false, flags: 1 })
            // Save it in library
            changes.set('referencePointX', { original: this.data.referencePoint.x, value: referencePoint.x })
            changes.set('referencePointY', { original: this.data.referencePoint.y, value: referencePoint.y })
        }
    }

    _isReferencePointAwaysCenter(element) {
        const elementType = element.get('elementType')
        return elementType === ElementType.PATH ||
            (elementType === ElementType.CONTAINER &&
                !element.isNormalGroup &&
                !element.isMaskGroup() &&
                !element.isBooleanType())
    }

    _updateReferencePoint(changes, existingReferencePoint) {
        const referencePoint = new Vector2()
        if (changes.has('size')) {
            const [width, height] = changes.get('size').value
            referencePoint.x = width * 0.5
            referencePoint.y = height * 0.5
        } else if (changes.has('width')) {
            const width = changes.get('width').value
            referencePoint.set(width * 0.5, existingReferencePoint[1])
        } else {
            const height = changes.get('height').value
            referencePoint.set(existingReferencePoint[0], height * 0.5)
        }
        return referencePoint
    }

    _updateBasePropChanges(changes) {
        for (const key of changes.keys()) {
            const propName = KEYS_TO_PROP[key]
            if (!propName) {
                continue
            }

            this._updateBaseProp(propName, changes)
        }
    }

    // KEEP here for future unit requirement
    // -------------------------------------------
    // /**
    //  * Update properties with unit change
    //  * @param {Map} out
    //  * @param {Map} data
    //  * @param {string} key
    //  */
    // _updatePropsWithUnitChange(out, data, key) {
    //     switch (key) {
    //         case 'originXUnit': {
    //             const width = this.dataStore.library.getComponent(this.base.dimensions).width
    //             const oldOriginX = this.dataStore.library.getComponent(this.base.origin).originX
    //             const newOriginX = getValueWithUnitChange(data.get(key).value, oldOriginX, width)
    //             out.set('originX', { value: newOriginX, original: oldOriginX })
    //             break
    //         }
    //         case 'originYUnit': {
    //             const height = this.dataStore.library.getComponent(this.base.dimensions).height
    //             const oldOriginY = this.dataStore.library.getComponent(this.base.origin).originY
    //             const newOriginY = getValueWithUnitChange(data.get(key).value, oldOriginY, height)
    //             out.set('originY', { value: newOriginY, original: oldOriginY })
    //             break
    //         }
    //     }
    // }
    // -------------------------------------------

    /**
     * @private
     * @param {string} key
     * @param {object} change
     * @param {object} setObj
     * @returns {bool}   true if handled; false otherwise
     */
    _vectorPropsToComponentKeys(key, change, setObj) {
        const mapping = CS_VECTOR_PROPS.get(key)
        if (!mapping) {
            return false
        }
        if (change.value[0] !== change.original[0]) {
            setObj[mapping[0]] = change.value[0]
        }
        if (change.value[1] !== change.original[1]) {
            setObj[mapping[1]] = change.value[1]
        }
        return true
    }

    /**
     * set changes back to base's property in library
     * @private
     * @param {string} propName
     * @param {ChangesEvent} changes  CHANGES event object
     * @param {bool} fire
     */
    _updateBaseProp(propName, changes, fire = true) {
        const element = this.element
        const setObj = {}

        for (const [key, change] of changes) {
            // TODO: Implement for all kinds of blurs
            if (key === 'blurGaussian') {
                if (change.value) {
                    const blurId = this.dataStore.library.addProperty(PropComponentType.BLUR_GAUSSIAN)
                    element.base.setProperty('blurGaussian', blurId)
                } else {
                    const blurId = element.base.blurGaussian
                    this.dataStore.library.deleteProperty(blurId)
                    element.base.setProperty('blurGaussian', null)
                }
                setObj[key] = change.value
                continue
            }
            if (!CS_TO_BASE_ALLOWED_KEYS[propName].has(key)) {
                continue
            }
            if (this._vectorPropsToComponentKeys(key, change, setObj)) {
                continue
            }
            setObj[key] = change.value
        }

        // Set Base's property changes by Library
        this.dataStore.library.setProperty(element.base[propName], setObj, true, fire)
    }

    /**
     * Get InteractionManager changes from computedStyle changes
     * @private
     * @param {string} propKey
     * @param {any} value
     * @param {object[]} allChanges
     */
    _getIMPropChange(propKey, value, allChanges) {
        const elementId = this.element.get('id')
        if (propKey === 'translate') {
            const translateId = this.base.translate
            const translateComponent = this.dataStore.library.getComponent(translateId)
            const { translateX, translateY } = translateComponent
            const { x, y } = value
            allChanges.push({
                elementId,
                propKey: 'motionPath',
                value: {
                    pos: [x - translateX, y - translateY]
                },
                delta: true,
                frameType: FrameType.EXPLICIT
            })
        } else if (propKey === 'contentAnchor') {
            const baseContentAnchor = this.element.getBaseProp('contentAnchor')
            value.contentAnchorX = (value.contentAnchorX === undefined ? this.get('contentAnchorX') : value.contentAnchorX) - baseContentAnchor.contentAnchorX
            value.contentAnchorY = (value.contentAnchorY === undefined ? this.get('contentAnchorY') : value.contentAnchorY) - baseContentAnchor.contentAnchorY
            allChanges.push({
                elementId,
                propKey: 'contentAnchor',
                value,
                delta: true,
                frameType: FrameType.EXPLICIT
            })
        } else {
            allChanges.push({
                elementId,
                propKey,
                value,
                delta: false,
                frameType: FrameType.EXPLICIT
            })
        }
    }

    /**
     * set changes to IM general property
     * @private
     * @param {ChangesEvent} changes  CHANGES event object
     * @param {bool} [force=false]
     */
    _updateIMGeneralProps(changes, force = false) {
        const contentAnchorChange = {}
        const allChanges = []
        const hasPathSizeChanged = new Map()

        for (const [key, change] of changes) {
            // Computed Group can not have siz ekf
            if (
                this.element.isContainer &&
                this.element.isComputedGroup
            ) {
                if (key === 'size' || key === 'width' || key === 'height') {
                    continue
                }
            }

            // Path element can not have size kf
            if (this.element.canMorph) {
                if (key === 'size' || key === 'width' || key === 'height') {
                    hasPathSizeChanged.set(key, change)
                    continue
                }
            }

            if (key === 'contentAnchorX') {
                contentAnchorChange.contentAnchorX = change.value
                continue
            }

            if (key === 'contentAnchorY') {
                contentAnchorChange.contentAnchorY = change.value
                continue
            }

            const propName = KEYS_TO_PROP[key]
            if (!generalKeySet.has(propName)) {
                continue
            }

            if (csChildListMap.has(propName)) {
                csChildListMap.get(propName).forEach((name, index) => {
                    if (change.value.length) {
                        if (change.original[index] !== change.value[index] || force) {
                            this._getIMPropChange(name, change.value[index], allChanges)
                        }
                    } else if (changes.has(name)) {
                        this._getIMPropChange(name, change.value, allChanges)
                    }
                })
            } else {
                this._getIMPropChange(propName, change.value, allChanges)
            }
        }

        // Collect all origin change in one change
        if (Object.keys(contentAnchorChange).length) {
            this._getIMPropChange('contentAnchor', contentAnchorChange, allChanges)
        }
        // Collect all changes and set it to IM together
        this.dataStore.interaction.setProperties(allChanges)
    }

    get _node() {
        return this.liftupParent
    }

    /**
     * Map layer to ComputedLayer
     * @param {Layer} layer
     * @param {bool} [updateCached=true]
     * @returns {ComputedLayer}
     */
    _mapToComputedLayer(layer, updateCached = true) {
        let cl = this._cachedComputedLayers.get(layer.id)
        if (cl) {
            if (updateCached) {
                this._updateComputedLayer(cl, layer)
            }
        } else {
            const layerType = layer.layerType
            cl = this._getUnusedComputedLayer(layerType)
            if (cl) {
                this._updateComputedLayer(cl, layer)
            } else {
                cl = computeLayer(this.dataStore, layer, this.elementId)
            }
            this._cachedComputedLayers.set(layer.id, cl)
        }
        cl.bindLayerChanges()
        return cl
    }

    /**
     * Update computedLayer with layer data
     * @param {ComputedLayer} computedLayer
     * @param {Layer} layer
     */
    _updateComputedLayer(computedLayer, layer) {
        const newData = layerToComputedLayerData(layer)
        clearInvalidSetterProps(newData)
        computedLayer.sets(newData, { undoable: false, flags: this.data.id })
    }

    /**
     * Update computedLayer with default data
     * @param {ComputedLayer} computedLayer
     * @param {object} defaultData
     */
    _updateComputedLayerWithDefaultData(computedLayer, defaultData) {
        computedLayer.sets(defaultData, { fire: false, undoable: false })
    }

    /**
     * Swap computedLayers
     * @param {LayerType} layerType
     * @param {number} fromIndex
     * @param {number} toIndex
     */
    _swapComputedLayers(layerType, fromIndex, toIndex) {
        const layerListKey = LayerTypeMapLayerListKey[layerType]
        const fromCL = this._computedLayerList[layerListKey][fromIndex]
        const toCL = this._computedLayerList[layerListKey][toIndex]
        if (!this._isComputedLayerSameType(fromCL, toCL)) {
            return
        }

        if (fromIndex > toIndex) {
            const cl = this._computedLayerList[layerListKey].splice(fromIndex, 1)[0]
            this._computedLayerList[layerListKey].splice(toIndex, 0, cl)
        } else {
            const cl = this._computedLayerList[layerListKey][fromIndex]
            this._computedLayerList[layerListKey].splice(toIndex, 0, cl)
            this._computedLayerList[layerListKey].splice(fromIndex, 1)
        }
    }

    /**
     * Remove computedLayer from cache
     * @private
     * @param {string} refId - base layer will use layerId, non-base layer will use trackId
     * @param {LayerListKey} layerListKey
     * @param {bool} removeFromList - set to false to skip removing from _computedLayerList
     * @returns {ComputedLayer}
     */
    _removeCachedComputedLayer(refId, layerListKey, removeFromList = true) {
        const computedLayer = this._cachedComputedLayers.get(refId)
        const layerType = computedLayer.get('layerType')
        if (removeFromList) {
            const clIdx = this._computedLayerList[layerListKey].findIndex((cl) => cl.get('layerId') === refId || cl.get('trackId') === refId)
            if (clIdx !== -1) {
                this._computedLayerList[layerListKey].splice(clIdx, 1)
            }
        }
        this._unusedComputedLayers[layerType].add(computedLayer)
        this._cachedComputedLayers.delete(refId)
        computedLayer.clearRef()
        computedLayer.unbindLayerChanges()
        return computedLayer
    }

    /**
     *
     * @param {string} layerListKey
     * @param {bool} [fire=false]
     */
    _removeAllComputedLayers(layerListKey, fire = false) {
        const clList = this._computedLayerList[layerListKey]
        const before = clList.map((cl) => cl.get('id'))
        const layerType = LayerListKeyMapLayerType[layerListKey]
        for (const cl of clList) {
            this._unusedComputedLayers[layerType].add(cl)
            this._cachedComputedLayers.delete(cl.get('layerId') || cl.get('trackId'))
            cl.clearRef()
            cl.unbindLayerChanges()
        }
        this._computedLayerList[layerListKey] = []

        if (fire) {
            // fire LAYER_LIST_CHANGES event
            const layerListChanges = new PropChange()
            layerListChanges.update(layerListKey, new Change({
                before,
                after: [],
                index: 0
            }))
            this.fire('LAYER_LIST_CHANGES', layerListChanges)
        }
    }

    /**
     * Map effect to ComputedEffect
     * @param {Effect} effect
     * @param {bool} [updateCached=true]
     * @returns {ComputedEffect}
     */
    _mapToComputedEffect(effect, updateCached = true) {
        let ce = this._cachedComputedEffects.get(effect.id)
        if (ce) {
            if (updateCached) {
                this._updateComputedEffect(ce, effect)
            }
        } else {
            ce = computeEffect(this.dataStore, effect, this.elementId)
            this._cachedComputedEffects.set(effect.id, ce)
        }

        ce.bindEffectChanges()
        return ce
    }

    /**
     * Update computedEffect with layer data
     * @param {ComputedEffect} computedEffect
     * @param {Effect} effect
     */
    _updateComputedEffect(computedEffect, effect) {
        const newData = effect.save()
        clearInvalidSetterProps(newData)
        computedEffect.sets(newData, { undoable: false, flags: this.data.id })
    }

    /**
     * Remove computedEffect
     * @private
     * @param {string} effectId
     */
    _removeComputedEffect(effectId) {
        for (const [computedEffectId, computedEffect] of this._computedEffectMap) {
            if (computedEffect.effectId === effectId) {
                computedEffect.unbindEffectChanges()
                this._computedEffectMap.delete(computedEffectId)
            }
        }
    }

    /**
     * Iterates over all applied (+ transition) ComputedLayers
     * @param {stinrg} layerListKey
     * @yields {ComputedLayer}
     */
    *_layerListIterator(layerListKey) {
        for (const computedLayer of this._computedLayerList[layerListKey]) {
            if (this.dataStore.isDesignMode && computedLayer.get('layerId')) {
                yield computedLayer
            } else if (this.dataStore.isActionMode) {
                yield computedLayer
            }
        }
    }

    /**
     * Iterates over all applied (+ transition) ComputedEffects
     * @yields {ComputedEffect}
     */
    *_effectListIterator() {
        for (const [, computedEffect] of this._computedEffectMap) {
            yield computedEffect
        }
    }

    /**
     * Sweeps through Style's singular properties and assembles changes object for sets()
     * @private
     * @param {PropertyComponent} prop
     * @param {string} propName
     * @param {object} changes
     * @returns {object} changes
     */
    _assemblePropChanges(prop, propName, changes = {}) {
        if (PROP_KEYS_TO_CS_KEYS[propName]) {
            for (const key of PROP_KEYS_TO_CS_KEYS[propName]) {
                // special case for opacity (temporary)
                if (key === 'opacity') {
                    changes[key] = prop.opacityType === OpacityType.SOLID
                        ? prop.opacity
                        : prop.opacityStops[0].opacity
                }
                // TODO: check if this condition is enough, so that can we can actually set `null` values (as opposed to `undefined`)
                else if (prop[key] !== undefined) {
                    changes[key] = prop[key]
                }
            }
        }
        return changes
    }

    /**
     * Sweeps through Base's singular extra properties and assembles changes object for sets()
     * @private
     * @param {PropertyComponent | undefined} prop   if undefined, correspoding CS props will be reset
     * @param {string} propName
     * @param {object} changes
     * @returns {object} changes
     */
    _assembleExtraPropChanges(prop, propName, changes = {}) {
        if (PROP_KEYS_TO_CS_KEYS_EXTRA[propName]) {
            if (prop) {
                // propName itself
                changes[propName] = true
                // its subprops
                for (const [propKey, csKey] of PROP_KEYS_TO_CS_KEYS_EXTRA[propName]) {
                    if (notNull(prop[propKey])) {
                        changes[csKey] = prop[propKey]
                    }
                }
            } else {
                // reset this extra's props if prop component is undefined
                // propName itself
                changes[propName] = DEFAULTS[propName]
                // and its subprops
                // eslint-disable-next-line no-unused-vars
                for (const [_, csKey] of PROP_KEYS_TO_CS_KEYS_EXTRA[propName]) {
                    changes[csKey] = DEFAULTS[csKey]
                }
            }
        }
        return changes
    }

    _processPropChanges(/* changes */) {
        // nothing to process here yet
    }

    /**
     * Handler for BASE_CHANGES event fired by each Style
     * Propagate changes Base -> ComputedStyle
     * @private
     * @param  {PropChange} changes
     */
    _handleBaseChanges(changes) {
        if (!this.dataStore.isDesignMode) {
            return
        }

        const _changes = {}
        const _layerListChanges = new PropChange()

        for (const [propName, change] of changes) {
            if (LayerListKeyList.includes(propName)) {
                // this is handling the changes in the lists of LayerComponents
                // skip handling multiple layer components case for now, won't happen in MVP

                // const layerListChange = this._handleLayerListChangeInLayerComponent(propName, change)
                // _layerListChanges.update(propName, layerListChange)
            } else {
                // Apply new prop data to computedStyle
                const newPropId = change.after
                if (!newPropId) {
                    // toggle extra prop if component is not present
                    this._assembleExtraPropChanges(undefined, propName, _changes)
                }
                const newProp = this.dataStore.library.getProperty(newPropId)
                if (newProp) {
                    this._assemblePropChanges(newProp, propName, _changes)
                    this._assembleExtraPropChanges(newProp, propName, _changes)
                }

                // Remove event listening
                const oldProp = this.dataStore.library.getProperty(change.before)
                if (oldProp) {
                    oldProp.off('CHANGES', this._propChangesFn[propName])
                }

                // Add event listening
                const handler = this._handlePropertyComponentChange.bind(this, propName)
                this._propChangesFn[propName] = handler
                if (newProp) {
                    newProp.on('CHANGES', handler)
                }
            }
        }


        // apply changes (if this call is not part of selectStyle() call)
        //   set `flags` to counter, to prevent fired CHANGES event to set props back to Base
        if (Object.keys(_changes).length) {
            this._processPropChanges(_changes)
            this.sets(_changes, { undoable: false, flags: 1 })
        }

        // fire layer changes event (if this call is not part of selectStyle() call)
        if (_layerListChanges.size) {
            this.fire('LAYER_LIST_CHANGES', _layerListChanges)
            this.dataStore.updateTransaction(this, _layerListChanges)
        }
    }

    /**
     * Handle layer list change in a LayerComponent
     * @param {string} layerListKey
     * @param {Change} change  changes in layer list (in a single LayerComponent)
     * @returns {Change}
     */
    _handleLayerListChangeInLayerComponent(layerListKey, change) {
        const before = this._computedLayerList[layerListKey].map((cl) => cl.get('id'))
        const afterSet = new Set(change.after)

        // Hide computedLayer if the Layer has been removed
        change.before.forEach((layerId) => {
            if (!afterSet.has(layerId)) {
                this._removeCachedComputedLayer(layerId, layerListKey, false)
            }
        })

        this._computedLayerList[layerListKey] = change.after.map((layerId) => {
            const layer = this.dataStore.library.getLayer(layerId)
            if (!layer) {
                return null
            }
            return this._mapToComputedLayer(layer, false)
        }).filter(Boolean)

        const layerListChange = new Change({
            before,
            after: this._computedLayerList[layerListKey].map((cl) => cl.get('id'))
        })

        return layerListChange
    }

    /**
     * Handle effect list change in a EffectComponent
     * @param {Change} change  changes in effect list (in a single EffectComponent)
     * @returns {Change}
     */
    _handleEffectListChangeInEffectComponent(change) {
        const before = []
        this._computedEffectMap.forEach(ce => { before.push(ce.get('id')) })
        const afterSet = new Set(change.after)

        // Remoce computedEffect if the Effect has been removed
        change.before.forEach((effectId) => {
            if (!afterSet.has(effectId)) {
                this._removeComputedEffect(effectId)
            }
        })

        const effectComponent = this.dataStore.library.getComponent(this.base.effects[0])

        this._computedEffectMap = effectComponent.effects.reduce((acc, effectId) => {
            const effect = this.dataStore.library.getEffect(effectId)
            if (!effect) {
                return acc
            }

            const ce = this._mapToComputedEffect(effect)
            acc.set(ce.get('id'), ce)
            return acc
        }, new Map())

        const after = []
        this._computedEffectMap.forEach(ce => { after.push(ce.get('id')) })
        const effectListChange = new Change({
            before,
            after
        })

        return effectListChange
    }
}

/**
 * @typedef {object} ComputedStyleData
 * @property {Element} element
 */
