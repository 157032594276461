const GRID = {
    scaleVisible: 9,
    size: 0.5,
    color: 0,
    alpha: 0.05
}

/** @typedef {import('../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('../overlay/Overlay').Pane} Pane */
/** @typedef {import('../Viewport').Viewport} Viewport */

/** @type {Pane} */
let _pane
/** @type {VisualServer} */
let _visualServer

/**
 * Initlize a pane for drawing grid
 * @param {VisualServer} visualServer The VisualServer the grid relys on
 * @param {number} index The index of the pane in the overlay
 */
export function init(visualServer, index) {
    _visualServer = visualServer
    _pane = _visualServer.overlay.createPane(index)
}

/**
 * Draw grid on screen space
 */
export function update() {
    _pane.clear()

    const viewport = _visualServer.viewport
    const scale = viewport.scale
    const width = viewport.width
    const height = viewport.height
    const invScale = 1 / viewport.scale
    const left = -viewport.x * invScale
    const top  = -viewport.y * invScale

    if ( scale >= GRID.scaleVisible - 1 ){

        // from 5-6x zoom, use alpha to fade in the grid (so it's less abrupt)
        const alpha = scale >= GRID.scaleVisible
            ? GRID.alpha
            : GRID.alpha - GRID.alpha * (GRID.scaleVisible - scale)
        _pane.lineStyle( GRID.size, GRID.color, alpha )
        const startX = ( Math.floor(left) - left ) * scale
        const startY = ( Math.floor(top)  - top  ) * scale
        for ( let x = startX; x < width; x += scale ) {
            _pane.drawLine( x, 0, x, height)
        }
        for ( let y = startY; y < height; y += scale ) {
            _pane.drawLine( 0, y, width, y)
        }
    }
}

export function clear() {
    _pane.clear()
}
