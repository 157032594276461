import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { uniqBy } from 'lodash'

import { Menu, Tag } from '..'
import { MetaDataProps, useUndoRedo } from '../../../hooks/useUndoRedo'
import { isUndoOrRedoKeyPressed } from '../../../utils/keyboard'
import ErrorTextComponent from '../ErrorTextComponent'
import { MenuRef } from '../Menu'
import { MenuListOptionProps, MenuOptionProps } from '../Menu/Menu.types.js'

export type TextAreaTagItemProps = {
  label: string
  value: string
  [key: string]: any
}

type TextAreaProps = {
  variant?: 'default' | 'flat'
  allowInputCommaAndSpace?: boolean
  allowTags?: boolean
  wrapperClassName?: string
  className?: string
  customTagValidator?: (tag: TextAreaTagItemProps) => boolean
  defaultTags?: TextAreaTagItemProps[]
  defaultValue?: string
  disabled?: boolean
  error?: string
  autoFocus?: boolean
  /**
   * If the function returns true, the option will be included in the filtered set; Otherwise, it will be excluded
   */
  filterOption?: (searchValue: string, option: MenuOptionProps) => boolean
  height?: number
  onChangeTags?: (tags: TextAreaTagItemProps[]) => void
  onChangeValue?: (value: string) => void
  onTagsValidation?: (allTagsValid: boolean) => void
  onTextAreaFocus?: () => void
  onClickOutside?: () => void
  handleCustomKeyDown?: (
    e: React.KeyboardEvent<HTMLTextAreaElement>,
    value: string,
    textAreaRef: any,
    isComposing: boolean,
    setInputValue: (value: string) => void
  ) => void
  handleClickBottomWrapper?: (textAreaRef: any) => void
  placeholder?: string
  rightComponent?: React.ReactNode
  bottomComponent?: React.ReactNode
  rows?: number
  searchMenuOptions?: MenuListOptionProps[]
  showSearch?: boolean
  size?: 's' | 'l'
  tagRegexp?: RegExp
  maxScrollHeight?: number
  minHeight?: number
  exposeClearInputMethod?: (func: any) => void
  dataTestId?: string
  clearUndoHistoryOnBlur?: boolean
}

const TextArea = ({
  variant = 'default',
  allowInputCommaAndSpace,
  allowTags,
  wrapperClassName = '',
  className = '',
  customTagValidator,
  defaultTags,
  defaultValue = '',
  disabled,
  error,
  autoFocus = false,
  filterOption,
  height,
  onChangeTags,
  onChangeValue,
  onTagsValidation,
  onTextAreaFocus,
  onClickOutside,
  handleCustomKeyDown,
  handleClickBottomWrapper,
  placeholder,
  rightComponent,
  bottomComponent = null,
  rows = 1,
  searchMenuOptions,
  showSearch,
  size = 'l',
  tagRegexp,
  maxScrollHeight,
  minHeight,
  exposeClearInputMethod,
  dataTestId,
  clearUndoHistoryOnBlur = true
}: TextAreaProps) => {
  const { undo, redo, updateUndoHistory, clearUndoHistory, commitChange, initiateDelete, finalizeDelete } =
    useUndoRedo()
  const [openSearchMenu, setOpenSearchMenu] = useState(false)
  const [isComposing, setIsComposing] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [tags, setTags] = useState<TextAreaTagItemProps[]>([])
  const [isTriggerAreaFocused, setIsTriggerAreaFocused] = useState(false)

  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLDivElement>(null)
  const valueRef = useRef<string>('')
  const menuRef = useRef<MenuRef>(null)
  const hasTags = tags.length > 0
  const isLargeSize = size === 'l'
  const focusTextArea = () => textAreaRef.current?.focus()

  const computedClassName = useMemo(() => {
    if (variant === 'flat') return ''
    if (disabled) return 'bg-light-overlay-5 opacity-40 cursor-not-allowed'
    if (error && isTriggerAreaFocused)
      return 'bg-light-overlay-5 highlight-border-rounded-md-error highlight-border-rounded-md-error-focus'
    if (error) return 'bg-light-overlay-5 highlight-border-rounded-md-error'
    if (isTriggerAreaFocused)
      return 'bg-light-overlay-5 highlight-border-rounded-md hover:bg-light-overlay-10 hover:outline-light-overlay-20-1-offset--1'
    return 'bg-light-overlay-5 hover:bg-light-overlay-10 hover:outline-light-overlay-20-1-offset--1'
  }, [disabled, error, variant, isTriggerAreaFocused])

  const contentClassName = useMemo(() => {
    if (isLargeSize) {
      return hasTags ? 'py-4 pl-4 pr-8' : 'p-8'
    }
    return hasTags ? 'py-6 pl-6 pr-8' : 'px-8 py-6'
  }, [isLargeSize, hasTags])

  const handleFocus = () => {
    onTextAreaFocus?.()
    setIsTriggerAreaFocused(true)
    valueRef.current = defaultValue
  }

  const updateTags = useCallback(
    (newTags: TextAreaTagItemProps[]) => {
      const uniqTags = uniqBy(newTags, 'value')
      setTags(uniqTags)
      onChangeTags?.(uniqTags)
    },
    [onChangeTags]
  )

  const formatTagWithValidation = useCallback(
    (tag: TextAreaTagItemProps) => ({
      ...tag,
      isValid: (!customTagValidator || customTagValidator(tag)) && (!tagRegexp || tagRegexp.test(tag.value))
    }),
    [customTagValidator, tagRegexp]
  )

  const generateTags = useCallback(
    (values: string[]) => {
      // when generate tag, need reset textArea style
      if (textAreaRef.current) {
        textAreaRef.current.style.flexBasis = ''
        textAreaRef.current.style.overflow = ''
        textAreaRef.current.wrap = ''
      }
      let newTags: TextAreaTagItemProps[]

      if (allowInputCommaAndSpace) {
        newTags = [...tags, ...values.map((value) => formatTagWithValidation({ label: value, value }))]
      } else {
        newTags = [
          ...tags,
          ...values.flatMap((value) =>
            value
              .split(/[\s,]/)
              .filter((v) => v)
              .map((v) => formatTagWithValidation({ label: v, value: v }))
          )
        ]
      }
      commitChange(inputValue, { tags: tags })
      updateTags(newTags)
      setInputValue('')
    },
    [allowInputCommaAndSpace, updateTags, tags, formatTagWithValidation, commitChange, inputValue]
  )

  const handleClose = useCallback(
    (index: number) => {
      const newTags = tags.filter((_, tagIndex) => tagIndex !== index)
      updateTags(newTags)
    },
    [tags, updateTags]
  )

  const handleUndoRedoOperation = useCallback(
    (lastValue: string) => {
      setInputValue(lastValue)
      onChangeValue?.(lastValue)
    },
    [onChangeValue]
  )

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (handleCustomKeyDown) handleCustomKeyDown(e, inputValue, textAreaRef, isComposing, setInputValue)
      const GENERATE_TAG_BY_KEYS = allowInputCommaAndSpace ? ['Enter'] : [' ', ',', 'Enter']
      const SELECT_ACCOUNT_BY_KEY = [' ', 'Enter']
      const TRIGGER_MENU_LIST_KEYDOWN_BY_KEY = ['Home', 'End', 'ArrowUp', 'ArrowDown']
      let activeIndex = menuRef.current?.getActiveIndex()
      if (e.key === 'Escape') {
        clearUndoHistory()
        setInputValue(valueRef.current)
        onChangeValue?.(valueRef.current)
        e.stopPropagation()
        textAreaRef?.current?.blur()
      }
      if (isUndoOrRedoKeyPressed(e) && !isComposing) {
        e.preventDefault()
        if (e.shiftKey) {
          redo(
            inputValue,
            (lastValue: string, metaData?: MetaDataProps) => {
              if (metaData?.tags) {
                updateTags(metaData?.tags as TextAreaTagItemProps[])
              }
              handleUndoRedoOperation(lastValue)
            },
            { tags: tags }
          )
        } else {
          undo(
            inputValue,
            (lastValue: string, metaData?: MetaDataProps) => {
              if (metaData?.tags) {
                updateTags(metaData?.tags as TextAreaTagItemProps[])
              }
              handleUndoRedoOperation(lastValue)
            },
            { tags: tags }
          )
        }
      }
      if (!activeIndex && activeIndex !== 0) activeIndex = -1
      if (allowTags && GENERATE_TAG_BY_KEYS.includes(e.key) && inputValue) {
        if (SELECT_ACCOUNT_BY_KEY.includes(e.key) && activeIndex >= 0) {
          menuRef.current && menuRef.current.selectActiveOption()
        } else {
          generateTags([inputValue])
        }
        e.stopPropagation()
        e.preventDefault()
      } else if (TRIGGER_MENU_LIST_KEYDOWN_BY_KEY.includes(e.key) && inputValue && activeIndex >= 0) {
        const newEvent = new KeyboardEvent(e.type, e.nativeEvent)
        menuRef.current && menuRef.current.onKeyDown(newEvent)
        e.stopPropagation()
        e.preventDefault()
      }
      if (e.key === 'Backspace') {
        if (allowTags && !inputValue) {
          // remove textarea flexBasis style
          if (textAreaRef.current?.style.flexBasis === '100%') {
            textAreaRef.current.style.flexBasis = ''
            return
          }
          const newTags = tags.slice(0, tags.length - 1)
          commitChange(inputValue, { tags: tags })
          updateTags(newTags)
        } else {
          if (!isComposing) initiateDelete(inputValue)
        }
      }
      if (allowTags && !inputValue && e.key === 'Enter' && textAreaRef.current) {
        e.stopPropagation()
        e.preventDefault()
        textAreaRef.current.style.flexBasis = '100%'
      }

      if (!allowTags && e.key === 'Enter' && (e.shiftKey || e.metaKey || e.ctrlKey || e.altKey)) {
        if (!handleCustomKeyDown) {
          e.preventDefault()
          setInputValue((prevValue) => prevValue + '\n')
        }
      }
    },
    [
      allowInputCommaAndSpace,
      allowTags,
      inputValue,
      generateTags,
      tags,
      updateTags,
      menuRef,
      handleCustomKeyDown,
      isComposing,
      commitChange,
      undo,
      redo,
      handleUndoRedoOperation,
      onChangeValue,
      initiateDelete,
      clearUndoHistory
    ]
  )

  const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Backspace' && !isComposing) {
      if (allowTags && !inputValue) {
      } else {
        finalizeDelete()
      }
    }
  }

  const handlePaste = useCallback(
    (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
      const pastedData = e.clipboardData.getData('text/plain')
      if (allowTags && pastedData.includes(',')) {
        const pastedValues = pastedData.split(',')
        generateTags(pastedValues)
        e.preventDefault()
      } else {
        commitChange(inputValue, { tags: tags })
      }
    },
    [allowTags, generateTags, commitChange, inputValue, tags]
  )

  const handleCut = () => {
    commitChange(inputValue, { tags: tags })
  }

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      let forceUpdate = false
      if (allowTags && !inputValue && tags.length > 0) forceUpdate = true
      if (!isComposing) updateUndoHistory(inputValue, forceUpdate)
      setInputValue(e.target.value)
      onChangeValue?.(e.target.value)
    },
    [onChangeValue, updateUndoHistory, inputValue, tags, allowTags, isComposing]
  )

  const handleBlur = useCallback(() => {
    setIsTriggerAreaFocused(false)
    if (allowTags && inputValue && !openSearchMenu) {
      generateTags([inputValue])
    }
    if (clearUndoHistoryOnBlur) clearUndoHistory()
  }, [allowTags, inputValue, generateTags, openSearchMenu, clearUndoHistory, clearUndoHistoryOnBlur])

  const handleSelect = useCallback(
    (selectedOption: MenuOptionProps) => {
      setInputValue('')
      updateTags([
        ...tags,
        formatTagWithValidation({
          ...selectedOption,
          label: String(selectedOption.name),
          value: String(selectedOption.value)
        })
      ])
    },
    [updateTags, tags, formatTagWithValidation]
  )

  const handleClickBottomComponent = (): void => {
    if (handleClickBottomWrapper) handleClickBottomWrapper(textAreaRef)
  }

  const handleCompositionStart = () => {
    commitChange(inputValue)
    setIsComposing(true)
  }

  const handleCompositionEnd = () => {
    setIsComposing(false)
  }

  useEffect(() => {
    setTags(defaultTags ?? [])
  }, [defaultTags])

  useEffect(() => {
    setInputValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    const anyTagInvalid = tags.some((tag) => !tag.isValid)
    onTagsValidation?.(!anyTagInvalid)
  }, [onTagsValidation, tags])

  useEffect(() => {
    if (!textAreaRef.current) {
      return
    }
    if (allowTags || height) {
      textAreaRef.current.style.height = ''
      return
    }

    textAreaRef.current.style.height = 'inherit'
    if (maxScrollHeight && maxScrollHeight < textAreaRef.current.scrollHeight) {
      textAreaRef.current.style.height = `${maxScrollHeight}px`
    } else if (minHeight && textAreaRef.current.scrollHeight < minHeight) {
      textAreaRef.current.style.height = `${minHeight}px`
    } else {
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
    }
  }, [inputValue, height, allowTags, maxScrollHeight, minHeight])

  useEffect(() => {
    if (height && contentRef.current && contentRef.current.scrollTop !== contentRef.current.scrollHeight) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight
    }
  }, [inputValue, height])

  useEffect(() => {
    // the maximum height of a tag is 24, if >24, it should be wrapped
    if (allowTags && textAreaRef.current && textAreaRef.current.scrollHeight > 24) {
      if (!textAreaRef.current.style.flexBasis) {
        textAreaRef.current.style.flexBasis = '100%'
      } else {
        textAreaRef.current.wrap = 'off'
        textAreaRef.current.style.overflow = 'hidden'
      }
    }
  }, [inputValue, allowTags])

  useEffect(() => {
    if (!onClickOutside) return
    const handleOutsideClick = (event: MouseEvent) => {
      if (triggerRef.current && !triggerRef.current.contains(event.target as Node)) {
        onClickOutside()
      }
    }
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [onClickOutside])

  useEffect(() => {
    const inputElement = textAreaRef.current
    const handleBeforeInput = (e: InputEvent) => {
      if (e.inputType === 'historyUndo' || e.inputType === 'historyRedo') {
        e.preventDefault()
      }
    }
    inputElement?.addEventListener('beforeinput', handleBeforeInput, true)
    return () => {
      inputElement?.removeEventListener('beforeinput', handleBeforeInput, true)
    }
  }, [])

  useEffect(() => {
    const clearInput = () => {
      setInputValue('')
      onChangeValue?.('')
    }
    if (exposeClearInputMethod) {
      exposeClearInputMethod(clearInput)
    }
  }, [exposeClearInputMethod, onChangeValue])

  return (
    <>
      <ErrorTextComponent showErrorTips={!!error} errorTips={error} containerClassName={`min-w-0 ${wrapperClassName} `}>
        <div ref={triggerRef} className={`flex rounded-md ${computedClassName} ${className}`}>
          <div
            ref={contentRef}
            className={`flex-1 flex flex-wrap gap-4 overflow-auto ${contentClassName}`}
            style={{
              height: height || 'auto'
            }}
            onClick={focusTextArea}
          >
            {tags.map((tag, index) => (
              <Tag
                hasError={!tag.isValid}
                key={tag.value}
                closable
                size={size}
                onClose={() => handleClose(index)}
                className={`max-w-full ${index === tags.length - 1 ? 'mr-[-4px]' : ''}`}
              >
                {tag.label}
              </Tag>
            ))}
            <textarea
              autoFocus={autoFocus}
              placeholder={hasTags ? undefined : placeholder}
              disabled={disabled}
              ref={textAreaRef}
              rows={rows}
              className={`first:ml-0 ml-4 ${
                hasTags ? `${isLargeSize ? 'py-4' : 'pb-6'}` : ''
              } min-w-[1px] resize-none bg-transparent flex-1 placeholder-light-overlay-60 text-12 text-white disabled:cursor-not-allowed`}
              value={inputValue}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              onKeyUp={handleKeyUp}
              onChange={handleChange}
              onPaste={handlePaste}
              onCut={handleCut}
              onFocus={handleFocus}
              onCompositionStart={handleCompositionStart}
              onCompositionEnd={handleCompositionEnd}
              data-test-id={dataTestId}
            />
          </div>
          {rightComponent && <div className="pr-8">{rightComponent}</div>}
          {bottomComponent && <div onClick={handleClickBottomComponent}>{bottomComponent}</div>}
        </div>
      </ErrorTextComponent>
      {showSearch && searchMenuOptions && (
        <Menu
          type="select"
          filterOption={filterOption}
          ref={menuRef}
          hideNoSearchResult
          onOpenChange={setOpenSearchMenu}
          onSelect={handleSelect}
          open={openSearchMenu}
          options={searchMenuOptions}
          searchValue={inputValue}
          selectable={false}
          showSearch={showSearch}
          trigger={triggerRef}
        />
      )}
    </>
  )
}

export default TextArea
