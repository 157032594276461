import { getPFn } from '@phase-software/data-utils'
import { Bezier } from 'bezier-js/src/bezier'
import { CMP_EPSILON, Num, Vector2 } from '../math'

/** @typedef {import('../math/Vector2').Vector2Like} Vector2Like */

/** @typedef {(curr: Part, next: Part, isFirstIter: boolean, isLastIter: boolean) => void} ForEachFn */

/** @enum {number} */
export const Command = {
    "M": 1,
    "L": 2,
    "Q": 3,
    "C": 4,
    "Z": 5
}

const cmdLen = {
    [Command.M]: 2,
    [Command.L]: 2,
    [Command.Q]: 4,
    [Command.C]: 6,
}

export class PathData {
    /**
     * @param {number[]} verticies
     * @param {Command[]} commands
     */
    constructor(verticies = [], commands = []) {
        /** @type {number[]} */
        this.vertices = verticies
        /** @type {Command[]} */
        this.commands = commands

        this.metadata = {}
        this.cornerRadiusOverrides = {}
        this.hasOpenOrNetworkSubaths = false
    }

    /**
     * @param {number} subpathStartIndex
     * @param {string} key
     * @param {any} value
     */
    attachMetadata(subpathStartIndex, key, value) {
        if (!this.metadata[subpathStartIndex]) {
            this.metadata[subpathStartIndex] = {}
        }
        this.metadata[subpathStartIndex][key] = value
    }

    *iter() {
        const boundaries = this.getSubpathBoundaries()

        for (let i = 0; i < boundaries.length - 1; i++) {
            const cmdS = boundaries[i][0]
            const cmdE = boundaries[i + 1][0]
            const verS = boundaries[i][1]
            const verE = boundaries[i + 1][1]
            yield new Subpath(this, cmdS, cmdE, verS, verE)
        }
    }

    /**
     * First number is an index in the commands array
     *
     * Second number is an index in the vertices array
     *
     * @returns {[number, number][]}
     */
    getSubpathBoundaries() {
        /** @type {[number, number][]} */
        const starts = []
        let j = 0
        for (let i = 0; i < this.commands.length; i++) {
            const command = this.commands[i]
            if (command === Command.M) {
                starts.push([i, j])
            }
            j += cmdLen[command]
        }
        starts.push([this.commands.length, this.vertices.length])
        return starts
    }

    reverseSubpaths() {
        const boundaries = this.getSubpathBoundaries()

        for (let i = 0; i < boundaries.length - 1; i++) {
            const start = boundaries[i][0]
            const end = boundaries[i + 1][0]
            for (let j = 1; j < Math.ceil((end - start) / 2); j++) {
                const tmp = this.commands[start + j]
                this.commands[start + j] = this.commands[end - j]
                this.commands[end - j] = tmp
            }
        }

        for (let i = 0; i < boundaries.length - 1; i++) {
            const start = boundaries[i][1]
            const end = boundaries[i + 1][1] - 1
            const len = end - start + 1
            for (let j = 0; j < Math.floor(len / 4); j++) {
                const _j = j * 2
                const x0 = start + _j
                const y0 = start + _j + 1
                const x1 = end - _j - 1
                const y1 = end - _j
                const tmpX = this.vertices[x0]
                const tmpY = this.vertices[y0]
                this.vertices[x0] = this.vertices[x1]
                this.vertices[y0] = this.vertices[y1]
                this.vertices[x1] = tmpX
                this.vertices[y1] = tmpY
            }
        }
    }

    /**
     * @param {number} startCmdI
     * @param {number} startVerI
     * @param {number} lastCmdI
     * @param {number} lastVerI
     */
    connectFirstAndLastSubpaths(startCmdI, startVerI, lastCmdI, lastVerI) {
        // pop last duplicated point
        this.vertices.pop()
        this.vertices.pop()

        const cmdRest = this.commands.slice(lastCmdI)
        const verRest = this.vertices.slice(lastVerI)

        // move all commands between startCmdI and lastCmdI by cmdRest.length - 1
        // (offset by one to the left so that when copying the commands in the next stage,
        // the first move command will be overwritten)
        for (let i = lastCmdI - 1; i >= startCmdI; i--) {
            this.commands[i + cmdRest.length - 1] = this.commands[i]
        }

        // move all vertices between startVerI and lastVerI by verRest.length
        for (let i = lastVerI - 1; i >= startVerI; i--) {
            this.vertices[i + verRest.length] = this.vertices[i]
        }

        // copy all commands from cmdRest starting at startCmdI
        for (let i = 0; i < cmdRest.length; i++) {
            this.commands[startCmdI + i] = cmdRest[i]
        }

        // copy all vertices from verRest starting at startVerI
        for (let i = 0; i < verRest.length; i++) {
            this.vertices[startVerI + i] = verRest[i]
        }

        // pop leftover command
        this.commands.pop()
    }

    clear() {
        this.commands.length = 0
        this.vertices.length = 0
    }

    get debugString(){
        let svg = ""
        svg += '<?xml version="1.0" encoding="UTF-8" standalone="no"?>\n'
        svg += '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">\n'
        svg += '<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">\n'
        let i = 0
        for (let c = 0; c < this.commands.length; ++c) {
            switch (this.commands[c]) {
                // M
                case 1: {
                    const x = this.vertices[i++]
                    const y = this.vertices[i++]
                    if (c!==0){
                        svg += '" stroke="black" style="fill:none"/>\n'
                    }
                    svg += `<path d="M${x} ${y} `
                } break
                // L
                case 2: {
                    const x = this.vertices[i++]
                    const y = this.vertices[i++]
                    svg += `L ${x} ${y} `
                } break
                // Q
                case 3: {
                    const cpx = this.vertices[i++]
                    const cpy = this.vertices[i++]
                    const x = this.vertices[i++]
                    const y = this.vertices[i++]
                    svg += `Q ${cpx} ${cpy} ${x} ${y} `
                } break
                // C
                case 4: {
                    const cpx1 = this.vertices[i++]
                    const cpy1 = this.vertices[i++]
                    const cpx2 = this.vertices[i++]
                    const cpy2 = this.vertices[i++]
                    const x = this.vertices[i++]
                    const y = this.vertices[i++]
                    svg += `C ${cpx1} ${cpy1} ${cpx2} ${cpy2} ${x} ${y} `
                } break
            }
        }
        svg += '" stroke="black" style="fill:none"/>\n'
        svg += '</svg>'
        return svg
    }
}

export class PathDataBuilder {
    constructor() {
        this.path = new PathData()

        this._started = false

        /** @type {number} */
        this.firstX = null
        /** @type {number} */
        this.firstY = null

        /** @type {number} */
        this.lastX = null
        /** @type {number} */
        this.lastY = null
    }

    clear() {
        this.path.clear()
        this._started = false

        /** @type {number} */
        this.firstX = null
        /** @type {number} */
        this.firstY = null

        /** @type {number} */
        this.lastX = null
        /** @type {number} */
        this.lastY = null
    }
    /**
     * @param {Vector2Like} vec
     */
    start_vec(vec) {
        this.start(vec.x, vec.y)
    }

    /**
     * @param {Vector2Like} vec
     */
    line_vec(vec) {
        this.line(vec.x, vec.y)
    }

    /**
     * @param {Vector2Like} vec
     */
    assure_vec(vec) {
        this.assure(vec.x, vec.y)
    }

    /**
     * @param {Vector2Like} vec
     * @param {Vector2Like} cp0
     */
    quadratic_vec(vec, cp0) {
        this.quadratic(vec.x, vec.y, cp0.x, cp0.y)
    }

    /**
     * @param {Vector2Like} vec
     * @param {Vector2Like} cp0
     * @param {Vector2Like} cp1
     */
    cubic_vec(vec, cp0, cp1) {
        this.cubic(vec.x, vec.y, cp0.x, cp0.y, cp1.x, cp1.y)
    }

    /**
     * @param {number} x
     * @param {number} y
     */
    start(x, y) {
        if (this._started) return
        this._started = true

        this.path.commands.push(Command.M)
        this.path.vertices.push(x, y)
        this.firstX = x
        this.firstY = y
        this.lastX = x
        this.lastY = y
    }

    /**
     * @param {number} x
     * @param {number} y
     */
    assure(x, y) {
        if (!this._started) {
            this.start(x, y)
        } else if (!(Num.Equal(this.lastX, x) && Num.Equal(this.lastY, y))) {
            this.line(x, y)
        }
    }

    /**
     * @param {number} x
     * @param {number} y
     */
    line(x, y) {
        if (!this._started) return

        this.path.commands.push(Command.L)
        this.path.vertices.push(x, y)
        this.lastX = x
        this.lastY = y
    }

    /**
     * @param {number} xc
     * @param {number} yc
     * @param {number} x
     * @param {number} y
     */
    quadratic(xc, yc, x, y) {
        if (!this._started) return

        this.path.commands.push(Command.Q)
        this.path.vertices.push(xc, yc, x, y)
        this.lastX = x
        this.lastY = y
    }

    /**
     * @param {number} xc
     * @param {number} yc
     * @param {number} xc2
     * @param {number} yc2
     * @param {number} x
     * @param {number} y
     */
    cubic(xc, yc, xc2, yc2, x, y) {
        if (!this._started) return

        this.path.commands.push(Command.C)
        this.path.vertices.push(xc, yc, xc2, yc2, x, y)
        this.lastX = x
        this.lastY = y
    }

    /**
     * @param {Bezier} curve
     */
    bezier(curve) {
        if (curve.order === 2) {
            this.quadratic_vec(curve.points[1], curve.points[2])
        } else if (curve.order === 3) {
            this.cubic_vec(curve.points[1], curve.points[2], curve.points[3])
        } else {
            console.warn('should not have happened')
        }
    }

    /**
     * @param {Bezier} curve
     */
    bezier_assure_first_point(curve) {
        this.assure_vec(curve.points[0])
        this.bezier(curve)
    }

    end(close = false) {
        if (!this._started) return
        this._started = false

        if (close) {
            this.path.commands.push(Command.Z)
        }
    }

    /**
     * @param {Subpath} subpath
     * @param {boolean} [continuePath]
     * @param {number} [offsetCmdS]
     * @param {number} [offsetCmdE]
     * @param {number} [offsetVerS]
     * @param {number} [offsetVerE]
     */
    copySubpath(subpath, continuePath = false, offsetCmdS = 0, offsetCmdE = 0, offsetVerS = 0, offsetVerE = 0) {
        this.copy(subpath.path, continuePath, subpath.cmdS + offsetCmdS, subpath.cmdE - offsetCmdE, subpath.verS + offsetVerS, subpath.verE - offsetVerE)
    }

    /**
     * @param {PathData} path
     * @param {boolean} [continuePath=false]
     * @param {number} [cmdS]
     * @param {number} [cmdE]
     * @param {number} [verS]
     * @param {number} [verE]
     */
    copy(path, continuePath = false, cmdS = 0, cmdE = path.commands.length, verS = 0, verE = path.vertices.length) {
        for (let i = cmdS; i < cmdE; i++) {
            if (continuePath && path.commands[i] === Command.M) {
                this.path.commands.push(Command.L)
            } else {
                this.path.commands.push(path.commands[i])
            }
        }

        for (let i = verS; i < verE; i++) {
            this.path.vertices.push(path.vertices[i])
        }
    }
}

export class Subpath {
    /**
     * @param {PathData} path
     * @param {number} cmdS
     * @param {number} cmdE
     * @param {number} verS
     * @param {number} verE
     */
    constructor(path, cmdS, cmdE, verS, verE) {
        this.path = path
        this.cmdS = cmdS
        this.cmdE = cmdE
        this.verS = verS
        this.verE = verE
        this.isClosed = this._isClosed()
        this.metadata = path.metadata[cmdS] || {}
        this.isEnd0Cap = this.metadata.isEnd0Cap === undefined ? true : this.metadata.isEnd0Cap
        this.isEnd1Cap = this.metadata.isEnd1Cap === undefined ? true : this.metadata.isEnd1Cap
    }

    get hasCurves() {
        for (let i = this.cmdS + 1; i < this.cmdE; i++) {
            if (this.path.commands[i] !== Command.L) return true
        }
        return false
    }

    /** @returns {Part} */
    first() {
        const cmd = this.path.commands[this.cmdS + 1]

        const isCurve = cmd === Command.Q || cmd === Command.C

        let k = this.verS

        const x0 = this.path.vertices[k++]
        const y0 = this.path.vertices[k++]
        let x0c
        let y0c
        let x1c
        let y1c

        if (isCurve) {
            x0c = this.path.vertices[k++]
            y0c = this.path.vertices[k++]
        }

        if (cmd === Command.C) {
            x1c = this.path.vertices[k++]
            y1c = this.path.vertices[k++]
        }

        const x1 = this.path.vertices[k++]
        const y1 = this.path.vertices[k]

        return new Part(
            cmd,
            x0,
            y0,
            x0c,
            y0c,
            x1c,
            y1c,
            x1,
            y1,
            true,
            this.cmdS + 1 === this.cmdE - 1,
            this.path.cornerRadiusOverrides[this.verS],
            this.path.cornerRadiusOverrides[k - 1]
        )
    }

    /** @returns {Part} */
    last() {
        const cmd = this.path.commands[this.cmdE - 1]

        const isCurve = cmd === Command.Q || cmd === Command.C

        let k = this.verE - 1

        const y1 = this.path.vertices[k--]
        const x1 = this.path.vertices[k--]

        let x0c
        let y0c
        let x1c
        let y1c

        if (cmd === Command.C) {
            y1c = this.path.vertices[k--]
            x1c = this.path.vertices[k--]
        }

        if (isCurve) {
            y0c = this.path.vertices[k--]
            x0c = this.path.vertices[k--]
        }

        const y0 = this.path.vertices[k--]
        const x0 = this.path.vertices[k]

        return new Part(
            cmd,
            x0,
            y0,
            x0c,
            y0c,
            x1c,
            y1c,
            x1,
            y1,
            this.cmdS === this.cmdE - 1,
            true,
            this.path.cornerRadiusOverrides[k],
            this.path.cornerRadiusOverrides[this.verE - 2]
        )
    }

    *iter() {
        let k = this.verS

        let lX = this.path.vertices[k++]
        let lY = this.path.vertices[k++]

        for (let j = this.cmdS + 1; j < this.cmdE; j++) {
            if (k >= this.path.vertices.length) return
            const cmd = this.path.commands[j]

            const isCurve = cmd === Command.Q || cmd === Command.C

            const cornerRadiusOverride0 = this.path.cornerRadiusOverrides[k - 2]

            const x0 = lX
            const y0 = lY
            let x0c
            let y0c
            let x1c
            let y1c

            if (isCurve) {
                x0c = this.path.vertices[k++]
                y0c = this.path.vertices[k++]
            }

            if (cmd === Command.C) {
                x1c = this.path.vertices[k++]
                y1c = this.path.vertices[k++]
            }

            const cornerRadiusOverride1 = this.path.cornerRadiusOverrides[k]

            // if (cmd === Command.Q || cmd === Command.C || cmd === Command.L) {
            const x1 = this.path.vertices[k++]
            const y1 = this.path.vertices[k++]
            // }

            lX = x1
            lY = y1

            yield new Part(
                cmd,
                x0,
                y0,
                x0c,
                y0c,
                x1c,
                y1c,
                x1,
                y1,
                j === this.cmdS + 1,
                j === this.cmdE - 1,
                cornerRadiusOverride0,
                cornerRadiusOverride1
            )
        }
    }

    /** @param {ForEachFn} fn */
    forEach(fn) {
        const gen = this.iter()

        /** @type {Part} */
        const first = gen.next().value
        /** @type {Part} */
        let curr = first

        let isFirstIter = true

        for (const next of gen) {
            fn(curr, next, isFirstIter, !this.isClosed && next.isLast)

            isFirstIter = false

            curr = next
        }

        if (this.isClosed) {
            fn(curr, first, isFirstIter, true)
        }
    }

    /** @returns {boolean} */
    _isClosed() {
        const x0 = this.path.vertices[this.verS]
        const y0 = this.path.vertices[this.verS + 1]
        const x1 = this.path.vertices[this.verE - 2]
        const y1 = this.path.vertices[this.verE - 1]
        return Num.Equal(x0, x1) && Num.Equal(y0, y1)
    }
}

export class Part {
    /**
     * @param {Command} cmd
     * @param {number} x0
     * @param {number} y0
     * @param {number} x0c
     * @param {number} y0c
     * @param {number} x1c
     * @param {number} y1c
     * @param {number} x1
     * @param {number} y1
     * @param {boolean} isFirst
     * @param {boolean} isLast
     * @param {number} cornerRadiusOverride0
     * @param {number} cornerRadiusOverride1
     */
    constructor(cmd, x0, y0, x0c, y0c, x1c, y1c, x1, y1, isFirst, isLast, cornerRadiusOverride0, cornerRadiusOverride1) {
        this.cmd = cmd
        this.p0 = new Vector2(x0, y0)
        this.cp0 = x0c !== undefined && new Vector2(x0c, y0c)
        this.cp1 = x1c !== undefined && new Vector2(x1c, y1c)
        this.p1 = new Vector2(x1, y1)
        this.isFirst = isFirst
        this.isLast = isLast
        this.isCurve = cmd === Command.Q || cmd === Command.C
        this.cornerRadiusOverride0 = cornerRadiusOverride0
        this.cornerRadiusOverride1 = cornerRadiusOverride1
    }

    /** @returns {Vector2} a point that can be used to calculate the tangent at `p0` */
    get tan0() {
        if (this.p0.equals(this.cp0)) {
            return this.cmd === Command.C ? this.cp1 : this.p1
        } else {
            return this.cp0
        }
    }

    /** @returns {Vector2} a point that can be used to calculate the tangent at `p1` */
    get tan1() {
        if (this.cmd === Command.C) {
            return this.p1.equals(this.cp1) ? this.cp0 : this.cp1
        } else {
            return this.p1.equals(this.cp0) ? this.p0 : this.cp0
        }
    }

    bezier() {
        if (!this.isCurve) throw new Error('not a curve')

        if (this.cmd === Command.C) {
            // avid this issue https://github.com/Pomax/bezierjs/issues/37
            const sameS = this.p0.equals(this.cp0)
            const sameE = this.p1.equals(this.cp1)
            const cp0 = sameS ? getNewCP(this.p0, this.cp1) : this.cp0
            const cp1 = sameE ? getNewCP(this.p1, this.cp0) : this.cp1

            const b = new Bezier(this.p0.x, this.p0.y, cp0.x, cp0.y, cp1.x, cp1.y, this.p1.x, this.p1.y)

            return b
        } else {
            return new Bezier(this.p0.x, this.p0.y, this.cp0.x, this.cp0.y, this.p1.x, this.p1.y)
        }
    }

    bezierData() {
        if (!this.isCurve) throw new Error('not a curve')

        return bezierData(this.p0, this.cp0, this.cp1, this.p1)
    }
}

/**
 * @typedef {{ length: number, getPointAt: (dist: number) => Vector2, getTAt: (dist: number) => number, getP: (t: number) => Vector2 }} BezierData
 */

/**
 * Get quadratic or cubic bezier data
 * @private
 * @param {Vector2} s - start point
 * @param {Vector2} c0 - first control point
 * @param {Vector2|undefined} c1 - second control point
 * @param {Vector2} e - end point
 * @param {number} [steps] - nr of intermediary points for look up table
 * @returns {BezierData}
 */
export function bezierData(s, c0, c1, e, steps = 256) {
    const [lut, length] = getLUT(s, c0, c1, e, steps)

    const getP = (t) => getPFn(t, s, c0, c1, e)

    /**
     * @param {number} dist distance from start
     * @returns {number} `t`
     */
    const getTAt = (dist) => {
        if (dist <= 0) return 0
        if (dist >= length) return 1

        for (let i = 0; i < steps; i++) {
            if (lut[i] >= dist) {
                const low = lut[i]
                const high = lut[i + 1]
                /** increase accuracy by calculating where dist lies between low and high */
                const percent = (dist - low) / (high - low)
                return (i + percent) / steps
            }
        }

        return 1
    }

    /**
     * @param {number} dist distance from start
     * @returns {Vector2}
     */
    const getPointAt = (dist) => getP(getTAt(dist))

    return { length, getPointAt, getTAt, getP }
}

/**
 * @param {Vector2} p
 * @param {Vector2} cp
 * @returns {Vector2}
 */
function getNewCP(p, cp) {
    return cp
        .clone()
        .sub(p)
        .normalize()
        .scale(CMP_EPSILON * 100)
        .add(p)
}

export const getLUT = (s, c0, c1, e, steps = 256) => {
    /**
     * look up table - key is `t = i / steps` value is `length`
     * @type {number[]}
     */
    const lut = new Array(steps + 1)
    lut[0] = 0
    let length = 0
    const last = new Vector2().copy(s)
    for (let i = 1; i <= steps; i++) {
        const p = getPFn(i / steps, s, c0, c1, e)
        length += p.distance_to(last)
        lut[i] = length
        last.copy(p)
    }

    return [lut, length]
}

export const getSegmentLUT = (s, c0, c1, e, steps = 256) => {
    /**
     * look up table - key is `t = i / steps` value is `length`
     * @type {number[]}
     */
    const lut = [new Vector2(s.x, s.y)]
    for (let i = 1; i <= steps; i++) {
        const p = getPFn(i / steps, s, c0, c1, e)
        lut.push(p.clone())
    }

    return lut
}
