import { Mode, EditMode, EntityType } from '@phase-software/types'
import {
    setAdd,
    arrEquals,
    PropChange,
    Change,
    FlagsEnum,
    NO_COMMIT
} from '@phase-software/data-utils'
import { Setter } from './Setter'

/** @typedef {import('./Element').Element} Element */
/** @typedef {import('@phase-software/data-utils/src/mesh/Vertex').Vertex} Vertex */
/** @typedef {('SELECT')} SelectChangeEvent */

const UNDO_EVENTS = ['SELECT', 'SELECT_CELL']

const ENABLE_UPDATE_TRANSACTION = {
    ELEMENT: EntityType.ELEMENT,
    ENTITIES: new Set([EntityType.COMPUTED_STYLE, EntityType.COMPUTED_LAYER, EntityType.COMPUTED_EFFECT])
}

/**
 * Workspace's Selection
 * @fires 'SELECT'
 * @fires 'hover'
 * @fires 'SELECT_CELL'
 */
export class Selection extends Setter {
    /**
     * @param {DataStore} dataStore
     * @param {SelectionData} [data]
     */
    constructor(dataStore, data) {
        super(dataStore, data)
        setAdd(this.undoEvents, UNDO_EVENTS)
        this.dataStore.on('mode', this._handleModeChange.bind(this))
        this.dataStore.interaction.on('INTERACTION_CHANGES', this._handleInteractionChange.bind(this))
        this.on('SELECT', this._handleSelectChange.bind(this))
    }

    _handleInteractionChange(changes) {
        if (changes.DELETE.size) {
            this.removeKFs(Array.from(changes.DELETE), {undoable: true, commit: false})
        }
    }

    _handleSelectChange(changes) {
        const motionPointsChange = changes.get('motionPoints')
        if (motionPointsChange) {
            if (motionPointsChange.after.length) {
                this.dataStore.switchEditMode(EditMode.MOTION_PATH, NO_COMMIT)
            } else {
                this.dataStore.switchEditMode(EditMode.ELEMENT, NO_COMMIT)
            }
        }
    }
    _handleModeChange(mode) {
        if (mode !== Mode.ACTION) {
            this.clearMotionPoints(NO_COMMIT)
        }
    }

    /**
     * @protected
     */
    create() {
        super.create()
        this.data.type = EntityType.SELECTION
        this.data.hover = null
        this.data.focusedLayer = null
        this.data.elements = []
        this.data.kfs = []
        this.data.vertices = []
        this.data.hoverVertex = null
        this.data.snapTarget = null
        this.data.dragOver = null
        this.data.motionPoints = []
        this.data.hoverMotionPoint = null
    }

    /**
     * Clears selection
     * @param {object} [options]
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @returns {boolean|undefined} false if not have to clear
     */
    clear(options = undefined) {
        if (this.isEmpty()) {
            return false
        }
        const original = {
            elements: [...this.data.elements],
            motionPoints: [...this.data.motionPoints],
            kfs: [...this.data.kfs]
        }

        this.data.elements = []
        this.data.kfs = []
        this.data.vertices = []
        this.data.focusedLayer = null
        this.data.hover = null
        this.data.hoverVertex = null
        this.data.dragOver = null
        this.data.motionPoints = []
        this.data.hoverMotionPoint = null
        this._fireAndCommitEvent(original, options)
    }

    /**
     * Select elements
     * @param {Element[]} elements
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    selectElements(elements, options = undefined) {
        const original = {
            elements: [...this.data.elements],
            motionPoints: [...this.data.motionPoints],
        }

        // console.log('DEBUG: selectElements', elements, options, original)
        // console.trace()

        // sort selection in order defined by scene tree and filter
        this.dataStore.eam.activateElementEditMode(NO_COMMIT)

        this.data.elements = this._sortAndFilterNewElements(elements)
        this.data.motionPoints = []

        this._elementSelectionChangePostProcess(original)
        this._fireAndCommitEvent(original, options)
    }

    /**
     * Add elements to selection
     * @param {Element[]} elements
     * @param {object} [options]
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     */
    addElements(elements, options = undefined) {
        const original = {
            elements: [...this.data.elements]
        }

        // console.log('DEBUG: addElements', elements, options, original)
        // console.trace()

        // TODO: range select
        // this.data.elements = this._filterToggleAndSortWithExistingElements(elements, false)

        const newElements = elements.filter(element => this.data.elements.indexOf(element) === -1)
        if (newElements.length) {
            this.data.elements.push(...newElements)
            // sort selection in order defined by scene tree (reassign because of tests)
            this.data.elements = this.dataStore.sortElements(this.data.elements)

            this._elementSelectionChangePostProcess(original)

            this._fireAndCommitEvent(original, options)
        }
    }

    /**
     * Remove elements from selection
     * @param {Element[]} elements
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    removeElements(elements, options = undefined) {
        const original = {
            elements: [...this.data.elements]
        }

        // console.log('DEBUG: removeElements', elements, options, original)
        // console.trace()

        this.data.elements = this.data.elements.filter(element => elements.indexOf(element) === -1)
        this._elementSelectionChangePostProcess(original)
        this._fireAndCommitEvent(original, options)
    }

    /**
     * Toggle elements from selection
     * @param {Element[]} elements
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    toggleElements(elements, options = undefined) {
        const original = {
            elements: [...this.data.elements]
        }

        // console.log('DEBUG: toggleElements', elements, options, original)
        // console.trace()

        // filter selection and sort in order defined by scene tree
        this.data.elements = this._filterToggleAndSortWithExistingElements(elements)
        this._elementSelectionChangePostProcess(original)
        this._fireAndCommitEvent(original, options)
    }

    /**
     * Clear Element selection
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    clearElements(options = undefined) {
        const original = {
            elements: [...this.data.elements]
        }

        // console.log('DEBUG: clearElements', options, original)
        // console.trace()

        this.data.elements = []
        this._fireAndCommitEvent(original, options)
    }

    /**
     * Select keyframes
     * @param {string[]} kfs
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     * @param {string[]} [ref]                   original reference
     * @returns {string[]} selected keyframes
     */
    selectKFs(kfs, options = undefined, ref = undefined) {
        const original = {
            kfs: [...this.data.kfs],
            elements: [...this.data.elements]
        }
        if (ref && ref.length) {
            original.kfs = ref.slice()
            original.elements = this._mapKFsToElements(original.kfs)
        }

        this.data.kfs = kfs.slice()
        // @Asa
        // Check selection SPEC, if we still want to have the relationship between elements and kfs
        this.data.elements = this._mapKFsToElements(this.data.kfs)

        this._fireAndCommitEvent(original, options)

        return this.data.kfs
    }

    /**
     * Select only keyframes
     * @param {string[]} kfs
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     * @param {string[]} [ref]                   original reference
     * @returns {string[]} selected keyframes
     */
    selectOnlyKFs(kfs, options = undefined, ref = undefined) {
        const original = { kfs: [...this.data.kfs] }
        
        if (ref && ref.length) {
            original.kfs = ref.slice()
        }

        this.data.kfs = kfs.slice()
        this._fireAndCommitEvent(original, options)

        return this.data.kfs
    }

    /**
     * Add keyframes to selection
     * @param {string[]} kfs
     * @param {object} [options]
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @returns {string[]} selected keyframes
     */
    addKFs(kfs, options = undefined) {
        const newKfs = kfs.filter((kf) => !this.data.kfs.includes(kf))
        if (!newKfs.length) {
            return this.data.kfs
        }

        const original = {
            kfs: [...this.data.kfs]
        }
        this.data.kfs.push(...newKfs)
        const newElements = this._mapKFsToElements(this.data.kfs)
        if (newElements.length) {
            original.elements = [...this.data.elements]
            this.data.elements = newElements
        }
        this._fireAndCommitEvent(original, options)

        return this.data.kfs
    }

    /**
     * Remove keyframes from selection
     * @param {string[]} kfs
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    removeKFs(kfs, options = undefined) {
        const original = {
            kfs: [...this.data.kfs]
        }
        this.data.kfs = this.data.kfs.filter(kf => kfs.indexOf(kf) === -1)
        // do not change element selection when removing KFs from selection
        this._fireAndCommitEvent(original, options)
    }


    /**
     * Toggle keyframes from selection
     * @param {string[]} kfs
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     * @returns {string[]} selected keyframes
     */
    toggleKFs(kfs, options = undefined) {
        const original = {
            kfs: [...this.data.kfs],
            elements: [...this.data.elements]
        }
        const newKFSet = new Set(this.data.kfs)
        kfs.forEach(kf => {
            if (newKFSet.has(kf)) {
                newKFSet.delete(kf)
            } else {
                newKFSet.add(kf)
            }
        })
        this.data.kfs = [...newKFSet]
        this.data.elements = this._mapKFsToElements(this.data.kfs)
        this._fireAndCommitEvent(original, options)

        return this.data.kfs
    }

    /**
     * Clear KF selection
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    clearKFs(options = undefined) {
        const original = {
            kfs: [...this.data.kfs]
        }

        this.data.kfs = []
        this._fireAndCommitEvent(original, options)
    }

    /**
     * Select the specified vertices with the specified options
     * @param {Vertex[]} vertices
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    selectVertices(vertices, options) {
        if (
            !vertices.length &&
            this.data.hoverVertex &&
            this.data.hoverVertex.isFlagged(FlagsEnum.CURVE_VERT)
        ) {
            this.data.hoverVertex = null
        }
        // If original vertices and new vertices have the same amout of vertices
        if (vertices.length === this.data.vertices.length) {
            // If all empty, just do nothing
            if (!vertices.length) {
                return
            }

            // If has vertices, then check if the vertices are still the same
            const noChange = this.data.vertices.every((v) => vertices.includes(v))
            if (noChange) {
                return
            }
        }

        const original = [...this.data.vertices]
        this.data.vertices = vertices
        this._fireAndCommitVertEvent(original, options)
    }

    /**
     * Add the specified vertices to the selected vertices collection with the specified options
     * @param {Vertex[]} vertices
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    addVertices(vertices, options) {
        const original = [...this.data.vertices]
        /** @todo we will allow to add vertices and curve control handle together */
        // We don't want to add the vertices and curve control handle together
        if (vertices.length === 0) return
        if (this.data.vertices.length !== 0 &&
            this.data.vertices[0].isFlagged(FlagsEnum.CURVE_VERT)
            === vertices[0].isFlagged(FlagsEnum.CURVE_VERT)
        ) {
            // O(n^2)
            for (let i = 0; i < vertices.length; i++) {
                if (this.data.vertices.indexOf(vertices[i]) === -1) {
                    this.data.vertices.push(vertices[i])
                }
            }

        } else {
            this.data.vertex = vertices
        }
        this._fireAndCommitVertEvent(original, options)
    }

    /**
     * Remove the specified vertices to the selected vertices collection with the specified options
     * @param {Vertex[]} vertices
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    removeVertices(vertices, options) {
        const original = [...this.data.vertices]
        // O(n^2)
        const remains = this.data.vertices.filter(vertex => vertices.indexOf(vertex) === -1)
        this.data.vertices = remains
        this._fireAndCommitVertEvent(original, options)
    }

    /**
     * Symmetrical difference the specified vertices to the selected vertices collection with the specified options
     * @param {Vertex[]} vertices
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    toggleVertices(vertices, options) {
        const original = [...this.data.vertices]
        // O(n^2)
        const difference = vertices
            .filter(vertex => this.data.vertices.indexOf(vertex) === -1)
            .concat(this.data.vertices.filter(vertex => vertices.indexOf(vertex) === -1))
        this.data.vertices = difference
        this._fireAndCommitVertEvent(original, options)
    }

    /**
     * Clear Vertex selection
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    clearVertices(options = undefined) {
        const original = [...this.data.vertices]
        this.data.vertices = []
        this._fireAndCommitVertEvent(original, options)
    }

    selectMotionPoints(motionPoints, options) {
        if (
            !motionPoints.length &&
            this.data.hoverMotionPoint &&
            (this.data.hoverMotionPoint.type === 'in' || this.data.hoverMotionPoint.type === 'out')
        ) {
            this.data.hoverMotionPoint = null
        }
        // If original vertices and new vertices have the same amount of vertices
        if (motionPoints.length === this.data.motionPoints.length) {
            // If all empty, just do nothing
            if (!motionPoints.length) {
                return
            }

            // If has vertices, then check if the vertices are still the same
            const noChange = this.data.motionPoints.every((v) => motionPoints.some(p => p.type === v.type && p.key === v.key))
            if (noChange) {
                return
            }
        }

        const original = {
            motionPoints: [...this.data.motionPoints]
        }
        this.data.motionPoints = motionPoints
        this._fireAndCommitEvent(original, options)
    }

    /**
     * Add the specified vertices to the selected vertices collection with the specified options
     * @param {Vertex[]} motionPoints
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    addMotionPoints(motionPoints, options) {
        const original = {
            motionPoints: [...this.data.motionPoints]
        }
        /** @todo we will allow to add vertices and curve control handle together */
        // We don't want to add the vertices and curve control handle together
        if (motionPoints.length === 0) return
        if (this.data.motionPoints.length === 0) {
            this.data.motionPoints = motionPoints
        } else {
            // O(n^2)
            for (let i = 0; i < motionPoints.length; i++) {
                if (this.data.motionPoints.indexOf(motionPoints[i]) === -1) {
                    this.data.motionPoints.push(motionPoints[i])
                }
            }
        }
        this._fireAndCommitEvent(original, options)
    }

    /**
     * Remove the specified vertices to the selected vertices collection with the specified options
     * @param {Vertex[]} motionPoints
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    removeMotionPoints(motionPoints, options) {
        const original = {
            motionPoints: [...this.data.motionPoints]
        }
        // O(n^2)
        const remains = this.data.motionPoints.filter(motionPoint => motionPoints.indexOf(motionPoint) === -1)
        this.data.motionPoints = remains
        this._fireAndCommitEvent(original, options)
    }

    /**
     * Symmetrical difference the specified vertices to the selected vertices collection with the specified options
     * @param {Vertex[]} motionPoints
     * @param {Function} searchFn
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    toggleMotionPoints(motionPoints, searchFn, options) {
        const original = {
            motionPoints: [...this.data.motionPoints]
        }
        // O(n^2)
        const difference = motionPoints
            .filter(motionPoint => !searchFn(this.data.motionPoints, motionPoint))
            .concat(this.data.motionPoints.filter(motionPoint => !searchFn(motionPoints, motionPoint)))
        this.data.motionPoints = difference
        this._fireAndCommitEvent(original, options)
    }

    /**
     * Clear Vertex selection
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    clearMotionPoints(options = undefined) {
        const original = {
            motionPoints: [...this.data.motionPoints]
        }
        this.data.motionPoints = []
        this._fireAndCommitEvent(original, options)
    }

    /**
     * Check if element is in selection
     * @param {Element} element
     * @returns {boolean}       true if selected; false otherwise
     */
    isSelected(element) {
        return this.data.elements.indexOf(element) !== -1
    }

    /**
     * @returns {boolean} true if no element is selected
     */
    isEmpty() {
        return (
            this.data.elements.length === 0 &&
            this.data.kfs.length === 0 &&
            this.data.vertices.length === 0
        )
    }

    isElementsEmpty() {
        return this.data.elements.length === 0
    }

    isKFsEmpty() {
        return this.data.kfs.length === 0
    }

    isVerticesEmpty() {
        return this.data.vertices.length === 0
    }

    /**
     * undo a SELECT event with given SelectChangeEvent
     * @param {string} type
     * @param {SelectChangeEvent} changes
     */
    undo(type, changes) {
        super.undo(type, changes)
        if (type === 'SELECT') {
            const original = {}
            if (changes.has('elements')) {
                original.elements = [...this.data.elements]
                this.data.elements = changes.get('elements').before
            }
            if (changes.has('kfs')) {
                original.kfs = [...this.data.kfs]
                this.data.kfs = changes.get('kfs').before
            }
            if (changes.has('motionPoints')) {
                original.motionPoints = [...this.data.motionPoints]
                this.data.motionPoints = changes.get('motionPoints').before
            }
            this._fireAndCommitEvent(original, { undoable: false })
        } else if (type === 'SELECT_CELL') {
            const original = [...this.data.vertices]
            this.data.vertices = changes.get('vertices').before
            this._fireAndCommitVertEvent(original, { undoable: false })
        }
    }

    /**
     * redo a SELECT event with given SelectChangeEvent
     * @param {string} type
     * @param {SelectChangeEvent} changes
     */
    redo(type, changes) {
        super.undo(type, changes)
        if (type === 'SELECT') {
            const original = {}
            if (changes.has('elements')) {
                original.elements = [...this.data.elements]
                this.data.elements = changes.get('elements').after
            }
            if (changes.has('kfs')) {
                original.kfs = [...this.data.kfs]
                this.data.kfs = changes.get('kfs').after
            }
            if (changes.has('motionPoints')) {
                original.motionPoints = [...this.data.motionPoints]
                this.data.motionPoints = changes.get('motionPoints').after
            }
            this._fireAndCommitEvent(original, { undoable: false })
        } else if (type === 'SELECT_CELL') {
            const original = [...this.data.vertices]
            this.data.vertices = changes.get('vertices').after
            this._fireAndCommitVertEvent(original, { undoable: false })
        }
    }

    /**
     * Merge current event object into previous event object (if possible)
     * @param {string} type
     * @param {object} prevChanges
     * @param {object} newChanges
     * @returns {bool} true if changes object successfully combined, false otherwise
     */
    combineUndo(type, prevChanges, newChanges) {
        if (type === 'SELECT') {
            // take value from the new changes but keep the original from the old ones
            if (prevChanges.has('elements') && newChanges.has('elements')) {
                prevChanges.get('elements').after = newChanges.get('elements').after
            }
            if (prevChanges.has('kfs') && newChanges.has('kfs')) {
                prevChanges.get('kfs').after = newChanges.get('kfs').after
            }
            return true
        } else if (type === 'SELECT_CELL') {
            prevChanges.get('vertices').after = newChanges.get('vertices').after
            return true
        }
        return false
    }

    /**
     * Sort and then filter element list provided to Selection according to their parent-children relationship
     * @param {Element[]} elements     sorted elements
     * @param {bool} sortInPlace     set ot true to sort in place if `elements` list is modifiable
     * @returns {Element[]}
     */
    _sortAndFilterNewElements(elements, sortInPlace = false) {
        const list = this.dataStore.sortElements(elements, sortInPlace)
        const isDescendantOf = this.dataStore.isDescendantOf.bind(this.dataStore)

        let ancestor
        const newFiltered = []
        for (let i = list.length - 1; i >= 0; i--) {
            const el = list[i]
            if (ancestor && isDescendantOf(el, ancestor)) {
                continue
            }
            ancestor = el
            newFiltered.unshift(el)
        }
        return newFiltered
    }

    /**
     * Sort and then filter provided element list based on range select rules and parent-children relationship
     * @param {Element[]} rangeElements
     * @param {bool} sortInPlace     set ot true to sort in place if `elements` list is modifiable
     * @returns {Element[]}
     */
    _sortAndFilterRangeNewElements(rangeElements, sortInPlace = false) {
        const getParentOf = this.dataStore.getParentOf.bind(this.dataStore)

        const list = this.dataStore.sortElements(rangeElements, sortInPlace) // reversed from UI
        const listSet = new Set(this.data.elements.concat(list))

        const isSelected = (element) => listSet.has(element)
        const isAllChildrenSelected = (element) => element.children.every(isSelected)

        const parentSet = new Set()
        const queue = list.map(el => getParentOf(el)).filter(Boolean)
        while (queue.length) {
            const el = queue.shift()
            parentSet.add(el)
            const parent = getParentOf(el)
            if (parent) {
                queue.push(parent)
            }
        }

        const newFiltered = new Set(this.data.elements.filter(el => !parentSet.has(el)))

        list.forEach((element, index) => {
            const parent = getParentOf(element)

            if (newFiltered.has(parent)) {
                newFiltered.delete(element)
            }

            if (!newFiltered.has(parent) && isSelected(parent) && isAllChildrenSelected(parent)) {
                newFiltered.add(parent)
                parent.children.forEach(el => {
                    newFiltered.delete(el)
                })
            }

            if (!newFiltered.has(parent) && (!element.children || !index)) {
                newFiltered.add(element)
                if (element.children) {
                    element.children.forEach(el => {
                        newFiltered.delete(el)
                    })
                }
            }

        })
        return this.dataStore.sortElements(Array.from(newFiltered))
    }

    /**
     * Filter provided element list based on toggle select rules and parent-children relationship and then sort them
     * @param {Element[]} elements
     * @returns {Element[]}
     */
    _filterToggleAndSortWithExistingElements(elements) {
        const existingSet = new Set(this.data.elements)
        const newSet = new Set(elements)
        const isDescendantOf = this.dataStore.isDescendantOf.bind(this.dataStore)

        for (const exEl of existingSet) {
            for (const newEl of newSet) {
                if (exEl === newEl) {
                    // remove from both sets, since need to toggle it out of selection
                    existingSet.delete(exEl)
                    newSet.delete(newEl)
                } else if (isDescendantOf(exEl, newEl) || isDescendantOf(newEl, exEl)) {
                    existingSet.delete(exEl)
                }
            }
        }
        return this.dataStore.sortElements([...existingSet, ...newSet])
    }

    /**
     * Clear keyframe selection if element selection changed
     * @param {object} original
     */
    _elementSelectionChangePostProcess(original) {
        if (!arrEquals(original.elements, this.data.elements)) {
            if (this.data.kfs.length) {
                original.kfs = [...this.data.kfs]
                this.data.kfs = []
            }

            if (this.data.elements.length !== 1) {
                original.motionPoints = [...this.data.motionPoints]
                this.data.motionPoints = []
            }
        }
    }

    /**
     * Maps list of KFs to list of elements those KFs are created for
     * @param {string[]} kfs
     * @returns {Element[]} sorted list of elements
     */
    _mapKFsToElements(kfs) {
        const elIdSet = new Set(kfs.map(kfId => this.dataStore.interaction.getElementIdByKeyFrame(kfId)))
        const elList = [...elIdSet].map(elId => this.dataStore.getById(elId))
        return this._sortAndFilterNewElements(elList, true)
    }

    /**
     * Fire the selection event and commit
     * @private
     * @param {object} original
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    _fireAndCommitEvent(original, { undoable = true, commit = true, flags, force = false } = { undoable: true, commit: true, force: false }) {
        const changes = new PropChange()
        if (original.elements && (!arrEquals(original.elements, this.data.elements) || force)) {
            changes.update('elements', new Change({
                before: original.elements,
                after: [...this.data.elements]
            }))
            this.dataStore.setFeature('editOrigin', false, { commit, undoable })
        }
        if (original.kfs && (!arrEquals(original.kfs, this.data.kfs) || force)) {
            changes.update('kfs', new Change({
                before: original.kfs,
                after: [...this.data.kfs]
            }))
        }

        if (original.motionPoints && !arrEquals(original.motionPoints, this.data.motionPoints)) {
            changes.update('motionPoints', new Change({
                before: original.motionPoints,
                after: [...this.data.motionPoints]
            }))
        }

        // only fire while the selection is really changed
        if (changes.size) {
            this.fire('SELECT', changes, { undoable, flags })
            if (undoable && commit) {
                this.dataStore.commitUndo()
            }
        }
    }

    /**
     * Fire the selection vertex event and commit
     * @param {Vertex[]} original
     * @param {object} [options]
     * @param {boolean} [options.undoable=true]  true if this method should add fired events to Undo; false otherwise
     * @param {boolean} [options.commit=true]    true if this method should commit all events it fired; set to false to not commit automatically
     */
    _fireAndCommitVertEvent(original, { undoable = true, commit = true } = { undoable: true, commit: true }) {
        const changes = new PropChange()
        changes.update('vertices', new Change({
            before: original,
            after: [...this.data.vertices]
        }))
        this.fire('SELECT_CELL', changes, { undoable })
        if (undoable && commit) {
            this.dataStore.commitUndo()
        }
    }

    updateTransaction(transaction, owner, changes) {
        const type = owner.get('type')
        const elements = new Set(this.get('elements'))
        const computedParents = new Set()
        const children = new Set()
        const siblings = new Set()

        // Aggregate computed parents
        elements.forEach((element) => {
            const parent = this.dataStore.getParentOf(element)
            if (parent && parent.isComputedGroup) {
                computedParents.add(parent)
                parent.children.forEach(child => {if (child !== element) siblings.add(child)})
            }

            if (element.isComputedGroup) {
                element.children.forEach(child => children.add(child))
            }
        })

        // Check if the changes will affect by computed parent or not
        const elementOrComputedParentHasChanges = elements.has(owner) || computedParents.has(owner)
        const elementOrComputedParentInstanceHasChanges = elements.has(owner.element) || computedParents.has(owner.element)
        const elementOrChildrenInstanceHasChanges = elements.has(owner.element) || children.has(owner.element)
        const elementOrSiblingsInstanceHasChanges = elements.has(owner.element) || siblings.has(owner.element)
        const isGeometryTypeChange = elements.has(owner.liftupParent) && changes.get('geometryType')

        if (
            (type === ENABLE_UPDATE_TRANSACTION.ELEMENT && elementOrComputedParentHasChanges) ||
            (ENABLE_UPDATE_TRANSACTION.ENTITIES.has(type) && elementOrComputedParentInstanceHasChanges) ||
            (ENABLE_UPDATE_TRANSACTION.ENTITIES.has(type) && elementOrChildrenInstanceHasChanges) ||
            (ENABLE_UPDATE_TRANSACTION.ENTITIES.has(type) && elementOrSiblingsInstanceHasChanges) ||
            isGeometryTypeChange
        ) {
            if (changes && changes.size) {
                if (transaction.has(owner)) {
                    const origin = transaction.get(owner)
                    changes.forEach((value, key) => {
                        origin.set(key, value)
                    })
                } else {
                    transaction.set(owner, changes)
                }
            }
        }
    }

    endTransaction(transaction) {
        if (transaction.size) {
            this.fire('SELECTION_CHANGES', transaction)
        }
    }
}

/** @typedef {import('./Setter').SetterData} SetterData */

/**
 * @typedef {SetterData} SelectionData
 */
