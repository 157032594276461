import {
  FileCollaboratorFieldsFragment,
  ProjectCollaboratorFieldsFragment,
  TeamUserFieldsFragment,
  TeamUserInvitationFieldsFragment
} from '../../generated/graphql'
import { MenuListOptionProps } from '../shared/Menu/Menu.types'
import {
  CollaboratorAction,
  CollaboratorPermission,
  DraftFilePermissionEnum,
  InvitationAction,
  ProjectFilePermissionEnum,
  ProjectPermissionEnum,
  ShareTypeEnum
} from './shareTypes'

export const getShareTypeDisplayName = (type: ShareTypeEnum) => ShareTypeEnum[type]

export type DefaultPermissionByType = {
  [key in ShareTypeEnum]: MenuListOptionProps[]
}

export const DEFAULT_PERMISSION_OPTIONS_BY_TYPE: DefaultPermissionByType = {
  project: [
    {
      name: 'can_edit',
      value: ProjectPermissionEnum.CAN_EDIT
    },
    {
      name: 'can_view',
      value: ProjectPermissionEnum.CAN_REVIEW
    },
    '-',
    {
      name: 'no_access',
      value: ProjectPermissionEnum.NO_ACCESS
    }
  ],
  draftFile: [
    {
      name: 'can_edit',
      value: DraftFilePermissionEnum.CAN_EDIT
    },
    {
      name: 'can_view',
      value: DraftFilePermissionEnum.CAN_REVIEW
    },
    '-',
    {
      name: 'no_access',
      value: DraftFilePermissionEnum.NO_ACCESS
    }
  ],
  projectFile: [
    {
      name: 'can_access',
      value: ProjectFilePermissionEnum.CAN_ACCESS
    },
    {
      name: 'view_only',
      value: ProjectFilePermissionEnum.REVIEW_ONLY
    },
    '-',
    {
      name: 'no_access',
      value: ProjectFilePermissionEnum.NO_ACCESS
    }
  ]
}

const CAN_EDIT = {
  name: 'can_edit',
  value: CollaboratorPermission.CAN_EDIT
}
const CAN_REVIEW = {
  name: 'can_view',
  value: CollaboratorPermission.CAN_REVIEW
}
const LEAVE = {
  name: 'leave',
  value: CollaboratorAction.LEAVE
}
const REMOVE = {
  name: 'remove',
  value: CollaboratorAction.REMOVE
}

const RESEND_INVITATION = {
  name: 'setting:team_setting.resend_invitation',
  value: InvitationAction.RESEND
}

const CANCEL_INVITATION = {
  name: 'setting:team_setting.cancel_invitation',
  value: InvitationAction.CANCEL
}

export const COLLABORATOR_PERMISSION_OPTIONS = [CAN_EDIT, CAN_REVIEW]
const COLLABORATOR_PERMISSION_OPTIONS_MAP = new Map(
  COLLABORATOR_PERMISSION_OPTIONS.map((option) => [option.value, option])
)
export const getCollaboratorPermissionName = (permission: CollaboratorPermission) =>
  COLLABORATOR_PERMISSION_OPTIONS_MAP.get(permission)?.name

const PROJECT_PERMISSION_DESCRIPTION_MAP = {
  [ProjectPermissionEnum.CAN_EDIT]: 'Anyone in team can edit',
  [ProjectPermissionEnum.CAN_REVIEW]: 'Anyone in team can view',
  [ProjectPermissionEnum.NO_ACCESS]: 'Anyone in team has no access'
}
export const getProjectPermissionDescription = (permission: ProjectPermissionEnum) =>
  PROJECT_PERMISSION_DESCRIPTION_MAP[permission]

const getCurrentUserActions = (isEditable: boolean) => {
  const editAccessRightAction = isEditable ? CAN_EDIT : CAN_REVIEW
  return [editAccessRightAction, '-', LEAVE]
}

const getOtherCollaboratorActions = (isEditable: boolean, isPending: boolean) => {
  const editAccessRightAction = isEditable ? [CAN_EDIT, CAN_REVIEW] : [CAN_REVIEW]
  const invitationAction = isPending ? [RESEND_INVITATION, '-', CANCEL_INVITATION] : [REMOVE]

  return [...editAccessRightAction, '-', ...invitationAction]
}

export const getAvailableCollaboratorActions = ({
  isEditable,
  isCurrentUser,
  isPending
}: {
  isEditable: boolean
  isCurrentUser: boolean
  isPending: boolean
}) => {
  if (isCurrentUser) {
    return getCurrentUserActions(isEditable)
  }
  return getOtherCollaboratorActions(isEditable, isPending)
}

export const transformTeamMemberWithInvitationList = ({
  teamUsers,
  teamUserInvitations
}: {
  teamUsers?: TeamUserFieldsFragment[]
  teamUserInvitations?: TeamUserInvitationFieldsFragment[]
}) => {
  const transformedTeamUsers = teamUsers?.map((teamUser) => teamUser.user?.email) || []
  const transformedTeamUserInvitations =
    teamUserInvitations?.map((teamUserInvitation) => teamUserInvitation.email) || []
  return transformedTeamUsers.concat(transformedTeamUserInvitations)
}
export const transformCollaborator = (
  collaborator: FileCollaboratorFieldsFragment | ProjectCollaboratorFieldsFragment
) => ({
  avatarImage: collaborator.avatar,
  description: collaborator.email || '',
  id: collaborator.invitation_id,
  isCreator:
    ('file_role' in collaborator && collaborator.file_role === 'creator') ||
    ('project_role' in collaborator && collaborator.project_role === 'creator'),
  isPending: collaborator.invitation_status === 'PENDING',
  name: collaborator.username || collaborator.email,
  permission: collaborator.permission,
  userId: collaborator.id,
  value: collaborator.email || ''
})

export const transformTeamMember = (teamMember: TeamUserFieldsFragment) => ({
  avatarImage: teamMember.user?.avatar,
  description: teamMember.user?.email || '',
  id: teamMember.user_id,
  name: teamMember.user?.username,
  value: teamMember.user?.email || ''
})
