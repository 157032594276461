import { BooleanOperation, ElementType, EntityType } from '@phase-software/types'
import { setupBaseGeometry } from '../node-setup'
import {
    watchElementChanges,
} from '../update-controller'
import { MaskType, UpdateType } from '../visual_server/RenderItem'

/** @typedef {import('../visual_server/SpatialCache').SceneNode} SceneNode */

// <element.id, wasm.geo_bank.id>
/** @type {Map<string, number>} */
export const idMap = new Map()
export const bank = {
    geo_bank_id: 1
}

/**
 * @param {VisualServer} VS
 * @param {SceneNode} node
 * @param {Element} element
 * @returns {RenderItem}
 */
export function initSceneNodeWithElement(VS, node, element) {
    const item = node.item

    item.name = element.get('name')

    const id = element.get('id')
    idMap.set(id, bank.geo_bank_id++)

    const entityType = element.get('type')
    const elementType = element.get('elementType')

    item.booleanType = BooleanOperation.NONE
    if (entityType !== EntityType.WORKSPACE && elementType === ElementType.CONTAINER) {
        if (element.get('isMask')) {
            item.maskType = MaskType.ALPHA
        }
        item.booleanType = element.get('booleanType') || BooleanOperation.NONE
    }

    // TODO: detect screen
    item.type = _determineItemType(elementType, item, element)


    if (entityType === EntityType.ELEMENT) {
        setupBaseGeometry(VS.storage, element, node)
    }
    // subscribe to change events
    watchElementChanges(element, item)

    return item
}

/**
 * @param {SceneNode} item
 */
export function updateBooleanParent(item) {
    let booleanParent = item.parent
    while (booleanParent) {
        if (booleanParent.item.isBooleanGroup()) {
            booleanParent.item.update(UpdateType.GEOMETRY)
        }
        booleanParent = booleanParent.parent
    }
}

// Define a function to determine the type of an item
/**
 * @param {ElementType} elementType
 * @param {RenderItem} item
 * @param {Element}element
 * @returns {import('../visual_server/RenderItem').NodeTypes}
 */
function _determineItemType(elementType, item, element) {
    if (elementType === ElementType.CONTAINER) {
        if (element.isNormalGroup) {
            return "group"
        } else if (!item.isMaskGroup() && !item.isBooleanGroup()) {
            return "container"
        }
    } else if (elementType === ElementType.SCREEN) {
        return "screen"
    }

    return "element"
}