
/** @typedef {import('@phase-software/types').KeyFrame} KeyFrame */


export default class Interval extends Array {
    /**
     * @param {bool} init  set to true to initialize KF with default objects
     */
    constructor(init = false) {
        super(2)

        /** @type {KeyFrame | null} */
        this[0] = init ? this._initKFData() : null
        /** @type {KeyFrame | null} */
        this[1] = init ? this._initKFData() : null
    }

    get start() {
        return this[0]
    }

    get end() {
        return this[1]
    }

    get isEmpty() {
        return !this[0] && !this[1]
    }

    /**
     * Update interval assinging input KeyFrame objects to start and end
     * @param {KeyFrame} start
     * @param {KeyFrame} end
     * @returns {Interval} self
     */
    update(start, end) {
        this[0] = start
        this[1] = end
        return this
    }

    /**
     * Copy the interval values from another interval
     * @param {Inteval} interval input interval
     * @param {bool} [copyKFs=false]    set to true if need to make a copy of KFs from input interval
     * @returns {Interval} self
     */
    copy(interval, copyKFs = false) {
        this[0] = copyKFs ? this._copyKFData(interval[0], this[0]) : interval[0]
        this[1] = copyKFs ? this._copyKFData(interval[1], this[1]) : interval[1]
        return this
    }

    /**
     * Update values of the interval KFs
     * @param {any} kf1Val
     * @param {any} kf2Val
     * @returns {Interval} self
     */
    updateValues(kf1Val, kf2Val) {
        if (this[0]) {
            this[0].value = kf1Val
        } else {
            this[0] = { value: kf1Val }
        }
        if (this[1]) {
            this[1].value = kf2Val
        } else {
            this[1] = { value: kf2Val }
        }
        return this
    }

    /**
     * Update times of the interval KFs
     * @param {any} kf1Time
     * @param {any} kf2Time
     * @returns {Interval} self
     */
    updateTimes(kf1Time, kf2Time) {
        if (this[0]) {
            this[0].time = kf1Time
        } else {
            this[0] = { time: kf1Time }
        }
        if (this[1]) {
            this[1].time = kf2Time
        } else {
            this[1] = { time: kf2Time }
        }
        return this
    }

    /**
     * Update easingType of the interval KFs
     * @param {EasingType} easingType
     * @returns {Interval} self
     */
    updateEasingType(easingType) {
        if (this[1]) {
            this[1].easingType = easingType
        } else {
            this[1] = { easingType }
        }
        return this
    }

    _initKFData() {
        return {}
    }

    _copyKFData(inKF, outKF){
        const out = outKF || {}
        out.id = inKF.id
        out.type = inKF.type
        out.frameType = inKF.frameType
        out.trackId = inKF.trackId
        out.easingType = inKF.easingType
        out.bezier = inKF.bezier
        out.steps = inKF.steps
        out.time = inKF.time
        out.value = inKF.value
        out.delta = inKF.delta
        out.ref = inKF.ref
        out.extra = inKF.extra ? { ...inKF.extra } : null
        return out
    }

    clear() {
        this[0] = null
        this[1] = null
        return this
    }
}

