import { CapShape } from '@phase-software/types'

/** @typedef {import('../dino').DemoData} DemoData */
/** @typedef {import('../dino').NodeData} NodeData */

/** @type {DemoData} */
export const Test_LineCap = (() => {
    /**
     * @param {string} name
     * @param {number} length
     * @param {string} cap
     * @param {number} x
     * @param {number} y
     * @param {string} paint
     * @returns {NodeData}
     */
    const genLine = (name, length, cap, x, y, paint) => ({
        name,
        shape: {
            type: "path",
            path: `M0,0 L${length},0`,
        },
        strokes: [
            {
                lineWidth,
                paint,
                cap,
            },
        ],
        transform: {
            position: [x, y],
        },
    })

    const caps = [
        CapShape.LINE_ARROW,
        CapShape.CIRCLE_SOLID,
        CapShape.CIRCLE_OUTLINE,
        CapShape.SQUARE_SOLID,
        CapShape.SQUARE_OUTLINE,
        CapShape.TRIANGLE_ARROW_SOLID,
        CapShape.TRIANGLE_ARROW_OUTLINE,
    ]

    const left = 50
    const top = 50
    const space = 50
    const length = 300
    const lineWidth = 5

    return {
        width: 400,
        height: top * 2 + space * (caps.length - 1),
        name: "Line Caps",
        tree: [
            ...caps.map((cap, i) => genLine(`line-${i}-c`, length + lineWidth * 2, cap, left - lineWidth, top + space * i, "#5cc9f5")),
            ...caps.map((_, i) => genLine(`line-${i}`, length - lineWidth * 5 * 2, CapShape.NONE, left + lineWidth * 5, top + space * i, "#b0f566")),
        ],
        animation: {
            tracks: [],
        },
    }
})()
