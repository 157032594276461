import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import { useUndoRedo } from '../../../hooks/useUndoRedo'
import { useDataStoreActions } from '../../../providers/dataStore/DataStoreProvider'
import { isUndoOrRedoKeyPressed } from '../../../utils/keyboard'
import Menu from '../Menu'
import DefaultSelect from './DefaultSelect'
import FlatSelect from './FlatSelect'

export const Select = forwardRef(
  (
    {
      variant,
      value,
      disabled,
      mixed,
      options,
      onChange,
      optionRender,
      onFocus,
      onBlur,
      noIS,
      showSearch,
      focusOnSelect,
      dataTestId,
      ...rest
    },
    forwardRef
  ) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const [search, setSearch] = useState('')
    const [isOnComposition, setIsOnComposition] = useState(false)
    const ref = useRef()
    const menuRef = useRef()
    const { undo, redo, updateUndoHistory, clearUndoHistory, commitChange, initiateDelete, finalizeDelete } =
      useUndoRedo()
    // FIXME: should keep the design system component clean
    const { changeEAMInputState } = useDataStoreActions()
    const updateIS = useCallback(
      (flag) => {
        if (!noIS) {
          changeEAMInputState(flag)
        }
      },
      [noIS, changeEAMInputState]
    )

    useEffect(() => {
      return () => {
        updateIS(false)
      }
    }, [updateIS])

    useImperativeHandle(forwardRef, () => ref.current)

    const selectedOption = mixed || value === undefined ? null : options.find((opt) => opt.value === value)

    const handleMenuOpen = (e) => {
      if (disabled) {
        return
      }
      if (!showSearch) {
        e.preventDefault()
        e.stopPropagation()
      }
      setMenuOpen(true)
      updateIS(true)

      let hasMouseMoved = false
      window.addEventListener(
        'click',
        () => {
          if (hasMouseMoved) {
            menuRef.current.selectActiveOption()
          }
        },
        { capture: true, once: true }
      )
      setTimeout(() => {
        window.addEventListener(
          'mousemove',
          () => {
            hasMouseMoved = true
          },
          { capture: true, once: true }
        )
      }, 100)
    }

    const preventDefaultAndStopPropagation = (e) => {
      e.preventDefault()
      e.stopPropagation()
    }

    const handleKeyDown = (e) => {
      if (disabled) {
        preventDefaultAndStopPropagation(e)
        return
      }

      const inputElement = e.currentTarget.querySelector('input')
      const keysOpenSelect = [' ', 'Enter', 'ArrowUp', 'ArrowDown']
      const keysTriggerMenu = ['ArrowUp', 'ArrowDown', 'Home', 'End', 'Enter', 'Tab']
      const isLetterKey = /^[a-zA-Z]$/.test(e.key)

      if (!showSearch) {
        if (keysOpenSelect.includes(e.key) || e.key === 'Escape') {
          setMenuOpen(e.key !== 'Escape')
          e.key === 'Escape' && e.target.blur()
          preventDefaultAndStopPropagation(e)
        }
      } else {
        if (isUndoOrRedoKeyPressed(e) && !isOnComposition) {
          e.preventDefault()
          if (e.shiftKey) {
            redo(search, (lastValue) => setSearch(lastValue))
          } else {
            undo(search, (lastValue) => setSearch(lastValue))
          }
        }
        if (!menuOpen) {
          if (keysOpenSelect.includes(e.key)) {
            inputElement?.focus()
            preventDefaultAndStopPropagation(e)
          } else if (isLetterKey) {
            inputElement?.focus()
          } else if (e.key === 'Escape') {
            e.target.blur()
          }
        } else {
          let activeIndex = menuRef.current?.getActiveIndex()
          activeIndex = activeIndex ?? -1

          if (keysTriggerMenu.includes(e.key) && activeIndex >= 0) {
            const newEvent = new KeyboardEvent(e.type, e.nativeEvent)
            menuRef.current && menuRef.current.onKeyDown(newEvent)
          }

          switch (e.key) {
            case 'Home':
            case 'End':
              preventDefaultAndStopPropagation(e)
              break
            case 'Enter':
              if (!isOnComposition) {
                setSearch('')
              }
              break
            case 'Escape':
              e.stopPropagation()
              setMenuOpen(false)
              e.currentTarget.blur()
              e.target.blur()
              break
            case 'Backspace':
              if (!isOnComposition) initiateDelete(e.target.value)
              break
          }
        }
      }
    }

    const handleKeyUp = (e) => {
      if (e.key === 'Backspace' && !isOnComposition) {
        finalizeDelete()
      }
    }

    const handlePaste = () => {
      commitChange(search)
    }

    const handleCut = () => {
      commitChange(search)
    }

    const handleCompositionStart = () => {
      commitChange(search)
      setIsOnComposition(true)
    }

    const handleCompositionEnd = () => {
      setIsOnComposition(false)
    }

    const handleSelect = (opt) => {
      ref.current.blur()
      onChange(opt.value)
      updateIS(false)
    }

    const handleFocus = (e) => {
      if (onFocus) {
        onFocus(e)
      }
      updateIS(true)
    }

    const handleBlur = (e) => {
      clearUndoHistory()
      if (onBlur) {
        onBlur(e)
      }
      updateIS(false)
    }

    const onChangeOpen = useCallback((flag) => {
      setMenuOpen(flag)
    }, [])

    const Select = variant === 'flat' ? FlatSelect : DefaultSelect

    const onChangeSearch = useCallback(
      (search, prevValue) => {
        if (prevValue !== undefined && !isOnComposition) updateUndoHistory(prevValue)
        setSearch(search)
      },
      [updateUndoHistory, isOnComposition]
    )

    useEffect(() => {
      const inputElement = ref.current
      const handleBeforeInput = (e) => {
        if (e.inputType === 'historyUndo' || e.inputType === 'historyRedo') {
          e.preventDefault()
        }
      }
      inputElement?.addEventListener('beforeinput', handleBeforeInput, true)
      return () => {
        inputElement?.removeEventListener('beforeinput', handleBeforeInput, true)
      }
    }, [])

    return (
      <>
        <Select
          ref={ref}
          selected={selectedOption}
          disabled={disabled}
          mixed={mixed}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onMouseDown={handleMenuOpen}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          showSearch={!!showSearch}
          openMenu={menuOpen}
          search={search}
          onChangeSearch={onChangeSearch}
          onPaste={handlePaste}
          onCut={handleCut}
          onCompositionStart={handleCompositionStart}
          onCompositionEnd={handleCompositionEnd}
          dataTestId={dataTestId}
          {...rest}
        />
        <Menu
          onOpenChange={onChangeOpen}
          open={menuOpen}
          ref={menuRef}
          trigger={ref}
          options={options}
          selectedValues={[selectedOption?.value]}
          customMenuOptionRender={optionRender}
          onSelect={handleSelect}
          showSearch={!!showSearch}
          focusOnSelect={focusOnSelect}
          searchValue={search}
          type="select"
          className="select-menu"
        />
      </>
    )
  }
)

Select.displayName = 'Select'

Select.propTypes = {
  variant: PropTypes.oneOf(['normal', 'flat']),
  mixed: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.array,
  size: PropTypes.oneOf(['s', 'l']),
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  optionRender: PropTypes.func,
  noIS: PropTypes.bool,
  error: PropTypes.string,
  keyFrameIconProps: PropTypes.object,
  showSearch: PropTypes.bool,
  placeholder: PropTypes.string,
  hideFocusStyle: PropTypes.bool,
  focusOnSelect: PropTypes.bool,
  dataTestId: PropTypes.string
}

Select.defaultProps = {
  variant: 'flat',
  mixed: false,
  disabled: false,
  options: [],
  size: 's',
  noIS: false
}

export default React.memo(Select)
