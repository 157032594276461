import { Screen } from '@phase-software/data-store/src/Screen'
import Stats from '@phase-software/data-utils/src/Stats'
// import * as Sentry from '@sentry/react'
import IS from './input-system'
import { VisualServer } from './visual_server/VisualServer'
import { initPanes, updatePanes } from './panes'
import { Vector2, Color } from './math'
import * as actions from './actions'
import {
    findTopMostElementAt,
    clearScreenNameHitTest,
    updateHitTest
} from './actions/selection'
import {
    clearAllControllers
} from './update-controller'
import { takeSnapshotAsBlob } from './utils/snapshot'
import Status from './status'
import { dinoScooter } from './dino'
import { WASM, initWasm } from './wasm/platforms/wasm'
import { bank, idMap } from './utils/node'
import { MP4Encoder } from './actions/export_media/mp4'
import { GIFEncoder } from './actions/export_media/gif'


/** @typedef {import('@phase-software/data-store/src/Workspace').Workspace} Workspace */
/** @typedef {import('@phase-software/data-store/src/DataStore').DataStore} DataStore */
/** @typedef {import('./visual_server/RenderItem').RenderItem} RenderItem */
/** @typedef {import('@phase-software/data-store/src/layer/ComputedLayer').ComputedLayer} ComputedLayer */
/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('./overlay/Overlay').Overlay} Overlay */
/** @typedef {import('./visual_server/VisualStorage').VisualStorage} VisualStorage */



let frameCounter = 0
// Disable Sentry snapshot integration for now
// const sentryCPF = 500 // capture the canvas once every 500 frames
let visible = false

/**
 * There will be only one instance of `VisualServer` during
 * the whole app life time
 * @type {VisualServer}
 */
let VS = null
/**
 * @param {HTMLCanvasElement} canvas
 * @param {DataStore} dataStore
 * @param {boolean} useLowDPR
 */
export async function init(canvas, dataStore, useLowDPR = false) {
    await initWasm(canvas, useLowDPR)
    WASM().init()

    VS = new VisualServer(canvas, dataStore, useLowDPR)

    initPanes(VS)

    IS.watch(canvas)
    actions.init(VS)

    initRootListeners()

    VS.indexer.connectDataStore(dataStore)
    VS.selection.watchDSSelection()

    await load()
    dinoScooter.init(VS)
    // render()
    loopA()
}

function loadStart() {
    Status.pause()
}

async function load() {
    IS.pause()

    await VS.dataStore.sync()

    IS.resume()

    Status.updateRootDirty(true)
    updateWorkspaceBackground()

    Status.resume()

    visible = true
}

export function resize() {
    Status.updateResizeDirty(true)
}

/**
 * Moves viewport to center on selection
 */
export function centerSelection() {
    actions.centerSelection()
}

/**
 * @param {number} x
 * @param {number} y
 * @returns {Vector2}
 */
export const toWorld = (x, y) => {
    if (VS) {
        const v = new Vector2(x, y)
        const w = VS.viewport.toWorld(v)
        return w
    } else {
        return new Vector2(x, y)
    }
}

export function cleanupRenderer() {
    clearScreenNameHitTest()
    clearAllControllers()
    if (VS) VS.clear()

    Status.pause()

    visible = false

    idMap.clear()
    bank.geo_bank_id = 1
    WASM()?.cleanup()
}


/* Implementation */

function loopA() {
    render()
    requestAnimationFrame(loopB)
}

function loopB() {
    render()
    requestAnimationFrame(loopA)
}

function render() {
    checkRendererReadyState()

    if (!Status.paused && WASM().tick()) {
        const focusContent = setRoot()
        VS.viewport.resize()

        dinoScooter.update()

        Stats.begin("node update")
        const updateList = [...VS.updateList.values()]
        VS.indexer.updateNodes(updateList)
        VS.updateList.clear()
        Stats.end("node update")

        Stats.begin("hit test")
        updateHitTest()
        Stats.end("hit test")

        Stats.begin("misc update")
        VS.selection.updateBounds()

        if (focusContent) focusContent()

        actions.update()
        VS.storage.update()
        Stats.end("misc update")

        if (visible) {
            const { x, y, scale, pixelRatio } = VS.viewport
            WASM().setViewport(x * pixelRatio, y * pixelRatio, scale * pixelRatio)

            Stats.begin("overlay update")
            updatePanes()
            Stats.end("overlay update")

            Stats.begin("draw nodes")
            WASM().prepareGroups()
            VS.drawNodes()
            Stats.end("draw nodes")

            Stats.begin("draw overlay")
            Stats.end("draw overlay")

            WASM().draw()
        }
    }

    // Disable Sentry snapshot integration for now
    // if (frameCounter % sentryCPF === 0) {
    //     const canvasRef = document.querySelector("#renderer-canvas")
    //     const client = Sentry.getClient()
    //     if (client){
    //         client.getIntegrationByName("ReplayCanvas")
    //             .snapshot(canvasRef)
    //     }
    // }
    frameCounter++

    // requestAnimationFrame(render)
}

function checkRendererReadyState() {
    if (frameCounter === 10) {
        // create a div with ready state on a page
        const g = document.createElement('div')
        g.setAttribute('data-test-id', 'rendererReady')
        document.getElementById('modal').appendChild(g)
    }
}

function initRootListeners() {
    const { dataStore, viewport } = VS

    dataStore.on('LOAD-START', loadStart)
    dataStore.on('LOAD', load)

    const setDirtyIfInPreview = () => {
        if (dataStore.get('state') === 'PREVIEW') {
            Status.updateRootDirty(true)
        }
    }

    dataStore.on('preview', setDirtyIfInPreview)
    dataStore.on('previewZoom', setDirtyIfInPreview)
    viewport.on('resize', setDirtyIfInPreview) // because we need to refocus the content on resize

    dataStore.workspace.on('CHANGE-WATCH', () => {
        Status.updateRootDirty(true)
    })

    dataStore.workspace.on('CHANGE-WATCH', updateWorkspaceBackground)

    // listen to zoom changes
    dataStore.workspace.on('scale', scale => {
        viewport.setZoom(scale)
    })

    // set workspace viewport zoom and position
    viewport.on('update', () => {
        dataStore.workspace.sets({
            scale: viewport.scale,
            panX: viewport.x,
            panY: viewport.y
        })
    })
}

function updateWorkspaceBackground() {
    const isDark = !!VS.dataStore.workspace.children.find(c => c instanceof Screen)
    // if workspace contains Screens, background needs to be dark
    const bgColor = Color.hex(isDark ? 0x0D0C0C : 0xFFFFFF)
    VS.setBackgroundColor(bgColor)
}

/**
 * @returns {() => void} focusContent function
 */
function setRoot() {
    if (!Status.dirty.root) return
    Status.updateRootDirty(false)
    // force a resize check before focusing content
    Status.updateResizeDirty(true)

    /** @type {import('@phase-software/data-store/src/DataStore').State} */
    const state = VS.dataStore.get('state')
    switch (state) {
        case 'PREVIEW': {
            const root = VS.dataStore.get('preview').get('id')
            VS.setRoot(root)

            return () => actions.focusPreviewContent()
        }

        case 'EDITING':
        case 'VIEWING':
        case 'VERSIONING': {
            const prevRoot = VS.root
            const root = VS.dataStore.workspace.get('id')
            VS.setRoot(root)
            // if it is not first time setting root, then we will not focus content
            if (prevRoot) return
            return () => actions.focusContent()
        }
    }
}

export {
    IS,
    findTopMostElementAt,
    takeSnapshotAsBlob,
    MP4Encoder,
    GIFEncoder
}
