import React from 'react'
import PropTypes from 'prop-types'
import DataStoreProvider from '../../providers/dataStore/DataStoreProvider'
import DocumentProvider from '../../providers/dataStore/DocumentProvider'
import InteractionProvider from '../../providers/dataStore/InteractionProvider'
import WorkspaceProvider from '../../providers/dataStore/WorkspaceProvider'
import ElementProvider from '../../providers/dataStore/ElementProvider'
import LayerProvider from '../../providers/dataStore/LayerProvider'
import PaintProvider from '../../providers/dataStore/PaintProvider'
import ImageProvider from '../../providers/dataStore/ImageProvider'
import FillProvider from '../../providers/dataStore/FillProvider'
import StrokeProvider from '../../providers/dataStore/StrokeProvider'
import ShadowProvider from '../../providers/dataStore/ShadowProvider'
import InnerShadowProvider from '../../providers/dataStore/InnerShadowProvider'
import EffectProvider from '../../providers/dataStore/EffectProvider'
import TransitionProvider from '../../providers/dataStore/TransitionProvider'
import FontsProvider from '../../providers/dataStore/FontsProvider'
import FontProvider from '../../providers/dataStore/FontProvider'
import TextDecorationProvider from '../../providers/dataStore/TextDecorationProvider'
import TextSpacingProvider from '../../providers/dataStore/TextSpacingProvider'
import TextDirectionProvider from '../../providers/dataStore/TextDirectionProvider'
import TextAlignmentProvider from '../../providers/dataStore/TextAlignmentProvider'
import ToolProvider from '../../providers/dataStore/ToolProvider'
import ElementSelectionProvider from '../../providers/dataStore/ElementSelectionProvider'
import KeyframeSelectionProvider from '../../providers/dataStore/KeyframeSelectionProvider'
import TransitionManagerProvider from '../../providers/TransitionManagerProvider'
import FlattenElementListProvider from '../../providers/dataStore/FlattenElementListProvider'
import ExportImagePresetProvider from '../../providers/dataStore/ExportImagePresetProvider'
import EditorProvider from '../../providers/dataStore/EditorProvider'
import ClipboardProvider from '../../providers/dataStore/ClipboardProvider'
import SubscribeFontLoad from './SubscribeFontLoad'
import SubscribeDataStoreLoad from './SubscribeDataStoreLoad'
import SubscribeWorkspaceSelectionChange from './SubscribeWorkspaceSelectionChange'
import SubscribeElementSelectionChange from './SubscribeElementSelectionChange'
import SubscribeKeyframeSelectionChange from './SubscribeKeyframeSelectionChange'
import SubscribeToolChange from './SubscribeToolChange'
import SubscribeModeChange from './SubscribeModeChange'
import SubscribeEamEvent from './SubscribeEamEvent'

const Providers = ({ dataStore, children }) => {
  return (
    <>
      <DataStoreProvider value={dataStore} />
      <DocumentProvider />
      <InteractionProvider />
      <WorkspaceProvider />
      <ElementProvider />
      <LayerProvider />
      <PaintProvider />
      <ImageProvider />
      <FillProvider />
      <StrokeProvider />
      <ShadowProvider />
      <EditorProvider />
      <InnerShadowProvider />
      <EffectProvider />
      <FontsProvider />
      <FontProvider />
      <TextDecorationProvider />
      <TextSpacingProvider />
      <TextDirectionProvider />
      <TextAlignmentProvider />
      <ToolProvider />
      <ElementSelectionProvider />
      <KeyframeSelectionProvider />
      <FlattenElementListProvider />
      <TransitionProvider />
      <TransitionManagerProvider />
      <ExportImagePresetProvider />
      <ClipboardProvider />
      <SubscribeFontLoad />
      <SubscribeDataStoreLoad />
      <SubscribeWorkspaceSelectionChange />
      <SubscribeElementSelectionChange />
      <SubscribeKeyframeSelectionChange />
      <SubscribeToolChange />
      <SubscribeModeChange />
      <SubscribeEamEvent />
      {children}
    </>
  )
}

Providers.propTypes = {
  dataStore: PropTypes.object,
  children: PropTypes.node
}

export default Providers
