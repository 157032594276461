import React, { ComponentPropsWithoutRef, forwardRef, useRef, useState } from 'react'

import { Button } from '..'
import TextArea from '../TextArea'
import { CommentType } from './types'

export interface CommentTextAreaProps extends ComponentPropsWithoutRef<'textarea'> {
  type: CommentType
  height?: number
  placeholder?: string
  defaultValue?: string
  onChangeValue?: (value: string) => void
  onInputSubmit?: (value: string) => void
  onCancel?: () => void
  onEscape?: () => void
  className?: string
  rows?: number
  disabled?: boolean
  error?: string
  autoFocus?: boolean
  maxScrollHeight?: number
  variant?: 'reply' | 'create' | 'edit'
  dataTestId?: string
}

const CommentTextArea = forwardRef<HTMLDivElement, CommentTextAreaProps>(
  (
    {
      type,
      height,
      placeholder,
      defaultValue = '',
      onInputSubmit,
      onEscape,
      onCancel,
      rows,
      disabled,
      error,
      autoFocus = false,
      maxScrollHeight,
      variant = 'reply',
      className,
      dataTestId
    },
    forwardedRef
  ) => {
    const [hasContent, setHasContent] = useState(!!defaultValue)
    const [isActive, setIsActive] = useState(false)
    const [inputValue, setInputValue] = useState(defaultValue)
    const clearInputFunctionRef = useRef<() => void>()

    const isPanelType = type === CommentType.PANEL

    const handleCustomKeyDown = (
      e: React.KeyboardEvent<HTMLTextAreaElement>,
      value: string,
      textAreaRef: React.RefObject<HTMLTextAreaElement>,
      isComposing: boolean,
      setTextAreaInputValue: (value: string) => void
    ) => {
      if (e.key === 'Enter') {
        if (!e.shiftKey && value.trimEnd() && !isComposing) {
          e.stopPropagation()
          e.preventDefault()
          if (onInputSubmit) {
            onInputSubmit(value.trimEnd())
            handleClearInput()
          }
          return
        }
        if (e.shiftKey || e.metaKey || e.ctrlKey || e.altKey) {
          e.stopPropagation()
          e.preventDefault()
          const newValue = value + '\n'
          setTextAreaInputValue(newValue)
        }
      }
      if (e.key === 'Escape') {
        e.stopPropagation()
        e.preventDefault()
        setIsActive(false)
        textAreaRef.current?.blur()
        onEscape?.()
      }
    }

    const handleClickSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()
      if (onInputSubmit) {
        onInputSubmit(inputValue.trimEnd())
        handleClearInput()
      }
    }

    const renderContent = (): React.ReactNode => {
      switch (variant) {
        case 'reply':
          if (isActive || hasContent) {
            return (
              <div className="flex flex-row-reverse px-8 pb-8 pt-2 gap-8 items-center cursor-text">
                <div>
                  <Button onClick={handleClickSubmit} size="s" icon="ArrowUp" disabled={!hasContent} tabIndex={-1} />
                </div>
                {/* TODO: [Commenting] When the reaction feature is to be implemented, it needs to be added back. */}
                {/* <Tooltip content="Add reaction">
                  <Icon name="Emoji" size="l" onClick={handleClickReaction} />
                </Tooltip> */}
              </div>
            )
          }
          return null
        case 'create':
          return (
            <div className="flex flex-row-reverse px-8 pb-8 pt-2 gap-8 items-center cursor-text">
              <div className={`${isPanelType && !isActive && !hasContent ? 'invisible' : ''}`}>
                <Button onClick={handleClickSubmit} size="s" icon="ArrowUp" disabled={!hasContent} tabIndex={-1} />
              </div>
              {/* TODO: [Commenting] When the reaction feature is to be implemented, it needs to be added back. */}
              {/* <Tooltip content="Add emoji">
                <Icon
                  name="Emoji"
                  size="l"
                  className={`${isPanelType && (isActive || hasContent || 'invisible')}`}
                  onClick={handleClickReaction}
                />
              </Tooltip> */}
            </div>
          )
        case 'edit':
          return (
            <div className="flex px-8 pb-8 pt-2 gap-8 justify-end items-center cursor-text">
              {/* TODO: [Commenting] When the reaction feature is to be implemented, it needs to be added back. */}
              {/* <Tooltip content="Add emoji">
                <Icon name="Emoji" size="l" onClick={handleClickReaction} />
              </Tooltip> */}
              <div>
                <Button onClick={onCancel} size="s" color="secondary" icon="Cross" tabIndex={-1} />
              </div>
              <div>
                <Button onClick={handleClickSubmit} size="s" icon="Check" disabled={!hasContent} tabIndex={-1} />
              </div>
            </div>
          )

        default:
          return null
      }
    }

    const handleChange = (value: string): void => {
      setHasContent(!!value.trimEnd())
      setInputValue(value)
    }

    const handleClickBottomWrapper = (ref: React.RefObject<HTMLTextAreaElement>): void => {
      ref.current?.focus()
    }

    const setClearInputFunction = (clearFunc: any) => {
      clearInputFunctionRef.current = clearFunc
    }

    const handleClearInput = () => {
      if (clearInputFunctionRef.current) {
        clearInputFunctionRef.current()
      }
    }

    const handleWrapperClick = (e: React.MouseEvent) => {
      e.stopPropagation()
    }

    return (
      <div className="w-full" ref={forwardedRef} onClick={handleWrapperClick}>
        <TextArea
          variant={variant === 'create' && !isPanelType ? 'flat' : 'default'}
          autoFocus={autoFocus}
          placeholder={placeholder}
          defaultValue={defaultValue}
          height={height}
          rows={rows}
          disabled={disabled}
          error={error}
          className={`flex-col cursor-text ${isPanelType && 'bg-light-overlay-10'} ${className}`}
          maxScrollHeight={maxScrollHeight}
          minHeight={16}
          bottomComponent={renderContent()}
          onChangeValue={handleChange}
          onTextAreaFocus={() => setIsActive(true)}
          onClickOutside={() => setIsActive(false)}
          handleCustomKeyDown={handleCustomKeyDown}
          handleClickBottomWrapper={handleClickBottomWrapper}
          exposeClearInputMethod={setClearInputFunction}
          size="s"
          dataTestId={dataTestId}
          clearUndoHistoryOnBlur={false}
        />
      </div>
    )
  }
)

CommentTextArea.displayName = 'CommentTextArea'

export default CommentTextArea
