// import { Unit, ImageOrigin, ImageTileMode } from '@phase-software/types'
import Stats from '@phase-software/data-utils/src/Stats'
import { PaintType } from '@phase-software/types'
import { Transform2D, Vector2, Color, Rect2 } from '../math'
// import { StaticArray } from '../utils/array'
// import { VisualStorage } from '../visual_server/VisualStorage'
// import {
//     Gfx_PassAction,
//     Gfx_Bindings,
//     Gfx_VertexFormat,
//     Gfx_UniformType,
//     Gfx_ShaderStage,
//     Gfx_PrimitiveType,
//     Gfx_BlendFactor,
//     Gfx_BlendOp,
//     Gfx_PixelFormat,
//     Gfx_ImageType,
//     Gfx_CompareFunc,
//     Gfx_StencilOp,
//     Gfx_Usage,
//     Gfx_BufferType,
//     Gfx_MAX_COLOR_ATTACHMENTS,
// } from "../gfx"
import {
    // CommandFill,
    // CommandPopAndBlitWithBlend,
    // CommandPopAndBlitWithOpacity,
    // CommandPopAsMask,
    // CommandPopMaskAndFree,
    // CommandPush,
    // CommandPushFillAsMask,
    RenderCommandTypes,
} from '../visual_server/RenderCommand'
import {
    WASM, allocTempFloat32Array, getFloat32Array,
    getRenderCommandsPtr, getRenderGroupsPtr,
    getSnapshotGroupsPtr, getSnapshotCommandsPtr
} from '../wasm/platforms/wasm'
import { ScaleLevelOfZoom } from './zoom'
// import vs_Solid from './shader/fill_solid.vert'
// import fs_Solid from './shader/fill_solid.frag'
// import vs_Solid_gl2 from './shader/fill_solid_webgl2.vert'
// import fs_Solid_gl2 from './shader/fill_solid_webgl2.frag'
// import fs_MaskedSolid from './shader/fill_solid_mask.frag'
// import fs_MaskedSolid_gl2 from './shader/fill_solid_mask_webgl2.frag'
// import vs_Gradient from './shader/gradient/fill_gradient.vert'
// import vs_Gradient_gl2 from './shader/gradient/fill_gradient_webgl2.vert'
// import fs_Linear from './shader/gradient/fill_gradient_linear.frag'
// import fs_Linear_gl2 from './shader/gradient/fill_gradient_linear_webgl2.frag'
// import fs_MaskedLinear from './shader/gradient/fill_gradient_mask_linear.frag'
// import fs_MaskedLinear_gl2 from './shader/gradient/fill_gradient_mask_linear_webgl2.frag'
// import fs_Radial from './shader/gradient/fill_gradient_radial.frag'
// import fs_Radial_gl2 from './shader/gradient/fill_gradient_radial_webgl2.frag'
// import fs_MaskedRadial from './shader/gradient/fill_gradient_mask_radial.frag'
// import fs_MaskedRadial_gl2 from './shader/gradient/fill_gradient_mask_radial_webgl2.frag'
// import fs_Angular from './shader/gradient/fill_gradient_angular.frag'
// import fs_Angular_gl2 from './shader/gradient/fill_gradient_angular_webgl2.frag'
// import fs_MaskedAngular from './shader/gradient/fill_gradient_mask_angular.frag'
// import fs_MaskedAngular_gl2 from './shader/gradient/fill_gradient_mask_angular_webgl2.frag'
// import fs_Diamond from './shader/gradient/fill_gradient_diamond.frag'
// import fs_Diamond_gl2 from './shader/gradient/fill_gradient_diamond_webgl2.frag'
// import fs_MaskedDiamond from './shader/gradient/fill_gradient_mask_diamond.frag'
// import fs_MaskedDiamond_gl2 from './shader/gradient/fill_gradient_mask_diamond_webgl2.frag'
// import vs_Image from './shader/fill_image.vert'
// import fs_Image from './shader/fill_image.frag'
// import vs_Image_gl2 from './shader/fill_image_webgl2.vert'
// import fs_Image_gl2 from './shader/fill_image_webgl2.frag'
// import vs_BoxShadow from './shader/box_shadow.vert'
// import fs_BoxShadow from './shader/box_shadow.frag'
// import vs_BoxShadow_gl2 from './shader/box_shadow_webgl2.vert'
// import fs_BoxShadow_gl2 from './shader/box_shadow_webgl2.frag'
// import fs_MaskedBoxShadow from './shader/masked_box_shadow.frag'
// import fs_MaskedBoxShadow_gl2 from './shader/masked_box_shadow_webgl2.frag'
// import vs_Blur from './shader/blur.vert'
// import fs_Blur from './shader/blur.frag'
// import vs_Blur_gl2 from './shader/blur_webgl2.vert'
// import fs_Blur_gl2 from './shader/blur_webgl2.frag'
// import vs_AlphaMaskBlit from './shader/alpha_clip.vert'
// import fs_AlphaMaskBlit from './shader/alpha_clip.frag'
// import vs_AlphaMaskBlit_gl2 from './shader/alpha_clip_webgl2.vert'
// import fs_AlphaMaskBlit_gl2 from './shader/alpha_clip_webgl2.frag'
// import vs_BlitWithOpacity from './shader/blit_with_opacity.vert'
// import fs_BlitWithOpacity from './shader/blit_with_opacity.frag'
// import vs_BlitWithOpacity_gl2 from './shader/blit_with_opacity_webgl2.vert'
// import fs_BlitWithOpacity_gl2 from './shader/blit_with_opacity_webgl2.frag'

// Import all the blendmode shaders
// import vs_BlendMode from './shader/blendmode/blendmode.vert'
// import vs_BlendMode_gl2 from './shader/blendmode/blendmode_webgl2.vert'
// import fs_normal from './shader/blendmode/normal.frag'
// import fs_normal_gl2 from './shader/blendmode/normal_webgl2.frag'
// import fs_darken from './shader/blendmode/darken.frag'
// import fs_darken_gl2 from './shader/blendmode/darken_webgl2.frag'
// import fs_multiply from './shader/blendmode/multiply.frag'
// import fs_multiply_gl2 from './shader/blendmode/multiply_webgl2.frag'
// import fs_color_burn from './shader/blendmode/color_burn.frag'
// import fs_color_burn_gl2 from './shader/blendmode/color_burn_webgl2.frag'
// import fs_lighten from './shader/blendmode/lighten.frag'
// import fs_lighten_gl2 from './shader/blendmode/lighten_webgl2.frag'
// import fs_screen from './shader/blendmode/screen.frag'
// import fs_screen_gl2 from './shader/blendmode/screen_webgl2.frag'
// import fs_color_dodge from './shader/blendmode/color_dodge.frag'
// import fs_color_dodge_gl2 from './shader/blendmode/color_dodge_webgl2.frag'
// import fs_overlay from './shader/blendmode/overlay.frag'
// import fs_overlay_gl2 from './shader/blendmode/overlay_webgl2.frag'
// import fs_soft_light from './shader/blendmode/soft_light.frag'
// import fs_soft_light_gl2 from './shader/blendmode/soft_light_webgl2.frag'
// import fs_hard_light from './shader/blendmode/hard_light.frag'
// import fs_hard_light_gl2 from './shader/blendmode/hard_light_webgl2.frag'
// import fs_difference from './shader/blendmode/difference.frag'
// import fs_difference_gl2 from './shader/blendmode/difference_webgl2.frag'
// import fs_exclusion from './shader/blendmode/exclusion.frag'
// import fs_exclusion_gl2 from './shader/blendmode/exclusion_webgl2.frag'
// import fs_hue from './shader/blendmode/hue.frag'
// import fs_hue_gl2 from './shader/blendmode/hue_webgl2.frag'
// import fs_saturation from './shader/blendmode/saturation.frag'
// import fs_saturation_gl2 from './shader/blendmode/saturation_webgl2.frag'
// import fs_color from './shader/blendmode/color.frag'
// import fs_color_gl2 from './shader/blendmode/color_webgl2.frag'
// import fs_luminosity from './shader/blendmode/luminosity.frag'
// import fs_luminosity_gl2 from './shader/blendmode/luminosity_webgl2.frag'

/** @typedef {import('../gfx').Gfx} Gfx */
/** @typedef {import('../gfx').Gfx_Image_t} Gfx_Image_t */
/** @typedef {import('../gfx').Gfx_Buffer_t} Gfx_Buffer_t */
/** @typedef {import('../gfx').Gfx_Pass_t} Gfx_Pass_t */
/** @typedef {import('../gfx').Gfx_Pipeline_t} Gfx_Pipeline_t */
/** @typedef {import('../visual_server/RenderItem').RenderItem} RenderItem */
/** @typedef {import('../resources/GradientResource').GradientResource} GradientResource */
/** @typedef {import('../resources/ImageResource').ImageResource} ImageResource */
/** @typedef {import('../resources/VectorResource').VectorResource} VectorResource */
/** @typedef {import('../resources/TextResource').TextResource} TextResource */
/** @typedef {import('../visual_server/SpatialCache').SpatialCache} SpatialCache */
/** @typedef {import('../visual_server/SpatialCache').QueryResult} QueryResult */
/** @typedef {import('./Tile').Tile} Tile */
/** @typedef {import('./TileCanvas').TileCanvas} TileCanvas */
/** @typedef {import('./MaskFeature').MaskData} MaskData */
/** @typedef {import('./RenderStackFeatureBase').DrawResult} DrawResult */
/** @typedef {import('../visual_server/RenderCommand').RenderCommand} RenderCommand */

/** @type {Gfx} */
// let gfx = null

/* public API */

export const DEBUG_TILE_LOG = false

// /** @type {Record<string, Gfx_Image_t>} */
// const g_ImageTable = Object.create(null)

/**
 * @typedef {'passthrough' | 'normal' | 'darken' | 'multiply' | 'color_burn' | 'lighten' | 'screen' | 'color_dodge' | 'overlay' | 'soft_light' | 'hard_light' | 'difference' | 'exclusion' | 'hue' | 'saturation' | 'color' | 'luminosity'} BlendModes
 */

const DefaultGradientTransform = new Transform2D(
    0, -1,
    1, 0,
    0, 1
)

export function Layer() {
    this.visible = true

    this.bounds = new Rect2()

    /** @type {Paint_t} */
    this.paint = null

    /** @type {Effect_t} */
    this.effect = null

    /** @type {BlendModes} */
    this.blendMode = 'passthrough'

    /** @type {VisualStorage} */
    this.storage = null
}
Layer.prototype = {
    constructor: Layer,

    clear() {
        this.visible = true

        this.vectorData = null
        this.textData = null

        this.bounds.set(0, 0, 0, 0)

        if (this.paint) {
            if (this.paint.gradient) {
                // console.log('destroy gradient')
                this.storage.destroyGradientResource(this.paint.gradient.id)
            }
            this.paint = null
        }

        this.effect = null

        this.blendMode = 'passthrough'

        this.storage = null

        return true
    },

    /* fill */

    /**
     * @param {Color} color
     * @param {number} opacity
     * @returns {this}
     */
    solid(color, opacity) {
        const paint = this.paint || new Paint_t()
        this.paint = paint

        paint.type = PaintType.SOLID

        paint.params.opacity = paint.params.opacity || [1]
        paint.params.opacity[0] = opacity

        paint.params.fill_color = color.as_array(paint.params.fill_color || [1, 1, 1, 1])
        paint.params.fill_color[3] = 1

        return this
    },

    /**
     * @param {PaintType} type
     * @param {GradientResource} gradient
     * @param {Transform2D} [transform]
     * @param {number} [opacity]
     * @returns {this}
     */
    gradient(type, gradient, transform = DefaultGradientTransform, opacity = 1.0) {
        const paint = this.paint || new Paint_t()
        this.paint = paint

        paint.type = type

        paint.params.opacity = paint.params.opacity || [1]
        paint.params.opacity[0] = opacity

        paint.gradient = gradient
        paint.transform.copy(transform)

        return this
    },

    /**
     * @param {string} imageID
     * @param {ImageOptions} options
     * @param {number} [opacity]
     * @returns {this}
     */
    image(imageID, options, opacity = 1.0) {
        const paint = this.paint || new Paint_t()
        this.paint = paint

        paint.type = PaintType.IMAGE

        paint.params.opacity = paint.params.opacity || [1]
        paint.params.opacity[0] = opacity

        paint.image = this.storage.getImageResource(imageID)
        paint.imageOptions = options

        return this
    },

    isPaintable() {
        if (!this.paint) return false

        switch (this.paint.type) {
            case PaintType.GRADIENT_LINEAR:
            case PaintType.GRADIENT_RADIAL:
            case PaintType.GRADIENT_ANGULAR:
            case PaintType.GRADIENT_DIAMOND: {
                // return this.paint.gradient && this.paint.gradient.texture_id !== null
                return true
            }
            case PaintType.IMAGE: {
                return this.paint.image && this.paint.image.texture_id !== null
            }
            case PaintType.SOLID:
            default: {
                return true
            }
        }
    },

    /* effect */

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} blur
     * @param {Color} color
     */
    dropShadow(x, y, blur, color) {
        const effect = this.effect || new Effect_t()
        this.effect = effect

        effect.type = 'drop_shadow'

        effect.params.blur = effect.params.blur || [1]
        effect.params.blur[0] = blur

        effect.params.offset = effect.params.offset || { x, y }
        effect.params.offset.x = x
        effect.params.offset.y = y

        effect.params.color = color.as_array(effect.params.color || [1, 1, 1, 1])

        const c = new Color().set_with_array(effect.params.color)
        this.solid(c, effect.params.color[3])
    },

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} blur
     * @param {Color} color
     */
    innerShadow(x, y, blur, color) {
        const effect = this.effect || new Effect_t()
        this.effect = effect

        effect.type = 'inner_shadow'

        effect.params.blur = effect.params.blur || [1]
        effect.params.blur[0] = blur

        effect.params.offset = effect.params.offset || { x, y }
        effect.params.offset.x = x
        effect.params.offset.y = y

        effect.params.color = color.as_array(effect.params.color || [1, 1, 1, 1])
    },
}

/** @type {Record<number, Record<number, Gfx_Buffer_t[]>>} first key: usage; second key: size */
// const pool_buf = {}

// /**
//  * Create a new *vertex* buffer
//  * @param {Gfx} gfx
//  * @param {number} size
//  * @param {Gfx_Usage} [usage]
//  * @returns {Gfx_Buffer_t}
//  */
// function Buffer_New(gfx, size, usage = Gfx_Usage.DYNAMIC) {
//     const no2Size = Num.NearestPowerOf2(size)
//     let pool = pool_buf[usage][no2Size]
//     if (!pool) {
//         pool = []
//         pool_buf[usage][no2Size] = pool
//     }

//     /** @type {Gfx_Buffer_t} */
//     let buf = null
//     for (let i = 0; i < pool.length; i++) {
//         const b = pool[i]
//         if (b.cmn.updateFrameIndex !== gfx.state.frameIndex) {
//             buf = b
//             pool.splice(i, 1)
//             break
//         }
//     }

//     if (!buf) {
//         buf = gfx.makeBuffer({
//             size: no2Size,
//             usage,
//         })
//     }

//     return buf
// }
// /**
//  * @param {Gfx_Buffer_t} buf
//  */
// function Buffer_Free(buf) {
//     if (!pool_buf[buf.cmn.usage]) pool_buf[buf.cmn.usage] = {}
//     let pool = pool_buf[buf.cmn.usage][buf.cmn.size]
//     if (!pool) {
//         pool = []
//         pool_buf[buf.cmn.usage][buf.cmn.size] = pool
//     }

//     pool.push(buf)
// }

/**
 * @typedef {'solid' | 'linear' | 'radial' | 'angular' | 'diamond' | 'image'} PaintTypes
 */
/**
 * @typedef {object} ImageOptions
 * @property {import('@phase-software/types').ImageMode} mode
 * /

/**
 * @typedef {object} ImageOptions_old
 * @property {import('@phase-software/data-store/src/component/ImageComponent.js').ImageOrientation} imageOrientation
 * @property {ImageOrigin} imageOrigin
 * @property {Vector2} imageOffset
 * @property {Unit} imageOffsetUnit
 * @property {Vector2} imageSize
 * @property {Unit} imageSizeUnit
 * @property {boolean} imageAspect
 * @property {boolean} imageContain
 * @property {number} imageRotation
 * @property {boolean} imageTileX
 * @property {boolean} imageTileY
 * @property {Vector2} imageTileGap
 * @property {Unit} imageTileGapUnit
 * @property {ImageTileMode} imageTileModeX
 * @property {ImageTileMode} imageTileModeY
 */

export function Paint_t() {
    /** @type {PaintType} */
    this.type = PaintType.SOLID
    this.params = Object.create(null)

    this.transform = new Transform2D()
    /** @type {GradientResource} */
    this.gradient = null
    /** @type {ImageResource} */
    this.image = null
    /** @type {ImageOptions} */
    this.imageOptions = null
}
Paint_t.prototype = {
    constructor: Paint_t,
    clone() {
        const paint = new Paint_t()

        paint.type = this.type
        paint.params = Object.assign(Object.create(null), this.params)

        paint.transform.copy(this.transform)
        paint.gradient = this.gradient
        paint.image = this.image
        paint.imageOptions = this.imageOptions

        return paint
    },
}

/**
 * @typedef {'layer_blur'|'drop_shadow'|'inner_shadow'} EffectTypes
 */

export function Effect_t() {
    /** @type {EffectTypes} */
    this.type = 'drop_shadow'
    this.params = Object.create(null)
}
Effect_t.prototype = {
    constructor: Effect_t,
    clone() {
        const effect = new Effect_t()

        effect.type = this.type
        effect.params = Object.assign(Object.create(null), this.params)

        return effect
    },
}


/* GAtlas */

const GAtlasSize = 4096
const GAtlasTileSize = 256
const TilesPerGAtlas = (GAtlasSize / GAtlasTileSize) * (GAtlasSize / GAtlasTileSize)

// /** @type {Gfx_Bindings} */
// let bind_DrawAtlasCPU = null
// /** @type {Gfx_Pipeline_t} */
// let pip_DrawAtlasCPU = null



/* tile renderer */

/**
 * @param {Gfx} gfx
 */
export function TileSet() {

    /** @type {number} */
    this.id = WASM().makeTileSet()

    /**
     * @type {Map<string, { col: number, row: number, level: number, cmd: number, version: number, id: string }>} `col.row.level` -> {...}
     */
    this.tiles = new Map()

    /** @type {GAtlas[]} */
    // this.atlases = []

    /** @type {Set<number>} */
    this.freeIDs = new Set() // tile id

    /** @type {number} */
    this.atlasIDOffset = 1
}
TileSet.prototype = {
    constructor: TileSet,

    /**
     * @param {Gfx_Image_t} img
     * @param {number} col
     * @param {number} row
     * @param {number} level
     * @param {number} version
     * @param {number} commandsLength
     * @returns {number}
     */
    // pack(img, col, row, level, version, commandsLength) {
    //     if (this.freeIDs.size === 0) {
    //         const atlas = new GAtlas(this.gfx, this.atlases.length)
    //         this.atlases.push(atlas)

    //         for (let i = atlas.tilePerCol * atlas.tilePerRow - 1; i >= 0; i--) {
    //             this.freeIDs.add((this.atlasIDOffset - 1) + i)
    //         }
    //         this.atlasIDOffset += atlas.tilePerCol * atlas.tilePerRow
    //     }

    //     const id = Array.from(this.freeIDs).pop()
    //     this.freeIDs.delete(id)
    //     const targetAtlas = this.getAtlasOfID(id)
    //     targetAtlas.packImage(this.getAtlasTileLocalID(id), img, false)
    //     this.tiles.set(this.getTileKey(col, row, level), {
    //         col, row, level,
    //         version,
    //         cmd: commandsLength,
    //         id,
    //     })
    //     return id
    // },
    useAFreeingTileId(col, row, level, version) {
        if (this.freeIDs.size === 0) {
            WASM().makeGAtlas()

            const tilesPerAtlas = WASM().getTilesPerAtlas()
            for (let i = tilesPerAtlas - 1; i >= 0; i--) {
                this.freeIDs.add((this.atlasIDOffset - 1) + i)
            }
            this.atlasIDOffset += tilesPerAtlas
        }
        const id = Array.from(this.freeIDs).pop()
        this.freeIDs.delete(id)

        // happen in zig env
        // const targetAtlas = this.getAtlasOfID(id)
        // targetAtlas.packImage(this.getAtlasTileLocalID(id), img, false)

        this.tiles.set(this.getTileKey(col, row, level), {
            col, row, level,
            version,
            id, // tile id (atlas idx, tile local indx)
        })
        return id
    },

    /**
     * @param {Gfx_Image_t} img
     * @param {number} col
     * @param {number} row
     * @param {number} level
     * @param {number} version
     * @param {number} commandsLength
     * @returns {number}
     */
    useAFreeingSnapshotTileId(col, row, level, version) {
        if (this.freeIDs.size === 0) {
            WASM().makeSnapshotGAtlas()

            const tilesPerAtlas = WASM().getTilesPerAtlas()
            for (let i = tilesPerAtlas - 1; i >= 0; i--) {
                this.freeIDs.add((this.atlasIDOffset - 1) + i)
            }
            this.atlasIDOffset += tilesPerAtlas
        }
        const id = Array.from(this.freeIDs).pop()
        this.freeIDs.delete(id)

        // happen in zig env
        // const targetAtlas = this.getAtlasOfID(id)
        // targetAtlas.packImage(this.getAtlasTileLocalID(id), img, false)

        this.tiles.set(this.getTileKey(col, row, level), {
            col, row, level,
            version,
            id, // tile id (atlas idx, tile local indx)
        })
        return id
    },

    /**
     * @param {number} id
     * @returns {GAtlas}
     */
    getAtlasOfID(id) {
        return this.atlases[(id / TilesPerGAtlas) | 0]
    },

    /**
     * @param {number} id
     * @returns {number}
     */
    getAtlasTileLocalID(id) {
        return (id % TilesPerGAtlas) | 0
    },

    /**
     * @param {number} col
     * @param {number} row
     * @param {number} level
     * @returns {string}
     */
    getTileKey(col, row, level) {
        return `${col}.${row}.${level}`
    },

    /**
     * @param {number} col
     * @param {number} row
     * @param {number} level
     * @returns {Tile}
     */
    getTile(col, row, level) {
        return this.tiles.get(this.getTileKey(col, row, level))
    },

    /**
     * @param {number} col
     * @param {number} row
     * @param {number} level
     */
    freeTile(col, row, level) {
        const key = this.getTileKey(col, row, level)
        const tile = this.tiles.get(key)
        if (tile) {
            this.tiles.delete(key)
            this.freeIDs.add(tile.id)
        }
    },
}

/* calculation */

// /**
//  * @param {ImageOptions} options
//  * @param {Rect2} bounds
//  * @param {number} aspectRatio
//  * @returns {{ xform: Transform2D, width: number, height: number }}
//  */
// function getTextureXform(options, bounds, aspectRatio) {
//     let width = options.imageSize.x
//     let height = options.imageSize.y

//     if (options.imageSizeUnit === Unit.PERCENT) {
//         width *= bounds.width / 100
//         height *= bounds.height / 100
//     }

//     if (options.imageAspect) {
//         const _aspectRatio = options.imageOrientation === 1 || options.imageOrientation === 3
//             ? 1 / aspectRatio
//             : aspectRatio

//         const imageAspectRatio = width / height

//         if (options.imageContain) {
//             // Scale down along larger dimension
//             if (imageAspectRatio >= _aspectRatio) {
//                 width = height * _aspectRatio
//             } else {
//                 height = width / _aspectRatio
//             }
//         } else {
//             // Scale up along smaller dimension
//             if (imageAspectRatio <= _aspectRatio) {
//                 width = height * _aspectRatio
//             } else {
//                 height = width / _aspectRatio
//             }
//         }
//     }

//     if (options.imageContain) {
//         if (options.imageAspect) {
//             const ratio = Math.min(bounds.width / width, bounds.height / height)
//             if (ratio < 1) {
//                 width *= ratio
//                 height *= ratio
//             }
//         } else {
//             if (width > bounds.width) {
//                 width = bounds.width
//             }
//             if (height > bounds.height) {
//                 height = bounds.height
//             }
//         }
//     }

//     const OFFSET = new Transform2D()
//     const imageOrigin = options.imageOrigin
//     const dX = bounds.width - width
//     const dY = bounds.height - height

//     if (imageOrigin === ImageOrigin.CENTER) {
//         OFFSET.translate(dX * 0.5, dY * 0.5)
//     } else if (imageOrigin === ImageOrigin.TOP) {
//         OFFSET.translate(dX * 0.5, 0)
//     } else if (imageOrigin === ImageOrigin.TOP_RIGHT) {
//         OFFSET.translate(dX, 0)
//     } else if (imageOrigin === ImageOrigin.RIGHT) {
//         OFFSET.translate(dX, dY * 0.5)
//     } else if (imageOrigin === ImageOrigin.BOTTOM_RIGHT) {
//         OFFSET.translate(dX, dY)
//     } else if (imageOrigin === ImageOrigin.BOTTOM) {
//         OFFSET.translate(dX * 0.5, dY)
//     } else if (imageOrigin === ImageOrigin.BOTTOM_LEFT) {
//         OFFSET.translate(0, dY)
//     } else if (imageOrigin === ImageOrigin.LEFT) {
//         OFFSET.translate(0, dY * 0.5)
//     }

//     {
//         let offsetX = options.imageOffset.x
//         let offsetY = options.imageOffset.y
//         if (options.imageOffsetUnit === Unit.PERCENT) {
//             offsetX *= bounds.width / 100
//             offsetY *= bounds.height / 100
//         }
//         OFFSET.translate(offsetX, offsetY)
//     }

//     OFFSET.tx *= -1
//     OFFSET.ty *= -1

//     const ROT = new Transform2D()
//         .translate(-width * 0.5, -height * 0.5)
//         .rotate(-options.imageRotation)
//         .translate(width * 0.5, height * 0.5)

//     const ORIENT = new Transform2D()
//         .translate(-0.5, -0.5)
//         .rotate(-options.imageOrientation * PI_2)
//         .translate(0.5, 0.5)

//     const T = new Transform2D()
//         .scale(bounds.width, bounds.height)
//         .prepend(OFFSET)
//         .prepend(ROT)
//         .scale(1 / width, 1 / height)
//         .prepend(ORIENT)

//     return { xform: T, width, height }
// }

/* render */

// const tmp_DrawAtlasVerts = new Float32Array((32 * 32) * (4 * 6)) // 32x32 tiles, each tile = (pos + uv) * 6
// /** @type {StaticArray<{ start: number, img: Gfx_Image_t }>} */
// const batchIndexes = new StaticArray()
// const dispSize = new Vector2()

// const vsParam_DrawAtlasCPU = {
//     disp_size: [0, 0, 1],
// }

// const bind_DrawImageNoXform = new Gfx_Bindings()

// /**
//  * Will draw to current pass, make sure correct pass is used.
//  * @param {number[][]} images [id, x, y], this will be modified during rendering.
//  * @param {TileSet} tileset
//  * @param {number} targetWidth
//  * @param {number} targetHeight
//  * @param {number} x
//  * @param {number} y
//  * @param {number} realTileSize
//  * @param {boolean} upsideDown
//  */
// function drawTilemap(images, tileset, targetWidth, targetHeight, x, y, realTileSize, upsideDown = false) {
//     images.sort((a, b) => {
//         if (a[0] < 0) return -1
//         if (b[0] < 0) return +1
//         return tileset.getAtlasOfID(a[0]).id - tileset.getAtlasOfID(b[0]).id
//     })

//     let i = 0

//     // - ignore invalid tiles
//     for (; i < images.length; i++) {
//         if (images[i][0] >= 0) break
//     }

//     batchIndexes.clear()
//     let currAtlasID = -1

//     // - build mesh
//     const vertices = tmp_DrawAtlasVerts
//     const tileU = 1 / 16
//     const tileV = 1 / 16
//     let vertIndex = 0
//     for (; i < images.length; i++) {
//         const t = images[i]

//         const left = Math.floor(t[1] * realTileSize + x)
//         const top = Math.floor(t[2] * realTileSize + y)

//         const id = t[0]
//         const atlasID = Math.floor(id / 256)
//         if (atlasID !== currAtlasID) {
//             currAtlasID = atlasID
//             batchIndexes.add({
//                 start: i * 6,
//                 img: tileset.atlases[atlasID].img,
//             })
//         }

//         const u0 = Math.floor((id % 256) % 16) / 16
//         const v0 = Math.floor((id % 256) / 16) / 16

//         // pos
//         vertices[vertIndex++] = left
//         vertices[vertIndex++] = top
//         // uv
//         vertices[vertIndex++] = u0
//         vertices[vertIndex++] = v0

//         // pos
//         vertices[vertIndex++] = Math.ceil(left + realTileSize)
//         vertices[vertIndex++] = top
//         // uv
//         vertices[vertIndex++] = u0 + tileU
//         vertices[vertIndex++] = v0

//         // pos
//         vertices[vertIndex++] = Math.ceil(left + realTileSize)
//         vertices[vertIndex++] = Math.ceil(top + realTileSize)
//         // uv
//         vertices[vertIndex++] = u0 + tileU
//         vertices[vertIndex++] = v0 + tileV

//         // pos
//         vertices[vertIndex++] = Math.ceil(left + realTileSize)
//         vertices[vertIndex++] = Math.ceil(top + realTileSize)
//         // uv
//         vertices[vertIndex++] = u0 + tileU
//         vertices[vertIndex++] = v0 + tileV

//         // pos
//         vertices[vertIndex++] = left
//         vertices[vertIndex++] = Math.ceil(top + realTileSize)
//         // uv
//         vertices[vertIndex++] = u0
//         vertices[vertIndex++] = v0 + tileV

//         // pos
//         vertices[vertIndex++] = left
//         vertices[vertIndex++] = top
//         // uv
//         vertices[vertIndex++] = u0
//         vertices[vertIndex++] = v0
//     }

//     if (vertIndex <= 0) return

//     gfx.applyPipeline(pip_DrawAtlasCPU)

//     dispSize.set(targetWidth, targetHeight).as_array(vsParam_DrawAtlasCPU.disp_size)
//     vsParam_DrawAtlasCPU.disp_size[2] = upsideDown ? -1 : 1
//     gfx.applyUniforms(Gfx_ShaderStage.VS, 0, vsParam_DrawAtlasCPU)

//     const buf = Buffer_New(gfx, vertIndex * 4, Gfx_Usage.DYNAMIC)
//     gfx.updateBuffer(buf, vertices.subarray(0, vertIndex))
//     bind_DrawAtlasCPU.vertexBuffers[0] = buf

//     i = 0
//     for (let arr = batchIndexes.array, len = batchIndexes.length; i < len; i++) {
//         bind_DrawAtlasCPU.fsImages[0] = arr[i].img
//         gfx.applyBindings(bind_DrawAtlasCPU)

//         const count = (i === len - 1) ? (Math.floor(vertIndex / 4) - arr[i].start) : (arr[i + 1].start - arr[i].start)
//         gfx.draw(arr[i].start, count, 1)
//     }

//     Buffer_Free(buf)
// }

// /**
//  * @param {Tile} tile
//  * @param {TileSet} tileset
//  * @param {number} scale
//  * @param {Color} bgColor
//  */
// let composeTileWithCommands = (tile, tileset, scale, bgColor) => {}

// /**
//  * @param {{ col: number, row: number, level: number }[]} tiles
//  * @param {TileSet} tileset
//  * @param {number} x
//  * @param {number} y
//  * @param {number} w
//  * @param {number} h
//  * @param {number} realTileSize
//  * @param {bool} upsideDown
//  */
// function drawComposedTiles(tiles, tileset, x, y, w, h, realTileSize, upsideDown = false) {
//     /** @type {number[][]} */
//     const cmds = []
//     for (let i = 0, len = tiles.length; i < len; i++) {
//         const tile = tiles[i]
//         const tileID = tileset.getTile(tile.col, tile.row, tile.level)

//         // tile has been redraw but doesn't have valid id?
//         if (!tileID || tileID.id < 0) continue

//         cmds.push([tileID.id, tile.col, tile.row])
//     }
//     drawTilemap(cmds, tileset, w, h, x, y, realTileSize, upsideDown)
// }

export class TileRenderer {
    constructor() {
        /** @type {Color} */
        this.bgColor = new Color()
        /** @type {Vector2} */
        this.offset = new Vector2()
        /** @type {number} */
        this.tileScaleLevel = 0
        /** @type {number} */
        this.projectionScale = 1

        // /** @type {TileSet} */
        // this.tileset = new TileSet()
    }

    clear() {
        return true
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} zoom
     */
    setViewport(x, y, zoom) {
        // const _zoom = Num.clamp(zoom, 0.01, 512)
        const _zoom = zoom

        this.offset.set(x, y)

        // tile at 1 level higher scale, so we draw at higher resolution and scale down
        this.tileScaleLevel = ScaleLevelOfZoom(_zoom)
        if (this.tileScaleLevel < 0) {
            this.tileScaleLevel += 1
        }

        this.projectionScale = _zoom / Math.pow(2, this.tileScaleLevel)
    }

    /**
     * @param {TileSet} tileset
     * @param {number} startCol
     * @param {number} startRow
     * @param {number} endCol
     * @param {number} endRow
     * @param {number} level
     */
    drawTiles(tileset, startCol, startRow, endCol, endRow, level) {
        /** @type {{ col: number, row: number }[]} */
        const tiles = [startRow, endRow, startCol, endCol, level] // nonEmptyTiles
        for (let r = startRow; r <= endRow; r++) {
            for (let q = startCol; q <= endCol; q++) {
                const tile = tileset.getTile(q, r, level)
                if (tile) {
                    tiles.push(tile.id)
                    // console.log(r, q)
                } else {
                    tiles.push(-1)
                }
            }
        }

        const ptr = allocTempFloat32Array(tiles)
        if (ptr) {
            // console.log(tiles)
            WASM().pushTileMap(ptr, tiles.length)
        }
    }

    /**
     * @param {TileSet} tileset
     * @param {number} startCol
     * @param {number} startRow
     * @param {number} endCol
     * @param {number} endRow
     * @param {number} level
     */
    drawSnapshotTiles(tileset, startCol, startRow, endCol, endRow, level) {
        /** @type {{ col: number, row: number }[]} */
        const tiles = [startRow, endRow, startCol, endCol, level] // nonEmptyTiles
        for (let r = startRow; r <= endRow; r++) {
            for (let q = startCol; q <= endCol; q++) {
                const tile = tileset.getTile(q, r, level)
                if (tile) {
                    tiles.push(tile.id)
                    // console.log(r, q)
                } else {
                    tiles.push(-1)
                }
            }
        }

        const ptr = allocTempFloat32Array(tiles)
        if (ptr) {
            // console.log(tiles)
            WASM().pushSnapshotTileMap(ptr, tiles.length)
        }
    }

    /**
     * @typedef RenderGroup
     * @property {number} col
     * @property {number} row
     * @property {number} level
     * @property {number} version
     * @property {RenderCommand[]} commands
     *
     * @param {TileSet} tileset
     * @param {RenderGroup[]} groups
     */
    composeTiles(tileset, groups) {
        const dirtyTiles = this._getDirtyTiles(tileset, groups)

        // compose all dirty tiles
        if (dirtyTiles.length > 0) {
            const rgrps_ptr = getRenderGroupsPtr()
            const rcmds_ptr = getRenderCommandsPtr()
            const rgroups = []
            const rcommands = []

            for (let i=0; i<dirtyTiles.length; i++) {
                const group = dirtyTiles[i]
                rgroups.push(
                    group.col, group.row, group.level,
                    rcommands.length,
                    group.commands.length
                )
                rcommands.push(...group.commands)
            }

            const rgrps_array = getFloat32Array(rgrps_ptr, rgroups.length)
            const rcmds_array = getFloat32Array(rcmds_ptr, rcommands.length)
            rgrps_array.set(rgroups)
            rcmds_array.set(rcommands)

            WASM().updateRenderGroupsLen(rgroups.length)
            WASM().updateRenderCommandsLen(rcommands.length)
            rgroups.length = 0
            rcommands.length = 0

            WASM().composeTiles()

            // console.log("compose: " + dirtyTiles.map(t => `(${t.col},${t.row})@${t.level}`).join(' + '))
            // for (const tile of dirtyTiles) {
            //     this.composeTile(tile, tileset, this.bgColor, this.tileScaleLevel)
            // }
        }
        Stats.log("total number of tile redraw", dirtyTiles.length)
        let numCmdFill = 0, numCmdPushCanvas = 0, numCmdPushFillAsMask = 0,
            numCmdPopMaskAndFree = 0, numCmdPopCanvasAsMask = 0, numCmdPopCanvasAndBlitWithOpacity = 0, numCmdPopCanvasAndBlitWithBlend = 0
        for (const cmd of dirtyTiles.flatMap(tile => tile.commands)) {
            if (!cmd) continue
            switch (cmd.type) {
                case RenderCommandTypes.fill:
                    numCmdFill += 1
                    break
                case RenderCommandTypes.push_canvas:
                    numCmdPushCanvas += 1
                    break
                case RenderCommandTypes.push_fill_as_mask:
                    numCmdPushFillAsMask += 1
                    break
                case RenderCommandTypes.pop_mask_and_free:
                    numCmdPopMaskAndFree += 1
                    break
                case RenderCommandTypes.pop_canvas_as_mask:
                    numCmdPopCanvasAsMask += 1
                    break
                case RenderCommandTypes.pop_canvas_and_blit_with_opacity:
                    numCmdPopCanvasAndBlitWithOpacity += 1
                    break
                case RenderCommandTypes.pop_canvas_and_blit_with_blend:
                    numCmdPopCanvasAndBlitWithBlend += 1
                    break
            }
        }
        Stats.log("total number of fill command", numCmdFill)
        Stats.log("total number of push_canvas command", numCmdPushCanvas)
        Stats.log("total number of push_fill_as_mask command", numCmdPushFillAsMask)
        Stats.log("total number of pop_mask_and_free command", numCmdPopMaskAndFree)
        Stats.log("total number of pop_canvas_as_mask command", numCmdPopCanvasAsMask)
        Stats.log("total number of pop_canvas_and_blit_with_opacity command", numCmdPopCanvasAndBlitWithOpacity)
        Stats.log("total number of pop_canvas_and_blit_with_blend command", numCmdPopCanvasAndBlitWithBlend)
    }

    composeSnapshotTiles(tileset, groups, clearColor) {
        const dirtyTiles = this._getDirtyTiles(tileset, groups)

        // compose all dirty tiles
        if (dirtyTiles.length > 0) {
            const sgrps_ptr = getSnapshotGroupsPtr()
            const scmds_ptr = getSnapshotCommandsPtr()
            const sgroups = []
            const scommands = []

            for (let i=0; i<dirtyTiles.length; i++) {
                const group = dirtyTiles[i]

                sgroups.push(
                    group.col, group.row, group.level,
                    scommands.length,
                    group.commands.length
                )
                scommands.push(...group.commands)
            }

            const sgrps_array = getFloat32Array(sgrps_ptr, sgroups.length)
            const scmds_array = getFloat32Array(scmds_ptr, scommands.length)
            sgrps_array.set(sgroups)
            scmds_array.set(scommands)

            WASM().updateSnapshotGroupsLen(sgroups.length)
            WASM().updateSnapshotCommandsLen(scommands.length)
            sgroups.length = 0
            scommands.length = 0

            WASM().composeSnapshotTiles(clearColor.r, clearColor.g, clearColor.b, clearColor.a)
        }
    }

    _getDirtyTiles(tileset, groups) {
        const dirtyTiles = []
        for (const group of groups) {
            const tile = tileset.getTile(group.col, group.row, group.level)
            if (tile) {
                if (tile.version < group.version) {
                    tileset.freeTile(group.col, group.row, group.level)
                    dirtyTiles.push(group)
                }
            } else {
                dirtyTiles.push(group)
            }
        }
        return dirtyTiles
    }
}

/**
 *
 */
export function CreateTileRenderer() {
    return new TileRenderer()
}

// const ctx = {
//     bind_comp: new Gfx_Bindings(),

//     /** @type {Gfx_Buffer_t} */
//     buf_cover_debug: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_stencil_debug: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_cover_debug: null,

//     /** @type {Gfx_Pipeline_t} */
//     pip_stencil: null,

//     /** @type {Gfx_Pipeline_t} */
//     pip_solid: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_linear: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_radial: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_angular: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_diamond: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_image: null,

//     /** @type {Gfx_Pipeline_t} */
//     pip_masked_solid: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_masked_linear: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_masked_radial: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_masked_angular: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_masked_diamond: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_masked_image: null,

//     /** @type {Gfx_Pipeline_t} */
//     pip_solid_rect: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_linear_rect: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_radial_rect: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_angular_rect: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_diamond_rect: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_image_rect: null,

//     /** @type {Gfx_Pipeline_t} */
//     pip_masked_solid_rect: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_masked_linear_rect: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_masked_radial_rect: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_masked_angular_rect: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_masked_diamond_rect: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_masked_image_rect: null,

//     /** @type {Gfx_Pipeline_t} */
//     pip_rect_shadow: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_masked_rect_shadow: null,
//     /** @type {Gfx_Pipeline_t} */
//     pip_blur: null,

//     /**
//      * @param {PaintTypes} type
//      * @param {boolean} isRect
//      * @param {boolean} hasMask
//      * @returns {Gfx_Pipeline_t}
//      */
//     getPipelineOfType: (type, isRect, hasMask) => {
//         // eslint-disable-next-line no-negated-condition
//         if (!hasMask) {
//             switch (type) {
//                 case 'solid':
//                     return isRect ? ctx.pip_solid_rect : ctx.pip_solid
//                 case 'linear':
//                     return isRect ? ctx.pip_linear_rect : ctx.pip_linear
//                 case 'radial':
//                     return isRect ? ctx.pip_radial_rect : ctx.pip_radial
//                 case 'angular':
//                     return isRect ? ctx.pip_angular_rect : ctx.pip_angular
//                 case 'diamond':
//                     return isRect ? ctx.pip_diamond_rect : ctx.pip_diamond
//                 case 'image':
//                     return isRect ? ctx.pip_image_rect : ctx.pip_image
//                 default: {
//                     console.warn(`No pipeline associated with command ${type}`)
//                     return isRect ? ctx.pip_solid_rect : ctx.pip_solid
//                 }
//             }
//         } else {
//             switch (type) {
//                 case 'solid':
//                     return isRect ? ctx.pip_masked_solid_rect : ctx.pip_masked_solid
//                 case 'linear':
//                     return isRect ? ctx.pip_masked_linear_rect : ctx.pip_masked_linear
//                 case 'radial':
//                     return isRect ? ctx.pip_masked_radial_rect : ctx.pip_masked_radial
//                 case 'angular':
//                     return isRect ? ctx.pip_masked_angular_rect : ctx.pip_masked_angular
//                 case 'diamond':
//                     return isRect ? ctx.pip_masked_diamond_rect : ctx.pip_masked_diamond
//                 case 'image':
//                     return isRect ? ctx.pip_masked_image_rect : ctx.pip_masked_image
//                 default: {
//                     console.warn(`No pipeline associated with command ${type}`)
//                     return isRect ? ctx.pip_masked_solid_rect : ctx.pip_masked_solid
//                 }
//             }
//         }
//     },
// }

export function initTileRendering() {
    // ctx.pip_solid = gfx.makePipeline({
    //     label: 'solid',
    //     shader: gfx.makeShader({
    //         label: 'solid',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Solid,
    //                 webgl2: vs_Solid_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48,
    //                 uniforms: [
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_Solid,
    //                 webgl2: fs_Solid_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 32,
    //                 uniforms: [
    //                     {
    //                         name: "fill_color",
    //                         type: Gfx_UniformType.FLOAT4,
    //                     },
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    //     stencil: {
    //         enabled: true,
    //         ref: 0,
    //         writeMask: 0,
    //         readMask: 0x3F,
    //         front: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //         back: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //     },
    // })
    // ctx.pip_linear = gfx.makePipeline({
    //     label: 'linear',
    //     shader: gfx.makeShader({
    //         label: 'linear',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Gradient,
    //                 webgl2: vs_Gradient_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "uv_xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_Linear,
    //                 webgl2: fs_Linear_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //             images: [
    //                 { name: 'color_table', type: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    //     stencil: {
    //         enabled: true,
    //         ref: 0,
    //         writeMask: 0,
    //         readMask: 0x3F,
    //         front: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //         back: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //     },
    // })
    // ctx.pip_radial = gfx.makePipeline({
    //     label: 'radial',
    //     shader: gfx.makeShader({
    //         label: 'radial',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Gradient,
    //                 webgl2: vs_Gradient_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "uv_xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_Radial,
    //                 webgl2: fs_Radial_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //             images: [
    //                 { name: 'color_table', type: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    //     stencil: {
    //         enabled: true,
    //         ref: 0,
    //         writeMask: 0,
    //         readMask: 0x3F,
    //         front: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //         back: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //     },
    // })
    // ctx.pip_angular = gfx.makePipeline({
    //     label: 'angular',
    //     shader: gfx.makeShader({
    //         label: 'angular',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Gradient,
    //                 webgl2: vs_Gradient_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "uv_xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_Angular,
    //                 webgl2: fs_Angular_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //             images: [
    //                 { name: 'color_table', type: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    //     stencil: {
    //         enabled: true,
    //         ref: 0,
    //         writeMask: 0,
    //         readMask: 0x3F,
    //         front: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //         back: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //     },
    // })
    // ctx.pip_diamond = gfx.makePipeline({
    //     label: 'diamond',
    //     shader: gfx.makeShader({
    //         label: 'diamond',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Gradient,
    //                 webgl2: vs_Gradient_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "uv_xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_Diamond,
    //                 webgl2: fs_Diamond_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //             images: [
    //                 { name: 'color_table', type: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    //     stencil: {
    //         enabled: true,
    //         ref: 0,
    //         writeMask: 0,
    //         readMask: 0x3F,
    //         front: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //         back: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //     },
    // })

    // ctx.pip_masked_solid = gfx.makePipeline({
    //     label: 'masked_solid',
    //     shader: gfx.makeShader({
    //         label: 'masked_solid',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Solid,
    //                 webgl2: vs_Solid_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48,
    //                 uniforms: [
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_MaskedSolid,
    //                 webgl2: fs_MaskedSolid_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 32,
    //                 uniforms: [
    //                     {
    //                         name: "fill_color",
    //                         type: Gfx_UniformType.FLOAT4,
    //                     },
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //             images: [
    //                 { name: "mask", imageType: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    //     stencil: {
    //         enabled: true,
    //         ref: 0,
    //         writeMask: 0,
    //         readMask: 0x3F,
    //         front: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //         back: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //     },
    // })
    // ctx.pip_masked_linear = gfx.makePipeline({
    //     label: 'masked_linear',
    //     shader: gfx.makeShader({
    //         label: 'masked_linear',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Gradient,
    //                 webgl2: vs_Gradient_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "uv_xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_MaskedLinear,
    //                 webgl2: fs_MaskedLinear_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //             images: [
    //                 { name: 'color_table', type: Gfx_ImageType._2D },
    //                 { name: 'mask', type: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    //     stencil: {
    //         enabled: true,
    //         ref: 0,
    //         writeMask: 0,
    //         readMask: 0x3F,
    //         front: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //         back: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //     },
    // })
    // ctx.pip_masked_radial = gfx.makePipeline({
    //     label: 'masked_radial',
    //     shader: gfx.makeShader({
    //         label: 'masked_radial',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Gradient,
    //                 webgl2: vs_Gradient_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "uv_xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_MaskedRadial,
    //                 webgl2: fs_MaskedRadial_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //             images: [
    //                 { name: 'color_table', type: Gfx_ImageType._2D },
    //                 { name: 'mask', type: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    //     stencil: {
    //         enabled: true,
    //         ref: 0,
    //         writeMask: 0,
    //         readMask: 0x3F,
    //         front: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //         back: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //     },
    // })
    // ctx.pip_masked_angular = gfx.makePipeline({
    //     label: 'masked_angular',
    //     shader: gfx.makeShader({
    //         label: 'masked_angular',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Gradient,
    //                 webgl2: vs_Gradient_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "uv_xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_MaskedAngular,
    //                 webgl2: fs_MaskedAngular_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //             images: [
    //                 { name: 'color_table', type: Gfx_ImageType._2D },
    //                 { name: 'mask', type: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    //     stencil: {
    //         enabled: true,
    //         ref: 0,
    //         writeMask: 0,
    //         readMask: 0x3F,
    //         front: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //         back: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //     },
    // })
    // ctx.pip_masked_diamond = gfx.makePipeline({
    //     label: 'masked_diamond',
    //     shader: gfx.makeShader({
    //         label: 'masked_diamond',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Gradient,
    //                 webgl2: vs_Gradient_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "uv_xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_MaskedDiamond,
    //                 webgl2: fs_MaskedDiamond_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //             images: [
    //                 { name: 'color_table', type: Gfx_ImageType._2D },
    //                 { name: 'mask', type: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    //     stencil: {
    //         enabled: true,
    //         ref: 0,
    //         writeMask: 0,
    //         readMask: 0x3F,
    //         front: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //         back: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //     },
    // })

    // ctx.pip_solid_rect = gfx.makePipeline({
    //     label: 'solid_rect',
    //     shader: gfx.makeShader({
    //         label: 'solid_rect',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Solid,
    //                 webgl2: vs_Solid_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48,
    //                 uniforms: [
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_Solid,
    //                 webgl2: fs_Solid_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 32,
    //                 uniforms: [
    //                     {
    //                         name: "fill_color",
    //                         type: Gfx_UniformType.FLOAT4,
    //                     },
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLES,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    // })
    // ctx.pip_linear_rect = gfx.makePipeline({
    //     label: 'linear_rect',
    //     shader: gfx.makeShader({
    //         label: 'linear_rect',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Gradient,
    //                 webgl2: vs_Gradient_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "uv_xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_Linear,
    //                 webgl2: fs_Linear_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 16,
    //                 uniforms: [
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //             images: [
    //                 { name: 'color_table', type: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    // })
    // ctx.pip_radial_rect = gfx.makePipeline({
    //     label: 'radial_rect',
    //     shader: gfx.makeShader({
    //         label: 'radial_rect',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Gradient,
    //                 webgl2: vs_Gradient_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "uv_xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_Radial,
    //                 webgl2: fs_Radial_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 16,
    //                 uniforms: [
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },

    //                 ],
    //             }],
    //             images: [
    //                 { name: 'color_table', type: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    // })
    // ctx.pip_angular_rect = gfx.makePipeline({
    //     label: 'angular_rect',
    //     shader: gfx.makeShader({
    //         label: 'angular_rect',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Gradient,
    //                 webgl2: vs_Gradient_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "uv_xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_Angular,
    //                 webgl2: fs_Angular_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 16,
    //                 uniforms: [
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //             images: [
    //                 { name: 'color_table', type: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    // })
    // ctx.pip_diamond_rect = gfx.makePipeline({
    //     label: 'diamond_rect',
    //     shader: gfx.makeShader({
    //         label: 'diamond_rect',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Gradient,
    //                 webgl2: vs_Gradient_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "uv_xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_Diamond,
    //                 webgl2: fs_Diamond_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 16,
    //                 uniforms: [
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },

    //                 ],
    //             }],
    //             images: [
    //                 { name: 'color_table', type: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    // })

    // ctx.pip_masked_solid_rect = gfx.makePipeline({
    //     label: 'masked_solid_rect',
    //     shader: gfx.makeShader({
    //         label: 'masked_solid_rect',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Solid,
    //                 webgl2: vs_Solid_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48,
    //                 uniforms: [
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_MaskedSolid,
    //                 webgl2: fs_MaskedSolid_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 32,
    //                 uniforms: [
    //                     {
    //                         name: "fill_color",
    //                         type: Gfx_UniformType.FLOAT4,
    //                     },
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //             images: [
    //                 { name: "mask", imageType: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLES,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    // })
    // ctx.pip_masked_linear_rect = gfx.makePipeline({
    //     label: 'masked_linear_rect',
    //     shader: gfx.makeShader({
    //         label: 'masked_linear_rect',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Gradient,
    //                 webgl2: vs_Gradient_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "uv_xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_MaskedLinear,
    //                 webgl2: fs_MaskedLinear_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 32,
    //                 uniforms: [
    //                     {
    //                         name: "fill_color",
    //                         type: Gfx_UniformType.FLOAT4,
    //                     },
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //             images: [
    //                 { name: 'color_table', type: Gfx_ImageType._2D },
    //                 { name: "mask", imageType: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    // })
    // ctx.pip_masked_radial_rect = gfx.makePipeline({
    //     label: 'masked_radial_rect',
    //     shader: gfx.makeShader({
    //         label: 'masked_radial_rect',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Gradient,
    //                 webgl2: vs_Gradient_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "uv_xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_MaskedRadial,
    //                 webgl2: fs_MaskedRadial_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 32,
    //                 uniforms: [
    //                     {
    //                         name: "fill_color",
    //                         type: Gfx_UniformType.FLOAT4,
    //                     },
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //             images: [
    //                 { name: 'color_table', type: Gfx_ImageType._2D },
    //                 { name: "mask", imageType: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    // })
    // ctx.pip_masked_angular_rect = gfx.makePipeline({
    //     label: 'masked_angular_rect',
    //     shader: gfx.makeShader({
    //         label: 'masked_angular_rect',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Gradient,
    //                 webgl2: vs_Gradient_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "uv_xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_MaskedAngular,
    //                 webgl2: fs_MaskedAngular_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 32,
    //                 uniforms: [
    //                     {
    //                         name: "fill_color",
    //                         type: Gfx_UniformType.FLOAT4,
    //                     },
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //             images: [
    //                 { name: 'color_table', type: Gfx_ImageType._2D },
    //                 { name: "mask", imageType: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    // })
    // ctx.pip_masked_diamond_rect = gfx.makePipeline({
    //     label: 'masked_diamond_rect',
    //     shader: gfx.makeShader({
    //         label: 'masked_diamond_rect',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Gradient,
    //                 webgl2: vs_Gradient_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "uv_xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_MaskedDiamond,
    //                 webgl2: fs_MaskedDiamond_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 32,
    //                 uniforms: [
    //                     {
    //                         name: "fill_color",
    //                         type: Gfx_UniformType.FLOAT4,
    //                     },
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //             images: [
    //                 { name: 'color_table', type: Gfx_ImageType._2D },
    //                 { name: "mask", imageType: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    // })

    // // TODO: add missing image pipelines
    // ctx.pip_image = gfx.makePipeline({
    //     label: 'ImageFill',
    //     shader: gfx.makeShader({
    //         label: 'ImageFill',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Image,
    //                 webgl2: vs_Image_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "uv_xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_Image,
    //                 webgl2: fs_Image_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 16,
    //                 uniforms: [
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     }
    //                 ],
    //             }],
    //             images: [
    //                 { name: 'image', type: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    //     stencil: {
    //         enabled: true,
    //         ref: 0,
    //         writeMask: 0,
    //         readMask: 0x3F,
    //         front: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //         back: {
    //             compare: Gfx_CompareFunc.NOTEQUAL,
    //             failOp: Gfx_StencilOp.ZERO,
    //             depthFailOp: Gfx_StencilOp.ZERO,
    //             passOp: Gfx_StencilOp.ZERO,
    //         },
    //     },
    // })
    // ctx.pip_image_rect = gfx.makePipeline({
    //     label: 'ImageRectFill',
    //     shader: gfx.makeShader({
    //         label: 'ImageRectFill',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Image,
    //                 webgl2: vs_Image_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 * 2,
    //                 uniforms: [
    //                     {
    //                         name: "uv_xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     }
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_Image,
    //                 webgl2: fs_Image_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 16,
    //                 uniforms: [
    //                     {
    //                         name: "opacity",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //             images: [
    //                 { name: 'image', type: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLES,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    // })

    // // TODO: add missing effect pipelines
    // ctx.pip_rect_shadow = gfx.makePipeline({
    //     label: 'BoxShadow',
    //     shader: gfx.makeShader({
    //         label: 'BoxShadow',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_BoxShadow,
    //                 webgl2: vs_BoxShadow_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 + 16 + 16,
    //                 uniforms: [
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "rect",
    //                         type: Gfx_UniformType.FLOAT4,
    //                     },
    //                     {
    //                         name: "blur",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_BoxShadow,
    //                 webgl2: fs_BoxShadow_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 16,
    //                 uniforms: [
    //                     {
    //                         name: "color",
    //                         type: Gfx_UniformType.FLOAT4,
    //                     },
    //                 ],
    //             }],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    // })
    // ctx.pip_masked_rect_shadow = gfx.makePipeline({
    //     label: 'MaskedBoxShadow',
    //     shader: gfx.makeShader({
    //         label: 'MaskedBoxShadow',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_BoxShadow,
    //                 webgl2: vs_BoxShadow_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 48 + 16 + 16,
    //                 uniforms: [
    //                     {
    //                         name: "xform",
    //                         type: Gfx_UniformType.FLOAT3,
    //                         arrayCount: 3,
    //                     },
    //                     {
    //                         name: "rect",
    //                         type: Gfx_UniformType.FLOAT4,
    //                     },
    //                     {
    //                         name: "blur",
    //                         type: Gfx_UniformType.FLOAT,
    //                     },
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_MaskedBoxShadow,
    //                 webgl2: fs_MaskedBoxShadow_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 16,
    //                 uniforms: [
    //                     {
    //                         name: "color",
    //                         type: Gfx_UniformType.FLOAT4,
    //                     },
    //                 ],
    //             }],
    //             images: [
    //                 { name: "mask", imageType: Gfx_ImageType._2D },
    //             ],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             opRGB: Gfx_BlendOp.ADD,
    //             opAlpha: Gfx_BlendOp.ADD,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    // })
    // ctx.pip_blur = gfx.makePipeline({
    //     label: 'Blur',
    //     shader: gfx.makeShader({
    //         label: 'Blur',
    //         attrs: [
    //             'position',
    //         ],
    //         vs: {
    //             source: {
    //                 webgl: vs_Blur,
    //                 webgl2: vs_Blur_gl2,
    //             },
    //             uniformBlocks: [{
    //                 size: 16,
    //                 uniforms: [
    //                     {
    //                         name: "mapping",
    //                         type: Gfx_UniformType.FLOAT4,
    //                     },
    //                 ],
    //             }],
    //         },
    //         fs: {
    //             source: {
    //                 webgl: fs_Blur,
    //                 webgl2: fs_Blur_gl2,
    //             },
    //             images: [
    //                 { name: 'tex', type: Gfx_ImageType._2D },
    //             ],
    //             uniformBlocks: [{
    //                 size: 16 + 16,
    //                 uniforms: [
    //                     {
    //                         name: "direction",
    //                         type: Gfx_UniformType.FLOAT2,
    //                     },
    //                     {
    //                         name: "blur",
    //                         type: Gfx_UniformType.FLOAT,
    //                     }
    //                 ],
    //             }],
    //         },
    //     }),
    //     layout: {
    //         buffers: [
    //             { stride: 5 * 4 },
    //         ],
    //         attrs: [
    //             { format: Gfx_VertexFormat.FLOAT2 },
    //         ],
    //     },
    //     primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //     colors: [{
    //         blend: {
    //             enabled: true,
    //             srcFactorRGB: Gfx_BlendFactor.ONE,
    //             srcFactorAlpha: Gfx_BlendFactor.ONE,
    //             dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //         },
    //     }],
    //     depth: {
    //         pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //     },
    // })

    // bind_DrawImageNoXform.vertexBuffers[0] = gfx.makeBuffer({
    //     usage: Gfx_Usage.IMMUTABLE,

    //     //  x, y,   u, v
    //     data: new Float32Array([
    //         -1, -1, 0, 0,
    //         +1, -1, 1, 0,
    //         -1, +1, 0, 1,
    //         +1, +1, 1, 1,
    //     ]),
    // })


    // const blitWithOpacity = (() => {
    //     const fs_params = {
    //         opacity: [0],
    //     }

    //     const pip_opacity = gfx.makePipeline({
    //         label: 'BlitWithOpacity',
    //         shader: gfx.makeShader({
    //             label: 'BlitWithOpacity',
    //             attrs: [
    //                 'position',
    //                 'uv',
    //             ],
    //             vs: {
    //                 source: {
    //                     webgl: vs_BlitWithOpacity,
    //                     webgl2: vs_BlitWithOpacity_gl2,
    //                 },
    //             },
    //             fs: {
    //                 source: {
    //                     webgl: fs_BlitWithOpacity,
    //                     webgl2: fs_BlitWithOpacity_gl2,
    //                 },
    //                 images: [
    //                     { name: 'tex', type: Gfx_ImageType._2D },
    //                 ],
    //                 uniformBlocks: [{
    //                     size: 16,
    //                     uniforms: [
    //                         {
    //                             name: "opacity",
    //                             type: Gfx_UniformType.FLOAT,
    //                         }
    //                     ],
    //                 }],
    //             },
    //         }),
    //         layout: {
    //             attrs: [
    //                 { format: Gfx_VertexFormat.FLOAT2 },
    //                 { format: Gfx_VertexFormat.FLOAT2 },
    //             ],
    //         },
    //         primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //         colors: [{
    //             blend: {
    //                 enabled: true,
    //                 srcFactorRGB: Gfx_BlendFactor.ONE,
    //                 srcFactorAlpha: Gfx_BlendFactor.ONE,
    //                 dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //                 dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             },
    //         }],
    //         depth: {
    //             pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //         },
    //     })

    //     /**
    //      * @param {TileCanvas} src
    //      * @param {TileCanvas} dst
    //      * @param {number} opacity
    //      */
    //     return (src, dst, opacity) => {
    //         fs_params.opacity[0] = opacity

    //         gfx.beginPass(dst.pass, action_KeepColorAndStencil)
    //         {
    //             gfx.applyViewport(0, 0, 512, 512, false)
    //             gfx.applyScissorRect(0, 0, 512, 512, false)

    //             gfx.applyPipeline(pip_opacity)

    //             bind_DrawImageNoXform.fsImages[0] = src.image
    //             gfx.applyBindings(bind_DrawImageNoXform)

    //             gfx.applyUniforms(Gfx_ShaderStage.FS, 0, fs_params)

    //             gfx.draw(0, 4, 1)
    //         }
    //         gfx.endPass()

    //         dst.has_color = true
    //     }
    // })()
    // // eslint-disable-next-line no-unused-vars
    // const blitWithAlphaMask = (() => {
    //     const pip_alphaMask = gfx.makePipeline({
    //         label: 'MaskedColor',
    //         shader: gfx.makeShader({
    //             label: 'MaskedColor',
    //             attrs: [
    //                 'position',
    //                 'uv',
    //             ],
    //             vs: {
    //                 source: {
    //                     webgl: vs_AlphaMaskBlit,
    //                     webgl2: vs_AlphaMaskBlit_gl2,
    //                 },
    //             },
    //             fs: {
    //                 source: {
    //                     webgl: fs_AlphaMaskBlit,
    //                     webgl2: fs_AlphaMaskBlit_gl2,
    //                 },
    //                 images: [
    //                     { name: 'src_tex', type: Gfx_ImageType._2D },
    //                     { name: 'msk_tex', type: Gfx_ImageType._2D },
    //                 ],
    //             },
    //         }),
    //         layout: {
    //             buffers: [
    //                 { stride: 4 * 4 },
    //             ],
    //             attrs: [
    //                 { format: Gfx_VertexFormat.FLOAT2 },
    //                 { format: Gfx_VertexFormat.FLOAT2 },
    //             ],
    //         },
    //         primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //         colors: [{
    //             blend: {
    //                 enabled: true,
    //                 srcFactorRGB: Gfx_BlendFactor.ONE,
    //                 srcFactorAlpha: Gfx_BlendFactor.ONE,
    //                 dstFactorRGB: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //                 dstFactorAlpha: Gfx_BlendFactor.ONE_MINUS_SRC_ALPHA,
    //             },
    //         }],
    //         depth: {
    //             pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //         },
    //     })

    //     /**
    //      * @param {TileCanvas} src
    //      * @param {TileCanvas} mask
    //      * @param {TileCanvas} dst
    //      * @param {Gfx_PassAction} action
    //      */
    //     return (src, mask, dst, action) => {
    //         gfx.beginPass(dst.pass, action)
    //         {
    //             gfx.applyViewport(0, 0, 512, 512, false)
    //             gfx.applyScissorRect(0, 0, 512, 512, false)

    //             gfx.applyPipeline(pip_alphaMask)

    //             bind_DrawImageNoXform.fsImages[0] = src.image
    //             bind_DrawImageNoXform.fsImages[1] = mask.image
    //             gfx.applyBindings(bind_DrawImageNoXform)

    //             gfx.draw(0, 4, 1)
    //         }
    //         gfx.endPass()
    //         dst.has_color = true
    //     }
    // })()
    // const blitWithBlendMode = (() => {
    //     /**
    //      * @param {string} type
    //      * @param {string} vs_webgl
    //      * @param {string} fs_webgl
    //      * @param {string} vs_webgl2
    //      * @param {string} fs_webgl2
    //      * @returns {Gfx_Pipeline_t}
    //      */
    //     function makePip(type, vs_webgl, fs_webgl, vs_webgl2, fs_webgl2) {
    //         return gfx.makePipeline({
    //             label: `blend_${type}`,
    //             shader: gfx.makeShader({
    //                 label: `blend_${type}`,
    //                 attrs: [
    //                     'position',
    //                 ],
    //                 vs: {
    //                     source: {
    //                         webgl: vs_webgl,
    //                         webgl2: vs_webgl2,
    //                     },
    //                     uniformBlocks: [{
    //                         size: 16,
    //                         uniforms: [
    //                             {
    //                                 name: "mapping",
    //                                 type: Gfx_UniformType.FLOAT4,
    //                             }
    //                         ],
    //                     }],
    //                 },
    //                 fs: {
    //                     source: {
    //                         webgl: fs_webgl,
    //                         webgl2: fs_webgl2,
    //                     },
    //                     images: [
    //                         { name: 'src_tex', type: Gfx_ImageType._2D },
    //                         { name: 'dst_tex', type: Gfx_ImageType._2D },
    //                     ],
    //                 },
    //             }),
    //             layout: {
    //                 buffers: [
    //                     { stride: 5 * 4 },
    //                 ],
    //                 attrs: [
    //                     { format: Gfx_VertexFormat.FLOAT2 },
    //                 ],
    //             },
    //             primitiveType: Gfx_PrimitiveType.TRIANGLE_STRIP,
    //             depth: {
    //                 pixelFormat: Gfx_PixelFormat.DEPTH_STENCIL,
    //             },
    //         })
    //     }

    //     const pips = {
    //         normal: makePip("normal", vs_BlendMode, fs_normal, vs_BlendMode_gl2, fs_normal_gl2),

    //         darken: makePip("darken", vs_BlendMode, fs_darken, vs_BlendMode_gl2, fs_darken_gl2),
    //         multiply: makePip("multiply", vs_BlendMode, fs_multiply, vs_BlendMode_gl2, fs_multiply_gl2),
    //         color_burn: makePip("color_burn", vs_BlendMode, fs_color_burn, vs_BlendMode_gl2, fs_color_burn_gl2),
    //         lighten: makePip("lighten", vs_BlendMode, fs_lighten, vs_BlendMode_gl2, fs_lighten_gl2),
    //         screen: makePip("screen", vs_BlendMode, fs_screen, vs_BlendMode_gl2, fs_screen_gl2),
    //         color_dodge: makePip("color_dodge", vs_BlendMode, fs_color_dodge, vs_BlendMode_gl2, fs_color_dodge_gl2),
    //         overlay: makePip("overlay", vs_BlendMode, fs_overlay, vs_BlendMode_gl2, fs_overlay_gl2),
    //         soft_light: makePip("soft_light", vs_BlendMode, fs_soft_light, vs_BlendMode_gl2, fs_soft_light_gl2),
    //         hard_light: makePip("hard_light", vs_BlendMode, fs_hard_light, vs_BlendMode_gl2, fs_hard_light_gl2),
    //         difference: makePip("difference", vs_BlendMode, fs_difference, vs_BlendMode_gl2, fs_difference_gl2),
    //         exclusion: makePip("exclusion", vs_BlendMode, fs_exclusion, vs_BlendMode_gl2, fs_exclusion_gl2),
    //         hue: makePip("hue", vs_BlendMode, fs_hue, vs_BlendMode_gl2, fs_hue_gl2),
    //         saturation: makePip("saturation", vs_BlendMode, fs_saturation, vs_BlendMode_gl2, fs_saturation_gl2),
    //         color: makePip("color", vs_BlendMode, fs_color, vs_BlendMode_gl2, fs_color_gl2),
    //         luminosity: makePip("luminosity", vs_BlendMode, fs_luminosity, vs_BlendMode_gl2, fs_luminosity_gl2),
    //     }

    //     const binding = new Gfx_Bindings()
    //     binding.vertexBuffers[0] = gfx.makeBuffer({
    //         usage: Gfx_Usage.IMMUTABLE,
    //         data: new Float32Array([
    //             0, 0, 0, 0, 0,
    //             1, 0, 0, 0, 0,
    //             0, 1, 0, 0, 0,
    //             1, 1, 0, 0, 0,
    //             0, 0, 0, 0, 0,
    //             1, 1, 0, 0, 0,
    //         ]),
    //     })

    //     const blend_vs_uniform_block = {
    //         mapping: [0, 0, 512, 512],
    //     }

    //     /**
    //      * @param {TileCanvas} src
    //      * @param {TileCanvas} dst
    //      * @param {TileCanvas} canvas
    //      * @param {BlendModes} blend
    //      * @param {Gfx_PassAction} action
    //      */
    //     return (src, dst, canvas, blend, action) => {
    //         gfx.beginPass(canvas.pass, action)
    //         {
    //             gfx.applyViewport(0, 0, 512, 512, false)
    //             gfx.applyScissorRect(0, 0, 512, 512, false)

    //             gfx.applyPipeline(pips[blend])

    //             binding.fsImages[0] = src.image
    //             binding.fsImages[1] = dst.image
    //             gfx.applyBindings(binding)
    //             gfx.applyUniforms(Gfx_ShaderStage.VS, 0, blend_vs_uniform_block)

    //             gfx.draw(0, 4, 1)
    //         }
    //         gfx.endPass()
    //         canvas.has_color = true
    //     }
    // })()

    // const maskPaint = new Paint_t()
    // maskPaint.type = "solid"
    // maskPaint.params.fill_color = [1, 0, 0, 1]
    // maskPaint.params.opacity = [1]

    // /**
    //  * @param {RenderGroup} tile
    //  * @param {TileSet} tileset
    //  * @param {Color} bgColor
    //  * @param {number} scale
    //  */
    // composeTileWithCommands = (tile, tileset, bgColor, scale) => {
    //     if (tile.commands.length === 0) return

    //     const stack = {
    //         /** @type {TileCanvas[]} */
    //         canvas: [],
    //         /** @type {TileCanvas[]} */
    //         mask: [],
    //     }

    //     // let canvas = TileCanvas_New()
    //     bgColor.as_array(action_ClearStencilAndColorToBG.colors[0].val)
    //     gfx.beginPass(canvas.pass, action_ClearStencilAndColorToBG)
    //     gfx.endPass()
    //     canvas.has_color = true
    //     canvas.has_stencil = false

    //     /** @type {TileCanvas} */
    //     let mask = null

    //     for (const cmd of tile.commands) {
    //         if (!cmd) continue

    //         switch (cmd.type) {
    //             case RenderCommandTypes.fill: {
    //                 let action = action_KeepColorAndStencil
    //                 if (canvas.has_stencil) {
    //                     action = action_ClearStencil
    //                 }
    //                 doCommandFill(
    //                     canvas, action,
    //                     tile.col, tile.row, scale,
    //                     cmd.geometry, cmd.cover, cmd.transform,
    //                     cmd.paint, cmd.opacity,
    //                     mask,
    //                     false
    //                 )
    //                 CommandFill.free(cmd)
    //                 break
    //             }
    //             case RenderCommandTypes.push_canvas: {
    //                 stack.canvas.push(canvas)

    //                 // canvas = TileCanvas_New()
    //                 gfx.beginPass(canvas.pass, action_ClearColorAndStencil)
    //                 gfx.endPass()
    //                 canvas.has_color = false
    //                 canvas.has_stencil = false

    //                 CommandPush.free(cmd)
    //                 break
    //             }
    //             case RenderCommandTypes.push_fill_as_mask: {
    //                 const prevMask = mask
    //                 stack.mask.push(mask)

    //                 // mask = TileCanvas_New()
    //                 let action = action_KeepColorAndStencil
    //                 if (mask.has_color && mask.has_stencil) {
    //                     action = action_ClearColorAndStencil
    //                 } else if (mask.has_color) {
    //                     action = action_ClearColor
    //                 } else if (mask.has_stencil) {
    //                     action = action_ClearStencil
    //                 }

    //                 const changed = doCommandFill(
    //                     mask, action,
    //                     tile.col, tile.row, scale,
    //                     cmd.geometry, cmd.cover, cmd.transform,
    //                     maskPaint, 1,
    //                     prevMask,
    //                     false
    //                 )

    //                 if (!changed && action !== action_KeepColorAndStencil) {
    //                     gfx.beginPass(mask.pass, action)
    //                     gfx.endPass()
    //                     mask.has_color = false
    //                     mask.has_stencil = false
    //                 }

    //                 CommandPushFillAsMask.free(cmd)
    //                 break
    //             }
    //             case RenderCommandTypes.pop_canvas_as_mask: {
    //                 stack.mask.push(mask)
    //                 mask = canvas
    //                 canvas = stack.canvas.pop()
    //                 CommandPopAsMask.free(cmd)
    //                 break
    //             }
    //             case RenderCommandTypes.pop_mask_and_free: {
    //                 // TileCanvas_Free(mask)
    //                 mask = stack.mask.pop()
    //                 CommandPopMaskAndFree.free(cmd)
    //                 break
    //             }
    //             case RenderCommandTypes.pop_canvas_and_blit_with_opacity: {
    //                 const src = canvas
    //                 canvas = stack.canvas.pop()
    //                 if (src.has_color) {
    //                     blitWithOpacity(src, canvas, cmd.opacity)
    //                     // console.log('blitWithOpacity')
    //                 }
    //                 // TileCanvas_Free(src)
    //                 CommandPopAndBlitWithOpacity.free(cmd)
    //                 break
    //             }
    //             case RenderCommandTypes.pop_canvas_and_blit_with_blend: {
    //                 const dst = canvas
    //                 const src = stack.canvas.pop()

    //                 // canvas = TileCanvas_New()

    //                 let action = action_KeepColorAndStencil
    //                 if (canvas.has_color) {
    //                     action = action_ClearColor
    //                 }
    //                 blitWithBlendMode(src, dst, canvas, cmd.blend, action)
    //                 // console.log('blitWithBlendMode')
    //                 // TileCanvas_Free(src)

    //                 // TileCanvas_Free(dst)
    //                 CommandPopAndBlitWithBlend.free(cmd)
    //                 break
    //             }
    //             default: {
    //                 console.warn(`Command "${cmd.type}" not supported!`)
    //             } break
    //         }
    //     }

    //     if (canvas.has_color) {
    //         tileset.pack(canvas.image, tile.col, tile.row, tile.level, gfx.state.frameIndex, tile.commands.length)
    //     }

    //     // TileCanvas_Free(canvas)
    // }
}

// const inv_256 = 1 / 256

// const vsParam_GeneralFill = {
//     xform: [
//         1, 0, 0,
//         0, 1, 0,
//         0, 0, 1,
//     ],
//     uv_xform: [
//         1, 0, 0,
//         0, 1, 0,
//         0, 0, 1,
//     ],
// }
// const fsParam_GeneralFill = {
//     opacity: [0],
// }
// /** @type {Paint_t} */
// const debugPaint = {
//     type: "solid",
//     params: {
//         fill_color: [1, 0, 0, 0.75],
//         opacity: [1],
//     },
// }

// /**
//  * @param {Float32Array} verts
//  * @returns {Gfx_Buffer_t}
//  */
// const uploadVertices = (verts) => gfx.makeBuffer({
//     data: verts,
//     type: Gfx_BufferType.VERTEX_BUFFER,
//     usage: Gfx_Usage.IMMUTABLE,
// })
// /** @type {{ buf: Gfx_Buffer_t, vertLen: number }} */
// const itemBufferData = { buf: null, vertLen: 0 }
// /**
//  * @param {VectorResource} vector
//  * @returns {{ buf: Gfx_Buffer_t, vertLen: number }}
//  */
// function uploadVectorVertices(vector) {
//     itemBufferData.buf = null
//     itemBufferData.vertLen = 0

//     if (vector.buffer) {
//         itemBufferData.buf = vector.buffer
//         itemBufferData.vertLen = vector.vertLen
//         return itemBufferData
//     }

//     // upload to GPU
//     const triangles = vector.getData()
//     if (triangles) {
//         itemBufferData.buf = uploadVertices(triangles)
//         itemBufferData.vertLen = triangles.length / 5 - 6
//     }

//     // cache
//     vector.buffer = itemBufferData.buf
//     vector.vertLen = itemBufferData.vertLen
//     vector.gfx = gfx

//     return itemBufferData
// }

// /**
//  * @param {Gfx_Image_t} image
//  * @param {ImageOptions} options
//  * @param {Rect2} bounds
//  * @param {Transform2D} xform
//  * @param {number} width
//  * @param {number} height
//  * @returns {Gfx_Image_t}
//  */
// function getTexture(image, options, bounds, xform, width, height) {
//     if (!options.imageTileX && !options.imageTileY) return image

//     let offsetX = options.imageOffset.x
//     let offsetY = options.imageOffset.y

//     if (options.imageOffsetUnit === Unit.PERCENT) {
//         offsetX *= bounds.width / 100
//         offsetY *= bounds.height / 100
//     }

//     let gapX = options.imageTileGap.x
//     let gapY = options.imageTileGap.y

//     if (options.imageTileGapUnit === Unit.PERCENT) {
//         gapX *= bounds.width / 100
//         gapY *= bounds.height / 100
//     }

//     /** @type {-1|0|1} */
//     let x = 0
//     /** @type {-1|0|1} */
//     let y = 0

//     const imageOrigin = options.imageOrigin
//     if (imageOrigin === ImageOrigin.TOP_LEFT) {
//         x = -1
//         y = -1
//     } else if (imageOrigin === ImageOrigin.TOP) {
//         y = -1
//     } else if (imageOrigin === ImageOrigin.TOP_RIGHT) {
//         x = 1
//         y = -1
//     } else if (imageOrigin === ImageOrigin.RIGHT) {
//         x = 1
//     } else if (imageOrigin === ImageOrigin.BOTTOM_RIGHT) {
//         x = 1
//         y = 1
//     } else if (imageOrigin === ImageOrigin.BOTTOM) {
//         y = 1
//     } else if (imageOrigin === ImageOrigin.BOTTOM_LEFT) {
//         x = -1
//         y = 1
//     } else if (imageOrigin === ImageOrigin.LEFT) {
//         x = -1
//     }

//     let negXSpace = 0
//     let posXSpace = 0
//     let negYSpace = 0
//     let posYSpace = 0

//     if (x === -1) {
//         posXSpace = (bounds.width - offsetX) - width
//     } else if (x === 0) {
//         negXSpace = (bounds.width * 0.5 + offsetX) - width * 0.5
//         posXSpace = (bounds.width * 0.5 - offsetX) - width * 0.5
//     } else {
//         negXSpace = (bounds.width + offsetX) - width
//     }

//     if (y === -1) {
//         posYSpace = (bounds.height - offsetY) - height
//     } else if (y === 0) {
//         negYSpace = (bounds.height * 0.5 + offsetY) - height * 0.5
//         posYSpace = (bounds.height * 0.5 - offsetY) - height * 0.5
//     } else {
//         negYSpace = (bounds.height + offsetY) - height
//     }

//     negXSpace = options.imageTileX ? Math.max(0, negXSpace) : 0
//     posXSpace = options.imageTileX ? Math.max(0, posXSpace) : 0
//     negYSpace = options.imageTileY ? Math.max(0, negYSpace) : 0
//     posYSpace = options.imageTileY ? Math.max(0, posYSpace) : 0

//     let negTileW = width + gapX
//     let posTileW = width + gapX
//     let negTileH = height + gapY
//     let posTileH = height + gapY

//     let negXTiles = negXSpace / negTileW
//     let posXTiles = posXSpace / posTileW
//     let negYTiles = negYSpace / negTileH
//     let posYTiles = posYSpace / posTileH

//     if (options.imageTileModeX === ImageTileMode.SPACE) {
//         negXTiles = Math.floor(negXTiles)
//         posXTiles = Math.floor(posXTiles)

//         // guard against dividing by 0
//         if (negXTiles > 0) negTileW = negXSpace / negXTiles
//         if (posXTiles > 0) posTileW = posXSpace / posXTiles
//     } else {
//         negXTiles = Math.ceil(negXTiles)
//         posXTiles = Math.ceil(posXTiles)
//     }

//     if (options.imageTileModeY === ImageTileMode.SPACE) {
//         negYTiles = Math.floor(negYTiles)
//         posYTiles = Math.floor(posYTiles)

//         // guard against dividing by 0
//         if (negYTiles > 0) negTileH = negYSpace / negYTiles
//         if (posYTiles > 0) posTileH = posYSpace / posYTiles
//     } else {
//         negYTiles = Math.ceil(negYTiles)
//         posYTiles = Math.ceil(posYTiles)
//     }

//     if (negXTiles === 0 && posXTiles === 0 && negYTiles === 0 && posYTiles === 0) return image

//     // scale dimensions by element size
//     negTileW /= bounds.width
//     posTileW /= bounds.width
//     negTileH /= bounds.height
//     posTileH /= bounds.height

//     const O = xform.clone()

//     const newImg = gfx.makeImage({ width: bounds.width, height: bounds.height })
//     const canvas = gfx.makePass({
//         label: 'TileImage',
//         colorAttachments: [
//             { image: newImg },
//         ],
//     })

//     for (let i = -negXTiles; i <= posXTiles; i++) {
//         for (let j = -negYTiles; j <= posYTiles; j++) {
//             const oX = i * (i < 0 ? negTileW : posTileW)
//             const oY = j * (j < 0 ? negTileH : posTileH)

//             xform.reset()
//                 .translate(-oX, -oY)
//                 .prepend(O)

//             // TODO: add DrawImage and pip_DrawImage_General back
//             // DrawImage(canvas, image, xform, pip_DrawImage_General)
//         }
//     }

//     gfx.destroyPass(canvas)

//     xform.reset()
//     return newImg
// }

// const tile_rect = new Rect2(0, 0, 256, 256)
// /**
//  * @param {Rect2} bounds
//  * @param {number} tileSize
//  */
// function trimBoundsInTile(bounds, tileSize) {
//     bounds.clip_by(tile_rect.set(0, 0, tileSize, tileSize))
// }

// /**
//  * @param {TileCanvas} canvas
//  * @param {Gfx_PassAction} action
//  * @param {number} col
//  * @param {number} row
//  * @param {number} scale
//  * @param {VectorResource} geometry
//  * @param {Rect2} cover
//  * @param {Transform2D} transform
//  * @param {Paint_t} paint
//  * @param {number} opacity
//  * @param {TileCanvas} [mask]
//  * @param {"cover" | "stencil" | false} debug
//  * @returns {boolean} whether any pixels are rendered to canvas
//  */
// function doCommandFill(
//     canvas, action,
//     col, row, scale,
//     geometry, cover, transform, // TODO: use cover rect instead of just its size since it's not always start from top-left of geometry
//     paint, opacity,
//     mask = null,
//     debug = false
// ) {
//     const {
//         bind_comp,

//         buf_cover_debug,
//         pip_cover_debug,
//         pip_stencil_debug,

//         pip_stencil,
//     } = ctx

//     const boundsInTile = transform.xform_rect(geometry.bounds)
//     boundsInTile.x *= scale
//     boundsInTile.y *= scale
//     boundsInTile.width *= scale
//     boundsInTile.height *= scale
//     boundsInTile.x -= (col * 256)
//     boundsInTile.y -= (row * 256)
//     trimBoundsInTile(boundsInTile, 256)

//     // completely outside the tile
//     if (
//         boundsInTile.left >= 256
//         ||
//         boundsInTile.top >= 256
//         ||
//         boundsInTile.right <= 0
//         ||
//         boundsInTile.bottom <= 0
//     ) {
//         return false
//     }
//     // check geometry vertices count
//     if (geometry.triangles.length <= 30) {
//         return false
//     }

//     boundsInTile.x *= 2
//     boundsInTile.y *= 2
//     boundsInTile.width *= 2
//     boundsInTile.height *= 2

//     boundsInTile.x = Math.max(0, boundsInTile.x - 1)
//     boundsInTile.y = Math.max(0, boundsInTile.y - 1)
//     boundsInTile.width = Math.min(boundsInTile.width + 2, 512)
//     boundsInTile.height = Math.min(boundsInTile.height + 2, 512)

//     // TODO: profile "full clear" vs "scissor clear"
//     gfx.applyViewport(0, 0, 512, 512, false)
//     gfx.applyScissorRect(boundsInTile.x, boundsInTile.y, Math.min(boundsInTile.width + 1, 512), Math.min(boundsInTile.height + 1, 512), false)

//     // projection matrix
//     const inv_256_scale = 256 / scale
//     const projectionXform = transform.clone()
//         .translate(-col * inv_256_scale, -row * inv_256_scale)
//         .scale(scale * 2 * inv_256, scale * 2 * inv_256)
//         .translate(-1, -1)

//     gfx.beginPass(canvas.pass, action)
//     {
//         gfx.applyViewport(0, 0, 512, 512, false)
//         gfx.applyScissorRect(boundsInTile.x, boundsInTile.y, Math.min(boundsInTile.width + 1, 512), Math.min(boundsInTile.height + 1, 512), false)

//         const isRect = geometry.isRect

//         // draw geometry into canvas stencil
//         if (debug !== "cover") {
//             if (isRect) {
//                 bind_comp.vertexBuffers[0] = uploadVectorVertices(geometry).buf
//             } else {
//                 if (debug === "stencil") {
//                     gfx.applyPipeline(pip_stencil_debug)
//                 } else {
//                     gfx.applyPipeline(pip_stencil)
//                 }

//                 projectionXform.to_array(false, vsParam_GeneralFill.xform)
//                 gfx.applyUniforms(Gfx_ShaderStage.VS, 0, vsParam_GeneralFill)

//                 const b = uploadVectorVertices(geometry)
//                 if (b.buf && b.vertLen > 0) {
//                     bind_comp.vertexBuffers[0] = b.buf
//                     gfx.applyBindings(bind_comp)
//                     gfx.draw(6, b.vertLen, 1)
//                 } else {
//                     console.warn("Fill command has no vertices to draw")
//                 }
//             }
//         }

//         // fill canvas with real painting
//         const c = (debug === "cover")
//             ? debugPaint
//             : paint

//         // find the right pipeline
//         const pip = ctx.getPipelineOfType(c.type, isRect, !!mask)

//         if (!debug) {
//             gfx.applyPipeline(pip)
//         } else if (debug === "cover") {
//             gfx.applyPipeline(pip_cover_debug)
//             bind_comp.vertexBuffers[0] = buf_cover_debug
//         }

//         // do fill
//         if (debug !== "stencil") {
//             // build transform for our cover geometry
//             const rotation = transform.get_rotation()
//             const skew = transform.get_skew()

//             const offsetScale = transform.get_scale()
//                 .scale(inv_256 * scale * 2)

//             const offset = new Vector2(geometry.bounds.x, geometry.bounds.y)
//             const offsetXform = new Transform2D()
//                 .set_rotation_scale_and_skew(rotation, offsetScale, skew)
//             offsetXform.xform(offset, offset)

//             const extent = projectionXform.get_scale()
//                 .multiply(geometry.bounds.w, geometry.bounds.h)

//             const coverXform = new Transform2D()
//                 .set_rotation_scale_and_skew(rotation, extent, skew)
//                 .translate(offset.x, offset.y)
//                 .translate(projectionXform.tx, projectionXform.ty)

//             if (mask) {
//                 bind_comp.fsImages[0] = mask.image
//             }

//             switch (c.type) {
//                 case 'solid': {
//                     if (isRect) {
//                         projectionXform.to_array(false, vsParam_GeneralFill.xform)
//                         gfx.applyUniforms(Gfx_ShaderStage.VS, 0, vsParam_GeneralFill)

//                         gfx.applyUniforms(Gfx_ShaderStage.FS, 0, c.params)

//                         gfx.applyBindings(bind_comp)
//                         gfx.draw(6, 6, 1)
//                     } else {
//                         coverXform.to_array(false, vsParam_GeneralFill.xform)
//                         gfx.applyUniforms(Gfx_ShaderStage.VS, 0, vsParam_GeneralFill)

//                         gfx.applyUniforms(Gfx_ShaderStage.FS, 0, c.params)
//                         fsParam_GeneralFill.opacity[0] = c.params.opacity[0] * opacity
//                         gfx.applyUniforms(Gfx_ShaderStage.FS, 0, fsParam_GeneralFill)

//                         gfx.applyBindings(bind_comp)
//                         gfx.draw(0, 4, 1)
//                     }
//                 } break

//                 case 'linear':
//                 case 'radial':
//                 case 'angular':
//                 case 'diamond': {
//                     bind_comp.fsImages[0] = c.gradient.getData() || VisualStorage.instance().resources.tex_White
//                     if (mask) {
//                         bind_comp.fsImages[1] = mask.image
//                     }
//                     coverXform.to_array(false, vsParam_GeneralFill.xform)

//                     /** @type {Transform2D} */
//                     const gradientXform = c.transform.clone()

//                     fsParam_GeneralFill.opacity[0] = c.params.opacity[0] * opacity

//                     gradientXform.to_array(false, vsParam_GeneralFill.uv_xform)
//                     gfx.applyUniforms(Gfx_ShaderStage.VS, 0, vsParam_GeneralFill)
//                     gfx.applyUniforms(Gfx_ShaderStage.FS, 0, fsParam_GeneralFill)
//                     gfx.applyBindings(bind_comp)
//                     gfx.draw(0, 4, 1)
//                 } break

//                 case 'image': {
//                     let image = c.image ? c.image.getData() : null
//                     image = image || VisualStorage.instance().resources.tex_Checkboard

//                     const { xform: imageXform, width, height } = getTextureXform(c.imageOptions, cover, image.cmn.width / image.cmn.height)

//                     bind_comp.fsImages[0] = getTexture(image, c.imageOptions, cover, imageXform, width, height)
//                     if (mask) {
//                         bind_comp.fsImages[1] = mask.image
//                     }
//                     coverXform.to_array(false, vsParam_GeneralFill.xform)

//                     if (isRect) {
//                         // imageXform.a /= cover.w
//                         // imageXform.d /= cover.h
//                         // imageXform.b /= cover.w
//                         // imageXform.c /= cover.h

//                         imageXform.to_array(false, vsParam_GeneralFill.uv_xform)
//                         gfx.applyUniforms(Gfx_ShaderStage.VS, 0, vsParam_GeneralFill)

//                         gfx.applyUniforms(Gfx_ShaderStage.FS, 0, c.params)

//                         gfx.applyBindings(bind_comp)
//                         gfx.draw(6, 6, 1)
//                     } else {
//                         imageXform.to_array(false, vsParam_GeneralFill.uv_xform)
//                         gfx.applyUniforms(Gfx_ShaderStage.VS, 0, vsParam_GeneralFill)

//                         gfx.applyUniforms(Gfx_ShaderStage.FS, 0, c.params)
//                         fsParam_GeneralFill.opacity[0] = c.params.opacity[0] * opacity
//                         gfx.applyUniforms(Gfx_ShaderStage.FS, 0, fsParam_GeneralFill)

//                         gfx.applyBindings(bind_comp)
//                         gfx.draw(0, 4, 1)
//                     }
//                 } break
//             }
//         }
//     }
//     gfx.endPass()

//     canvas.has_color = true
//     canvas.has_stencil = true // TODO: no stencil for rect fast pass rendering

//     return true
// }
