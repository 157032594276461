import { LZWEncoder } from './LZWEncoder'
import { NeuQuant } from './TypedNeuQuant'
import { OctreeQuant, Color } from './octreequant'

let nPix = 0

export class GIF2 {
    /**
     * @param {"neuquant" | "octree"} algorithm
     * @param {boolean} useOptimizer
     */
    constructor(algorithm = 'neuquant', useOptimizer = false) {
        this.algorithm = algorithm
        this.useOptimizer = useOptimizer

        this.width = 0
        this.height = 0

        this.frames = 1
        this.threshold = 90
        this.indexedPixels = null
        this.palSizeNeu = 7
        this.palSizeOct = 7
        this.sample = 10
        this.colorTab = null
        this.reuseTab = null
        this.colorDepth = null
        this.usedEntry = new Array()
        this.firstFrame = true
        this.started = false
        this.image = null
        this.prevImage = null
        this.dispose = -1
        this.repeat = false
        this.delay = 0
        this.transparent = NaN
        this.transIndex = 0

        this.buffer = new ByteArray()
    }

    start() {
        this.buffer.writeUTFBytes('GIF89a')
        this.started = true
    }

    /**
     * @param {Uint8ClampedArray} input
     */
    addFrame(input) {
        this.image = input

        this.analyzePixels()

        if (this.firstFrame) {
            this.writeLSD()
            this.writePalette()
            if (this.repeat) {
                this.writeNetscapeExt()
            }
        }

        this.writeGraphicCtrlExt()
        this.writeImageDesc()
        if (!this.firstFrame) {
            this.writePalette()
        }
        this.writePixels()
        this.firstFrame = false
    }

    analyzePixels() {
        const w = this.width
        const h = this.height

        const data = this.image

        if (this.useOptimizer && this.prevImage) {
            let delta = 0
            for (let len = data.length, i = 0; i < len; i += 4) {
                if (
                    data[i] !== this.prevImage[i] ||
                    data[i + 1] !== this.prevImage[i + 1] ||
                    data[i + 2] !== this.prevImage[i + 2]
                ) {
                    delta++
                }
            }
            const match = 100 - Math.ceil((delta / (data.length / 4)) * 100)
            this.reuseTab = match >= this.threshold
        }

        this.prevImage = data

        if (this.algorithm === 'neuquant') {
            let count = 0
            this.pixels = new Uint8Array(w * h * 3)

            for (let i = 0; i < h; i++) {
                for (let j = 0; j < w; j++) {
                    const b = i * w * 4 + j * 4
                    this.pixels[count++] = data[b]
                    this.pixels[count++] = data[b + 1]
                    this.pixels[count++] = data[b + 2]
                }
            }

            nPix = this.pixels.length / 3
            this.indexedPixels = new Uint8Array(nPix)

            if (!this.reuseTab) {
                this.quantizer = new NeuQuant(this.pixels, this.sample)
                this.quantizer.buildColormap()
                this.colorTab = this.quantizer.getColormap()
            }

            let k = 0
            for (let j = 0; j < nPix; j++) {
                const index = this.quantizer.lookupRGB(
                    this.pixels[k++] & 0xff,
                    this.pixels[k++] & 0xff,
                    this.pixels[k++] & 0xff
                )

                this.usedEntry[index] = true
                this.indexedPixels[j] = index
            }

            this.colorDepth = 8
            this.palSizeNeu = 7
            this.pixels = null
        } else if (this.algorithm === 'octree') {
            this.colors = []

            if (!this.reuseTab) {
                this.quantizer = new OctreeQuant()
            }

            for (let i = 0; i < h; i++) {
                for (let j = 0; j < w; j++) {
                    const b = i * w * 4 + j * 4
                    const color = new Color(data[b], data[b + 1], data[b + 2])
                    this.colors.push(color)

                    if (!this.reuseTab) {
                        this.quantizer.addColor(color)
                    }
                }
            }

            const nPix = this.colors.length
            this.indexedPixels = new Uint8Array(nPix)

            if (!this.reuseTab) {
                this.colorTab = []
                const palette = this.quantizer.makePalette(Math.pow(2, this.palSizeOct + 1))

                for (const p of palette) {
                    this.colorTab.push(p.red, p.green, p.blue)
                }
            }

            for (let i = 0; i < nPix; i++) {
                const index = this.quantizer.getPaletteIndex(this.colors[i])
                this.usedEntry[index] = true
                this.indexedPixels[i] = index
            }

            this.colorDepth = this.palSizeOct + 1
        }

        if (Number.isFinite(this.transparent)) {
            this.transIndex = this.findClosest(this.transparent)

            for (let pixelIndex = 0; pixelIndex < nPix; pixelIndex++) {
                if (this.image[pixelIndex * 4 + 3] == 0) {
                    this.indexedPixels[pixelIndex] = this.transIndex
                }
            }
        }
    }

    /**
     * @param {number} c
     */
    findClosest(c) {
        if (this.colorTab === null) {
            return -1
        }

        const r = (c & 0xff0000) >> 16
        const g = (c & 0x00ff00) >> 8
        const b = c & 0x0000ff
        let minpos = 0
        let dmin = 256 * 256 * 256
        const len = this.colorTab.length
        for (let i = 0; i < len;) {
            const index = i / 3
            const dr = r - (this.colorTab[i++] & 0xff)
            const dg = g - (this.colorTab[i++] & 0xff)
            const db = b - (this.colorTab[i++] & 0xff)
            const d = dr * dr + dg * dg + db * db
            if (this.usedEntry[index] && d < dmin) {
                dmin = d
                minpos = index
            }
        }

        return minpos
    }

    setFrameRate(fps) {
        this.delay = Math.round(100 / fps)
    }

    setDelay(ms) {
        this.delay = Math.round(ms / 10)
    }

    setDispose(code) {
        if (code >= 0) {
            this.dispose = code
        }
    }

    /**
     * @param {boolean} repeat
     */
    setRepeat(repeat) {
        this.repeat = repeat
    }

    /**
     * @param {number} color // hex color
     */
    setTransparent(color) {
        this.transparent = color
    }

    setThreshold(threshold) {
        if (threshold > 100) {
            // eslint-disable-next-line no-param-reassign
            threshold = 100
        } else if (threshold < 0) {
            // eslint-disable-next-line no-param-reassign
            threshold = 0
        }
        this.threshold = threshold
    }

    /**
     * @param {number} size
     */
    setPaletteSize(size) {
        if (size > 7) {
            // eslint-disable-next-line no-param-reassign
            size = 7
        } else if (size < 4) {
            // eslint-disable-next-line no-param-reassign
            size = 4
        }
        this.palSizeOct = size
    }

    writeLSD() {
        this.writeShort(this.width)
        this.writeShort(this.height)

        this.buffer.writeByte(0x80 | 0x70 | 0x00 | this.palSizeNeu)

        this.buffer.writeByte(0)
        this.buffer.writeByte(0)
    }

    writeGraphicCtrlExt() {
        this.buffer.writeByte(0x21)
        this.buffer.writeByte(0xf9)
        this.buffer.writeByte(4)

        let transp, disp
        if (Number.isNaN(this.transparent)) {
            transp = 0
            disp = 0
        } else {
            transp = 1
            disp = 2
        }

        if (this.dispose >= 0) {
            disp = this.dispose & 7
        }
        disp <<= 2

        this.buffer.writeByte(0 | disp | 0 | transp)

        this.writeShort(this.delay)
        this.buffer.writeByte(this.transIndex)
        this.buffer.writeByte(0)
    }

    writeNetscapeExt() {
        this.buffer.writeByte(0x21)
        this.buffer.writeByte(0xff)
        this.buffer.writeByte(11)
        this.buffer.writeUTFBytes('NETSCAPE2.0')
        this.buffer.writeByte(3)
        this.buffer.writeByte(1)
        // loop count 0 means LOOP FOREVER
        this.writeShort(0)
        this.buffer.writeByte(0)
    }

    writeImageDesc() {
        this.buffer.writeByte(0x2c)
        this.writeShort(0)
        this.writeShort(0)
        this.writeShort(this.width)
        this.writeShort(this.height)

        if (this.firstFrame) {
            this.buffer.writeByte(0)
        } else {
            this.buffer.writeByte(0x80 | 0 | 0 | 0 | this.palSizeNeu)
        }
    }

    writePalette() {
        this.buffer.writeBytes(this.colorTab)
        const n = 3 * 256 - this.colorTab.length
        for (let i = 0; i < n; i++) {
            this.buffer.writeByte(0)
        }
    }

    writeShort(pValue) {
        this.buffer.writeByte(pValue & 0xff)
        this.buffer.writeByte((pValue >> 8) & 0xff)
    }

    writePixels() {
        const enc = new LZWEncoder(this.width, this.height, this.indexedPixels, this.colorDepth)
        enc.encode(this.buffer)
    }

    finish() {
        this.buffer.writeByte(0x3b)
    }
}

class ByteArray {
    constructor() {
        this.data = []
    }

    getData() {
        return Uint8Array.from(this.data)
    }

    writeByte(val) {
        this.data.push(val)
    }

    writeUTFBytes(str) {
        for (let len = str.length, i = 0; i < len; i++) {
            this.writeByte(str.charCodeAt(i))
        }
    }

    writeBytes(array, offset, length) {
        for (let len = length || array.length, i = offset || 0; i < len; i++) {
            this.writeByte(array[i])
        }
    }
}
