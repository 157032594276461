import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useApolloClient } from '@apollo/client'
import { LoadingStatus } from '@phase-software/types'

import { FileFieldsFragment, ProjectFieldsFragment } from '../../generated/graphql'
import useFileActions from '../../hooks/useFileActions'
import { useProjectsQuery } from '../../hooks/useWorkspace'
import { useSetNotification } from '../../providers/NotificationProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { translateProjectName } from '../../utils/transformProject'
import { getProjectPermissionDescription } from '../Share/shareHelper'
import { ProjectPermissionEnum } from '../Share/shareTypes'
import { Dialog, Select } from '../shared'

type MoveProjectDialogProps = {
  open: boolean
  fileId: FileFieldsFragment['id']
  originalProjectId: FileFieldsFragment['project_id']
  onClose: () => void
}
const MoveFileDialog = ({ open, onClose, fileId, originalProjectId }: MoveProjectDialogProps) => {
  const { t } = useTranslation(['workspace', 'common'])
  const client = useApolloClient()
  const { workspaceData } = useWorkspaceContext()
  const { moveFile } = useFileActions()
  const { addNotification } = useSetNotification()

  const [newProjectId, setNewProjectId] = useState<ProjectFieldsFragment['id']>(originalProjectId)
  const [isMoving, setIsMoving] = useState(false)

  const isMoveButtonDisabled = isMoving || newProjectId === originalProjectId

  const { data: projectListData } = useProjectsQuery({
    isPersonalWorkspace: workspaceData.isPersonal,
    workspaceId: workspaceData.id
  })

  const transformedDropdownOptions = useMemo(
    () =>
      projectListData?.projects?.map(({ id, name, permission }) => ({
        value: id,
        name: translateProjectName(name || '', t),
        description: workspaceData.isPersonal
          ? undefined
          : workspaceData.draftProjectId === id
          ? getProjectPermissionDescription(ProjectPermissionEnum.CAN_REVIEW)
          : getProjectPermissionDescription(permission as ProjectPermissionEnum)
      })) || [],
    [projectListData?.projects, t, workspaceData.draftProjectId, workspaceData.isPersonal]
  )

  const handleMove = async () => {
    if (isMoveButtonDisabled) return
    setIsMoving(true)
    try {
      await moveFile({
        fileId,
        projectId: originalProjectId,
        targetProjectId: newProjectId
      })
      onClose()

      await client.refetchQueries({
        include: 'active'
      })
      const newProjectName = projectListData?.projects?.find(({ id }) => id === newProjectId)?.name

      addNotification({
        type: 'success',
        content: (
          <Trans
            i18nKey="workspace:the_file_has_been_moved_to"
            values={{ project_name: newProjectName }}
            components={[<span key="file-moved-notification" className="font-semibold" />]}
          />
        )
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsMoving(false)
    }
  }
  const handleClose = () => {
    setNewProjectId(originalProjectId)
    onClose()
  }

  return (
    <Dialog
      data-test-id="move-file-dialog"
      size="s"
      title={t('move_file')}
      showProgressButton
      progressStatus={isMoving ? LoadingStatus.WAITING : LoadingStatus.INITIAL}
      confirmBtnText={t('move')}
      cancelBtnText={t('common:button.cancel')}
      onConfirm={handleMove}
      onCancel={handleClose}
      disableConfirm={isMoveButtonDisabled}
      open={open}
    >
      <p className="mb-16">{t('choose_the_destination_project')}</p>
      <Select
        data-test-id="location-input-edit"
        // @ts-ignore TODO: fix after refactor of Select
        showSearch
        placeholder={t('setting:team_setting.select_country')}
        value={newProjectId}
        onChange={setNewProjectId}
        options={transformedDropdownOptions}
        size="l"
        variant="normal"
        caret
      />
    </Dialog>
  )
}

export default MoveFileDialog
