import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { Location } from 'history'

import { useBroadcaster } from '../../Broadcaster'
import { useSetNotification } from '../../providers/NotificationProvider'
import { CUSTOM_EVENT_NAMES } from '../../constants'
import { Dialog } from '../shared'

const FileSyncDialog = () => {
  const [navDialogOpen, setNavDialogOpen] = useState(false)
  const [nextPath, setNextPath] = useState('')
  const unblockRef = useRef<any>(null)
  const prevLocationRef = useRef<Location | null>(null)
  const notificationId = useRef<string>('')
  const [notifyOnSync, setNotifyOnSync] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const { addNotification, removeNotification } = useSetNotification()
  const broadcaster = useBroadcaster()
  const { t } = useTranslation(['file', 'workspace'])

  const handleDialogConfirm = useCallback(() => {
    setNavDialogOpen(false)
    unblockRef?.current?.()
    history.push(nextPath)
  }, [history, nextPath, unblockRef])

  const handleDialogCancel = useCallback(() => {
    setNavDialogOpen(false)
    setNotifyOnSync(true)
  }, [])

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (prevLocationRef.current && prevLocationRef.current.key !== location.key) {
        const isSynced = broadcaster?.isSynced()
        if (!isSynced) {
          setNavDialogOpen(true)
          setNextPath(location.pathname)
          return false
        }
      }
    })
    prevLocationRef.current = location
    return () => {
      setNavDialogOpen(false)
      unblockRef?.current?.()
    }
  }, [broadcaster, history, location])

  useEffect(() => {
    const handleNatsIsSynced = () => {
      if (!notifyOnSync) return
      notificationId.current = addNotification({
        id: notificationId.current,
        type: 'success',
        content: t('file:file_synced')
      })
      setNotifyOnSync(false)
    }
    document.addEventListener(CUSTOM_EVENT_NAMES.NATS_IS_SYNCED, handleNatsIsSynced)
    return () => {
      document.removeEventListener(CUSTOM_EVENT_NAMES.NATS_IS_SYNCED, handleNatsIsSynced)
      if (notificationId.current) {
        removeNotification(notificationId.current)
      }
      notificationId.current = ''
    }
  }, [addNotification, notifyOnSync, removeNotification, t])

  return (
    <Dialog
      size="xs"
      title={t('workspace:dialog.unsaved_changes')}
      confirmBtnText={t('workspace:dialog.just_leave')}
      cancelBtnText={t('workspace:dialog.stay')}
      onConfirm={handleDialogConfirm}
      onCancel={handleDialogCancel}
      open={navDialogOpen}
    >
      {t('workspace:dialog.leave_before_file_sync')}
    </Dialog>
  )
}

export default FileSyncDialog
