import React, { forwardRef, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import theme from '../../../theme/dark'
import Icon, { IconSize } from '../Icon'

export interface AvatarProps {
  size: 's' | 'm' | 'l' | number
  radiusSize?: 'sm' | 'md' | 'lg' | 'xl' | 'circle'
  alt?: string
  src?: string | null
  pending?: boolean
  anonymous?: boolean
  overflow?: number
  className?: string
  bg?: string
  color?: string
  children?: React.ReactNode
  showOverflowText?: boolean
  isIdle?: boolean
  icon?: string
}

const getTextSizeClass = (size: number) => {
  if (size >= 72) {
    return 'text-[30px]'
  } else if (size >= 48) {
    return 'text-[23px]'
  } else if (size >= 32) {
    return 'text-14'
  } else if (size >= 28) {
    return 'text-12'
  }
  return 'text-9'
}

export const sizeMap = {
  s: 16,
  m: 28,
  l: 32
}

const getComputedStyleClassName = ({
  size,
  alt,
  anonymous,
  pending,
  hasOverflow
}: {
  size: AvatarProps['size']
  alt: string
  anonymous: AvatarProps['anonymous']
  pending: AvatarProps['pending']
  hasOverflow: boolean
}) => {
  const currentSize = typeof size === 'string' ? sizeMap[size] : size

  const basicClassName = `${getTextSizeClass(currentSize)} ${hasOverflow ? 'hover:text-white' : ''} ${
    anonymous ? 'border border-dashed border-light-overlay-40' : ''
  }`

  if (anonymous) {
    return { imageSize: currentSize, bgColor: '', styleClassName: `text-white ${basicClassName}` }
  } else if (pending) {
    return {
      imageSize: currentSize,
      bgColor: theme.colors.neutrals[70],
      styleClassName: `text-light-overlay-60 ${basicClassName}`
    }
  } else if (hasOverflow) {
    return {
      imageSize: currentSize,
      bgColor: theme.colors.lightOverlays[10],
      styleClassName: `text-light-overlay-60 ${basicClassName}`
    }
  }
  const h = alt.split('').reduce((acc, cur) => acc * 3 + cur.charCodeAt(0), 0) % 360
  const convertedColor = convertHSLtoRGB(h / 360, 1, 0.66)
  const textColor = contrastTextColor(convertedColor)
  return {
    imageSize: currentSize,
    bgColor: `rgb(${convertedColor.join(',')})`,
    styleClassName: `${textColor} ${basicClassName}`
  }
}

const contrastTextColor = ([r, g, b]: [number, number, number]) => {
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 147 ? 'text-black' : 'text-white'
}
const convertHueToRGB = (p: number, q: number, ot: number): number => {
  let t = ot
  if (t < 0) t += 1
  if (t > 1) t -= 1
  if (t < 1 / 6) return p + (q - p) * 6 * t
  if (t < 1 / 2) return q
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
  return p
}
const convertHSLtoRGB = (h: number, s: number, l: number): [number, number, number] => {
  let r: number, g: number, b: number

  if (s === 0) {
    r = l
    g = l
    b = l
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q
    r = convertHueToRGB(p, q, h + 1 / 3)
    g = convertHueToRGB(p, q, h)
    b = convertHueToRGB(p, q, h - 1 / 3)
  }

  return [r, g, b].map((c) => Math.round(c * 255)) as [number, number, number]
}
export const radiusSizeMap = {
  xs: 'rounded-xs',
  sm: 'rounded-sm',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  circle: 'rounded-circle'
}

const pendingIconMap: Record<AvatarProps['size'], [string, IconSize]> = {
  s: ['PendingUser16', 'l'],
  m: ['PendingUser28', 'xxxl'],
  l: ['PendingUser32', 'xxxxl']
}

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  (
    {
      size = 'm',
      alt = '',
      radiusSize = 'md',
      src,
      pending,
      anonymous,
      overflow,
      className = '',
      isIdle = false,
      showOverflowText = false,
      icon
    },
    ref
  ) => {
    const { t } = useTranslation('common')
    const imgRef = useRef<HTMLImageElement>(null)
    const [failedAttempts, setFailedAttempts] = useState(0)

    const hasOverflow = overflow !== undefined
    const hasAlt = !!alt
    const altChar = hasAlt ? Array.from(alt)[0].toUpperCase() : ''
    const overFlowChar = hasOverflow ? (overflow > 99 ? '99+' : overflow > 9 ? '9+' : `${overflow}`) : ''
    const char = anonymous ? 'A' : hasOverflow ? overFlowChar : altChar

    const { imageSize, bgColor, styleClassName } = useMemo(() => {
      return getComputedStyleClassName({ size, alt, anonymous, pending, hasOverflow })
    }, [size, alt, anonymous, pending, hasOverflow])

    const handleBrokenImage = useCallback(() => {
      if (failedAttempts < 3) return
      setFailedAttempts((prev) => prev + 1)
      if (imgRef.current) {
        imgRef.current.style.display = 'none'
      }
    }, [failedAttempts])

    const handleImageLoad = () => setFailedAttempts(0)
    const cornerRadius = radiusSizeMap[radiusSize]
    const renderAvatarImageContent = () => {
      if (pending) {
        return <Icon name={pendingIconMap[size][0]} size={pendingIconMap[size][1]} className="cursor-default" />
      }
      if (src && !anonymous && !hasOverflow) {
        return (
          <img
            className="absolute top-0 left-0 w-full h-full object-cover"
            src={src}
            alt={alt}
            ref={imgRef}
            onError={handleBrokenImage}
            onLoad={handleImageLoad}
          />
        )
      }
      return char
    }
    const renderAvatarContent = () => {
      if (size === 's' && hasOverflow) {
        return (
          <div
            ref={ref}
            className={`relative inline-flex justify-center items-center align-middle overflow-hidden group cursor-pointer ${className}`}
          >
            <Icon
              name="More"
              size="l"
              className="rounded-sm bg-light-overlay-10 group-hover:bg-light-overlay-15 group-hover:text-white p-2"
            />
            {showOverflowText && (
              <span className="pl-8 text-11 text-light-overlay-60 group-hover:text-white ">
                {overflow} {t('more')}
              </span>
            )}
          </div>
        )
      }
      if (icon) {
        return (
          <div
            ref={ref}
            className={`relative inline-flex justify-center items-center align-middle overflow-hidden bg-light-overlay-10 ${cornerRadius} ${styleClassName} ${className}`}
            style={{
              width: `${imageSize}px`,
              height: `${imageSize}px`
            }}
          >
            <Icon name={icon} useCurrentColor />
          </div>
        )
      }
      return (
        <div
          ref={ref}
          className={`relative inline-flex justify-center items-center align-middle overflow-hidden ${cornerRadius} ${styleClassName} ${className}`}
          style={{
            backgroundColor: src || pending ? 'transparent' : bgColor, // because the bg color is computed in runtime, tailwind can not prepare the css class for it
            width: `${imageSize}px`,
            height: `${imageSize}px`,
            opacity: isIdle ? '0.4' : '1'
          }}
        >
          {renderAvatarImageContent()}
        </div>
      )
    }

    return renderAvatarContent()
  }
)

Avatar.displayName = 'Avatar'

export default React.memo(Avatar)
