import { Line } from "@phase-software/data-utils"
import { Viewport } from "../Viewport"
import { HANDLES } from "../actions/handles"
import { Rect2, Transform2D, Vector2 } from "../math"
import { idMap } from "../utils/node"
import { WASM } from "../wasm/platforms/wasm"
import { MIN_SIZE_THRESHOLD, SCREEN_FONT_SIZE } from "../constants"
import { shouldHandlesHidden } from "../panes"
import { isPointInNodeHitTest } from "../actions/selection"

/**
 * @typedef {import('@phase-software/data-store/src/DataStore').DataStore} DataStore
 * @typedef {import('@phase-software/data-utils').Vertex} Vertex
 * @typedef {import('./VisualServer').VisualServer} VisualServer
 * @typedef {import('./SpatialCache').SceneNode} SceneNode
 * @typedef {import('./SpatialCache').RenderItem} RenderItem
 * @typedef {import("./Selection").Selection} Selection
 * @typedef {import("../node-setup").Mesh} Mesh
 * @typedef {Map<NodeID, SceneNode>} NodeBank
 * @typedef {{ type: string, data: any }} OpData
 * @typedef {string} NodeID
 */

export const HIT_TOLERANCE = {
    VERT_HALF_BOX_WIDTH: 8,
    ORIGIN_HALF_BOX_WIDTH: 8,
    ROTATE_HANDLES_HALF_BOX_WIDTH: 12,
    RESIZE_HANDLES_HALF_BOX_WIDTH: 4,
    ROTATE_HANDLES_RADIUS: 14,
    MOVE_LINE_RADIUS: 0.5,
}
const MOUSE_RADIUS = 2.5

let _visualServer

class Scene {
    /** @type {Map<NodeID, SceneNode>} */
    node_bank = new Map()
    /** @type {NodeID} */
    root = ""

    /**
     * @param {ComputeContext} ctx
     * @param {SceneNode} node
     * @param {NodeID} parent_id
     */
    addNewNode(ctx, node, parent_id) {
        this.node_bank.set(node.id, node)
        const parentWorld = parent_id ? ctx.transform.get(parent_id).world : Transform2D.IDENTITY.clone()
        updateTransformAndLockedAndHiddenRecursively(ctx, this.node_bank, node.id, parentWorld)
        calculateBoundingBox(ctx, this.node_bank, node.id, false)
        calculateSubtreeBounds(ctx, this.node_bank, node.id)
    }

    addScreenNode(ctx, node, selectable, zoom, size) {
        this.addNewNode(ctx, node)
        const nameBBox = getScreenNameBBox(node, selectable, zoom, size)
        ctx.screen_name.set(node.id, nameBBox)
    }
}

/**
 * @template T
 * @template K
 * @param {T} data
 * @param {keyof T} key
 * @param {K} default_value
 * @returns {K}
 */
export function optional(data, key, default_value) {
    if (key in data) {
        return data[key]
    }
    return default_value
}

/**
 * @template T
 * @param {T} obj
 * @returns {T}
 */
export function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj))
}

/**
 * @param {number} num
 * @param {number} max_digits
 * @returns
 */
export function num2str(num, max_digits) {
    let total_p = max_digits
    const dot_index = num.toString().indexOf('.')
    if (dot_index >= 0) {
        total_p += dot_index
    }
    return parseFloat(num.toPrecision(total_p)).toString()
}



class ComputeContext {
    /** @type {Map<NodeID, {world: Transform2D, local: Transform2D>}} */
    transform = new Map()
    /** @type {Map<NodeID, {world: Rect2, visualWorld: Rect2, local: Rect2, subtree: Rect2>}} */
    bounds = new Map()
    /** @type {Map<NodeID, Rect2>}} */
    screen_name = new Map()

    // if element itself is locked or its parent is locked, it will be set to locked here
    /** @type {Map<NodeID, boolean>}} */
    locked = new Map()

    // if element itself is hidden or its parent is hidden / BooleanGroup / MaskGroup,
    // it will be set to hidden here
    /** @type {Map<NodeID, boolean>}} */
    hidden = new Map()
}

export class HitTest {
    constructor(visualServer) {
        /** @type {VisualServer} */
        this.visualServer = visualServer
        _visualServer = visualServer
    }

    state = {
        compute: new ComputeContext(),

        /** @type {Scene} */
        scene: new Scene(),

        bg_color: "#F5F5F5",
        viewport: new Viewport(),

        /** Prompt info displayed at bottom status bar */
        prompt: "",

        /** @type {NodeID} */
        hovered: null,
        /** @type {NodeID[]} */
        selected: [],

        selection_box: new Rect2(),

        grid: {
            visible: true,
            spacing: 128,
            divisions: 2,
            gutter: 0,
        },

        snapping: {
            axis: false,
            obb: {
                points: true,
                center: true,
            },
            grid: false,
            pixel: false,

            result: {
                dir: null,
                coord: NaN,
            },
        },

        move: {
            /** @type {Map<NodeID, { translate: Vector2, world: Transform2D, size: Vector2, origin: Vector2 }>} */
            old: new Map(),
        },

        resize: {
            /**
             * 0 = invalid
             * 1 = left
             * 2 = top_left
             * 3 = top
             * 4 = top_right
             * 5 = right
             * 6 = bottom_right
             * 7 = bottom
             * 8 = bottom_left
             */
            handle: 0,
            keep_aspect: false,
            from_center: false,

            prev_size: new Vector2(),
            /** @type {Map<NodeID, { translate: Vector2, world: Transform2D, size: Vector2, origin: Vector2, rotation: number, skew: Vector2 }>} */
            old: new Map(),
            /** @type {{ box: Rect2, world: Transform2D, origin: Vector2}} */
            old_selection: {},
        },

        path: {
            /**
             * whether draw curve preview to current mouse cursor
             * @type {boolean}
             */
            rubber_band: true,

            /**
             * Modes for point appending operation
             * - quadratic: adjust handle of last point, to make a quadratic curve out of "append_point", "append_point_handle_end" and "mouse_point" (Figma style)
             * - straight: apeend a point with 0 handle in/out (Affinity Designer style)
             * @type {"quadratic" | "straight"}
             */
            point_append_mode: "quadratic",

            /** @type {NodeID} */
            curr_node: null,

            /**
             * index of active subpath
             */
            active_subpath: 0,

            /** @type {"none" | "i" | "o"} */
            active_handle: "none",

            /**
             * Hovered point index: [subpath_index, point_index]
             */
            hovered: null,
            /**
             * Selected point indexes: [subpath_index, point_index]
             * @type {[number, number][]}
             */
            selected: [],
        },

        /**
         * @typedef {"none" | "click" | "single_select" | "multi_select" | "box_select" | "rect_creation" | "oval_creation" | "path_creation" | "path_edit" | "text_creation" | "container_creation" | "single_resize" | "single_select_move" | "multi_select_move"} EditState
         * @type {EditState}
         */
        edit_state: "none",
        /**
         * @typedef {"none" | "plus" | "edit" | "begin_subpath" | "append_point" | "move_handle"} EditSubState
         * @type {EditSubState}
         */
        edit_substate: "none",

        /**
         * @param {EditState} new_state
         * @param {EditSubState} new_substate
         */
        changeEditState(new_state, new_substate = "none") {
            if ((this.state.edit_state !== new_state) || (this.state.edit_substate !== new_substate)) {
                // eslint-disable-next-line no-negated-condition
                console.log(`[state] "${this.state.edit_state}${(this.state.edit_substate !== "none") ? (`.${  this.state.edit_substate}`) : ''}" -> "${new_state}${(new_substate !== "none") ? (`.${  new_substate}`) : ''}"`)
            }
            this.state.edit_state = new_state
            this.state.edit_substate = new_substate
        },

        mouse: {
            curr: new Vector2(0, 0),
            prev: new Vector2(NaN, NaN),
            double_click: {
                left: {
                    timer_start: null,
                    just_pressed: false,
                    just_released: false
                },
                right: {
                    timer_start: null,
                    just_pressed: false,
                    just_released: false
                }
            },

            left: {
                pressed: false,
                just_pressed: false,
                just_released: false,
                press_pos: new Vector2(NaN, NaN),
                press_pos_world: new Vector2(NaN, NaN),
            },
            middle: {
                pressed: false,
                just_pressed: false,
                just_released: false,
                press_pos: new Vector2(NaN, NaN),
                press_pos_world: new Vector2(NaN, NaN),
            },
            right: {
                pressed: false,
                just_pressed: false,
                just_released: false,
                press_pos: new Vector2(NaN, NaN),
                press_pos_world: new Vector2(NaN, NaN),
            },
        },

        /** @type {Map<string, { just_pressed: boolean, pressed: boolean, just_released: boolean }>} */
        key: new Map(),
        in_text_input: false,

        // operation for undo/redo
        op: {
            /** @type {OpData[]} */
            undo: [],
            /** @type {OpData[]} */
            redo: [],
        },

        pane: {
            /** @type {Pane} */
            root: null,
            /** @type {Pane} */
            fps: null,
        },
        /** @type {HTMLTextAreaElement} */
        text_edit: null,

    }

    update() {
        updateTransformAndLockedAndHiddenRecursively(this.state.compute, this.state.scene.node_bank, this.state.scene.root, Transform2D.IDENTITY)
        calculateBoundingBox(this.state.compute, this.state.scene.node_bank, this.state.scene.root, true)
        calculateSubtreeBounds(this.state.compute, this.state.scene.node_bank, this.state.scene.root)
        calculateScreenNameBounds(this.state.compute, this.state.scene.node_bank)
    }
}

/**
 * @param {ComputeContext} ctx
 * @param {Map<string, SceneNode>} node_bank
 * @param {NodeID} node_id
 * @param {Transform2D} parent_world
 */
function calcNodeTransform(ctx, node_bank, node_id, parent_world) {
    const node = node_bank.get(node_id)
    // local matrix from transform data
    const local = node.item.transform.local.clone()
    // calculate world matrix
    const world = parent_world.clone().append(local)
    // save compute local/world transforms
    let t = ctx.transform.get(node_id)
    if (!t) {
        t = { local, world }
        ctx.transform.set(node_id, t)
    }
    t.local.copy(local)
    t.world.copy(world)
}

/**
 * @param {ComputeContext} ctx
 * @param {NodeBank} node_bank
 * @param {NodeID} node_id
 * @param {Mat2D} parent_world
 * @param {boolean} parent_locked
 * @param {boolean} parent_hidden
 */
function updateTransformAndLockedAndHiddenRecursively(ctx, node_bank, node_id, parent_world, parent_locked = false, parent_hidden = false) {
    const node = node_bank.get(node_id)

    // locked
    const locked = parent_locked || node.item.locked
    ctx.locked.set(node_id, locked)

    // hidden
    const hidden = parent_hidden || (!node.item.visible)
    ctx.hidden.set(node_id, hidden)

    // transform
    calcNodeTransform(ctx, node_bank, node_id, parent_world)
    const world = ctx.transform.get(node_id).world
    // update children transform
    for (let index = 0; index < node.children.length; index++) {
        updateTransformAndLockedAndHiddenRecursively(ctx, node_bank, node.children[index].id, world, locked, hidden)
    }
}


/**
 * @param {ComputeContext} ctx
 * @param {NodeBank} node_bank
 * @param {NodeID} node_id
 * @param {boolean} updateChildren
 */
function calculateBoundingBox(ctx, node_bank, node_id, updateChildren = false) {
    const node = node_bank.get(node_id)

    const local_bbox = node.boundsLocal
    const world_bbox = node.boundsWorldAABB
    const vis_world_bbox = node.boundsVisualWorld

    if (ctx.bounds.has(node_id)) {
        const bounds = ctx.bounds.get(node_id)
        // update the bound in the context map
        bounds.local.copy(local_bbox)
        bounds.world.copy(world_bbox)
        bounds.visualWorld.copy(vis_world_bbox)
    } else {
        // create a bound in the context map
        ctx.bounds.set(node_id, {
            world: world_bbox.clone(),
            visualWorld: vis_world_bbox.clone(),
            local: local_bbox.clone(),
            subtree: world_bbox.clone(),
        })
    }

    if (updateChildren) {
        for (let index = 0; index < node.children.length; index++) {
            calculateBoundingBox(ctx, node_bank, node.children[index].id, true)
        }
    }
}

/**
 * @param {ComputeContext} ctx
 * @param {Map<NodeID, SceneNode>} node_bank
 * @param {NodeID} node_id
 */
function calculateSubtreeBounds(ctx, node_bank, node_id) {
    const node = node_bank.get(node_id)
    const bounds = ctx.bounds.get(node_id)
    if (node.children.length === 0) {
        bounds.subtree.copy(bounds.visualWorld)
        return
    }
    const subtree = bounds.visualWorld.clone()
    for (let index = node.children.length - 1; index >= 0 ; index--) {
        calculateSubtreeBounds(ctx, node_bank, node.children[index].id)
        subtree.merge_with(ctx.bounds.get(node.children[index].id).subtree)
    }
    bounds.subtree.copy(subtree.clone())
}

/**
 * @param {ComputeContext} ctx
 * @param {Map<NodeID, SceneNode>} node_bank
 */
function calculateScreenNameBounds(ctx, node_bank) {
    for (const item of ctx.screen_name) {
        const id = item[0]
        const element = _visualServer.dataStore.getById(id)
        const selectable = element.get('visible') && !element.get('locked')
        const zoom = _visualServer.dataStore.workspace.get('scale')
        const pane = _visualServer.overlay.panes[0]
        const nameSize = pane.measureText(element.get('name'), 'Roboto', SCREEN_FONT_SIZE)
        const node = node_bank.get(id)
        const nameBBox = getScreenNameBBox(node, selectable, zoom, nameSize)
        ctx.screen_name.set(id, nameBBox)
    }
}

/**
 * Check if a point is inside a bounding box with a tolerance (enlarge space)
 * @param {Rect} bbox
 * @param {Vector2} point
 * @returns {boolean}
 */
const isPointInBbox = (bbox, point) => {
    const enlarge_space = MOUSE_RADIUS / _visualServer.viewport.scale
    rect.set(bbox.x - enlarge_space, bbox.y - enlarge_space, bbox.width + enlarge_space * 2, bbox.height + enlarge_space * 2)
    return rect.contains(point)
}

/**
 * Check if a point is inside a node mesh with a tolerance (enlarge space)
 * @param {ComputeContext} ctx
 * @param {NodeBank} node_bank
 * @param {NodeID} node_id
 * @param {Vector2} point
 * @returns {boolean}
 */
function isPointInNode(ctx, node_bank, node_id, point) {
    // Generate multiple points around the mouse point as a circle
    const enlarge_space = MOUSE_RADIUS / _visualServer.viewport.scale
    const points = []
    const deg = 30
    for (let i=0; i<360/deg ; i++) {
        points.push(new Vector2(point.x + enlarge_space * Math.cos(i * deg * Math.PI / 180), point.y + enlarge_space * Math.sin(i * deg * Math.PI / 180)))
    }
    for (let i = 0; i<points.length; i++) {
        if(_isPointInNode(ctx, node_bank, node_id, points[i])) return true
    }
    return false
}

/**
 * Check if a point is exactly inside a node mesh
 * @param {ComputeContext} ctx
 * @param {NodeBank} node_bank
 * @param {NodeID} node_id
 * @param {Vector2} point
 * @returns {boolean}
 */
export function _isPointInNode(ctx, node_bank, node_id, point) {
    const element = _visualServer.dataStore.getById(node_id)

    // If the element is a line element and without stroke layer, we have to check if the point is intersect with the path
    // TODO: shouldn't it be the rule on all path element that has not stroke and fill layer?
    let onLinePath = false
    if (element.isLineElement()) {
        const dis = HIT_TOLERANCE.MOVE_LINE_RADIUS / _visualServer.viewport.projectionTransform.a

        const transform = ctx.transform.get(node_id)
        /** @type {Mesh} */
        const mesh = element.get('geometry').get('mesh')
        const edgesArray = Array.from(mesh.edges.values())
        for (let i = 0; i < edgesArray.length; i++) {
            const start = transform.world.xform(mesh.getVertPos(edgesArray[i].v.id))
            const end = transform.world.xform(mesh.getVertPos(edgesArray[i].w.id))
            line.setP(start, end)
            const n = line.nearest(point)
            const mouseDist = line.eval(n.t).subtract(point)
            if (mouseDist.length_squared() <= dis) {
                onLinePath = true
            }
        }
    }

    let node = node_bank.get(node_id)

    // mask node
    if (node.item.isMaskGroup() && node.children.length) {
        node = node.children[node.children.length - 1]
    }

    const { x, y } = node.item.transform.size
    const isZeroSize = x < MIN_SIZE_THRESHOLD && y < MIN_SIZE_THRESHOLD
    if (isZeroSize) return false

    const world = ctx.transform.get(node.id).world.clone()
    const local_point = world.clone().affine_inverse().xform(point)
    const geo_id = idMap.get(node.id)
    const type = WASM().isPointInsideGeometry(geo_id, local_point.x, local_point.y)

    const isStrokeOnly = (node.item.fillLayers.length === 0 && node.item.strokeLayers.length !== 0)
    if (isStrokeOnly) {
        if (node.item.isContainerNormalGroup()) {
            return type > 0 || onLinePath
        }
        else return type === 3 || onLinePath
    }

    return type > 0 || onLinePath
}

/**
 * @param {ComputeContext} ctx
 * @param {boolean} modifier
 * @param {NodeBank} node_bank
 * @param {NodeID} root_id
 * @param {Vector2} point
 * @param {boolean} ignore_root
 * @param {boolean} double_click
 * @returns {NodeID}
 */
function searchTopNodeAtPoint(ctx, modifier, node_bank, root_id, point, ignore_root, double_click = false) {
    const locked = ctx.locked.get(root_id)
    const hidden = ctx.hidden.get(root_id)
    if (locked || hidden) return null

    const node = node_bank.get(root_id)
    const isDeepSelect = modifier
    const bbox = ctx.bounds.get(root_id)

    const isMaskElement = (node.parent && node.parent.item.isMaskGroup() && node.id === node.parent.children[node.parent.children.length - 1].id)
    if (isMaskElement && !double_click) return null

    const isClipped = (node.item.isMaskGroup() || node.item.isBooleanGroup() || node.item.clipping)

    // subtree bbox should contains the point if node contains it or any of its children does
    if (isClipped ? isPointInBbox(bbox.visualWorld, point) : isPointInBbox(bbox.subtree, point)) {
        // point is out of the node itself, but still contained by any child nodes
        const isGroup = node.item.isContainerNormalGroup() || node.item.isMaskGroup() || node.item.isBooleanGroup()

        if (ignore_root || !isPointInBbox(bbox.visualWorld, point) || (isDeepSelect && isGroup)) {
            for (let i = node.children.length - 1; i >= 0; i--) {
                const child_id = searchTopNodeAtPoint(ctx, modifier, node_bank, node.children[i].id, point, false, double_click)
                if (child_id !== null) {
                    return child_id
                }
            }
            if (isPointInBbox(bbox.visualWorld, point) && (double_click)) {
                if (isPointInNode(ctx, node_bank, root_id, point)) {
                    if ((isDeepSelect && isGroup)) return null
                    return root_id
                }
            }
            return null
        } else {
            if (!isPointInNode(ctx, node_bank, root_id, point) || node.item.isNormalGroup()) {
                // if the container has corner radius, when hover on the corner
                // should check if the children overlap on the corner
                const node = node_bank.get(root_id)
                if ((node.item.isContainer() && !node.item.clipping) || node.item.isNormalGroup()) {
                    for (let i = node.children.length - 1; i >= 0; i--) {
                        const child_id = searchTopNodeAtPoint(ctx, modifier, node_bank, node.children[i].id, point, false, double_click)
                        if (child_id !== null) {
                            return root_id
                        }
                    }
                }
                return null
            }
            return root_id
        }
    }
    return null
}

/**
 * @param {ComputeContext} ctx
 * @param {NodeBank} node_bank
 * @param {NodeID} root_id
 * @param {Vector2} point
 * @param {boolean} ignore_root
 * @returns {NodeID[]}
 */
export function searchNodesAtPoint(ctx, node_bank, root_id, point, ignore_root) {
    const bbox = ctx.bounds.get(root_id)
    const nodes = []
    // subtree bbox should contains the point if node contains it or any of its children does
    if (isPointInBbox(bbox.subtree, point)) {
        // point is out of the node itself, but still contained by any child nodes
        const node = node_bank.get(root_id)
        const locked = ctx.locked.get(root_id)
        const hidden = ctx.hidden.get(root_id)
        if (locked || hidden) return null
        // check whether the root node contains the point
        if (!ignore_root && isPointInNode(ctx, node_bank, root_id, point)) {
            nodes.push(root_id)
        }
        // collect all children which contain the point
        for (let i = node.children.length - 1; i >= 0; i--) {
            const child_ids = searchNodesAtPoint(ctx, node_bank, node.children[i].id, point, false)
            if (child_ids && child_ids.length > 0) {
                nodes.push(...child_ids)
            }
        }
    }
    return nodes
}

/**
 * @param {ComputeContext} ctx
 * @param {Vector2} point
 * @returns {NodeID[]}
 */
export function searchScreenNamesAtPoint(ctx, point) {
    const names = []
    for (const [id, bbox] of ctx.screen_name) {
        const locked = ctx.locked.get(id)
        const hidden = ctx.hidden.get(id)
        if (locked || hidden) continue
        if (bbox.contains(point)) {
            names.push(id)
        }
    }
    return names
}

/**
 * @param {object} state
 * @param {Vector2} mouse_pos
 * @param {Selection} selection
 * @param {boolean} modifier
 */
export function findHoveredTopNodeEqOrBelowDepth(state, mouse_pos, selection, modifier) {
    const node_id = selection.first.id
    const node_bank = state.scene.node_bank
    const root_id = state.scene.node_bank.get(state.scene.root).children[0].id

    const node = node_bank.get(node_id)
    const max_depth = node.item.type === 'screen' ? node.item.depth + 1 : node.item.depth
    const found_id = searchTopNodeAtPointEqOrBelowDepth(state, node_bank, root_id, mouse_pos, max_depth, modifier, true)
    const isDeepSelect = modifier
    if (found_id) {
        let found = node_bank.get(found_id)
        if (!isDeepSelect) {
            // if in the deep select state, then use found node directly
            while (found.item.depth > max_depth) {
                found = node_bank.get(found.parent.id)
            }
            if (found.item.depth === max_depth && selection.bounds.has_point(mouse_pos)) {
                state.hovered = found.id
                return
            }
        }
        state.hovered = root_id === found.id ? null : found.id
    } else {
        state.hovered = null
        for (const [id, box] of state.compute.screen_name) {
            if (isPointInBbox(box, mouse_pos)) {
                state.hovered = id
            }
        }
    }
}

/**
 * @param {object} state
 * @param {NodeBank} node_bank
 * @param {NodeID} root_id
 * @param {Vector2} point
 * @param {number} max_depth
 * @param {boolean} modifier
 * @param {boolean} ignore_root
 * @returns {NodeID}
 */
function searchTopNodeAtPointEqOrBelowDepth(state, node_bank, root_id, point, max_depth, modifier, ignore_root) {
    const locked = state.compute.locked.get(root_id)
    const hidden = state.compute.hidden.get(root_id)
    if (locked || hidden) return null

    const isDeepSelect = modifier
    const node = node_bank.get(root_id)

    const isMaskElement = (node.parent && node.parent.item.isMaskGroup() && node.id === node.parent.children[node.parent.children.length - 1].id)
    if (isMaskElement) return null

    // if in the deep select state, then skip the comparison of depth
    if (node.item.depth > max_depth && !isDeepSelect) return null
    const bbox = state.compute.bounds.get(root_id)

    const isClipped = (node.item.isMaskGroup() || node.item.isBooleanGroup() || node.item.clipping)

    // subtree bbox should contains the point if node contains it or any of its children does
    if (isClipped ? isPointInBbox(bbox.visualWorld, point) : isPointInBbox(bbox.subtree, point)) {
        // point is out of the node itself, but still contained by any child nodes
        // if in the deep select state and the node is group type then skip the comparison of depth and skip
        // hovered is the node's children then check the mouse is hovering other child
        const selectedNode = node_bank.get(_visualServer.selection.first.id)
        const isGroup = node.item.isContainerNormalGroup() || node.item.isMaskGroup() || node.item.isBooleanGroup()

        if (ignore_root || !isPointInBbox(bbox.visualWorld, point) || (isDeepSelect && isGroup) || selectedNode.parent.id === root_id || node.item.isContainerNormalGroup()) {
            // children still within depth range? go check them
            if (node.item.depth + 1 <= max_depth || isDeepSelect) {
                for (let i = node.children.length - 1; i >= 0; i--) {
                    const child_id = searchTopNodeAtPointEqOrBelowDepth(state, node_bank, node.children[i].id, point, max_depth, modifier)
                    if (child_id !== null) {
                        return child_id
                    }
                }
            }

            if (isPointInNode(state.compute, node_bank, root_id, point) && !isDeepSelect) {
                if (node.item.isNormalGroup()) {
                    for (let i = node.children.length - 1; i >= 0; i--) {
                        const child_id = searchTopNodeAtPoint(state.compute, modifier, node_bank, node.children[i].id, point, false, false)
                        if (child_id !== null) {
                            return root_id
                        }
                    }
                    return null
                }
                return root_id
            } else {
                return null
            }
        } else {
            if ((isDeepSelect && isGroup) || !isPointInNode(state.compute, node_bank, root_id, point)) {
                return null
            }
            return root_id
        }
    }
    return null
}


/**
 * @param {object} state
 * @param {boolean} modifier
 * @param {Vector2} mouse_pos
 * @param {NodeID} root_id
 * @param {NodeID} parent_of_selectable
 * @param {boolean} double_click
 */
export function findHoveredTopNode(state, modifier, mouse_pos, root_id, parent_of_selectable, double_click = false) {
    /** @type {ComputeContext} */
    const ctx = state.compute
    const node_bank = state.scene.node_bank
    const isDeepSelect = modifier
    /** @type {NodeID} */
    let hovered = searchTopNodeAtPoint(ctx, modifier, node_bank, root_id, mouse_pos, true, double_click)
    let found = node_bank.get(hovered)
    if (!isDeepSelect && !double_click ) {
        while (found && found.parent.id !== parent_of_selectable) {
            found = node_bank.get(found.parent.id)
        }
    }
    hovered = found ? found.id : null
    if (!hovered) {
        // TODO: search screen by their name box
        for (const [id, box] of ctx.screen_name) {
            if (isPointInBbox(box, mouse_pos)) {
                hovered = id
            }
        }
    }

    state.hovered = hovered
}

/**
 * Find innermost and topmost Scalable node, return screen node if no node found
 *
 * Scalable = screen or container or normal group (not include boolean group or mask group)
 *
 * @param {object} state
 * @param {Vector2} mouse_pos world position of mouse
 * @param {NodeID} root_id
 * @returns {string}
 */
export function findScalableNode(state, mouse_pos, root_id) {
    /** @type {ComputeContext} */
    const ctx = state.compute
    const node_bank = state.scene.node_bank
    /** @type {NodeID} */
    const found_id = searchScalableNode(ctx, node_bank, root_id, mouse_pos)
    return found_id || _visualServer.indexer.getScreenNode().id
}

/**
 * @param {object} state
 * @param {Vector2} mouse_pos
 * @param {NodeID} root_id
 */
export function findHoveredScalableNode(state, mouse_pos, root_id) {
    const id = findScalableNode(state, mouse_pos, root_id)
    state.hovered = id
}

/**
 * @param {ComputeContext} ctx
 * @param {NodeBank} node_bank
 * @param {NodeID} root_id
 * @param {Vector2} point
 * @param mouse_pos
 */
function searchScalableNode(ctx, node_bank, root_id, mouse_pos) {
    const locked = ctx.locked.get(root_id)
    const hidden = ctx.hidden.get(root_id)
    if (locked || hidden) return null

    const node = node_bank.get(root_id)

    const isScalable = node.item.isScreen() || node.item.isContainerNormalGroup()
    if (!isScalable) return null

    const bbox = ctx.bounds.get(root_id)
    if (isPointInBbox(bbox.subtree, mouse_pos)) {
        // looking for innermost & topmost node
        for (let i = node.children.length - 1; i >= 0; i--) {
            const child_id = searchScalableNode(ctx, node_bank, node.children[i].id, mouse_pos)
            if (child_id !== null) {
                return child_id
            }
        }

        if (isPointInNode(ctx, node_bank, root_id, mouse_pos)) {
            return root_id
        }
    }
    return null
}


/**
 * @param {object} state
 * @param {Vector2} mouse_pos
 * @param {Selection} selection
 * @param {boolean} ignore_root
 * @returns {NodeID}
 */
export function findHoverSelection(state, mouse_pos, selection, ignore_root = false) {
    if (selection.size < 1)  return false
    const root_id = _visualServer.dataStore.workspace.children[0].get('id')
    /** @type {ComputeContext} */
    for (const selected of selection.iter()) {
        if (ignore_root && root_id === selected.id) continue
        const bbox = state.compute.bounds.get(selected.id).local
        const world = state.compute.transform.get(selected.id).world
        const local_point = world.clone().affine_inverse().xform(mouse_pos)
        if (isPointInBbox(bbox, local_point)) {
            if (selected.element.isLineElement()) {
                if (isPointInNode(state.compute, state.scene.node_bank, selected.id, mouse_pos)) return selected.id
            } else {
                return selected.id
            }
        }
    }
    return null
}

/**
 * @param px
 * @param py
 * @param p1x
 * @param p1y
 * @param p2x
 * @param p2y
 */
function Parallelogram(px, py, p1x, p1y, p2x, p2y) {
    /** @type {Vector2} */
    this.p = new Vector2(px, py)
    /** @type {Vector2} */
    this.p1 = new Vector2(p1x, p1y)
    /** @type {Vector2} */
    this.p2 = new Vector2(p2x, p2y)
}
Parallelogram.prototype = {
    constructor: Parallelogram,
    set(px, py, p1x, p1y, p2x, p2y) {
        this.p.set(px, py)
        this.p1.set(p1x, p1y)
        this.p2.set(p2x, p2y)
        return this
    },
    setP(p, p1, p2) {
        this.p.copy(p)
        this.p1.copy(p1)
        this.p2.copy(p2)
        return this
    },
    isPointInside(point) {
        const A = this.p1.clone().subtract(this.p)
        const B = this.p2.clone().subtract(this.p)
        if((A.x === 0 && A.y === 0) || (B.x === 0 && B.y === 0)) return false

        const C = point.clone().subtract(this.p)
        if (C.x === 0 && C.y === 0) return false

        const area_ab = A.cross(B)
        const area_ac = A.cross(C)
        const area_ba = -1 * area_ab
        const area_bc = B.cross(C)
        if (Math.sign(area_ab) === Math.sign(area_ac) && Math.sign(area_ba) === Math.sign(area_bc)) {
            // Check the distance between the point and the line by comparing their areas.
            if (Math.abs(area_ab) >= Math.abs(area_ac) && Math.abs(area_ba) >= Math.abs(area_bc)) {
                return true
            }
        }
        return false
    },
}

const TINY_SIZE_THRESHOLD = 24
export const isTinySize = (transform, bbox) => {
    const threshold = TINY_SIZE_THRESHOLD / _visualServer.viewport.projectionTransform.a
    const bottomRight = transform.xform(bbox.bottomRight)
    const topRight = transform.xform(bbox.topRight)
    const bottomLeft = transform.xform(bbox.bottomLeft)
    const topLeft = transform.xform(bbox.topLeft)
    if (
        bottomRight.distance(topRight) < threshold
        || topRight.distance(topLeft) < threshold
        || topLeft.distance(bottomLeft) < threshold
        || bottomLeft.distance(bottomRight) < threshold
    ) return true
    return false
}

/**
 * @param {Rect2} bbox should be a world AABB bbox
 * @param {Vector2} mouse_pos
 */
export const getActiveHandleForAABB = (bbox, mouse_pos) => {
    const resize_handle_s = HIT_TOLERANCE.RESIZE_HANDLES_HALF_BOX_WIDTH / _visualServer.viewport.projectionTransform.a
    const rotate_handle_s = HIT_TOLERANCE.ROTATE_HANDLES_RADIUS / _visualServer.viewport.projectionTransform.a

    const point = _visualServer.viewport.toWorld(mouse_pos)
    const T = Transform2D.IDENTITY
    const isTiny = isTinySize(T, bbox)

    if(shouldHandlesHidden(bbox, T)) return null

    // corner resize
    const bottomRight = T.xform(bbox.bottomRight)
    const topRight = T.xform(bbox.topRight)
    const bottomLeft = T.xform(bbox.bottomLeft)
    const topLeft = T.xform(bbox.topLeft)
    if (isTiny) {
        rect.set(bottomRight.x, bottomRight.y, resize_handle_s, resize_handle_s)
        if(rect.contains(point)) return { item: null, handle: HANDLES.BOTTOM_RIGHT }
        rect.set(topRight.x, topRight.y - resize_handle_s, resize_handle_s, resize_handle_s)
        if(rect.contains(point)) return { item: null, handle: HANDLES.TOP_RIGHT }
        rect.set(bottomLeft.x - resize_handle_s, bottomLeft.y, resize_handle_s, resize_handle_s)
        if(rect.contains(point)) return { item: null, handle: HANDLES.BOTTOM_LEFT }
        rect.set(topLeft.x - resize_handle_s, topLeft.y - resize_handle_s, resize_handle_s, resize_handle_s)
        if(rect.contains(point)) return { item: null, handle: HANDLES.TOP_LEFT }
    } else {
        rect.set(bottomRight.x - resize_handle_s, bottomRight.y - resize_handle_s, resize_handle_s * 2, resize_handle_s * 2)
        if(rect.contains(point)) return { item: null, handle: HANDLES.BOTTOM_RIGHT }
        rect.set(topRight.x - resize_handle_s, topRight.y - resize_handle_s, resize_handle_s * 2, resize_handle_s * 2)
        if(rect.contains(point)) return { item: null, handle: HANDLES.TOP_RIGHT }
        rect.set(bottomLeft.x - resize_handle_s, bottomLeft.y - resize_handle_s, resize_handle_s * 2, resize_handle_s * 2)
        if(rect.contains(point)) return { item: null, handle: HANDLES.BOTTOM_LEFT }
        rect.set(topLeft.x - resize_handle_s, topLeft.y - resize_handle_s, resize_handle_s * 2, resize_handle_s * 2)
        if(rect.contains(point)) return { item: null, handle: HANDLES.TOP_LEFT }
    }

    // edge resize
    // top & bottom
    vec1.set(topLeft.y - topRight.y, topRight.x - topLeft.x).normalize().multiply(resize_handle_s, resize_handle_s)
    vec2.set(vec1.x, vec1.y).negate()
    // left & right
    vec3.set(topLeft.y - bottomLeft.y, bottomLeft.x - topLeft.x).normalize().multiply(resize_handle_s, resize_handle_s)
    vec4.set(vec3.x, vec3.y).negate()

    // const line_shrik = isTiny ? resize * 0.5 : resize
    const line_shrik = 0
    const double_width = isTiny ? 0 : 1
    // bottom
    parallelogram.setP(
        bottomLeft.clone().add(line_shrik, 0).add(vec1),
        bottomLeft.clone().add(line_shrik, 0).add(vec2.clone().multiply(double_width, double_width)),
        bottomRight.clone().add(-line_shrik, 0).add(vec1)
    )
    if (parallelogram.isPointInside(point)) return { item: null, handle: HANDLES.BOTTOM }
    // right
    parallelogram.setP(
        bottomRight.clone().add(0, line_shrik).add(vec4),
        bottomRight.clone().add(0, line_shrik).add(vec3.clone().multiply(double_width, double_width)),
        topRight.clone().add(0, -line_shrik).add(vec4)
    )
    if (parallelogram.isPointInside(point)) return { item: null, handle: HANDLES.RIGHT }
    // left
    parallelogram.setP(
        topLeft.clone().add(0, line_shrik).add(vec3),
        topLeft.clone().add(0, line_shrik).add(vec4.clone().multiply(double_width, double_width)),
        bottomLeft.clone().add(0, -line_shrik).add(vec3)
    )
    if (parallelogram.isPointInside(point)) return { item: null, handle: HANDLES.LEFT }
    // top
    parallelogram.setP(
        topRight.clone().add(line_shrik, 0).add(vec2),
        topRight.clone().add(line_shrik, 0).add(vec1.clone().multiply(double_width, double_width)),
        topLeft.clone().add(-line_shrik, 0).add(vec2)
    )
    if (parallelogram.isPointInside(point)) return { item: null, handle: HANDLES.TOP }

    // corner rotate
    parallelogram.setP(bottomLeft, topLeft, bottomRight)
    if (parallelogram.isPointInside(point)) return null

    if(bottomRight.distance_to(point) < rotate_handle_s) return { item: null, handle: HANDLES.BOTTOM_RIGHT_ROTATE }
    if(topRight.distance_to(point) < rotate_handle_s) return { item: null, handle: HANDLES.TOP_RIGHT_ROTATE }
    if(bottomLeft.distance_to(point) < rotate_handle_s) return { item: null, handle: HANDLES.BOTTOM_LEFT_ROTATE }
    if(topLeft.distance_to(point) < rotate_handle_s) return { item: null, handle: HANDLES.TOP_LEFT_ROTATE }

    return null
}

const items = []
/**
 * @param {SelectedItem[]} selectedItems
 * @param {Vector2} mouse_pos
 */
export const getActiveHandle = (selectedItems, mouse_pos) => {
    const resize_handle_s = HIT_TOLERANCE.RESIZE_HANDLES_HALF_BOX_WIDTH / _visualServer.viewport.projectionTransform.a
    const roate_handle_s = HIT_TOLERANCE.ROTATE_HANDLES_RADIUS / _visualServer.viewport.projectionTransform.a

    const point = _visualServer.viewport.toWorld(mouse_pos)

    items.length = 0
    // prepare data
    for (let i=0; i<selectedItems.length; i++) {
        const item = selectedItems[i]
        const T = item.node.item.transform.world
        const bbox = item.node.boundsLocalVisualZero
        const isTiny = isTinySize(T, bbox)

        let lineInfo = null
        if (item.element.isLineElement()) {
            const mesh = item.element.get('geometry').get('mesh')
            const edge = mesh.edges.values().next().value
            const duVpos = mesh.getVertPos(edge.v.id)
            const duWPos = mesh.getVertPos(edge.w.id)
            const signX = Math.sign(duWPos.x - duVpos.x)
            const signY = Math.sign(duWPos.y - duVpos.y)
            const p1 = new Vector2()
            const p2 = new Vector2()
            if (signX * signY > 0) {
                p1.copy(T.xform(bbox.bottomRight))
                p2.copy(T.xform(bbox.topLeft))
            } else {
                p1.copy(T.xform(bbox.bottomLeft))
                p2.copy(T.xform(bbox.topRight))
            }
            lineInfo = { signX, signY, p1, p2 }
        }
        items.push({ item, T, bbox, isTiny, lineInfo })
    }

    // corner resize
    for (let i=0; i<items.length; i++) {
        const { item, T, bbox, isTiny, lineInfo } = items[i]

        if (item.node.item.isEmpty || shouldHandlesHidden(bbox, T)) {
            continue
        }

        const rotation = T.clone().get_rotation()
        transform.reset().rotate(rotation)

        if (item.element.isLineElement()) {
            const { signX, signY, p1, p2 } = lineInfo

            vec1.set(resize_handle_s, 0)
            vec2.set(0, resize_handle_s)
            transform.basis_xform(vec1, vec1)
            transform.basis_xform(vec2, vec2)
            vec3.copy(vec1).negate()
            vec4.copy(vec2).negate()
            vec1.multiply(2, 2)
            vec2.multiply(2, 2)
            if (signX * signY > 0) {
                // rect.set(p1.x - resize_handle_s, p1.y - resize_handle_s, resize_handle_s * 2, resize_handle_s * 2)
                // if(rect.contains(point)) return { item, handle: HANDLES.BOTTOM_RIGHT }
                // rect.set(p2.x - resize_handle_s, p2.y - resize_handle_s, resize_handle_s * 2, resize_handle_s * 2)
                // if(rect.contains(point)) return { item, handle: HANDLES.TOP_LEFT }

                const p1Start = p1.clone().add(vec3).add(vec4)
                parallelogram.setP(
                    p1Start,
                    p1Start.clone().add(vec1),
                    p1Start.clone().add(vec2),
                )
                if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.BOTTOM_RIGHT }
                const p2Start = p2.clone().add(vec3).add(vec4)
                parallelogram.setP(
                    p2Start,
                    p2Start.clone().add(vec1),
                    p2Start.clone().add(vec2),
                )
                if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.TOP_LEFT }
            } else if (signX === 0) {
                // rect.set(p1.x - resize_handle_s, p1.y - resize_handle_s, resize_handle_s * 2, resize_handle_s * 2)
                // if(rect.contains(point)) return { item, handle: HANDLES.BOTTOM_RIGHT }
                // rect.set(p2.x - resize_handle_s, p2.y - resize_handle_s, resize_handle_s * 2, resize_handle_s * 2)
                // if(rect.contains(point)) return { item, handle: HANDLES.TOP_LEFT }

                const p1Start = p1.clone().add(vec3).add(vec4)
                parallelogram.setP(
                    p1Start,
                    p1Start.clone().add(vec1),
                    p1Start.clone().add(vec2),
                )
                if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.BOTTOM_RIGHT }
                const p2Start = p2.clone().add(vec3).add(vec4)
                parallelogram.setP(
                    p2Start,
                    p2Start.clone().add(vec1),
                    p2Start.clone().add(vec2),
                )
                if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.TOP_LEFT }
            } else if (signY === 0) {
                // rect.set(p2.x - resize_handle_s, p2.y - resize_handle_s, resize_handle_s * 2, resize_handle_s * 2)
                // if(rect.contains(point)) return { item, handle: HANDLES.BOTTOM_RIGHT }
                // rect.set(p1.x - resize_handle_s, p1.y - resize_handle_s, resize_handle_s * 2, resize_handle_s * 2)
                // if(rect.contains(point)) return { item, handle: HANDLES.TOP_LEFT }

                const p2Start = p2.clone().add(vec3).add(vec4)
                parallelogram.setP(
                    p2Start,
                    p2Start.clone().add(vec1),
                    p2Start.clone().add(vec2),
                )
                if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.BOTTOM_RIGHT }
                const p1Start = p1.clone().add(vec3).add(vec4)
                parallelogram.setP(
                    p1Start,
                    p1Start.clone().add(vec1),
                    p1Start.clone().add(vec2),
                )
                if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.TOP_LEFT }
            } else {
                // rect.set(p1.x - resize_handle_s, p1.y - resize_handle_s, resize_handle_s * 2, resize_handle_s * 2)
                // if(rect.contains(point)) return { item, handle: HANDLES.BOTTOM_LEFT }
                // rect.set(p2.x - resize_handle_s, p2.y - resize_handle_s, resize_handle_s * 2, resize_handle_s * 2)
                // if(rect.contains(point)) return { item, handle: HANDLES.TOP_RIGHT }

                const p1Start = p1.clone().add(vec3).add(vec4)
                parallelogram.setP(
                    p1Start,
                    p1Start.clone().add(vec1),
                    p1Start.clone().add(vec2),
                )
                if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.BOTTOM_LEFT }
                const p2Start = p2.clone().add(vec3).add(vec4)
                parallelogram.setP(
                    p2Start,
                    p2Start.clone().add(vec1),
                    p2Start.clone().add(vec2),
                )
                if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.TOP_RIGHT }
            }
        } else {
            const bottomRight = T.xform(bbox.bottomRight)
            const topRight = T.xform(bbox.topRight)
            const bottomLeft = T.xform(bbox.bottomLeft)
            const topLeft = T.xform(bbox.topLeft)

            if (isTiny) {
                // rect.set(bottomRight.x, bottomRight.y, resize_handle_s, resize_handle_s)
                // if(rect.contains(point)) return { item, handle: HANDLES.BOTTOM_RIGHT }
                // rect.set(topRight.x, topRight.y - resize_handle_s, resize_handle_s, resize_handle_s)
                // if(rect.contains(point)) return { item, handle: HANDLES.TOP_RIGHT }
                // rect.set(bottomLeft.x - resize_handle_s, bottomLeft.y, resize_handle_s, resize_handle_s)
                // if(rect.contains(point)) return { item, handle: HANDLES.BOTTOM_LEFT }
                // rect.set(topLeft.x - resize_handle_s, topLeft.y - resize_handle_s, resize_handle_s, resize_handle_s)
                // if(rect.contains(point)) return { item, handle: HANDLES.TOP_LEFT }

                vec1.set(resize_handle_s, 0)
                vec2.set(0, resize_handle_s)
                transform.basis_xform(vec1, vec1)
                transform.basis_xform(vec2, vec2)
                vec3.copy(vec1).negate()
                vec4.copy(vec2).negate()

                // bottom right
                const bottomRightStart = bottomRight.clone()
                parallelogram.setP(
                    bottomRightStart,
                    bottomRightStart.clone().add(vec1),
                    bottomRightStart.clone().add(vec2),
                )
                if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.BOTTOM_RIGHT }
                // top right
                const topRightStart = topRight.clone().add(vec4)
                parallelogram.setP(
                    topRightStart,
                    topRightStart.clone().add(vec1),
                    topRightStart.clone().add(vec2),
                )
                if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.TOP_RIGHT }
                // bottom left
                const bottomLeftStart = bottomLeft.clone().add(vec3)
                parallelogram.setP(
                    bottomLeftStart,
                    bottomLeftStart.clone().add(vec1),
                    bottomLeftStart.clone().add(vec2),
                )
                if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.BOTTOM_LEFT }
                // top left
                const topLeftStart = topLeft.clone().add(vec3).add(vec4)
                parallelogram.setP(
                    topLeftStart,
                    topLeftStart.clone().add(vec1),
                    topLeftStart.clone().add(vec2),
                )
                if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.TOP_LEFT }
            } else {
                // rect.set(bottomRight.x - resize_handle_s, bottomRight.y - resize_handle_s, resize_handle_s * 2, resize_handle_s * 2)
                // if(rect.contains(point)) return { item, handle: HANDLES.BOTTOM_RIGHT }
                // rect.set(topRight.x - resize_handle_s, topRight.y - resize_handle_s, resize_handle_s * 2, resize_handle_s * 2)
                // if(rect.contains(point)) return { item, handle: HANDLES.TOP_RIGHT }
                // rect.set(bottomLeft.x - resize_handle_s, bottomLeft.y - resize_handle_s, resize_handle_s * 2, resize_handle_s * 2)
                // if(rect.contains(point)) return { item, handle: HANDLES.BOTTOM_LEFT }
                // rect.set(topLeft.x - resize_handle_s, topLeft.y - resize_handle_s, resize_handle_s * 2, resize_handle_s * 2)
                // if(rect.contains(point)) return { item, handle: HANDLES.TOP_LEFT }

                transform.reset().rotate(rotation)
                vec1.set(resize_handle_s, 0)
                vec2.set(0, resize_handle_s)
                transform.basis_xform(vec1, vec1)
                transform.basis_xform(vec2, vec2)
                vec3.copy(vec1).negate()
                vec4.copy(vec2).negate()
                vec1.multiply(2, 2)
                vec2.multiply(2, 2)

                // bottom right
                const bottomRightStart = bottomRight.clone().add(vec3).add(vec4)
                parallelogram.setP(
                    bottomRightStart,
                    bottomRightStart.clone().add(vec1),
                    bottomRightStart.clone().add(vec2),
                )
                if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.BOTTOM_RIGHT }
                // top right
                const topRightStart = topRight.clone().add(vec3).add(vec4)
                parallelogram.setP(
                    topRightStart,
                    topRightStart.clone().add(vec1),
                    topRightStart.clone().add(vec2),
                )
                if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.TOP_RIGHT }
                // bottom left
                const bottomLeftStart = bottomLeft.clone().add(vec3).add(vec4)
                parallelogram.setP(
                    bottomLeftStart,
                    bottomLeftStart.clone().add(vec1),
                    bottomLeftStart.clone().add(vec2),
                )
                if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.BOTTOM_LEFT }
                // top left
                const topLeftStart = topLeft.clone().add(vec3).add(vec4)
                parallelogram.setP(
                    topLeftStart,
                    topLeftStart.clone().add(vec1),
                    topLeftStart.clone().add(vec2),
                )
                if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.TOP_LEFT }
            }
        }
    }

    // edge resize
    for (let i=0; i<selectedItems.length; i++) {
        const { item, T, bbox, isTiny } = items[i]

        if (item.node.item.isEmpty || shouldHandlesHidden(bbox, T)) {
            continue
        }

        if (item.element.isLineElement()) continue

        const bottomRight = T.xform(bbox.bottomRight)
        const topRight = T.xform(bbox.topRight)
        const bottomLeft = T.xform(bbox.bottomLeft)
        const topLeft = T.xform(bbox.topLeft)

        const scale = T.get_scale()
        // TODO: negative scale issue
        const signn = (scale.x >= 0 ? 1 : -1) * (scale.y >= 0 ? 1 : -1)

        // top & bottom
        vec1.set(topLeft.y - topRight.y, topRight.x - topLeft.x).normalize().multiply(resize_handle_s, resize_handle_s).multiply(signn, signn)
        vec2.set(vec1.x, vec1.y).negate()
        // left & right
        vec3.set(topLeft.y - bottomLeft.y, bottomLeft.x - topLeft.x).normalize().multiply(resize_handle_s, resize_handle_s).multiply(signn, signn)
        vec4.set(vec3.x, vec3.y).negate()

        const double_width = (!isTiny || item.node.item.isScreen()) ? 1 : 0
        // bottom
        parallelogram.setP(
            bottomLeft.clone().add(vec1),
            bottomLeft.clone().add(vec2.clone().multiply(double_width, double_width)),
            bottomRight.clone().add(vec1)
        )
        if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.BOTTOM }
        // right
        parallelogram.setP(
            bottomRight.clone().add(vec4),
            bottomRight.clone().add(vec3.clone().multiply(double_width, double_width)),
            topRight.clone().add(vec4)
        )
        if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.RIGHT }
        // left
        parallelogram.setP(
            topLeft.clone().add(vec3),
            topLeft.clone().add(vec4.clone().multiply(double_width, double_width)),
            bottomLeft.clone().add(vec3)
        )
        if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.LEFT }
        // top
        parallelogram.setP(
            topRight.clone().add(vec2),
            topRight.clone().add(vec1.clone().multiply(double_width, double_width)),
            topLeft.clone().add(vec2)
        )
        if (parallelogram.isPointInside(point)) return { item, handle: HANDLES.TOP }
    }

    // corner rotate
    for (let i=0; i<selectedItems.length; i++) {
        const { item, T, bbox, lineInfo } = items[i]

        if (
            item.node.item.isEmpty
            || shouldHandlesHidden(bbox, T)
            || item.node.item.isScreen()
        ) continue

        if (item.element.isLineElement()) {
            const { signX, signY, p1, p2 } = lineInfo

            if (isPointInNodeHitTest(point, item.node.id)) continue

            if (signX * signY > 0) {
                if(p1.distance_to(point) < roate_handle_s) return { item, handle: HANDLES.BOTTOM_RIGHT_ROTATE }
                if(p2.distance_to(point) < roate_handle_s) return { item, handle: HANDLES.TOP_LEFT_ROTATE }
            } else {
                if(p1.distance_to(point) < roate_handle_s) return { item, handle: HANDLES.BOTTOM_LEFT_ROTATE }
                if(p2.distance_to(point) < roate_handle_s) return { item, handle: HANDLES.TOP_RIGHT_ROTATE }
            }
        } else {
            const bottomRight = T.xform(bbox.bottomRight)
            const topRight = T.xform(bbox.topRight)
            const bottomLeft = T.xform(bbox.bottomLeft)
            const topLeft = T.xform(bbox.topLeft)

            parallelogram.setP(bottomLeft, topLeft, bottomRight)
            if (parallelogram.isPointInside(point)) continue

            if(bottomRight.distance_to(point) < roate_handle_s) return { item, handle: HANDLES.BOTTOM_RIGHT_ROTATE }
            if(topRight.distance_to(point) < roate_handle_s) return { item, handle: HANDLES.TOP_RIGHT_ROTATE }
            if(bottomLeft.distance_to(point) < roate_handle_s) return { item, handle: HANDLES.BOTTOM_LEFT_ROTATE }
            if(topLeft.distance_to(point) < roate_handle_s) return { item, handle: HANDLES.TOP_LEFT_ROTATE }
        }
    }

    return null
}

/**
 * @param {ComputeContext} ctx
 * @param {Map<NodeID, SceneNode>} node_bank
 * @param {NodeID[]} node_ids
 * @param {Rect2} box
 * @param {boolean} ignore_lock
 * @returns {NodeID[]}
 */
export function searchOverlapsWithBox(ctx, node_bank, node_ids, box, ignore_lock = false) {
    /** @type {NodeID[]} */
    const list = []
    for (let i = 0, len = node_ids.length; i < len; i++) {
        const child = node_ids[i]
        const element = _visualServer.dataStore.getById(child)

        const hidden = ctx.hidden.get(child)
        if (hidden) continue

        if (ignore_lock) {
            const locked = ctx.locked.get(child)
            if (locked) continue
        }

        if (element.isLineElement()) {
            const transform = ctx.transform.get(child)
            /** @type {Mesh} */
            const mesh = element.get('geometry').get('mesh')
            const edgesArray = Array.from(mesh.edges.values())
            for (let i = 0; i < edgesArray.length; i++) {
                const start = transform.world.xform(mesh.getVertPos(edgesArray[i].v.id))
                const end = transform.world.xform(mesh.getVertPos(edgesArray[i].w.id))

                if (box.intersects_segment(start, end)) {
                    list.push(child)
                    break
                }
            }
        } else {
            /** @type {SceneNode} */
            const node = node_bank.get(child)
            const bounds_local = ctx.bounds.get(child).local
            const bounds_world = ctx.bounds.get(child).world

            if (node.parent && node.parent.item.clipping) {
                const parent_bounds_world = ctx.bounds.get(node.parent.id).world
                bounds_world.clip_by(parent_bounds_world)
            }

            if (bounds_world.is_zero()) continue

            // 1. child fully contained by the box
            if (box.containsRect(bounds_world)) {
                list.push(child)
            }
            // 2. box overlaps with child OBB and AABB
            else if (box.overlaps(bounds_world)) {
                // Check whether the search box intersects with the edges of node parallelogram bounds
                let found = false
                const lt = node.item.transform.world.xform(bounds_local.actualTopLeft())
                const rt = node.item.transform.world.xform(bounds_local.actualTopRight())
                const rb = node.item.transform.world.xform(bounds_local.actualBottomRight())
                const lb = node.item.transform.world.xform(bounds_local.actualBottomLeft())
                const lines = [[lt, rt], [rt, rb], [rb, lb], [lb, lt]]
                for (let i = 0; i < 4; i++) {
                    const [start, end] = lines[i]
                    if (box.intersects_segment(start, end)) {
                        list.push(child)
                        found = true
                        break
                    }
                }
                if (found) continue

                // Check whether the search box fully been contained by the node parallelogram bounds
                const point = box.actualTopLeft() // just need one point of selectin box for comparison
                parallelogram.set(lt.x, lt.y, rt.x, rt.y, lb.x, lb.y)
                if (parallelogram.isPointInside(point)) {
                    list.push(child)
                    continue
                }
            }
        }
    }
    return list
}

/**
 * @param {SceneNode} node
 * @param {boolean} selectable
 * @param {number} zoom
 * @param {[number, number]} size
 * @returns {Rect2}
 */
function getScreenNameBBox(node, selectable, zoom, size) {
    const pos = node.item.transform.local
    const box = new Rect2(
        pos.tx,
        pos.ty - size[1] / zoom,
        selectable ? size[0] / zoom : 0,
        selectable ? size[1] / zoom : 0
    )
    return box
}

/**
 * @param {Mesh} mesh
 * @param {RenderItem} item
 * @param {Viewport} viewport
 * @param {Vector2} mouse_point
 * @returns {Vertex}
 */
export function findHoverVertices(mesh, item, viewport, mouse_point) {
    const hitBuffer = HIT_TOLERANCE.VERT_HALF_BOX_WIDTH / viewport.scale
    const vertices = mesh.vertices
    // showing curve > vertex
    const hoverVertex = []
    for (const vertex of vertices) {
        const dummy = mesh.getVertPos(vertex.id)
        const pos = item.transform.world.xform(dummy)
        mouse_rect.set(mouse_point.x - hitBuffer, mouse_point.y - hitBuffer, 2 * hitBuffer, 2 * hitBuffer)
        if (mouse_rect.contains(pos)) {
            hoverVertex.push(vertex)
        }
    }
    return hoverVertex
}

/**
 * @param {Vector2} mouse_point
 * @param {SceneNode[]} selectedNodes
 * @returns {SceneNode[]}
 */
export function findHoverOrigin(mouse_point, selectedNodes) {
    const hitBuffer = HIT_TOLERANCE.ORIGIN_HALF_BOX_WIDTH / _visualServer.viewport.scale
    const hoverOrigins = []
    for (const node of selectedNodes) {
        const originPos = node.item.transform.worldPivot
        mouse_rect.set(mouse_point.x - hitBuffer, mouse_point.y - hitBuffer, 2 * hitBuffer, 2 * hitBuffer)
        if (mouse_rect.contains(originPos)) {
            hoverOrigins.push(node)
        }
    }
    return hoverOrigins
}

/**
 * Seach vertices inside an area, note that vertices can have trigger area
 * @param {Mesh} mesh
 * @param {RenderItem} item
 * @param {Viewport} viewport
 * @param {Rect2} area
 * @param {boolean} [fullyInside = false]
 * @returns {Vertex[]}
 */
export function searchVertices(mesh, item, viewport, area, fullyInside = false) {
    const hitBuffer = HIT_TOLERANCE.VERT_HALF_BOX_WIDTH / viewport.scale
    const worldXform = item.transform.world
    const selectVertices = []
    for (const vertex of mesh.vertices) {
        const dummy = mesh.getVertPos(vertex.id)
        const pos = worldXform.xform(dummy)
        if ((fullyInside && area.contains(pos)) || (!fullyInside && area.overlaps(rect.set(pos.x - hitBuffer, pos.y - hitBuffer, 2 * hitBuffer, 2 * hitBuffer)))) {
            selectVertices.push(vertex)
        }
    }
    return selectVertices
}

const line = new Line()
const mouse_rect = new Rect2(0, 0, 0, 0)
const rect = new Rect2()
const parallelogram = new Parallelogram()
const vec1 = new Vector2()
const vec2 = new Vector2()
const vec3 = new Vector2()
const vec4 = new Vector2()
const transform = new Transform2D()
