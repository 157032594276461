/* eslint-disable jsdoc/require-returns */
import { Rect2, Transform2D } from "../../math"
import { VisualServer } from "../../visual_server/VisualServer"
import { app, wgl, init } from "./web/web"
export {
    allocTempUint8Array,
    allocTempUint16Array,
    allocTempUint32Array,
    allocTempFloat32Array,
    allocTempFloat64Array,
    getUint8Array,
    getUint16Array,
    getUint32Array,
    getInt32Array,
    getFloat32Array,
    getFloat64Array,
} from "./web/web"

/**
 * @typedef {object} WASM
 *
 * draw.zig
 * @property {(cool: number) => void} beCool
 * @property {(id: number, typ: number ,cmd_ptr: number, cmd_len: number, vtr_ptr: number, vtr_len: number) => void} updateGeometry
 * @property {(id: number, typ: number ,x: number, y: number, w: number, h: number) => void} updateRectGeometry
 * @property {(id: number, index: number, linewidth: number, cap: LineCap, join: LineJoin, miterlimit: number) => void} updateStroke
 * @property {(id: number, index: number) => void} destroyStroke
 * @property {(id: number) => void} destroyGeometry
 * @property {(id: number, x: number, y: number) => number} isPointInsideGeometry
 * @property {(x: number, y: number, scale: number) => void} setViewport
 *
 * @property {(ptr: number, len: number, w: number, h: number) => number} makeBrushImage
 * @property {() => number} createLayer
 * @property {(pane_id: number, pip_id: number, img_id: number, v_ptr: number, v_len: number, i_ptr: number, i_len: number) => void} pushBrush
 * @property {(r: number, g: number, b: number, a: number) => void} setBackgroundColor
 */

/** @type {WASM | import("./web/web").WASM} */
let wasm
export const WASM = () => wasm

const env = {
    app,
    wgl,
    gfx: {
        useAFreeingTileId: (col, row, level, version) => {
            const VS = VisualServer.instance()
            return VS.renderTileset.useAFreeingTileId(col, row, level, version)
        },
        useAFreeingSnapshotTileId: (col, row, level, version) => {
            const VS = VisualServer.instance()
            return VS.snapshotTileset.useAFreeingSnapshotTileId(col, row, level, version)
        },
        pushBounds: (x, y, w, h) => {
            bounds.set(x, y, w, h)
        },
    },
}

/**
 * @param {HTMLCanvasElement} canvas
 * @param {boolean} useLowDPR
 */
export const initWasm = async (canvas, useLowDPR=false) => {
    // Set the DPI scale to the window.devicePixelRatio if growthbook flag is set
    if (useLowDPR) {
        env.app.dpiScale = window.devicePixelRatio
    }
    await fetch(`${process.env.PUBLIC_URL}/renderer.wasm`)
        .then(response => response.arrayBuffer())
        .then(buffer => WebAssembly.instantiate(buffer, env))
        .then(results => {
            wasm = results.instance.exports

            const gl = canvas.getContext("webgl2", {
                antialias: true,
                alpha: false,
                depth: false,
                stencil: false,
                powerPreference: 'high-performance',
                premultipliedAlpha: true,
                preserveDrawingBuffer: true,
                desynchronized: true,
            })

            init(wasm, gl)
        })
        .catch(error => console.error(error))
}

const bounds = new Rect2()
/**
 * @param {number} id
 * @param {number} typ
 */
export const getGeometryBounds = (id, typ) => {
    wasm.getGeometryBounds(id, typ)
    return {
        x: bounds.x,
        y: bounds.y,
        w: bounds.width,
        h: bounds.height,
    }
}
/**
 * @param {number} id
 * @param {number} typ
 * @param {Transform2D} xform
 */
export const getGeometryXformBounds = (id, typ, xform = Transform2D.IDENTITY) => {
    wasm.getGeometryXformBounds(id, typ, xform.a, xform.b, xform.c, xform.d, xform.tx, xform.ty)
    return {
        x: bounds.x,
        y: bounds.y,
        w: bounds.width,
        h: bounds.height,
    }
}

export const getCurrentVersion = () => wasm.queryFrameIndex()

export const getRenderGroupsPtr = () => wasm.getRenderGroupsPtr()
export const getRenderCommandsPtr = () => wasm.getRenderCommandsPtr()
export const getSnapshotGroupsPtr = () => wasm.getSnapshotGroupsPtr()
export const getSnapshotCommandsPtr = () => wasm.getSnapshotCommandsPtr()
export const getViewsPtr = () => wasm.getViewsPtr()
export const getBlobsPtr = () => wasm.getBlobsPtr()
export const getViewsLen = () => wasm.getViewsLen()
export const getBlobsLen = () => wasm.getBlobsLen()
