import { useCallback, useRef } from 'react'

import { useDataStoreActions } from '../providers/dataStore/DataStoreProvider'

export type MetaDataProps = Record<string, unknown>

interface UndoRedoProps {
  value: string
  metaData?: MetaDataProps
}

export const useUndoRedo = () => {
  const undoStack = useRef<UndoRedoProps[]>([])
  const redoStack = useRef<UndoRedoProps[]>([])
  const isUpdatePending = useRef(false)
  const isDeleting = useRef(false)
  const isContinuousDeleting = useRef(false)
  const initialDeleteValue = useRef('')

  const { undo: dataStoreUndo, redo: dataStoreRedo } = useDataStoreActions()

  const undo = useCallback(
    (inputValue: string, handleValueChange: Function, metaData?: MetaDataProps) => {
      if (undoStack.current.length > 0) {
        const lastValue = undoStack.current.pop()
        redoStack.current.push({ value: inputValue, metaData: metaData })
        handleValueChange(lastValue?.value, lastValue?.metaData)
      } else if (redoStack.current.length === 0 && dataStoreUndo) {
        dataStoreUndo()
      }
    },
    [dataStoreUndo]
  )

  const redo = useCallback(
    (inputValue: string, handleValueChange: Function, metaData?: MetaDataProps) => {
      if (redoStack.current.length > 0) {
        const lastValue = redoStack.current.pop()
        undoStack.current.push({ value: inputValue, metaData: metaData })
        handleValueChange(lastValue?.value, lastValue?.metaData)
      } else if (undoStack.current.length === 0 && dataStoreRedo) {
        dataStoreRedo()
      }
    },
    [dataStoreRedo]
  )

  const commitChange = useCallback((inputValue: string, metaData?: MetaDataProps) => {
    undoStack.current.push({ value: inputValue, metaData })
    redoStack.current = []
  }, [])

  const updateUndoHistory = useCallback(
    (inputValue: string, forceUpdate?: boolean) => {
      if (!forceUpdate) {
        if (isDeleting.current) return
        if (inputValue === undoStack.current[undoStack.current.length - 1]?.value) return
      }
      if (!isUpdatePending.current) {
        commitChange(inputValue)
        isUpdatePending.current = true
        setTimeout(() => {
          isUpdatePending.current = false
        }, 500)
      }
    },
    [commitChange]
  )

  const initiateDelete = useCallback((value: string) => {
    if (isDeleting.current) {
      isContinuousDeleting.current = true
      return
    }
    initialDeleteValue.current = value
    isDeleting.current = true
  }, [])

  const finalizeDelete = useCallback(() => {
    if (!isDeleting.current) return
    isDeleting.current = false
    if (isContinuousDeleting.current) {
      commitChange(initialDeleteValue.current)
    } else {
      updateUndoHistory(initialDeleteValue.current)
    }
    initialDeleteValue.current = ''
    isContinuousDeleting.current = false
  }, [updateUndoHistory, commitChange])

  const clearUndoHistory = useCallback(() => {
    undoStack.current = []
    redoStack.current = []
  }, [])

  return {
    undo,
    redo,
    updateUndoHistory,
    clearUndoHistory,
    commitChange,
    initiateDelete,
    finalizeDelete,
    undoStack,
    redoStack
  }
}
