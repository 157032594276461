import React from 'react'

import Icon from '../Icon'
import Tooltip from '../Tooltip'

export type TagProps = {
  children: string | React.ReactElement
  closable?: boolean
  onClose?: () => void
  size?: 's' | 'l'
  hasError?: boolean
  maxWidth?: string
  className?: string
  tip?: string
}

const Tag = ({ children, closable, onClose, size = 'l', hasError, maxWidth, className = '', tip }: TagProps) => {
  const isLargeSize = size === 'l'
  const sizeClassName = isLargeSize ? 'h-24 text-12' : 'h-16 text-11'
  const padding = closable ? 'pl-6 pr-4' : 'px-6'

  return (
    <Tooltip content={tip}>
      <div
        style={{ maxWidth }}
        className={`text-white text-12 inline-flex gap-x-2 items-center rounded-sm ${sizeClassName} ${
          hasError ? 'bg-rubin-overlay' : 'bg-light-overlay-10'
        } ${padding} ${className}`}
      >
        <span className={`flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis`}>{children}</span>
        {closable && <Icon name="Cross" size="s" onClick={onClose} />}
      </div>
    </Tooltip>
  )
}

export default Tag
