import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EditMode } from '@phase-software/types'

import {
  COMMENT_OPTION,
  GENERAL_SCALE_OPTION,
  GENERAL_SELECT_OPTION,
  HAND_OPTION,
  INSERT_OPTION,
  PEN_OPTION,
  SHAPE_OPTIONS,
  UNREAD_COMMENT_OPTION
} from '../../constants/fileEditorConstants'
import useEditorImage from '../../hooks/useEditorImage'
import { useCommentContext } from '../../providers/CommentProvider'
import { useSetTutorial } from '../../providers/TutorialProvider'
import { useTool, useToolActions } from '../../providers/dataStore/ToolProvider'
import { useUI } from '../../providers/dataStore/UIProvider'
import { TutorialType } from '../../tutorials'
import { ToolButton } from '../shared'
import { isSeparatorOption } from '../shared/Menu/utils'
import { ToolbarProps } from '../shared/ToolButton'

export type PrimaryToolProps = {
  tool: ToolbarProps['value']
  onSelect: ToolbarProps['onChange']
}

type GeneralToolProps = {
  isPathEditMode: boolean
} & PrimaryToolProps

const GeneralTool = ({ isPathEditMode, tool, onSelect }: GeneralToolProps) => {
  const { startTutorial, markTutorialAsCompleted } = useSetTutorial()
  const { t } = useTranslation('file', { keyPrefix: 'tools' })
  const options = isPathEditMode ? [GENERAL_SELECT_OPTION] : [GENERAL_SELECT_OPTION, GENERAL_SCALE_OPTION]
  const translatedOptions = options.map((option) => {
    if (isSeparatorOption(option)) return option
    return { ...option, name: t(option.name ?? '') }
  })

  const handleMenuChange = (open: boolean) => {
    if (open) {
      startTutorial(TutorialType.TOOL_MENU)
    } else {
      markTutorialAsCompleted(TutorialType.TOOL_MENU)
    }
  }

  return (
    <ToolButton
      data-test-id="tool-menu"
      className="pt-general-tool"
      menuClassName="pt-general-tool-menu"
      options={translatedOptions}
      onChange={onSelect}
      value={tool}
      showArrowIcon={!isPathEditMode}
      onOpenChange={handleMenuChange}
    />
  )
}

const ShapeTools = ({ tool, onSelect }: PrimaryToolProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'tools' })
  const translatedShareOptions = useMemo(
    () =>
      SHAPE_OPTIONS.map((option) => {
        return { ...option, name: t(option.name ?? '') }
      }),
    [t]
  )
  return <ToolButton options={translatedShareOptions} onChange={onSelect} value={tool} data-test-id="object-menu" />
}

const PenTool = ({ tool, onSelect }: PrimaryToolProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'tools' })
  const translatedPenOption = useMemo(() => {
    return {
      ...PEN_OPTION,
      name: t(PEN_OPTION.name ?? '')
    }
  }, [t])

  return (
    <ToolButton
      options={[translatedPenOption]}
      onChange={onSelect}
      value={tool}
      showArrowIcon={false}
      data-test-id="path-tool"
    />
  )
}

export const HandTool = ({ tool, onSelect }: PrimaryToolProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'tools' })
  const translatedHandOption = useMemo(() => {
    return {
      ...HAND_OPTION,
      name: t(HAND_OPTION.name ?? '')
    }
  }, [t])

  return (
    <ToolButton
      options={[translatedHandOption]}
      onChange={onSelect}
      value={tool}
      showArrowIcon={false}
      data-test-id="hand-tool"
    />
  )
}

export const CommentTool = ({ tool, onSelect }: PrimaryToolProps) => {
  const { hasUnread } = useCommentContext()
  const { t } = useTranslation('file', { keyPrefix: 'tools' })
  const commentOption = hasUnread ? UNREAD_COMMENT_OPTION : COMMENT_OPTION
  const testId = hasUnread ? 'unread-comment-tool' : 'comment-tool'
  const translatedCommentOption = useMemo(() => {
    return {
      ...commentOption,
      name: t(commentOption.name ?? '')
    }
  }, [t, commentOption])

  return (
    <ToolButton
      key={testId}
      options={[translatedCommentOption]}
      onChange={onSelect}
      value={tool}
      showArrowIcon={false}
      data-test-id={testId}
    />
  )
}

export const InsertTool = ({ tool, onSelect }: PrimaryToolProps) => {
  const { t } = useTranslation('file', { keyPrefix: 'tools' })
  const translatedInsertOption = useMemo(() => {
    return {
      ...INSERT_OPTION,
      name: t(INSERT_OPTION.name ?? '')
    }
  }, [t])

  return (
    <ToolButton
      options={[translatedInsertOption]}
      onChange={onSelect}
      value={tool}
      showArrowIcon={false}
      data-test-id="insert-tool"
    />
  )
}

const PrimaryTools = () => {
  const { isEditingState, editMode, isVersioningState, isInspectingState } = useUI()
  const { activeTool } = useTool()
  const { setActiveTool } = useToolActions()
  const { insertImage } = useEditorImage()
  const isPathEditMode = editMode === EditMode.SHAPE

  const selectImage = useCallback(() => {
    insertImage({ multiple: true })
  }, [insertImage])

  return (
    <div className="pt-top-tool">
      {isEditingState && (
        <>
          <GeneralTool key="general" tool={activeTool} onSelect={setActiveTool} isPathEditMode={isPathEditMode} />
          {!isPathEditMode && <ShapeTools key="creation" tool={activeTool} onSelect={setActiveTool} />}
          <PenTool key="pen" tool={activeTool} onSelect={setActiveTool} />
          <InsertTool key="insert" tool={activeTool} onSelect={selectImage} />
        </>
      )}
      {isInspectingState && <GeneralTool key="general" tool={activeTool} onSelect={setActiveTool} isPathEditMode />}

      <HandTool key="hand" tool={activeTool} onSelect={setActiveTool} />
      {!isPathEditMode && !isVersioningState && (
        <CommentTool key="comment" tool={activeTool} onSelect={setActiveTool} />
      )}
    </div>
  )
}

export default PrimaryTools
