import { EPSILON } from '@phase-software/data-utils/src/commons'
import { Rect2, Transform2D, Vector2 } from '../../math'
import { searchNodesBySnappingRect } from '../selection/index'
import { CMP_EPSILON } from '../../math/const'
import { AXIS_DIAGONAL_EPSILON } from '../../constants'

/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('../../visual_server/VisualServer').VisualServer} VisualServer */
/** @typedef {import('../../math').Transform2D} Transform2D */
/** @typedef {import('../selection/Hittest').Hittest} Hittest */

const anchorThreshold = 0.01 // Threshold for edge vs corner detection
const SPACE_TOLERANCE = 2
const AXIS_THRESHOLD = {
    ELEMENT: 100,
    ORIGIN: 40,
    POINT: 40
}

export class Snapping {
    /**
     * @param {VisualServer} visualServer
     */
    constructor(visualServer) {
        this.vs = visualServer
        this.threshold = 5
        this.startOrigin = new Vector2()
        this.startSnapping = false
        this.axisDiagonalVec = [new Vector2(), new Vector2()]
        this.axisDiagonalCount = 0
        this.firstSnapXToGrid = true
        this.firstSnapYToGrid = true
        this.lastSnapToGridPos = new Vector2()
        this.moved = false

        // key is the x pos, value is all y pos with x pos
        /** @type {Map<number, Map<number, number>>} */
        this.otherElementsVertices = new Map()
        /** @type {Map<number, Map<number, number>>} */
        this.selectedVertices = new Map()
        /** @type {Map<number, Map<number, number>>} */
        this.snapToElementXUI = new Map()
        /** @type {Map<number, Map<number, number>>} */
        this.snapToElementYUI = new Map()

        // during current mouse update, whether the dragging element is snapping to other element
        this.isXSnapping = false
        this.isYSnapping = false

        // Common spacing
        /** @type {Map<number, Rect2>} */
        this.snapAreaOnXLeftLUT = new Map()
        /** @type {Map<number, Rect2>} */
        this.snapAreaOnXRightLUT = new Map()
        /** @type {Map<number, Rect2>} */
        this.snapAreaOnYTopLUT = new Map()
        /** @type {Map<number, Rect2>} */
        this.snapAreaOnYBottomLUT = new Map()
        /** @type {Map<number, Rect2[][]>} The value is an array of 2 Rects that generate the spacing */
        this.spaceOnXLUT = new Map()
        /** @type {Map<number, Rect2[][]>} */
        this.spaceOnYLUT = new Map()
        // Common spacing UI
        /** @type {Map<string, Rect2>} */
        this.spacingAreaUIMap = new Map()
        /** @type {Vector2} */
        this.spacingLength = new Vector2(-1, -1)
        /** @type {Rect2} */
        this.selectedBoundsWorld = new Rect2()

        /** @type {Rect2} */
        this.boundsForResize = new Rect2()
        /** @type {import('../../visual_server/Transform').Transform} */
        this.hoverNodeTransform = null
        /** @type {Vector2[]} */
        this.spacingVerticalVec = new Vector2()
        this.spacingVertical = 0
        this.spacingHorizonalVec = new Vector2()
        this.spacingHorizonal = 0
        // this.debugRect = null
    }

    static ResizeTypes = Object.freeze({
        RESIZE_ONE_ELEMENT: 1,
        RESIZE_ELEMENTS: 2,
        SCALE_ONE_ELEMENT: 3
    });

    updateThreshold(viewScale) {
        const zoomRatio = Math.floor(viewScale * 100)
        if (zoomRatio <= 2) {
            this.threshold = 200
        } else if(zoomRatio <= 5) {
            this.threshold = 100
        } else if(zoomRatio <= 10) {
            this.threshold = 66
        } else if(zoomRatio <= 25) {
            this.threshold = 21
        } else if (zoomRatio <= 50) {
            this.threshold = 9
        } else if (zoomRatio <= 75) {
            this.threshold = 7
        } else if (zoomRatio <= 100) {
            this.threshold = 4
        } else if (zoomRatio <= 200) {
            this.threshold = 2
        } else if (zoomRatio <= 400) {
            this.threshold = 1
        } else if (zoomRatio <= 25600) {
            this.threshold = 0.5
        }
    }

    updateMoved(moveDelta) {
        if (!moveDelta.is_zero() && !this.moved) this.moved = true
    }

    resetInterfaceData() {
        this.axisDiagonalCount = 0
    }

    /**
     * @param {Vector2} startOrigin
     */
    setSnappingOriginalPos(startOrigin) {
        this.startOrigin.copy(startOrigin)
        this.startSnapping = true
    }

    setEndSnapping() {
        this.startSnapping = false
        this.axisDiagonalCount = 0
        this.firstSnapXToGrid = true
        this.firstSnapYToGrid = true
        this.moved = false
    }

    /**
     * check whether draw snapping UI
     * @returns {bool}
     */
    isSnappingUIDataDirty() {
        return this.axisDiagonalCount > 0 || this.snapToElementXUI.size || this.snapToElementYUI.size || this.spacingAreaUIMap.size
    }

    /**
     * @param {Vector2} delta
     * @param {boolean} isPressShift
     * @param {string} type
     * @returns {Vector2} // warn: need to free the return variable after used
     */
    snapAxisAndDiagonal(delta, isPressShift, type = 'ELEMENT') {
        const newDelta = delta.clone()
        if (isPressShift) {
            const abs_delta = delta.clone().abs()
            // if delta in the axis threshold extension
            if (abs_delta.x <= AXIS_THRESHOLD[type] || abs_delta.y <= AXIS_THRESHOLD[type]) {
                if (abs_delta.x <= AXIS_DIAGONAL_EPSILON && abs_delta.y <= AXIS_DIAGONAL_EPSILON) {
                    this._setAxisDiagonalAngle(0, 90)
                } else if (abs_delta.y - abs_delta.x > AXIS_DIAGONAL_EPSILON){
                    newDelta.set_x(0)
                    this._setAxisDiagonalAngle(90)
                } else {
                    newDelta.set_y(0)
                    this._setAxisDiagonalAngle(0)
                }
            } else { // if delta in the diagonal area
                if (delta.x > 0 && delta.y > 0) {
                    this._setAxisDiagonalAngle(-45)
                    if (abs_delta.x >= abs_delta.y) {
                        newDelta.set(abs_delta.x, abs_delta.x)
                    } else {
                        newDelta.set(abs_delta.y, abs_delta.y)
                    }
                } else if (delta.x < 0 && delta.y > 0) {
                    this._setAxisDiagonalAngle(45)
                    if (abs_delta.x >= abs_delta.y) {
                        newDelta.set(-abs_delta.x, abs_delta.x)
                    } else {
                        newDelta.set(-abs_delta.y, abs_delta.y)
                    }
                } else if (delta.x < 0 && delta.y < 0) {
                    this._setAxisDiagonalAngle(-45)
                    if (abs_delta.x >= abs_delta.y) {
                        newDelta.set(-abs_delta.x, -abs_delta.x)
                    } else {
                        newDelta.set(-abs_delta.y, -abs_delta.y)
                    }
                } else if (delta.x > 0 && delta.y < 0) {
                    this._setAxisDiagonalAngle(45)
                    if (abs_delta.x >= abs_delta.y) {
                        newDelta.set(abs_delta.x, -abs_delta.x)
                    } else {
                        newDelta.set(abs_delta.y, -abs_delta.y)
                    }
                }
            }
        } else {
            this._setAxisDiagonalAngle(null, null)
        }
        return newDelta
    }
    /**
     * @param {Vector2} pos
     * @param {Vector2} moveDelta
     * @returns {Vector2}
     */
    snapElementsPosToGrid(pos, moveDelta = new Vector2()) {
        const new_delta = new Vector2()
        if (moveDelta.x !== 0 && pos.x !== Math.round(pos.x)) {
            new_delta.x = Math.round(pos.x) - pos.x
        }
        if (moveDelta.y !== 0 && pos.y !== Math.round(pos.y)) {
            new_delta.y = Math.round(pos.y) - pos.y
        }
        return new_delta
    }

    /**
     * @param {Vector2} currMousePos
     * @param {Vector2} initMousePos
     * @param {Vector2} selectedVertPos
     * @param {boolean} isClickEdge
     * @param {boolean} isAlignAxis
     * @returns {Vector2}
     */
    snapResizeElementToPixelGrid(currMousePos, initMousePos, selectedVertPos, isClickEdge, isAlignAxis) {
        if(isClickEdge && !isAlignAxis) return currMousePos.clone()
        const mouseDelta = currMousePos.clone().sub(initMousePos)
        const newMousePos = currMousePos.clone()
        // offset between mousePos and selected edge
        const offset = initMousePos.clone().sub(selectedVertPos)
        let currInt = Math.round(currMousePos.x)
        // if first time mouse movement
        if (this.firstSnapXToGrid) {
            // if current mouse position is on a pixel grid then
            if (mouseDelta.x !== 0) {
                newMousePos.x = Math.round(selectedVertPos.x) + offset.x
            }
            this.firstSnapXToGrid = false
        } else {
            newMousePos.x = currInt + offset.x
        }

        currInt = Math.round(currMousePos.y)
        if (this.firstSnapYToGrid) {
            if (mouseDelta.y !== 0) {
                newMousePos.y = Math.round(selectedVertPos.y) + offset.y
            }
            this.firstSnapYToGrid = false
        } else {
            newMousePos.y = currInt + offset.y
        }
        // record the mouse pos for preventing that the size is less than 1 and re-calculating the size
        this.lastSnapToGridPos.copy(newMousePos)

        return this.lastSnapToGridPos
    }

    /**
     * @param {Vector2} currMousePos
     * @param {Vector2} initMousePos
     * @param {Vector2} selectedVertPos
     * @param {boolean} isClickEdge
     * @param {boolean} isAlignAxis
     * @returns {Vector2}
     */
    snapScaleElementToPixelGrid(currMousePos, initMousePos, selectedVertPos, isClickEdge, isAlignAxis) {
        if (!this.moved) return initMousePos.clone()
        if(isClickEdge && !isAlignAxis) return currMousePos.clone()
        const mouseDelta = currMousePos.clone().sub(initMousePos)
        const newMousePos = currMousePos.clone()
        // offset between mousePos and selected edge
        const offset = initMousePos.clone().sub(selectedVertPos)
        let currInt = Math.round(currMousePos.x)
        // if first time mouse movement
        if (this.firstSnapXToGrid) {
            // if current mouse position is on a pixel grid then
            if (mouseDelta.x !== 0) {
                newMousePos.x = Math.round(selectedVertPos.x) + offset.x
            }
            this.firstSnapXToGrid = false
        } else {
            newMousePos.x = currInt + offset.x
        }

        currInt = Math.round(currMousePos.y)
        if (this.firstSnapYToGrid) {
            if (mouseDelta.y !== 0) {
                newMousePos.y = Math.round(selectedVertPos.y) + offset.y
            }
            this.firstSnapYToGrid = false
        } else {
            newMousePos.y = currInt + offset.y
        }

        return newMousePos
    }

    /**
     * @deprecated This function has been no longer called
     * @param {Vertex} hoverVert
     * @param {Vector2} delta
     * @param {Transform} nodeTransform
     * @param {Size} elemSize
     * @param {Mesh} mesh
     * @returns {Vector2}
     */
    getClosestDistanceToGrid(hoverVert, delta, nodeTransform, elemSize, mesh) {
        const newDelta = delta.clone()
        const vertPosOffset = new Vector2()
        const vertPos = new Vector2()

        const rowPos = mesh.getVertPos(hoverVert.id)
        vertPos.set(rowPos[0], rowPos[1])
        nodeTransform.world.xform(vertPos, vertPos)
        vertPosOffset.copy(vertPos).round().sub(vertPos)
        newDelta.sub(vertPosOffset).round()
        if (vertPosOffset.x !== 0) {
            newDelta.x += vertPosOffset.x
        }

        if (vertPosOffset.y !== 0) {
            newDelta.y += vertPosOffset.y
        }
        return newDelta
    }

    /**
     * Set resizing element snapping points, the points would be the two vertices of
     * dragging edge or the one of dragging handler
     * @param {Vector2} anchor
     * @param {import('../../visual_server/RenderItem').RenderItem } hoverNode
     */
    setResizeSelectedElementOAB(anchor, hoverNode) {
        this.selectedVertices.clear()
        const selectedElement = this.vs.selection
        const selectedElementID = selectedElement.single ? selectedElement.single.id : null
        const selectedItemBounds = selectedElementID ? this.vs.indexer.getNode(selectedElementID).boundsLocalVisualZero : selectedElement.bounds
        const selectedWorldTrans = selectedElementID ? this.vs.indexer.getNode(selectedElementID).item.transform.world : Transform2D.IDENTITY
        const isComputedGroup = selectedElementID && this.vs.indexer.getNode(selectedElementID).item.isComputedGroup()
        this._getResizeDraggingSideData(this.selectedVertices, selectedElement.single, selectedItemBounds, selectedWorldTrans, anchor, isComputedGroup)
        if (hoverNode) {
            this.boundsForResize.copy(hoverNode.boundsLocalVisualZero)
            this.hoverNodeTransform = hoverNode.item.transform
        } else{
            this.selectedBoundsWorld.copy(selectedItemBounds)
            if (selectedElement.single) {
                this.boundsForResize.copy(selectedItemBounds)
                this.hoverNodeTransform = this.vs.indexer.getNode(selectedElementID).item.transform
                this.selectedBoundsWorld = selectedWorldTrans.xform_rect(this.selectedBoundsWorld)
            }
        }
    }

    /**
     * Set moving element snapping points, if it is single selection , the points will be
     * the vertices of OAB. If it is multi-selection, the points will be the vertices of AAB.
     */
    setSelectedElementOAB() {
        this.selectedVertices.clear()
        const selectedElement = this.vs.selection

        // if only select one element, it will snap to others with its OAB
        const selectedElementID = selectedElement.single ? selectedElement.single.id : null
        const selectedItemBounds = selectedElement.single ? this.vs.indexer.getNode(selectedElementID)?.boundsLocalVisualZero : selectedElement.bounds
        const selectedWorldTrans = selectedElement.single ? this.vs.indexer.getNode(selectedElementID)?.item.transform.world : Transform2D.IDENTITY
        this._getElementOABAndCenterData(this.selectedVertices, selectedElement.single, selectedItemBounds, selectedWorldTrans)
        this.selectedBoundsWorld.copy(selectedItemBounds)
        if (selectedElement.single){
            this.selectedBoundsWorld = selectedWorldTrans.xform_rect(this.selectedBoundsWorld)
        }
    }

    /**
     * Set not selected elements snapping points. The elements are in the same column or row area
     * of selected element. The data will be their AAB vertices.
     * @param {boolean} isMovingEle
     */
    updateSnapMovingData(isMovingEle = false) {
        // Reset data
        this.otherElementsVertices.clear()
        this.snapAreaOnXLeftLUT.clear()
        this.snapAreaOnXRightLUT.clear()
        this.snapAreaOnYTopLUT.clear()
        this.snapAreaOnYBottomLUT.clear()
        // Initializtion
        const selectedElement = this.vs.selection
        const viewportRect = this.vs.viewport.rectW.clone()
        // for removing the area of the rulers
        const rulerSize = 18 / this.vs.viewport.scale
        // get elements in cross area
        // increase one threshold in bbox rect to make sure have space for snapping
        const colBoxPosX = selectedElement.bounds.x - this.threshold * 2 < viewportRect.x + rulerSize ?
            viewportRect.x + rulerSize : selectedElement.bounds.x - this.threshold * 2
        const colBoxPosY = viewportRect.y + rulerSize
        const colBoxW = colBoxPosX + selectedElement.bounds.width + this.threshold * 4 > viewportRect.right ?
            viewportRect.right - colBoxPosX : selectedElement.bounds.width + this.threshold * 4
        const colBoxH = viewportRect.height - rulerSize
        // get elements in same column area in the viewport
        const searchBoXCol = new Rect2(colBoxPosX, colBoxPosY, colBoxW, colBoxH)

        const rowBoxPosX = viewportRect.x + rulerSize
        const rowBoxPosY = selectedElement.bounds.y - this.threshold * 2 < viewportRect.y + rulerSize ?
            viewportRect.y + rulerSize : selectedElement.bounds.y - this.threshold * 2
        const rowBoxW = viewportRect.width - rulerSize
        const rowBoxH = rowBoxPosY + selectedElement.bounds.height + this.threshold * 4 > viewportRect.bottom ?
            viewportRect.bottom - rowBoxPosY : selectedElement.bounds.height + this.threshold * 4
        // get elements in same row area in the viewport
        const searchBoXRow = new Rect2(rowBoxPosX, rowBoxPosY, rowBoxW, rowBoxH)

        // get the fix col box for common spacing
        const colFitBoxPosX = selectedElement.bounds.x < viewportRect.x + rulerSize ?
            viewportRect.x + rulerSize : selectedElement.bounds.x
        const colFitBoxPosY = viewportRect.y + rulerSize
        const colFitBoxW = colBoxPosX + selectedElement.bounds.width > viewportRect.right ?
            viewportRect.right - colBoxPosX : selectedElement.bounds.width
        const colFitBoxH = viewportRect.height - rulerSize
        const searchFitBoXCol = new Rect2(colFitBoxPosX, colFitBoxPosY, colFitBoxW, colFitBoxH)

        // get the fit row box for common spacing
        const rowFitBoxPosX = viewportRect.x + rulerSize
        const rowFitBoxPosY = selectedElement.bounds.y < viewportRect.y + rulerSize ?
            viewportRect.y + rulerSize : selectedElement.bounds.y
        const rowFitBoxW = viewportRect.width - rulerSize
        const rowFitBoxH = rowBoxPosY + selectedElement.bounds.height > viewportRect.bottom ?
            viewportRect.bottom - rowBoxPosY : selectedElement.bounds.height
        const searchFitBoXRow = new Rect2(rowFitBoxPosX, rowFitBoxPosY, rowFitBoxW, rowFitBoxH)

        const selectedID = selectedElement._selected
        const selectedParent = new Set()
        for (const id of selectedID){
            const node = this.vs.indexer.getNode(id)
            if (node.parent){
                selectedParent.add(node.parent.id)
            }
        }
        const colItems = searchNodesBySnappingRect(searchBoXCol)
        const fitColItems = searchNodesBySnappingRect(searchFitBoXCol)
        this._getElementsCornerAndCenterData(selectedID, colItems, this.otherElementsVertices, isMovingEle, false, selectedParent, fitColItems, selectedElement.bounds)
        const rowItems = searchNodesBySnappingRect(searchBoXRow)
        const fitRowItems = searchNodesBySnappingRect(searchFitBoXRow)
        this._getElementsCornerAndCenterData(selectedID, rowItems, this.otherElementsVertices, isMovingEle, true, selectedParent, fitRowItems, selectedElement.bounds)

    }

    /**
     * @param {Vector2} delta
     * @param {bool} isSnapToGrid
     * @returns {Vector2}
     */
    comparingVertices(delta, isSnapToGrid = true) {
        const selectedVertex = new Vector2()
        const otherElementVertex = new Vector2()
        const currentDelta = new Vector2()
        const extraDelta = new Vector2()
        this.snapToElementXUI.clear()
        this.snapToElementYUI.clear()
        this.isXSnapping = false
        this.isYSnapping = false
        // go through all vertices to get the closest distance to snapping
        // and add vertices of other element that the dragging element is snapping to
        for (const [x1, mapY1] of this.selectedVertices.entries()) {
            for (const y1Data of mapY1.entries()) {
                const y1 = y1Data[0]
                selectedVertex.set(x1, y1)
                const selectedVertexUI = this._convertPosToUIPanelVersion(selectedVertex)
                // to check the position of vertex is integer, if not then don't do snapping
                const isX1OnPixelStep = Number.isInteger(selectedVertexUI.x) || !isSnapToGrid
                const isY1OnPixelStep = Number.isInteger(selectedVertexUI.y) || !isSnapToGrid

                for (const [x2, mapY2] of this.otherElementsVertices.entries()) {
                    for (const y2Data of mapY2.entries()) {
                        const y2 = y2Data[0]
                        otherElementVertex.set(x2, y2)
                        const otherElementVertexUI = this._convertPosToUIPanelVersion(otherElementVertex)
                        // if snap to pixel grid, skip the vertex is not on Grid step
                        const isX2OnPixelStep =  Number.isInteger(otherElementVertexUI.x) || !isSnapToGrid
                        const isY2OnPixelStep =  Number.isInteger(otherElementVertexUI.y) || !isSnapToGrid
                        // otherElementVertex will be the diff of otherElement vertex and
                        // current selected vertex and then sub the delta
                        currentDelta.copy(otherElementVertexUI).sub(selectedVertexUI).sub(delta)
                        if (isX1OnPixelStep && isX2OnPixelStep && Math.abs(currentDelta.x) <= this.threshold) {
                            // if it has snapping before then check the distance
                            // if the new distance is shorter then update the data and clear previous UI data in the map
                            if (!this.isXSnapping) {
                                extraDelta.x = currentDelta.x
                                this.isXSnapping = true
                            } else if (Math.abs(extraDelta.x) > Math.abs(currentDelta.x)) {
                                this.snapToElementXUI.clear()
                                extraDelta.x = currentDelta.x
                            }
                        }

                        if (isY1OnPixelStep && isY2OnPixelStep && Math.abs(currentDelta.y) <= this.threshold) {
                            if (!this.isYSnapping) {
                                extraDelta.y = currentDelta.y
                                this.isYSnapping = true
                            } else if (Math.abs(extraDelta.y) > Math.abs(currentDelta.y)) {
                                this.snapToElementYUI.clear()
                                extraDelta.y = currentDelta.y
                            }

                        }
                        // add other element's vertex to data base if the current delta is same as previous delta
                        if(this.isXSnapping && extraDelta.x === currentDelta.x) {
                            this._addSnapXUIData(otherElementVertexUI.x, otherElementVertexUI.y, this.snapToElementXUI)
                        }
                        if(this.isYSnapping && extraDelta.y === currentDelta.y) {
                            this._addSnapYUIData(otherElementVertexUI.x, otherElementVertexUI.y, this.snapToElementYUI)
                        }
                    }
                }
            }
        }
        //
        this.spacingAreaUIMap.clear()
        this.spacingHorizonal = 0
        this.spacingVertical = 0
        // update the bounds
        const compareBounds = this.selectedBoundsWorld.clone()
        compareBounds.x += delta.x
        compareBounds.x += extraDelta.x
        compareBounds.y += delta.y
        compareBounds.y += extraDelta.y
        // Spacing data
        this._moveSnapSpacing(compareBounds, extraDelta, isSnapToGrid)
        this._updateMoveSnappingUI(extraDelta, delta)
        return extraDelta
    }

    _updateMoveSnappingUI(extraDelta, delta) {
        if (!this.isXSnapping) {
            this.snapToElementXUI.clear()
        }

        if (!this.isYSnapping) {
            this.snapToElementYUI.clear()
        }

        // add dragging element's vertices to UI data map if the vertex is snapping to any vertex
        const newVertPos = new Vector2()
        // mapY1: Map<posY, offset>
        for (const [x1, mapY1] of this.selectedVertices.entries()) {
            for (const yData of mapY1.entries()) {
                const y2 = yData[0]
                newVertPos.set(x1, y2)
                const newUIPos = this._convertPosToUIPanelVersion(newVertPos)
                newVertPos.copy(newUIPos)
                newVertPos.add(extraDelta).add(delta)
                const newUIPos2 = this._convertPosToUIPanelVersion(newVertPos)
                newVertPos.copy(newUIPos2)

                if (this.snapToElementXUI.has(newVertPos.x)) {
                    this.snapToElementXUI.get(newVertPos.x).set(newVertPos.y, 0)
                }

                if (this.snapToElementYUI.has(newVertPos.y)) {
                    this.snapToElementYUI.get(newVertPos.y).set(newVertPos.x, 0)
                }
            }
        }
    }

    /**
     * @param {Vector2} mousePos
     * @param {Vector2} initMousePos
     * selectedVertPos is the click point position
     * if dragging an edge, the position value will be the center point position of two vertices of the edge
     * if dragging an vertex the position value will be same as the vertex's
     * @param {Vector2} selectedVertPos
     * @param {Vector2} anchor
     * @param {boolean} [isSnapToGrid]
     * @param {boolean} [isAlignAxis] prevent an edge of a rotated element from snapping
     * @param {number} resizeType
     * @param {boolean} keepAspect
     * @returns {Vector2}
     */
    comparingVerticesWithResize(mousePos, initMousePos, selectedVertPos, anchor, isSnapToGrid = true, isAlignAxis = null, resizeType, keepAspect = false) {
        const resizeDir = new Vector2(0.5, 0.5).sub(anchor)
        const isClickEdge = resizeDir.x === 0 || resizeDir.y === 0
        if (isClickEdge && (isAlignAxis !== null && !isAlignAxis)) {
            this.snapToElementXUI.clear()
            this.snapToElementYUI.clear()
            this.isXSnapping = false
            this.isYSnapping = false
            return mousePos.clone()
        }
        const selectedVertex = new Vector2()
        const otherElementVertex = new Vector2()
        const currentDelta = new Vector2()
        const extraDelta = new Vector2()
        // the offset of initMousePos to dragging vertex of center point of dragging edge
        const offset = initMousePos.clone().sub(selectedVertPos)
        // the moving edge/vertex position without involving initMouse position offset
        const newMousePos = this._convertPosToUIPanelVersion(mousePos.clone().sub(offset))
        this.snapToElementXUI.clear()
        this.snapToElementYUI.clear()
        this.isXSnapping = false
        this.isYSnapping = false
        const mouseOnVertEdge = anchor.y === 0.5
        const mouseOnHoriEdge = anchor.x === 0.5

        for (const [x1, mapY1] of this.selectedVertices.entries()) {
            for (const y1Data of mapY1.entries()) {
                const y1 = y1Data[0]
                selectedVertex.set(x1, y1)
                // convert system data to UI panel data
                const selectedVertexUI = this._convertPosToUIPanelVersion(selectedVertex)
                // check whether the position on the UI panel is integer
                const isX1OnPixelStep = Number.isInteger(selectedVertexUI.x) || !isSnapToGrid
                const isY1OnPixelStep = Number.isInteger(selectedVertexUI.y) || !isSnapToGrid
                for (const [x2, mapY2] of this.otherElementsVertices.entries()) {
                    for (const y2Data of mapY2.entries()) {
                        const y2 = y2Data[0]
                        otherElementVertex.set(x2, y2)
                        // convert system data to UI panel data
                        const otherElementVertexUI = this._convertPosToUIPanelVersion(otherElementVertex)
                        // check whether the position on the UI panel is integer
                        const isX2OnPixelStep =  Number.isInteger(otherElementVertexUI.x) || !isSnapToGrid
                        const isY2OnPixelStep =  Number.isInteger(otherElementVertexUI.y) || !isSnapToGrid

                        // otherElementVertex will be the variation of otherElement vertex and
                        // current selected vertex add the delta
                        if (mouseOnVertEdge || mouseOnHoriEdge) {
                            selectedVertexUI.x = this.selectedVertices.size === 1 ? newMousePos.x : selectedVertexUI.x
                            selectedVertexUI.y = this.selectedVertices.size === 1 ? selectedVertexUI.y : newMousePos.y
                            currentDelta.copy(otherElementVertexUI).sub(selectedVertexUI)
                        } else {
                            currentDelta.copy(otherElementVertexUI).sub(newMousePos)
                        }

                        if (isX1OnPixelStep && isX2OnPixelStep && Math.abs(currentDelta.x) <= this.threshold && !mouseOnHoriEdge) {
                            if (this.isXSnapping) {
                                // previous delta is larger than the current delta, remove previous vertices and update delta
                                if (Math.abs(extraDelta.x) - Math.abs(currentDelta.x) > CMP_EPSILON) {
                                    extraDelta.x = currentDelta.x
                                    this.snapToElementXUI.clear()
                                }
                            } else {
                                extraDelta.x = currentDelta.x
                            }

                            this.isXSnapping = true
                        }

                        if (isY1OnPixelStep && isY2OnPixelStep && Math.abs(currentDelta.y) <= this.threshold && !mouseOnVertEdge) {
                            if (this.isYSnapping) {
                                if (Math.abs(extraDelta.y) - Math.abs(currentDelta.y) > CMP_EPSILON) {
                                    extraDelta.y = currentDelta.y
                                    this.snapToElementYUI.clear()
                                }
                            } else {
                                extraDelta.y = currentDelta.y
                            }

                            this.isYSnapping = true
                        }

                        if(this.isXSnapping && extraDelta.x === currentDelta.x) {
                            // add other element's vertex to data base
                            this._addSnapXUIData(otherElementVertexUI.x, otherElementVertexUI.y, this.snapToElementXUI)
                        }
                        if(this.isYSnapping && extraDelta.y === currentDelta.y) {
                            // add other element's vertex to data base
                            this._addSnapYUIData(otherElementVertexUI.x, otherElementVertexUI.y, this.snapToElementYUI)
                        }
                    }
                }
            }
        }

        // Don't directly use newMousePos.copy(mousePos).add(extraDelta)
        newMousePos.add(extraDelta)
        this.spacingAreaUIMap.clear()
        this.spacingHorizonal = 0
        this.spacingVertical = 0
        if (!keepAspect) {
            const gripPosition = Snapping.getGripPointFromVector(resizeDir)
            // this.debugRect = null
            const compareBounds = this._updateCompareBoundsInGeneral(resizeType, gripPosition, newMousePos)
            this._resizeSnapSpacing(resizeDir, compareBounds, extraDelta, isSnapToGrid)
            this._updateSnappingUIResize(mouseOnHoriEdge, mouseOnVertEdge, newMousePos, extraDelta)
            newMousePos.copy(mousePos).add(extraDelta)
        }
        return newMousePos
    }

    _updateSnappingUIResize(mouseOnHoriEdge, mouseOnVertEdge, newMousePos, extraDelta) {
        if (!this.isXSnapping) {
            this.snapToElementXUI.clear()
        }

        if (!this.isYSnapping) {
            this.snapToElementYUI.clear()
        }

        // add dragging element's vertices to UI data map if the vertex is snapping to any vertex
        const newVertPos = new Vector2()
        // mapY1: Map<posY, offset>
        for (const [x, mapY1] of this.selectedVertices.entries()) {
            for (const yData of mapY1.entries()) {
                const y = yData[0]
                newVertPos.set(x, y)
                const newUIPos = this._convertPosToUIPanelVersion(newVertPos)
                newVertPos.copy(newUIPos)

                if (mouseOnHoriEdge || mouseOnVertEdge) {
                    if (this.snapToElementXUI.has(newMousePos.x + extraDelta.x)) {
                        this.snapToElementXUI.get(newMousePos.x + extraDelta.x).set(newVertPos.y, 0)
                    }
                    if (this.snapToElementYUI.has(newMousePos.y + extraDelta.y)) {
                        this.snapToElementYUI.get(newMousePos.y + extraDelta.y).set(newVertPos.x, 0)
                    }
                } else {
                    if (this.snapToElementXUI.has(newMousePos.x + extraDelta.x)) {
                        this.snapToElementXUI.get(newMousePos.x + extraDelta.x).set(newMousePos.y + extraDelta.y, 0)
                    }
                    if (this.snapToElementYUI.has(newMousePos.y + extraDelta.y)) {
                        this.snapToElementYUI.get(newMousePos.y + extraDelta.y).set(newMousePos.x + extraDelta.x, 0)
                    }
                }
            }
        }
    }

    _updateCompareBoundsInGeneral(resizeType, gripPosition, newMousePos) {
        let compareBounds
        if (resizeType === Snapping.ResizeTypes.RESIZE_ELEMENTS) {
            compareBounds = Snapping.resizeRect(this.selectedBoundsWorld, gripPosition, newMousePos)
        } else if (resizeType === Snapping.ResizeTypes.RESIZE_ONE_ELEMENT) {
            if (!this.hoverNodeTransform || !this.hoverNodeTransform.rotation) {
                compareBounds = Snapping.resizeRect(this.selectedBoundsWorld, gripPosition, newMousePos)
            } else {
                const newMousePosLocal = this.hoverNodeTransform.worldInv.xform(newMousePos)
                const compareBoundsLocal = Snapping.resizeRect(this.boundsForResize, gripPosition, newMousePosLocal)
                compareBounds = this.hoverNodeTransform.world.xform_rect(compareBoundsLocal)
            }
        } else if (this.hoverNodeTransform) {
            const newMousePosLocal = this.hoverNodeTransform.worldInv.xform(newMousePos)
            const compareBoundsLocal = Snapping.scaleRect(this.boundsForResize, gripPosition, newMousePosLocal, this.hoverNodeTransform.getPivotOffset())
            compareBounds = this.hoverNodeTransform.world.xform_rect(compareBoundsLocal)
            // this.debugRect = compareBounds
        } else {
            console.warn('Invalid in comparingVerticesWithResize')
        }
        return compareBounds
    }

    _resizeSnapSpacing(resizeDir, compareBounds, extraDelta, isSnapToGrid) {

        if (resizeDir.x >= anchorThreshold || resizeDir.x <= -anchorThreshold) {
            // Spacing data
            this._generateAllSpacing(true, compareBounds)
            const nonContactOnX = Array.from(this.spaceOnXLUT.keys()).sort((a, b) => a - b)
            const ignoreNonContactDir = resizeDir.x >= anchorThreshold ? Snapping.IgnoreNonContactDir.LEFT : Snapping.IgnoreNonContactDir.RIGHT
            const tol = this.isXSnapping ? CMP_EPSILON : SPACE_TOLERANCE
            const possibleSpacing = Snapping._compareSpacing(compareBounds.left, compareBounds.right, this.snapAreaOnXLeftLUT, this.snapAreaOnXRightLUT, nonContactOnX, tol, tol, ignoreNonContactDir)
            if (possibleSpacing) {
                const expectedSpacing = this._updateResizeExtraDeltaBySpacing(resizeDir, possibleSpacing, extraDelta, compareBounds, true, isSnapToGrid)
                if (expectedSpacing > 0) {
                    this._snapSpacingUpdateUIXaxis(compareBounds, possibleSpacing, expectedSpacing)
                }
            }
        }

        // left = top, right = bottom
        if (resizeDir.y >= anchorThreshold || resizeDir.y <= -anchorThreshold) {
            this._generateAllSpacing(false, compareBounds)
            const nonContactOnY = Array.from(this.spaceOnYLUT.keys()).sort((a, b) => a - b)
            const ignoreNonContactDir = resizeDir.y >= anchorThreshold ? Snapping.IgnoreNonContactDir.RIGHT : Snapping.IgnoreNonContactDir.RIGHT
            const tol = this.isYSnapping ? CMP_EPSILON : SPACE_TOLERANCE
            const possibleSpacing = Snapping._compareSpacing(compareBounds.top, compareBounds.bottom, this.snapAreaOnYTopLUT, this.snapAreaOnYBottomLUT, nonContactOnY, tol, tol, ignoreNonContactDir)
            if (possibleSpacing) {
                const expectedSpacing = this._updateResizeExtraDeltaBySpacing(resizeDir, possibleSpacing, extraDelta, compareBounds, false, isSnapToGrid)
                if (expectedSpacing > 0) {
                    this._snapSpacingUpdateUIYaxis(compareBounds, possibleSpacing, expectedSpacing)
                }
            }
        }
    }

    /**
     * @param {Vector2} resizeDir
     * @param {object} possibleSpacing
     * @param {Vector2} extraDelta
     * @param {Rect2} compareBounds
     * @param {boolean} isHorizontal
     * @param {boolean} isSnapToGrid
     * @returns {number}
     */
    _updateResizeExtraDeltaBySpacing(resizeDir, possibleSpacing, extraDelta, compareBounds, isHorizontal, isSnapToGrid) {
        let expectedSpacing = 0
        const isPositiveAnchor = (isHorizontal ? resizeDir.x : resizeDir.y) >= anchorThreshold
        if (possibleSpacing.leftBounds && possibleSpacing.rightBounds) {
            expectedSpacing = Snapping.roundToUIPanelVersion(isPositiveAnchor ? possibleSpacing.leftSpace : possibleSpacing.rightSpace)
            if (isSnapToGrid && !Number.isInteger(expectedSpacing)){
                return -1
            }
            if (isHorizontal) {
                extraDelta.x += isPositiveAnchor ? (possibleSpacing.rightSpace - expectedSpacing) : (expectedSpacing - possibleSpacing.leftSpace)
            } else {
                extraDelta.y += isPositiveAnchor ? (possibleSpacing.rightSpace - expectedSpacing) : (expectedSpacing - possibleSpacing.leftSpace)
            }
        } else {
            expectedSpacing = this._updateEtractDeltaByNonContactedSpacing(possibleSpacing, isHorizontal, extraDelta, compareBounds, isSnapToGrid)
        }
        return expectedSpacing
    }

    _moveSnapSpacing(compareBounds, extraDelta, isSnapToGrid) {

        const xTol = this.isXSnapping ? CMP_EPSILON : SPACE_TOLERANCE
        this._generateAllSpacing(true, compareBounds)
        const nonContactOnX = Array.from(this.spaceOnXLUT.keys()).sort((a, b) => a - b)
        let possibleSpacing = Snapping._compareSpacing(compareBounds.left, compareBounds.right, this.snapAreaOnXLeftLUT, this.snapAreaOnXRightLUT, nonContactOnX, xTol * 2, xTol)
        if (possibleSpacing) {
            const expectedSpacing = this._updateMoveExtraDeltaBySpacing(possibleSpacing, extraDelta, compareBounds, true, isSnapToGrid)
            if (expectedSpacing > 0) {
                this._snapSpacingUpdateUIXaxis(compareBounds, possibleSpacing, expectedSpacing)
            }
        }

        const yTol = this.isYSnapping ? CMP_EPSILON : SPACE_TOLERANCE
        this._generateAllSpacing(false, compareBounds)
        const nonContactOnY = Array.from(this.spaceOnYLUT.keys()).sort((a, b) => a - b)
        possibleSpacing = Snapping._compareSpacing(compareBounds.top, compareBounds.bottom, this.snapAreaOnYTopLUT, this.snapAreaOnYBottomLUT, nonContactOnY, yTol * 2, yTol)
        if (possibleSpacing) {
            const expectedSpacing = this._updateMoveExtraDeltaBySpacing(possibleSpacing, extraDelta, compareBounds, false, isSnapToGrid)
            if (expectedSpacing > 0){
                this._snapSpacingUpdateUIYaxis(compareBounds, possibleSpacing, expectedSpacing)
            }
        }
    }

    /**
     * @param {object} possibleSpacing
     * @param {Vector2} extraDelta This will be modifed
     * @param {Rect2} compareBounds
     * @param {boolean} isHorizontal
     * @param {boolean} isSnapToGrid
     * @returns {number}
     */
    _updateMoveExtraDeltaBySpacing(possibleSpacing, extraDelta, compareBounds, isHorizontal, isSnapToGrid) {
        let expectedSpacing = 0
        if (possibleSpacing.leftBounds && possibleSpacing.rightBounds) {
            expectedSpacing = Snapping.roundToUIPanelVersion((possibleSpacing.leftSpace + possibleSpacing.rightSpace) * 0.5)
            if (isSnapToGrid && !Number.isInteger(expectedSpacing)){
                return -1
            }
            if (isHorizontal){
                extraDelta.x += expectedSpacing - possibleSpacing.leftSpace
            } else{
                extraDelta.y += expectedSpacing - possibleSpacing.leftSpace
            }
        } else {
            expectedSpacing = this._updateEtractDeltaByNonContactedSpacing(possibleSpacing, isHorizontal, extraDelta, compareBounds, isSnapToGrid)
        }
        return expectedSpacing
    }

    _updateEtractDeltaByNonContactedSpacing(possibleSpacing, isHorizontal, extraDelta, compareBounds, isSnapToGrid) {
        if (isSnapToGrid && !Number.isInteger(possibleSpacing.nonContactSpacing)){
            return -1
        }
        const expectedSpacing = possibleSpacing.nonContactSpacing
        if (possibleSpacing.leftBounds) {
            if (isHorizontal) {
                extraDelta.x += expectedSpacing - (compareBounds.left - possibleSpacing.leftBounds.right)
            } else {
                extraDelta.y += expectedSpacing - (compareBounds.top - possibleSpacing.leftBounds.bottom)
            }
        } else {
            if (isHorizontal) {
                extraDelta.x += (possibleSpacing.rightBounds.left - compareBounds.right) - expectedSpacing
            } else {
                extraDelta.y += (possibleSpacing.rightBounds.top - compareBounds.bottom) - expectedSpacing
            }
        }
        return expectedSpacing
    }

    _snapSpacingUpdateUIXaxis(compareBounds, possibleSpacing, expectedSpacing){
        let leftDisplayRect = null, rightDisplayRect = null
        if (possibleSpacing.leftBounds) {
            leftDisplayRect = Snapping.createHorizontalRect(possibleSpacing.leftBounds, true, expectedSpacing, compareBounds)
            Snapping.addToDisplay(this.spacingAreaUIMap, leftDisplayRect)
            this.spacingHorizonalVec.x = possibleSpacing.leftBounds.right
            this.spacingHorizonalVec.y = compareBounds.y + compareBounds.height * 0.5
            this.spacingHorizonal = expectedSpacing
        }
        if (possibleSpacing.rightBounds) {
            rightDisplayRect = Snapping.createHorizontalRect(possibleSpacing.rightBounds, false, expectedSpacing, compareBounds)
            Snapping.addToDisplay(this.spacingAreaUIMap, rightDisplayRect)
            if (!possibleSpacing.leftBounds) {
                this.spacingHorizonalVec.x = possibleSpacing.rightBounds.left - expectedSpacing
                this.spacingHorizonalVec.y = compareBounds.y + compareBounds.height * 0.5
                this.spacingHorizonal = expectedSpacing
            }
        }

        if (this.spaceOnXLUT.has(expectedSpacing)) {
            const rectPairArr = this.spaceOnXLUT.get(expectedSpacing)
            for (const rectPiar of rectPairArr) {
                const nonContactRect = Snapping.createHorizontalRect(rectPiar[0], true, expectedSpacing, rectPiar[1])
                if (leftDisplayRect && leftDisplayRect.overlaps(nonContactRect)) {
                    continue
                }
                if (rightDisplayRect && rightDisplayRect.overlaps(nonContactRect)) {
                    continue
                }
                Snapping.addToDisplay(this.spacingAreaUIMap, nonContactRect)
            }
        }

    }
    _snapSpacingUpdateUIYaxis(compareBounds, possibleSpacing, expectedSpacing ) {
        if (this.spaceOnYLUT.has(expectedSpacing)) {
            const rectPairArr = this.spaceOnYLUT.get(expectedSpacing)
            for (const rectPiar of rectPairArr) {
                const nonContactRect= Snapping.createVerticalRect(rectPiar[0], true, expectedSpacing, rectPiar[1])
                if (possibleSpacing.leftBounds && possibleSpacing.leftBounds.overlaps(nonContactRect)) {
                    continue
                }
                if (possibleSpacing.rightBounds && possibleSpacing.rightBounds.overlaps(nonContactRect)) {
                    continue
                }
                Snapping.addToDisplay(this.spacingAreaUIMap, nonContactRect)
            }
        }

        if (possibleSpacing.leftBounds) {
            Snapping.addToDisplay(this.spacingAreaUIMap, Snapping.createVerticalRect(possibleSpacing.leftBounds, true, expectedSpacing, compareBounds))
            this.spacingVerticalVec.x = compareBounds.x + compareBounds.width * 0.5
            this.spacingVerticalVec.y = possibleSpacing.leftBounds.bottom
            this.spacingVertical = expectedSpacing
        }
        if (possibleSpacing.rightBounds) {
            Snapping.addToDisplay(this.spacingAreaUIMap, Snapping.createVerticalRect(possibleSpacing.rightBounds, false, expectedSpacing, compareBounds))
            if (!possibleSpacing.leftBounds) {
                this.spacingVerticalVec.x = compareBounds.x + compareBounds.width * 0.5
                this.spacingVerticalVec.y = possibleSpacing.rightBounds.top - expectedSpacing
                this.spacingVertical = expectedSpacing
            }
        }
    }

    // Function to translate Vector2 grip to GripPoint
    static getGripPointFromVector(gripVector) {
        const { x, y } = gripVector

        if (Math.abs(x) < anchorThreshold && Math.abs(y) < anchorThreshold) {
            return null // No grip point selected
        }

        if (y <= -anchorThreshold) {
            if (x <= -anchorThreshold) return GripPosition.TOP_LEFT
            if (x >= anchorThreshold) return GripPosition.TOP_RIGHT
            return GripPosition.TOP
        } else if (y >= anchorThreshold) {
            if (x <= -anchorThreshold) return GripPosition.BOTTOM_LEFT
            if (x >= anchorThreshold) return GripPosition.BOTTOM_RIGHT
            return GripPosition.BOTTOM
        } else {
            if (x <= -anchorThreshold) return GripPosition.LEFT
            if (x >= anchorThreshold) return GripPosition.RIGHT
        }

        return null // Fallback, should not reach here
    }

    /**
     * Resize a Rect2 based on grip position and current mouse position
     * @param {Rect2} rect - The initial state created by createResizeState
     * @param {number} gripPosition - The position where the mouse is gripping (use GripPosition enum)
     * @param {Vector2} mousePos - The current mouse position
     * @returns {Rect2} The resized rectangle
     */
    static resizeRect(rect, gripPosition, mousePos) {
        const newRect = rect.clone()

        switch (gripPosition) {
            case GripPosition.TOP_LEFT:
                newRect.width = rect.right - mousePos.x
                newRect.height = rect.bottom - mousePos.y
                newRect.x = mousePos.x
                newRect.y = mousePos.y
                break
            case GripPosition.TOP:
                newRect.height = rect.bottom - mousePos.y
                newRect.y = mousePos.y
                break
            case GripPosition.TOP_RIGHT:
                newRect.width = mousePos.x - rect.x
                newRect.height = rect.bottom - mousePos.y
                newRect.y = mousePos.y
                break
            case GripPosition.RIGHT:
                newRect.width = mousePos.x - rect.x
                break
            case GripPosition.BOTTOM_RIGHT:
                newRect.width = mousePos.x - rect.x
                newRect.height = mousePos.y - rect.y
                break
            case GripPosition.BOTTOM:
                newRect.height = mousePos.y - rect.y
                break
            case GripPosition.BOTTOM_LEFT:
                newRect.width = rect.right - mousePos.x
                newRect.height = mousePos.y - rect.y
                newRect.x = mousePos.x
                break
            case GripPosition.LEFT:
                newRect.width = rect.right - mousePos.x
                newRect.x = mousePos.x
                break
        }

        return newRect
    }

    /**
     * Resize a Rect2 based on grip position and current mouse position
     * @param {Rect2} rect - The initial state created by createResizeState
     * @param {number} gripPosition - The position where the mouse is gripping (use GripPosition enum)
     * @param {Vector2} mousePos - The current mouse position
     * @param {Vector2} origin
     * @returns {Rect2} The resized rectangle
     */
    static scaleRect(rect, gripPosition, mousePos, origin) {
        const newRect = rect.clone()
        let scaleX = 1
        let scaleY = 1
        switch (gripPosition) {
            case GripPosition.TOP_LEFT:
                scaleX = (origin.x - mousePos.x) / (origin.x - rect.x)
                scaleY = (origin.y - mousePos.y) / (origin.y - rect.y)
                break
            case GripPosition.TOP:
                scaleY = (origin.y - mousePos.y) / (origin.y - rect.y)
                break
            case GripPosition.TOP_RIGHT:
                scaleX = (mousePos.x - origin.x) / (rect.x + rect.width - origin.x)
                scaleY = (origin.y - mousePos.y) / (origin.y - rect.y)
                break
            case GripPosition.RIGHT:
                scaleX = (mousePos.x - origin.x) / (rect.x + rect.width - origin.x)
                break
            case GripPosition.BOTTOM_RIGHT:
                scaleX = (mousePos.x - origin.x) / (rect.x + rect.width - origin.x)
                scaleY = (mousePos.y - origin.y) / (rect.y + rect.height - origin.y)
                break
            case GripPosition.BOTTOM:
                scaleY = (mousePos.x - origin.x) / (rect.x + rect.width - origin.x)
                break
            case GripPosition.BOTTOM_LEFT:
                scaleX = (origin.x - mousePos.x) / (origin.x - rect.x)
                scaleY = (mousePos.y - origin.y) / (rect.y + rect.height - origin.y)
                break
            case GripPosition.LEFT:
                scaleX = (origin.x - mousePos.x) / (origin.x - rect.x)
                break
        }
        if (Number.isFinite(scaleX)) {
            newRect.width *= scaleX
            newRect.x = origin.x + (newRect.x - origin.x) * scaleX
        }
        if (Number.isFinite(scaleY)) {
            newRect.height *= scaleY
            newRect.y = origin.y + (newRect.y - origin.y) * scaleY
        }
        return newRect
    }


    static createHorizontalRect (bounds, isLeft, expectedSpacing, compareBounds) {
        const x = isLeft ? bounds.right : bounds.left - expectedSpacing
        const y = Math.min(compareBounds.top, bounds.top)
        const height = Math.max(compareBounds.bottom, bounds.bottom) - y
        return new Rect2(x, y, expectedSpacing, height)
    }

    static createVerticalRect (bounds, isTop, expectedSpacing, compareBounds) {
        const x = Math.min(compareBounds.left, bounds.left)
        const y = isTop ? bounds.bottom : bounds.top - expectedSpacing
        const width = Math.max(compareBounds.right, bounds.right) - x
        return new Rect2(x, y, width, expectedSpacing)
    }

    static addToDisplay (spacingAreaUIMap, rect) {
        const hash = rect.hash()
        if (!spacingAreaUIMap.has(hash)) {
            spacingAreaUIMap.set(hash, rect)
        }
    }

    endSnapMovingElementToElement() {
        this.otherElementsVertices.clear()
        this.selectedVertices.clear()
        this.snapToElementXUI.clear()
        this.snapToElementYUI.clear()
        this.spacingAreaUIMap.clear()
        this.spacingHorizonal = 0
        this.spacingVertical = 0
    }

    /**
     * check if the selection aabb vert/hori with x/y axis when it is single selection
     * if it is multi-selection return true
     * @param {Selection} selection
     * @returns {bool}
     */
    isBoundingBoxAlignAxis(selection) {
        return !selection.single || this.isMultipleOf90Degree(selection.first.node.item.transform.rotation)
    }

    /**
     * Check if following scaling conditions fit to snap to pixel when dragging handler
     * In the multi-selection the rotated element cannot snap scaling to all of
     * pixel grid when dragging handler
     * Condition not to snap scaling to pixel grid:
     * 1. It is multi-selection
     * 2. It has rotated element in selection
     * 3. It is dragging handler
     * @param {Element} hoveredNode
     * @param {bool} isClickEdge
     * @returns {bool}
     */
    isScalingSnapToPixelGrid(hoveredNode, isClickEdge) {
        const hasRotatedElement = this.isMultipleOf90Degree(hoveredNode.item.transform.world.get_rotation())
        return !isClickEdge || (isClickEdge && hasRotatedElement)
    }

    /**
     * Get the point at the center of edge as the click point
     * @param {Vector2} scaleDir
     * @param {SceneNode} hoverNode
     * @returns {Vector2} (warn: need to free after used)
     */
    getScalingClickPoint(scaleDir, hoverNode) {
        const elementSize = hoverNode.item.transform.size.clone().abs()
        const clickPoint = point.set(scaleDir.x * elementSize.x, scaleDir.y * elementSize.y).add(hoverNode.boundsLocal.center)
        hoverNode.item.transform.world.xform(clickPoint, clickPoint)
        return clickPoint
    }

    /**
     * snap each element to pixel grid in the selection
     * @param {Vector2} delta
     * @param {Selection} selection
     * @param {number} rotation
     * @param {Vector2} ratio
     * @param {Vector2} parentScale
     * @param {bool} isSnapScalingToPixelGrid
     * @returns {Vector2}
     */
    snapScalingToPixelGrid(delta, selection, rotation, ratio, parentScale, isSnapScalingToPixelGrid) {
        const newDelta = delta.clone()
        const cos = Math.cos(rotation)
        newDelta.divide(parentScale)

        // The rotation of a single selection should be already accounted before
        // by rotating the delta vector with the negative rotation of the selected element.
        // Therefore, there's no need to worry about the rotation of a single selection here
        if (!selection.single && !this.isMultipleOf90Degree(rotation)) {
            newDelta.divide(Math.abs(cos), Math.abs(cos))
        }

        if (isSnapScalingToPixelGrid) {
            newDelta.multiply(ratio)
        }
        return newDelta
    }

    /**
     * When changing sign of scaling value, this will keep outside edge snapping to pixel grid
     * @param {Vector2} scaleDir
     * @param {Vector2} scale
     * @param {Vector2} origin
     * @param {number} scaleX
     * @param {number} scaleY
     * @returns {Vector2}
     */
    flipSnapEdge(scaleDir, scale, origin, scaleX, scaleY) {
        const newScale = scale.clone()
        const flipRatioX = scaleDir.x > 0 ? (100 - origin.x) / origin.x : origin.x / (100 - origin.x)
        const flipRatioY = scaleDir.y > 0 ? (100 - origin.y) / origin.y : origin.y / (100 - origin.y)
        const oldScaleSign = new Vector2(scaleX, scaleY).sign()
        const newScaleSign = scale.clone().sign()
        if (oldScaleSign.x !== newScaleSign.x) {
            newScale.x = scale.x * flipRatioX
        }
        if (oldScaleSign.y !== newScaleSign.y) {
            newScale.y = scale.y * flipRatioY
        }
        return newScale
    }

    /**
     * return last position of snapping to pixel grid
     * @returns {Vector2}
     */
    getSnapGridPos() {
        return this.lastSnapToGridPos
    }

    /**
     * check whether the angle is multiple of 90 degree
     * @param {number} radian
     * @returns {bool}
     */
    isMultipleOf90Degree(radian) {
        const epsilon_radian = radian % (Math.PI * 0.5) < EPSILON ? 0 : radian
        return Number.isInteger(epsilon_radian % (Math.PI * 0.5))
    }

    /**
     * @param {number} angle1
     * @param {number} angle2
     */
    _setAxisDiagonalAngle(angle1, angle2 = null) {
        this.axisDiagonalCount = angle2 ? 2 : 1
        this._setAxisDiagonalVec(angle1, 0)
        this._setAxisDiagonalVec(angle2, 1)
    }

    /**
     * @param {number} angle
     * @param {number} vecIndex
     */
    _setAxisDiagonalVec(angle, vecIndex) {
        switch (angle) {
            case 0:
                this.axisDiagonalVec[vecIndex].set(1, 0)
                break
            case 45:
                this.axisDiagonalVec[vecIndex].set(-1, 1)
                break
            case 90:
                this.axisDiagonalVec[vecIndex].set(0, 1)
                break
            case -45:
                this.axisDiagonalVec[vecIndex].set(1, 1)
                break
            default:
                this.axisDiagonalVec[vecIndex].set(0, 0)
                break
        }
    }

    /**
     * get elements to snap to
     * @param {Set<string>} selectedIdList
     * @param {Element[]} elements
     * @param {Map<number, number[]>} dataList
     * @param {boolean} isMovingEle
     * @param {boolean} isRowArea
     * @param {Set<string>} selectedParent
     * @param {Element[]} spacingElements
     * @param {Rect2} selectionBounds
     */
    _getElementsCornerAndCenterData(selectedIdList, elements, dataList, isMovingEle, isRowArea, selectedParent, spacingElements, selectionBounds) {
        for (const element of elements) {
            const isNotChild = this._checkParentIdNotInclude(element, selectedIdList, isMovingEle)
            const isNotComputedGroupParent = !this._isComputedGroupAncestorOfAnyInList(element, selectedIdList)
            // not snap to Screen, hidden elements, child elements or elements in the selection
            const id = element.get('id')
            const node = this.vs.indexer.nodeMap.get(id)

            if (!element.isHidden() &&
                isNotChild &&
                isNotComputedGroupParent &&
                !selectedIdList.has(id)) {
                const worldRect = node.boundsWorldVisualZeroAABB
                this._addPointDataToMap(worldRect.center.x, worldRect.center.y, dataList)
                this._addPointDataToMap(worldRect.left, worldRect.top, dataList)
                this._addPointDataToMap(worldRect.right, worldRect.top, dataList)
                this._addPointDataToMap(worldRect.left, worldRect.bottom, dataList)
                this._addPointDataToMap(worldRect.right, worldRect.bottom, dataList)

                if (selectedParent.has( this.vs.indexer.getNode(element.get('id')).parent.id)
                    && spacingElements.has(element) // if the element in the fit cross area elements list
                    && !selectionBounds.overlaps(worldRect) // exclude the element which is overlapped with selectedElement
                ){
                    // Spacing
                    if (isRowArea) {
                        this.snapAreaOnXLeftLUT.set(worldRect.left, worldRect)
                        this.snapAreaOnXRightLUT.set(worldRect.right, worldRect)
                    } else {
                        this.snapAreaOnYTopLUT.set(worldRect.top, worldRect)
                        this.snapAreaOnYBottomLUT.set(worldRect.bottom, worldRect)
                    }
                }
            }
        }
    }

    /**
     * exclude to snap to children element
     * @param {Element} hitElement
     * @param {Set<string>} checkIdList
     * @param {boolean} isMovingEle
     * @returns {bool}
     */
    _checkParentIdNotInclude(hitElement, checkIdList, isMovingEle) {
        const node = this.vs.indexer.getNode(hitElement.get('id'))
        let parent = node
        while (!parent.item.isScreen() && parent.parent) {
            if (checkIdList.has(parent.id) && (parent.item.type !== "container" || isMovingEle)) return false
            parent = parent.parent
        }
        return true
    }

    /**
     * check whether the hitElement is a "normal group type" ancestor element
     * of the selected elements
     * @param {Element} hitElement
     * @param {Set<string>} selectedIdList
     * @returns {bool}
     */
    _isComputedGroupAncestorOfAnyInList(hitElement, selectedIdList) {
        const hitNode = this.vs.indexer.getNode(hitElement.get('id'))
        if (!hitElement.isComputedGroup) return false
        // Check whether the hit element is a ancestor of selected elements
        for (const selectedId of selectedIdList) {
            const selected = this.vs.indexer.getNode(selectedId)
            // if the hit element is not deeper than selected element than continue
            let parent = selected
            while(hitNode.item.depth < parent.item.depth) {
                if (parent.parent === hitNode) return true
                parent = parent.parent
            }
        }
        return false
    }

    /**
     * @param {Map<number, Map<number,number>>} map
     * @param {bool} isSingleSelected
     * @param {Rect2} bound
     * @param {Transform2D} worldTrans
     */
    _getElementOABAndCenterData(map, isSingleSelected, bound, worldTrans) {
        // multi-selection (x, y) will not be (0, 0)
        const v1 = new Vector2(bound.x + bound.width, bound.y)
        const v2 = new Vector2(bound.x + bound.width, bound.y + bound.height)
        const v3 = new Vector2(bound.x, bound.y + bound.height)
        const v4 = new Vector2(bound.x, bound.y)
        const v5 = new Vector2(bound.x + bound.width * 0.5, bound.y + bound.height * 0.5)
        if (isSingleSelected) {
            // single-selection (x, y) will be (0, 0)
            const worldBounds = worldTrans.xform_rect(bound)
            v1.copy(worldBounds.topRight)
            v2.copy(worldBounds.bottomRight)
            v3.copy(worldBounds.bottomLeft)
            v4.copy(worldBounds.topLeft)
            v5.copy(worldBounds.center)
        }
        this._addPointDataToMap(v5.x, v5.y, map)
        this._addPointDataToMap(v1.x, v1.y, map)
        this._addPointDataToMap(v2.x, v2.y, map)
        this._addPointDataToMap(v3.x, v3.y, map)
        this._addPointDataToMap(v4.x, v4.y, map)
    }

    /**
     * @param {Map<number, Map<number,number>>} map
     * @param {bool} isSingleSelected
     * @param {Rect2} bound
     * @param {Transform2D} worldTrans
     * @param {Vector2} anchor
     * @param {boolean} isComputedGroup
     */
    _getResizeDraggingSideData(map, isSingleSelected, bound, worldTrans, anchor, isComputedGroup) {
        const resizeDir = new Vector2(0.5, 0.5).sub(anchor)
        const size = new Vector2(bound.width, bound.height)
        const offset = resizeDir.x === 0 ? new Vector2(bound.width * 0.5, 0) : new Vector2(0, bound.height * 0.5)
        const isClickEdge = resizeDir.x === 0 || resizeDir.y === 0
        const v1 = new Vector2()
        const v2 = new Vector2()
        size.multiply(resizeDir)
        // if resizing/scaling is dragging an edge, there would be two snapping vertices
        if (isClickEdge) {
            const midPoint = bound.center.clone().add(size)
            if (isSingleSelected) {
                if (isComputedGroup) {
                    const referencePoint = isSingleSelected.element.get('referencePoint')
                    const computedGroupOffset =  resizeDir.x === 0 ? new Vector2(referencePoint.x, 0) : new Vector2(0, referencePoint.y)
                    v1.set(0, 0).add(size).add(computedGroupOffset)
                    v2.set(0, 0).add(size).sub(computedGroupOffset)
                } else {
                    v1.set(bound.width * 0.5, bound.height * 0.5).add(size).add(offset)
                    v2.set(bound.width * 0.5, bound.height * 0.5).add(size).sub(offset)
                }
            } else {
                v1.copy(midPoint).add(offset)
                v2.copy(midPoint).sub(offset)
            }
            worldTrans.xform(v1, v1)
            worldTrans.xform(v2, v2)
            this._addPointDataToMap(v1.x, v1.y, map)
            this._addPointDataToMap(v2.x, v2.y, map)
        // if resizing/scaling is dragging a handler, there would one snapping vertex
        } else {
            if (isSingleSelected) {
                v1.set(bound.width * 0.5 + size.x, bound.height * 0.5 + size.y)
            } else {
                v1.copy(bound.center).add(size)
            }
            worldTrans.xform(v1, v1)
            this._addPointDataToMap(v1.x, v1.y, map)
        }
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {Map<number, Map<number,number>>} map
     */
    _addPointDataToMap(x, y, map) {
        if(map.has(x)) {
            const subMap = map.get(x)
            subMap.set(y, 0)
        } else {
            const subMap = new Map()
            subMap.set(y, 0)
            map.set(x, subMap)
        }
    }

    _addSnapXUIData(x, y, map) {
        this._addPointDataToMap(x, y, map)
    }

    _addSnapYUIData(x, y, map) {
        this._addPointDataToMap(y, x, map)
    }

    _removePointDelta(map, delta) {
        const removeKeys = []
        for (const [, mapXY] of map.entries()) {
            for (const [posComponent, oldDelta] of mapXY.entries()) {
                if (mapXY.get(posComponent) !== 0) {
                    if (!mapXY.has(posComponent - oldDelta + delta)) {
                        removeKeys.push(posComponent)
                    }
                    mapXY.set(posComponent - oldDelta + delta, delta)
                }
            }
            for (const removeKey of removeKeys) {
                mapXY.delete(removeKey)
            }
            removeKeys.length = 0
        }
    }

    /**
     * convert system data to UI panel data
     * UI panel only shows number with rounding system data to the two decimal places
     * @param {Vector2} pos
     * @returns {Vector2} (warning: need to free after used)
     */
    _convertPosToUIPanelVersion(pos) {
        const uiPanelVersion = pos.clone()
        uiPanelVersion.scale(100)
        uiPanelVersion.round()
        uiPanelVersion.scale(0.01)
        return uiPanelVersion
    }

    /**
     *
     * @param {number} value
     * @returns {number}
     */
    static roundToUIPanelVersion(value) {
        return Math.round(value * 100) / 100
    }

    /**
     * This function should be called before calling _removeOverlappingIntervals
     * @param {boolean} isRow
     * @param {Rect2} overlapBounds
     */
    _generateAllSpacing(isRow, overlapBounds) {
        const result = isRow ? this.spaceOnXLUT : this.spaceOnYLUT
        result.clear()
        const intervals = Array.from((isRow ? this.snapAreaOnXLeftLUT : this.snapAreaOnYTopLUT).entries()).sort((a, b) => a[0] - b[0])
        const n = intervals.length
        // Can not found the better way than O(n^2). However time complexity of the following code snippet is O(n*(n-1)/2)
        for (let i = 0; i < n; i++) {
            let j = i + 1
            while ((j < n) && (intervals[j][0] <= (isRow ? intervals[i][1].right : intervals[i][1].bottom))) {
                j++
            }
            while (j < n) {
                if (!overlapBounds.overlaps(intervals[i][1]) && !overlapBounds.overlaps(intervals[j][1])) {
                    const spacing = Snapping.roundToUIPanelVersion(intervals[j][0] - (isRow ? intervals[i][1].right : intervals[i][1].bottom))
                    if (result.has(spacing)) {
                        result.get(spacing).push([intervals[i][1], intervals[j][1]])
                    } else {
                        result.set(spacing, [[intervals[i][1], intervals[j][1]]])
                    }
                }
                j++
            }
        }
    }

    static IgnoreNonContactDir = Object.freeze({NONE : -1, LEFT: 1, RIGHT: 2})
    /**
     * @param {number} left
     * @param {number} right
     * @param {Map<number, Rect2>} snapLeftLUT
     * @param {Map<number, Rect2>} snapRightLUT
     * @param {number[]} nonContactSpacingArr
     * @param {number} tolerance
     * @param {number} nonContactTOL
     * @param {number} ignoreNonContactDir
     * @returns {{ leftSpace: number, rightSpace:number, leftBounds:Rect2, rightBounds:Rect2, nonContactSpacing:number}}
     */
    static _compareSpacing(left, right, snapLeftLUT, snapRightLUT, nonContactSpacingArr, tolerance, nonContactTOL, ignoreNonContactDir) {
        if (!snapLeftLUT.size && !snapRightLUT.size) {
            return null
        }

        const sortedLeft = Array.from(snapLeftLUT.keys()).sort((a, b) => a - b)
        const sortedRight = Array.from(snapRightLUT.keys()).sort((a, b) => a - b)

        let leftIndex = Snapping._searchInsertPosition(sortedRight, left, true)
        let rightIndex = Snapping._searchInsertPosition(sortedLeft, right, false)

        // Should not be ture
        while (leftIndex > -1 && sortedRight[leftIndex]>= left){
            leftIndex --
        }
        // Should not be ture
        while (rightIndex < sortedLeft.length && (sortedLeft[rightIndex] <= right)) {
            rightIndex++
        }
        // non Contact spacing
        let nonContactLeftBounds = null
        let nonContactRightBounds = null
        let nonContactSpacing = -1
        if (leftIndex >= 0 && rightIndex < sortedLeft.length) {
            const leftSpace = left - sortedRight[leftIndex]
            const rightSpace = sortedLeft[rightIndex] - right
            if (Math.abs(leftSpace - rightSpace) <= tolerance) {
                return { leftSpace, rightSpace, leftBounds: snapRightLUT.get(sortedRight[leftIndex]), rightBounds: snapLeftLUT.get(sortedLeft[rightIndex]) }
            }
        }
        if (leftIndex >= 0 && ignoreNonContactDir !== Snapping.IgnoreNonContactDir.LEFT) {
            const leftSpace = left - sortedRight[leftIndex]
            const nonContactIndex = Snapping._binarySearchWithTolerance(nonContactSpacingArr, leftSpace, nonContactTOL)
            if (nonContactIndex > -1) {
                nonContactLeftBounds = snapRightLUT.get(sortedRight[leftIndex])
                nonContactSpacing = nonContactSpacingArr[nonContactIndex]
            }
        }

        if (rightIndex < sortedLeft.length && ignoreNonContactDir !== Snapping.IgnoreNonContactDir.RIGHT) {
            const rightSpace = sortedLeft[rightIndex] - right
            const nonContactIndex = Snapping._binarySearchWithTolerance(nonContactSpacingArr, rightSpace, nonContactTOL)
            if (nonContactIndex > -1) {
                const newNonContactSpacing = nonContactSpacingArr[nonContactIndex]
                if (nonContactSpacing < 0 || (newNonContactSpacing < nonContactSpacing)) {
                    nonContactRightBounds = snapLeftLUT.get(sortedLeft[rightIndex])
                    nonContactLeftBounds = null
                    nonContactSpacing = newNonContactSpacing
                }
            }
        }

        if (nonContactSpacing !== -1) {
            return { leftBounds: nonContactLeftBounds, rightBounds: nonContactRightBounds, nonContactSpacing }
        }
        return null
    }


    static _binarySearchWithTolerance(arr, target, tolerance) {
        let left = 0
        let right = arr.length - 1

        while (left <= right) {
            const mid = Math.floor((left + right) / 2)
            const midValue = arr[mid]

            if (Math.abs(midValue - target) <= tolerance) {
                return mid // Target found within tolerance
            } else if (midValue < target) {
                left = mid + 1
            } else {
                right = mid - 1
            }
        }

        return -1 // Target not found
    }
    static _searchInsertPosition(arr, target, find_max_less_than){
        let left = 0, right = arr.length - 1
        let result = -1
        while (left <= right){
            const mid = left +  Math.floor((right - left) * 0.5)

            if (find_max_less_than){
                if(arr[mid] < target){
                    result = mid
                    left = mid + 1
                }
                else{
                    right = mid - 1
                }
            }
            else{
                if (arr[mid] > target){
                    result = mid
                    right = mid - 1
                }
                else{
                    left = mid + 1
                }
            }
        }
        return result
    }

}

const point = new Vector2()

const GripPosition = Object.freeze({
    TOP_LEFT: 0,
    TOP: 1,
    TOP_RIGHT: 2,
    RIGHT: 3,
    BOTTOM_RIGHT: 4,
    BOTTOM: 5,
    BOTTOM_LEFT: 6,
    LEFT: 7
})


