import Encoder from '../encoder'
import { GIF2 } from './GIFEncoder'

export class GIFEncoder extends Encoder {
    constructor() {
        super()

        this.gif = new GIF2("octree", false)
        this.hasStarted = false
    }

    static getEstimatedSize({ width, height, fps, duration, transparency, loop, speed }) {
        const headerSize = 14 // 6 bytes for GIF Header + 7 bytes for Logical Screen Descriptor + 1 byte for GCT flag
        const gceSize = 8 // Graphic Control Extension size
        const imageDescriptorSize = 10 // Image Descriptor size
        const netscapeExtensionSize = loop ? 19 : 0 // Netscape Extension block size
        const colorTableSize = 256 * 3 // Assuming full 256 color table used, 3 bytes per color
        const estimatedCompressionEfficiency = 0.05

        const adjustedDuration = duration / speed
        const frameCount = fps * adjustedDuration
        const pixelCount = width * height
        const bytesPerFrame = pixelCount * (transparency ? 4 : 3) // 4 bytes for RGBA, 3 for RGB
        const estimatedCompressedSizePerFrame = bytesPerFrame * estimatedCompressionEfficiency

        // Total estimate
        const estimatedTotalSize = headerSize +
                                (gceSize + imageDescriptorSize + estimatedCompressedSizePerFrame) * frameCount +
                                colorTableSize +
                                netscapeExtensionSize

        const sizeInKilobytes = estimatedTotalSize / 1024

        return sizeInKilobytes
    }

    /**
     * Add frame data to encoder
     * @param {Uint8ClampedArray} pixels
     * @param {{ width: number, height: number, transparent: boolean, loop: boolean, iterTime: number, speed: number, gifTransparentColor: number }} settings
     */
    addFrame(pixels, settings) {
        const { width, height, loop, iterTime, speed, gifTransparentColor } = settings
        if (!this.hasStarted) {
            this.gif.width = width
            this.gif.height = height
            this.gif.setDelay(iterTime / speed)
            this.gif.setRepeat(loop)
            this.gif.setTransparent(gifTransparentColor)
            this.gif.start()
            this.hasStarted = true
        }
        this.gif.addFrame(pixels)
    }

    /**
     * Get encoder data
     */
    getData() {
        return this.gif.buffer.getData()
    }

    /**
     * Finish adding the last frame to encoder
     */
    finalize() {
        this.gif.finish()
    }
}
