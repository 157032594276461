import { useCallback } from 'react'

import { EditMode, LoadingStatus, Mode } from '@phase-software/types'

import { useFilePermissionContext } from '../FilePermissionProvider'
import { useSpinnerLoadingContext } from '../SpinnerLoadingProvider'
import { useTransitionManagerActions } from '../TransitionManagerProvider'
import { createProvider } from '../utils'
import { useDataStore } from './DataStoreProvider'

const defaultValue = {
  mode: Mode.DESIGN, // sync with dataStore.data.mode
  editMode: EditMode.ELEMENT,
  zoom: 1,
  hasRendererInit: false,
  isTargeting: false,
  isEditingState: false,
  isVersioningState: false,
  isInspectingState: false,
  exportProgress: 0,
  exportStatus: LoadingStatus.INITIAL,
  target: {},
  isContentPanelHidden: false,
  isRightPanelHidden: false,
  isToolbarHidden: false,
  hideRuler: false,
  hideOrigin: false,
  isVersionHistoryPanelHidden: false,
  snapToPixelGrid: true,
  snapToObject: true,
  editOrigin: false,
  isActionPanelHidden: false
}
const [Provider, useSelectState, useSetState, getUISnapshot] = createProvider('UI', defaultValue)

const selectionCache = new Set()

export const useUI = useSelectState

export const useSetUI = useSetState

export const useUIActions = () => {
  const dataStore = useDataStore()
  const setState = useSetState()
  const { canEditFile } = useFilePermissionContext()
  const { stopAnimation } = useTransitionManagerActions()
  const { startSpinnerLoadingWithTimeout } = useSpinnerLoadingContext()

  const setMode = useCallback(
    (mode) => {
      dataStore.eam.switchMode(mode)
    },
    [dataStore]
  )

  const changeZoom = useCallback(
    (zoom) => {
      setState((s) => ({ ...s, zoom }))
      dataStore.eam.changeZoom(zoom)
    },
    [setState, dataStore]
  )

  const zoomIn = useCallback(() => {
    dataStore.eam.zoomIn()
  }, [dataStore])

  const zoomOut = useCallback(() => {
    dataStore.eam.zoomOut()
  }, [dataStore])

  const zoomToFit = useCallback(() => {
    dataStore.eam.zoomFitContent()
  }, [dataStore])

  const zoomToSelection = useCallback(() => {
    dataStore.eam.zoomFitSelection()
  }, [dataStore])

  /**
   * Restore the selection and clear cache while switching back to EDITOR mode
   */
  const restoreSelection = useCallback(() => {
    dataStore.selection.selectMultiple([...selectionCache], { undoable: false })
    selectionCache.clear()
  }, [dataStore])

  /**
   * Leave TARGETING mode and clear the target
   */
  const leaveTargetingMode = useCallback(
    (needRestoreSelection = true) => {
      const undo = dataStore.get('undo')
      if (needRestoreSelection) {
        restoreSelection()
      }
      // Clear undo record in TARGETING mode before leaving
      undo.clear()
      undo.context = 'EDITOR'
      setState((s) => ({
        ...s,
        target: {},
        isTargeting: false
      }))
    },
    [setState, dataStore, restoreSelection]
  )

  /**
   * Leave TARGETING mode and change the target
   */
  const changeTarget = useCallback(() => {
    const { target } = getUISnapshot()

    // Exist TRAGETING mode first to make sure the target changes records in EDITOR mode
    leaveTargetingMode(false)
    dataStore.get('undo').commit()
    switch (target.owner) {
      case 'TRIGGER': {
        const trigger = dataStore.getById(target.data.triggerId)
        trigger.updateWithSelection()
        break
      }
      case 'CONDITION': {
        const condition = dataStore.getById(target.data.conditionId)
        condition.updateWithSelection()
        break
      }
      case 'ELEMENT_TRACK': {
        const elementTrack = dataStore.getById(target.data.elementTrackId)
        elementTrack.updateWithSelection()
        break
      }
      default:
        break
    }
    restoreSelection()
    dataStore.get('undo').commit()
  }, [leaveTargetingMode, dataStore, restoreSelection])

  const toggleTargetingMode = useCallback(
    (owner, data) => {
      const undo = dataStore.get('undo')
      dataStore.selection.get('selected').forEach((e) => selectionCache.add(e))
      undo.context = 'TARGETING'
      // Select the targeting element while entering the targeting mode
      let elementId = ''
      switch (owner) {
        case 'TRIGGER': {
          elementId = dataStore.getById(data.triggerId).get('elementId')
          break
        }
        case 'CONDITION': {
          elementId = dataStore.getById(data.conditionId).get('elementId')
          break
        }
        case 'ELEMENT_TRACK': {
          elementId = dataStore.getById(data.elementTrackId).get('elementId')
          break
        }
        default:
          break
      }
      dataStore.selection.select(dataStore.getById(elementId), {
        undoable: false
      })
      setState((s) => ({
        ...s,
        target: { owner, data },
        isTargeting: true
      }))
    },
    [setState, dataStore]
  )

  const setRendererInit = useCallback(
    (hasRendererInit) => {
      setState((s) => ({ ...s, hasRendererInit }))
    },
    [setState]
  )

  const changeExportProgress = useCallback(
    (progress) => {
      setState((s) => ({ ...s, exportProgress: progress }))
    },
    [setState]
  )

  const changeExportStatus = useCallback(
    (exportStatus) => {
      setState((s) => ({ ...s, exportStatus }))
    },
    [setState]
  )

  const toggleInterface = useCallback(() => {
    dataStore.eam.toggleInterface()
  }, [dataStore.eam])

  const toggleRuler = useCallback(() => {
    dataStore.eam.toggleRuler()
  }, [dataStore.eam])

  const toggleCommentVisibility = useCallback(() => {
    dataStore.eam.toggleCommentVisibility()
  }, [dataStore.eam])

  const enterVersionPreview = useCallback(() => {
    stopAnimation()
    dataStore.transition.set('viewerTime', 0, true)
    dataStore.switchState('VERSIONING')
  }, [dataStore, stopAnimation])

  const leaveVersionPreview = useCallback(() => {
    if (dataStore.get('state') === 'VERSIONING') startSpinnerLoadingWithTimeout()
    stopAnimation()
    dataStore.switchState(canEditFile ? 'EDITING' : 'VIEWING')
  }, [canEditFile, dataStore, stopAnimation, startSpinnerLoadingWithTimeout])

  const enterInspectingMode = useCallback(() => {
    const elements = dataStore.selection.get('elements')
    stopAnimation()
    dataStore.transition.set('viewerTime', dataStore.transition.time, true)
    if (elements.length > 1) dataStore.selection.clear()
    if (elements.length === 1 && dataStore.get('editMode') === EditMode.SHAPE) {
      dataStore.eam.activateElementEditMode()
    }
    dataStore.switchState('INSPECTING')
  }, [dataStore, stopAnimation])

  const leaveInspectingMode = useCallback(() => {
    stopAnimation()
    dataStore.transition.set('time', dataStore.transition.viewerTime, true)
    dataStore.switchState(canEditFile ? 'EDITING' : 'VIEWING')
  }, [canEditFile, dataStore, stopAnimation])

  const toggleOrigin = useCallback(() => {
    dataStore.eam.toggleOrigin()
  }, [dataStore.eam])

  const toggleSnapToPixelGrid = useCallback(() => {
    dataStore.eam.toggleSnapToPixelGrid()
  }, [dataStore.eam])

  const toggleSnapToObject = useCallback(() => {
    dataStore.eam.toggleSnapToObject()
  }, [dataStore.eam])

  const setEditOrigin = useCallback(
    (bool) => {
      setState((s) => ({
        ...s,
        editOrigin: bool
      }))
    },
    [setState]
  )

  return {
    changeExportProgress,
    changeExportStatus,
    changeTarget,
    changeZoom,
    enterVersionPreview,
    enterInspectingMode,
    leaveTargetingMode,
    leaveVersionPreview,
    leaveInspectingMode,
    setMode,
    setRendererInit,
    toggleCommentVisibility,
    toggleOrigin,
    toggleInterface,
    toggleRuler,
    toggleSnapToPixelGrid,
    toggleSnapToObject,
    toggleTargetingMode,
    zoomIn,
    zoomOut,
    zoomToFit,
    zoomToSelection,
    setEditOrigin
  }
}

export default Provider
