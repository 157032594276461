import { MediaType } from '@phase-software/types'
import { GIFEncoder } from './gif/index'
import { MP4Encoder } from './mp4/index'
import Recorder from './recorder'

export default class ExportMedia {
    onRecordProgress = null
    /**
     *
     * @param {import('../../visual_server/VisualServer').VisualServer} visualServer
     * @param {*} dataStore
     */
    constructor(visualServer, dataStore) {
        this.visualServer = visualServer
        this.dataStore = dataStore

        this._encoder = null
        this.recorder = new Recorder(visualServer, dataStore)
        this.init()
    }

    init() {
        this.type = null
        this.exportFile = null
        this.recorder.init()
    }

    /**
     * Enable to record media
     */
    enable() {
        this.recorder.enable()
        this.dataStore.forceUpdateLayerListToRenderer()
    }

    /**
     * Disable tp record media
     */
    disable() {
        this.recorder.disable()
    }

    /**
     * Set output media viewport size
     */
    setOutputSize() {
        this.recorder.setOutputSize(
            this.dataStore.workspace.children[0].get('size').width,
            this.dataStore.workspace.children[0].get('size').height
        )
    }

    /**
     * Set media type
     * @param {MediaType} type
     */
    async setMediaType(type) {
        this.type = type
        this.recorder.setMediaType(type)
        // Set specific settings for specific type here.
        // So, we can get correct user settings from outside of the encoder.
        if (this.type === MediaType.MP4) {
            this.updateRecordSetting({
                transparent: false
            })
        }
        await this._initEncoder()
        this.recorder.setEncoder(this._encoder)
    }

    /**
     * Update recorder settings
     * @param {object} newSettings
     */
    updateRecordSetting(newSettings) {
        this.recorder.updateSetting(newSettings)
    }

    /**
     * Init media encoder
     */
    async _initEncoder() {
        switch (this.type) {
            case MediaType.GIF: {
                this._encoder = new GIFEncoder()
                break
            }
            case MediaType.MP4: {
                const options = {
                    width: this.recorder.width,
                    height: this.recorder.height,
                    fps: this.recorder.settings.fps,
                    speed: this.recorder.settings.speed,
                    quality: this.recorder.settings.quality
                }
                this._encoder = new MP4Encoder()
                await this._encoder.init(options)
                break
            }
        }
    }

    /**
     * Record canvas for exporting video
     * @param {Function} onRecordProgress
     */
    async recordCanvas(onRecordProgress) {
        await this.recorder.start(onRecordProgress, this.onRecordComplete.bind(this))
    }

    /**
     * Create file blob
     * @param {Uint8Array} uint8Array
     * @returns {Blob}
     */
    createBlob(uint8Array) {
        let format
        switch (this.type) {
            case MediaType.GIF:
                format = { type: 'image/gif' }
                break
            case MediaType.MP4:
                format = { type: 'video/mp4' }
                break
        }

        return new Blob([uint8Array], format)
    }

    /**
     * Handle finish recording
     * @param {Uint8Array} bytes
     */
    onRecordComplete(bytes) {
        this.exportFile = URL.createObjectURL(this.createBlob(bytes))
    }

    clear() {
        this.init()
    }
}
