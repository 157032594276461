import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { FILE_EDITOR_LAYOUT_AREAS } from '../../../constant'
import { FileFieldsFragment } from '../../../generated/graphql'
import { usePresenceUsers, usePresenceUsersContext } from '../../../providers/PresenceUsersProvider'
import { WorkspaceData } from '../../../providers/WorkspaceContextProvider'
import { useUI, useUIActions } from '../../../providers/dataStore/UIProvider'
import ExportMediaDialog from '../../modals/ExportMediaDialog'
import { Button, FocusLoop } from '../../shared'
import { PresenceAvatarGroup } from '../../shared/Avatar/AvatarGroup'
import Export from '../Export'
import GeometryTool from '../GeometryTool'
import InspectingButton from '../InspectingButton'
import MaskTool from '../MaskTool'
import Overlay from '../Overlay'
// import Tutorial from './Tutorial'
import PrimaryTools from '../PrimaryTools'
import ShareButton from '../ShareButton'
import VersionHistoryButton from '../VerstionHistoryButton'
import Zoom from '../Zoom'
import HamburgerMenu from './HamburgerMenu'

type FileToolbarProps = {
  fileId: FileFieldsFragment['id']
  projectId: FileFieldsFragment['project_id']
  workspaceData: WorkspaceData
}
export const FileToolbar = ({ workspaceData, projectId, fileId }: FileToolbarProps) => {
  usePresenceUsers()
  const { t } = useTranslation('file')
  const { onlineUsers, idleUsers } = usePresenceUsersContext()
  const { isEditingState, isVersioningState, isInspectingState } = useUI()
  const { leaveVersionPreview } = useUIActions()

  const participants = [...onlineUsers, ...idleUsers]

  const rightRef = useRef<HTMLDivElement>(null)
  const actionRef = useRef<HTMLDivElement>(null)

  const actionWidth = actionRef.current?.offsetWidth || 207
  // Remain at least 80px spacing toward the middle Tools + PresenceAvatarGroup container margin
  const pruneWidth = actionWidth + 80 + 16
  const showInspectingButton = isEditingState || isInspectingState

  const renderCenterTool = () => {
    if (isEditingState) return null
    if (isVersioningState)
      return (
        <Button size="l" variant="solid" color="primary" onClick={leaveVersionPreview}>
          {t('back_to_file')}
        </Button>
      )
    if (isInspectingState) {
      return <div className="text-light-overlay-60">{t('inspect_mode')}</div>
    }
    return <div className="text-light-overlay-60">{t('view_only')}</div>
  }

  return (
    <FocusLoop
      className="grid items-center bg-neutral-90 border-b border-solid border-neutral-80"
      style={{ gridArea: FILE_EDITOR_LAYOUT_AREAS.HEADER, gridTemplateColumns: '1fr minmax(100px, auto) 1fr' }}
    >
      <div className="flex items-center" data-test-id="editor-top-tools-panel">
        <HamburgerMenu workspaceData={workspaceData} projectId={projectId} fileId={fileId} />
        <PrimaryTools />
      </div>
      <div className="flex items-center justify-center">
        {!isInspectingState && <GeometryTool />}
        {!isInspectingState && <MaskTool />}
        {renderCenterTool()}
      </div>
      <div className="flex items-center justify-end" ref={rightRef}>
        {/* <Tutorial fileId={fileId} /> */}
        <div className="mr-16">
          <PresenceAvatarGroup
            users={participants}
            parentRef={rightRef}
            maxVisible={10}
            avatarSpacing={8}
            pruneWidth={pruneWidth}
          />
        </div>
        <div className={`flex items-center ${!isVersioningState ? 'gap-0' : 'gap-8'}`} ref={actionRef}>
          <div className="flex items-center gap-8">
            <ShareButton projectId={projectId} fileId={fileId} />
            {!isVersioningState && <Export />}
            <div className="flex items-center gap-4">
              {showInspectingButton && <InspectingButton />}
              {!isVersioningState && (
                <VersionHistoryButton workspaceData={workspaceData} projectId={projectId} fileId={fileId} />
              )}
            </div>
          </div>
          <Zoom />
        </div>
      </div>
      <Overlay />
      <ExportMediaDialog />
    </FocusLoop>
  )
}

export default React.memo(FileToolbar)
