import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AvatarField from '../../components/Settings/AvatarField'
import DescriptionField from '../../components/Settings/DescriptionField'
import Field from '../../components/Settings/Field'
import LocationField from '../../components/Settings/LocationField'
import NameField from '../../components/Settings/NameField'
import DeleteTeamDialog from '../../components/modals/DeleteTeamDialog'
import { Form, Grid, ProgressButton } from '../../components/shared'
import { useGetDeleteTeamPendingRequestByIdQuery } from '../../generated/graphql'
import useTeamActions from '../../hooks/useTeamActions'
import { useSetNotification } from '../../providers/NotificationProvider'
import { track } from '../../services/heapAnalytics'
import { teamNameValidator } from '../../utils/validator'
import { useTeamSettingsContext } from './TeamSettingsContext'

const TeamGeneral = () => {
  const {
    id,
    name,
    setName,
    description,
    setDescription,
    location,
    setLocation,
    avatar,
    updateTeamProfile,
    updateAvatar,
    removeAvatar,
    refetchTeamData
  } = useTeamSettingsContext()
  const { addNotification } = useSetNotification()
  const { createDeleteTeamRequest, cancelDeleteTeamRequest } = useTeamActions()

  const { data: deleteTeamPendingRequestData, refetch: refetchDeleteTeamPendingRequest } =
    useGetDeleteTeamPendingRequestByIdQuery({
      variables: {
        id
      },
      skip: !id
    })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [isCreatingDeleteTeamRequest, setIsCreatingDeleteTeamRequest] = useState(false)
  const [deleteTeamDialogOpen, setDeleteTeamDialogOpen] = useState(false)
  const [deleteTeamRequestId, setDeleteTeamRequestId] = useState('')

  const { t, ready } = useTranslation('setting')

  const disabledSubmit = !name || name.trim().length === 0 || name.trim().length > 255

  const handleSubmit = async () => {
    if (disabledSubmit || isSubmitting) return

    try {
      setIsSubmitting(true)
      await updateTeamProfile({ teamId: id, name: name.trim(), description, location })

      addNotification({ type: 'success', content: t('profile_setting.profile_updated') })
      track('Team Settings Updated', { teamName: name.trim() })
    } catch (error) {
      console.error(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleDeleteTeam = async () => {
    if (isCreatingDeleteTeamRequest) return
    setIsCreatingDeleteTeamRequest(true)
    try {
      const hasPendingDeleteRequest = deleteTeamPendingRequestData?.team_delete_intents?.length !== 0

      if (hasPendingDeleteRequest) {
        await cancelDeleteTeamRequest(id, deleteTeamPendingRequestData?.team_delete_intents?.[0].id)
      }
      const { id: requestId } = await createDeleteTeamRequest(id)
      setDeleteTeamRequestId(requestId)
      setDeleteTeamDialogOpen(true)
    } catch (error) {
      console.error(error)
    } finally {
      setIsCreatingDeleteTeamRequest(false)
    }
  }

  const handleCloseDeleteTeamDialog = () => {
    refetchDeleteTeamPendingRequest()
    setDeleteTeamRequestId('')
    setDeleteTeamDialogOpen(false)
  }
  const showNotification = (type: 'update' | 'remove') => {
    if (type === 'update') addNotification({ type: 'success', content: t('team_setting.team_logo_updated') })
    else addNotification({ type: 'success', content: t('team_setting.team_logo_removed') })
  }

  const handleUpdateAvatar = async (e: File) => {
    await updateAvatar(id, e)
    await showNotification('update')
  }
  const handleRemoveAvatar = async () => {
    await removeAvatar(id)
    await showNotification('remove')
  }

  if (!ready) return null

  return (
    <>
      {/* @ts-ignore TODO: fix after refactor of Form */}
      <Form noValidate onSubmit={handleSubmit}>
        {/* @ts-ignore TODO: fix after refactor of Grid */}
        <Grid gridGap={24} className="mt-32">
          <AvatarField
            label={t('team_setting.team_logo')}
            name={name}
            value={avatar}
            updateAvatar={handleUpdateAvatar}
            removeAvatar={handleRemoveAvatar}
            onChange={refetchTeamData}
          />
          <NameField
            label={t('team_setting.team_name')}
            value={name}
            onChange={setName}
            validator={teamNameValidator}
            size="full"
          />
          <LocationField value={location} onChange={setLocation} size="full" />
          <DescriptionField value={description} onChange={setDescription} size="full" />
          <ProgressButton
            className="w-fit"
            type="submit"
            status={Number(isSubmitting)}
            data-test-id="name-input-submit"
          >
            {t('update')}
          </ProgressButton>
          <div className="border-t border-solid border-neutral-80"></div>
          <Field label={t('team_setting.delete_team')}>
            <p>{t('team_setting.irrecoverable_action')}</p>
            <ProgressButton
              status={Number(isCreatingDeleteTeamRequest)}
              color="danger"
              size="l"
              className="mt-24"
              onClick={handleDeleteTeam}
            >
              {t('team_setting.delete_team')}
            </ProgressButton>
          </Field>
        </Grid>
      </Form>
      <DeleteTeamDialog
        id={id}
        requestId={deleteTeamRequestId}
        name={name}
        open={deleteTeamDialogOpen}
        onClose={handleCloseDeleteTeamDialog}
      />
    </>
  )
}

export default TeamGeneral
