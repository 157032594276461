import React, { useCallback, useEffect, useMemo, useState } from 'react'
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory, useLocation, useParams } from 'react-router'

// @ts-ignore
import { takeSnapshotAsBlob } from '@phase-software/renderer'
import { DocumentData, Mode } from '@phase-software/types'

import DataStoreProviders from '../../components/DataStore/Providers'
import { FileEditorLoading } from '../../components/shared/Skeleton'
import { TEMPORARY_SVG_URL_FIELD } from '../../constants/global'
import dataStore from '../../dataStore'
import { FileFieldsFragment, useGetFileByIdQuery } from '../../generated/graphql'
import useFileActions from '../../hooks/useFileActions'
import useHeapAnalytics from '../../hooks/useHeapAnalytics'
import { useEnablePresencePreference } from '../../hooks/usePresence'
import { RoomProvider } from '../../liveblocks.config'
import { CommentProvider } from '../../providers/CommentProvider'
import { FilePermissionProvider } from '../../providers/FilePermissionProvider'
import { FileProvider } from '../../providers/FileProvider'
import { useSetNotification } from '../../providers/NotificationProvider'
import { PresenceProvider } from '../../providers/PresenceProvider'
import { ProjectPermissionProvider } from '../../providers/ProjectPermissionProvider'
import { useSkeletonLoadingContext } from '../../providers/SkeletonLoadingProvider'
import { useSpinnerLoadingContext } from '../../providers/SpinnerLoadingProvider'
import { useWorkspaceContext } from '../../providers/WorkspaceContextProvider'
import { useUI } from '../../providers/dataStore/UIProvider'
import { track } from '../../services/heapAnalytics'
import { uploadContent } from '../../services/nats'
// @ts-ignore
import { waitUntil } from '../../utils/data'
import { fetchSVGFileFromURL } from '../../utils/file'
import { getWorkspaceDraftsPath } from '../../utils/pathGenerators'
import FileModeRoutes from './FileModeRoutes'

const File = () => {
  const { t } = useTranslation('workspace')
  const history = useHistory()
  const location = useLocation()

  const { fileId } = useParams<{ fileId: FileFieldsFragment['id'] }>()
  const { workspaceData } = useWorkspaceContext()
  const { fileEditorReady, updateFileEditorReady } = useSkeletonLoadingContext()
  const { startSpinnerLoading, stopSpinnerLoading } = useSpinnerLoadingContext()

  const { insertSVGToFile, downloadContent, uploadFileThumbnail, postProcessLottieFile, postProcessSVGFile } =
    useFileActions()

  const { addNotification } = useSetNotification()
  const { isPresenceShowByFile, setIsPresenceShowByFile } = useEnablePresencePreference(fileId)

  const { mode } = useUI()
  const isDesignMode = mode === Mode.DESIGN

  const { space, teamName } = useHeapAnalytics()
  const { data: fileQueryData, loading: fileQueryLoading } = useGetFileByIdQuery({
    variables: {
      id: fileId
    }
  })

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  const file = fileQueryData?.files_by_pk
  const projectId = file?.project_id
  const isArchivedFile = file?.status === 'ARCHIVE'
  const fileName = file?.name ?? ''

  const [previousFileId, setPreviousFileId] = useState(fileId)
  const [fileContent, setFileContent] = useState<object | null>(null)
  const [downloading, setDownloading] = useState<boolean>(false)
  const [hasFileError, setHasFileError] = useState(false)

  useEffect(() => {
    return () => {
      updateFileEditorReady(false)
    }
  }, [updateFileEditorReady])

  const downloadSVGFromFileContent = useCallback(
    async (content: any) => {
      const url = content[TEMPORARY_SVG_URL_FIELD]

      if (!url) return
      try {
        console.log(TEMPORARY_SVG_URL_FIELD, url)
        startSpinnerLoading()
        const svgFile = await fetchSVGFileFromURL(url, fileName)
        await insertSVGToFile(dataStore, svgFile, projectId, fileId, true)
        // clear undo history to avoid undoing the import
        dataStore.get('undo').clear()
      } catch (error) {
        console.error('Failed to download SVG file:', error)
        // NOTE: right now TEMPORARY_SVG_URL_FIELD is only used for Figma import
        // if we have more use cases, we need to change the error message
        addNotification({
          type: 'error',
          content: t('message.import_from_figma_has_failed')
        })
      } finally {
        const savedContent = dataStore.save()
        savedContent[TEMPORARY_SVG_URL_FIELD] = null
        await uploadContent({ projectId, fileId, content: savedContent })
        stopSpinnerLoading()
      }
    },
    [addNotification, fileId, fileName, insertSVGToFile, projectId, startSpinnerLoading, stopSpinnerLoading, t]
  )

  const downloadFileContent = useCallback(
    async (fileId: string, projectId: string, fromImport?: boolean) => {
      try {
        setDownloading(true)
        let content: DocumentData

        const blobUrl = searchParams.get('blobUrl')

        if (fromImport && blobUrl) {
          const response = await fetch(blobUrl)
          content = await response.json()
        } else {
          content = await downloadContent({ projectId, fileId })
        }
        setFileContent(content)
        setDownloading(false)

        if (fromImport) {
          const source = searchParams.get('source')
          const isLottie = source === 'lottie'
          const isSVG = source === 'svg'

          dataStore.once('LOAD', async () => {
            await waitUntil(() => dataStore.drawInfo)
            const screen = dataStore.workspace.watched.children[0]

            await downloadSVGFromFileContent(content)

            // ensuring there is no commit in the undo history,
            // the alternative way is not enable the data-sync before post process
            if (isLottie || isSVG) {
              postProcessLottieFile(dataStore)
            }
            if (isSVG) {
              /** @todo The post process should be in the importer plug-in  */
              postProcessSVGFile(dataStore)
            }

            const savedContent = dataStore.save()
            await uploadContent({ projectId, fileId, content: savedContent })

            // flush the current undo group
            dataStore.get('undo').clear()

            // delete import parameter to avoid re-generate thumbnail
            searchParams.delete('import')
            searchParams.delete('blobUrl')
            history.replace({ ...history.location, search: searchParams.toString() }, {})

            // wait for the screen to render
            setTimeout(async () => {
              const snapshot = await takeSnapshotAsBlob(screen.get('id'), 'png')
              uploadFileThumbnail({ projectId, fileId, blob: snapshot })
            })
          })
        } else {
          dataStore.once('LOAD', async () => {
            await downloadSVGFromFileContent(content)
          })
        }

        track('File Opened', {
          fileId,
          projectId,
          space,
          teamName,
          isShared: false
        })
      } catch (error) {
        console.error('Failed to download content:', error)
        setHasFileError(true)
      }
    },
    [
      setDownloading,
      downloadContent,
      downloadSVGFromFileContent,
      history,
      postProcessLottieFile,
      postProcessSVGFile,
      searchParams,
      space,
      teamName,
      uploadFileThumbnail
    ]
  )

  // when duplicate in the editor, the url param fileId is changed
  // need to get the new file content base on the new fileId
  useEffect(() => {
    if (!downloading && !fileContent && previousFileId !== fileId) {
      // FIXME: (shiny) this is a temporary fix for the issue where the file content is not updated when the fileId changes
      dataStore.switchState('EDITING')
      downloadFileContent(fileId, projectId)
      setPreviousFileId(fileId)
    }
  }, [downloading, fileContent, previousFileId, fileId, downloadFileContent, projectId])

  useEffect(() => {
    if (fileQueryLoading) return

    if (!projectId) {
      setHasFileError(true)
      return
    }

    if (!downloading && !fileContent) {
      const fromImport = searchParams.get('import')
      downloadFileContent(fileId, projectId, Boolean(fromImport))
    }
  }, [downloadFileContent, fileContent, downloading, fileId, fileQueryLoading, projectId, searchParams])

  if (isArchivedFile) {
    return <Redirect to="/404" />
  }

  if (hasFileError) {
    return <Redirect to={getWorkspaceDraftsPath(workspaceData.type, workspaceData.slug)} />
  }

  return (
    <ProjectPermissionProvider id={projectId}>
      <FilePermissionProvider id={fileId} projectId={projectId}>
        <FileProvider id={fileId} projectId={projectId} fileName={fileName}>
          <PresenceProvider
            isPresenceShowByFile={isPresenceShowByFile}
            setIsPresenceShowByFile={setIsPresenceShowByFile}
          >
            {/* eslint-disable-next-line camelcase */}
            <RoomProvider id={fileId} initialPresence={{}} unstable_batchedUpdates={unstable_batchedUpdates}>
              <CommentProvider fileId={fileId}>
                <DataStoreProviders dataStore={dataStore}>
                  {isDesignMode && !fileEditorReady && <FileEditorLoading />}
                  {fileContent && <FileModeRoutes fileId={fileId} projectId={projectId} fileContent={fileContent} />}
                </DataStoreProviders>
              </CommentProvider>
            </RoomProvider>
          </PresenceProvider>
        </FileProvider>
      </FilePermissionProvider>
    </ProjectPermissionProvider>
  )
}

export default File
