import { useCallback } from 'react'

import { keys } from 'lodash'

import { ContentType } from '@phase-software/data-store'
import { NO_COMMIT } from '@phase-software/data-utils'
import { LottieConverter, PhaseConverter } from '@phase-software/lottie-exporter'
import { ElementType, MediaType } from '@phase-software/types'

import { useSetNotification } from '../../providers/NotificationProvider'
import { createProvider } from '../utils'
import { useSetElement } from './ElementProvider'
import { useSetElementSelection } from './ElementSelectionProvider'
import { useSetFill } from './FillProvider'
import { useSetFlattenElementList } from './FlattenElementListProvider'
import { useSetFont } from './FontProvider'
import { useSetInnerShadow } from './InnerShadowProvider'
import { useSetKeyframeSelection } from './KeyframeSelectionProvider'
import { useSetLayer } from './LayerProvider'
import { useSetPaint } from './PaintProvider'
import { useSetShadow } from './ShadowProvider'
import { useSetStroke } from './StrokeProvider'
import { useSetTextAlignment } from './TextAlignmentProvider'
import { useSetTextDecoration } from './TextDecorationProvider'
import { useSetTextDirection } from './TextDirectionProvider'
import { useSetTextSpacing } from './TextSpacingProvider'
import { useSetTransition } from './TransitionProvider'
import { useSetWorkspace } from './WorkspaceProvider'

const [Provider, useSelectState, useSetState] = createProvider('DataStore')

export const useDataStore = useSelectState

export const useSetDataStore = () => {
  const setState = useSetState()
  const setDataStore = useCallback((store) => setState(store), [setState])

  return {
    setDataStore,
    ...useSetElement(),
    ...useSetFill(),
    ...useSetInnerShadow(),
    ...useSetLayer(),
    ...useSetPaint(),
    ...useSetShadow(),
    ...useSetStroke(),
    ...useSetWorkspace(),
    ...useSetElementSelection(),
    ...useSetKeyframeSelection(),
    ...useSetFont(),
    ...useSetTextDecoration(),
    ...useSetTextAlignment(),
    ...useSetTextDirection(),
    ...useSetTextSpacing(),
    ...useSetTransition(),
    ...useSetFlattenElementList()
  }
}

export const useDataStoreActions = () => {
  const dataStore = useDataStore()
  const { addNotification } = useSetNotification()

  const getElement = useCallback(
    (id) => {
      return dataStore.getElement(id)
    },
    [dataStore]
  )

  const getParentOf = useCallback(
    (element) => {
      return dataStore.getParentOf(element)
    },
    [dataStore]
  )

  const reorderElements = useCallback(
    (elementIds, groupId, targetId, action, commitUndo = true) => {
      let target = getElement(targetId)
      const targetGroup = getElement(groupId)
      const elements = elementIds.map(getElement).reverse()

      if (action === 'append') {
        targetGroup.set('expanded', true)
        if (targetGroup.get('elementType') === ElementType.GEOMETRY_GROUP) {
          elements.forEach((el) => {
            const booleanOperation = el.get('booleanOperation')
            if (!booleanOperation || booleanOperation === 'NONE') {
              el.set('booleanOperation', 'UNION')
            }
          })
          dataStore.addChildrenAt(targetGroup, elements, 0, NO_COMMIT)
        } else {
          dataStore.addChildren(targetGroup, elements, NO_COMMIT)
        }
      } else {
        let index = targetGroup.children.findIndex((child) => child === target)
        if (targetGroup.get('elementType') === ElementType.GEOMETRY_GROUP) {
          elements.forEach((el) => {
            const booleanOperation = el.get('booleanOperation')
            if (!booleanOperation || booleanOperation === 'NONE') {
              el.set('booleanOperation', 'UNION')
            }
          })
          if (action === 'insert-before') {
            index++
          }
        } else {
          if (action === 'insert-before') {
            index++
          } else {
            // insert after
            while (index === -1) {
              target = getParentOf(target)
              index = targetGroup.children.findIndex((child) => child === target)
            }
          }
        }
        dataStore.addChildrenAt(targetGroup, elements, index, NO_COMMIT)
      }
      if (commitUndo) {
        dataStore.commitUndo()
      }
    },
    [dataStore, getElement, getParentOf]
  )

  const copyBySelectionPriority = useCallback(() => {
    dataStore.eam.copy()
  }, [dataStore])

  const copyKeyframes = useCallback(() => {
    dataStore.eam.copy(ContentType.KEYFRAME)
  }, [dataStore])

  const cutKeyframes = useCallback(() => {
    dataStore.eam.cut(ContentType.KEYFRAME)
  }, [dataStore])

  const cutElementList = useCallback(() => {
    dataStore.eam.cut(ContentType.ELEMENT)
  }, [dataStore])

  const copyElementList = useCallback(() => {
    dataStore.eam.copy(ContentType.ELEMENT)
  }, [dataStore])

  const duplicateElementList = useCallback(() => {
    dataStore.eam.duplicateElement()
  }, [dataStore])

  const pasteToElementList = useCallback(
    async (mousePos) => {
      const res = await dataStore.clipboard.paste(null, mousePos)
      if (res instanceof Error) {
        addNotification({
          type: 'error',
          content:
            "Sorry, pasting media from outside Phase editor is not supported at this time, but we're working on it!"
        })
      }
    },
    [dataStore, addNotification]
  )

  const deleteElementList = useCallback(() => {
    dataStore.eam.delete()
  }, [dataStore])

  const moveElementListToTop = useCallback(
    (elementIds) => {
      const parentGroup = dataStore.groupElementsByParent(elementIds)
      keys(parentGroup).forEach((key) => {
        const containerId = key
        const reorderIds = parentGroup[key].reverse()
        const { filteredIds, targetId } = dataStore.filterReorderElementsAndFindTargetId(containerId, reorderIds, 'top')
        if (!filteredIds.length) return
        reorderElements(filteredIds, containerId, targetId, 'insert-before', false)
      })
      dataStore.commitUndo()
    },
    [dataStore, reorderElements]
  )

  const moveElementListToBottom = useCallback(
    (elementIds) => {
      const parentGroup = dataStore.groupElementsByParent(elementIds)
      keys(parentGroup).forEach((key) => {
        const containerId = key
        const reorderIds = parentGroup[key].reverse()
        const { filteredIds, targetId } = dataStore.filterReorderElementsAndFindTargetId(
          containerId,
          reorderIds,
          'bottom'
        )
        if (!filteredIds.length) return
        reorderElements(filteredIds, containerId, targetId, 'insert-after', false)
      })
      dataStore.commitUndo()
    },
    [dataStore, reorderElements]
  )

  const moveElementListUp = useCallback(
    (elementIds) => {
      const parentGroup = dataStore.groupElementsByParent(elementIds)
      keys(parentGroup).forEach((key) => {
        const containerId = key
        const reorderIds = parentGroup[key].reverse()
        const filteredIds = dataStore.filterReorderElements(containerId, reorderIds, 'forward')
        filteredIds.forEach((elementId) => {
          const { prevId } = dataStore.getElementBrotherNodeIds(elementId)
          if (!prevId) return
          reorderElements([elementId], containerId, prevId, 'insert-before', false)
        })
      })
      dataStore.commitUndo()
    },
    [dataStore, reorderElements]
  )

  const moveElementListDown = useCallback(
    (elementIds) => {
      const parentGroup = dataStore.groupElementsByParent(elementIds)
      keys(parentGroup).forEach((key) => {
        const containerId = key
        const reorderIds = parentGroup[key]
        const filteredIds = dataStore.filterReorderElements(containerId, reorderIds, 'backward')
        filteredIds.forEach((elementId) => {
          const { nextId } = dataStore.getElementBrotherNodeIds(elementId)
          if (!nextId) return
          reorderElements([elementId], containerId, nextId, 'insert-after', false)
        })
      })
      dataStore.commitUndo()
    },
    [dataStore, reorderElements]
  )

  const groupElementList = useCallback(
    (containerType) => {
      dataStore.groupElements(containerType)
    },
    [dataStore]
  )

  const booleanGroupElementList = useCallback(
    (booleanType) => {
      dataStore.booleanGroupElements(booleanType)
    },
    [dataStore]
  )

  const maskGroupElementList = useCallback(() => {
    dataStore.maskGroupElements()
  }, [dataStore])

  const ungroupElementList = useCallback(() => {
    dataStore.ungroupCurrentSelection()
  }, [dataStore])

  const changeElementListVisible = useCallback(() => {
    dataStore.toggleElementsVisible()
  }, [dataStore])

  const changeElementListLock = useCallback(() => {
    dataStore.toggleElementsLocked()
  }, [dataStore])

  const flipElementListHorizontal = useCallback((elementIds) => {
    console.log('flipElementListHorizontal', elementIds)
  }, [])

  const flipElementListVertical = useCallback((elementIds) => {
    console.log('flipElementListVertical', elementIds)
  }, [])

  const getElements = useCallback(
    (elementIds) => {
      return elementIds.map((id) => dataStore.getById(id))
    },
    [dataStore]
  )

  const isAncestorSelected = useCallback(
    (elementId) => {
      const element = getElement(elementId)
      let selected = false
      let target = element
      while (!selected && target) {
        selected = dataStore.selection.isSelected(target)
        target = dataStore.getParentOf(target)
      }
      return selected
    },
    [dataStore, getElement]
  )

  const commitUndo = useCallback(() => {
    dataStore.get('undo').commit()
  }, [dataStore])

  const debounceCommitUndo = useCallback(() => {
    dataStore.debounceCommitUndo()
  }, [dataStore])

  const clearUndo = useCallback(() => {
    dataStore.clearUndo()
  }, [dataStore])

  const switchState = useCallback(
    (state) => {
      dataStore.switchState(state)
    },
    [dataStore]
  )

  const isDescendantOf = useCallback(
    (id, pId) => {
      const element = getElement(id)
      const ancestor = getElement(pId)
      return dataStore.isDescendantOf(element, ancestor)
    },
    [dataStore, getElement]
  )

  const selectWorkspace = useCallback(
    (workspaceId) => {
      dataStore.selectWorkspace(dataStore.getById(workspaceId))
    },
    [dataStore]
  )

  const isParentChild = useCallback(
    (parentId, childId) => {
      const parent = getElement(parentId)
      return parent.children.some((child) => child.get('id') === childId)
    },
    [getElement]
  )

  const selectElement = useCallback(
    (elementId) => {
      dataStore.selection.selectElements([dataStore.getById(elementId)])
    },
    [dataStore]
  )

  const selectElements = useCallback(
    (elementIdList) => {
      const selection = elementIdList.map((id) => dataStore.getElement(id))
      dataStore.selection.selectElements(selection)
    },
    [dataStore]
  )

  const selectAll = useCallback(() => {
    dataStore.eam.selectAll()
  }, [dataStore])

  const toggleSelectElement = useCallback(
    (elementId) => {
      const element = dataStore.getById(elementId)
      dataStore.selection.toggleElements([element])
    },
    [dataStore]
  )

  const deleteSelectedElements = useCallback(() => {
    dataStore.deleteSelectedElements()
  }, [dataStore])

  const removeElementSelection = useCallback(
    (elements) => {
      dataStore.selection.removeElements(elements.map((elementId) => dataStore.getById(elementId)))
    },
    [dataStore]
  )

  const clearElementSelection = useCallback(() => {
    dataStore.selection.clearElements()
  }, [dataStore])

  const clearSelection = useCallback(() => {
    dataStore.selection.clear()
  }, [dataStore])

  const _setGeometryOperation = useCallback((el, booleanOperation) => {
    if (booleanOperation === 'MASK') {
      el.set('mask', true)
    } else {
      el.sets({
        mask: false,
        booleanOperation
      })
    }
  }, [])

  const createGeometryGroup = useCallback(
    (targets, booleanOperation) => {
      const elements = targets.map(getElement)
      const firstElement = elements[0]
      const parent = firstElement.get('parent')
      const newIndex = parent.children.indexOf(firstElement)

      const geometryGroup = dataStore.createElement('GEOMETRY_GROUP')
      geometryGroup.set('name', 'Compound path')

      elements.forEach((el) => _setGeometryOperation(el, booleanOperation))

      dataStore.addChildrenAt(parent, [geometryGroup], newIndex, NO_COMMIT)
      dataStore.addChildren(geometryGroup, elements, NO_COMMIT)
      dataStore.updateElementOrder()

      // side effect
      selectElement(geometryGroup.data.id)
      dataStore.commitUndo()
    },
    [dataStore, getElement, selectElement, _setGeometryOperation]
  )

  const setGeometryOperation = useCallback(
    (targets, booleanOperation) => {
      const elements = targets.map(getElement)
      elements
        .filter((el) => el.get('parent').get('elementType') === 'GEOMETRY_GROUP')
        .forEach((el) => _setGeometryOperation(el, booleanOperation))
    },
    [getElement, _setGeometryOperation]
  )

  const setPlayheadTime = useCallback(
    (time) => {
      dataStore.eam.stopAnimation()
      dataStore.transition.setPlayheadTime(time)
    },
    [dataStore]
  )

  const selectKeyframeSelection = useCallback(
    (kfs, undoable = true, ref = undefined) => {
      const options = { commit: true, undoable }
      return dataStore.selection.selectKFs(kfs, options, ref)
    },
    [dataStore]
  )

  const addKeyframeSelection = useCallback(
    (kfs) => {
      return dataStore.selection.addKFs(kfs)
    },
    [dataStore]
  )

  const toggleKeyframeSelection = useCallback(
    (kfs) => {
      return dataStore.selection.toggleKFs(kfs)
    },
    [dataStore]
  )

  const deleteKeyframeSelection = useCallback(
    (kfs) => {
      dataStore.selection.removeKFs(kfs)
    },
    [dataStore]
  )

  const changeEAMInputState = useCallback(
    (focus) => {
      dataStore && dataStore.eam.changeInputFocus(focus)
    },
    [dataStore]
  )

  const createLottieJSON = useCallback(
    async ({ fps, speed, start, end }) => {
      const phaseConverter = new PhaseConverter({ fps, speed, start: start * 1000, end: end * 1000 })
      const irContent = await phaseConverter.toIR(dataStore)
      console.log({ irContent })
      const lottieConverter = new LottieConverter()
      const lottie = lottieConverter.fromIR(irContent)
      console.log({
        lottie: JSON.parse(JSON.stringify(lottie))
      })
      return JSON.parse(JSON.stringify(lottie)) // Strips non-enumerable properties
    },
    [dataStore]
  )

  const downloadLottieFile = useCallback(
    async ({ fileName, fps, speed, start, end }) => {
      const lottieJson = await createLottieJSON({ fps, speed, start, end })
      const lottieFileContent = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(lottieJson))}`
      dataStore.eam.exportFinish(lottieFileContent, {
        fileName,
        type: MediaType.LOTTIE
      })
    },
    [dataStore, createLottieJSON]
  )

  const exportMedia = useCallback(
    (data) => {
      dataStore.eam.exportMedia(data)
    },
    [dataStore]
  )

  const cancelExportMedia = useCallback(() => {
    dataStore.eam.cancelExportMedia()
  }, [dataStore])

  const selectGradientStop = useCallback(
    (idx) => {
      dataStore.eam.setActiveGradientStop(idx)
    },
    [dataStore]
  )

  const getScreenList = useCallback(() => {
    return dataStore.workspace.watched?.children.map((o) => o.get('id')) || []
  }, [dataStore])

  const getFirstScreenElementSize = useCallback(() => {
    const screenElement = dataStore.workspace.watched?.children[0]
    return screenElement.gets('width', 'height')
  }, [dataStore])

  const undo = useCallback(() => {
    // When calling undo/redo in useUndoRedo, the dataStore may be undefined
    return dataStore?.eam.undo()
  }, [dataStore])

  const redo = useCallback(() => {
    // When calling undo/redo in useUndoRedo, the dataStore may be undefined
    return dataStore?.eam.redo()
  }, [dataStore])

  const getComputedLayerByElementId = useCallback(
    (elementId, type, index) => {
      const $element = getElement(elementId)
      if (!$element) {
        return null
      }

      const computedFill = [...$element.computedStyle[type]][index]

      if (!computedFill) {
        return null
      }
      return computedFill.data
    },
    [getElement]
  )

  const getComputedLayerIndex = useCallback(
    (elementId, type, id) => {
      const $element = getElement(elementId)
      if (!$element) {
        return null
      }

      const fillsArr = [...$element.computedStyle[type]]
      if (fillsArr.length !== 0) {
        let layerIndex
        fillsArr.forEach((element, index) => {
          // If a fill is added during an animation, the layerId will be undefined.
          if (element.data.layerId === id || element.data.trackId === id) {
            layerIndex = index
          }
        })
        return layerIndex
      }
      return null
    },
    [getElement]
  )

  const getComputedStyleByElementId = useCallback(
    (elementId, type) => {
      const $element = getElement(elementId)
      if (!$element) {
        return null
      }

      return [...$element.computedStyle[type]]
    },
    [getElement]
  )

  const getKeyframeSelection = useCallback(() => {
    return dataStore.selection.get('kfs')
  }, [dataStore])

  const setFeature = useCallback(
    (name, value) => {
      return dataStore.setFeature(name, value)
    },
    [dataStore]
  )

  return {
    cutElementList,
    copyElementList,
    pasteToElementList,
    duplicateElementList,
    deleteElementList,
    moveElementListToTop,
    moveElementListToBottom,
    moveElementListUp,
    moveElementListDown,
    groupElementList,
    booleanGroupElementList,
    maskGroupElementList,
    ungroupElementList,
    changeElementListVisible,
    changeElementListLock,
    flipElementListHorizontal,
    flipElementListVertical,
    getElements,
    commitUndo,
    debounceCommitUndo,
    clearUndo,
    switchState,
    createGeometryGroup,
    selectWorkspace,
    reorderElements,
    isParentChild,
    deleteSelectedElements,
    selectElement,
    selectElements,
    selectAll,
    toggleSelectElement,
    removeElementSelection,
    clearElementSelection,
    clearSelection,
    setGeometryOperation,
    setPlayheadTime,
    selectKeyframeSelection,
    addKeyframeSelection,
    toggleKeyframeSelection,
    deleteKeyframeSelection,
    changeEAMInputState,
    getElement,
    getParentOf,
    isDescendantOf,
    isAncestorSelected,
    downloadLottieFile,
    exportMedia,
    cancelExportMedia,
    selectGradientStop,
    getScreenList,
    getFirstScreenElementSize,
    undo,
    redo,
    getComputedStyleByElementId,
    getComputedLayerIndex,
    getComputedLayerByElementId,
    getKeyframeSelection,
    createLottieJSON,
    copyKeyframes,
    cutKeyframes,
    copyBySelectionPriority,
    setFeature
  }
}

export default Provider
