import {
    ElementType,
    EntityType,
    PaintType,
    EffectType,
    Mode,
    BlendMode,
    TrimPathMode,
    ImageMode,
    ContainerElementType,
} from '@phase-software/types'
import { checkerboardBase64Url } from '@phase-software/data-utils'
import { Transform2D, Color, Vector2 } from './math'
import { setupStrokeGeometry } from './node-setup'
import { UpdateType } from './visual_server/RenderItem'

/** @typedef {import('@phase-software/data-store/src/Element').Element} Element */
/** @typedef {import('@phase-software/data-store/src/layer/ComputedLayer').ComputedLayer} ComputedLayer */
/** @typedef {import('@phase-software/data-store/src/effect/ComputedTrimPath').ComputedTrimPath} ComputedTrimPath */
/** @typedef {import('@phase-software/data-utils/src/Changes').PropChange} PropChange */
/** @typedef {import('./math').Vector2} Vector2 */
/** @typedef {import('./math/Vector2').Vector2Like } Vector2Like */

/** @typedef {import('./visual_server/RenderItem').RenderItem} RenderItem */
/** @typedef {import('./tile_renderer/TileRenderer').ImageOptions} ImageOptions */
/** @typedef {'fills'|'strokes'|'innerShadows'|'shadows'} LayerListType */

/** @type {LayerListType[]} */
const LAYER_LISTS = ['fills', 'strokes', 'shadows', 'innerShadows']
const changedLists = new Map([
    [LAYER_LISTS[0], {}],
    [LAYER_LISTS[1], {}],
    [LAYER_LISTS[2], {}],
    [LAYER_LISTS[3], {}]
])

/** @type {Record<string, ElementController>} */
let controllers = Object.create(null)

const tmp_color = new Color()

const BlendModeTable = {
    [BlendMode.PASS_THROUGH]: 'passthrough',
    [BlendMode.NORMAL]: 'normal',
    [BlendMode.DARKEN]: 'darken',
    [BlendMode.MULTIPLY]: 'multiply',
    [BlendMode.COLOR_BURN]: 'color_burn',
    [BlendMode.LIGHTEN]: 'lighten',
    [BlendMode.SCREEN]: 'screen',
    [BlendMode.COLOR_DODGE]: 'color_dodge',
    [BlendMode.OVERLAY]: 'overlay',
    [BlendMode.SOFT_LIGHT]: 'soft_light',
    [BlendMode.HARD_LIGHT]: 'hard_light',
    [BlendMode.DIFFERENCE]: 'difference',
    [BlendMode.EXCLUSION]: 'exclusion',
    [BlendMode.HUE]: 'hue',
    [BlendMode.SATURATION]: 'saturation',
    [BlendMode.COLOR]: 'color',
    [BlendMode.LUMINOSITY]: 'luminosity',
}

const CHANGES_EVENT = 'CHANGES'


export function clearAllControllers() {
    for (const id in controllers) {
        controllers[id].clear()
    }
    controllers = Object.create(null)
}

/**
 * @param {Element} element
 * @param {RenderItem} node
 */
export function watchElementChanges(element, node) {
    if (controllers[node.id]) {
        console.warn(`already watching node ${node.id}`)
        return
    }
    const ctrl = new ElementController()
    controllers[node.id] = ctrl
    ctrl.element = element
    ctrl.node = node

    ctrl.connect()
}

/**
 * Stop watching element changes
 * @param {string} elementId
 * @returns {bool}
 */
export function stopWatchElementChanges(elementId) {
    const ctrl = controllers[elementId]
    if (!ctrl) {
        // console.warn(`No watcher for node ${elementId}`)
        return false
    }
    delete controllers[elementId]

    return ctrl.disconnect()
}

/**
 * Resume watching element changes
 * @param {string} elementId
 * @returns {bool}
 */
export function resumeWatchElementChanges(elementId) {
    const ctrl = controllers[elementId]
    if (!ctrl) {
        console.warn(`No watcher for node ${elementId}`)
        return false
    }

    return ctrl.connect()
}

/**
 * @param {Element} element
 * @param {RenderItem} node
 * @param {boolean} clipContent
 */
function updateElementClipping(node, clipContent) {
    node.clipping = clipContent
    node.update(UpdateType.STYLE | UpdateType.TRANSFORM)
}

/**
 * @param {RenderItem} node
 * @param {boolean} value
 */
function updateElementAutoOrient(node, value) {
    node.autoOrient = value
    node.update(UpdateType.STYLE | UpdateType.TRANSFORM)
}

/**
 * @param {RenderItem} node
 * @param {boolean} value
 */
function updateElementOrientRotation(node, value) {
    node.orientRotation = value
    node.update(UpdateType.STYLE | UpdateType.TRANSFORM)
}

/**
 * @param {RenderItem} node
 * @param {boolean} visible
 */
function updateElementVisible(node, visible) {
    node.setVisible(visible)
}
/**
 * @param {RenderItem} node
 * @param {number} opacity
 */
function updateElementOpacity(node, opacity) {
    node.setOpacity(opacity)
}
/**
 * @param {RenderItem} node
 * @param {number} locked
 */
function updateElementLocked(node, locked) {
    node.setLocked(locked)
}

/**
 * @param {RenderItem} node
 * @param {BlendMode} blendMode
 */
function updateElementBlendMode(node, blendMode) {
    node.setBlendMode(BlendModeTable[blendMode])
}


/**
 * @param {RenderItem} node
 * @param {number} geometryIndex
 * @param {number} index
 * @param {ComputedLayer} layer
 */
export function updateElementPaintLayer(node, geometryIndex, index, layer) {
    const theVisualStorage = node.visualServer.storage

    switch (layer.get('paintType')) {
        case PaintType.IMAGE: {
            const imageId = layer.get('imageId') || 'default'
            let res = theVisualStorage.getImageResource(imageId)
            if (!res) {
                res = theVisualStorage.createImageResource(imageId)
                const image = node.visualServer.dataStore.images.getImage(imageId)
                const src = image && image.src ? image.src : checkerboardBase64Url

                if (imageId !== 'default' && (!image || !image.src)) {
                    console.warn(`Cannot find the image src. imageId: ${imageId}`)
                }

                theVisualStorage.loadImage(imageId, src)
            }
            // store relative node id into resource, so we can update it after image loaded
            res.node_ids.add(node.id)

            /** @type {ImageOptions} */
            const imageOptions = {
                mode: layer.get('imageMode') || ImageMode.FILL
            }

            node.image(geometryIndex, index, imageId, imageOptions, layer.get('opacity'))
            break
        }
        case PaintType.GRADIENT_LINEAR:
        case PaintType.GRADIENT_RADIAL:
        case PaintType.GRADIENT_ANGULAR:
        case PaintType.GRADIENT_DIAMOND: {
            const type = layer.get('paintType')
            if (type) {
                // color stops
                const gradientStops = layer.get('gradientStops')
                // transform
                const gradientTransformData = layer.get('gradientTransform')
                const gradientXform = new Transform2D()
                gradientXform.fromArray(gradientTransformData)
                node.gradient(geometryIndex, index, type, gradientStops, gradientXform, layer.get('opacity'))
            }
            break
        }
        case PaintType.SOLID:
        default: {
            tmp_color.set_with_array(layer.get('color'))
            node.solid(geometryIndex, index, tmp_color, layer.get('opacity'))
        } break
    }

    node.setLayerVisible(geometryIndex, index, layer.get('visible'))

    node.update(UpdateType.STYLE)
}

/**
 * @param {Element} element
 * @param {RenderItem} node
 * @param {number} itemIndex
 * @param {ComputedLayer} layer
 */
export function updateElementInnerShadowLayer(element, node, itemIndex, layer) {
    tmp_color.set_with_array(layer.get('color'))
    node.setInnerShadow(itemIndex, layer.get('offsetX'), layer.get('offsetY'), layer.get('blur'), tmp_color)

    node.setInnerShadowVisible(itemIndex, layer.get('visible'))

    node.update(UpdateType.STYLE)
}
/**
 * @param {Element} element
 * @param {RenderItem} node
 */
export function updateElementBlur(element, node) {
    const style = element.get('computedStyle')
    if (style.get('blurGaussian') && style.get('blurGaussianVisible')) {
        node.setBlur(style.get('blurGaussianAmount'))
    } else {
        node.setBlur(0)
    }

    node.update(UpdateType.STYLE | UpdateType.TRANSFORM)
}

/**
 * @param {Element} element
 * @param {RenderItem} node
 * @param {number} itemIndex
 * @param {ComputedLayer} layer
 */
export function updateElementDropShadowLayer(element, node, itemIndex, layer) {
    tmp_color.set_with_array(layer.get('color'))
    tmp_color.a *= layer.get('opacity')
    node.setDropShadow(itemIndex, layer.get('offsetX'), layer.get('offsetY'), layer.get('blur'), tmp_color)

    node.setDropShadowVisible(itemIndex, layer.get('visible'))

    node.update(UpdateType.STYLE | UpdateType.TRANSFORM)
}

class LayerController {
    constructor() {
        /** @type {'paint'|'inner_shadow'|'drop_shadow'} */
        this.type = null

        this.geometryIndex = -1
        this.itemIndex = -1

        /** @type {Element} */
        this.element = null

        /** @type {RenderItem} */
        this.node = null

        /** @type {ComputedLayer} */
        this.layer = null
    }

    clear() {
        this.type = null

        this.geometryIndex = -1
        this.itemIndex = -1

        this.element = null

        this.node = null

        this.layer = null

        return true
    }

    onChanged() {
        switch (this.type) {
            case 'paint': {
                updateElementPaintLayer(this.node, this.geometryIndex, this.itemIndex, this.layer)
            } break
            case 'inner_shadow': {
                updateElementInnerShadowLayer(this.element, this.node, this.itemIndex, this.layer)
            } break
            case 'drop_shadow': {
                updateElementDropShadowLayer(this.element, this.node, this.itemIndex, this.layer)
            } break
        }
    }
}

class ElementController {
    constructor() {
        /** @type {Element} */
        this.element = null

        /** @type {RenderItem} */
        this.node = null

        /** @type {LayerController[]} */
        this.fills = []
        /** @type {LayerController[]} */
        this.strokes = []
        /** @type {LayerController[]} */
        this.innerShadows = []
        /** @type {LayerController[]} */
        this.shadows = []
        /** @type {ComputedTrimPath} */
        this.trim = null

        this.isWatching = false
    }

    clear() {
        this.disconnect()

        this.node = null
        this.element = null

        return true
    }

    connect() {
        if (this.isWatching) {
            return false
        }
        this.isWatching = true

        const { element, node } = this

        this.connectModeWatcher()

        // - [general]

        element.on('name', this.onNameChanged, this)
        element.on('visible', this.onVisibleChanged, this)
        element.on('opacity', this.onOpacityChanged, this)
        element.on('locked', this.onLockedChanged, this)
        element.on('blendMode', this.onBlendModeChanged, this)
        element.on('autoOrient', this.onAutoOrientChanged, this)
        element.on('orientRotation', this.onOrientRotationChanged, this)
        element.on('overflowX', this.onOverflowChanged, this)
        element.on('overflowY', this.onOverflowChanged, this)
        element.on('booleanType', this.onBooleanTypeChanged, this)
        element.on('maskType', this.onMaskTypeChanged, this)
        element.on('containerType', this.onContainerTypeChange, this)

        updateElementAutoOrient(node, element.get('autoOrient'))
        updateElementOrientRotation(node, element.get('orientRotation'))
        updateElementClipping(node, element.get('overflowX'))

        updateElementVisible(node, element.get('visible'))
        updateElementOpacity(node, element.get('opacity'))
        updateElementLocked(node, element.get('locked'))

        updateElementBlendMode(node, element.get('blendMode'))

        // - [geometry & layers]

        const style = element.get('computedStyle')
        style.on('LAYER_LIST_CHANGES', this.onLayersChanged, this)
        this.onLayersChanged(changedLists, null, true)

        if (element.get('type') === EntityType.WORKSPACE || element.get('elementType') === ElementType.GROUP) {
            node.bounds.setSize(null)
        } else {
            node.bounds.setSize(style.get('size'))
            style.on('size', this.onBoundsResizeChanged, this)
        }

        // - [transform]

        node.transform.setTranslateX(style.get('translateX'))
        node.transform.setTranslateY(style.get('translateY'))
        if (!element.isComputedGroup) {
            node.transform.setReferencePoint(style.get('referencePoint'))
        }
        node.transform.setContentAnchor(style.get('contentAnchor'))
        node.transform.setScale(style.get('scale'))
        node.setScaleFlag(style.get('scale'))
        node.transform.setSkew(style.get('skew'))
        node.transform.setRotation(style.get('rotation'))
        node.transform.setSize(style.get('size'))
        node.setSizeFlag(style.get('size'))

        style.on('translateX', this.onTranslateXChanged, this)
        style.on('translateY', this.onTranslateYChanged, this)
        if (!element.isComputedGroup) {
            style.on('referencePoint', this.onReferencePointChanged, this)
        }
        style.on('contentAnchor', this.onContentAnchorChanged, this)
        style.on('scale', this.onScaleChanged, this)
        style.on('skew', this.onSkewChanged, this)
        style.on('rotation', this.onRotationChanged, this)
        style.on('size', this.onSizeChanged, this)

        // - [effects]

        style.on('EFFECT_LIST_CHANGES', this.onEffectsChanged, this)
        this.onEffectsChanged()

        // If element was created in Action mode - initialize it's base transform
        if (element.dataStore.isActionMode) {
            this._initializeBaseTransform()
        }

        node.update(UpdateType.TRANSFORM)

        return true
    }

    connectModeWatcher() {
        const { dataStore } = this.element
        dataStore.on('mode', this.onModeChanged, this)

        this.onModeChanged(dataStore.get('mode'))
    }

    disconnectModeWatcher() {
        this.element.dataStore.off('mode', this.onModeChanged, this)
    }

    disconnect() {
        if (!this.isWatching) {
            return false
        }

        const { element } = this

        this.disconnectModeWatcher()

        // - [general]

        element.off('name', this.onNameChanged, this)
        element.off('visible', this.onVisibleChanged, this)
        element.off('opacity', this.onOpacityChanged, this)
        element.off('locked', this.onLockedChanged, this)
        element.off('blendMode', this.onBlendModeChanged, this)
        element.off('autoOrient', this.onAutoOrientChanged, this)
        element.off('overflowX', this.onOverflowChanged, this)
        element.off('overflowY', this.onOverflowChanged, this)
        element.off('booleanType', this.onBooleanTypeChanged, this)
        element.off('maskType', this.onMaskTypeChanged, this)

        // - [geometry & layers]

        const style = element.get('computedStyle')
        style.off('LAYER_LIST_CHANGES', this.onLayersChanged, this)
        style.off('size', this.onBoundsResizeChanged, this)

        this.disconnectFillLayers()
        this.disconnectBorderLayers()
        this.disconnectShadowLayers()
        this.disconnectInnerShadowLayers()

        // - [transform]

        style.off('translateX', this.onTranslateXChanged, this)
        style.off('translateY', this.onTranslateYChanged, this)
        style.off('referencePoint', this.onReferencePointChanged, this)
        style.off('contentAnchor', this.onContentAnchorChanged, this)
        style.off('scale', this.onScaleChanged, this)
        style.off('skew', this.onSkewChanged, this)
        style.off('rotation', this.onRotationChanged, this)
        style.off('size', this.onSizeChanged, this)

        // - [effect]
        style.off('EFFECT_LIST_CHANGES', this.onEffectsChanged, this)
        if (this.trim) {
            this.trim.off("CHANGES", this.onTrimChanged, this)
        }

        this.isWatching = false
        return true
    }

    disconnectFillLayers(removeItems = true) {
        for (const ctrl of this.fills) {
            ctrl.layer.off('CHANGES', ctrl.onChanged, ctrl)
        }
        if (removeItems) {
            this.node.removeFills()
        }

        for (const c of this.fills) {
            c.clear()
        }
        this.fills.length = 0
    }

    disconnectBorderLayers(removeItems = true) {
        for (const ctrl of this.strokes) {
            ctrl.layer.off('CHANGES', ctrl.onChanged, ctrl)
        }
        if (removeItems) {
            this.node.removeStrokes()
        }

        for (const c of this.strokes) {
            c.clear()
        }
        this.strokes.length = 0
    }

    disconnectInnerShadowLayers(removeItems = true) {
        for (const ctrl of this.innerShadows) {
            ctrl.layer.off('CHANGES', ctrl.onChanged, ctrl)
        }
        if (removeItems) {
            this.node.removeInnerShadows()
        }

        for (const c of this.innerShadows) {
            c.clear()
        }
        this.innerShadows.length = 0
    }

    disconnectShadowLayers(removeItems = true) {
        for (const ctrl of this.shadows) {
            ctrl.layer.off('CHANGES', ctrl.onChanged, ctrl)
        }
        if (removeItems) {
            this.node.removeDropShadows()
        }

        for (const c of this.shadows) {
            c.clear()
        }
        this.shadows.length = 0
    }

    onModeChanged(mode) {
        if (mode === Mode.ACTION) {
            this._initializeBaseTransform()
        } else if (mode === Mode.DESIGN) {
            this._resetBaseTransforms()
        }
    }

    _initializeBaseTransform() {
        const t = this.node.baseTransform
        t.disconnectRef()

        let prop = this.element.getBaseProp('translate')
        t.setTranslate(prop)
        prop.on(CHANGES_EVENT, this.onBaseTransitChanged, this)

        prop = this.element.getBaseProp('dimensions')
        t.setSize(prop)
        prop.on(CHANGES_EVENT, this.onBaseSizeChanged, this)

        prop = this.element.getBaseProp('rotation')
        t.setRotation(prop)
        prop.on(CHANGES_EVENT, this.onBaseRotationChanged, this)

        prop = this.element.getBaseProp('scale')
        t.setScale(prop)
        prop.on(CHANGES_EVENT, this.onBaseScaleChanged, this)

        prop = this.element.getBaseProp('skew')
        t.setSkew(prop)
        prop.on(CHANGES_EVENT, this.onBaseSkewChanged, this)

        if (!this.element.isComputedGroup) {
            prop = this.element.getBaseProp('referencePoint')
            t.setReferencePoint(prop)
            prop.on(CHANGES_EVENT, this.onBaseReferencePointChanged, this)
        }

        prop = this.element.getBaseProp('contentAnchor')
        t.setContentAnchor(prop)
        prop.on(CHANGES_EVENT, this.onBaseContentAnchorChanged, this)

        this.node.update(UpdateType.TRANSFORM)
    }

    _resetBaseTransforms() {
        this.node.baseTransform.connectRef(this.node.transform)

        let prop = this.element.getBaseProp('translate')
        prop.off(CHANGES_EVENT, this.onBaseTransitChanged, this)

        prop = this.element.getBaseProp('dimensions')
        prop.off(CHANGES_EVENT, this.onBaseSizeChanged, this)

        prop = this.element.getBaseProp('rotation')
        prop.off(CHANGES_EVENT, this.onBaseRotationChanged, this)

        prop = this.element.getBaseProp('scale')
        prop.off(CHANGES_EVENT, this.onBaseScaleChanged, this)

        prop = this.element.getBaseProp('skew')
        prop.off(CHANGES_EVENT, this.onBaseSkewChanged, this)

        prop = this.element.getBaseProp('referencePoint')
        prop.off(CHANGES_EVENT, this.onBaseReferencePointChanged, this)

        prop = this.element.getBaseProp('contentAnchor')
        prop.off(CHANGES_EVENT, this.onBaseContentAnchorChanged, this)

        this.node.update(UpdateType.TRANSFORM)
    }

    /**
     * @param {PropChange} changes
     */
    onBaseTransitChanged(changes) {
        const obj = {}
        let change = changes.get('translateX')
        if (change) obj.translateX = change.after
        change = changes.get('translateY')
        if (change) obj.translateY = change.after
        this.node.baseTransform.setTranslate(obj)
        this.node.update(UpdateType.TRANSFORM)
    }

    /**
     * @param {PropChange} changes
     */
    onBaseRotationChanged(changes) {
        const obj = {}
        const change = changes.get('rotation')
        if (change) obj.rotation = change.after
        this.node.baseTransform.setRotation(obj)
        this.node.update(UpdateType.TRANSFORM)
    }

    /**
     * @param {PropChange} changes
     */
    onBaseSizeChanged(changes) {
        const obj = {}
        let change = changes.get('width')
        if (change) obj.width = change.after
        change = changes.get('height')
        if (change) obj.height = change.after
        this.node.baseTransform.setSize(obj)
        this.node.update(UpdateType.TRANSFORM | UpdateType.GEOMETRY)
    }

    /**
     * @param {PropChange} changes
     */
    onBaseScaleChanged(changes) {
        const obj = {}
        let change = changes.get('scaleX')
        if (change) obj.scaleX = change.after
        change = changes.get('scaleY')
        if (change) obj.scaleY = change.after
        this.node.baseTransform.setScale(obj)
        this.node.update(UpdateType.TRANSFORM)
    }

    /**
     * @param {PropChange} changes
     */
    onBaseSkewChanged(changes) {
        const obj = {}
        let change = changes.get('skewX')
        if (change) obj.skewX = change.after
        change = changes.get('skewY')
        if (change) obj.skewY = change.after
        this.node.baseTransform.setSkew(obj)
        this.node.update(UpdateType.TRANSFORM)
    }

    /**
     * @param {PropChange} changes
     */
    onBaseReferencePointChanged(changes) {
        const obj = {}
        let change = changes.get('referencePointX')
        if (change) obj.referencePointX = change.after
        change = changes.get('referencePointY')
        if (change) obj.referencePointY = change.after
        this.node.baseTransform.setReferencePoint(obj)
        this.node.update(UpdateType.TRANSFORM)
    }

    /**
     * @param {PropChange} changes
     */
    onBaseContentAnchorChanged(changes) {
        const obj = {}
        let change = changes.get('contentAnchorX')
        if (change) obj.contentAnchorX = change.after
        change = changes.get('contentAnchorY')
        if (change) obj.contentAnchorY = change.after
        this.node.baseTransform.setContentAnchor(obj)
        this.node.update(UpdateType.TRANSFORM)
    }

    /**
     * @param {number} value
     */
    onTranslateXChanged(value) {
        this.node.transform.setTranslateX(value)
        this.node.update(UpdateType.TRANSFORM)
    }

    /**
     * @param {number} value
     */
    onTranslateYChanged(value) {
        this.node.transform.setTranslateY(value)
        this.node.update(UpdateType.TRANSFORM)
    }

    /**
     * @param {Vector2Like} value
     */
    onReferencePointChanged(value) {
        this.node.transform.setReferencePoint(value)
        this.node.update(UpdateType.TRANSFORM)
    }

    /**
     * @param {Vector2Like} value
     */
    onContentAnchorChanged(value) {
        this.node.transform.setContentAnchor(value)
        this.node.update(UpdateType.TRANSFORM)
    }

    /**
     * @param {Vector2} value
     */
    onScaleChanged(value) {
        this.node.transform.setScale(value)
        this.node.transform.update()
        this.node.setScaleFlag(value)
        this.node.update(UpdateType.TRANSFORM)
    }

    /**
     * @param {Vector2} value
     */
    onSkewChanged(value) {
        this.node.transform.setSkew(value)
        this.node.transform.update()
        this.node.update(UpdateType.TRANSFORM)
    }

    /**
     * @param {number} value
     */
    onRotationChanged(value) {
        const finalRotation = this.node.visualServer.dataStore.isActionMode && this.node.autoOrient
            ? value + this.node.orientRotation
            : value
        this.node.transform.setRotation(finalRotation)
        this.node.transform.update()
        this.node.update(UpdateType.TRANSFORM)
    }

    /**
     * @param {Vector2} value
     */
    onSizeChanged(value) {
        this.node.transform.setSize(value)
        this.node.setSizeFlag(value)
        this.node.update(UpdateType.TRANSFORM)
    }


    onEffectsChanged() {
        const { element, node } = this
        const style = element.get('computedStyle')

        let hasTrim = false
        for (const effect of style.effects) {
            if (effect.get("effectType") === EffectType.TRIM_PATH) {
                if (this.trim && this.trim !== effect) {
                    this.trim.off("CHANGES", this.onTrimChanged, this)
                }
                this.trim = effect

                this.trim.on("CHANGES", this.onTrimChanged, this)
                this.onTrimChanged()

                hasTrim = true
            }
        }

        // trim path removed
        if (!hasTrim) {
            if (this.trim) {
                this.trim.off("CHANGES", this.onTrimChanged, this)
            }
            node.setTrimPath(0, 0, 1, TrimPathMode.SIMULTANEOUSLY)
        }
    }

    onTrimChanged() {
        const { node, trim } = this

        node.setTrimPath(
            trim.get("offset") * 0.01,
            trim.get("start") * 0.01,
            trim.get("end") * 0.01,
            trim.get("mode")
        )
    }

    /**
     * @param {Vector2} value
     */
    onBoundsResizeChanged(value) {
        this.node.bounds.setSize(value)
        this.node.update(UpdateType.TRANSFORM)
    }

    /**
     * @param {string} name
     */
    onNameChanged(name) {
        this.node.name = name
    }

    /**
     * @param {boolean} visible
     */
    onVisibleChanged(visible) {
        updateElementVisible(this.node, visible)
        this.node.update(UpdateType.TRANSFORM | UpdateType.GEOMETRY)
    }

    /**
     * @param {number} opacity
     */
    onOpacityChanged(opacity) {
        updateElementOpacity(this.node, opacity)
    }

    /**
     * @param {number} locked
     */
    onLockedChanged(locked) {
        updateElementLocked(this.node, locked)
    }

    /**
     * @param {BlendMode} blendMode
     */
    onBlendModeChanged(blendMode) {
        updateElementBlendMode(this.node, blendMode)
    }

    /**
     *
     * @param {PropChanges} changes
     * @param {*} _     options event was fired with
     * @param {bool} donotUpdate  extra parameter to not update existing render items & controllers
     */
    onLayersChanged(changes, _, donotUpdate = false) {
        if (!changes.size) return

        const { element, node } = this
        const storage = node.visualServer.storage
        const style = element.get('computedStyle')

        /** @type {LayerListType[]} */
        const list = []
        for (let i = 0; i < LAYER_LISTS.length; i++) {
            const listName = LAYER_LISTS[i]
            if (changes.get(listName)) {
                list.push(listName)
            }
        }

        for (let i = 0; i < list.length; i++) {
            switch (list[i]) {
                case 'fills': {
                    this.disconnectFillLayers(!donotUpdate)

                    let itemIndex = 0
                    for (const fill of style.fills) {
                        if (node.base) {
                            node.base.vector.setDataType('FILL')
                            updateElementPaintLayer(node, -1, itemIndex, fill)
                        }

                        const ctrl = new LayerController()
                        ctrl.type = 'paint'
                        ctrl.geometryIndex = -1
                        ctrl.itemIndex = itemIndex
                        ctrl.element = element
                        ctrl.node = node
                        ctrl.layer = fill

                        fill.on('CHANGES', ctrl.onChanged, ctrl)

                        this.fills.push(ctrl)

                        itemIndex += 1
                    }
                } break

                case 'strokes': {
                    this.disconnectBorderLayers(!donotUpdate)

                    let itemIndex = 0
                    for (const border of style.strokes) {
                        setupStrokeGeometry(storage, node, itemIndex, border)
                        if (node.strokes[itemIndex]) {
                            node.strokes[itemIndex].vector.setDataType('STROKE')
                            updateElementPaintLayer(node, itemIndex, itemIndex, border)
                        }

                        const ctrl = new LayerController()
                        ctrl.type = 'paint'
                        ctrl.geometryIndex = itemIndex
                        ctrl.itemIndex = itemIndex
                        ctrl.element = element
                        ctrl.node = node
                        ctrl.layer = border

                        border.on('CHANGES', ctrl.onChanged, ctrl)

                        this.strokes.push(ctrl)

                        itemIndex++
                    }
                } break

                case 'innerShadows': {
                    this.disconnectInnerShadowLayers(!donotUpdate)

                    let itemIndex = 0
                    for (const shadow of style.innerShadows) {
                        if (node.base) {
                            updateElementInnerShadowLayer(element, node, itemIndex, shadow)
                        }

                        const ctrl = new LayerController()
                        ctrl.type = 'inner_shadow'
                        ctrl.geometryIndex = -1
                        ctrl.itemIndex = itemIndex
                        ctrl.element = element
                        ctrl.node = node
                        ctrl.layer = shadow

                        shadow.on('CHANGES', ctrl.onChanged, ctrl)

                        this.innerShadows.push(ctrl)

                        itemIndex += 1
                    }
                } break

                case 'shadows': {
                    this.disconnectShadowLayers(!donotUpdate)

                    let itemIndex = 0
                    for (const shadow of style.shadows) {
                        if (node.base) {
                            updateElementDropShadowLayer(element, node, itemIndex, shadow)
                        }

                        const ctrl = new LayerController()
                        ctrl.type = 'drop_shadow'
                        ctrl.geometryIndex = -1
                        ctrl.itemIndex = itemIndex
                        ctrl.element = element
                        ctrl.node = node
                        ctrl.layer = shadow

                        shadow.on('CHANGES', ctrl.onChanged, ctrl)

                        this.shadows.push(ctrl)

                        itemIndex += 1
                    }
                } break
            }
        }
    }

    onOverflowChanged(value) {
        if (this.node.clipping === value) return
        updateElementClipping(this.node, value)
    }

    onAutoOrientChanged(value) {
        if (this.node.autoOrient === value) return
        updateElementAutoOrient(this.node, value)
        if (this.node.visualServer.dataStore.isActionMode) {
            const finalRotation = this.node.autoOrient
                ? this.node.transform.rotation + this.node.orientRotation
                : this.node.transform.rotation - this.node.orientRotation
            this.node.transform.setRotation(finalRotation)
            this.node.transform.update()
            this.node.update(UpdateType.TRANSFORM)
        }
    }

    onOrientRotationChanged(value) {
        // the orient rotation will change in action mode
        const finalRotation = this.node.autoOrient
            ? this.node.transform.rotation - this.node.orientRotation + value
            : this.node.transform.rotation
        this.node.orientRotation = value
        this.node.transform.setRotation(finalRotation)
        this.node.transform.update()
        this.node.update(UpdateType.TRANSFORM)
    }

    onBooleanTypeChanged(value) {
        if (this.node.booleanType === value) return
        this.node.booleanType = value
        this.node.update(UpdateType.STYLE | UpdateType.TRANSFORM | UpdateType.GEOMETRY)
    }

    onMaskTypeChanged(value) {
        if (this.node.maskType === value) return
        this.node.maskType = value
        this.node.update(UpdateType.STYLE)
    }

    onContainerTypeChange(value){
        const newType = value === ContainerElementType.NORMAL_GROUP? 'group' : 'container'
        if (this.node.type === newType) return
        this.node.type = newType
        // This function should not exist
        this._switchReferencePointEventRecieve()
        this.node.update(UpdateType.GEOMETRY | UpdateType.TRANSFORM)
    }

    /**
     * The new added feature - computed group can prevent referencePoint from effecting tranform matrix because there is no utilities related to the feature
     * Therefore, there is a long term goal needs QA's help to remove referencePoint. The following function will no longer exist once we achieve the goal
     */
    _switchReferencePointEventRecieve() {
        const style = this.element.get('computedStyle')
        const prop = this.element.getBaseProp('referencePoint')
        const referencePoint = this.element.get('referencePoint')
        if (this.node.type === 'group') {
            this.node.transform.setReferencePoint(new Vector2(0, 0))
            style.off('referencePoint')
            prop.off(CHANGES_EVENT, this.onBaseReferencePointChanged, this)
        } else {
            this.node.transform.setReferencePoint(referencePoint)
            this.node.baseTransform.setReferencePoint(prop)
            style.on('referencePoint', this.onReferencePointChanged, this)
            prop.on(CHANGES_EVENT, this.onBaseReferencePointChanged, this)
        }
    }
}
