import { ToolType } from '@phase-software/types'

import { PERMISSIONS } from '../access-control'
import { createOption } from '../components/shared/Menu/utils'

export const TOOLBAR_OPTIONS = {
  GO_TO_DASHBOARD: 'GO_TO_DASHBOARD',
  NEW: 'NEW',
  IMPORT_LOTTIE: 'IMPORT_LOTTIE',
  IMPORT_LOTTIE_OR_PHASE: 'IMPORT_LOTTIE_OR_PHASE',
  IMPORT_SVG: 'IMPORT_SVG',
  INSERT: 'INSERT',
  EXPORT: 'EXPORT',
  UNDO: 'UNDO',
  REDO: 'REDO',
  CUT: 'CUT',
  COPY: 'COPY',
  PASTE: 'PASTE',
  PASTE_HERE: 'PASTE_HERE',
  DELETE: 'DELETE',
  DOWNLOAD: 'DOWNLOAD',
  SELECT_ALL: 'SELECT_ALL',
  SHOW_HIDE_PANELS: 'SHOW_HIDE_PANELS',
  SHOW_HIDE_ORIGIN: 'SHOW_HIDE_ORIGIN',
  TOGGLE_RULER: 'TOGGLE_RULER',
  TOGGLE_INTERFACE: 'TOGGLE_INTERFACE',
  TOGGLE_COMMENT_VISIBILITY: 'TOGGLE_COMMENT_VISIBILITY',
  TOGGLE_ORIGIN: 'TOGGLE_ORIGIN',
  TOGGLE_SNAP_TO_PIXEL_GRID: 'TOGGLE_SNAP_TO_PIXEL_GRID',
  TOGGLE_SNAP_TO_OBJECT: 'TOGGLE_SNAP_TO_OBJECT',
  SHOW_HIDE_PRESENCE: 'SHOW_HIDE_PRESENCE',
  ENTER_VERSION_PREVIEW: 'ENTER_VERSION_PREVIEW',
  SUPPORT: 'SUPPORT',
  WATCH_TUTORIAL_VIDEO: 'WATCH_TUTORIAL_VIDEO'
}

// Primary Options
export const GO_TO_DASHBOARD_OPTION = createOption(
  'menu.go_to_dashboard',
  TOOLBAR_OPTIONS.GO_TO_DASHBOARD,
  'to-dashboard'
)
export const CREATE_NEW_FILE_OPTION = {
  name: 'common:new',
  value: TOOLBAR_OPTIONS.NEW,
  permission: PERMISSIONS.CREATE_FILE,
  dataTestId: 'create-new-file'
}
export const IMPORT_LOTTIE_OPTION = {
  name: 'menu.new_file_from_lottie',
  value: TOOLBAR_OPTIONS.IMPORT_LOTTIE,
  permission: PERMISSIONS.CREATE_FILE,
  dataTestId: 'import-lottie'
}
export const CREATE_NEW_FILE_FROM_IMPORT_OPTION = {
  name: 'menu.new_file_from_import',
  value: TOOLBAR_OPTIONS.IMPORT_LOTTIE_OR_PHASE,
  permission: PERMISSIONS.CREATE_FILE,
  dataTestId: 'create-new-file-from-import'
}
export const INSERT_IMAGE_OPTION = createOption('menu.insert_image', TOOLBAR_OPTIONS.INSERT, 'insert-image')
export const INSERT_SVG_OPTION = createOption('menu.insert_svg', TOOLBAR_OPTIONS.IMPORT_SVG, 'insert-svg')
export const EXPORT_OPTION = createOption('menu.export', TOOLBAR_OPTIONS.EXPORT, 'export')
export const DOWNLOAD_OPTION = createOption('common:menu.download', TOOLBAR_OPTIONS.DOWNLOAD, 'download')

// History Options
export const UNDO_OPTION = createOption('menu.undo', TOOLBAR_OPTIONS.UNDO, 'undo', '⌘Z')
export const REDO_OPTION = createOption('menu.redo', TOOLBAR_OPTIONS.REDO, 'redo', '⇧⌘Z')

// Interactive Options
export const COPY_OPTION = createOption('menu.copy', TOOLBAR_OPTIONS.COPY, 'copy', '⌘C')
export const PASTE_OPTION = createOption('menu.paste', TOOLBAR_OPTIONS.PASTE, 'paste', '⌘V')
// FIXME: PASTE_HERE_OPTION
//    Temporary hide this option, requested by design team
//    This is due to the behavior of paste here is not clearly defined
// export const PASTE_HERE_OPTION = createOption('Paste here', TOOLBAR_OPTIONS.PASTE_HERE)
export const DELETE_OPTION = createOption('menu.delete', TOOLBAR_OPTIONS.DELETE, 'delete')

export const SELECT_ALL_OPTION = createOption('menu.select_all', TOOLBAR_OPTIONS.SELECT_ALL, 'select-all', '⌘A')

// Static Options
export const SHOW_HIDE_RULER_OPTION = createOption(
  'menu.show_ruler',
  TOOLBAR_OPTIONS.TOGGLE_RULER,
  'toggle-ruler',
  '⇧R'
)
export const SHOW_HIDE_INTERFACE_OPTION = createOption(
  'menu.show_interface',
  TOOLBAR_OPTIONS.TOGGLE_INTERFACE,
  'toggle-interface',
  '⌘\\'
)
export const SHOW_HIDE_COMMENT_VISIBILITY_OPTION = createOption(
  'menu.show_comments',
  TOOLBAR_OPTIONS.TOGGLE_COMMENT_VISIBILITY,
  'toggle-comment',
  '⇧C'
)
export const SHOW_HIDE_ORIGIN_OPTION = createOption(
  'menu.show_origin',
  TOOLBAR_OPTIONS.TOGGLE_ORIGIN,
  'toggle-origin',
  '⇧Y'
)
export const SHOW_HIDE_PRESENCE_OPTION = createOption(
  'menu.show_collaborator_cursor',
  TOOLBAR_OPTIONS.SHOW_HIDE_PRESENCE,
  'toggle-presence',
  '⌘⇧\\'
)
export const TOGGLE_SNAP_TO_PIXEL_GRID_OPTION = createOption(
  'menu.snap_to_pixel_grid',
  TOOLBAR_OPTIONS.TOGGLE_SNAP_TO_PIXEL_GRID,
  'toggle-pixel-snap'
)
export const TOGGLE_SNAP_TO_OBJECT_OPTION = createOption(
  'menu.snap_to_object',
  TOOLBAR_OPTIONS.TOGGLE_SNAP_TO_OBJECT,
  'toggle-object-snap'
)


export const SUPPORT_OPTION = createOption('menu.support', TOOLBAR_OPTIONS.SUPPORT, 'support')
export const WATCH_TUTORIAL_VIDEO_OPTION = createOption(
  'menu.watch_tutorial_video',
  TOOLBAR_OPTIONS.WATCH_TUTORIAL_VIDEO,
  'watch-tutorial-video'
)

// Primary Tools
export const GENERAL_SELECT_OPTION = { name: 'select', value: ToolType.SELECT, icon: 'Select', shortcut: 'V' }
export const GENERAL_SCALE_OPTION = {
  name: 'scale',
  value: ToolType.SCALE,
  icon: 'Zoom',
  shortcut: 'K',
  className: 'pt-scale-option'
}

export const SHAPE_OPTIONS = [
  {
    name: 'rectangle',
    value: ToolType.RECTANGLE,
    icon: 'Rectangle',
    shortcut: 'R',
    dataTestId: 'rectangle-tool'
  },
  {
    name: 'oval',
    value: ToolType.ELLIPSE,
    icon: 'Oval',
    shortcut: 'O',
    dataTestId: 'oval-tool'
  },
  {
    name: 'container',
    value: ToolType.CONTAINER,
    icon: 'Container',
    shortcut: 'N',
    dataTestId: 'container-tool'
  }
  // TODO: ComputedGroup
  // { name: 'Polygon', value: ToolType.POLYGON, icon: 'Polygon', shortcut: 'P' },
  // { name: 'Star', value: ToolType.STAR, icon: 'Star', shortcut: 'S' },
  // { name: 'Line', value: ToolType.LINE, icon: 'Line', shortcut: 'L' },
  // { name: 'Arrow', value: ToolType.ARROW, icon: 'Arrow', shortcut: 'A' },
]
export const PEN_OPTION = { name: 'pen', value: ToolType.PEN, icon: 'Pen' }
export const HAND_OPTION = { name: 'hand', value: ToolType.HAND, icon: 'Hand' }
export const COMMENT_OPTION = { name: 'comment', value: ToolType.COMMENT, icon: 'Comment' }
export const UNREAD_COMMENT_OPTION = { name: 'comment', value: ToolType.COMMENT, icon: 'UnreadComment' }
export const INSERT_OPTION = { name: 'insert', value: ToolType.INSERT, icon: 'InsertImage' }

// Links
export const SUPPORT_LINKS = 'https://community.phase.com/s/support/'
