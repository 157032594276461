import { GradientResource } from '../resources/GradientResource'
import { FontResource } from '../resources/FontResource'
import { TextResource } from '../resources/TextResource'
import { createTexture, deleteTexture } from '../wasm/platforms/web/web'

/** @typedef {import('../gfx/gfx').Gfx} Gfx */
/** @typedef {import('../gfx/gfx').Gfx_Image_t} Gfx_Image_t */
/** @typedef {import('./VisualServer').VisualServer} VisualServer */

/** @type {VisualServer} */
let VS = null

/**
 * @typedef {object} ImageResource
 * @property {string} id
 * @property {HTMLImageElement} img
 * @property {string} texture_id        id that represent the Image uploaded to gfx (GPU)
 * @property {Set<string>} node_ids     for trigger node update after image loaded
 * @property {boolean} _dirty
 */
/**
 * @param {string} id
 */
const createEmptyImageResource = () => {
    /** @type {ImageResource} */
    const res = {
        id: null,
        img: null,
        node_ids: new Set(),
        texture_id: null,
        _dirty: false
    }
    return res
}

export class VisualStorage {
    static instance() {
        return instance
    }

    /**
     * @param {VisualServer} vs
     */
    constructor(vs) {
        if (!VS) VS = vs

        // @Incomplete: use controllers to handle connection with DataStore

        /** @type {Map<string, ImageResource>} */
        this.images = new Map()
        /** @type {Record<string, GradientResource>} */
        this.gradients = Object.create(null)
        /** @type {Record<string, TextResource>} */
        this.texts = Object.create(null)
        /** @type {Record<string, FontResource>} */
        this.fonts = Object.create(null)


        // general textures for VisualServer

        this.resources = {
            // default texture placeholders
            // tex_White: CreateFlatTexture(this.gfx, 0xFF, 0xFF, 0xFF, 0xFF),
            // tex_Black: CreateFlatTexture(this.gfx, 0x00, 0x00, 0x00, 0xFF),
            // tex_Checkboard: CreateCheckboardTexture(this.gfx, 0x00, 0xFF),

            // // opaque textures for development
            // tex_Red:   CreateFlatTexture(this.gfx, 0xFF, 0x00, 0x00, 0xFF),
            // tex_Green: CreateFlatTexture(this.gfx, 0x00, 0xFF, 0x00, 0xFF),
            // tex_Blue:  CreateFlatTexture(this.gfx, 0x00, 0x00, 0xFF, 0xFF),

            // // fully transparent
            // tex_Zero:  CreateFlatTexture(this.gfx, 0x00, 0x00, 0x00, 0x00),
        }

        instance = this
    }

    /**
     * @param {string} id
     */
    createImageResource(id) {
        const res = createEmptyImageResource()
        res.id = id
        this.images.set(id, res)
        return res
    }
    /**
     * @param {string} id
     */
    getImageResource(id) {
        return this.images.get(id)
    }
    /**
     * @param {string} id
     */
    destroyImageResource(id) {
        const res = this.images.get(id)
        if (!res) return

        deleteTexture(res.texture_id)
        this.images.set(id, null)
    }
    /**
     * load image from src string
     * @param {string} id
     * @param {string} src - any src string that `HTMLImageElement` supports
     */
    loadImage(id, src) {
        const res = this.images.get(id)
        const image = new Image()
        image.onload = () => {
            res.img = image
            res._dirty = true
        }
        image.onerror = () => {
            // TODO: depending on error, retry or use a fallback
        }
        // CORS requests for this element will have the credentials flag set to 'same-origin'
        image.crossOrigin = 'anonymous'
        image.src = src
    }
    /**
     * update image resource if it's dirty, and update all nodes that use this image
     * @param {string} id
     */
    updateImageResource(id) {
        const res = this.images.get(id)
        if (res._dirty) {
            const texture_id = createTexture(res.img)
            res.texture_id = texture_id
            res._dirty = false

            for (const id of res.node_ids) {
                const node = VS.indexer.nodeMap.get(id)
                node.item.styleUpdated()
            }
        }
    }

    /**
     * @param {string} [id]
     * @returns {GradientResource}
     */
    createGradientResource(id) {
        const res = new GradientResource(this.gfx)
        if (id) res.id = id
        this.gradients[res.id] = res
        return res
    }
    /**
     * @param {string} id
     */
    destroyGradientResource(id) {
        const res = this.gradients[id]
        if (!res) {
            delete this.gradients[id]
            return
        }

        // remove from storage
        delete this.gradients[id]

        // destroy internal resource
        if (res._image) {
            this.gfx.destroyImage(res._image)
        }

        // reset
        res._image = null
        res.removeAllListeners()

        // TODO: recycle
    }
    /**
     * @param {string} id
     * @returns {GradientResource}
     */
    getGradientResource(id) {
        return this.gradients[id]
    }

    /**
     * @param {string} [id]
     * @returns {FontResource}
     */
    createFontResource(id) {
        const res = new FontResource()
        if (id) res.id = id
        this.fonts[res.id] = res
        return res
    }
    /**
     * @param {string} id
     */
    destroyFontResource(id) {
        const res = this.fonts[id]
        if (!res) return

        res.removeAllListeners()

        // remove from storage
        this.fonts[id] = null

        // TODO: recycle
    }
    /**
     * @param {string} id
     * @returns {FontResource}
     */
    getFontResource(id) {
        return this.fonts[id]
    }
    /**
     * @param {string} fontName
     * @returns {FontResource}
     */
    getOrCreateFontResource(fontName) {
        let res = this.getFontResource(fontName)
        if (res) return res

        res = this.createFontResource(fontName)
        res.setFontName(fontName)
        return res
    }

    /**
     * @param {string} [id]
     * @returns {TextResource}
     */
    createTextResource(id) {
        const res = new TextResource()
        if (id) res.id = id
        this.texts[res.id] = res
        return res
    }
    /**
     * @param {string} id
     */
    destroyTextResource(id) {
        const res = this.texts[id]
        if (!res) return

        // remove from storage
        this.texts[id] = null

        // destroy internal resource

        // reset
        res.removeAllListeners()

        // TODO: recycle
    }
    /**
     * @param {string} id
     * @returns {TextResource}
     */
    getTextResource(id) {
        return this.texts[id]
    }

    update() {
        for (const [id,] of this.images) {
            this.updateImageResource(id)
        }
        for (const id in this.gradients) {
            const g = this.gradients[id]
            if (g) g._update()
        }
        for (const id in this.fonts) {
            const f = this.fonts[id]
            if (f) f._update()
        }
        for (const id in this.texts) {
            const t = this.texts[id]
            if (t) t._update()
        }
        // for (const id in this.vectors) {
        //     const v = this.vectors[id]
        //     if (v) v._update()
        // }
    }

    clear() {
        for (const id in this.images) {
            this.destroyImageResource(id)
        }
        this.images = new Map()

        for (const id in this.gradients) {
            this.destroyGradientResource(id)
        }
        this.gradients = Object.create(null)

        for (const id in this.texts) {
            this.destroyTextResource(id)
        }
        this.texts = Object.create(null)

        // TODO: cache fonts and glyph data, maybe compress for potential use later
        // for (const id in this.fonts) {
        //     this.destroyFontResource(id)
        // }
        // this.fonts = Object.create(null)

        return true
    }
}

/** @type {VisualStorage} */
let instance = null

// /**
//  * @param {Gfx} gfx
//  * @param {number} r
//  * @param {number} g
//  * @param {number} b
//  * @param {number} a
//  * @returns {Gfx_Image_t}
//  */
// function CreateFlatTexture(gfx, r, g, b, a) {
//     const width = 4
//     const height = 4

//     const data = new Uint8Array(width * height * 4)
//     for (let i = 0; i < width * height * 4; i += 4) {
//         data[i + 0] = r
//         data[i + 1] = g
//         data[i + 2] = b
//         data[i + 3] = a
//     }

//     return gfx.makeImage({
//         width,
//         height,
//         data: [[{ raw: true, data }]],
//     })
// }

// /**
//  * @param {Gfx} gfx
//  * @param {number} c1
//  * @param {number} c2
//  * @returns {Gfx_Image_t}
//  */
// function CreateCheckboardTexture(gfx, c1, c2) {
//     const width = 64
//     const height = 64

//     const data = new Uint8Array(width * height * 4)
//     for (let r = 0; r < 4; r++) {
//         for (let q = 0; q < 4; q++) {
//             const c = ((q + r) % 2) ? c1 : c2

//             for (let rr = 0; rr < 16; rr++) {
//                 for (let qq = 0; qq < 16; qq++) {
//                     const i = (r * (width * 16) + rr * width + q * 16 + qq) * 4

//                     data[i + 0] = c
//                     data[i + 1] = c
//                     data[i + 2] = c
//                     data[i + 3] = 0xFF
//                 }
//             }
//         }
//     }

//     return gfx.makeImage({
//         width,
//         height,
//         data: [[{ raw: true, data }]],
//     })
// }
